// Login.jsx
import { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Divider, FlexboxGrid, Panel } from 'rsuite';
import { withTranslation } from 'react-i18next';

/* The page shown to new users - allows to register, login or use the page without logging in */

interface HomePageProps {
    loggedIn: boolean
    t: (k:string) => string
    tReady: boolean
}

interface HomePageState {
    username:string
    password:string
}

class HomePage extends Component<HomePageProps, HomePageState> {

  constructor(props:HomePageProps) {
    super(props)
    this.state = {
      username : '',
      password: '',
    }
  }

  render() {

    const linkStyle = {margin: -10, marginTop: -13, fontSize: 16}

    const imageStyle = {marginTop: 30, border: '2px solid'}

    return this.props.loggedIn ? 
    <Redirect to={'/workspaces'} ></Redirect> :
    (
      <div style={{display: 'block', width: '100%'}}>
        <FlexboxGrid justify="center" style={{padding: 100}}>
          <FlexboxGrid.Item colspan={12}>
            <Panel>
                <h2>{this.props.t('welcome_home_page')}</h2>
                <Divider/>
                <p style={{fontSize: 18}}>

                  <Button  
                    componentClass={Link} to="/login" 
                    appearance='link'  
                    style={linkStyle}
                  >
                    {this.props.t('login')}
                  </Button>

                  {', '}

                  <Button  
                    componentClass={Link} 
                    to="/register" 
                    appearance='link'  
                    style={linkStyle}
                  >
                    {this.props.t('register')}
                  </Button>

                  {this.props.t('proceed_home_page')}

                  <Button 
                    componentClass={Link} 
                    to="/analysis" 
                    appearance='link' 
                    style={linkStyle}
                  >
                    {this.props.t('without_logging_in')}
                  </Button>

                  {'.'}

                </p>
                
                <p style={{fontSize: 18, marginTop: 30}}>{this.props.t('home_page_a')}</p>
                <p style={{fontSize: 18}}>{this.props.t('home_page_b')}</p>
                <Panel style={imageStyle}><img alt='Shape' src='./shape.png' style={{width: '100%'}} /></Panel>
                <Panel style={imageStyle} bordered><img alt='Mapping' src='./mapping.png' style={{width: '120%'}} /></Panel>
                <p style={{fontSize: 18, marginTop: 30}}>{this.props.t('home_page_c')}</p>
                <p style={{fontSize: 18}}>{this.props.t('home_page_d')}</p>
                <Panel style={imageStyle} bordered><img alt='Violations' src='./violations.png' style={{width: '120%'}} /></Panel>
                <p style={{fontSize: 18, marginTop: 30}}>{this.props.t('home_page_e')}</p>
                <Panel style={imageStyle} bordered><img alt='Overview' src='./overview.png' style={{width: '110%'}} /></Panel>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withTranslation()(HomePage)