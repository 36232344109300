import {ChangeEvent, Component} from 'react'; 
import { Alert, Icon } from 'rsuite';
import { Repo } from '../../repositories/Repo';
import { withTranslation } from 'react-i18next';
import { ERROR_TIME } from '../../utils/utils';

/* Panel to upload log files */

interface FileUploadProps {
    workspaceId:number
    update: () => void
    t: (k:string) => string
    tReady: boolean
}

interface FileUploadState {
    selectedFile:File|undefined
}

class UploadPanel extends Component<FileUploadProps, FileUploadState> { 

    state:FileUploadState = { 
        selectedFile: undefined
    }; 
     
    onFileChange = (event:ChangeEvent) => {
      if(event.target){
          const tar:HTMLInputElement = (event.target as HTMLInputElement)
          if(tar.files) {
            this.onFileUpload(tar.files[0])
          }
      } else{
        Alert.error(this.props.t('select_file_error'), ERROR_TIME);
      }
    }; 
     
    onFileUpload = (file:File) => { 
      const filename = file.name
      file.text().then((content) => {
        const file = {
            log_name : filename,
            content: content
        }
        return Repo.addLog(this.props.workspaceId, file)
        })
        .then(res => {
          if (res.success) {
            Alert.info(this.props.t('upload_success'), 15000)
            this.setState({selectedFile: undefined})
            this.props.update()
          } else {
            Alert.error(this.props.t('upload_error') + res.error_msg, ERROR_TIME);
          }
        })
    }
     
    fileData = () => { 
      if (this.state.selectedFile) { 
          
        return ( 
          <div  style={{margin: 3}}> 
            <h5>{this.props.t('file_details')}</h5> 
            <p>{this.props.t('file_name') + this.state.selectedFile.name}</p> 
            <p>{this.props.t('file_type') + this.state.selectedFile.type}</p> 
            <p> 
              {this.props.t('last_modified') + ":"} 
              {new Date(this.state.selectedFile.lastModified).toDateString()} 
            </p> 
          </div>
        ); 
      } else { 
        return ( 
          <div style={{margin: 3}}> 
            <br /> 
            <label>{this.props.t('choose_file_before_upload')}</label> 
          </div> 
        ); 
      } 
    };
     
    render() { 
      return (

        <div className="input-group" style={{width: 150}}>
          <label className="custom-file-upload">
            <input type="file" multiple onChange={this.onFileChange} />
            <Icon icon='plus-square'/>{' Upload log'}
          </label>
        </div>
      ); 
    } 
  } 
  
  export default withTranslation()(UploadPanel) 