import { Component } from 'react';
import { Alert, Button, ButtonToolbar, FlexboxGrid, Input, Panel, SelectPicker, Table, Tooltip, Whisper } from 'rsuite';
import { AccessRole, InvitationInfo, ShareUserInfo, UserInfo, WorkspaceInfo } from '../../../../common/ClientServerInterface';
import { Repo } from '../../repositories/Repo';
import MyModal from '../helperComponents/MyModal';
import { EditText } from '../helperComponents/EditableText';
import UserList from './UserList';
import { withTranslation } from 'react-i18next';
import { ERROR_TIME, formatDate } from '../../utils/utils';
import { TutorialTooltip } from '../helperComponents/TutorialTooltip';

/* Modal to handle who has access to the workspace */

interface WorkspaceResourceModalProps {
    userId: number
    workspace: WorkspaceInfo|undefined
    saveWorkspaceName: (workspace:WorkspaceInfo, newName:string) => Promise<void>
    onClose: () => void
    show: boolean
    t: (k:string) => string
    tReady: boolean
}

interface WorkspaceResourceModalState {
    invitations:InvitationInfo[]
    link:string
    sharedWith: ShareUserInfo[]
    invitation_name: string
    invitation_role: AccessRole
    invitation_duration: number
}

class WorkspaceResourceModal extends Component<WorkspaceResourceModalProps> {
    state:WorkspaceResourceModalState
    userId: number

    constructor(props:WorkspaceResourceModalProps) {
        super(props);
        this.userId = props.userId
        this.state = {
            sharedWith: [],
            invitations: [],
            link: '',
            invitation_name: '',
            invitation_duration: 24,
            invitation_role: 'Upload'
        }
    }

    async removeInvitation(id:number){
        await Repo.removeInvitation(this.props.workspace!.id, id)
        .then(res => {
            if (res.success) {
                this.setState({invitations: this.state.invitations.filter(inv => inv.id !== id)})
            } else {
                Alert.error(this.props.t('remove_invitation_error') + res.error_msg, ERROR_TIME);
            }
        })
    }

    async removeShare(id:number){
        await Repo.unshareWorkspace(this.props.workspace!.id, {user_id: id})
        .then(res => {
            if (res.success) {
                this.setState({sharedWith: this.state.sharedWith.filter(user => user.id !== id)})
            } else {
                Alert.error(this.props.t('unshare_workspace_error') + res.error_msg, ERROR_TIME);
            }
        })
    }

    async setAccess(user:ShareUserInfo, access:AccessRole){
        await Repo.editAccess(this.props.workspace!.id, {user_id: user.id, access: access})
        .then(res => {
            if (res.success) {
                user.access = access
                this.setState({sharedWith: this.state.sharedWith})
            } else {
                Alert.error(this.props.t('unshare_workspace_error') + res.error_msg, ERROR_TIME);
            }
        })
    }

    async saveInvitationName(id:number, name:string){
        await Repo.editInvitation(this.props.workspace!.id, id, {invitation_name: name})
        .then(res => {
            if(res.success) {
                Alert.info(this.props.t('edit_invitation_success'));
            } else {
                Alert.error(this.props.t('edit_invitation_error')  + res.error_msg, ERROR_TIME);
            }
        })
    }

    onInvNameChange = (value:string, event:any) => {
        this.setState({
            invitation_name: value
        })
    }
    
    async createInvitation(workspace:WorkspaceInfo, name:string, role:AccessRole, duration:number){
        if(name.length <= 0){
            Alert.error(this.props.t('no_name_share_workspace_error'));
        } else{
            await Repo.shareWorkspace(workspace.id, {role: role, name: name, duration: duration})            
            .then(res => {
                if(res.success) {
                    Alert.info(this.props.t('create_invitation_success'));
                    this.setState({invitation_name: ''})
                    this.fetchWorkspaceInfo()
                } else {
                    Alert.error(this.props.t('share_workspace_error')  + res.error_msg, ERROR_TIME);
                }
            })
        }
    }

    onSelectRole(role:AccessRole){
        this.setState({invitation_role: role})
    }

    onSelectDuration(duration:number){
        this.setState({invitation_duration: duration})
    }

    async fetchWorkspaceInfo(){
        let invitations:InvitationInfo[] = []
        let sharedWith:UserInfo[] = []

        if(this.props.workspace){
            const res = await Repo.getWorkspace(this.props.workspace?.id)

            if(!res.success){
                Alert.error(this.props.t('get_workspace_error') + res.error_msg, ERROR_TIME)
            } else{
                invitations = res.payload!.invitations
                sharedWith = res.payload!.shared_with.filter((u:UserInfo) => Number(u.id) !== Number(this.userId))

                this.setState({
                    sharedWith: sharedWith, 
                    invitations: invitations
                })
            }
        }
    }

    getDurations(){
        const hour = this.props.t('hour')
        const hours = this.props.t('hours')
        const day = this.props.t('day')
        const days = this.props.t('days')
        const week = this.props.t('week')
        const month = this.props.t('month')

        return [
            {val: 1, lab: '1'+hour},
            {val: 5, lab: '5'+hours},
            {val: 24, lab: '1'+day},
            {val: 24*3, lab: '3'+days},
            {val: 24*7, lab: '1'+week},
            {val: 24*31, lab: '1'+month},
        ]
    }

    componentDidMount() {
        this.fetchWorkspaceInfo()
    }

    render() {

        const acc_role = this.props.workspace?.role

        return (
            this.props.workspace ?
            <MyModal 
                buttons={[]} 
                title={
                    <EditText 
                        onSave={(s) => this.props.saveWorkspaceName(this.props.workspace!, s)} 
                        value={this.props.workspace.name} editClassName="form-control" size={30}
                    />
                } 
                titleSize={'36px'} 
                show={this.props.show} 
                onClose={this.props.onClose} onAccept={this.props.onClose} 
                full={true} 
                onShow={this.fetchWorkspaceInfo.bind(this)}
            >
                <FlexboxGrid justify="center" style={{padding:  20}}>
                    <FlexboxGrid.Item colspan={22}>

                        <div style={{marginTop: 50}}>
                            <h4 className='list-head'>
                                {this.props.t('access_to_workspace')}
                            </h4>
                            <UserList 
                                data={this.state.sharedWith} 
                                deletable={acc_role === 'Full'}
                                emptyMessage={this.props.t('no_accesses')} 
                                saveName={async (id:number, name:string) => {}}
                                remove={this.removeShare.bind(this)}
                                workspace={this.props.workspace}
                                setAccess={this.setAccess.bind(this)}
                            /> 
                        </div>

                        {acc_role === 'Full' ?
                            <>
                            <div style={{ marginTop: 30}}>
                                <Panel header={<big>{this.props.t('create_invitation')}</big>} bordered>
                                    <label>{this.props.t('invitation_name')}</label>
                                    <Input 
                                        placeholder={this.props.t('invitation_placeholder')} 
                                        onChange={this.onInvNameChange}
                                    />

                                    <div style={{marginTop: 10}}>
                                        <label>{this.props.t('role')}</label>
                                        <TutorialTooltip text={this.props.t('tutorial_role')} />
                                        <SelectPicker
                                            style={{ width: "100%" }}
                                            value={ this.state.invitation_role}
                                            size="s"
                                            placeholder={ this.state.invitation_role}
                                            data={[{val: 'Full'}, {val: 'Standard'}, {val: 'Upload'}]}
                                            labelKey={"val"}
                                            valueKey={"val"}
                                            searchable={false}
                                            onSelect={(v:number, item:any, event:React.SyntheticEvent<any,Event>) => 
                                                this.onSelectRole(item.val)}
                                            preventOverflow
                                        />
                                    </div>

                                    <div style={{marginTop: 10}}>
                                        <label>Duration</label>
                                        <SelectPicker
                                            style={{ width: "100%" }}
                                            value={ this.state.invitation_duration}
                                            size="s"
                                            placeholder={ this.state.invitation_duration}
                                            data={this.getDurations()}
                                            labelKey={"lab"}
                                            valueKey={"val"}
                                            searchable={false}
                                            onSelect={(v:number, item:any, event:React.SyntheticEvent<any,Event>) => 
                                                this.onSelectDuration(item.val)}
                                            preventOverflow
                                        />
                                    </div>

                                    <ButtonToolbar style={{ display: "flex"}}>
                                        <Button 
                                            appearance='ghost' 
                                            style={{ marginTop: 10, marginLeft: 'auto' }} 
                                            onClick={() => this.createInvitation(
                                                this.props.workspace!, 
                                                this.state.invitation_name, 
                                                this.state.invitation_role, 
                                                this.state.invitation_duration
                                            )}
                                        >
                                            {this.props.t('create_invitation_button')}
                                        </Button>
                                    </ButtonToolbar>
                                </Panel>
                            </div>
                            
                            <div style={{marginTop: 50}}>
                                <h4 className='list-head'>
                                    {this.props.t('invitations')}
                                </h4>
                                <Table rowHeight={50} data={this.state.invitations} id="table" hover>
                                    <Table.Column width={200}>
                                        <Table.HeaderCell>{this.props.t('name')}</Table.HeaderCell>
                                        <Table.Cell>
                                            {(rowData:InvitationInfo) => 
                                            <EditText 
                                                value={rowData.name} 
                                                editClassName='' 
                                                onSave={(name:string) => this.saveInvitationName(rowData.id, name)} 
                                                size={14}
                                            />
                                            }
                                        </Table.Cell>
                                    </Table.Column>

                                    <Table.Column width={100}>
                                        <Table.HeaderCell>{this.props.t('role')}</Table.HeaderCell>
                                        <Table.Cell dataKey="role"></Table.Cell>
                                    </Table.Column>

                                    <Table.Column width={500}>
                                        <Table.HeaderCell>Link</Table.HeaderCell>
                                        <Table.Cell>
                                            {(i:InvitationInfo) => 
                                            (
                                                <div>
                                                    {'www.gdpr-check.net/invitation/' + i.link}
                                                    <Whisper trigger='click' speaker={
                                                        <Tooltip>
                                                            {this.props.t('link_has_been_copied')}
                                                        </Tooltip>
                                                    }>
                                                        <Button 
                                                            onClick={() => {
                                                                    navigator.clipboard.writeText(
                                                                        'www.gdpr-check.net/invitation/' + i.link)
                                                                }
                                                            }
                                                            style={{marginLeft: 10}}
                                                            appearance='ghost'
                                                            size='xs'
                                                        >
                                                            {this.props.t('copy')}
                                                        </Button>
                                                    </Whisper>
                                                </div>
                                            )
                                            }
                                        </Table.Cell>
                                    </Table.Column>

                                    <Table.Column width={150}>
                                        <Table.HeaderCell>{this.props.t('expiration')}</Table.HeaderCell>
                                        <Table.Cell>
                                            {(rowData:InvitationInfo) => 
                                                formatDate(new Date(rowData.expiration_date))
                                            }
                                        </Table.Cell>
                                    </Table.Column>


                                </Table>
                            </div>
                            </>
                         : ''}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </MyModal>
            : ''
        );
    }
}

export default withTranslation()(WorkspaceResourceModal)

