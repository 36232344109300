import { Component } from 'react';
import { Alert, Button, ButtonToolbar, Divider, FlexboxGrid, Input, Loader, Panel } from 'rsuite';
import { WorkspaceInfo } from '../../../common/ClientServerInterface';
import WorkspaceList from '../components/workspacesPageComponents/WorkspaceList';
import WorkspaceResourceModal from '../components/workspacesPageComponents/WorkspaceResourceModal';
import { Repo } from '../repositories/Repo';
import { withTranslation } from 'react-i18next';
import WorkspaceAccessModal from '../components/workspacesPageComponents/WorkspaceAccessModal';
import { ERROR_TIME } from '../utils/utils';
import { TutorialTooltip } from '../components/helperComponents/TutorialTooltip';

/* This file contains the page for showing available workspaces */

interface WorkspacesProps {
    userId: number
    chooseAnalysis: (w_id:number, a_id:number) => void
    t: (k:string) => string
    tReady: boolean
}

interface WorkspacesState {
    workspaces:WorkspaceInfo[]
    workspaceName: string
    shareUser:string
    showResourceModal: boolean
    showAccessModal: boolean
    chosenWorkspace: WorkspaceInfo|undefined
    invitationLink: string
    loading: boolean
}

class WorkspacesPage extends Component<WorkspacesProps> {
    state:WorkspacesState
    userId: number

    constructor(props:WorkspacesProps) {
        super(props);
        this.userId = props.userId
        this.state = {
            workspaces: [],
            workspaceName: '',
            invitationLink: '',
            shareUser: '',
            showResourceModal: false,
            showAccessModal: false,
            chosenWorkspace: undefined,
            loading: true
        }
    }

    async removeWorkspace(id:number){
        await Repo.removeWorkspace(id)
            .then(res => {
                if (res.success) {
                  this.setState({workspaces: this.state.workspaces.filter(workspace => workspace.id !== id)})
                } else {
                    Alert.error(this.props.t('remove_workspace'), ERROR_TIME);
                }
            })
    }

    async leaveWorkspace(id:number){
        await Repo.unshareWorkspace(id, {user_id: this.userId})
            .then(res => {
                if (res.success) {
                  this.setState({workspaces: this.state.workspaces.filter(workspace => workspace.id !== id)})
                } else {
                    Alert.error(this.props.t('leave_workspace_error'), ERROR_TIME);
                }
            })
    }

    async createWorkspace(name:string){
        if(name.length <= 0){
            Alert.error(this.props.t('no_name_workspace_error'), ERROR_TIME)
        }
        else{
            await Repo.addWorkspace({workspace_name: name})
            .then(res => {
                if (res.success) {
                    this.state.workspaces.push(res.payload!)
                    this.setState({workspaces: this.state.workspaces, workspaceName: ''})
                    Alert.info(this.props.t('created_workspace_success'))
                } else {
                    Alert.error(this.props.t('post_workspace_error') + res.error_msg, ERROR_TIME)
                }
            })
        }
    }

    async saveWorkspaceName(workspace:WorkspaceInfo, name:string){
        if(workspace.name !== name){
            Repo.editWorkspace(workspace.id, {workspace_name: name}).then(res => {
                if(res.success) {
                    Alert.success(this.props.t('update_workspace_success'))
                    workspace.name = name
                } else{
                    Alert.error(this.props.t('update_workspace_error') + res.error_msg, ERROR_TIME)
                }
            })
        }
    }

    handleNewWorkspaceNameChange = (value:string, event:any) => {
        this.setState({
            workspaceName: value
        })
    }

    handleInvitationLinkChange = (value:string, event:any) => {
        this.setState({
            invitationLink: value
        })
    }

    showResourceModal(workspace:WorkspaceInfo){
        this.setState({chosenWorkspace: workspace, showResourceModal: true})
    }

    showAccessModal(workspace:WorkspaceInfo){
        this.setState({chosenWorkspace: workspace, showAccessModal: true})
    }

    async updateWorkspaces(){
        let workspaces:WorkspaceInfo[] = []
        await Repo.getWorkspaces()
        .then(res => res.success ? 
            res.payload!.forEach((w:WorkspaceInfo) => {workspaces.push(w)}) 
            : 
            Alert.error(this.props.t('get_workspace_error') + res.error_msg, ERROR_TIME)
        )
        this.setState({workspaces: workspaces, loading: false})
    }
    
    componentDidMount() {
        this.updateWorkspaces()
    }

    render() {
        const myWorkspaces = this.state.workspaces.filter((l) => Number(l.created_by) === Number(this.userId))
        const sharedWorkspaces = this.state.workspaces.filter((l) => Number(l.created_by) !== Number(this.userId))

        const buttonStyle = { borderStyle: "solid", borderWidth: "1px" }

        return (
            <>
            <div style={{display: 'block', width: '100%'}}>
                <FlexboxGrid justify="center" style={{padding: 0}}>
                    <FlexboxGrid.Item colspan={10}>
                        <div style={{marginTop: 50}}>
                            <Panel 
                                collapsible 
                                defaultExpanded 
                                header={
                                    <h4 className='list-head'>
                                        {this.props.t('my_workspaces')}
                                        <TutorialTooltip text={this.props.t('tutorial_workspaces')}/>
                                    </h4>
                                }
                            >   
                                {
                                    this.state.loading ? 
                                    <div style={{width: '100%', textAlign: 'center'}}><Loader size="md" /></div>
                                    :
                                    <WorkspaceList 
                                        workspaces={myWorkspaces}
                                        showResourceModal={this.showResourceModal.bind(this)}
                                        showAccessModal={this.showAccessModal.bind(this)}
                                        emptyMessage={this.props.t('no_workspaces')}
                                        leaveWorkspace={this.leaveWorkspace.bind(this)}
                                        onChoose={this.props.chooseAnalysis}>
                                    </WorkspaceList>
                                }
                            </Panel>
                        </div>
                        <div style={{marginTop: 50}}>
                            <Panel 
                                collapsible 
                                defaultExpanded 
                                header={
                                    <h4 className='list-head'>
                                        {this.props.t('shared_workspaces')}
                                    </h4>
                                }
                            > 
                                {
                                    this.state.loading ? 
                                    <div style={{width: '100%', textAlign: 'center'}}><Loader size="md" /></div>
                                    :
                                    <WorkspaceList 
                                        workspaces={sharedWorkspaces} 
                                        showResourceModal={this.showResourceModal.bind(this)}
                                        showAccessModal={this.showAccessModal.bind(this)}
                                        emptyMessage={this.props.t('no_shared_workspaces')}
                                        leaveWorkspace={this.leaveWorkspace.bind(this)}
                                        onChoose={this.props.chooseAnalysis}>
                                    </WorkspaceList>
                                }
                            </Panel>
                        </div>
                        <div style={{marginTop: 50}}>
                            <Panel 
                                header={
                                    <h5 className='list-head'>
                                        {this.props.t('create_new_workspace')}
                                    </h5>
                                }
                            > 
                                <ButtonToolbar style={{ display: "flex"}}>
                                    <Input 
                                        onChange={this.handleNewWorkspaceNameChange} 
                                        value={this.state.workspaceName} 
                                        placeholder={this.props.t('enter_workspace_name')} 
                                        style={{width: 300}}
                                    />
                                    <Button 
                                        appearance='ghost' 
                                        onClick={() => this.createWorkspace(this.state.workspaceName)} 
                                        style={buttonStyle}
                                    >
                                        {this.props.t('create_workspace')}
                                    </Button>
                                </ButtonToolbar>
                            </Panel>

                            <div style={{height: 200}}/>

                            <Divider/>
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>

            <WorkspaceResourceModal 
                workspace={this.state.chosenWorkspace} 
                userId={this.props.userId} 
                onClose={() => this.setState({showResourceModal: false})}
                saveWorkspaceName={this.saveWorkspaceName.bind(this)}
                show={this.state.showResourceModal}
            />

            <WorkspaceAccessModal 
                workspace={this.state.chosenWorkspace} 
                userId={this.props.userId} 
                onClose={() => this.setState({showAccessModal: false})}
                saveWorkspaceName={this.saveWorkspaceName.bind(this)}
                show={this.state.showAccessModal}
            />
            </>
                    
        );
    }
}

export default withTranslation()(WorkspacesPage)
