import { ActionsToGdpr,  FileData, LogRecord, LogRecordId } from "../models/engine-types";
import { TimeFormat } from "../models/timeformat";
import { tryDivide } from "../utils/utils";
import { AttributeCol, GDPRAttr, SeparatorInfo, Shape, ShapeInput } from "../models/shape";
import { IFileData } from "../models/file";
import { randomBytes } from "crypto";

/** This file contains the functions used to handle the translation of the log records to actions via shapes */

/* Validates that the shapes of a log is in the correct format and that all actions are mapped in the action mapping */
export function validateAndParseIFileData(log:IFileData) : FileData {
    try {
        if (!log.content) throw new Error(`Missing content for file.`)

        let convertedShapes = validateShapeInputs(log.shapes)
        let records = parseRecords(log, convertedShapes)
        let actions = inferActions(records)
        validateActionMap(log.actionMapping, Array.from(actions.keys()))

        return { filename: log.file.name, records, actionToGdpr: log.actionMapping }
    } catch (error:any) {
        throw new Error(`Incomplete input for file ${log.file.name}: ${error.message}`)
    }
}

/* Validates that all shape inputs are filled in correctly */
export function validateShapeInputs(shapes:ShapeInput[]) : Shape[] {
    let convertedShapes = shapes.map( s => {
        try {
            return validateShapeInput(s)
        } catch (error:any) {
            throw new Error(`Error in shape "${s.name}":\n${error.message}`)
        }
    })
    return convertedShapes
}

/* Validates that a shape input is filled in correctly */
function validateShapeInput(shape:ShapeInput) : Shape {
    let { type, value } = shape.separatorInfo
    if (!value || !type) throw new Error(`Please specify a ${type}.`)
    
    let search = (a:AttributeCol, attr:GDPRAttr):boolean => {
        return a.attribute === attr 
            //|| 
            //(a.children !== undefined && a.children.reduce((acc, aa) => acc || search(aa, attr), false))
    }

    let attributes = shape.attributes
    const dsid = attributes.reduce((acc, a) => acc || search(a, 'dsid'), false)
    const dataid = attributes.reduce((acc, a) => acc || search(a, 'dataid'), false)
    const timestamp = attributes.reduce((acc, a) => acc || search(a, 'timestamp'), false)
    const action = attributes.reduce((acc, a) => acc || search(a, 'action'), false)
    if ( dsid === undefined ) throw new Error(`Please choose a column for dsid.`)
    if ( dataid === undefined) throw new Error(`Please choose a column for dataid.`)
    if ( timestamp === undefined) throw new Error(`Please choose a column for timestamp.`)
    if ( action === undefined) throw new Error(`Please choose a column for action.`)

    //if (!shape.timestampRegex) throw new Error(`Please specify the format of the timestamp as explained in the field.`) 
    if(shape.timestampRegex.length > 0) {
        new TimeFormat(shape.timestampRegex) // throws an Error if regex is invalid
    }

    let separatorInfo : SeparatorInfo =  { type, value }

    let r = { attributes, separatorInfo, timeformat: shape.timestampRegex, preserveCase: shape.preserveCase }

    return r
}

/* Validates that all actions are mapped in the action mapping */
function validateActionMap(actionsToGdpr:ActionsToGdpr, actions: string[]) {
    actions.forEach(a => {
        if (!actionsToGdpr[a]) 
        throw new Error(`Incomplete signature mapping: The action '${a}' is not present in signatures mapping. Apply the shapes to add it.`)
        let signatures = actionsToGdpr[a].gdprActions
        let missingData = signatures.find(a => ('data' in a.signature) && !a.signature.data ) // check if any gdpr action containing data fields are missing data
        if (missingData) 
            throw new Error(`GDPR signature '${missingData.signature.name}' requires a data type. Please fill out the data type field.`)
    })
}

/* Sets the number of parsed records on each shape - notice the shape ordering matters here */
export function setNumMatched(file:IFileData) {
    try {
        let content = file.content.trim().split("\n")
        let convertedShapes = file.shapes.map(s => {
            try {
                return validateShapeInput(s)
            } catch (error) {
                return undefined
            }
        })
        let unmatched : string[] = []
    
        file.shapes.forEach(s => s.matchedRecords = [])
            
        content.forEach((record:string, index) => {
            if (index === 0) return; // skip header
            let matched = false
            let i = 0
            convertedShapes.forEach((s, idx) => {
                if(s){
                    try {
                        if(!matched){
                            tryMatch(record, index, s, idx, file.id, file.file.name)
                            matched = true
                            file.shapes[i].matchedRecords!.push(record)
                        } 
                    } catch (error) {
                        matched = false
                    }
                }
                i++
            })
            if(!matched) unmatched.push(record)
        })
    
    } catch (error) {
        
    }

}

/* Parses a log with no shapes - i.e. to dummy records */
export function parseRecordsWithoutShape(log: IFileData) : LogRecord[] {

    const dummyDate = new Date()

    return log.content.trim().split("\n").map((record:string, index) => (
        {
            id: LogRecordId(log.file.name, index),
            filename: log.file.name,
            dsid : '',
            dataid : '',
            actions : [],
            timestamp : dummyDate,
            record: record,
            index,
            fileid: log.id,
            shapeid: -1
        }
    ))
}

/* Parses the records of a log to actions (string -> LogRecord[]) */
export function parseRecords(log: IFileData, shapes: Shape[]) {
    let records : LogRecord[] = [] 

    const date = new Date()
        
    log.content.trim().split("\n").forEach((record:string, index) => {
        if (index === 0){
            records.push({
                id: LogRecordId(log.file.name, index),
                filename: log.file.name,
                dsid : '',
                dataid : '',
                actions : [],
                timestamp : date,
                record: record,
                index,
                fileid: log.id,
                shapeid: -1
            })
        } else {
            try {
                let LogRecord = matchAny(record, index, shapes, log.file.name, log.id) // throws error if no matching shape
                records.push(LogRecord)
            } catch (error) {
                records.push({
                    id: LogRecordId(log.file.name, index),
                    filename: log.file.name,
                    dsid : '',
                    dataid : '',
                    actions : [],
                    timestamp : date,
                    record: record,
                    index,
                    fileid: log.id,
                    shapeid: -1
                })
            }
        }
    })

    return records
}

/* Collects all actions present in a list of LogRecords */
export function inferActions(records:LogRecord[]) {
    let logActions : Map<string, LogRecord[]> = new Map() 
    records.forEach(r => {
        r.actions.forEach(a => {
            if(a !== ''){
                if(logActions.has(a)){
                    logActions.get(a)!.push(r)
                } else{
                    logActions.set(a, [r])
                }
            }
        })
    })
    return logActions
}

/* Tries to match the record to any of the shapes */
export function matchAny(record:string, index: number, shapes : Shape[], filename: string, fileid: number) : LogRecord {
    let i = 0
    for(const shape of shapes){
        try { return tryMatch(record, index, shape, i, fileid, filename) }
        catch (e) { i++}
    }
    throw new Error(`No shape matching the record at line ${index}: '${record}'`)
}

/* Tries to match the record to the shape */
export function tryMatch(record:string, index: number, shape: Shape, shapeid:number, fileid: number, filename: string) : LogRecord {
    let { entry, err } = tryDivide(record, shape.separatorInfo) // test whether we can separate the record
    if (!entry)       throw new Error(err)

    let dsid = ''
    let dataid = ''
    let timestamp = ''
    let actions:Map<string, string> = new Map()

    const handleAttribute = (a:AttributeCol, r:string, si:SeparatorInfo, matchMultiple:boolean) => {
        let res = tryDivide(r, si, matchMultiple)
        const recordPieces = res.entry
        if (!recordPieces) throw new Error(res.err)
        if(a.column === undefined) throw new Error(`Column not defined in shape for attribute; ${a.attribute}.` )
        if(a.column > recordPieces.length) throw new Error(`Column out of range for attribute; ${a.attribute}.` )
        const piece = recordPieces[a.column]
        let matches:RegExpMatchArray|null = null
        if(a.regex){
            matches = piece.match(a.regex)
            
            if(!matches){
                throw new Error(`Shape does not match the action`)
            }
        }

        const addAction = (attr:AttributeCol, s:string) => {
            const id = attr.actionid ? attr.actionid : randomBytes(30).toString()
            if(actions.has(id)){
                actions.set(id, actions.get(id) + ' - ' + s)
            } else {
                actions.set(id, s)
            }
        }

        
        if(a.attribute === 'split'){
            if(a.children) {
                a.children.map(c =>
                    handleAttribute(c, piece, {type: 'regex', value: a.regex ? a.regex : ''}, a.matchMultiple || false))
            }
        } 
        else if(a.attribute === 'dsid') {
            if(matchMultiple) {
                recordPieces.forEach(m => {
                    dsid += m
                })
            } else {
                dsid += piece
            }
        }
        else if(a.attribute === 'dataid') {
            if(matchMultiple) {
                recordPieces.forEach(m => {
                    dataid += m
                })
            } else {
                dataid += piece
            }
        }
        else if(a.attribute === 'timestamp') {
            if(matchMultiple) {
                recordPieces.forEach(m => {
                    timestamp += m
                })
            } else {
                timestamp += piece
            }
        }
        else if(a.attribute === 'action'){
            if(matchMultiple) { 
                recordPieces.forEach(m => {
                    addAction(a, m)
                })
            } else {
                addAction(a, piece)
            }
        } 
    }

    shape.attributes.forEach(a => handleAttribute(a, record, shape.separatorInfo, a.matchMultiple || false))

    if(!dsid || !dataid || !timestamp || !actions.size){
        throw new Error(`Shape does not specify dsid, dataid, timestamp and action(s)`)
    }

    let date:Date;
    try {
        if(shape.timeformat.length === 0){
            date = new Date(timestamp)
            if(isNaN(date.getTime())){
                throw Error()
            }
        }
        else {
            const timeformatter = new TimeFormat(shape.timeformat)
            date = timeformatter.match(timestamp) // test whether timestamp matches timeformat
        }
    } catch (e) {
        throw new Error(`Timeformat "${shape.timeformat}" does not match the timestamp ${timestamp}`)
    }

    return {
        id: LogRecordId(filename, index),
        filename,
        dsid : dsid,
        dataid : dataid,
        actions : shape.preserveCase ? Array.from(actions.values()) : Array.from(actions.values()).map(a => a.toLowerCase()),
        timestamp : date,
        record,
        index,
        fileid: fileid,
        shapeid: shapeid
    }
}