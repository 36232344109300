import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal } from "rsuite";

/* Confirmation of e.g. delete of resources */

interface ConfirmationProps{
    id : number
    onAccept: (id:number) => void
    onClose: () => void
    show: boolean
    text: string
    t: (k:string) => string
}

class ConfirmationModal extends React.Component<ConfirmationProps>{

    render(){
        return(
        <Modal backdrop="static" role="alertdialog" show={this.props.show} onClose={this.props.onClose} size="xs">
            <Modal.Body>
                {this.props.text}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => {this.props.onAccept(this.props.id); this.props.onClose()}} appearance="primary">
                    {this.props.t('yes')}
                </Button>
                <Button onClick={this.props.onClose} appearance="subtle">
                    {this.props.t('no')}
                </Button>
            </Modal.Footer>

        </Modal>)
    }
}

export default withTranslation()(ConfirmationModal)