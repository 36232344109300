import { ReactElement } from 'react';
import { Modal, Button } from 'rsuite'

interface IModalProps {
    children:any, 
    title: string|ReactElement<any, any>,
    subTitle?: string|ReactElement<any, any>,
    titleSize?: string,
    show: boolean, 
    onClose: () => void,
    onAccept?: () => void,
    onShow?: () => void
    color?: string,
    full?: boolean
    buttons?: React.ReactElement[]
}
const MyModal = (props:IModalProps) => {
    return (
        <div className="modal-container">
            <Modal backdrop={true} show={props.show} onHide={props.onClose} full={props.full} onShow={props.onShow}>
                <Modal.Header>
                    <Modal.Title style={{
                        fontSize: props.titleSize?props.titleSize:'30px', 
                        color:props.color, 
                        textAlign: "center"
                    }}>
                        {props.title}
                    </Modal.Title>
                    <Modal.Title style={{
                        fontSize: props.titleSize?props.titleSize:'24px', 
                        color:props.color, 
                        textAlign: "center"
                    }}>
                        {props.subTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
                <Modal.Footer>
                    { props.buttons }
                    {
                        props.onAccept ? 
                        <Button onClick={props.onAccept} appearance="primary"> Ok </Button> :
                        ''
                    }
                    <Button onClick={props.onClose} appearance="subtle"> Cancel </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default MyModal