import React from 'react';
import '../common.css'
import { SelectPicker, Whisper, Form, FormControl, Tooltip } from 'rsuite';
import { delimiters, SeparatorInfo } from '../../models/shape';
import MyRow from '../helperComponents/MyRow'
import { WHISPER_DELAY } from '../../utils/utils';
import { withTranslation } from 'react-i18next';

/* The component used to define the separator of a shape */

interface IRegexProps {
    style?: React.CSSProperties,
    separatorInfo: SeparatorInfo,
    onChange: (data:SeparatorInfo) => void
    onBlur: () => void
    t: (s:string) => string
    tReady: boolean
}

const RegexComponent = (props : IRegexProps) => {
    let { type, value } = props.separatorInfo

    const onChangeRegexData = (input: SeparatorInfo) => {
        let { type, value } = props.separatorInfo
        if (type !== input.type || value !== input.value ) {props.onChange(input)}
    }

    return (
        <div>
            <Form 
                onCheck={props.onBlur} 
                checkTrigger={'blur'} 
            >

                <MyRow colRatio={[1,5]} style={props.style}>

                    <Whisper 
                        delay={WHISPER_DELAY} 
                        trigger="hover" 
                        placement={'top'}
                        speaker={
                            <Tooltip>
                                {props.t('delimeter_tooltip')}
                            </Tooltip>
                        }
                    >
                        <SelectPicker        
                            value={type}
                            placeholder={props.t('choose')+'...'}
                            data={ delimiters.map((v) => { return { name: v }})}
                            labelKey={"name"}
                            valueKey={"name"}
                            style={{width: "100%"}}
                            searchable={false}
                            onChange={(newType,_) => {
                                onChangeRegexData({type: newType, value})
                                props.onBlur()
                            }}
                        /> 
                    </Whisper>

                    <FormControl
                        name={'delimeter'} 
                        style={{width: '80%'}}
                        value={value}
                        placeholder={"Input " + type } 
                        onChange={(newValue,_) => onChangeRegexData({type, value: newValue})}
                    />

                </MyRow>
            </Form>
        </div>
    );
}

export default withTranslation()(RegexComponent);