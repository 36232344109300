import { AnalysisInfo, CommentInfo, CredentialsReq, EditCommentReq, EditInvitationReq, EditLogReq, EditMappingCommentReq, EditMappingReq, EditShapeReq, EditWorkspaceReq, FalsePositiveInfo, GetAnalysisRes, GetCommentsRes, GetInvitationRes, GetLogsRes, GetMappingCommentsRes, GetMappingsRes, GetShapesRes, GetWorkspaceRes, GetWorkspacesRes, IdResponse, MappingCommentInfo, PostAnalysisReq, PostCommentReq, PostFalsePositiveReq, PostLogsReq, PostMappingCommentReq, PostMappingsReq, PostShapesReq, PostWorkspaceReq, ShareWorkspaceReq, LinkRes, UnshareWorkspaceReq, UserInfo, WorkspaceInfo, PostSummaryReq, GetSummaryRes, EditAccessReq, EditAnalysisReq } from '../../../common/ClientServerInterface'

export interface RepoResponseNoPayload{
    success: boolean
    error_msg: string
}

export interface RepoResponse<T> extends RepoResponseNoPayload{
    payload: T | undefined
}

const url = process.env.NODE_ENV === 'production' ? '/api' : ''


/* This class handles the communication between the client and server - the api endpoints are expressed as functions here */
export class Repo{

    private static async handleResponse<T>(res:Response):Promise<RepoResponse<T>>{
        try {
            const json = await res.json()
            if(res.status >= 200 && res.status < 300) { 
                return {
                    payload: json,
                    success: true,
                    error_msg: ''
                }
            }
            else{
                return {
                    payload: undefined,
                    success: false,
                    error_msg: json.error_msg
                }
            }
        } catch (error) {
            return {
                payload: undefined,
                success: false,
                error_msg: 'Could not parse server response.'
            }   
        }
    }

    private static async handleResponseNoPayload(res:Response):Promise<RepoResponseNoPayload>{
        try {
            if(res.status >= 200 && res.status < 300) { 
                return {
                    success: true,
                    error_msg: ''
                }
            }
            else{
                const json = await res.json()
                return {
                    success: false,
                    error_msg: json.error_msg
                }
            }
        } catch (error) {
            return {
                success: false,
                error_msg: 'Could not parse server response.'
            }   
        }
    }

    private static async get<T>(endpoint:string):Promise<RepoResponse<T>>{
        const res = await fetch(url+endpoint)

        return this.handleResponse(res)
    }

    private static async getNoResponse(endpoint:string):Promise<RepoResponseNoPayload>{
        const res = await fetch(url+endpoint)

        return this.handleResponseNoPayload(res)
    }

    private static async send<T, A>(endpoint:string, payload:A, method:string):Promise<RepoResponse<T>>{
        const res = await fetch(url+endpoint, {
                method: method,
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json'
                }
        }) 

        return this.handleResponse(res)
    }

    private static async sendNoResponse<A>(endpoint:string, payload:A, method:string):Promise<RepoResponseNoPayload>{
        const res = await fetch(url+endpoint, {
                method: method,
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json'
                }
        })

        return this.handleResponseNoPayload(res)
    }

    private static async sendLarge<T, A>(endpoint:string, payload:A, method:string):Promise<RepoResponse<T>>{
        const res = await fetch(url+endpoint, {
                method: method,
                body: new Blob( [ JSON.stringify(payload) ], { type: 'text/plain' } ),
                headers: {
                    'Content-Type': 'application/json'
                }
        })

        return this.handleResponse(res)
    }

    private static async sendLargeNoResponse<A>(endpoint:string, payload:A, method:string):Promise<RepoResponseNoPayload>{
        const res = await fetch(url+endpoint, {
                method: method,
                body: new Blob( [ JSON.stringify(payload) ], { type: 'text/plain' } ),
                headers: {
                    'Content-Type': 'application/json'
                }
        })
        
        return this.handleResponseNoPayload(res)
    }

    public static async getWorkspaces():Promise<RepoResponse<GetWorkspacesRes>>{
        return await Repo.get("/workspaces")
    }

    public static async getWorkspace(workspace_id:number):Promise<RepoResponse<GetWorkspaceRes>>{
        return await Repo.get("/workspaces/"+workspace_id)
    }

    public static async getLogs(workspace_id:number):Promise<RepoResponse<GetLogsRes>>{
        return await Repo.get("/workspaces/"+workspace_id+"/logs")
    }

    public static async getShapes(workspace_id:number):Promise<RepoResponse<GetShapesRes>>{
        return await Repo.get("/workspaces/"+workspace_id+"/shapes")
    }

    public static async getMappings(workspace_id:number):Promise<RepoResponse<GetMappingsRes>>{
        return await Repo.get("/workspaces/"+workspace_id+"/mappings")
    }

    public static async getInvitations(workspace_id:number):Promise<RepoResponse<GetInvitationRes>>{
        return await Repo.get("/workspaces/"+workspace_id+"/invitations")
    }

    public static async isLoggedIn():Promise<RepoResponse<UserInfo>>{
        return await Repo.get("/checkToken")
    }

    public static async logOut():Promise<RepoResponseNoPayload>{
        return await Repo.getNoResponse("/logout")
    }

    public static async authenticate(credentials:CredentialsReq):Promise<RepoResponse<UserInfo>>{
        return await Repo.send("/authenticate", credentials, 'POST')
    }

    public static async register(credentials:CredentialsReq){
        return await Repo.sendNoResponse("/register", credentials, 'POST')
    }

    public static async addWorkspace(req:PostWorkspaceReq):Promise<RepoResponse<WorkspaceInfo>>{
        return await Repo.send("/workspaces", req, 'POST')
    }

    public static async addLog(workspace_id:number, req:PostLogsReq){
        return await Repo.sendLargeNoResponse("/workspaces/"+workspace_id+"/logs", req, 'POST')
    }

    public static async postMapping(workspace_id:number, mapping:PostMappingsReq):Promise<RepoResponse<IdResponse>>{
        return await Repo.send("/workspaces/"+workspace_id+"/mappings", mapping, 'POST')
    }

    public static async postShape(workspace_id:number, shape:PostShapesReq):Promise<RepoResponse<IdResponse>>{
        return await Repo.send("/workspaces/"+workspace_id+"/shapes", shape, 'POST')
    }

    public static async shareWorkspace(workspace_id:number, req:ShareWorkspaceReq):Promise<RepoResponse<LinkRes>>{
        return await Repo.send("/workspaces/"+workspace_id+"/invitations", req, 'POST')
    }

    public static async unshareWorkspace(workspace_id:number, req:UnshareWorkspaceReq){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/share", req, 'DELETE')
    }

    public static async removeLog(workspace_id:number, log_id:number){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/logs/"+log_id, {}, 'DELETE')
    }

    public static async removeShape(workspace_id:number, shape_id:number){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/shapes/"+shape_id, {}, 'DELETE')
    }

    public static async removeMapping(workspace_id:number, mapping_id:number){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/mappings/"+mapping_id, {}, 'DELETE')
    }

    public static async removeInvitation(workspace_id:number, invitation_id:number){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/invitations/"+invitation_id, {}, 'DELETE')
    }

    public static async removeWorkspace(workspace_id:number){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id, {}, 'DELETE')
    }

    public static async editLog(workspace_id:number, log_id:number, req:EditLogReq){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/logs/"+log_id, req, 'PUT')
    }

    public static async editShape(workspace_id:number, shape_id:number, req:EditShapeReq){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/shapes/"+shape_id, req, 'PUT')
    }

    public static async editMapping(workspace_id:number, mapping_id:number, req:EditMappingReq){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/mappings/"+mapping_id, req, 'PUT')
    }

    public static async editAnalysis(workspace_id:number, analysis_id:number, req:EditAnalysisReq){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/analysis/"+analysis_id, req, 'PUT')
    }

    public static async editInvitation(workspace_id:number, invitation_id:number, req:EditInvitationReq){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/invitations/"+invitation_id, req, 'PUT')
    }

    public static async editWorkspace(workspace_id:number, req:EditWorkspaceReq){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id, req, 'PUT')
    }

    public static async editAccess(workspace_id:number, req:EditAccessReq){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/share", req, 'PUT')
    }

    public static async useInvitation(link:string){
        return await Repo.getNoResponse("/invitation/"+link)
    }

    public static async postAnalysis(workspace_id:number, req:PostAnalysisReq):Promise<RepoResponse<IdResponse>>{
        return await Repo.sendLarge("/workspaces/"+workspace_id+"/analysis", req, 'POST')
    }

    public static async getAnalysis(workspace_id:number):Promise<RepoResponse<GetAnalysisRes>>{
        return await Repo.get("/workspaces/"+workspace_id+"/analysis")
    }

    public static async getOneAnalysis(workspace_id:number, analysis_id:number):Promise<RepoResponse<AnalysisInfo>>{
        return await Repo.get("/workspaces/"+workspace_id+"/analysis/"+analysis_id)
    }

    public static async removeAnalysis(workspace_id:number, analysis_id:number){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/analysis/"+analysis_id, {}, 'DELETE')
    }

    public static async getComments(workspace_id:number, analysis_id:number):Promise<RepoResponse<GetCommentsRes>>{
        return await Repo.get("/workspaces/"+workspace_id+"/analysis/"+analysis_id+"/comments")
    }

    public static async postComment(workspace_id:number, comment:PostCommentReq):Promise<RepoResponse<CommentInfo>>{
        return await Repo.send("/workspaces/"+workspace_id+"/comments", comment, 'POST')
    }

    public static async removeComment(workspace_id:number, comment_id:number){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/comments/"+comment_id, {}, 'DELETE')
    }

    public static async editComment(workspace_id:number, comment_id:number, req:EditCommentReq):Promise<RepoResponse<IdResponse>>{
        return await Repo.send("/workspaces/"+workspace_id+"/comments/"+comment_id, req, 'PUT')
    }

    public static async getMappingComments(workspace_id:number, mapping_id:number):Promise<RepoResponse<GetMappingCommentsRes>>{
        return await Repo.get("/workspaces/"+workspace_id+"/mapping/"+mapping_id+"/comments")
    }

    public static async postMappingComment(workspace_id:number, comment:PostMappingCommentReq):Promise<RepoResponse<MappingCommentInfo>>{
        return await Repo.send("/workspaces/"+workspace_id+"/mappingcomments", comment, 'POST')
    }

    public static async removeMappingComment(workspace_id:number, comment_id:number){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/mappingcomments/"+comment_id, {}, 'DELETE')
    }

    public static async editMappingComment(workspace_id:number, comment_id:number, req:EditMappingCommentReq):Promise<RepoResponse<IdResponse>>{
        return await Repo.send("/workspaces/"+workspace_id+"/mappingcomments/"+comment_id, req, 'PUT')
    }

    public static async setFalsePositive(workspace_id:number, fp:PostFalsePositiveReq){
        return await Repo.sendNoResponse("/workspaces/"+workspace_id+"/falsepositives", fp, 'POST')
    }

    public static async getFalsePositives(workspace_id:number, analysis_id:number):Promise<RepoResponse<FalsePositiveInfo[]>>{
        return await Repo.get("/workspaces/"+workspace_id+"/analysis/"+analysis_id+"/falsepositives")
    }

    public static async getSummary(link:string):Promise<RepoResponse<GetSummaryRes>>{
        return await Repo.get("/summaries/"+link)
    }

    public static async postSummary(req:PostSummaryReq):Promise<RepoResponse<LinkRes>>{
        return await Repo.send("/summaries", req, 'POST')
    }

}