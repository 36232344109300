import { Component } from "react";
import { Panel } from "rsuite";
import { withTranslation } from 'react-i18next';
import { getRuleFromList, RightToErasure2 } from "../../engine/rules";
import { SubmitResult } from "../../containers/AnalysisPage";
import { ShareWith } from "../../models/gdpr-signatures";
import { LogRecord } from "../../models/engine-types";

/* Shows which processors each data point has been shared with */

interface DataShareOverviewProps{
    analysisResult: SubmitResult | null
    tReady: boolean
    t: (k:string) => string
}

class DataShareOverview extends Component<DataShareOverviewProps>{

    
    render(){
        const dataShares:Map<string, Map<string, (ShareWith & LogRecord)[]>> = this.props.analysisResult !== null ? 
            (getRuleFromList(this.props.analysisResult.rules, 'Right to erasure 2') as RightToErasure2).getSharedData() :
            new Map()
        
        const sharedData = Array.from(dataShares.keys())

        return(
            sharedData.length <= 0 ? this.props.t('no_data_shares_overview') : 
            sharedData.map(data => {
                const sharedDataids = Array.from(dataShares.get(data)!.keys())
                return (
                    <Panel 
                        collapsible 
                        bordered 
                        style={{marginTop: 10}} 
                        header={data}
                    >
                        {sharedDataids.map(dataid => (
                            <>
                                {`${dataid} has been shared with the following processors:`}
                                <ul>
                                {Array.from(new Set(dataShares.get(data)!.get(dataid)!.map(r => r.processorid))).map(p => (
                                    <li>
                                        {p}
                                    </li>
                                    ))}
                                </ul>
                            </>
                        ))}
                    </Panel>

                )}
        ))
    }
}

export default withTranslation()(DataShareOverview)