import React from 'react';
import {  Panel, Button, Alert, Input, ButtonGroup, ButtonToolbar, Uploader, } from 'rsuite';
import { SelectShapeMappingData, ShapeMappingSelector } from './ShapePanel';
import ActionTable from './ActionMappingTable';
import { IFileData } from '../../models/file';
import { ActionsToGdpr } from '../../models/engine-types';
import MyModal from '../helperComponents/MyModal';
import { Repo } from '../../repositories/Repo';
import { MappingCommentInfo, MappingInfo } from '../../../../common/ClientServerInterface';
import { withTranslation } from 'react-i18next';
import { downloadCSV, ERROR_TIME, formatDate, mappingFromJSON } from '../../utils/utils';
import { FileType } from 'rsuite/lib/Uploader';
import { csvToMapping, mappingToCSV } from '../../models/gdpr-signatures';
import { TutorialTooltip } from '../helperComponents/TutorialTooltip';

/* Shows the view used to create an action mapping using the action mapping table */

interface ActionMappingPanelProps {
    fdata: IFileData,
    workspaceId: number
    userId: number
    showLoginModal: (f?:() => void) => void
    applyShapes: (f:IFileData, keepExpanded?: boolean, ignoreError?:boolean) => void
    updateMappings: (o:number, n:number, c:ActionsToGdpr) => void
    getMappings: () => void
    mappings: MappingInfo[]
    demoStep?: number
    t: (k:string) => string
    tReady: boolean
}

interface ActionMappingPanelState {
    showSaveModal: boolean
    showLoadModal:boolean
    showTransferCommentsModal: boolean
    mappingName: string
    actionMapping: ActionsToGdpr
}

class ActionMappingPanel extends React.Component<ActionMappingPanelProps, ActionMappingPanelState>{

    constructor(props:ActionMappingPanelProps){
        super(props)
        this.state = {
            showLoadModal: false,
            showSaveModal: false,
            showTransferCommentsModal: false,
            mappingName: '',
            actionMapping: props.fdata.actionMapping
        }
    }

    rerender(){
        this.forceUpdate()
    }

    async getComments(mappingId:number){
        await Repo.getMappingComments(this.props.workspaceId, mappingId)
        .then(res => {
            if(res.success){
                this.props.fdata.mappingComments = res.payload!
                this.rerender()            
            } else{
                this.props.fdata.mappingComments = []
                this.rerender() 
                Alert.error(this.props.t('get_comments_error') + res.error_msg, ERROR_TIME)
            }
        })
    }

    async addComment(action:string, text:string){
        return Repo.postMappingComment(this.props.workspaceId, {text: text, actionMappingId: this.props.fdata.actionMappingid, action: action})
        .then(res => {
            if(res.success){
                this.props.fdata.mappingComments ? 
                    this.props.fdata.mappingComments.push(res.payload!) : 
                    this.props.fdata.mappingComments = [res.payload!]
                    this.rerender()
                return true
            } else{
                Alert.error(this.props.t('add_comment_error') + res.error_msg, ERROR_TIME)
                return false
            }
        })
    }

    removeComment(comment_id:number){
        Repo.removeMappingComment(this.props.workspaceId, comment_id)
        .then(res => {
            if(res.success){
                this.props.fdata.mappingComments = this.props.fdata.mappingComments ? 
                    this.props.fdata.mappingComments.filter(c => c.id !== comment_id) : 
                    []
                this.rerender()
            } else {
                Alert.error(this.props.t('remove_comment_error') + res.error_msg, ERROR_TIME)
            }
        })
    }

    editComment(comment_id:number, text:string){
        Repo.editMappingComment(this.props.workspaceId, comment_id, {text: text})
        .then(res => {
            if(res.success){
                const com = this.props.fdata.mappingComments?.find(c => c.id === comment_id)
                if(com){
                    let coms = this.props.fdata.mappingComments!.filter(c => c.id !== comment_id)
                    const newCom:MappingCommentInfo = {
                        text: text, 
                        id: res.payload!.id, 
                        actionMappingId: com.actionMappingId, 
                        uploadedAt: com.uploadedAt,
                        uploadedBy: com.uploadedBy,
                        uploadedByUsername: com.uploadedByUsername,
                        editedAt: new Date(Date.now()),
                        action: com.action,
                        removed: false,
                        history: [...com.history, com]
                    }
                    coms.push(newCom)
                    coms.sort((a,b) => new Date(a.uploadedAt).getTime() - new Date(b.uploadedAt).getTime())
                    this.props.fdata.mappingComments = coms
                    this.rerender()                }
            } else {
                Alert.error(this.props.t('edit_comment_error') + res.error_msg, ERROR_TIME)
            }
        })
    }

    async overwrite(mapping:SelectShapeMappingData){
        const new_id = await this.saveMapping(mapping.name, mapping.id)
        if(new_id){
            this.props.updateMappings(mapping.id, new_id, this.props.fdata.actionMapping)
            this.props.fdata.actionMappingid = new_id
            Repo.removeMapping(this.props.workspaceId, mapping.id)
            this.props.getMappings()
        }
    }

    onChangeActionsMap = (actionToGdpr: ActionsToGdpr) => {
        this.props.fdata.actionMapping = actionToGdpr
        this.props.fdata.mappingChanges = true
        this.setState({actionMapping: actionToGdpr})
    }

    clearUnusedActions = () => {
        for(const a in this.props.fdata.actionMapping){
            const recs = this.props.fdata.actions.get(a)
            if(!recs || recs?.length <= 0){
                this.props.fdata.mappingChanges = true
                let newActions = {...this.props.fdata.actionMapping}
                delete newActions[a]
                this.onChangeActionsMap(newActions)    
            }
        }
    }

    saveMapping = async (mapping_name: string, overwrite?: number) => {
        return Repo.postMapping(this.props.workspaceId, {
            mapping_name: mapping_name, 
            content: JSON.stringify(this.props.fdata.actionMapping),
            transferComments: overwrite
        })
        .then(res => {
            if (res.success) {
                this.props.fdata.actionMappingid = res.payload!.id
                Alert.info(this.props.t('mapping_save_success'), 10000)
                if(!overwrite) {
                    this.props.fdata.mappingComments = []
                }
                this.setState({mappingName: ''})           
                this.props.getMappings()
                return res.payload!.id
            } else {
                Repo.isLoggedIn().then(res => {
                    if(res.success)
                        Alert.error(this.props.t('mapping_save_error') + res.error_msg, ERROR_TIME)
                    else
                        this.props.showLoginModal(() => this.saveMapping(mapping_name))
                })
                return 0
            }
        })

    }

    loadMapping = async (mapping_id: number, mapping_content: string) => {
        const json = mappingFromJSON(mapping_content)
        for(const a in this.props.fdata.actionMapping){
            this.props.fdata.actionMapping[a] = json[a] ? json[a] : {gdprActions: [], description: ''}
        }
        for(const a in json){
            if(!this.props.fdata.actionMapping[a]) this.props.fdata.actionMapping[a] = json[a]
        }
        this.props.fdata.actionMappingid = mapping_id
        await this.getComments(mapping_id)
        this.rerender()
    }

    exportMappingToCSV(){
        const csvContent = mappingToCSV(this.props.fdata.actionMapping)

        downloadCSV(csvContent, 'gdpr_mapping.csv')
    }

    async importMappingFromCSV(file: FileType){
        if(!file.blobFile){
            Alert.error(this.props.t('import_mapping_error'), ERROR_TIME)
            return
        }
        try {
            const csv = await file.blobFile!.text()
            const mapping = csvToMapping(csv)
            for(const a in this.props.fdata.actionMapping){
                if(!mapping[a]){
                    mapping[a] = this.props.fdata.actionMapping[a]
                }
            }
            this.onChangeActionsMap(mapping)
            Alert.info(this.props.t('import_mapping_succes'))
        } catch (error) {
            Alert.error(this.props.t('import_mapping_error'), ERROR_TIME)
        }
    }

    render(){
        const mappings: SelectShapeMappingData[] = this.props.mappings.map((m) => ({
            name: m.name, 
            content: m.content, 
            info: `${this.props.t('created_at')} ${formatDate(new Date(m.pub_date))}`,
            id: m.id
        }))

        return (
            <>
                {Object.entries(this.props.fdata.actionMapping).length > 0 ? 
                    <Panel style={{padding: 0, marginTop: 30, width: "95%"}} bordered header={
                        <>
                            Action Mapping
                            <TutorialTooltip 
                                active={this.props.demoStep === 1 || this.props.demoStep === 5} 
                                text={this.props.t('tutorial_mapping')}
                            />
                            <ButtonGroup  style={{float: "right"}}>
                                <Button 
                                    disabled={this.props.workspaceId === 0} 
                                    style={{border: '1px solid lightGrey'}} 
                                    onClick={e => this.setState({showLoadModal: true})}
                                > 
                                    {this.props.t('load_mapping')}
                                </Button>

                                <Button 
                                    disabled={this.props.workspaceId === 0} 
                                    style={{border: '1px solid lightGrey'}} 
                                    onClick={e => this.setState({showSaveModal: true})}
                                > 
                                    {this.props.t('save_mapping')}
                                </Button>

                                <Button 
                                    style={{border: '1px solid lightGrey'}} 
                                    onClick={e => this.exportMappingToCSV()}
                                >
                                    {this.props.t('export')}
                                </Button>

                                <Button 
                                    style={{border: '1px solid lightGrey'}} 
                                    onClick={e => this.clearUnusedActions()}
                                >
                                    {this.props.t('remove_unused_actions')}
                                </Button>

                            </ButtonGroup>
                        </>
                    }>

                        <ActionTable 
                            fdata={this.props.fdata} 
                            onChange={this.onChangeActionsMap} 
                            workspaceId={this.props.workspaceId}
                            userId={this.props.userId}
                            addComment={this.addComment.bind(this)}
                            removeComment={this.removeComment.bind(this)}
                            editComment={this.editComment.bind(this)}
                        />


                    </Panel>
                    : ''}
                <Panel style={{minHeight: 300}}/>
                    
                <MyModal 
                    title={this.props.t('load_mapping')}
                    show={this.state.showLoadModal}
                    onClose={() => this.setState({showLoadModal: false})}
                    full
                >
                    <h4>Workspace mappings:</h4>
                    <ShapeMappingSelector
                        onSelect={(mapping:SelectShapeMappingData) => 
                            {this.loadMapping(mapping.id, mapping.content); this.setState({showLoadModal: false})}}
                        data={mappings}
                        actionName={this.props.t('load')}
                    />
                    <h4>{this.props.t('import_mapping')}</h4>
                    <Uploader 
                        onUpload={file => {this.importMappingFromCSV(file); this.setState({showLoadModal: false})}}
                        accept='.csv'
                        multiple={false}
                        draggable
                    >
                        <div style={{lineHeight: '150px'}}>{this.props.t('mapping_upload_description')}</div>
                    </Uploader>
                </MyModal>

                <MyModal 
                    title={this.props.t('save_mapping')}
                    show={this.state.showSaveModal} 
                    onClose={() => this.setState({showSaveModal: false})} 
                    full
                >
                    <h4>Workspace mappings:</h4>
                    <ShapeMappingSelector
                        onSelect={(mapping:SelectShapeMappingData) => 
                            {this.overwrite(mapping); this.setState({showSaveModal: false})}}
                        data={mappings}
                        actionName={this.props.t('overwrite')}
                    />
                    <h4 style={{fontSize: 20}}>{this.props.t('save_new_mapping')}</h4>
                    <ButtonToolbar style={{display: 'flex'}}>
                        <Input 
                            value={this.state.mappingName}
                            placeholder={this.props.t('enter_mapping_name')} 
                            onChange={(newMappingName, ev) => this.setState({mappingName: newMappingName})}
                            style={{width: '40%'}}
                        />
                        <Button 
                            appearance='ghost' 
                            onClick={() => { 
                                if(this.props.fdata.actionMappingid > 0) {this.setState({showTransferCommentsModal: true})} 
                                else this.saveMapping(this.state.mappingName); this.setState({showSaveModal: false})}
                            }
                        >
                            {this.props.t('save_mapping')}
                        </Button>
                    </ButtonToolbar>
                </MyModal>

                <MyModal 
                    title={this.props.t('save_mapping')}
                    show={this.state.showTransferCommentsModal} 
                    onClose={() => this.setState({showTransferCommentsModal: false})} 
                    buttons={[
                        <Button 
                            appearance='ghost' 
                            onClick={() => {
                                this.saveMapping(this.state.mappingName, this.props.fdata.actionMappingid)
                                this.setState({showTransferCommentsModal: false})}
                            }
                        >
                            {this.props.t('yes')}
                        </Button>,
                        <Button 
                            appearance='ghost' 
                            onClick={() => {
                                this.saveMapping(this.state.mappingName)
                                this.setState({showTransferCommentsModal: false})}
                            }
                        >
                            {this.props.t('no')}
                        </Button>
                    ]}
                >
                    {this.props.t('transfer_mapping_comments')}
                </MyModal>
            </>

        )
    }
}

export default withTranslation()(ActionMappingPanel)