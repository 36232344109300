import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonToolbar, FlexboxGrid, Form, FormGroup, Panel } from 'rsuite';
import { CredentialsReq } from '../../../common/ClientServerInterface';
import { Repo } from '../repositories/Repo';
import { withTranslation } from 'react-i18next';

/* Page to register as a new user */

interface RegisterPageProps {
  login: (body:CredentialsReq) => Promise<void>
  t: (k:string) => string
  tReady: boolean
  stopRedirect?: boolean
}

interface RegisterPageState {
    username:string
    password:string
}

class RegisterPage extends Component<RegisterPageProps, RegisterPageState> {

  constructor(props:RegisterPageProps) {
    super(props)
    this.state = {
      username : '',
      password: ''
    }
  }

  handleInputChange = (event:any) => {
    const { value, name } = event.target;
    if(name === 'username'){
      this.setState({
        username: value
      })
    } else {
      this.setState({
        password: value
      })
    }
  }

  async onSubmit(event:any){
    event.preventDefault();
    const cred:CredentialsReq = this.state
    Repo.register(cred)
    .then(res => {
      if (res.success) {
        this.props.login(cred)
      } else {
        alert(this.props.t('register_error'));
      }
    })
  }

  render() {
    return (
      <div style={{width: '100%'}}>
        <FlexboxGrid justify="center" style={{padding: 100}}>
          <FlexboxGrid.Item colspan={8}>
        <Panel header={<h3>{this.props.t('register')}</h3>} bordered>
        <Form fluid>
          <FormGroup>
            <div className="input-group">
                    <label htmlFor="username">
                      {this.props.t('username')}
                    </label>
                    <input
                      type="text"
                      name="username"
                      className="login-input"
                      placeholder={this.props.t('enter_username')}
                      value={this.state.username}
                      onChange={this.handleInputChange}
                    />
                </div>
          </FormGroup>
          <FormGroup>
            <div className="input-group">
                    <label htmlFor="password">
                      {this.props.t('password')}
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="login-input"
                      placeholder={this.props.t('enter_password')}
                      value={this.state.password}
                      onChange={this.handleInputChange}
                    />
                </div>
          </FormGroup>
          <FormGroup>
            <ButtonToolbar>
              <Button 
                appearance="primary" 
                onClick={this.onSubmit.bind(this)}
              >
                {this.props.t('sign_up')}
              </Button>
              {this.props.stopRedirect ? '' :
                <Button 
                  appearance="link" 
                  componentClass={Link} 
                  to="/login"
                >
                    {this.props.t('already_registered')}?
                  </Button>
              }
            </ButtonToolbar>
          </FormGroup>
        </Form>
      </Panel>
      </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
    );
  }
}

export default withTranslation()(RegisterPage)