import React, { Component } from "react";
import { withRouter } from "react-router";
import { Redirect, RouteComponentProps } from "react-router-dom";

/* Handles invitation links - joins the workspace if possible and redirects */

interface ParamWithLink {link:string}

interface InvitationHandlerProps extends RouteComponentProps{
    setInvitation: (p:string) => void
    isLoggedIn: boolean
    joinWorkspace: (l:string) => void
}

class InvitationHandler extends Component<InvitationHandlerProps>{

    componentDidMount() {
        const id = (this.props.match.params as ParamWithLink).link;
        if(this.props.isLoggedIn) {
            this.props.joinWorkspace(id)
        }
        else {this.props.setInvitation(id)}
    }

    render(){
        return this.props.isLoggedIn ? <Redirect to='/workspaces'/> : <Redirect to='/login'/>
    }
}

export default withRouter(InvitationHandler)