import React from 'react';
import RegexComponent from '../shapePanelComponents/Regex';
import GDPRAttributeColumnSelector from '../shapePanelComponents/ColumnSelector';
import { Button, Table, ButtonGroup, Checkbox } from 'rsuite';
import { tryDivide } from '../../utils/utils';
import { ShapeInput, SeparatorInfo, GDPRAttr } from '../../models/shape';
import { ShapeInfo } from '../../../../common/ClientServerInterface';
import { withTranslation } from 'react-i18next';
import { IFileData } from '../../models/file';

/* Panel containing the components used to define a shape */

interface ShapePanelProps {
    fdata: IFileData,
    idx: number,
    onChange: (fileData:ShapeInput) => void,
    shapeInput: ShapeInput,
    workspaceId: number
    showLoginModal: (f:()=>void) => void
    showSaveModal: (shape_id:number) => void
    showExportModal: (shape_id:number) => void
    getShapes: () => void
    onBlur: () => void
    shapes: ShapeInfo[]
    applyShapes: () => void
    updateShapes: (o:number, n:number, c:ShapeInput) => void
    recordExample: string
    t: (k:string) => string
    tReady: boolean
}

interface IShapePanelState { 
    shape: ShapeInput
  }

class ShapePanel extends React.Component<ShapePanelProps, IShapePanelState> {
    constructor(props: ShapePanelProps) {
        super(props);
        this.state = {
            shape: props.shapeInput
        };
    }

    onRemoveAttribute = (idx:number[]) => {
        if(idx.length === 0) return
        if(idx.length === 1) this.props.shapeInput.attributes = this.props.shapeInput.attributes.filter((c, i) => i !== idx[0])
        else {
            let attr = this.props.shapeInput.attributes[idx[0]]
            let i = 1
            while(i < idx.length-1){
                if(attr.children === undefined) throw Error()
                attr = attr.children[idx[i]]
            }
            attr.children = attr.children?.filter((c, i) => i !== idx[idx.length-1])
        }
        this.props.shapeInput.changes = true
        this.setState({shape: this.props.shapeInput})
    }

    onAddAttribute = (attr:GDPRAttr, idx:number[]) => {
        const newAttr = {column: undefined, attribute: attr}
        if(idx.length <= 0){
            this.props.shapeInput.attributes.push(newAttr)
        }
        else if(idx.length === 1){
            const a = this.props.shapeInput.attributes.filter((c, i) => i === idx[0])
            if(a.length){
                if(a[0].children){
                    a[0].children.push(newAttr)
                } else {
                    a[0].children = [newAttr]
                }
            }
        }
        else {
            let a = this.props.shapeInput.attributes[idx[0]]
            let i = 1
            while(i < idx.length-1){
                if(a.children === undefined) throw Error()
                a = a.children[idx[i]]
            }
            if(a.children){
                a.children.push(newAttr)
            } else {
                a.children = [newAttr]
            }
        }
        this.props.shapeInput.changes = true
        this.setState({shape: this.props.shapeInput})
    }

    onAttributeSelect = (idx:number[], gdprAttr:GDPRAttr, column?:number) => {
        if(idx.length === 0) return
        if(idx.length === 1){
            if(column === undefined){
                this.props.shapeInput.attributes[idx[0]].column = undefined
            }
            else{
                this.props.shapeInput.attributes[idx[0]] = {column: column, attribute: gdprAttr}
            }
        }
        else {
            let attr = this.props.shapeInput.attributes[idx[0]]
            let i = 1
            while(i < idx.length-1){
                if(attr.children === undefined) throw Error()
                attr = attr.children[idx[i]]
            }

            if(attr.children){
                if(column === undefined){
                    attr.children[idx[idx.length-1]].column = undefined
                }
                else{
                    attr.children[idx[idx.length-1]] = {column: column, attribute: gdprAttr}
                }
            }
        }

        this.props.shapeInput.changes = true
        this.setState({shape: this.props.shapeInput})
    }

    onAttributeRegexChange = (idx:number[], regex:string) => {
        if(idx.length === 0) return
        if(idx.length === 1) {
            this.props.shapeInput.attributes[idx[0]] = {...this.props.shapeInput.attributes[idx[0]], regex}
        } else {
            let attr = this.props.shapeInput.attributes[idx[0]]
            let i = 1
            while(i < idx.length-1){
                if(attr.children === undefined) throw Error()
                attr = attr.children[idx[i]]
            }
            if(attr.children){
                attr.children[idx[idx.length-1]] = {...attr.children[idx[idx.length-1]], regex}
            }
        }
        this.props.shapeInput.changes = true
        this.setState({shape: this.props.shapeInput})
    }

    onActionIdChange = (idx:number[], actionid:string) => {
        if(idx.length === 0) return
        if(idx.length === 1) {
            this.props.shapeInput.attributes[idx[0]] = {...this.props.shapeInput.attributes[idx[0]], actionid}
        } else {
            let attr = this.props.shapeInput.attributes[idx[0]]
            let i = 1
            while(i < idx.length-1){
                if(attr.children === undefined) throw Error()
                attr = attr.children[idx[i]]
            }
            if(attr.children){
                attr.children[idx[idx.length-1]] = {...attr.children[idx[idx.length-1]], actionid}
            }
        }
        this.props.shapeInput.changes = true
        this.setState({shape: this.props.shapeInput})
    }

    onChangeTimestampRegex = (regex: string) => {
        if (regex === this.props.shapeInput.timestampRegex) return;   // Nothing has changed
        this.props.shapeInput.timestampRegex = regex
        this.props.shapeInput.changes = true
        this.setState({shape: this.props.shapeInput})
    }

    getRecordPieces = () => {
        let { type, value } = this.props.shapeInput.separatorInfo
        if (!(value && type)) return []
        let { entry } = tryDivide(this.props.recordExample, this.props.shapeInput.separatorInfo)
        if (!entry) return []
        return entry
    }

    render() {
        let eventShape = this.props.shapeInput
        let componentMargin = { margin: "30px 0"}
        let recordPieces = this.getRecordPieces()

        return (
            <div
                style={{width:"100%"}}
            >
                <RegexComponent
                    {...eventShape} 
                    onChange={(si:SeparatorInfo) => {
                        this.props.shapeInput.separatorInfo = si
                        this.setState({shape: this.props.shapeInput})
                    }}
                    onBlur={this.props.onBlur}
                />

                <GDPRAttributeColumnSelector 
                    style={componentMargin} 
                    recordPieces={recordPieces} 
                    {...eventShape} 
                    onAttributeSelect={this.onAttributeSelect.bind(this)} 
                    onAttributeRegexChange={this.onAttributeRegexChange.bind(this)}
                    onActionIdChange={this.onActionIdChange.bind(this)}
                    onTimestampRegexChange={this.onChangeTimestampRegex.bind(this)}
                    onAddAttribute={this.onAddAttribute.bind(this)}
                    onRemoveColumn={this.onRemoveAttribute.bind(this)}
                    onBlur={this.props.onBlur}
                />

                <Checkbox 
                    onClick={() => {
                        this.props.shapeInput.preserveCase=!this.props.shapeInput.preserveCase
                        this.setState({shape: this.props.shapeInput})
                        this.props.onBlur()
                    }} 
                    checked={this.props.shapeInput.preserveCase}
                    style={{marginTop: 20}}
                    
                >
                    Preserve case of actions
                </Checkbox>

                {
                    'Matched records: ' + 
                    (this.props.shapeInput.matchedRecords ? this.props.shapeInput.matchedRecords.length : 0)
                }

                <ButtonGroup style={{display: 'flex', marginTop: 20}}>
                    <Button 
                        disabled={this.props.workspaceId === 0} 
                        onClick={e => this.props.showSaveModal(this.props.idx)}
                        style={{border: '1px solid lightGrey', width: '50%'}}
                    > 
                        {this.props.t('save_shape')}
                    </Button>

                    <Button 
                        onClick={e => this.props.showExportModal(this.props.idx)}
                        style={{border: '1px solid lightGrey', width: '50%'}}
                    > 
                        {this.props.t('export_to_json')}
                    </Button>
                </ButtonGroup>
            </div>
        );
    }
}

export type SelectShapeMappingData = { 
    name: string
    content: string
    info: string
    id: number
}

interface IShapeSelectorProps<T> {
    style?: React.CSSProperties,
    onSelect: (item:T) => void,
    data: T[],
    actionName: string
}

export const ShapeMappingSelector = (props: IShapeSelectorProps<SelectShapeMappingData>) => (
    <Table height={400} data={props.data} id="table" hover>
        <Table.Column width={300}>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell>
                {(rowData:SelectShapeMappingData) => 
                    rowData.name
                }
            </Table.Cell>
        </Table.Column>

        <Table.Column width={300}>
            <Table.HeaderCell>Info</Table.HeaderCell>
            <Table.Cell>
                {(rowData:SelectShapeMappingData) => 
                    rowData.info
                }
            </Table.Cell>
        </Table.Column>

        <Table.Column width={100} fixed='right'>
            <Table.HeaderCell>Actions</Table.HeaderCell>
            <Table.Cell>
                {(rowData:SelectShapeMappingData) => 
                    <Button appearance='link' onClick={() => props.onSelect(rowData)} style={{marginTop: -10}}>
                        {props.actionName}
                    </Button>
                }
            </Table.Cell>
        </Table.Column>
    </Table>
)

export default withTranslation()(ShapePanel)
