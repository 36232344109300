import React from "react";
import { Button, ButtonToolbar, Icon, IconButton, Panel } from "rsuite";
import { AccessRole, ShareUserInfo, WorkspaceInfo } from "../../../../common/ClientServerInterface";
import { withTranslation } from 'react-i18next';
import ConfirmationModal from "../helperComponents/ConfirmationModal";

/* Renders a list of users */

interface UserListState{
    showDeleteModal: boolean
}

interface UserListProps{
    data: ShareUserInfo[]
    saveName: (id:number, name:string) => Promise<void>
    remove: (id:number) => Promise<void>
    setAccess: (u:ShareUserInfo, a:AccessRole) => Promise<void>
    emptyMessage: string
    deletable: boolean
    workspace: WorkspaceInfo
    t: (k:string) => string
    tReady: boolean
}

class UserList extends React.Component<UserListProps, UserListState>{

    constructor(props:UserListProps){
        super(props)
        this.state = {showDeleteModal: false}
    }

    render(){
        return(

            this.props.data.length === 0 ? <p>{this.props.emptyMessage}</p> :
                this.props.data.map((u) => {
    
                    return (
                        <Panel bordered style={{marginTop: 10}}>
                                <h4>{u.username}</h4>
                            <ButtonToolbar style={{float: 'right', marginTop: 0}}>

                                {
                                    u.access !== 'Full' ? 
                                    <Button 
                                        appearance='ghost' 
                                        style={{ marginLeft: 10}} 
                                        onClick={() => this.props.setAccess(u, 'Full')}
                                    >
                                        {this.props.t('give_full_access')}
                                    </Button> 
                                    : 
                                    ''                        
                                }

                                {
                                    u.access !== 'Standard' ? 
                                    <Button 
                                        appearance='ghost' 
                                        style={{ marginLeft: 10 }} 
                                        onClick={() => this.props.setAccess(u, 'Standard')}
                                    >
                                        {this.props.t('give_standard_access')}
                                    </Button> 
                                    :
                                    ''                        
                                }

                                {
                                    u.access !== 'Upload' ? 
                                    <Button 
                                        appearance='ghost' 
                                        style={{ marginLeft: 10 }} 
                                        onClick={() => this.props.setAccess(u, 'Upload')}
                                    >
                                        {this.props.t('give_upload_access')}
                                    </Button> 
                                    : 
                                    ''                        
                                }
                                
                                {this.props.deletable ? 
                                    <IconButton 
                                        icon={<Icon icon="warning" />} 
                                        placement='right' 
                                        appearance='primary' 
                                        style={{ marginLeft: 10}} 
                                        onClick={() => this.setState({showDeleteModal: true})}
                                    >
                                        {this.props.t('remove')}
                                    </IconButton> :
                                    ''
                                }

                            </ButtonToolbar>

                            <ButtonToolbar style={{ display: "flex", marginTop: 20}}>
                                <p>
                                    {this.props.t('user_has') + ' ' + u.access + ' ' + 
                                        this.props.t('access_to_the_workspace')}
                                </p>
                            </ButtonToolbar>

                            <ConfirmationModal
                                onClose={() => this.setState({showDeleteModal: false})}
                                text={this.props.t('remove_user_access')}
                                show={this.state.showDeleteModal}
                                id={u.id}
                                onAccept={(id:number) => this.props.remove(id)}
                            />
                        </Panel>
                    );
                })
        )}
}

export default withTranslation()(UserList)