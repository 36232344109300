import { Collect, Processing, Delete, DsConsent, LegalGrounds, DsRevoke, DsAccess, DsAccessRequest, DsDelete, DsObject, DsRestrict, DsRepeal, ShareWith, NotifyProc, description, Inform, EndOfCase } from "../models/gdpr-signatures";
import { TransformedLogRecord, LogRecord, Violation, newViolationEntry} from "../models/engine-types";
import { addToItems } from "../utils/utils";



/**
 * This file contains the implementation of the GDPR articles
 * A GDPRRule takes in a list of transformed log records and checks whether the gdpr article has been violated. 
 * One GDPRRule for each gdpr article. 
 */

export type AnalysisDateOption = 'Ignore' | 'None' | 'Date'

export interface AnalysisParameters{
    dateOption: AnalysisDateOption
    date?: Date
}

export type GDPRRule = {
    name: string
    article: string
    description: string
    step(record: TransformedLogRecord) : void
    stepMany(records: TransformedLogRecord[]): void
    getErrors(params?:AnalysisParameters): TransformedLogRecord[]
    getViolations(params?:AnalysisParameters): Violation[]
}

export class DataMinimisation implements GDPRRule {
    name = "Data minimisation"
    article = "5(1c)"
    description = "The collected data was eventually processed"

    unprocessedCollects : Map<string, (Collect & LogRecord)[]> = new Map()
    cases : Map<string, TransformedLogRecord[]> = new Map()
    
    identifier ({ name, dsid, data, dataid } : Collect | Processing) {
        return `${dsid}${data}${dataid}`
    }

    step (record: TransformedLogRecord) {
        if (record.name === "Collect" || record.name === "Processing") { 
            let identifier = this.identifier({...record})
            addToItems(identifier, record, this.cases)

            if (record.name === "Collect")
                addToItems(identifier, record, this.unprocessedCollects)

            else if (record.name === "Processing")
                this.unprocessedCollects.delete(identifier)
        }
    }

    stepMany(records: TransformedLogRecord[]) { records.forEach( ar => this.step(ar) )}

    // getErrors () { return [...this.collects.values()].flatMap(cs => cs) }    // if we care about multiple collects of the same data, i.e. each collect is an error 
    getErrors () { return [...this.unprocessedCollects.values()].map(cs => cs[0]) }        // we don't care about multiple collects of the same data, i.e. it still just counts as 1 error

    getViolations() { 
        return  Array.from(this.unprocessedCollects).map(([identifier, cs]) => {
            let e = cs[0]
            let v = [{
                e,
                desc: `Found ${cs.length} Collect of data point (data: ${e.data}, id: ${e.dataid}, dsid: ${e.dsid}) without subsequent processing.`,
                flag: true
            }, ...this.cases.get(identifier)!.map(t => ({
                e: t, 
                desc: description(t),
                flag: cs.some(c => c.id === t.id)
            }))]
            return v
        })
    }
}

export class StorageLimitation implements GDPRRule {
    name = "Storage limitation"
    article = "5(1e)"
    description = "The collected data was eventually deleted"

    collects : Map<string, (Collect & LogRecord)[]> = new Map()
    cases : Map<string, TransformedLogRecord[]> = new Map()

    identifier ({ name, dsid, data, dataid } : Collect | Delete) {
        return `${dsid}${data}${dataid}`
    }

    isFlagged = (t: TransformedLogRecord) => false

    step (record: TransformedLogRecord) {
        if (record.name === "Collect" || record.name === "Delete") { 
            let identifier = this.identifier({...record})
            addToItems(identifier, record, this.cases)

            if (record.name === "Collect")
                addToItems(identifier, record, this.collects)

            else if (record.name === "Delete")
                this.collects.delete(identifier)
        }
    }
    stepMany(records: TransformedLogRecord[]) { records.forEach( ar => this.step(ar) )}

    getErrors () { return Array.from(this.collects.values()).flatMap(cs => cs) }

    getViolations() { 
        return  Array.from(this.collects).map(([identifier, cs]) => {
            let e = cs[0]
            let v = [{
                e,
                desc:`Found ${cs.length} Collect of data point (data: ${e.data}, id: ${e.dataid}, dsid: ${e.dsid}) without subsequent deletion.`,
                flag: true
            }, ...this.cases.get(identifier)!.map(t => ({
                e: t, 
                desc: description(t),
                flag: cs.some(c => c.id === t.id)
            }))]
            return v
        })
    }

}

export class LawfulProcessing implements GDPRRule { // Showing entire case history
    name = "Lawful processing"
    article = "6(1)"
    description = "There was either legal grounds or consent provided for the collect of data"

    consents_and_legal_grounds : Set<string> = new Set()
    errors : Map<string, (Processing & LogRecord)> = new Map()
    violatedCases : Map<string, (Processing & LogRecord)[]> = new Map()
    cases : Map<string, TransformedLogRecord[]> = new Map()

    identifier ({ name, dsid, data } : DsConsent | LegalGrounds | Processing) {
        return `${dsid}${data}`
    }

    step (record: TransformedLogRecord) {
        if (record.name === "Ds Consent" || record.name === "Legal Grounds" || record.name === "Processing") { 
            let identifier = this.identifier({...record})

            if (record.name === "Ds Consent" || record.name === "Legal Grounds"){
                this.consents_and_legal_grounds.add(identifier)
                if(this.violatedCases.has(identifier)){
                    const hist = this.violatedCases.get(identifier)!
                    const sameTimestamp = hist.reduce((b, r) => b && r.timestamp.getTime() === record.timestamp.getTime(), true)
                    if(sameTimestamp) this.violatedCases.delete(identifier)
                }
            }

            else if (record.name === "Processing" && !this.consents_and_legal_grounds.has(identifier)) {
                this.errors.set(record.id, record)
                addToItems(identifier, record, this.violatedCases)
            }

            addToItems(identifier, record, this.cases)
        }
    }
    stepMany(records: TransformedLogRecord[]) { records.forEach( ar => this.step(ar) )}

    getErrors () { return [...this.errors.values()] }

    getViolations() { 
        let violations : Violation[] = []
        this.violatedCases.forEach((errors, identifier) => {
            if (!this.cases.has(identifier)) throw new Error(`Implementation error: case missing for ${identifier}`)

            let e = errors[0]
            violations.push([{
                    e,
                    desc: `Found ${errors.length} processings of data point (data: ${e.data}, id: ${e.dataid}, dsid: ${e.dsid}) without legal grounds or consent.`, 
                    flag: true
                }, ...this.cases.get(identifier)!.map(e => ({ e, desc: description(e), flag: this.errors.has(e.id) }))
            ])
        })
        return violations
    }
}

export class Consent implements GDPRRule {
    name = "Consent"
    article = "7(3)"
    description = "There was either legal grounds or consent provided for the collect of data"

    legal_grounds : Map<string, (LegalGrounds & LogRecord)> = new Map()
    consent: Map<string, (DsConsent & LogRecord)> = new Map()
    revoke : Map<string, (DsRevoke & LogRecord)> = new Map()

    errors1 : Map<string, Processing & LogRecord> = new Map()   // contains processings without legal grounds or consent (record_id => Processing)
    errors2 : Map<string, Processing & LogRecord> = new Map()   // contains processings after consent has been revoked (record_id => Processing)
    violations1 : Map<string, (Processing & LogRecord)[]> = new Map() // contains processings without legal grounds or consent (case_identifier => Processing[])
    violations2 : Map<string, (Processing & LogRecord)[]> = new Map() // contains processings after consent has been revoked (case_identifier => Processing[])
    cases : Map<string, TransformedLogRecord[]> = new Map()

    identifier ({ name, dsid, data } : DsConsent | DsRevoke | LegalGrounds | Processing) {
        return `${dsid}${data}`
    }

    step (record: TransformedLogRecord) {

        if (record.name === "Ds Consent" || record.name  === "Legal Grounds" || record.name  === "Ds Revoke" || record.name  === "Processing" ) {
            let identifier = this.identifier({...record})

            if (record.name === "Legal Grounds") {
                this.legal_grounds.set(identifier, record)
                if(this.violations1.has(identifier)){
                    const hist = this.violations1.get(identifier)!
                    const sameTimestamp = hist.reduce((b, r) => b && r.timestamp.getTime() === record.timestamp.getTime(), true)
                    if(sameTimestamp) this.violations1.delete(identifier)
                }
            }

            else if (record.name === "Ds Consent"){
                this.consent.set(identifier, record)
                if(this.violations1.has(identifier)){
                    const hist = this.violations1.get(identifier)!
                    const sameTimestamp = hist.reduce((b, r) => b && r.timestamp.getTime() === record.timestamp.getTime(), true)
                    if(sameTimestamp) this.violations1.delete(identifier)
                }

                if(this.violations2.has(identifier)){
                    const hist = this.violations2.get(identifier)!
                    const sameTimestamp = hist.reduce((b, r) => b && r.timestamp.getTime() === record.timestamp.getTime(), true)
                    if(sameTimestamp) this.violations2.delete(identifier)
                }
            }

            else if (record.name === "Ds Revoke") 
                this.revoke.set(identifier, record)

            else if (record.name === "Processing"){
                let legal_grounds = this.legal_grounds.get(identifier)
                let consent = this.consent.get(identifier)
                let revoke = this.revoke.get(identifier)

                if (!legal_grounds && !consent) { // if neither legal grounds or consent has been given
                    this.errors1.set(record.id, record)
                    addToItems(identifier, record, this.violations1)
                }
                else if (revoke && consent && revoke.index > consent.index && !legal_grounds) { // if consent has been revoked (note that 'consent' will never be undefined here, due to the last 'if' statement)
                    this.errors2.set(record.id, record)
                    addToItems(identifier, record, this.violations2)
                } 
            }

            addToItems(identifier, record, this.cases)
        }
    }

    stepMany(records: TransformedLogRecord[]) { records.forEach( ar => this.step(ar) )}

    getErrors () { return [...this.errors1.values(), ...this.errors2.values()] }

    getViolations() { 
        let violations = []

        for (let [identifier,errors] of this.violations1) {
            let e = errors[0]
            let v = newViolationEntry(e, `Found ${errors.length} processings of data point (data: ${e.data}, id: ${e.dataid}, dsid: ${e.dsid}) without legal grounds or consent.`, true)
            let history = this.cases.get(identifier)!.map(record => ({ e: record, desc: description(record), flag: this.errors1.has(record.id)}))
            
            violations.push([v, ...history])
        }
        for (let [identifier,errors] of this.violations2) {
            let e = errors[0]
            let v = newViolationEntry(e, `Found ${errors.length} processings of data point (data: ${e.data}, id: ${e.dataid}, dsid: ${e.dsid}) after consent was revoked.`, true)
            let history = this.cases.get(identifier)!.map(record => ({ e: record, desc: description(record), flag: this.errors2.has(record.id)}))
            violations.push([v, ...history])
        }
        return violations
    }
}

export class LawfulProcessingAndConsent implements GDPRRule {
    name = "Lawful processing and consent"
    article = "6(1) and 7(3)"
    description = "There was either legal grounds or consent provided for the collect of data"

    rule = new LawfulProcessing()

    step(record:TransformedLogRecord){
        this.rule.step(record)
    }

    stepMany(records:TransformedLogRecord[]){
        this.rule.stepMany(records)
    }

    getErrors(){
        return this.rule.getErrors()
    }

    getViolations(){
        return this.rule.getViolations()
    }
}

export class RightToAccess implements GDPRRule {
    name = "Right to access"
    article = "15(1)"
    description = "Access was provided within 30 days of access requests"

    unansweredAccessRequests : Map<string, (DsAccessRequest & LogRecord)[] > = new Map()    // record_id => record
    cases : Map<string, TransformedLogRecord[]> = new Map()
    violatedCases : Map<string, (DsAccessRequest & LogRecord)[]> = new Map() // contains overdue access requests (case_identifier => Ds Access[])
    errors : Map<string, TransformedLogRecord> = new Map()
    endedCases : Set<string> = new Set()
    
    identifier ({ name, dsid } : DsAccessRequest | DsAccess | EndOfCase) {
        return `${dsid}`
    }

    overdue(first: Date, second: Date, id:string, limit:number) {
        return this.endedCases.has(id) || (second.valueOf() > (first.valueOf() + limit))
    }

    step (record: TransformedLogRecord) {
        if (record.name === "Ds Access Request" || record.name === "Ds Access" || record.name === "End of Case") { 
            let identifier = this.identifier({...record})
            addToItems(identifier, record, this.cases)

            if (record.name === "Ds Access Request")
                addToItems(identifier, record, this.unansweredAccessRequests)

            else if (record.name === "End of Case")
                this.endedCases.add(identifier)

            else if (record.name === "Ds Access" && this.unansweredAccessRequests.has(identifier)) { // We only check for overdue access requests when we encounter an access grant, but what if there never comes an access grant? Thus we must run through all unanswered access requests in the end, and check if they are overdue too.
                let overdueAccessRequests = this.unansweredAccessRequests.get(identifier)!.filter(t => this.overdue(t.timestamp, record.timestamp, this.identifier(record), t.timelimit))
                if (overdueAccessRequests) 
                    overdueAccessRequests.forEach(e => {
                        this.errors.set(e.id, e)
                        addToItems(identifier, e, this.violatedCases)
                    })
                this.unansweredAccessRequests.delete(identifier)
            }
        }
    }

    stepMany(records: TransformedLogRecord[]) { records.forEach( ar => this.step(ar) )}

    getErrors (params?:AnalysisParameters) {
        const ignore = params?.dateOption === 'Ignore'
        const include = params ? params.dateOption === 'None' : true
        const od = params?.date ? new Date(params.date) : new Date()
        let errors: TransformedLogRecord[] = [...this.errors.values()]
        this.unansweredAccessRequests.forEach((ts, _) => errors.push(...ts.filter(t => include || (!ignore && this.overdue(t.timestamp, od, this.identifier(t), t.timelimit)))))
        return errors
    }

    getViolations(params?:AnalysisParameters) { 
        const ignore = params?.dateOption === 'Ignore'
        const include = params ? params.dateOption === 'None' : true
        const od = params?.date ? new Date(params.date) : new Date()
        let violatedCases : Map<string, (DsAccessRequest & LogRecord)[]> = new Map(this.violatedCases)
        let overdueRequests : Map<string, TransformedLogRecord> = new Map(this.errors)

        this.unansweredAccessRequests.forEach((ts, k) => {
            ts.forEach(t => {
                if (include || (!ignore && this.overdue(t.timestamp, od, this.identifier(t), t.timelimit))) {
                    overdueRequests.set(t.id, t)
                    addToItems(this.identifier({...t}), t, violatedCases)
                }
            })
        })

        let violations : Violation[] = []
        violatedCases.forEach((errors, identifier) =>  {
            let e = errors[0]
            let desc = `Found ${errors.length} overdue access request${errors.length === 1 ? "" : "s"} from data subject ${e.dsid}`
            if (this.violatedCases.has(identifier)) desc += ` and no access grant` // The violated ids in this.violatedCases all have at least 1 access grant

            violations.push([
                newViolationEntry(e, `${desc}.`, true),
                ...this.cases.get(identifier)!.map(r => newViolationEntry(r, description(r), this.errors.has(r.id)))
            ])
        })

        return violations
    }
}

export class RightToErasure11 implements GDPRRule {
    name = "Right to erasure 1.1" // Deletion request implies deletion within 30 days
    article = "17(1)"
    description = "The data was deleted within 30 days of the deletion request"

    ds_deletion_requests : Map<string, (DsDelete & LogRecord)[]> = new Map()
    cases : Map<string, TransformedLogRecord[]> = new Map()
    violatedCases : Map<string, (DsDelete & LogRecord)[]> = new Map() // contains overdue deletion requests (case_identifier => Ds Delete[])
    errors : Map<string, (DsDelete & LogRecord)> = new Map()
    endedCases : Set<string> = new Set()

    identifier ({ name, dsid, data, dataid } : DsDelete | Delete ) {
        return `${dsid}${data}${dataid}`
    }
    
    overdue(first: Date, second: Date, id:string, limit:number) {
        return this.endedCases.has(id) || (second.valueOf() > (first.valueOf() + limit))
    }

    step (record: TransformedLogRecord) {
        if(record.name === "End of Case"){
            this.endedCases.add(`${record.dsid}`)
            for(const k of this.cases.keys()){
                if(k.startsWith(`${record.dsid}`)){
                    addToItems(k, record, this.cases)
                }
            }
        }
        else if (record.name === "Ds Delete" || record.name === "Delete") { 
            let identifier = this.identifier({...record})
            addToItems(identifier, record, this.cases)
            
            if (record.name === "Ds Delete") { 
                let requests = this.ds_deletion_requests.get(identifier) || []
                this.ds_deletion_requests.set(identifier, [...requests, record])
            }
            else if (record.name === "Delete" && this.ds_deletion_requests.get(identifier)) {
                let deletionRequests = this.ds_deletion_requests.get(identifier)!

                deletionRequests.forEach(deletionRequest => {
                    if (this.overdue(deletionRequest.timestamp, record.timestamp, `${record.dsid}`, deletionRequest.timelimit)) {
                        this.errors.set(deletionRequest.id, deletionRequest)
                        addToItems(identifier, deletionRequest, this.violatedCases)
                    }
                })

                this.ds_deletion_requests.delete(identifier)
            }
        }
    }

    stepMany(records: TransformedLogRecord[]) { 
        records.forEach( ar => this.step(ar) )
    }

    getErrors(params?:AnalysisParameters) {
        const ignore = params?.dateOption === 'Ignore'
        const include = params ? params.dateOption === 'None' : true
        const od = params?.date ? new Date(params.date) : new Date()
        let overdue: TransformedLogRecord[] = [...this.errors.values()]
        let unanswered = [...this.ds_deletion_requests].flatMap(([k,ts]) => ts.filter(t => include || (!ignore && this.overdue(t.timestamp, od, `${t.dsid}`, t.timelimit))))
        return [...overdue, ...unanswered]
    }

    getViolations(params?:AnalysisParameters) { 
        const ignore = params?.dateOption === 'Ignore'
        const include = params ? params.dateOption === 'None' : true
        const od = params?.date ? new Date(params.date) : new Date()
        let violatedCases : Map<string, (DsDelete & LogRecord)[]> = new Map(this.violatedCases)
        let overdueRequests = new Map(this.errors)

        this.ds_deletion_requests.forEach((ts,k) => {
            ts.forEach(t => {
                if (include || (!ignore && this.overdue(t.timestamp, od, `${t.dsid}`, t.timelimit))) {
                    overdueRequests.set(t.id, t)
                    addToItems(this.identifier({...t}), t, violatedCases)
                }
            })
        })

        let violations : Violation[] = []
        for (let [identifier, errors] of violatedCases) {
            let e = errors[0]
            let desc = `Found ${errors.length} deletion requests for (data: ${e.data}, id: ${e.dataid}, dsid: ${e.dsid})`

            let hasDeletions = this.violatedCases.has(this.identifier({...e})) // The violated cases in this.violatedCases all have at least 1 deletion
            if (hasDeletions) desc += `which was not deleted in time.`
            else desc += `but no subsequent deletions.`

            violations.push([ 
                newViolationEntry(e, desc, true), 
                ...this.cases.get(identifier)!.map(r => ({
                    e: r, 
                    desc: description(r),
                    flag: overdueRequests.has(r.id)
                }))]
            )
        }

        return violations
    }
}
export class RightToErasure12 implements GDPRRule {
    name = "Right to erasure 1.2" // Use implies not once delete
    article = "17(1)"
    description = "The data was not deleted prior to processing"

    deletion : Map<string, TransformedLogRecord&Delete> = new Map()
    cases : Map<string, TransformedLogRecord[]> = new Map()
    violatedCases : Map<string, TransformedLogRecord[]> = new Map()
    errors : Map<string, (Processing & LogRecord)> = new Map()

    identifier ({ dsid, data } : Processing | Delete | Collect | LegalGrounds | DsConsent) {
        return `${dsid}${data}`
    }
    
    step (record: TransformedLogRecord) {
        if (record.name === "Delete" || record.name === "Collect" || record.name === "Processing" || record.name === "Ds Consent" || record.name === "Legal Grounds") {
            let identifier = this.identifier({...record})
            addToItems(identifier, record, this.cases)

            if (record.name === "Delete"){
                if(!this.deletion.has(identifier)) this.deletion.set(identifier, record)
            }

            else if (record.name === "Collect" || record.name === "Ds Consent" || record.name === "Legal Grounds")
                this.deletion.delete(identifier)

            else if (record.name === "Processing") {
                if (this.deletion.has(identifier)){
                    const delete_ = this.deletion.get(identifier)!
                    if (delete_.timestamp.getTime() + delete_.graceperiod < record.timestamp.getTime()) {
                        this.errors.set(record.id, record) 
                        addToItems(identifier, record, this.violatedCases)
                    }
                }
            }
        }
    }

    stepMany(records: TransformedLogRecord[]) { records.forEach( ar => this.step(ar) )}

    getErrors () { return [...this.errors.values()] }

    getViolations() { 
        let violations = []

        for (let [k,errors] of this.violatedCases) {
            let e = errors[0]
            let v = [{
                    e, 
                    desc:`Found ${errors.length} processings of datapoint (${e.dataid}) after deletion.`,
                    flag: true
                }, ...this.cases.get(k)!.map(r => ({
                    e: r, 
                    desc: description(r),
                    flag: this.errors.has(r.id)
                }))]
            violations.push(v)
        }
        return violations
    }
}


export class RightToErasure2 implements GDPRRule {
    name = "Right to erasure 2" // Deletion request and share with (procid) implies notify procid within 30 days
    article = "17(2)"
    description = "All processors the data had been shared with were informed about the deletion request"

    shareDataWith : Map<string, Map<string, ShareWith & LogRecord>> = new Map()    // dataid => shared-processorId => ShareWith
    unNotifiedShares : Map<string, ShareWith & LogRecord> = new Map()              // processorid => ShareWith
    deletionRequests : Map<string, DsDelete & LogRecord> = new Map()               // dataid => ShareWith
    deletionRequestsOverdue : Map<string, DsDelete & LogRecord> = new Map()
    violatedCases : Map<string, (DsDelete & LogRecord)[]> = new Map()   // dataid => first DsDelete that was overdue
    cases : Map<string, TransformedLogRecord[]> = new Map()
    endedCases : Set<string> = new Set()
    shares : (ShareWith & LogRecord)[] = [] // used for data share overview in analysispage

    identifier ({ name, dsid, data, dataid } : DsDelete | ShareWith | NotifyProc) {
        return `${dsid}${data}${dataid}`
    }
    
    overdue(first: Date, second: Date, id:string, limit:number) {
        return this.endedCases.has(id) || second.valueOf() > (first.valueOf() + limit)
    }

    step (record: TransformedLogRecord) {
        if(record.name === "End of Case"){
            this.endedCases.add(`${record.dsid}`)
            for(const k of this.cases.keys()){
                if(k.startsWith(`${record.dsid}`)){
                    addToItems(k, record, this.cases)
                }
            }
        }
        else if (record.name === "Ds Delete" || record.name === "Share With" || record.name === "Notify Proc") { 
            let identifier = this.identifier({...record})
            addToItems(identifier, record, this.cases)

            if (record.name === "Share With") {
                let dataShares = this.shareDataWith.get(identifier) || new Map()
                dataShares.set(record.processorid, record)
                this.shareDataWith.set(identifier, dataShares)
                this.unNotifiedShares.set(record.processorid, record)
                
                this.shares.push(record)
            }

            else if (record.name === "Ds Delete" && this.shareDataWith.get(identifier)) {
                this.deletionRequests.set(identifier, record)
            }

            /* if 'notify(procId, dataId) && deletionRequest(dataId) && once share(procId, dataId)' */
            else if (record.name === "Notify Proc" && this.deletionRequests.get(identifier) && this.shareDataWith.get(identifier)?.get(record.processorid)) {
                let notify = record
                let notifiedProcessor = notify.processorid
                let deletionRequest = this.deletionRequests.get(identifier)!
                let shareWithProcessors = this.shareDataWith.get(identifier)!

                shareWithProcessors.delete(notifiedProcessor)
                this.unNotifiedShares.delete(notify.processorid)
                if (shareWithProcessors.size === 0) {    // all processors have been notified
                    this.shareDataWith.delete(identifier)
                    this.deletionRequests.delete(identifier)
                }
                if (this.overdue(deletionRequest.timestamp, notify.timestamp, `${notify.dsid}`, deletionRequest.timelimit)) {    // deletion request was over 30 days old
                    this.deletionRequestsOverdue.set(deletionRequest.id, deletionRequest)
                    addToItems(identifier, deletionRequest, this.violatedCases)
                }
            }
        }
    }

    stepMany(records: TransformedLogRecord[]) { records.forEach( ar => this.step(ar) )}

    getErrors(params?:AnalysisParameters) { 
        const ignore = params?.dateOption === 'Ignore'
        const include = params ? params.dateOption === 'None' : true
        const od = params?.date ? new Date(params.date) : new Date()
        let errors: TransformedLogRecord[] = [...this.deletionRequestsOverdue.values()]
        let overdueDeletionRequests = [...this.deletionRequests.values()].filter((r) => include || (!ignore && this.overdue(r.timestamp, od, `${r.dsid}`, r.timelimit)))
        for (let request of overdueDeletionRequests) {
            let sharedWith = this.shareDataWith.get(this.identifier(request))
            if (sharedWith) errors.push(...sharedWith.values())
        }
        return errors
    }

    getViolations(params?:AnalysisParameters) { 
        const ignore = params?.dateOption === 'Ignore'
        const include = params ? params.dateOption === 'None' : true
        const od = params?.date ? new Date(params.date) : new Date()
        let violations : Violation[] = []

        this.violatedCases.forEach((dsDelete, caseid) => {
            let e = dsDelete[0]
            violations.push([{
                e,
                desc: `Notification of processors overdue for datapoint ${e.dataid}`,
                flag: true
            }, ...this.cases.get(caseid)!.map(r => ({
                e: r, 
                desc: description(r),
                flag: this.deletionRequestsOverdue.has(r.id)
            }))])
        })

        let overdueDeletionRequests = [...this.deletionRequests.values()].filter((r) => include || (!ignore && this.overdue(r.timestamp, od, `${r.dsid}`, r.timelimit)))
        let violatedCases2 = new Set(overdueDeletionRequests.map(r => this.identifier(r)))

        violatedCases2.forEach(caseid => {
            let history = this.cases.get(caseid)
            if (!history) throw new Error(`Implementation error: history for case ${caseid} is undefined`)

            let dataSharedWith = this.shareDataWith.get(caseid)
            if (!dataSharedWith) throw new Error(`Implementation error: sharedWiths for case ${caseid} is undefined`)
            
            let e = history.find(r => r.name === "Share With" && dataSharedWith!.has(r.processorid))
            if (!e) throw new Error(`Implementation error: no unnotified shares for case ${caseid}`)

            let v : Violation = [{
                e,
                desc: `Deletion request not notified to ${dataSharedWith.size} processor` + (dataSharedWith.size === 1 ? `.` : `s.`) ,
                flag: true
            }, ...history!.map(r => ({
                e: r, 
                desc: description(r),
                flag: r.name === "Share With" && dataSharedWith!.has(r.processorid)
            }))]

            violations.push(v)
        })
        return violations
    }

    //returns a map from data type to a map of dataid to a list of logrecords
    getSharedData () { 
        const shareOverview:Map<string, Map<string, (ShareWith & LogRecord)[]>> = new Map()
        for(const r of this.shares){
            if(shareOverview.has(r.data)){
                const sharesOnData = shareOverview.get(r.data)!
                if(sharesOnData.has(r.dataid)){
                    const sharesOnDataid = sharesOnData.get(r.dataid)!
                    sharesOnDataid.push(r)
                } else{
                    sharesOnData.set(r.dataid, [r])
                }
            } else{
                const newShare = new Map()
                newShare.set(r.dataid, [r])
                shareOverview.set(r.data, newShare)
            }
        }
        return shareOverview
    }
}

export class RightToObject implements GDPRRule {
    name = "Right to object"
    article = "21(1)"
    description = "The legal grounds for the data (if any) was not objected prior to processing"

    legal_grounds : Map<string, TransformedLogRecord> = new Map()
    objections: Map<string, TransformedLogRecord> = new Map()
    cases : Map<string, TransformedLogRecord[]> = new Map()
    violatedCases : Map<string, TransformedLogRecord> = new Map()
    errors : Map<string, (Processing & LogRecord)> = new Map()

    identifier ({ name, dsid, data } : DsObject | LegalGrounds | Processing) {
        return `${dsid}${data}`
    }
    
    step (record: TransformedLogRecord) {

        if (record.name  === "Legal Grounds" || record.name === "Ds Object" || record.name  === "Processing" ) {
            let identifier = this.identifier({...record})
            addToItems(identifier, record, this.cases)

            if (record.name === "Legal Grounds") 
                this.legal_grounds.set(identifier, record)

            else if (record.name === "Ds Object") 
                this.objections.set(identifier, record)
                
            else if (record.name === "Processing"){
                let legal_grounds = this.legal_grounds.get(identifier)
                let objection = this.objections.get(identifier)

                // if (!legal_grounds) { // if neither legal grounds or consent has been given
                //     this.errors.push(actionRecord)
                //     this.reason.push("without legal grounds or consent has been given")
                //     return
                // }
                if (legal_grounds && objection && objection.timestamp > legal_grounds.timestamp) { 
                    // if objection has happened after legal grounds
                    this.errors.set(record.id, record)
                    if (!this.violatedCases.has(identifier))
                        this.violatedCases.set(identifier, record)
                } 
            }
        }
    }
    stepMany(records: TransformedLogRecord[]) { records.forEach( ar => this.step(ar) )}

    getErrors () { return Array.from(this.errors.values()) }

    getViolations() { 
        let violations = []

        for (let [k,e] of this.violatedCases) {
            let v = [{
                    e,
                    desc: "Processing of data after objection against legal grounds",
                    flag: true
                }, ...this.cases.get(k)!.map(r => ({
                    e, 
                    desc: description(r),
                    flag: this.errors.has(r.id)
                }))]
            violations.push(v)
        }
        return violations
    }
}

export class InfoOnCollect implements GDPRRule {
    name = "Info on collect"
    article = "13(1)"
    description = "Info on the collect was provided to the subject"

    collects : Map<string, (Collect & LogRecord) [] > = new Map()
    informs : Set<string> = new Set()

    identifier ({ name, dsid, data } : Collect | Inform) {
        return `${dsid}${data}`
    }

    step (record: TransformedLogRecord) {
        if (record.name === "Collect") {
            let identifier = this.identifier({...record})
            let informed = this.informs.has(identifier)
            if(!informed){
                addToItems(identifier, record, this.collects)
            } 
        }
        else if (record.name === "Inform") {
            let identifier = this.identifier({...record})
            let collect = this.collects.get(identifier)
            if (collect) {
                this.collects.delete(identifier)
            }
            this.informs.add(identifier)
        }
    }

    stepMany(records: TransformedLogRecord[]) { records.forEach( ar => this.step(ar) )}

    getErrors () { return Array.from(this.collects.values()).flat() }

    getViolations() { 
        let violations : Violation[] = []
        Array.from(this.collects.values()).forEach(v => {
            violations.push(
                [{
                    e: v[0], 
                    desc:`Found ${v.length} collect${v.length > 1 ? 's' : ''} of ${v[0].data} for ${v[0].dsid} but info on collect never provided.`,
                    flag: false
                }, ...v.map(r => ({
                    e: r, 
                    desc: description(r),
                    flag: true
                }))]
            )}
        )
        return violations
    }
}


export class RightToRestriction implements GDPRRule {
    name = "Right to restriction"
    article = "18(1-2)"
    description = "The data was not restricted prior to processing"

    restrictions : Map<string, (DsRestrict & LogRecord)> = new Map()
    repeals : Map<string, (DsRepeal & LogRecord)> = new Map()
    cases : Map<string, TransformedLogRecord[]> = new Map()
    violatedCases : Map<string, (Processing & LogRecord)> = new Map()
    errors : Map<string, (Processing & LogRecord)> = new Map()

    identifier ({ name, dsid, data } : DsRestrict | DsRepeal | Processing) {
        return `${dsid}${data}`
    }
    
    step (record: TransformedLogRecord) {

        if (record.name === "Ds Restrict" || record.name  === "Ds Repeal" || record.name  === "Processing" ) {
            let identifier = this.identifier({...record})
            addToItems(identifier, record, this.cases)

            if (record.name === "Ds Restrict") 
                this.restrictions.set(identifier, record)

            else if (record.name === "Ds Repeal")
                this.repeals.set(identifier, record)
                
            else if (record.name === "Processing"){
                let restriction = this.restrictions.get(identifier)
                let repeal = this.repeals.get(identifier)

                if ((restriction && !repeal) || (restriction && repeal && restriction.index > repeal.index)) {
                    this.errors.set(record.id, record)
                    if (!this.violatedCases.has(identifier))
                        this.violatedCases.set(identifier, record)
                }
            }
        }
    }

    stepMany(records: TransformedLogRecord[]) { records.forEach( ar => this.step(ar) )}

    getErrors () { return [...this.errors.values()] }

    getViolations() { 
        let violations = []

        for (let [k,e] of this.violatedCases) {
            let v = [{
                    e,
                    desc: `Processing after restriction: Data point ${e.data} (${e.dataid}) was processed about data subject ${e.dsid} after processing of the data has been restricted.`,
                    flag: true
                }, ...this.cases.get(k)!.map(r => ({
                    e, 
                    desc: description(r),
                    flag: this.errors.has(r.id)
                }))]
            violations.push(v)
        }
        return violations
    }
}

let informOnCollect = new InfoOnCollect()
let dataMinimisation = new DataMinimisation()
let storageLimitation = new StorageLimitation()
let lawfulProcessing = new LawfulProcessing()
let consent = new Consent()
let lpAndConsent = new LawfulProcessingAndConsent()
let rightToAcces = new RightToAccess()
let rightToErasure11 = new RightToErasure11()
let rightToErasure12 = new RightToErasure12()
let rightToErasure2 = new RightToErasure2()
let rightToObject = new RightToObject()
let rightToRestriction = new RightToRestriction()

export function getRule(name: string){
    switch (name) {
        case "Inform on collect":
            return informOnCollect
        case "Data minimisation":
            return dataMinimisation
        case "Storage limitation":
            return storageLimitation
        case "Lawful processing":
            return lawfulProcessing
        case "Consent":
            return consent
        case "Lawful processing and consent":
            return lpAndConsent
        case "Right to access":
            return rightToAcces
        case "Right to erasure 1.1":
            return rightToErasure11
        case "Right to erasure 1.2":
            return rightToErasure12
        case "Right to erasure 2":
            return rightToErasure2
        case "Right to object":
            return rightToObject
        case "Right to restriction":
            return rightToRestriction
        default:
            break;
    }
}

export function getRuleFromList(rules:GDPRRule[], name: string){
    return rules.find(r => r.name === name)
}

export const defaultRules = () => { return [
        new InfoOnCollect(),
        new DataMinimisation(),
        new StorageLimitation(),
        //new LawfulProcessing(),
        //new Consent(),
        new LawfulProcessingAndConsent(),
        new RightToAccess(),
        new RightToErasure11(),
        new RightToErasure12(),
        new RightToErasure2(),
        new RightToObject(),
        new RightToRestriction(),
]}


export function totalViolations(rules: GDPRRule[], params?:AnalysisParameters, filter?:(v:Violation[], r:string)=>Violation[]): number{
    let violationsCount = 0
    for (let i = 0; i < rules.length; i++) {
        if(filter){
            violationsCount += filter(rules[i].getViolations(params), rules[i].name).length
        } else {
            violationsCount += rules[i].getViolations(params).length
        }
    }
    return violationsCount
}

export {}