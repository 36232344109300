interface IProps {
    colRatio: number[],
    [name: string]: any
}

const margin = (i:number) => {
    let marginLeft = i > 0 ? 10 : 0
    return { marginLeft }
}

const MyRow = ( {colRatio, ...props}: IProps ) => {
    let children = props.children.map ? props.children : [props.children]
    return (
        <div {...props} style={{display: "flex", flexDirection: "row", ...props.style}} >
            {children && children.map((c:any,i:number) => (
                <div key={i} style={{flexGrow: colRatio[i], ...margin(i)}} >
                    {c}
                </div>
            ))}
        </div>
    )
}

export default MyRow;