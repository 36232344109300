
import React, { useState } from 'react';
import { description, toString } from '../../models/gdpr-signatures';
import { AnalysisParameters, GDPRRule, getRuleFromList } from '../../engine/rules';
import MyModal from '../helperComponents/MyModal';
import { Button, ButtonToolbar, Icon, Input, InputGroup, SelectPicker} from 'rsuite';
import { downloadCSV, formatDate } from '../../utils/utils';
import * as jsPDF from 'jspdf';
import { FlaggedLogRecord, Violation } from '../../models/engine-types';

/* Renders a view of the translated logs in an analysis */

const InputWithButton = (props : 
    { value: string, placeholder:string, size: string, handleClick: (val:string) => void, style?: React.CSSProperties } ) => {
    return ( 
        <InputGroup inside style={props.style}>
            <Input 
                placeholder={props.placeholder} 
                onChange={(val,e) => props.handleClick(val)} 
                value={props.value}
            />
            <InputGroup.Button >
                <Icon icon="search" />
            </InputGroup.Button>
        </InputGroup>
);}

interface IProps {
    show: boolean,
    onClose: () => void,
    records: FlaggedLogRecord[],
    gdprRules: GDPRRule[]
    analysisParameters: AnalysisParameters
}

export const TranslationView = (props: IProps ) => {
    const [caseid, setCase] = useState('');
    const [rule, setRule] = useState<GDPRRule|undefined>(undefined);
    
    let flagRule = rule ? getRuleFromList(props.gdprRules, rule.name) : rule
    let violations = rule ? flagRule!.getViolations(props.analysisParameters) : []
    let records = caseid ? props.records.filter(r => r.record.dsid.includes(caseid)) : props.records
    records = rule ? records.filter(r => violations!.reduce((found:boolean, vs:Violation) => found || (vs.filter(x => 
        x.e.dsid === r.record.dsid
        ).length > 0), false)) : records

    let inputStyling = { display: "inline-block", margin: "2px", width: "300px", height: "30px" }

    const downloadPDF = () => {
        let doc = new jsPDF()
        let source = document.getElementById("translation")
        doc.fromHTML(source, 5, 5, { width: 200 })
        doc.save("gdpr_translation.pdf")
    }

    let saveButton = <Button default onClick={() => downloadPDF()}> Save to PDF</Button>
    
    let ruleNames = props.gdprRules.map(r => r.name)

    let rows = props.records.map((rec,i) => {
        let t = rec.record
        let data = 'data' in t ? t.data : ' '
        let dataid = 'dataid' in t ? t.dataid : ' '
        let processorid = 'processorid' in t ? t.processorid : ' '
        let timelimit = 'timelimit' in t ? t.timelimit : ' '
        let graceperiod = 'graceperiod' in t ? t.graceperiod : ' '
        let info = 'info' in t ? t.info : ' '

        let csv = [
            t.filename, 
            t.index.toString(),
            formatDate(t.timestamp),
            t.name,
            t.actions.reduce((acc, a) => acc + '; ' + a),
            t.dsid,
            data,
            dataid,
            processorid,
            info,
            timelimit,
            graceperiod
        ]

        let flags = ruleNames.map(rn => rec.flags.get(rn) ? "1" : "0")

        return csv.concat(flags)
    })


    let columnNames = [
        "file name", "index", "timestamp", "name", "actions", "dsid", "data", 
        "dataid", "processorid", "info", "time limit", "grace period"
    ]
    columnNames = columnNames.concat(ruleNames)
    rows.unshift(columnNames)

    let csvContent = "data:text/csv;charset=utf-8,"

    rows.forEach(function(rowArray) {
        let row = rowArray.join(",")
        csvContent += row + "\r\n"
    });

    let saveCSVButton = <Button default onClick={() => downloadCSV(csvContent, "gdpr_translation.csv")}> Save to CSV</Button>

    return (
        <MyModal 
            buttons={[saveButton, saveCSVButton]} 
            title={"Translation"} 
            titleSize={'46px'} 
            show={props.show} 
            onClose={props.onClose} 
            color={"black"} 
            full={true}
        >
            <ButtonToolbar>
                <InputWithButton 
                    size="sm"
                    value={caseid}
                    placeholder="Filter by case" 
                    style={{ ...inputStyling }}
                    handleClick={(value) => { setCase(value) }}
                />
                <SelectPicker
                    size="sm"
                    data={props.gdprRules.map(r => ({name: r.name, value: r}))} 
                    value={rule} 
                    style={{ ...inputStyling, marginLeft: "10px" }}
                    placeholder={"Select rule"}
                    onChange={r => setRule(r)}
                    labelKey={"name"}
                    valueKey={"value"}
                    searchable={false}
                    preventOverflow
                />
            </ButtonToolbar>

            <TranslationTable records={records} rule={rule} />
        </MyModal>
    );
}

interface ITranslationTableProps {
    records: FlaggedLogRecord[],
    rule?: GDPRRule
}

const TranslationTable = (props:ITranslationTableProps) => {
    let lightGreyColor = "#e5e5ea"
    let visited : Set<string> = new Set()

    let columnStyling = { display: "inline-block", margin: "2px"}

    return (
        <div 
            id="translation" 
            style={{
                height: "510px", 
                overflow: "auto", 
                border: `1px solid ${lightGreyColor}`, 
                borderRadius: "6px", 
                padding: "0 10px"
            }}
        >
            {props.records.map((t,i) => 
                {
                    let rec = t.record
                    let flag = props.rule ? t.flags.get(props.rule.name) : false

                    let sameAsPrevRecord = false 

                    if (!sameAsPrevRecord && visited.has(rec.record)) 
                        console.error("Error: This TransformedLogRecord belongs to a log-record that has been seen before, but which was not the same as previous one");
                    visited.add(rec.record)

                    let borderTop = `1px solid ${lightGreyColor}`
                    if (sameAsPrevRecord || i === 0) borderTop = ""

                    let recordTextColor = "black"
                    if (sameAsPrevRecord) recordTextColor = "white"

                    if (props.rule && flag) {
                        recordTextColor = "red"
                    }

                    return (
                        <div key={i} className="row" style={{ display: "flex", padding: "10px 0", borderTop, color: "black" }}>
                            <div style={{ flex: 4, ...columnStyling, color: recordTextColor, borderRight: '1px solid #d5d5da' }}>{`${rec.record.replaceAll(',', ', ')}` }</div>
                            <div style={{ flex: 2, ...columnStyling,  borderRight: '1px solid #d5d5da' }}>{ toString(rec) }</div>
                            <div style={{ flex: 3, ...columnStyling }}>{ description(rec) }</div>
                            <div style={{ color: "white" }}>{ "." }</div>
                        </div>
                    )
                }
            )}
        </div>
    )
}


