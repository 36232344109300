import React from 'react';
import '../common.css'
import { SelectPicker, List, Button, Whisper, Popover, AutoComplete, Row, Col, Input, IconButton, Icon, Panel, Tooltip, Checkbox, InputNumber } from 'rsuite';
import { GDPRActionInputs, CoreGDPRSignature, Delete, DsAccessRequest, DsDelete } from '../../models/gdpr-signatures';
import { ActionsToGdpr, GDPRAction, LogRecord } from '../../models/engine-types';
import { IFileData } from '../../models/file';
import { EditText } from '../helperComponents/EditableText';
import { MappingCommentInfo } from '../../../../common/ClientServerInterface';
import Comment from '../violationsComponents/Comment';
import { withTranslation } from 'react-i18next';
import { dayInMs, WHISPER_DELAY } from '../../utils/utils';

/* The table used to define an action mapping */

interface ActionRowProps extends ActionTableProps{ 
    logAction: string
    logEntries?: LogRecord[]
    signatures: GDPRAction[]
    description: string
    index:number
    dataTypes: string[]
    processorIds: string[]
    processorInfos: string[]
    providers: string[]
    legalBases: string[]
    onActionChange: (a:string, signatures:GDPRAction[]) => void
    onDescriptionChange: (a:string, desc:string) => void
    removeAction: (a:string) => void
    comments: MappingCommentInfo[]
    mappingId: number
}

interface ActionRowState{
    expanded: boolean
    comment_text: string
}

class ActionRow extends React.Component<ActionRowProps, ActionRowState> {
    constructor(props:ActionRowProps) {
        super(props);
        this.state = {
            expanded: false,
            comment_text: ''
        }

        this.handleSelectGDPR = this.handleSelectGDPR.bind(this)
        this.handleAddGDPR = this.handleAddGDPR.bind(this)
        this.handleDeleteGDPR = this.handleDeleteGDPR.bind(this)
    }

    data = GDPRActionInputs();

    handleAddGDPR (idx: number) {
        let { logAction, signatures } = this.props
        let defaultSignature = this.data[0]
        signatures.splice(idx+1, 0, {signature: defaultSignature, startOfCase: false, ifDataCollected: false})
        this.props.onActionChange(logAction, [...signatures])
    }

    handleDeleteGDPR (idx: number) {
        let { logAction, signatures } = this.props
        signatures.splice(idx, 1)
        this.props.onActionChange(logAction, [...signatures])
    }

    handleSelectGDPR (index:number, value: CoreGDPRSignature|null) {
        if (!value) this.handleDeleteGDPR(index)
        else {
            let { logAction, signatures } = this.props
            if (signatures.length <= 0 && value){
                this.props.onActionChange(logAction, [{signature: value, startOfCase: false, ifDataCollected: false}])
                return
            } 
            if (signatures[index].signature === value) return;
            else{
                let newSignatures = [...signatures]
                newSignatures[index].signature = value
                this.props.onActionChange(logAction, newSignatures)
            }
        }
    }

    handleCheckSOC(idx:number){
        const newSignatures = [...this.props.signatures]
        newSignatures[idx].startOfCase = !newSignatures[idx].startOfCase
        if(newSignatures[idx].startOfCase) newSignatures[idx].ifDataCollected = false
        this.props.onActionChange(this.props.logAction, newSignatures)
    }

    handleCheckIDC(idx:number){
        const newSignatures = [...this.props.signatures]
        newSignatures[idx].ifDataCollected = !newSignatures[idx].ifDataCollected
        if(newSignatures[idx].ifDataCollected) newSignatures[idx].startOfCase = false
        this.props.onActionChange(this.props.logAction, newSignatures)
    }

    addComment(action:string){
        this.props.addComment(action, this.state.comment_text).then(r =>{
            if(r) this.setState({comment_text: ''})
        })
    }

    
    render() {
        const fullWidth = { width: '100%' }
        const signatures = this.props.signatures.length === 0 ? [null] : this.props.signatures

        return (
            <List.Item style={{paddingBottom:0, paddingTop: 10}}>
                <Row>
                <Row >
                    <div>
                    <Col xs={1}>
                        <IconButton 
                            appearance="subtle"
                            icon={<Icon icon={this.state.expanded ? 'minus-square-o' : 'plus-square-o'}/>} 
                            onClick={() => this.setState({expanded: !this.state.expanded})} 
                            style={{marginLeft: 10}}
                        />
                    </Col>

                    <Col xs={7}>
                        <p style={{marginLeft: 10, marginTop: 5}}>{this.props.logAction}</p>
                    </Col>

                    <Col xs={15}>
                        {signatures.map((a:GDPRAction|null, i:number) => {
                            let disableDelete = signatures.length <= 1
                            return (
                                <Row key={i} style={{marginBottom: 10}}>
                                    <Col xs={7}>
                                        <SelectPicker 
                                            name="gdprActionSelecter"
                                            placeholder="None"
                                            data={this.data}
                                            value={ a?.signature.name }
                                            valueKey={"name"}
                                            labelKey={"name"}
                                            style={fullWidth}
                                            searchable={false}
                                            onSelect={(v,item, event) => { 
                                                this.handleSelectGDPR(i, (item as any as CoreGDPRSignature)) }}
                                            onClean={(event) => this.handleSelectGDPR(i, null)}
                                            preventOverflow
                                        />
                                    </Col>

                                    <Col xs={6}>
                                        { a && ('data' in a.signature) && 
                                            <Whisper 
                                                delay={WHISPER_DELAY} 
                                                trigger="hover" 
                                                placement={'top'} 
                                                speaker={<Popover>{this.props.t('required')}</Popover>}
                                            >
                                                <AutoComplete 
                                                    name={""} 
                                                    placeholder={"Data type"} 
                                                    value={a.signature.data}
                                                    filterBy={(s, item:{value:string, label:string}) => 
                                                        s !== item.value && item.value.includes(s)}// don't show a suggestion that is the same as the current input
                                                    data={this.props.dataTypes}
                                                    onChange={(input,e) => 
                                                        {'data' in a.signature && 
                                                        this.handleSelectGDPR(i, { ...a.signature, data: input})} 
                                                    } 
                                                    required="required"
                                                    style={fullWidth}
                                                />
                                            </Whisper>
                                        }
                                        { a && ('processorid' in a.signature) && 
                                            <Whisper 
                                                delay={WHISPER_DELAY} 
                                                trigger="hover" 
                                                placement={'top'} 
                                                speaker={<Popover>{this.props.t('required')}</Popover>}
                                            >
                                                <AutoComplete 
                                                    name={""} 
                                                    placeholder={"Processor id"} 
                                                    value={a.signature.processorid}
                                                    filterBy={(s, item:{value:string, label:string}) => 
                                                        s !== item.value && item.value.includes(s)}// don't show a suggestion that is the same as the current input
                                                    data={this.props.processorIds}
                                                    onChange={(input,e) => 
                                                        {'processorid' in a.signature && 
                                                        this.handleSelectGDPR(i, { ...a.signature, processorid: input})}
                                                    }
                                                    required="required"
                                                    style={fullWidth}
                                                />
                                            </Whisper>
                                        }
                                        { a && ('graceperiod' in a.signature) && 
                                            <Whisper 
                                                delay={WHISPER_DELAY} 
                                                trigger="hover" 
                                                placement={'top'} 
                                                speaker={<Popover>{this.props.t('grace_period')}</Popover>}
                                            >
                                                <InputNumber 
                                                    value={a.signature.graceperiod / 1000}
                                                    step={1}
                                                    min={0}
                                                    onChange={(v) => 
                                                        this.handleSelectGDPR(i, { ...(a.signature as Delete), 
                                                            graceperiod: Number(v)*1000})
                                                    }
                                                />
                                            </Whisper>
                                        }
                                        { a && ('timelimit' in a.signature) && 
                                            <Whisper 
                                                delay={WHISPER_DELAY} 
                                                trigger="hover" 
                                                placement={'top'} 
                                                speaker={<Popover>{this.props.t('time_limit')}</Popover>}
                                            >
                                                <InputNumber 
                                                    value={a.signature.timelimit / dayInMs}
                                                    step={1}
                                                    min={0}
                                                    onChange={(v) => 
                                                        this.handleSelectGDPR(i, { ...(a.signature as DsAccessRequest | DsDelete), 
                                                            timelimit: Number(v)*dayInMs})
                                                    }
                                                />
                                            </Whisper>
                                        }
                                        { a && (a.signature.name === 'Collect' || a.signature.name === 'Collect And Inform') && 
                                            <Whisper 
                                                delay={WHISPER_DELAY} 
                                                trigger="hover" 
                                                placement={'top'} 
                                                speaker={<Popover>{this.props.t('data_provider_info')}</Popover>}
                                            >
                                                <AutoComplete 
                                                    name={""} 
                                                    placeholder={this.props.t('data_provider')} 
                                                    value={a.signature.info}
                                                    filterBy={(s, item:{value:string, label:string}) => 
                                                        s !== item.value && item.value.includes(s)}// don't show a suggestion that is the same as the current input
                                                    data={this.props.providers}
                                                    onChange={(input,e) => 
                                                        {'info' in a.signature && 
                                                        this.handleSelectGDPR(i, { ...a.signature, info: input})}
                                                    }
                                                    style={fullWidth}
                                                />
                                            </Whisper>
                                        }
                                        { a && a.signature.name === 'Legal Grounds' &&
                                            <Whisper 
                                                delay={WHISPER_DELAY} 
                                                trigger="hover" 
                                                placement={'top'} 
                                                speaker={<Popover>{this.props.t('legal_basis_info')}</Popover>}
                                            >
                                                <AutoComplete 
                                                    name={""} 
                                                    placeholder={this.props.t('legal_basis')} 
                                                    value={a.signature.info}
                                                    filterBy={(s, item:{value:string, label:string}) => 
                                                        s !== item.value && item.value.includes(s)}// don't show a suggestion that is the same as the current input
                                                    data={this.props.legalBases}
                                                    onChange={(input,e) => 
                                                        {'info' in a.signature && 
                                                        this.handleSelectGDPR(i, { ...a.signature, info: input})}
                                                    }
                                                    style={fullWidth}
                                                />
                                            </Whisper>
                                        }
                                        { a && a.signature.name === 'Share With' &&
                                            <Whisper 
                                                delay={WHISPER_DELAY} 
                                                trigger="hover" 
                                                placement={'top'} 
                                                speaker={<Popover>{this.props.t('processor_info_info')}</Popover>}
                                            >
                                                <AutoComplete 
                                                    name={""} 
                                                    placeholder={this.props.t('processor_info')} 
                                                    value={a.signature.info}
                                                    filterBy={(s, item:{value:string, label:string}) => 
                                                        s !== item.value && item.value.includes(s)}// don't show a suggestion that is the same as the current input
                                                    data={this.props.processorInfos}
                                                    onChange={(input,e) => 
                                                        {'info' in a.signature && 
                                                        this.handleSelectGDPR(i, { ...a.signature, info: input})}
                                                    }
                                                    style={fullWidth}
                                                />
                                            </Whisper>
                                        }
                                    </Col>

                                    <Col xs={6}>
                                        { a ?
                                            <div style={{overflow: 'hidden', width: '100%', marginTop: -2}}>
                                                {
                                                    a.signature.name === 'Collect' ||
                                                    a.signature.name === 'Collect And Inform' ||
                                                    a.signature.name === 'Ds Consent' ||
                                                    a.signature.name === 'Legal Grounds' ||
                                                    a.signature.name === 'Share With' ||
                                                    a.signature.name === 'Inform' ?
                                                    <Whisper 
                                                        delay={WHISPER_DELAY} 
                                                        placement='top' 
                                                        trigger='hover' 
                                                        speaker={<Tooltip>Start of case</Tooltip>}
                                                    >
                                                        <div style={{float: 'left'}}>
                                                            <Checkbox 
                                                                onClick={() => this.handleCheckSOC(i)} 
                                                                checked={a?.startOfCase}
                                                            >
                                                                <Icon icon='sort-up'/>
                                                            </Checkbox>
                                                        </div>
                                                    </Whisper>
                                                    : ''
                                                }

                                                {'data' in a.signature && 
                                                a.signature.name !== 'Legal Grounds' && 
                                                a.signature.name !== 'Ds Consent' && 
                                                a.signature.name !== 'Collect' &&
                                                a.signature.name !== 'Collect And Inform'
                                                ? 
                                                    <Whisper 
                                                        delay={WHISPER_DELAY} 
                                                        placement='top' 
                                                        trigger='hover' 
                                                        speaker={<Tooltip>If data collected</Tooltip>}
                                                    >
                                                        <div style={{float: 'left'}}>
                                                            <Checkbox 
                                                                onClick={() => this.handleCheckIDC(i)} 
                                                                checked={a?.ifDataCollected}
                                                            >
                                                                <Icon icon='database'/>
                                                            </Checkbox>
                                                        </div>
                                                    </Whisper>
                                                    : ''
                                                }
                                            </div>
                                            : ''
                                        }
                                    </Col>

                                    <Col xs={4}>
                                        <div>
                                            <Whisper 
                                                delay={WHISPER_DELAY} 
                                                speaker={<Tooltip>{this.props.t('add_gdpr_action')}</Tooltip>}
                                            >
                                                <Button 
                                                    style={{marginLeft: 10}} 
                                                    onClick={() => this.handleAddGDPR(i)}
                                                    size='sm'
                                                > 
                                                    +
                                                </Button>
                                            </Whisper>

                                            <Whisper 
                                                delay={WHISPER_DELAY}  
                                                speaker={<Tooltip>{this.props.t('remove_gdpr_action')}</Tooltip>}
                                            >
                                                <IconButton 
                                                    onClick={() => this.handleDeleteGDPR(i)} 
                                                    size='sm'
                                                    disabled={disableDelete} 
                                                    icon={<Icon icon='trash'/>}
                                                />
                                            </Whisper>
                                            </div>
                                        </Col>
                                    </Row>
                                )})}
                        </Col>

                        <Col xs={1}>
                            <Whisper 
                                delay={WHISPER_DELAY}  
                                speaker={<Tooltip>{this.props.t('remove_action_from_mapping')}</Tooltip>}
                            >
                                <IconButton 
                                    appearance="subtle"
                                    onClick={() => this.props.removeAction(this.props.logAction)}
                                    disabled={this.props.logEntries && this.props.logEntries.length > 0}
                                    icon={<Icon icon={'warning'}/>} 
                                />
                            </Whisper>
                        </Col>
                    </div>
                </Row>
                <div style={{height: 10}}/>
                    { 
                        this.state.expanded ?
                        <div style={{marginTop: 30}}>
                            <p style={{marginLeft: 10}}>
                                <b>{'Description: '}</b>
                                <EditText 
                                    value={this.props.description} 
                                    onSave={s => this.props.onDescriptionChange(this.props.logAction, s)}
                                    editClassName=''
                                    size={50}
                                />
                            </p> 
                        </div>
                        : ''
                    }
                    { 
                        this.state.expanded  && this.props.mappingId ?
                        <div style={{marginTop: 30, marginLeft: 10, width: '60%'}}>
                            <b>{'Comments: '}</b>
                            {this.props.comments.map(c => (
                                <Comment 
                                    editedAt={c.editedAt} 
                                    history={c.history} 
                                    access={Number(c.uploadedBy) === Number(this.props.userId)} 
                                    id={c.id} 
                                    text={c.text} 
                                    uploadedAt={c.uploadedAt} 
                                    uploadedByUsername={c.uploadedByUsername} 
                                    workspaceId={this.props.workspaceId} 
                                    editComment={this.props.editComment} 
                                    removeComment={this.props.removeComment}
                                />
                            ))}
                            <div style={{ display: "flex", marginTop: 20, marginBottom: 20}}>
                                <Input 
                                    rows={3}
                                    style={{width: '50%'}} 
                                    onChange={(s) => this.setState({comment_text: s})} 
                                    value={this.state.comment_text}
                                />
                                <Button 
                                    style={{marginLeft: 10}} 
                                    appearance='ghost' 
                                    onClick={() => this.addComment(this.props.logAction)}
                                >
                                    {this.props.t('comment')}
                                </Button>
                            </div>
                        </div>
                        : ''
                    }
                    { 
                        this.state.expanded && this.props.logEntries ?
                        <>
                            <div style={{marginLeft: 10, width: '100%', marginTop: 30}}>
                                <b>{`The log contains ${this.props.logEntries?.length} entries matching this action:`}</b>

                                <Panel bordered style={{ maxHeight: '200px', overflow: 'auto', width: "95%" }}>
                                    <pre>
                                        {this.props.logEntries.map(e => (
                                            <p style={{marginLeft: 10}}>{e.record}</p>
                                            ))}
                                    </pre>
                                </Panel>
                                <div style={{height: 10}}/>
                            </div>
                        </>
                        : ''
                    }
                    {this.state.expanded ? <div style={{height: 40}}/> : '' }
                </Row>
            </List.Item>
        )
    }
}

interface ActionTableProps { 
    fdata: IFileData
    onChange: (actionToGdpr: ActionsToGdpr) => void
    addComment: (a: string, t:string) => Promise<boolean>
    removeComment: (id: number) => void
    editComment: (id: number, t:string) => void
    workspaceId: number
    userId: number
    t: (s:string) => string
    tReady: boolean
}

class ActionTable extends React.Component<ActionTableProps, {}> {
    constructor(props:ActionTableProps) {
        super(props);
        this.getAutoCompleteDataTypes = this.getAutoCompleteDataTypes.bind(this)
        this.onActionChange = this.onActionChange.bind(this)
    }

    getAutoCompleteDataTypes() : string[] {
        let uniques = new Set<string>()
        let actionToGdpr = this.props.fdata.actionMapping
        Object.keys(actionToGdpr).forEach(a => {
            let gdprSignatures = actionToGdpr[a].gdprActions
            gdprSignatures.forEach(sig => {
                if (sig && 'data' in sig.signature) {
                    let data = sig.signature['data']
                    uniques.add(data)
                }
            })
        })
        return Array.from(uniques);
    }

    getAutoCompleteProcessorIds() : string[] {
        let uniques = new Set<string>()
        let actionToGdpr = this.props.fdata.actionMapping
        Object.keys(actionToGdpr).forEach(a => {
            let gdprSignatures = actionToGdpr[a].gdprActions
            gdprSignatures.forEach(sig => {
                if (sig && 'processorid' in sig.signature) {
                    let data = sig.signature['processorid']
                    uniques.add(data)
                }
            })
        })
        return Array.from(uniques);
    }

    getAutoCompleteLegalBases() : string[] {
        let uniques = new Set<string>()
        let actionToGdpr = this.props.fdata.actionMapping
        Object.keys(actionToGdpr).forEach(a => {
            let gdprSignatures = actionToGdpr[a].gdprActions
            gdprSignatures.forEach(sig => {
                if (sig && sig.signature.name === 'Legal Grounds') {
                    let info = sig.signature.info
                    uniques.add(info ? info : '')
                }
            })
        })
        return Array.from(uniques);
    }

    getAutoCompleteProviders() : string[] {
        let uniques = new Set<string>()
        let actionToGdpr = this.props.fdata.actionMapping
        Object.keys(actionToGdpr).forEach(a => {
            let gdprSignatures = actionToGdpr[a].gdprActions
            gdprSignatures.forEach(sig => {
                if (sig && (sig.signature.name === 'Collect' || sig.signature.name === 'Collect And Inform')) {
                    let info = sig.signature.info
                    uniques.add(info ? info : '')
                }
            })
        })
        return Array.from(uniques);
    }

    getAutoCompleteProcessorInfos() : string[] {
        let uniques = new Set<string>()
        let actionToGdpr = this.props.fdata.actionMapping
        Object.keys(actionToGdpr).forEach(a => {
            let gdprSignatures = actionToGdpr[a].gdprActions
            gdprSignatures.forEach(sig => {
                if (sig && sig.signature.name === 'Share With') {
                    let info = sig.signature.info
                    uniques.add(info ? info : '')
                }
            })
        })
        return Array.from(uniques);
    }

    removeAction(action:string){
        this.props.fdata.mappingChanges = true
        let newActions = {...this.props.fdata.actionMapping}
        delete newActions[action]
        this.props.onChange(newActions)    
    }

    onActionChange(action: string, signatures:GDPRAction[]) {
        this.props.fdata.mappingChanges = true
        let newActions = {...this.props.fdata.actionMapping}
        newActions[action].gdprActions = signatures
        this.props.onChange(newActions)
    }

    onDescriptionChange(action: string, desc:string) {
        this.props.fdata.mappingChanges = true
        let newActions = {...this.props.fdata.actionMapping}
        newActions[action].description = desc
        this.props.onChange(newActions)
    }

    render() {
        const actionMap = this.props.fdata.actionMapping
        const dataTypes = this.getAutoCompleteDataTypes()
        const processorIds = this.getAutoCompleteProcessorIds()
        const legalBases = this.getAutoCompleteLegalBases()
        const processorInfos = this.getAutoCompleteProcessorInfos()
        const providers = this.getAutoCompleteProviders()
        return (
            <List hover >
                {Object.keys(actionMap).map((a,i) => (
                    <ActionRow 
                        {...this.props}
                        key={i + a}
                        processorIds={processorIds} 
                        legalBases={legalBases} 
                        processorInfos={processorInfos}
                        providers={providers}
                        onActionChange={this.onActionChange.bind(this)} 
                        onDescriptionChange={this.onDescriptionChange.bind(this)} 
                        dataTypes={dataTypes} 
                        logAction={a}
                        logEntries={this.props.fdata.actions.get(a)}
                        signatures={actionMap[a].gdprActions} 
                        description={actionMap[a].description}
                        index={i}
                        comments={this.props.fdata.mappingComments ? 
                            this.props.fdata.mappingComments.filter(c => c.action === a) : 
                            []
                        }
                        mappingId={this.props.fdata.actionMappingid}
                        removeAction={this.removeAction.bind(this)}
                    />
                ))} 
            </List>
        )
    }
}

export default withTranslation()(ActionTable)