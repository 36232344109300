import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Dropdown, Icon } from 'rsuite';
import { withTranslation } from 'react-i18next';
import { i18n } from 'i18next';
import 'rsuite/dist/styles/rsuite-default.css'
//import './common.css'

/* The navigation bar used at the top of each page */

interface NavBarProps{
    isLoggedIn: boolean
    logout: () => void
    username: string
    stopRedirect: () => void
    t: (k:string) => string
    tReady: boolean
    i18n: i18n
}

class MyNavBar extends Component<NavBarProps> {

    constructor(props:NavBarProps){
        super(props)
        this.state = {
            isLoggedIn: props.isLoggedIn
        }
    }

    private logout() {
        this.props.logout()
    }

    private logInOutNav(){
        if(this.props.isLoggedIn){
            return( 
                <Dropdown.Item 
                    icon={<Icon icon="sign-out" />} 
                    componentClass={Link} to="/" 
                    onClick={this.logout.bind(this)} 
                    disabled={!this.props.isLoggedIn}
                >
                    {this.props.t('logout')}
                </Dropdown.Item>
            )
        } else{
            return (
                <Dropdown.Item 
                    icon={<Icon icon="sign-in" />} 
                    componentClass={Link} 
                    to="/login"
                >
                    {this.props.t('login')}
                </Dropdown.Item>
            )
        }
    }

    private registerNav(){
        if(this.props.isLoggedIn){
            return null
        } else{
            return (
                <Dropdown.Item icon={<Icon icon="calendar-check-o" />} componentClass={Link} to="/register">
                    {this.props.t('register')}
                </Dropdown.Item>
            )
        }
    }

    render() {
        return (
        <Navbar id={"navbar"} appearance={'inverse'}>
            <Navbar.Header style={{marginLeft: 250}}>

            </Navbar.Header>
            <Navbar.Body>
                <Nav>
                    <Nav.Item componentClass={Link} to="/">
                        <b>GDPR-Check</b>
                    </Nav.Item>

                    <Nav.Item icon={<Icon icon="file-text" />} componentClass={Link} to="/demo">
                        {'Demo'}
                    </Nav.Item>

                    <Nav.Item icon={<Icon icon="archive" />} componentClass={Link} to="/workspaces" onSelect={this.props.stopRedirect}>
                        {this.props.t('my_workspaces')}
                    </Nav.Item>

                    <Dropdown 
                        icon={<Icon icon="avatar" />} 
                        title={this.props.isLoggedIn ? this.props.username : this.props.t('user')}
                    >
                        { this.registerNav() }
                        { this.logInOutNav() }
                    </Dropdown>


                </Nav>

                <Nav pullRight style={{marginRight: 250}}>
                    <Dropdown icon={<Icon icon="language" />} title={this.props.t('language')}>
                        <Dropdown.Item onClick={() => this.props.i18n.changeLanguage('en')}>
                            English
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => this.props.i18n.changeLanguage('da')}>
                            Danish
                        </Dropdown.Item>
                    </Dropdown>
                </Nav>

            </Navbar.Body>
        </Navbar>    
    )}        
}

export default withTranslation()(MyNavBar)