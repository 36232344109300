import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import WorkspacesPage from './WorkspacesPage';

/* Used to 'protect' a page that requires the user to be logged in */

interface withAuthProps {
}

interface withAuthState {
    loading: boolean,
    redirect: boolean,
    userId: number
}

export default function withAuth(
  isLoggedIn:()=>Promise<number>, 
  ComponentToProtect:typeof WorkspacesPage, 
  chooseWorkspace?:(w_id:number, a_id:number)=>void){
  

  class AuthComponent extends Component<withAuthProps, withAuthState> {

    chooseWorkspace:(w_id:number, a_id:number)=>void

    constructor(props:withAuthProps) {
      super(props);
      this.state = {
          loading: true,
          redirect: false,
          userId: 0
      }
      this.chooseWorkspace = chooseWorkspace ? chooseWorkspace : (w_id:number, a_id:number) => {}
    }

    componentDidMount() {
      isLoggedIn()
      .then(res => {
          if (res !== 0) {
              this.setState({loading: false, userId: Number(res)})
          } else {
              this.setState({ loading: false, redirect: true });
          }
      })
    }


    render() {
      if(!this.state){ return null }
      if (this.state.loading) { return null }
      if (this.state.redirect) { return <Redirect to="/login" /> }
      else { return <ComponentToProtect {...this.props} userId={this.state.userId} chooseAnalysis={this.chooseWorkspace}/> }
    }
  }

  return <AuthComponent/>
}