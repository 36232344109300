import React from 'react';
import { Panel, IconButton, Icon, ButtonGroup, Whisper, Tooltip, FlexboxGrid } from 'rsuite';
import { newShape } from '../../models/shape';
import { IFileData } from '../../models/file';
import { ActionsToGdpr } from '../../models/engine-types';
import { ShapeInput } from '../../models/shape';
import { MappingInfo, ShapeInfo } from '../../../../common/ClientServerInterface';
import { withTranslation } from 'react-i18next';
import { array_move, WHISPER_DELAY } from '../../utils/utils';
import { RiErrorWarningFill, RiErrorWarningLine } from 'react-icons/ri'
import ActionMappingPanel from './ActionMappingPanel';
import ShapeOverview from './ShapeOverview';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import { TutorialTooltip } from '../helperComponents/TutorialTooltip';

/* The view shown when clicking on a file on the analysis page - contains shape panels and an action mapping table */

const flexRow = { 
    display: "flex", 
    flexDirection: "row", 
    width: "100%",
}

interface FileViewProps {
    fdata: IFileData,
    onChange: (fdata:IFileData) => void,
    workspaceId: number
    userId: number
    showLoginModal: (f?:() => void) => void
    applyShapes: (f:IFileData, ignoreError?:boolean) => void
    updateMappings: (o:number, n:number, c:ActionsToGdpr) => void
    updateShapes: (o:number, n:number, c:ShapeInput) => void
    getShapes: () => void
    shapes: ShapeInfo[],
    getMappings: () => void
    setNumMatched: () => void
    mappings: MappingInfo[]
    activeShape: number
    setActiveShape: (n:number) => void
    setDemoStep: (n:number) => void
    recordExample: string
    demoStep?: number
    t: (k:string) => string
    tReady: boolean
}

interface FileViewState {
    showSaveModal: boolean
    showLoadModal:boolean
    showTransferCommentsModal: boolean
    mappingName: string
}

class FileView extends React.Component<FileViewProps, FileViewState>{

    constructor(props:FileViewProps){
        super(props)
        this.state = {
            showLoadModal: false,
            showSaveModal: false,
            showTransferCommentsModal: false,
            mappingName: ''
        }
    }

    setFilterShape(n:number){
        if(this.props.fdata.filterShape === n) this.props.onChange({...this.props.fdata, filterShape: undefined})
        else this.props.onChange({...this.props.fdata, filterShape: n})
    }

    onAddShape = (entry?:ShapeInput) => {
        let newFileData = {...this.props.fdata}
        if(entry){
            newFileData.shapes.push(entry)
        }
        else {
            newFileData.shapes.push({...newShape(), separatorInfo: { value: '', type: 'delimiter'}})
        }
        this.props.onChange(newFileData)
    }

    onChangeShape = (changedShape:ShapeInput, idx:number) => {
        let newFileData = {...this.props.fdata}
        newFileData.shapes[idx] = {...changedShape}
        this.props.onChange(newFileData)
    }

    removeShape = (idx: number) => {
        let newFileData = {...this.props.fdata}
        let shapeArray = newFileData.shapes
        shapeArray.splice(idx, 1)
        this.props.onChange(newFileData)
        this.props.setActiveShape(this.props.activeShape-1)
    }

    moveShapeUp = (idx: number) => {
        let newFileData = {...this.props.fdata}
        let shapeArray = newFileData.shapes
        array_move(shapeArray, idx, idx-1)
        this.props.onChange(newFileData)
        this.props.setActiveShape(idx-1)
    }

    moveShapeDown = (idx: number) => {
        let newFileData = {...this.props.fdata}
        let shapeArray = newFileData.shapes
        array_move(shapeArray, idx, idx+1)
        this.props.onChange(newFileData)
        this.props.setActiveShape(idx+1)
    }

    onBlur(){
        this.props.setNumMatched()
    }

    contentWindow(style:React.CSSProperties){
        const fdata = this.props.fdata
        const unmatched = this.props.fdata.records.filter(r => r.shapeid < 0)
        const num_unmatched = unmatched.length === 1 && unmatched[0].index === 0 ? 0 : unmatched.length

        return(
            <div style={{...flexRow as React.CSSProperties, ...style, zIndex:1000}}>
                <Panel bordered style={{backgroundColor: 'white', width: '100%'}}>
                    <div style={{textAlign: 'right'}}>
                        <ButtonGroup 
                            style={{marginLeft: 'auto', marginTop: -20, backgroundColor: 'transparent'}}
                        >
                            <Whisper
                                delay={WHISPER_DELAY} 
                                placement='left' 
                                speaker={
                                    <Tooltip>
                                        {
                                            `${num_unmatched} ${this.props.t('unmatched_records')}. 
                                            ${fdata.filterShape === -1 ? 
                                            this.props.t('click_to_unfilter') : 
                                            this.props.t('click_to_filter')}`
                                        }
                                    </Tooltip>
                                }
                            >
                                <IconButton
                                    icon={
                                        num_unmatched > 0 ? 
                                            (fdata.filterShape === -1 ? 
                                            <RiErrorWarningFill/> : 
                                            <RiErrorWarningLine/>) :
                                            <Icon icon='check-circle-o'/>
                                    }
                                    circle
                                    size='lg'
                                    style={{
                                        marginTop: num_unmatched > 0 ? -8 : -10, 
                                        background: "transparent", 
                                        color: num_unmatched > 0 ? 'red' : ''
                                    }} 
                                    onClick= {() => this.setFilterShape(-1)}
                                />
                            </Whisper>

                            <TutorialTooltip
                                style={{marginTop: 4}}
                                active={this.props.demoStep === 1 || this.props.demoStep === 4} 
                                text={this.props.t('tutorial_content_display')}
                            />
                        </ButtonGroup>
                    </div>

                    <Panel bordered style={{overflow: 'auto', backgroundColor: '#f6f6f6', marginTop: -10}}>
                        {this.props.children}
                    </Panel>
                </Panel>

            </div>

        )
    }

    render(){

        return (
            <div style={{width: "100%"}}>

                    <FlexboxGrid>

                        <FlexboxGridItem colspan={18}>
                            { this.contentWindow({width: '100%'}) }
                        </FlexboxGridItem>

                        <FlexboxGridItem colspan={6}>
                            <ShapeOverview
                                workspaceId={this.props.workspaceId} 
                                fdata={this.props.fdata} 
                                onChange={this.onChangeShape.bind(this)} 
                                showLoginModal={this.props.showLoginModal}
                                getShapes={this.props.getShapes}
                                shapes={this.props.shapes}
                                onBlur={() => {this.onBlur()}}
                                updateShapes={this.props.updateShapes}
                                applyShapes={() => this.props.applyShapes(this.props.fdata, false)}
                                activeShape={this.props.activeShape}
                                setActiveShape={this.props.setActiveShape.bind(this)}
                                moveShapeDown={this.moveShapeDown.bind(this)}
                                moveShapeUp={this.moveShapeUp.bind(this)}
                                removeShape={this.removeShape.bind(this)}
                                setFilterShape={this.setFilterShape.bind(this)}
                                recordExample={this.props.recordExample}
                                addShape={this.onAddShape}
                                demoStep={this.props.demoStep}
                                setDemoStep={this.props.setDemoStep}
                            />
                        </FlexboxGridItem>
                    </FlexboxGrid>

                <ActionMappingPanel
                    fdata={this.props.fdata}
                    workspaceId={this.props.workspaceId}
                    showLoginModal={this.props.showLoginModal}
                    applyShapes={this.props.applyShapes}
                    updateMappings={this.props.updateMappings}
                    getMappings={this.props.getMappings}
                    mappings={this.props.mappings}
                    userId={this.props.userId}
                    demoStep={this.props.demoStep}
                />
            </div>

        )
    }
}

export default withTranslation()(FileView)