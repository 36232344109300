// Login.jsx
import { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, ButtonToolbar, FlexboxGrid, Form, FormGroup, Panel } from 'rsuite';
import { CredentialsReq } from '../../../common/ClientServerInterface';
import { withTranslation } from 'react-i18next';

/* Page to login */

interface LoginPageProps {
    login: (body:CredentialsReq) => void
    loggedIn: boolean
    nextPage: string
    t: (k:string) => string
    tReady: boolean
    stopRedirect?: boolean
}

interface LoginPageState {
    username:string
    password:string
}

class LoginPage extends Component<LoginPageProps, LoginPageState> {

  constructor(props:LoginPageProps) {
    super(props)
    this.state = {
      username : '',
      password: '',
    }
  }

  handleInputChange = (event:any) => {
    const { value, name } = event.target;
    if(name === 'username'){
        this.setState({
          username: value
        })
    }

    if(name === 'password'){
        this.setState({
          password: value
        })
    }
  }

  onSubmit = (event:any) => {
    this.props.login(this.state)
  }

  render() {
    return this.props.loggedIn ? <Redirect to={this.props.nextPage}/> :
    (
      <div style={{display: 'block', width: '100%'}}>
        <FlexboxGrid justify="center" style={{padding: 100}}>
          <FlexboxGrid.Item colspan={8}>
            <Panel header={<h3>Login</h3>} bordered>
                <Form fluid>
                    <FormGroup>
                        <div className="input-group">
                                <label htmlFor="username">{this.props.t('username')}</label>
                                <input
                                  type="text"
                                  name="username"
                                  className="login-input"
                                  placeholder={this.props.t('enter_username')}
                                  value={this.state.username}
                                  onChange={this.handleInputChange}
                                />
                            </div>
                    </FormGroup>
                    <FormGroup>
                        <div className="input-group">
                                <label htmlFor="password">{this.props.t('password')}</label>
                                <input
                                  type="password"
                                  name="password"
                                  className="login-input"
                                  placeholder={this.props.t('enter_password')}
                                  value={this.state.password}
                                  onChange={this.handleInputChange}
                                />
                            </div>
                    </FormGroup>
                    <FormGroup>
                        <ButtonToolbar>
                            <Button appearance="primary" onClick={this.onSubmit}>
                              {this.props.t('sign_in')}
                            </Button>
                            {this.props.stopRedirect ? '' :
                              <Button appearance="link" componentClass={Link} to="/register">
                                {this.props.t('register_new_user')}
                              </Button>
                            }
                        </ButtonToolbar>
                    </FormGroup>
                </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withTranslation()(LoginPage)