import { Component } from "react"
import { Button, Icon, IconButton, Input, Panel } from "rsuite"
import { CommentInfo, MappingCommentInfo } from "../../../../common/ClientServerInterface"
import { formatDate } from "../../utils/utils"
import { withTranslation } from 'react-i18next';
import MyModal from "../helperComponents/MyModal"
import ConfirmationModal from "../helperComponents/ConfirmationModal";

/* Renders a comment */

interface CommentProps{
    text: string
    access: boolean
    uploadedAt: Date
    editedAt: Date
    uploadedByUsername: string
    history: (CommentInfo|MappingCommentInfo)[]
    id: number
    workspaceId: number
    isHistory?: boolean
    removeComment: (c:number) => void
    editComment: (c:number, t:string) => void
    t: (k:string) => string
    tReady: boolean
}

interface CommentState{
    showEditModal: boolean
    showHistoryModal: boolean
    showDeleteModal: boolean
    newComment: string
}

class Comment extends Component<CommentProps, CommentState>{

    constructor(props:CommentProps){
        super(props)
        this.state = {
            showEditModal: false,
            showHistoryModal: false,
            showDeleteModal: false,
            newComment: props.text
        }
    }

    showEditCommentModal(){
        this.setState({showEditModal: true})
    }

    showCommentHistoryModal(){
        this.setState({showHistoryModal: true})
    }

    render(){

        const background_color = this.props.access ? '#DDEEFF' : '#E8F8FF'

        return(
            <>
            <Panel shaded style={{width:'100%', background: background_color, marginTop: 10}}>
                <div style={{ display: "flex", marginTop: 0}}>
                    <p><b>{this.props.text}</b></p>

                    {
                        this.props.access && !this.props.isHistory ?
                        <>
                        <IconButton 
                            icon={<Icon icon="edit" />} 
                            appearance='ghost' 
                            style={{marginLeft: 'auto', marginTop: -10}} 
                            onClick={this.showEditCommentModal.bind(this)}
                        />
                        <IconButton 
                            icon={<Icon icon="trash" />} 
                            appearance='ghost' 
                            style={{marginLeft: 10, marginTop: -10}} 
                            onClick={() => this.setState({showDeleteModal: true})}
                        />
                        </>
                        :
                        ''
                    }
                </div>
                <p style={{fontSize: 13}}>
                    {this.props.isHistory 
                        ? "Edited at: "+formatDate(new Date(this.props.editedAt))+"." 
                        : "Uploaded on: "+formatDate(new Date(this.props.uploadedAt))+" by "+this.props.uploadedByUsername+"."} 
                    {this.props.history.length>0 && !this.props.isHistory ? 
                        <Button 
                            appearance={'link'} 
                            onClick={this.showCommentHistoryModal.bind(this)}
                        >
                            {this.props.t('edited')}
                        </Button> 
                        : ''
                    }
                </p>
            </Panel>

            {this.props.isHistory ? '' :
                <>
                <MyModal 
                    title={this.props.t('edit_comment')} 
                    show={this.state.showEditModal} 
                    onClose={() => this.setState({showEditModal: false})} 
                    onAccept={() => {
                        this.props.editComment(this.props.id, this.state.newComment)
                        this.setState({showEditModal: false})
                    }}
                >
                        <Input onChange={s => this.setState({newComment: s})} value={this.state.newComment}></Input>
                </MyModal>

                <MyModal 
                    title={this.props.t('comment_history')} 
                    show={this.state.showHistoryModal} 
                    onClose={() => this.setState({showHistoryModal: false})} 
                    onAccept={() => this.setState({showHistoryModal: false})}
                >
                        {
                            [...this.props.history, this.props]
                            .sort((a, b) => new Date(a.editedAt)
                            .getTime() - new Date(b.editedAt).getTime())
                            .map(c => 
                                <Comment 
                                    editedAt={c.editedAt} 
                                    history={c.history} 
                                    access={false} 
                                    id={c.id} 
                                    text={c.text} 
                                    uploadedAt={c.uploadedAt} 
                                    uploadedByUsername={c.uploadedByUsername} 
                                    workspaceId={this.props.workspaceId} 
                                    editComment={() => {}} 
                                    removeComment={() => {}}
                                    t={this.props.t}
                                    tReady={this.props.tReady}
                                    isHistory
                                />
                            )
                        }
                </MyModal>

                <ConfirmationModal
                    id={this.props.id}
                    onAccept={() => this.props.removeComment(this.props.id)}
                    onClose={() => this.setState({showDeleteModal: false})}
                    text={this.props.t('delete_comment_confirmation')}
                    show={this.state.showDeleteModal}
                >

                </ConfirmationModal>
                </>
            }
            </>
        )
    }
}

export default withTranslation()(Comment)