// eslint-disable-next-line
export const dreyerContent = 'ID;Event;Title;Role;Date;\n\
14a-027;Initialize;NULL;Ansøger;2013-12-25 12:36:38.983;\n\
14a-027;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2013-12-25 12:36:38.987;\n\
14a-028;Initialize;NULL;Ansøger;2013-12-26 01:07:29.983;\n\
14a-028;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2013-12-26 01:07:29.983;\n\
14a-030;Initialize;NULL;Ansøger;2013-12-28 09:04:07.103;\n\
14a-030;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2013-12-28 09:04:07.103;\n\
14a-032;Initialize;NULL;Ansøger;2013-12-29 04:44:20.523;\n\
14a-032;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2013-12-29 04:44:20.523;\n\
14a-027;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2013-12-30 01:41:21.143;\n\
14a-027;DL_WFCaseTypeStep!518;Change phase to review;suser;2013-12-30 01:41:22.717;\n\
14a-028;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2013-12-30 01:54:44.280;\n\
14a-028;DL_WFCaseTypeStep!518;Change phase to review;suser;2013-12-30 01:54:46.527;\n\
14a-030;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2013-12-30 02:09:51.490;\n\
14a-030;DL_WFCaseTypeStep!518;Change phase to review;suser;2013-12-30 02:09:53.620;\n\
14a-030;DL_WFCaseTypeStep!475;Register Decision;ls;2013-12-30 03:25:42.390;\n\
14a-030;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2013-12-30 03:26:02.763;\n\
14a-027;DL_WFCaseTypeStep!472;Architect Review;mp;2013-12-30 03:34:46.273;\n\
14a-027;DL_WFCaseTypeStep!472;Architect Review;mp;2013-12-30 03:35:19.810;\n\
14a-029;Initialize;NULL;administrator;2013-12-31 02:35:43.050;\n\
14a-029;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2013-12-31 02:35:47.570;\n\
14a-029;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2013-12-31 02:36:40.530;\n\
14a-029;DL_WFCaseTypeStep!518;Change phase to review;suser;2013-12-31 02:36:42.097;\n\
14a-031;Initialize;NULL;administrator;2013-12-31 02:44:33.157;\n\
14a-031;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2013-12-31 02:44:36.797;\n\
14a-031;DL_WFCaseTypeStep!470;Initial rejection;ls;2013-12-31 02:45:01.780;\n\
14a-035;Initialize;NULL;Ansøger;2014-01-02 00:52:37.457;\n\
14a-035;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-02 00:52:37.457;\n\
14a-036;Initialize;NULL;Ansøger;2014-01-02 12:49:25.887;\n\
14a-036;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-02 12:49:25.887;\n\
14a-037;Initialize;NULL;Ansøger;2014-01-02 13:13:58.023;\n\
14a-037;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-02 13:13:58.023;\n\
14a-038;Initialize;NULL;Ansøger;2014-01-02 21:37:43.413;\n\
14a-038;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-02 21:37:43.413;\n\
14a-039;Initialize;NULL;Ansøger;2014-01-03 16:48:45.023;\n\
14a-039;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-03 16:48:45.023;\n\
14a-040;Initialize;NULL;Ansøger;2014-01-03 19:47:03.270;\n\
14a-040;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-03 19:47:03.270;\n\
14a-041;Initialize;NULL;Ansøger;2014-01-04 21:26:55.817;\n\
14a-041;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-04 21:26:55.817;\n\
14a-042;Initialize;NULL;Ansøger;2014-01-04 22:14:32.950;\n\
14a-042;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-04 22:14:32.950;\n\
14a-043;Initialize;NULL;Ansøger;2014-01-04 23:48:00.793;\n\
14a-043;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-04 23:48:00.793;\n\
14a-044;Initialize;NULL;Ansøger;2014-01-05 16:57:06.980;\n\
14a-044;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-05 16:57:06.980;\n\
14a-045;Initialize;NULL;Ansøger;2014-01-05 20:07:10.087;\n\
14a-045;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-05 20:07:10.087;\n\
14a-046;Initialize;NULL;Ansøger;2014-01-05 22:04:52.330;\n\
14a-046;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-05 22:04:52.330;\n\
14a-047;Initialize;NULL;Ansøger;2014-01-05 22:10:33.873;\n\
14a-047;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-05 22:10:33.873;\n\
14a-048;Initialize;NULL;Ansøger;2014-01-05 22:14:20.980;\n\
14a-048;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-05 22:14:20.980;\n\
14a-049;Initialize;NULL;Ansøger;2014-01-06 03:37:46.360;\n\
14a-049;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-06 03:37:46.360;\n\
14a-051;Initialize;NULL;Ansøger;2014-01-06 11:30:31.700;\n\
14a-051;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-06 11:30:31.700;\n\
14a-052;Initialize;NULL;Ansøger;2014-01-06 12:41:19.867;\n\
14a-052;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-06 12:41:19.867;\n\
14a-053;Initialize;NULL;Ansøger;2014-01-06 16:55:40.737;\n\
14a-053;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-06 16:55:40.737;\n\
14a-054;Initialize;NULL;Ansøger;2014-01-06 19:11:44.787;\n\
14a-054;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-06 19:11:44.790;\n\
14a-055;Initialize;NULL;Ansøger;2014-01-06 20:04:12.733;\n\
14a-055;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-06 20:04:12.733;\n\
14a-056;Initialize;NULL;Ansøger;2014-01-06 20:53:45.617;\n\
14a-056;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-06 20:53:45.617;\n\
14a-057;Initialize;NULL;Ansøger;2014-01-06 21:29:57.077;\n\
14a-057;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-06 21:29:57.077;\n\
14a-059;Initialize;NULL;Ansøger;2014-01-07 10:57:02.033;\n\
14a-059;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-07 10:57:02.263;\n\
14a-060;Initialize;NULL;Ansøger;2014-01-07 14:16:13.453;\n\
14a-060;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-07 14:16:13.453;\n\
14a-061;Initialize;NULL;Ansøger;2014-01-07 16:00:28.563;\n\
14a-061;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-07 16:00:28.567;\n\
14a-063;Initialize;NULL;Ansøger;2014-01-08 09:55:05.683;\n\
14a-063;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-08 09:55:05.683;\n\
14a-064;Initialize;NULL;Ansøger;2014-01-08 11:05:04.640;\n\
14a-064;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-08 11:05:04.640;\n\
14a-065;Initialize;NULL;Ansøger;2014-01-08 11:40:25.957;\n\
14a-065;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-08 11:40:26.110;\n\
14a-066;Initialize;NULL;Ansøger;2014-01-08 15:23:03.983;\n\
14a-066;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-08 15:23:03.983;\n\
14a-067;Initialize;NULL;Ansøger;2014-01-08 15:32:13.563;\n\
14a-067;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-08 15:32:13.563;\n\
14a-068;Initialize;NULL;Ansøger;2014-01-08 15:48:36.243;\n\
14a-068;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-08 15:48:36.243;\n\
14a-069;Initialize;NULL;Ansøger;2014-01-08 20:46:14.210;\n\
14a-069;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-08 20:46:14.210;\n\
14a-070;Initialize;NULL;Ansøger;2014-01-08 22:32:28.300;\n\
14a-070;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-08 22:32:28.300;\n\
14a-071;Initialize;NULL;Ansøger;2014-01-09 11:36:05.130;\n\
14a-071;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 11:36:05.130;\n\
14a-072;Initialize;NULL;Ansøger;2014-01-09 11:39:34.290;\n\
14a-072;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 11:39:34.290;\n\
14a-073;Initialize;NULL;Ansøger;2014-01-09 11:57:32.800;\n\
14a-073;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 11:57:32.800;\n\
14a-074;Initialize;NULL;Ansøger;2014-01-09 11:58:31.303;\n\
14a-074;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 11:58:31.303;\n\
14a-075;Initialize;NULL;Ansøger;2014-01-09 12:45:29.917;\n\
14a-075;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 12:45:29.917;\n\
14a-076;Initialize;NULL;Ansøger;2014-01-09 13:16:33.400;\n\
14a-076;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 13:16:33.400;\n\
14a-077;Initialize;NULL;Ansøger;2014-01-09 13:40:03.620;\n\
14a-077;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 13:40:03.620;\n\
14a-078;Initialize;NULL;Ansøger;2014-01-09 13:56:41.843;\n\
14a-078;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 13:56:41.843;\n\
14a-079;Initialize;NULL;Ansøger;2014-01-09 15:00:48.327;\n\
14a-079;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 15:00:48.330;\n\
14a-080;Initialize;NULL;Ansøger;2014-01-09 15:24:15.480;\n\
14a-080;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 15:24:15.480;\n\
14a-081;Initialize;NULL;Ansøger;2014-01-09 15:35:35.743;\n\
14a-081;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 15:35:35.743;\n\
14a-082;Initialize;NULL;Ansøger;2014-01-09 15:54:51.677;\n\
14a-082;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 15:54:51.677;\n\
14a-083;Initialize;NULL;Ansøger;2014-01-09 17:02:14.670;\n\
14a-083;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 17:02:14.670;\n\
14a-084;Initialize;NULL;Ansøger;2014-01-09 18:03:13.890;\n\
14a-084;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 18:03:13.890;\n\
14a-088;Initialize;NULL;Ansøger;2014-01-09 22:18:37.813;\n\
14a-088;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-09 22:18:37.813;\n\
14a-090;Initialize;NULL;Ansøger;2014-01-10 10:18:38.403;\n\
14a-090;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-10 10:18:38.403;\n\
14a-091;Initialize;NULL;Ansøger;2014-01-10 12:24:42.403;\n\
14a-091;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-10 12:24:42.403;\n\
14a-092;Initialize;NULL;Ansøger;2014-01-10 12:47:25.110;\n\
14a-092;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-10 12:47:25.110;\n\
14a-094;Initialize;NULL;Ansøger;2014-01-10 14:14:18.803;\n\
14a-094;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-10 14:14:18.807;\n\
14a-095;Initialize;NULL;Ansøger;2014-01-10 14:17:36.570;\n\
14a-095;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-10 14:17:36.570;\n\
14a-096;Initialize;NULL;Ansøger;2014-01-10 14:56:56.983;\n\
14a-096;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-10 14:56:56.983;\n\
14a-097;Initialize;NULL;Ansøger;2014-01-11 23:24:24.300;\n\
14a-097;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-11 23:24:24.300;\n\
14a-098;Initialize;NULL;Ansøger;2014-01-12 00:54:49.383;\n\
14a-098;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-12 00:54:49.383;\n\
14a-099;Initialize;NULL;Ansøger;2014-01-12 13:47:45.890;\n\
14a-099;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-12 13:47:45.890;\n\
14a-100;Initialize;NULL;Ansøger;2014-01-12 14:40:46.450;\n\
14a-100;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-12 14:40:46.450;\n\
14a-101;Initialize;NULL;Ansøger;2014-01-12 18:12:56.393;\n\
14a-101;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-12 18:12:56.393;\n\
14a-102;Initialize;NULL;Ansøger;2014-01-12 19:57:49.917;\n\
14a-102;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-12 19:57:49.917;\n\
14a-103;Initialize;NULL;Ansøger;2014-01-12 22:26:36.657;\n\
14a-103;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-12 22:26:36.657;\n\
14a-104;Initialize;NULL;Ansøger;2014-01-13 10:51:23.350;\n\
14a-104;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 10:51:23.350;\n\
14a-105;Initialize;NULL;Ansøger;2014-01-13 12:21:38.633;\n\
14a-105;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 12:21:38.633;\n\
14a-106;Initialize;NULL;Ansøger;2014-01-13 12:34:08.863;\n\
14a-106;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 12:34:08.867;\n\
14a-107;Initialize;NULL;Ansøger;2014-01-13 13:03:39.277;\n\
14a-107;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 13:03:39.277;\n\
14a-108;Initialize;NULL;Ansøger;2014-01-13 13:09:05.400;\n\
14a-108;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 13:09:05.400;\n\
14a-109;Initialize;NULL;Ansøger;2014-01-13 13:29:47.230;\n\
14a-109;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 13:29:47.230;\n\
14a-110;Initialize;NULL;Ansøger;2014-01-13 13:50:50.983;\n\
14a-110;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 13:50:50.983;\n\
14a-111;Initialize;NULL;Ansøger;2014-01-13 14:04:57.593;\n\
14a-111;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 14:04:57.593;\n\
14a-112;Initialize;NULL;Ansøger;2014-01-13 14:30:19.430;\n\
14a-112;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 14:30:19.430;\n\
14a-113;Initialize;NULL;Ansøger;2014-01-13 15:22:50.633;\n\
14a-113;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 15:22:50.633;\n\
14a-114;Initialize;NULL;Ansøger;2014-01-13 15:36:49.900;\n\
14a-114;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 15:36:49.900;\n\
14a-115;Initialize;NULL;Ansøger;2014-01-13 17:19:51.000;\n\
14a-115;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 17:19:51.003;\n\
14a-116;Initialize;NULL;Ansøger;2014-01-13 19:10:18.067;\n\
14a-116;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 19:10:18.067;\n\
14a-117;Initialize;NULL;Ansøger;2014-01-13 20:41:55.830;\n\
14a-117;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 20:41:55.830;\n\
14a-118;Initialize;NULL;Ansøger;2014-01-13 21:28:22.993;\n\
14a-118;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 21:28:22.993;\n\
14a-119;Initialize;NULL;Ansøger;2014-01-13 22:07:58.517;\n\
14a-119;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 22:07:58.517;\n\
14a-120;Initialize;NULL;Ansøger;2014-01-13 22:22:57.470;\n\
14a-120;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 22:22:57.470;\n\
14a-121;Initialize;NULL;Ansøger;2014-01-13 22:56:26.290;\n\
14a-121;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 22:56:26.290;\n\
14a-122;Initialize;NULL;Ansøger;2014-01-13 23:02:30.393;\n\
14a-122;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 23:02:30.393;\n\
14a-123;Initialize;NULL;Ansøger;2014-01-13 23:05:02.837;\n\
14a-123;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 23:05:02.837;\n\
14a-124;Initialize;NULL;Ansøger;2014-01-13 23:28:58.240;\n\
14a-124;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 23:28:58.240;\n\
14a-125;Initialize;NULL;Ansøger;2014-01-13 23:33:23.413;\n\
14a-125;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-13 23:33:23.417;\n\
14a-126;Initialize;NULL;Ansøger;2014-01-14 00:25:03.183;\n\
14a-126;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 00:25:03.183;\n\
14a-127;Initialize;NULL;Ansøger;2014-01-14 08:59:59.347;\n\
14a-127;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 08:59:59.350;\n\
14a-128;Initialize;NULL;Ansøger;2014-01-14 09:47:04.020;\n\
14a-128;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 09:47:04.020;\n\
14a-129;Initialize;NULL;Ansøger;2014-01-14 09:57:21.377;\n\
14a-129;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 09:57:21.377;\n\
14a-130;Initialize;NULL;Ansøger;2014-01-14 10:35:52.163;\n\
14a-130;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 10:35:52.167;\n\
14a-131;Initialize;NULL;Ansøger;2014-01-14 11:22:22.993;\n\
14a-131;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 11:22:22.993;\n\
14a-132;Initialize;NULL;Ansøger;2014-01-14 11:55:18.273;\n\
14a-132;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 11:55:18.273;\n\
14a-133;Initialize;NULL;Ansøger;2014-01-14 12:00:07.483;\n\
14a-133;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 12:00:07.487;\n\
14a-134;Initialize;NULL;Ansøger;2014-01-14 12:21:27.713;\n\
14a-134;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 12:21:27.713;\n\
14a-135;Initialize;NULL;Ansøger;2014-01-14 12:45:53.397;\n\
14a-135;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 12:45:53.397;\n\
14a-136;Initialize;NULL;Ansøger;2014-01-14 12:47:45.770;\n\
14a-136;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 12:47:45.770;\n\
14a-137;Initialize;NULL;Ansøger;2014-01-14 12:55:33.047;\n\
14a-137;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 12:55:33.047;\n\
14a-138;Initialize;NULL;Ansøger;2014-01-14 12:58:38.890;\n\
14a-138;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 12:58:38.890;\n\
14a-139;Initialize;NULL;Ansøger;2014-01-14 13:05:11.697;\n\
14a-139;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 13:05:11.697;\n\
14a-140;Initialize;NULL;Ansøger;2014-01-14 13:17:41.977;\n\
14a-140;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 13:17:41.977;\n\
14a-141;Initialize;NULL;Ansøger;2014-01-14 13:42:48.083;\n\
14a-141;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 13:42:48.083;\n\
14a-142;Initialize;NULL;Ansøger;2014-01-14 13:59:23.237;\n\
14a-142;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 13:59:23.237;\n\
14a-143;Initialize;NULL;Ansøger;2014-01-14 14:03:03.440;\n\
14a-143;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 14:03:03.440;\n\
14a-144;Initialize;NULL;Ansøger;2014-01-14 14:18:20.983;\n\
14a-144;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 14:18:20.983;\n\
14a-145;Initialize;NULL;Ansøger;2014-01-14 14:35:55.223;\n\
14a-145;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 14:35:55.223;\n\
14a-146;Initialize;NULL;Ansøger;2014-01-14 15:28:59.360;\n\
14a-146;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 15:28:59.360;\n\
14a-147;Initialize;NULL;Ansøger;2014-01-14 16:12:47.987;\n\
14a-147;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 16:12:47.987;\n\
14a-148;Initialize;NULL;Ansøger;2014-01-14 17:06:44.070;\n\
14a-148;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 17:06:44.073;\n\
14a-149;Initialize;NULL;Ansøger;2014-01-14 17:26:57.487;\n\
14a-149;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 17:26:57.487;\n\
14a-150;Initialize;NULL;Ansøger;2014-01-14 17:27:55.743;\n\
14a-150;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 17:27:55.743;\n\
14a-151;Initialize;NULL;Ansøger;2014-01-14 18:13:19.693;\n\
14a-151;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 18:13:19.693;\n\
14a-152;Initialize;NULL;Ansøger;2014-01-14 18:19:03.703;\n\
14a-152;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 18:19:03.703;\n\
14a-153;Initialize;NULL;Ansøger;2014-01-14 18:23:20.833;\n\
14a-153;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 18:23:20.833;\n\
14a-154;Initialize;NULL;Ansøger;2014-01-14 18:23:30.183;\n\
14a-154;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 18:23:30.183;\n\
14a-119;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-01-14 18:37:08.260;\n\
14a-119;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-01-14 18:37:14.860;\n\
14a-119;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-01-14 18:37:18.483;\n\
14a-155;Initialize;NULL;Ansøger;2014-01-14 18:41:01.230;\n\
14a-155;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 18:41:01.233;\n\
14a-156;Initialize;NULL;Ansøger;2014-01-14 19:02:39.843;\n\
14a-156;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 19:02:39.843;\n\
14a-157;Initialize;NULL;Ansøger;2014-01-14 20:28:06.313;\n\
14a-157;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 20:28:06.313;\n\
14a-158;Initialize;NULL;Ansøger;2014-01-14 20:54:27.407;\n\
14a-158;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 20:54:27.407;\n\
14a-159;Initialize;NULL;Ansøger;2014-01-14 20:55:45.840;\n\
14a-159;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 20:55:45.840;\n\
14a-160;Initialize;NULL;Ansøger;2014-01-14 21:49:46.730;\n\
14a-160;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 21:49:46.730;\n\
14a-161;Initialize;NULL;Ansøger;2014-01-14 21:49:47.320;\n\
14a-161;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 21:49:47.320;\n\
14a-162;Initialize;NULL;Ansøger;2014-01-14 22:06:45.800;\n\
14a-162;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 22:06:45.800;\n\
14a-163;Initialize;NULL;Ansøger;2014-01-14 22:07:30.717;\n\
14a-163;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 22:07:30.720;\n\
14a-164;Initialize;NULL;Ansøger;2014-01-14 22:08:30.603;\n\
14a-164;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 22:08:30.603;\n\
14a-165;Initialize;NULL;Ansøger;2014-01-14 22:34:37.093;\n\
14a-165;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 22:34:37.093;\n\
14a-166;Initialize;NULL;Ansøger;2014-01-14 22:42:19.450;\n\
14a-166;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 22:42:19.450;\n\
14a-167;Initialize;NULL;Ansøger;2014-01-14 22:42:26.607;\n\
14a-167;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 22:42:26.607;\n\
14a-168;Initialize;NULL;Ansøger;2014-01-14 22:45:35.313;\n\
14a-168;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 22:45:35.313;\n\
14a-169;Initialize;NULL;Ansøger;2014-01-14 22:55:39.540;\n\
14a-169;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 22:55:39.540;\n\
14a-170;Initialize;NULL;Ansøger;2014-01-14 23:06:47.907;\n\
14a-170;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 23:06:47.907;\n\
14a-171;Initialize;NULL;Ansøger;2014-01-14 23:19:47.793;\n\
14a-171;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 23:19:47.793;\n\
14a-172;Initialize;NULL;Ansøger;2014-01-14 23:41:21.697;\n\
14a-172;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-14 23:41:21.697;\n\
14a-173;Initialize;NULL;Ansøger;2014-01-15 00:04:05.100;\n\
14a-173;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 00:04:05.100;\n\
14a-174;Initialize;NULL;Ansøger;2014-01-15 01:09:18.720;\n\
14a-174;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 01:09:18.720;\n\
14a-175;Initialize;NULL;Ansøger;2014-01-15 01:45:37.457;\n\
14a-175;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 01:45:37.457;\n\
14a-176;Initialize;NULL;Ansøger;2014-01-15 04:52:57.603;\n\
14a-176;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 04:52:57.603;\n\
14a-177;Initialize;NULL;Ansøger;2014-01-15 08:45:11.360;\n\
14a-177;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 08:45:11.360;\n\
14a-178;Initialize;NULL;Ansøger;2014-01-15 09:23:38.187;\n\
14a-178;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 09:23:38.187;\n\
14a-179;Initialize;NULL;Ansøger;2014-01-15 10:22:36.547;\n\
14a-179;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 10:22:36.547;\n\
14a-180;Initialize;NULL;Ansøger;2014-01-15 10:26:09.583;\n\
14a-180;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 10:26:09.583;\n\
14a-181;Initialize;NULL;Ansøger;2014-01-15 11:03:11.353;\n\
14a-181;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 11:03:11.353;\n\
14a-182;Initialize;NULL;Ansøger;2014-01-15 11:20:49.383;\n\
14a-182;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 11:20:49.383;\n\
14a-183;Initialize;NULL;Ansøger;2014-01-15 11:23:21.000;\n\
14a-183;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 11:23:21.003;\n\
14a-184;Initialize;NULL;Ansøger;2014-01-15 11:28:33.140;\n\
14a-184;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 11:28:33.140;\n\
14a-185;Initialize;NULL;Ansøger;2014-01-15 11:33:01.107;\n\
14a-185;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 11:33:01.107;\n\
14a-186;Initialize;NULL;Ansøger;2014-01-15 11:34:19.780;\n\
14a-186;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 11:34:19.780;\n\
14a-187;Initialize;NULL;Ansøger;2014-01-15 11:43:53.507;\n\
14a-187;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 11:43:53.507;\n\
14a-188;Initialize;NULL;Ansøger;2014-01-15 11:45:40.827;\n\
14a-188;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 11:45:40.827;\n\
14a-189;Initialize;NULL;Ansøger;2014-01-15 12:20:49.673;\n\
14a-189;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 12:20:49.673;\n\
14a-190;Initialize;NULL;Ansøger;2014-01-15 12:41:54.840;\n\
14a-190;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 12:41:54.840;\n\
14a-191;Initialize;NULL;Ansøger;2014-01-15 12:59:07.333;\n\
14a-191;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 12:59:07.333;\n\
14a-192;Initialize;NULL;Ansøger;2014-01-15 13:06:35.277;\n\
14a-192;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 13:06:35.277;\n\
14a-193;Initialize;NULL;Ansøger;2014-01-15 13:28:58.530;\n\
14a-193;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 13:28:58.530;\n\
14a-194;Initialize;NULL;Ansøger;2014-01-15 13:38:19.850;\n\
14a-194;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 13:38:19.850;\n\
14a-195;Initialize;NULL;Ansøger;2014-01-15 13:42:42.190;\n\
14a-195;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 13:42:42.190;\n\
14a-196;Initialize;NULL;Ansøger;2014-01-15 13:55:03.147;\n\
14a-196;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 13:55:03.147;\n\
14a-197;Initialize;NULL;Ansøger;2014-01-15 13:55:36.093;\n\
14a-197;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 13:55:36.093;\n\
14a-198;Initialize;NULL;Ansøger;2014-01-15 14:10:51.400;\n\
14a-198;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 14:10:51.400;\n\
14a-199;Initialize;NULL;Ansøger;2014-01-15 14:28:21.213;\n\
14a-199;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 14:28:21.213;\n\
14a-200;Initialize;NULL;Ansøger;2014-01-15 14:50:04.753;\n\
14a-200;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 14:50:04.753;\n\
14a-201;Initialize;NULL;Ansøger;2014-01-15 14:54:44.743;\n\
14a-201;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 14:54:44.743;\n\
14a-202;Initialize;NULL;Ansøger;2014-01-15 14:56:03.670;\n\
14a-202;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 14:56:03.670;\n\
14a-203;Initialize;NULL;Ansøger;2014-01-15 15:28:05.400;\n\
14a-203;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 15:28:05.400;\n\
14a-204;Initialize;NULL;Ansøger;2014-01-15 15:37:30.697;\n\
14a-204;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 15:37:30.697;\n\
14a-205;Initialize;NULL;Ansøger;2014-01-15 16:01:39.467;\n\
14a-205;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 16:01:39.467;\n\
14a-206;Initialize;NULL;Ansøger;2014-01-15 16:25:57.650;\n\
14a-206;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 16:25:57.650;\n\
14a-207;Initialize;NULL;Ansøger;2014-01-15 16:40:19.283;\n\
14a-207;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 16:40:19.283;\n\
14a-208;Initialize;NULL;Ansøger;2014-01-15 16:41:35.410;\n\
14a-208;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 16:41:35.410;\n\
14a-209;Initialize;NULL;Ansøger;2014-01-15 17:26:59.257;\n\
14a-209;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 17:26:59.257;\n\
14a-210;Initialize;NULL;Ansøger;2014-01-15 17:48:38.877;\n\
14a-210;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 17:48:38.877;\n\
14a-211;Initialize;NULL;Ansøger;2014-01-15 19:21:10.620;\n\
14a-211;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 19:21:10.620;\n\
14a-212;Initialize;NULL;Ansøger;2014-01-15 19:23:39.537;\n\
14a-212;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 19:23:39.537;\n\
14a-213;Initialize;NULL;Ansøger;2014-01-15 19:29:21.693;\n\
14a-213;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 19:29:21.693;\n\
14a-214;Initialize;NULL;Ansøger;2014-01-15 19:51:19.290;\n\
14a-214;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 19:51:19.290;\n\
14a-215;Initialize;NULL;Ansøger;2014-01-15 19:52:17.483;\n\
14a-215;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 19:52:17.483;\n\
14a-216;Initialize;NULL;Ansøger;2014-01-15 19:55:05.780;\n\
14a-216;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 19:55:05.780;\n\
14a-217;Initialize;NULL;Ansøger;2014-01-15 19:58:52.850;\n\
14a-217;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 19:58:52.850;\n\
14a-218;Initialize;NULL;Ansøger;2014-01-15 20:03:22.330;\n\
14a-218;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 20:03:22.333;\n\
14a-219;Initialize;NULL;Ansøger;2014-01-15 20:08:16.260;\n\
14a-219;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 20:08:16.260;\n\
14a-220;Initialize;NULL;Ansøger;2014-01-15 20:34:00.113;\n\
14a-220;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 20:34:00.113;\n\
14a-221;Initialize;NULL;Ansøger;2014-01-15 20:35:32.827;\n\
14a-221;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 20:35:32.830;\n\
14a-222;Initialize;NULL;Ansøger;2014-01-15 20:45:34.790;\n\
14a-222;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 20:45:34.790;\n\
14a-223;Initialize;NULL;Ansøger;2014-01-15 20:53:16.227;\n\
14a-223;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 20:53:16.227;\n\
14a-224;Initialize;NULL;Ansøger;2014-01-15 20:56:02.350;\n\
14a-224;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 20:56:02.350;\n\
14a-225;Initialize;NULL;Ansøger;2014-01-15 21:29:42.590;\n\
14a-225;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 21:29:42.590;\n\
14a-226;Initialize;NULL;Ansøger;2014-01-15 22:28:21.423;\n\
14a-226;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 22:28:21.427;\n\
14a-227;Initialize;NULL;Ansøger;2014-01-15 22:30:02.813;\n\
14a-227;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 22:30:02.813;\n\
14a-228;Initialize;NULL;Ansøger;2014-01-15 22:31:28.010;\n\
14a-228;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 22:31:28.010;\n\
14a-229;Initialize;NULL;Ansøger;2014-01-15 22:41:27.330;\n\
14a-229;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 22:41:27.330;\n\
14a-230;Initialize;NULL;Ansøger;2014-01-15 22:55:04.500;\n\
14a-230;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 22:55:04.500;\n\
14a-231;Initialize;NULL;Ansøger;2014-01-15 22:55:20.500;\n\
14a-231;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 22:55:20.503;\n\
14a-232;Initialize;NULL;Ansøger;2014-01-15 23:19:08.800;\n\
14a-232;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 23:19:08.800;\n\
14a-233;Initialize;NULL;Ansøger;2014-01-15 23:23:53.420;\n\
14a-233;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 23:23:53.420;\n\
14a-234;Initialize;NULL;Ansøger;2014-01-15 23:30:25.783;\n\
14a-234;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 23:30:25.783;\n\
14a-235;Initialize;NULL;Ansøger;2014-01-15 23:42:28.083;\n\
14a-235;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 23:42:28.083;\n\
14a-236;Initialize;NULL;Ansøger;2014-01-15 23:44:16.953;\n\
14a-236;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 23:44:16.957;\n\
14a-237;Initialize;NULL;Ansøger;2014-01-15 23:44:28.450;\n\
14a-237;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 23:44:28.450;\n\
14a-238;Initialize;NULL;Ansøger;2014-01-15 23:47:17.230;\n\
14a-238;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 23:47:17.233;\n\
14a-239;Initialize;NULL;Ansøger;2014-01-15 23:53:38.590;\n\
14a-239;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 23:53:38.590;\n\
14a-240;Initialize;NULL;Ansøger;2014-01-15 23:55:50.217;\n\
14a-240;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 23:55:50.217;\n\
14a-241;Initialize;NULL;Ansøger;2014-01-15 23:56:50.077;\n\
14a-241;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-15 23:56:50.077;\n\
14a-242;Initialize;NULL;Ansøger;2014-01-16 00:01:02.840;\n\
14a-242;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-16 00:01:02.840;\n\
14a-243;Initialize;NULL;Ansøger;2014-01-16 00:02:26.480;\n\
14a-243;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-16 00:02:26.480;\n\
14a-244;Initialize;NULL;Ansøger;2014-01-16 00:25:18.163;\n\
14a-244;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-16 00:25:18.163;\n\
14a-245;Initialize;NULL;Ansøger;2014-01-16 00:33:15.197;\n\
14a-245;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-16 00:33:15.197;\n\
14a-032;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-16 13:30:26.103;\n\
14a-032;DL_WFCaseTypeStep!518;Change phase to review;suser;2014-01-16 13:30:29.930;\n\
14a-035;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-16 13:37:28.310;\n\
14a-035;DL_WFCaseTypeStep!518;Change phase to review;suser;2014-01-16 13:37:30.800;\n\
14a-037;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-16 13:43:41.740;\n\
14a-037;DL_WFCaseTypeStep!518;Change phase to review;suser;2014-01-16 13:43:44.443;\n\
14a-036;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-16 13:45:54.980;\n\
14a-036;DL_WFCaseTypeStep!518;Change phase to review;suser;2014-01-16 13:45:57.830;\n\
14a-030;DL_WFCaseTypeStep!475;Register Decision;ls;2014-01-16 14:11:57.767;\n\
14a-030;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-01-16 14:12:02.617;\n\
14a-246;Initialize;NULL;Ansøger;2014-01-16 14:50:46.487;\n\
14a-246;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-16 14:50:46.490;\n\
14a-247;Initialize;NULL;Ansøger;2014-01-18 02:24:43.293;\n\
14a-247;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-18 02:24:43.293;\n\
14a-249;Initialize;NULL;Ansøger;2014-01-22 16:34:11.120;\n\
14a-249;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-22 16:34:11.133;\n\
14a-249;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-22 22:51:37.860;\n\
14a-249;DL_WFCaseTypeStep!518;Change phase to review;suser;2014-01-22 22:51:40.813;\n\
14a-249;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-01-22 22:51:45.567;\n\
14a-249;DL_WFCaseTypeStep!475;Register Decision;ls;2014-01-22 22:53:16.650;\n\
14a-249;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-01-22 22:54:02.940;\n\
14a-249;DL_WFCaseTypeStep!478;Approve application;ls;2014-01-22 23:03:47.303;\n\
14a-249;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-01-22 23:03:49.577;\n\
14a-249;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-01-22 23:03:49.630;\n\
14a-249;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-01-22 23:03:53.790;\n\
14a-249;DL_WFCaseTypeStep!522;Change Phase to Payout;suser;2014-01-22 23:03:57.223;\n\
14a-249;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-22 23:06:06.160;\n\
14a-249;DL_WFCaseTypeStep!518;Change phase to review;suser;2014-01-22 23:06:07.917;\n\
14a-249;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-01-22 23:06:13.587;\n\
14a-249;DL_WFCaseTypeStep!522;Change Phase to Payout;suser;2014-01-22 23:06:15.050;\n\
14a-250;Initialize;NULL;Ansøger;2014-01-24 12:25:40.197;\n\
14a-250;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-24 12:25:40.210;\n\
14a-040;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 18:23:34.543;\n\
14a-040;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 18:23:38.653;\n\
14a-040;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-01-24 18:23:46.890;\n\
14a-040;DL_WFCaseTypeStep!475;Register Decision;ls;2014-01-24 18:26:48.463;\n\
14a-040;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-01-24 18:27:02.823;\n\
14a-040;DL_WFCaseTypeStep!478;Approve application;ls;2014-01-24 18:27:50.050;\n\
14a-040;DL_WFCaseTypeStep!531;Change phase to Preparation;ANONYMOUS LOGON;2014-01-24 18:27:52.190;\n\
14a-040;DL_WFCaseTypeStep!542;Set to Pre-approved;ANONYMOUS LOGON;2014-01-24 18:27:52.193;\n\
14a-038;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 18:34:44.197;\n\
14a-038;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 18:34:47.453;\n\
14a-039;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 18:35:44.797;\n\
14a-039;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 18:35:46.403;\n\
14a-041;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 18:36:04.400;\n\
14a-041;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 18:36:05.900;\n\
14a-040;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-01-24 19:02:03.197;\n\
14a-040;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2014-01-24 19:02:08.063;\n\
14a-042;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 19:52:47.277;\n\
14a-042;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 19:52:51.450;\n\
14a-043;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-01-24 19:55:14.150;\n\
14a-044;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 19:55:34.520;\n\
14a-044;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 19:55:36.037;\n\
14a-045;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 19:56:16.120;\n\
14a-045;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 19:56:17.610;\n\
14a-046;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 19:56:54.210;\n\
14a-046;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 19:56:56.543;\n\
14a-048;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 19:57:10.950;\n\
14a-048;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 19:57:12.430;\n\
14a-049;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 19:57:29.253;\n\
14a-049;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 19:57:31.597;\n\
14a-051;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 19:57:47.610;\n\
14a-051;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 19:57:49.850;\n\
14a-052;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 19:58:07.773;\n\
14a-052;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 19:58:09.747;\n\
14a-053;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 19:58:26.743;\n\
14a-053;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 19:58:28.957;\n\
14a-054;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-24 19:59:15.433;\n\
14a-054;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-24 19:59:16.973;\n\
14a-047;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-01-24 20:04:01.310;\n\
14a-251;Initialize;NULL;Ansøger;2014-01-27 13:37:24.390;\n\
14a-251;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-27 13:37:24.517;\n\
14a-055;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-27 17:59:47.953;\n\
14a-055;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-27 17:59:51.933;\n\
14a-056;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-27 18:00:06.920;\n\
14a-056;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-27 18:00:08.667;\n\
14a-057;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-27 18:00:27.940;\n\
14a-057;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-27 18:00:30.103;\n\
14a-059;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-27 18:01:10.193;\n\
14a-059;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-27 18:01:11.660;\n\
14a-060;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-27 18:01:58.010;\n\
14a-060;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-27 18:02:00.310;\n\
14a-061;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-27 18:02:24.157;\n\
14a-061;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-27 18:02:25.560;\n\
14a-063;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-27 18:02:55.827;\n\
14a-063;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-27 18:02:57.987;\n\
14a-252;Initialize;NULL;Ansøger;2014-01-27 18:32:35.837;\n\
14a-252;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-27 18:32:35.837;\n\
14a-253;Initialize;NULL;Ansøger;2014-01-27 18:57:03.500;\n\
14a-253;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-27 18:57:03.503;\n\
14a-026;Initialize;NULL;administrator;2014-01-28 15:08:42.450;\n\
14a-026;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-28 15:08:45.810;\n\
14a-026;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-28 15:08:53.447;\n\
14a-026;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-28 15:08:54.907;\n\
14a-058;Initialize;NULL;administrator;2014-01-28 15:10:09.217;\n\
14a-058;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-28 15:10:12.137;\n\
14a-058;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-01-28 15:10:46.880;\n\
14a-254;Initialize;NULL;Ansøger;2014-01-29 08:02:29.403;\n\
14a-254;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-01-29 08:02:29.403;\n\
14a-064;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-30 15:40:51.283;\n\
14a-064;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-01-30 15:40:53.820;\n\
14a-029;DL_WFCaseTypeStep!472;Architect Review;mp;2014-01-30 15:46:10.690;\n\
14a-065;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-01-30 15:47:19.343;\n\
14a-065;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-01-30 15:47:20.743;\n\
14a-029;DL_WFCaseTypeStep!474;Review;bry;2014-01-30 15:52:17.603;\n\
14a-029;DL_WFCaseTypeStep!474;Review;bry;2014-01-30 15:59:01.350;\n\
14a-029;DL_WFCaseTypeStep!474;Review;bry;2014-01-30 15:59:40.980;\n\
14a-038;DL_WFCaseTypeStep!475;Register Decision;ls;2014-01-30 16:33:35.717;\n\
14a-038;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-01-30 16:34:04.737;\n\
14a-029;DL_WFCaseTypeStep!473;Review;br;2014-02-02 18:04:13.513;\n\
14a-255;Initialize;NULL;Ansøger;2014-02-03 10:48:31.693;\n\
14a-255;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-03 10:48:31.720;\n\
14a-256;Initialize;NULL;Ansøger;2014-02-03 12:32:34.890;\n\
14a-256;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-03 12:32:34.890;\n\
14a-257;Initialize;NULL;Ansøger;2014-02-03 13:13:33.823;\n\
14a-257;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-03 13:13:33.823;\n\
14a-258;Initialize;NULL;Ansøger;2014-02-03 13:34:25.067;\n\
14a-258;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-03 13:34:25.067;\n\
14a-259;Initialize;NULL;Ansøger;2014-02-03 14:58:14.577;\n\
14a-259;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-03 14:58:14.577;\n\
14a-260;Initialize;NULL;Ansøger;2014-02-03 16:05:48.517;\n\
14a-260;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-03 16:05:48.517;\n\
14a-261;Initialize;NULL;Ansøger;2014-02-03 18:51:09.133;\n\
14a-261;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-03 18:51:09.133;\n\
14a-262;Initialize;NULL;Ansøger;2014-02-04 13:57:11.397;\n\
14a-262;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-04 13:57:11.400;\n\
14a-263;Initialize;NULL;Ansøger;2014-02-04 14:05:38.833;\n\
14a-263;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-04 14:05:38.833;\n\
14a-264;Initialize;NULL;Ansøger;2014-02-04 14:44:58.817;\n\
14a-264;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-04 14:44:58.817;\n\
14a-265;Initialize;NULL;Ansøger;2014-02-04 16:08:45.493;\n\
14a-265;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-04 16:08:45.493;\n\
14a-266;Initialize;NULL;Ansøger;2014-02-04 18:52:21.583;\n\
14a-266;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-04 18:52:21.583;\n\
14a-267;Initialize;NULL;Ansøger;2014-02-04 19:37:03.980;\n\
14a-267;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-04 19:37:03.980;\n\
14a-178;DL_WFCaseTypeStep!541;Account number changed;ls;2014-02-05 16:22:53.607;\n\
14a-099;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-05 16:29:55.820;\n\
14a-211;DL_WFCaseTypeStep!541;Account number changed;ls;2014-02-05 17:00:07.347;\n\
14a-268;Initialize;NULL;Ansøger;2014-02-06 13:17:45.903;\n\
14a-268;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-06 13:17:45.907;\n\
14a-269;Initialize;NULL;Ansøger;2014-02-06 13:48:08.647;\n\
14a-269;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-06 13:48:08.647;\n\
14a-270;Initialize;NULL;Ansøger;2014-02-06 14:13:35.907;\n\
14a-270;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-06 14:13:35.910;\n\
14a-271;Initialize;NULL;Ansøger;2014-02-06 14:45:30.520;\n\
14a-271;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-06 14:45:30.520;\n\
14a-272;Initialize;NULL;Ansøger;2014-02-06 19:56:44.027;\n\
14a-272;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-06 19:56:44.030;\n\
14a-273;Initialize;NULL;Ansøger;2014-02-06 22:22:22.860;\n\
14a-273;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-06 22:22:22.860;\n\
14a-026;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-02-06 23:29:32.600;\n\
14a-028;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-02-06 23:40:12.880;\n\
14a-274;Initialize;NULL;Ansøger;2014-02-07 11:09:30.870;\n\
14a-274;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-07 11:09:30.870;\n\
14a-275;Initialize;NULL;Ansøger;2014-02-07 12:15:19.793;\n\
14a-275;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-07 12:15:19.797;\n\
14a-276;Initialize;NULL;Ansøger;2014-02-07 12:23:19.050;\n\
14a-276;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-07 12:23:19.050;\n\
14a-277;Initialize;NULL;Ansøger;2014-02-07 12:39:40.783;\n\
14a-277;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-07 12:39:40.783;\n\
14a-278;Initialize;NULL;Ansøger;2014-02-07 12:41:18.133;\n\
14a-278;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-07 12:41:18.133;\n\
14a-279;Initialize;NULL;Ansøger;2014-02-07 13:05:08.000;\n\
14a-279;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-07 13:05:08.000;\n\
14a-280;Initialize;NULL;Ansøger;2014-02-07 13:56:42.917;\n\
14a-280;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-07 13:56:42.917;\n\
14a-281;Initialize;NULL;Ansøger;2014-02-07 14:44:49.593;\n\
14a-281;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-07 14:44:49.593;\n\
14a-220;DL_WFCaseTypeStep!541;Account number changed;ls;2014-02-08 12:13:02.223;\n\
14a-066;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 16:58:36.820;\n\
14a-066;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 16:58:40.790;\n\
14a-067;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 16:58:52.180;\n\
14a-067;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 16:58:54.473;\n\
14a-068;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 16:59:08.703;\n\
14a-068;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 16:59:10.203;\n\
14a-069;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 16:59:40.150;\n\
14a-069;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 16:59:42.533;\n\
14a-070;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:01:00.507;\n\
14a-070;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:01:01.953;\n\
14a-071;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:01:15.463;\n\
14a-071;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:01:17.610;\n\
14a-072;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:01:28.413;\n\
14a-072;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:01:30.763;\n\
14a-073;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:01:42.980;\n\
14a-073;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:01:45.130;\n\
14a-074;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:01:57.110;\n\
14a-074;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:01:59.247;\n\
14a-075;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:02:09.830;\n\
14a-075;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:02:11.340;\n\
14a-076;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:02:24.443;\n\
14a-076;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:02:26.600;\n\
14a-077;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:03:29.950;\n\
14a-077;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:03:31.877;\n\
14a-078;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:08:36.067;\n\
14a-078;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:08:38.613;\n\
14a-079;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:08:55.867;\n\
14a-079;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:08:58.103;\n\
14a-080;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:09:11.433;\n\
14a-080;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:09:12.977;\n\
14a-081;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:09:29.027;\n\
14a-081;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:09:31.180;\n\
14a-082;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:10:02.840;\n\
14a-082;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:10:04.763;\n\
14a-083;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:10:32.873;\n\
14a-083;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:10:35.163;\n\
14a-084;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:10:51.050;\n\
14a-084;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:10:53.280;\n\
14a-088;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:12:16.640;\n\
14a-088;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:12:18.123;\n\
14a-090;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:12:33.627;\n\
14a-090;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:12:35.797;\n\
14a-100;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:29:16.373;\n\
14a-100;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:29:19.147;\n\
14a-101;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:29:40.663;\n\
14a-101;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:29:43.023;\n\
14a-091;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-09 17:30:59.227;\n\
14a-092;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:31:22.163;\n\
14a-092;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:31:23.790;\n\
14a-094;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:31:38.190;\n\
14a-094;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:31:40.330;\n\
14a-095;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:31:49.850;\n\
14a-095;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:31:52.313;\n\
14a-096;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:32:01.593;\n\
14a-096;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:32:03.087;\n\
14a-097;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:32:16.140;\n\
14a-097;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:32:18.430;\n\
14a-098;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:32:34.703;\n\
14a-098;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:32:36.860;\n\
14a-102;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:35:11.057;\n\
14a-102;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:35:13.533;\n\
14a-103;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-09 17:35:48.940;\n\
14a-104;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:36:08.220;\n\
14a-104;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:36:09.660;\n\
14a-105;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:36:22.733;\n\
14a-105;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:36:24.927;\n\
14a-106;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:36:36.837;\n\
14a-106;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:36:39.087;\n\
14a-107;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 17:36:52.953;\n\
14a-107;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 17:36:55.203;\n\
14a-108;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:08:09.253;\n\
14a-108;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:08:12.940;\n\
14a-110;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:09:11.210;\n\
14a-110;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:09:12.823;\n\
14a-109;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:09:50.037;\n\
14a-109;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:09:52.230;\n\
14a-111;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:16:08.023;\n\
14a-111;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:16:10.607;\n\
14a-112;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:16:20.503;\n\
14a-112;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:16:22.870;\n\
14a-113;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:16:31.817;\n\
14a-113;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:16:34.010;\n\
14a-114;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:16:41.810;\n\
14a-114;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:16:43.943;\n\
14a-115;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:16:51.633;\n\
14a-115;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:16:53.780;\n\
14a-116;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:17:02.570;\n\
14a-116;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:17:04.023;\n\
14a-117;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:17:15.530;\n\
14a-117;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:17:17.723;\n\
14a-118;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:17:34.453;\n\
14a-118;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:17:36.563;\n\
14a-120;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 18:21:30.350;\n\
14a-120;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 18:21:32.917;\n\
14a-121;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:26:22.250;\n\
14a-121;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:26:26.093;\n\
14a-122;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:26:41.100;\n\
14a-122;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:26:43.440;\n\
14a-123;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:26:58.870;\n\
14a-123;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:27:01.777;\n\
14a-124;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:27:15.200;\n\
14a-124;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:27:17.573;\n\
14a-125;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-09 20:28:54.600;\n\
14a-126;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:29:14.070;\n\
14a-126;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:29:15.520;\n\
14a-127;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:29:33.277;\n\
14a-127;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:29:35.397;\n\
14a-128;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:29:50.863;\n\
14a-128;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:29:53.017;\n\
14a-129;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:30:25.207;\n\
14a-129;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:30:26.750;\n\
14a-130;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:30:59.840;\n\
14a-130;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:31:02.040;\n\
14a-131;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:31:20.630;\n\
14a-131;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:31:22.907;\n\
14a-132;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:31:43.237;\n\
14a-132;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:31:45.463;\n\
14a-133;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:32:04.740;\n\
14a-133;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:32:06.317;\n\
14a-134;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 20:34:58.230;\n\
14a-134;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 20:35:00.977;\n\
14a-135;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:03:50.440;\n\
14a-135;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:03:54.040;\n\
14a-136;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:04:45.623;\n\
14a-136;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:04:47.150;\n\
14a-137;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:05:17.610;\n\
14a-137;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:05:19.103;\n\
14a-138;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:05:36.470;\n\
14a-138;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:05:38.763;\n\
14a-139;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:06:03.363;\n\
14a-139;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:06:04.830;\n\
14a-140;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:06:35.220;\n\
14a-140;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:06:37.387;\n\
14a-141;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:12:02.297;\n\
14a-141;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:12:04.853;\n\
14a-142;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:12:30.810;\n\
14a-142;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:12:33.113;\n\
14a-143;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:12:52.733;\n\
14a-143;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:12:55.040;\n\
14a-144;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-09 21:13:42.477;\n\
14a-145;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:14:01.853;\n\
14a-145;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:14:03.310;\n\
14a-146;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:14:31.870;\n\
14a-146;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:14:34.110;\n\
14a-147;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:14:54.527;\n\
14a-147;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:14:56.723;\n\
14a-148;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:15:23.447;\n\
14a-148;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:15:24.963;\n\
14a-149;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:15:40.267;\n\
14a-149;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:15:42.433;\n\
14a-150;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 21:16:04.063;\n\
14a-150;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 21:16:06.057;\n\
14a-151;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 23:08:36.900;\n\
14a-151;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 23:08:40.577;\n\
14a-152;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 23:18:16.207;\n\
14a-152;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 23:18:18.667;\n\
14a-153;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 23:18:37.710;\n\
14a-153;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 23:18:40.100;\n\
14a-154;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 23:19:01.740;\n\
14a-154;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 23:19:03.280;\n\
14a-155;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 23:19:25.193;\n\
14a-155;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 23:19:27.427;\n\
14a-156;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-09 23:21:31.743;\n\
14a-157;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 23:22:36.717;\n\
14a-157;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 23:22:38.683;\n\
14a-158;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-09 23:27:08.500;\n\
14a-158;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-09 23:27:11.117;\n\
14a-159;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 11:50:40.657;\n\
14a-159;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 11:50:45.617;\n\
14a-160;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 11:51:04.153;\n\
14a-160;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 11:51:05.587;\n\
14a-161;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 11:51:20.190;\n\
14a-161;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 11:51:21.960;\n\
14a-162;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 11:51:40.690;\n\
14a-162;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 11:51:42.467;\n\
14a-163;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 11:52:03.857;\n\
14a-163;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 11:52:05.300;\n\
14a-164;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 11:53:56.807;\n\
14a-164;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 11:53:58.170;\n\
14a-165;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 11:54:11.703;\n\
14a-165;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 11:54:13.200;\n\
14a-166;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 11:54:27.523;\n\
14a-166;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 11:54:29.377;\n\
14a-167;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 11:54:53.660;\n\
14a-167;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 11:54:55.443;\n\
14a-168;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 11:55:11.377;\n\
14a-168;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 11:55:12.820;\n\
14a-169;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:13:07.880;\n\
14a-170;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:13:46.817;\n\
14a-171;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:14:40.160;\n\
14a-170;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 12:15:04.097;\n\
14a-171;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:16:16.537;\n\
14a-171;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 12:16:24.140;\n\
14a-172;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:16:51.093;\n\
14a-172;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:17:47.840;\n\
14a-172;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:18:29.293;\n\
14a-172;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 12:18:42.427;\n\
14a-173;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:19:10.877;\n\
14a-173;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 12:19:12.943;\n\
14a-174;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:19:37.550;\n\
14a-174;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 12:19:40.697;\n\
14a-175;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:20:02.087;\n\
14a-175;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 12:20:05.787;\n\
14a-177;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:20:40.563;\n\
14a-177;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 12:20:43.210;\n\
14a-179;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:21:30.480;\n\
14a-179;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 12:21:32.313;\n\
14a-180;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 12:22:00.913;\n\
14a-180;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 12:22:04.873;\n\
14a-181;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:11:48.597;\n\
14a-181;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:11:52.553;\n\
14a-182;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:12:07.190;\n\
14a-182;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:12:08.640;\n\
14a-183;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:12:27.343;\n\
14a-183;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:12:29.593;\n\
14a-184;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:12:50.437;\n\
14a-184;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:12:52.540;\n\
14a-185;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:13:10.400;\n\
14a-185;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:13:11.843;\n\
14a-186;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:13:25.923;\n\
14a-186;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:13:28.023;\n\
14a-187;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:13:38.997;\n\
14a-187;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:13:41.190;\n\
14a-188;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:13:57.280;\n\
14a-188;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:13:59.977;\n\
14a-189;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:14:12.767;\n\
14a-189;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:14:14.237;\n\
14a-190;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:14:32.547;\n\
14a-190;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:14:35.747;\n\
14a-191;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:14:51.870;\n\
14a-191;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:14:54.270;\n\
14a-192;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 13:15:11.787;\n\
14a-192;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 13:15:13.290;\n\
14a-178;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 15:33:44.037;\n\
14a-178;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 15:33:48.000;\n\
14a-169;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 15:37:29.217;\n\
14a-169;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 15:37:31.990;\n\
14a-176;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 15:38:26.140;\n\
14a-176;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 15:38:28.103;\n\
14a-193;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 15:39:38.190;\n\
14a-193;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 15:39:40.267;\n\
14a-194;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 15:39:57.750;\n\
14a-194;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 15:40:02.187;\n\
14a-195;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 15:40:23.483;\n\
14a-195;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 15:40:26.290;\n\
14a-196;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 15:40:42.040;\n\
14a-196;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 15:40:44.990;\n\
14a-198;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 15:42:46.910;\n\
14a-198;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 15:42:49.493;\n\
14a-199;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 15:43:04.763;\n\
14a-199;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 15:43:06.487;\n\
14a-200;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 15:43:22.167;\n\
14a-200;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 15:43:24.600;\n\
14a-197;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 15:45:25.887;\n\
14a-197;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 15:45:27.413;\n\
14a-201;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:00:36.100;\n\
14a-201;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:00:38.677;\n\
14a-202;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:00:54.060;\n\
14a-202;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:00:56.357;\n\
14a-203;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:01:27.250;\n\
14a-203;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:01:50.480;\n\
14a-204;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:02:05.697;\n\
14a-204;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:02:07.397;\n\
14a-205;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:02:21.290;\n\
14a-205;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:02:24.083;\n\
14a-207;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:02:55.283;\n\
14a-207;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:02:57.613;\n\
14a-208;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:03:11.193;\n\
14a-208;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:03:12.620;\n\
14a-209;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:03:29.453;\n\
14a-209;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:03:31.820;\n\
14a-210;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:03:46.013;\n\
14a-210;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:03:48.230;\n\
14a-211;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:04:03.390;\n\
14a-211;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:04:04.883;\n\
14a-213;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:04:19.810;\n\
14a-213;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:04:21.747;\n\
14a-206;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:05:53.560;\n\
14a-206;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:05:54.913;\n\
14a-212;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:06:08.723;\n\
14a-212;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:06:10.393;\n\
14a-214;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:08:33.737;\n\
14a-214;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:08:35.207;\n\
14a-215;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:08:48.753;\n\
14a-215;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:08:50.840;\n\
14a-216;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:09:05.527;\n\
14a-216;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:09:07.170;\n\
14a-217;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:09:19.920;\n\
14a-217;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:09:22.430;\n\
14a-218;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:09:36.850;\n\
14a-218;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:09:39.497;\n\
14a-220;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:10:47.840;\n\
14a-220;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:10:49.513;\n\
14a-219;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-10 16:12:49.233;\n\
14a-221;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:57:05.873;\n\
14a-221;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:57:09.967;\n\
14a-228;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-10 16:57:57.447;\n\
14a-222;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:58:31.087;\n\
14a-222;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:58:32.800;\n\
14a-223;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:58:50.143;\n\
14a-223;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:58:52.423;\n\
14a-224;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:59:12.287;\n\
14a-224;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:59:13.787;\n\
14a-226;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:59:36.893;\n\
14a-226;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:59:39.273;\n\
14a-225;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 16:59:54.433;\n\
14a-225;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 16:59:57.007;\n\
14a-227;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:00:52.367;\n\
14a-227;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:00:54.267;\n\
14a-229;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:02:10.033;\n\
14a-229;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:02:11.743;\n\
14a-230;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:02:27.663;\n\
14a-230;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:02:30.187;\n\
14a-231;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:02:45.890;\n\
14a-231;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:02:48.783;\n\
14a-232;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:09:08.487;\n\
14a-232;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:09:11.130;\n\
14a-233;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:11:21.307;\n\
14a-233;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:11:22.900;\n\
14a-234;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:11:36.703;\n\
14a-234;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:11:39.020;\n\
14a-235;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:11:57.637;\n\
14a-235;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:11:59.850;\n\
14a-236;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:12:16.427;\n\
14a-236;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:12:18.020;\n\
14a-237;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:12:38.320;\n\
14a-237;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:12:41.583;\n\
14a-238;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:13:09.503;\n\
14a-238;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:13:11.153;\n\
14a-239;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:13:40.427;\n\
14a-239;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:13:43.300;\n\
14a-240;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:14:01.010;\n\
14a-240;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:14:05.207;\n\
14a-241;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:59:05.250;\n\
14a-241;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:59:09.673;\n\
14a-242;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 17:59:43.207;\n\
14a-242;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 17:59:45.170;\n\
14a-243;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:00:00.347;\n\
14a-243;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:00:03.853;\n\
14a-244;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:00:21.627;\n\
14a-244;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:00:23.807;\n\
14a-245;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:00:39.397;\n\
14a-245;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:00:41.700;\n\
14a-246;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:01:07.813;\n\
14a-246;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:01:09.300;\n\
14a-247;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:01:21.357;\n\
14a-247;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:01:23.540;\n\
14a-250;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:03:29.080;\n\
14a-250;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:03:30.743;\n\
14a-251;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-10 18:03:48.493;\n\
14a-252;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:04:22.270;\n\
14a-252;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:04:23.763;\n\
14a-253;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:06:04.087;\n\
14a-253;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:06:05.580;\n\
14a-254;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-10 18:07:04.930;\n\
14a-255;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:08:00.163;\n\
14a-255;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:08:01.817;\n\
14a-256;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:08:31.167;\n\
14a-256;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:08:33.750;\n\
14a-257;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:08:49.143;\n\
14a-257;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:08:52.060;\n\
14a-258;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:09:09.493;\n\
14a-258;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:09:11.173;\n\
14a-259;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:09:33.980;\n\
14a-259;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:09:36.600;\n\
14a-260;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:10:08.497;\n\
14a-260;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:10:09.980;\n\
14a-261;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:10:24.117;\n\
14a-261;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:10:26.300;\n\
14a-262;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:10:52.947;\n\
14a-262;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:10:55.330;\n\
14a-263;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:11:22.810;\n\
14a-263;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:11:24.290;\n\
14a-264;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-10 18:12:17.533;\n\
14a-265;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:12:56.450;\n\
14a-265;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:12:57.963;\n\
14a-266;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:13:21.993;\n\
14a-266;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:13:23.477;\n\
14a-267;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:13:39.120;\n\
14a-267;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:13:41.303;\n\
14a-268;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:14:00.587;\n\
14a-268;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:14:04.963;\n\
14a-269;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:14:29.400;\n\
14a-269;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:14:32.500;\n\
14a-270;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:15:21.287;\n\
14a-270;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:15:22.983;\n\
14a-271;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:29:28.427;\n\
14a-271;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:29:30.950;\n\
14a-272;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:44:49.787;\n\
14a-272;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:44:52.457;\n\
14a-273;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:45:19.080;\n\
14a-273;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:45:20.513;\n\
14a-274;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:45:44.320;\n\
14a-274;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:45:46.617;\n\
14a-275;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:46:01.160;\n\
14a-275;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:46:04.340;\n\
14a-276;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:46:17.703;\n\
14a-276;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:46:19.967;\n\
14a-277;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:46:30.643;\n\
14a-277;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:46:33.433;\n\
14a-278;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:46:51.377;\n\
14a-278;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:46:55.250;\n\
14a-279;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-10 18:47:21.847;\n\
14a-280;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:47:44.120;\n\
14a-280;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:47:45.743;\n\
14a-281;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-10 18:48:20.713;\n\
14a-281;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-10 18:48:22.357;\n\
14a-222;DL_WFCaseTypeStep!541;Account number changed;ls;2014-02-11 09:26:31.520;\n\
14a-282;Initialize;NULL;Ansøger;2014-02-11 10:48:19.310;\n\
14a-282;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-11 10:48:19.310;\n\
14a-282;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-11 10:55:33.020;\n\
14a-282;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-02-11 10:55:35.760;\n\
14a-282;DL_WFCaseTypeStep!475;Register Decision;ls;2014-02-11 10:57:29.950;\n\
14a-282;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-02-11 10:57:47.380;\n\
14a-282;DL_WFCaseTypeStep!519;Change phase to Board meeting;ls;2014-02-11 10:57:49.827;\n\
14a-282;DL_WFCaseTypeStep!544;Pre-process application;ls;2014-02-11 10:58:00.110;\n\
14a-282;DL_WFCaseTypeStep!545;Execute pre-decision;ls;2014-02-11 10:58:01.563;\n\
14a-282;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-02-11 10:58:01.563;\n\
14a-282;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-02-11 10:59:02.170;\n\
14a-282;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-02-11 10:59:02.173;\n\
14a-282;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-02-11 10:59:50.890;\n\
14a-282;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-02-11 10:59:54.630;\n\
14a-099;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-11 13:36:52.207;\n\
14a-099;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-02-11 13:36:56.260;\n\
14a-099;RestartWorkflow;NULL;ls;2014-02-11 13:38:13.097;\n\
14a-099;Initialize;NULL;ls;2014-02-11 13:38:13.273;\n\
14a-099;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-11 13:38:16.587;\n\
14a-099;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-11 14:16:29.663;\n\
14a-028;DL_WFCaseTypeStep!474;Review;bry;2014-02-11 17:06:29.493;\n\
14a-026;DL_WFCaseTypeStep!474;Review;bry;2014-02-11 17:06:49.513;\n\
14a-027;DL_WFCaseTypeStep!474;Review;bry;2014-02-11 17:07:09.263;\n\
14a-027;DL_WFCaseTypeStep!474;Review;bry;2014-02-11 17:07:28.270;\n\
14a-026;DL_WFCaseTypeStep!474;Review;bry;2014-02-11 17:10:50.597;\n\
14a-026;DL_WFCaseTypeStep!474;Review;bry;2014-02-11 17:11:05.740;\n\
14a-113;DL_WFCaseTypeStep!541;Account number changed;ls;2014-02-11 17:57:35.120;\n\
14a-283;Initialize;NULL;Ansøger;2014-02-11 18:18:25.350;\n\
14a-283;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-11 18:18:25.350;\n\
14a-206;DL_WFCaseTypeStep!541;Account number changed;ls;2014-02-11 19:34:09.587;\n\
14a-284;Initialize;NULL;Ansøger;2014-02-11 22:49:17.827;\n\
14a-284;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-11 22:49:17.827;\n\
14a-285;Initialize;NULL;Ansøger;2014-02-13 10:09:51.070;\n\
14a-285;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-13 10:09:51.070;\n\
14a-286;Initialize;NULL;Ansøger;2014-02-13 14:03:55.460;\n\
14a-286;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-13 14:03:55.460;\n\
14a-287;Initialize;NULL;Ansøger;2014-02-13 15:42:22.170;\n\
14a-287;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-13 15:42:22.173;\n\
14a-283;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-13 15:56:53.900;\n\
14a-284;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-13 15:58:00.373;\n\
14a-284;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-13 15:58:02.193;\n\
14a-285;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-13 15:58:31.587;\n\
14a-285;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-13 15:58:34.230;\n\
14a-286;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-13 15:58:47.700;\n\
14a-286;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-13 15:58:50.167;\n\
14a-287;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-13 16:01:29.013;\n\
14a-287;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-13 16:01:31.607;\n\
14a-288;Initialize;NULL;Ansøger;2014-02-14 16:47:06.510;\n\
14a-288;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-14 16:47:06.510;\n\
14a-288;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-15 13:55:56.777;\n\
14a-288;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-15 13:56:06.867;\n\
14a-288;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-15 13:56:09.053;\n\
14a-289;Initialize;NULL;Ansøger;2014-02-15 14:58:01.137;\n\
14a-289;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-15 14:58:01.137;\n\
14a-290;Initialize;NULL;Ansøger;2014-02-15 15:34:56.737;\n\
14a-290;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-15 15:34:56.737;\n\
14a-291;Initialize;NULL;Ansøger;2014-02-15 15:48:00.620;\n\
14a-291;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-15 15:48:00.620;\n\
14a-292;Initialize;NULL;Ansøger;2014-02-15 16:49:35.070;\n\
14a-292;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-15 16:49:35.070;\n\
14a-293;Initialize;NULL;Ansøger;2014-02-15 17:44:31.690;\n\
14a-293;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-15 17:44:31.690;\n\
14a-294;Initialize;NULL;Ansøger;2014-02-15 18:10:07.647;\n\
14a-294;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-15 18:10:07.647;\n\
14a-295;Initialize;NULL;Ansøger;2014-02-15 18:29:47.573;\n\
14a-295;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-15 18:29:47.573;\n\
14a-289;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-15 18:36:30.297;\n\
14a-289;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-02-15 18:36:39.610;\n\
14a-289;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-02-15 18:36:43.290;\n\
14a-290;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-15 18:38:52.187;\n\
14a-290;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-02-15 18:38:57.947;\n\
14a-290;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-02-15 18:39:01.403;\n\
14a-291;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-15 18:39:30.390;\n\
14a-291;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-02-15 18:39:36.170;\n\
14a-291;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-02-15 18:39:39.263;\n\
14a-292;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-15 18:39:59.580;\n\
14a-292;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-02-15 18:40:06.207;\n\
14a-292;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-02-15 18:40:10.283;\n\
14a-293;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-15 18:40:36.013;\n\
14a-293;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-02-15 18:41:25.533;\n\
14a-293;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-02-15 18:41:28.770;\n\
14a-294;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-15 18:42:27.350;\n\
14a-294;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-02-15 18:42:36.840;\n\
14a-294;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-02-15 18:42:39.903;\n\
14a-295;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-15 18:43:15.123;\n\
14a-295;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-02-15 18:43:20.490;\n\
14a-295;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-02-15 18:43:23.677;\n\
14a-288;RestartWorkflow;NULL;ls;2014-02-15 18:52:22.433;\n\
14a-288;Initialize;NULL;ANONYMOUS LOGON;2014-02-15 18:52:22.563;\n\
14a-288;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-15 18:52:26.673;\n\
14a-288;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-02-15 18:54:08.873;\n\
14a-296;Initialize;NULL;Ansøger;2014-02-17 15:17:03.217;\n\
14a-296;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-17 15:17:03.217;\n\
14a-296;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-17 16:07:51.970;\n\
14a-296;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-17 16:07:56.280;\n\
14a-040;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2014-02-18 12:56:20.970;\n\
14a-040;DL_WFCaseTypeStep!541;Account number changed;ANONYMOUS LOGON;2014-02-18 13:33:38.523;\n\
14b-297;Initialize;NULL;Ansøger;2014-02-19 17:57:57.490;\n\
14b-297;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-19 17:57:57.490;\n\
14b-297;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-02-19 19:50:41.023;\n\
14b-297;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-02-19 19:50:44.983;\n\
14b-298;Initialize;NULL;Ansøger;2014-02-20 19:31:51.083;\n\
14b-298;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-20 19:31:51.083;\n\
14b-299;Initialize;NULL;Ansøger;2014-02-21 08:53:20.557;\n\
14b-299;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-21 08:53:20.557;\n\
14a-282;DL_WFCaseTypeStep!541;Account number changed;ANONYMOUS LOGON;2014-02-21 11:38:01.870;\n\
14a-282;DL_WFCaseTypeStep!541;Account number changed;ANONYMOUS LOGON;2014-02-21 11:38:01.923;\n\
14b-300;Initialize;NULL;Ansøger;2014-02-21 13:13:20.887;\n\
14b-300;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-21 13:13:20.887;\n\
14b-301;Initialize;NULL;Ansøger;2014-02-23 18:59:09.140;\n\
14b-301;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-23 18:59:09.140;\n\
14b-302;Initialize;NULL;Ansøger;2014-02-25 00:04:25.973;\n\
14b-302;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-02-25 00:04:25.977;\n\
14a-040;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-02-28 11:20:46.480;\n\
14a-296;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 16:58:12.540;\n\
14a-284;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 17:18:21.520;\n\
14a-278;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 17:22:00.907;\n\
14a-277;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 17:22:33.833;\n\
14a-276;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 17:22:44.170;\n\
14a-275;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 17:22:56.570;\n\
14a-274;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 17:23:10.133;\n\
14a-267;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 17:54:12.407;\n\
14a-263;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 17:56:25.780;\n\
14a-261;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 17:57:06.980;\n\
14a-260;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 17:59:12.357;\n\
14a-257;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 18:03:15.380;\n\
14a-253;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 18:23:10.293;\n\
14a-252;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 18:23:22.410;\n\
14a-250;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 18:28:01.377;\n\
14a-244;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-04 19:33:42.057;\n\
14a-256;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 10:58:07.357;\n\
14a-255;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 11:11:48.280;\n\
14a-242;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 11:15:31.990;\n\
14a-237;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 11:26:19.460;\n\
14a-236;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 11:26:49.190;\n\
14a-234;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 11:34:37.523;\n\
14a-222;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 11:43:28.573;\n\
14a-220;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 11:43:57.133;\n\
14a-221;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 11:58:02.167;\n\
14a-218;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 12:15:11.373;\n\
14a-195;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 16:34:05.200;\n\
14a-192;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 16:35:12.767;\n\
14a-191;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 16:36:27.240;\n\
14a-188;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 16:37:38.217;\n\
14a-186;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 16:38:46.963;\n\
14a-185;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 16:41:04.913;\n\
14a-175;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 16:44:55.940;\n\
14a-173;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 16:51:00.133;\n\
14a-172;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:01:24.607;\n\
14a-169;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:08:12.957;\n\
14a-168;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:15:15.703;\n\
14a-167;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:16:32.687;\n\
14a-164;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:17:48.267;\n\
14a-152;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:19:04.583;\n\
14a-150;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:21:07.147;\n\
14a-146;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:24:33.700;\n\
14a-143;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:27:39.157;\n\
14a-136;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:36:12.283;\n\
14a-128;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:41:18.630;\n\
14a-126;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:45:20.333;\n\
14a-124;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:45:55.097;\n\
14a-123;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:46:47.050;\n\
14a-122;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:47:00.847;\n\
14a-120;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:53:25.480;\n\
14a-126;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:53:42.883;\n\
14a-110;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 18:59:39.703;\n\
14a-109;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:00:32.010;\n\
14a-102;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:02:22.003;\n\
14a-101;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:04:14.080;\n\
14a-084;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:06:55.267;\n\
14a-083;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:10:19.190;\n\
14a-069;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:10:43.703;\n\
14a-064;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:12:39.410;\n\
14a-060;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:13:33.970;\n\
14a-056;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:16:00.083;\n\
14a-054;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:17:02.060;\n\
14a-048;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:20:52.663;\n\
14a-046;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:22:44.280;\n\
14a-042;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:25:06.460;\n\
14a-037;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:31:28.893;\n\
14a-035;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:32:07.640;\n\
14a-032;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:33:46.347;\n\
14a-081;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:38:30.600;\n\
14a-153;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:40:51.007;\n\
14b-297;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:43:03.563;\n\
14a-027;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-05 19:46:00.657;\n\
14b-303;Initialize;NULL;Ansøger;2014-03-06 20:39:06.590;\n\
14b-303;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-06 20:39:06.590;\n\
14b-304;Initialize;NULL;Ansøger;2014-03-07 14:24:16.023;\n\
14b-304;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-07 14:24:16.027;\n\
14b-305;Initialize;NULL;Ansøger;2014-03-08 01:45:33.230;\n\
14b-305;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-08 01:45:33.230;\n\
14b-306;Initialize;NULL;Ansøger;2014-03-08 14:55:53.007;\n\
14b-306;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-08 14:55:53.007;\n\
14a-287;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 09:25:30.207;\n\
14a-285;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 09:37:11.660;\n\
14a-281;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 09:46:42.340;\n\
14a-271;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 11:21:51.383;\n\
14a-269;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 11:29:00.327;\n\
14a-268;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 11:34:01.993;\n\
14a-266;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 11:39:06.673;\n\
14a-265;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 11:43:56.613;\n\
14a-258;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 11:50:35.340;\n\
14a-259;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 19:48:09.967;\n\
14a-247;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 19:59:19.503;\n\
14a-245;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 20:08:57.553;\n\
14a-233;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 20:16:25.283;\n\
14a-213;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 20:29:23.730;\n\
14a-212;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 20:34:55.873;\n\
14a-211;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 20:40:36.490;\n\
14b-307;Initialize;NULL;Ansøger;2014-03-09 20:44:02.680;\n\
14b-307;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-09 20:44:02.680;\n\
14a-208;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 20:47:29.613;\n\
14a-206;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 20:53:15.860;\n\
14a-203;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 20:59:04.633;\n\
14a-199;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 21:08:46.367;\n\
14a-198;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 21:23:27.260;\n\
14a-197;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 21:31:39.513;\n\
14a-038;DL_WFCaseTypeStep!477;Reject application;ls;2014-03-09 21:50:52.410;\n\
14a-180;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 21:51:26.923;\n\
14a-179;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 22:02:03.220;\n\
14a-178;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 22:10:03.507;\n\
14a-177;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 22:20:52.683;\n\
14a-170;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 22:30:25.417;\n\
14a-162;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 22:37:34.670;\n\
14a-159;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 22:43:46.563;\n\
14a-154;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 22:49:01.563;\n\
14a-151;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 22:54:46.670;\n\
14a-147;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 23:01:19.243;\n\
14a-131;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 23:14:05.520;\n\
14a-130;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 23:18:47.957;\n\
14a-129;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-09 23:27:56.570;\n\
14b-297;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:37:26.973;\n\
14a-296;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:37:59.997;\n\
14a-287;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:38:44.360;\n\
14a-285;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:39:36.133;\n\
14a-284;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:39:56.880;\n\
14a-281;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:43:32.140;\n\
14a-278;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:43:48.540;\n\
14a-277;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:44:35.940;\n\
14a-276;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:44:47.240;\n\
14a-275;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:45:03.860;\n\
14a-274;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:45:24.247;\n\
14a-271;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:46:15.757;\n\
14a-269;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:48:41.727;\n\
14a-268;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:49:17.680;\n\
14a-267;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:49:44.990;\n\
14a-266;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:50:02.657;\n\
14a-265;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:50:24.350;\n\
14a-263;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:50:42.320;\n\
14a-261;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:50:57.990;\n\
14a-260;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:51:12.013;\n\
14a-259;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:51:39.350;\n\
14a-258;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:51:55.640;\n\
14a-257;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:52:14.860;\n\
14a-256;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:52:44.427;\n\
14a-255;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:53:00.340;\n\
14a-253;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:53:19.717;\n\
14a-252;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:53:37.027;\n\
14a-247;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:54:07.780;\n\
14a-245;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:55:10.710;\n\
14a-244;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:55:48.487;\n\
14a-242;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:56:04.497;\n\
14a-237;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:56:18.877;\n\
14a-236;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:56:41.560;\n\
14a-234;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:56:59.270;\n\
14a-233;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:57:40.700;\n\
14a-222;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:57:58.973;\n\
14a-221;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:58:49.297;\n\
14a-220;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:59:19.133;\n\
14a-218;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 11:59:35.200;\n\
14a-213;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:01:05.560;\n\
14a-212;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:01:26.133;\n\
14a-211;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:01:44.667;\n\
14a-208;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:02:08.113;\n\
14a-206;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:02:21.233;\n\
14a-203;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:02:42.700;\n\
14a-199;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:03:00.530;\n\
14a-198;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:03:54.237;\n\
14a-197;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:05:23.690;\n\
14a-195;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:05:50.477;\n\
14a-192;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:06:12.353;\n\
14a-191;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:06:27.620;\n\
14a-188;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:06:45.420;\n\
14a-186;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:07:43.630;\n\
14a-185;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:07:54.103;\n\
14a-180;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:08:09.373;\n\
14a-179;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:09:42.527;\n\
14a-178;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:10:06.297;\n\
14a-177;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:10:28.863;\n\
14a-175;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:10:43.460;\n\
14a-173;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:10:57.783;\n\
14a-172;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:11:11.450;\n\
14a-170;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:11:29.963;\n\
14a-169;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:11:43.250;\n\
14a-168;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:11:59.940;\n\
14a-167;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:12:13.337;\n\
14a-164;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:12:25.130;\n\
14a-162;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:12:38.297;\n\
14a-159;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:12:50.583;\n\
14a-154;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:13:01.547;\n\
14a-153;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:13:18.573;\n\
14a-152;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:13:32.300;\n\
14a-151;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:15:08.743;\n\
14a-150;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:15:21.460;\n\
14a-147;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:15:32.440;\n\
14a-146;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:15:55.420;\n\
14a-146;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:16:07.137;\n\
14a-143;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:16:19.823;\n\
14a-136;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:16:33.270;\n\
14a-131;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:16:45.650;\n\
14a-130;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:17:14.470;\n\
14a-129;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:17:51.570;\n\
14a-128;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:18:08.363;\n\
14a-128;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:18:24.340;\n\
14a-126;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:19:34.723;\n\
14a-126;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:19:47.937;\n\
14a-124;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:19:58.727;\n\
14a-123;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:20:29.017;\n\
14a-122;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:20:45.350;\n\
14a-120;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:21:00.493;\n\
14a-110;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:21:11.673;\n\
14a-109;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:21:24.407;\n\
14a-102;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:21:35.923;\n\
14a-101;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:21:46.167;\n\
14a-084;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:21:57.320;\n\
14a-083;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:22:12.340;\n\
14a-083;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:22:30.477;\n\
14a-081;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:22:40.913;\n\
14a-069;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:23:00.457;\n\
14a-064;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:23:11.003;\n\
14a-060;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:23:21.390;\n\
14a-056;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:23:35.177;\n\
14a-054;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:23:51.230;\n\
14a-048;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:24:19.660;\n\
14a-046;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:24:31.017;\n\
14a-042;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:24:47.377;\n\
14a-037;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:24:57.607;\n\
14a-035;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:25:07.967;\n\
14a-032;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:25:18.490;\n\
14a-027;DL_WFCaseTypeStep!474;Review;bry;2014-03-10 12:25:36.097;\n\
14b-308;Initialize;NULL;Ansøger;2014-03-10 17:02:19.450;\n\
14b-308;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-10 17:02:19.450;\n\
14a-286;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-10 19:53:21.867;\n\
14a-280;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-10 19:54:50.290;\n\
14a-272;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-10 20:00:28.870;\n\
14a-270;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-10 20:13:49.037;\n\
14a-262;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-10 20:18:30.463;\n\
14a-246;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-10 20:23:39.533;\n\
14a-243;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-10 20:27:52.300;\n\
14a-241;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 13:37:04.350;\n\
14a-240;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 13:40:52.573;\n\
14a-239;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 13:45:13.567;\n\
14a-238;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 13:56:22.193;\n\
14a-235;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 14:02:45.067;\n\
14a-231;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 14:07:46.180;\n\
14a-232;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 14:12:08.807;\n\
14a-230;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 14:16:08.917;\n\
14a-229;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 14:43:55.170;\n\
14a-227;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 14:50:27.573;\n\
14a-226;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 14:56:39.497;\n\
14a-225;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:00:16.810;\n\
14a-224;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:01:47.800;\n\
14a-223;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:06:33.200;\n\
14a-217;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:07:21.103;\n\
14a-215;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:11:14.577;\n\
14a-214;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:17:38.670;\n\
14a-210;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:22:22.963;\n\
14a-209;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:23:15.007;\n\
14a-207;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:27:05.570;\n\
14a-205;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:32:32.480;\n\
14a-204;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:38:14.870;\n\
14a-202;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 15:43:54.740;\n\
14a-286;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:13:51.087;\n\
14a-280;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:14:22.363;\n\
14a-272;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:15:30.267;\n\
14a-270;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:16:01.323;\n\
14a-262;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:16:38.900;\n\
14a-246;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:17:22.230;\n\
14a-243;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:17:50.280;\n\
14a-241;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:18:13.510;\n\
14a-240;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:18:37.673;\n\
14a-239;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:19:00.680;\n\
14a-238;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:19:41.180;\n\
14a-235;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:20:10.357;\n\
14a-232;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:20:33.467;\n\
14a-231;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:21:02.393;\n\
14a-230;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:21:23.530;\n\
14a-229;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:21:43.820;\n\
14a-227;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:22:05.623;\n\
14a-226;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:22:36.647;\n\
14a-225;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:23:16.477;\n\
14a-224;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:23:32.237;\n\
14a-223;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:23:51.570;\n\
14a-217;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:24:18.283;\n\
14a-215;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:24:30.510;\n\
14a-214;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:24:43.347;\n\
14a-210;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:25:09.643;\n\
14a-209;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:25:29.483;\n\
14a-207;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:25:48.713;\n\
14a-205;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:26:34.447;\n\
14a-204;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:26:51.180;\n\
14a-202;DL_WFCaseTypeStep!474;Review;bry;2014-03-11 17:27:07.513;\n\
14a-226;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 20:12:51.730;\n\
14a-201;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 20:33:49.353;\n\
14a-200;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 20:40:34.277;\n\
14a-196;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 20:48:11.657;\n\
14a-194;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 20:52:39.793;\n\
14a-193;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 20:56:28.223;\n\
14a-190;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 22:34:44.507;\n\
14a-176;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 22:39:12.670;\n\
14a-189;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 22:41:04.250;\n\
14a-187;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 22:44:46.203;\n\
14a-184;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 22:49:34.507;\n\
14a-183;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 22:52:13.700;\n\
14a-182;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 22:54:33.907;\n\
14a-181;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-11 22:57:55.473;\n\
14b-300;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-03-12 09:51:18.837;\n\
14b-300;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-03-12 10:46:41.090;\n\
14b-300;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-03-12 10:46:46.427;\n\
14b-306;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-03-12 11:14:09.867;\n\
14b-306;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-03-12 11:14:14.063;\n\
14b-309;Initialize;NULL;Ansøger;2014-03-12 17:26:13.727;\n\
14b-309;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-12 17:26:13.727;\n\
14a-166;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 00:30:46.330;\n\
14a-121;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 00:42:56.927;\n\
14a-116;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 00:50:49.953;\n\
14a-115;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 00:54:34.300;\n\
14a-114;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 01:02:53.457;\n\
14a-112;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 01:06:54.387;\n\
14a-111;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 01:11:57.707;\n\
14a-107;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 01:15:55.240;\n\
14a-104;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 01:19:45.953;\n\
14a-100;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 01:23:57.840;\n\
14a-094;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 01:35:12.100;\n\
14a-082;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 01:38:08.760;\n\
14a-166;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 08:53:35.253;\n\
14a-121;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:07:05.467;\n\
14a-116;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:07:23.750;\n\
14a-077;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 09:07:39.090;\n\
14a-115;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:07:46.817;\n\
14a-114;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:08:04.677;\n\
14a-112;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:08:23.717;\n\
14a-111;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:08:53.083;\n\
14a-107;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:09:18.830;\n\
14a-104;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:09:44.140;\n\
14a-100;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:10:00.097;\n\
14a-094;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:10:59.977;\n\
14a-082;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:11:28.473;\n\
14a-075;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 09:14:42.230;\n\
14a-201;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:15:16.800;\n\
14a-200;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:15:32.303;\n\
14a-196;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:15:45.847;\n\
14a-194;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:16:10.800;\n\
14a-193;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:16:26.200;\n\
14a-190;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:16:48.843;\n\
14a-189;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:17:03.820;\n\
14a-187;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:17:21.530;\n\
14a-184;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:17:39.053;\n\
14a-183;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:17:52.707;\n\
14a-182;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:18:04.597;\n\
14a-181;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:18:18.690;\n\
14a-176;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 09:18:36.310;\n\
14a-074;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 09:23:05.720;\n\
14a-070;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 09:39:18.127;\n\
14a-068;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 09:47:49.833;\n\
14a-067;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 10:15:42.780;\n\
14a-066;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 10:22:26.213;\n\
14a-066;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 10:22:47.880;\n\
14a-063;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 10:27:03.963;\n\
14a-061;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 10:47:40.540;\n\
14a-059;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 10:52:06.003;\n\
14a-057;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 11:08:14.060;\n\
14a-055;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 11:16:49.783;\n\
14a-053;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 11:19:02.850;\n\
14a-052;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 11:25:08.513;\n\
14a-049;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 11:33:18.577;\n\
14a-045;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 11:46:53.517;\n\
14a-044;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 11:54:40.020;\n\
14a-039;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 11:57:45.060;\n\
14a-038;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 12:01:55.597;\n\
14b-310;Initialize;NULL;Ansøger;2014-03-13 12:43:08.793;\n\
14b-310;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-13 12:43:08.797;\n\
14a-030;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-13 13:06:46.300;\n\
14a-077;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:19:00.533;\n\
14a-075;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:20:47.570;\n\
14a-074;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:21:01.810;\n\
14a-070;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:21:15.100;\n\
14a-068;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:21:24.140;\n\
14a-067;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:21:34.183;\n\
14a-066;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:21:46.180;\n\
14a-063;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:22:02.047;\n\
14a-061;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:22:15.117;\n\
14a-059;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:22:48.410;\n\
14a-057;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:23:00.370;\n\
14b-311;Initialize;NULL;Ansøger;2014-03-13 13:23:13.997;\n\
14b-311;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-13 13:23:13.997;\n\
14a-055;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:23:15.070;\n\
14a-053;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:23:25.853;\n\
14a-052;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:23:36.707;\n\
14a-049;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:23:48.387;\n\
14a-045;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:24:14.180;\n\
14a-044;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:24:29.747;\n\
14a-039;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:26:31.303;\n\
14a-038;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:26:44.757;\n\
14a-030;DL_WFCaseTypeStep!474;Review;bry;2014-03-13 13:26:56.927;\n\
14b-312;Initialize;NULL;Ansøger;2014-03-13 18:39:16.350;\n\
14b-312;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-13 18:39:16.350;\n\
14b-313;Initialize;NULL;Ansøger;2014-03-13 18:49:38.920;\n\
14b-313;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-13 18:49:38.920;\n\
14a-154;DL_WFCaseTypeStep!541;Account number changed;ls;2014-03-14 13:47:29.733;\n\
14b-314;Initialize;NULL;Ansøger;2014-03-14 14:07:56.540;\n\
14b-314;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-14 14:07:56.540;\n\
14a-026;DL_WFCaseTypeStep!473;Review;br;2014-03-16 20:33:02.847;\n\
14a-028;DL_WFCaseTypeStep!473;Review;br;2014-03-16 20:38:01.767;\n\
14a-030;DL_WFCaseTypeStep!473;Review;br;2014-03-16 20:43:22.740;\n\
14a-038;DL_WFCaseTypeStep!473;Review;br;2014-03-16 20:49:25.100;\n\
14a-174;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 22:23:23.923;\n\
14a-171;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 22:27:53.140;\n\
14a-165;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 22:30:32.380;\n\
14a-163;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 22:31:28.077;\n\
14a-161;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 22:32:05.327;\n\
14a-160;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 22:32:33.040;\n\
14a-158;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 22:37:03.517;\n\
14a-157;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 22:43:21.320;\n\
14a-155;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 22:47:53.073;\n\
14a-149;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 22:52:18.487;\n\
14a-044;DL_WFCaseTypeStep!473;Review;br;2014-03-16 22:57:23.210;\n\
14a-148;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 22:57:59.703;\n\
14a-045;DL_WFCaseTypeStep!473;Review;br;2014-03-16 23:00:36.500;\n\
14a-145;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:00:38.147;\n\
14a-142;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:03:49.643;\n\
14a-141;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:04:49.310;\n\
14a-049;DL_WFCaseTypeStep!473;Review;br;2014-03-16 23:07:47.913;\n\
14a-052;DL_WFCaseTypeStep!473;Review;br;2014-03-16 23:09:25.320;\n\
14a-140;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:10:07.420;\n\
14a-139;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:12:22.523;\n\
14a-138;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:13:40.190;\n\
14a-137;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:14:09.917;\n\
14a-053;DL_WFCaseTypeStep!473;Review;br;2014-03-16 23:14:14.000;\n\
14a-135;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:16:23.143;\n\
14a-134;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:19:08.743;\n\
14a-055;DL_WFCaseTypeStep!473;Review;br;2014-03-16 23:20:19.737;\n\
14a-132;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:21:35.613;\n\
14a-133;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:24:43.210;\n\
14a-057;DL_WFCaseTypeStep!473;Review;br;2014-03-16 23:25:31.067;\n\
14a-127;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:26:04.883;\n\
14a-059;DL_WFCaseTypeStep!473;Review;br;2014-03-16 23:29:17.203;\n\
14a-118;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:30:46.177;\n\
14a-061;DL_WFCaseTypeStep!473;Review;br;2014-03-16 23:33:16.373;\n\
14a-117;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:35:09.777;\n\
14a-063;DL_WFCaseTypeStep!473;Review;br;2014-03-16 23:36:36.100;\n\
14a-113;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:38:37.553;\n\
14a-108;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:40:40.077;\n\
14a-106;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-16 23:43:30.717;\n\
14a-105;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 12:21:51.957;\n\
14a-098;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 12:28:08.263;\n\
14a-097;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 18:45:00.757;\n\
14a-096;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 18:50:30.717;\n\
14a-095;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 18:51:36.600;\n\
14a-092;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 18:55:46.507;\n\
14a-090;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 18:56:29.510;\n\
14a-080;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 19:00:02.377;\n\
14a-088;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 19:02:32.270;\n\
14a-079;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 19:06:04.380;\n\
14a-078;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 19:09:56.150;\n\
14a-076;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 19:16:46.500;\n\
14a-071;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 19:19:40.303;\n\
14a-073;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 19:21:44.513;\n\
14a-072;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 19:26:05.697;\n\
14a-036;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 19:27:27.010;\n\
14a-066;DL_WFCaseTypeStep!473;Review;br;2014-03-17 22:51:26.307;\n\
14a-067;DL_WFCaseTypeStep!473;Review;br;2014-03-17 22:55:16.483;\n\
14a-065;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 22:57:43.100;\n\
14a-068;DL_WFCaseTypeStep!473;Review;br;2014-03-17 22:58:01.357;\n\
14a-070;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:02:27.863;\n\
14a-074;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:06:05.443;\n\
14a-051;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 23:06:34.817;\n\
14a-075;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:10:42.390;\n\
14a-041;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 23:12:41.583;\n\
14a-273;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 23:15:37.930;\n\
14a-077;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:15:44.130;\n\
14a-171;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 23:21:34.350;\n\
14a-165;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 23:22:18.420;\n\
14a-082;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:24:41.900;\n\
14a-094;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:31:33.693;\n\
14a-216;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-17 23:33:10.270;\n\
14a-100;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:35:00.403;\n\
14a-104;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:38:18.730;\n\
14a-107;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:40:10.540;\n\
14a-111;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:43:55.187;\n\
14a-112;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:46:29.527;\n\
14a-114;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:50:16.713;\n\
14a-115;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:55:03.660;\n\
14a-116;DL_WFCaseTypeStep!473;Review;br;2014-03-17 23:57:34.900;\n\
14a-273;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:20:57.720;\n\
14a-216;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:22:05.550;\n\
14a-174;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:22:47.850;\n\
14a-171;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:23:07.050;\n\
14a-165;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:23:28.460;\n\
14a-163;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:23:51.863;\n\
14a-161;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:24:05.757;\n\
14a-160;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:24:17.923;\n\
14a-158;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:24:37.040;\n\
14a-157;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:25:41.390;\n\
14a-155;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:26:02.773;\n\
14a-149;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:26:42.327;\n\
14a-145;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:27:02.660;\n\
14a-148;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:27:20.237;\n\
14a-148;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:27:33.613;\n\
14a-142;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:27:51.867;\n\
14a-141;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:28:03.987;\n\
14a-140;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:28:15.440;\n\
14a-139;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:28:27.170;\n\
14a-138;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:28:41.367;\n\
14a-137;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:28:55.390;\n\
14a-135;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:29:06.530;\n\
14a-134;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:29:22.837;\n\
14a-133;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:29:46.113;\n\
14a-132;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:29:58.330;\n\
14a-127;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:30:10.427;\n\
14a-118;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:30:29.160;\n\
14a-117;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:30:40.673;\n\
14a-113;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:30:54.863;\n\
14a-108;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:31:07.780;\n\
14a-106;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:31:23.600;\n\
14a-105;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:31:45.580;\n\
14a-098;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:31:58.313;\n\
14a-097;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:32:11.693;\n\
14a-096;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:32:22.763;\n\
14a-095;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:38:12.510;\n\
14a-092;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:38:38.067;\n\
14a-090;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:38:59.510;\n\
14a-088;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:39:21.670;\n\
14a-080;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:39:44.220;\n\
14a-079;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:39:54.243;\n\
14a-078;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:40:07.407;\n\
14a-076;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:40:18.103;\n\
14a-073;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:40:28.630;\n\
14a-072;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:40:41.930;\n\
14a-071;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:40:52.223;\n\
14a-065;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:41:19.380;\n\
14a-051;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:41:57.557;\n\
14a-041;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:42:56.490;\n\
14a-036;DL_WFCaseTypeStep!474;Review;bry;2014-03-18 13:43:58.570;\n\
14b-299;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-03-19 10:07:47.897;\n\
14b-299;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-03-19 10:07:51.933;\n\
14b-299;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-03-19 10:16:06.763;\n\
14b-299;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-03-19 10:16:08.427;\n\
14b-299;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-03-19 10:22:42.203;\n\
14b-299;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-03-19 10:22:44.000;\n\
14b-299;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-03-19 10:47:27.213;\n\
14b-315;Initialize;NULL;Ansøger;2014-03-19 13:49:04.290;\n\
14b-315;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-19 13:49:04.293;\n\
14a-121;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:12:30.217;\n\
14a-129;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:19:21.660;\n\
14a-130;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:22:08.533;\n\
14a-131;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:26:44.817;\n\
14a-147;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:27:51.530;\n\
14a-151;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:33:04.460;\n\
14a-154;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:36:10.317;\n\
14a-159;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:37:07.417;\n\
14a-162;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:40:43.177;\n\
14a-166;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:42:24.697;\n\
14a-170;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:47:55.510;\n\
14a-177;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:52:12.310;\n\
14a-178;DL_WFCaseTypeStep!473;Review;br;2014-03-21 17:55:43.320;\n\
14a-179;DL_WFCaseTypeStep!473;Review;br;2014-03-22 11:39:06.537;\n\
14a-180;DL_WFCaseTypeStep!473;Review;br;2014-03-22 11:42:27.977;\n\
14a-197;DL_WFCaseTypeStep!473;Review;br;2014-03-22 12:00:14.517;\n\
14a-198;DL_WFCaseTypeStep!473;Review;br;2014-03-22 12:10:02.800;\n\
14a-199;DL_WFCaseTypeStep!473;Review;br;2014-03-22 12:17:03.900;\n\
14a-203;DL_WFCaseTypeStep!473;Review;br;2014-03-22 12:20:40.320;\n\
14a-206;DL_WFCaseTypeStep!473;Review;br;2014-03-22 12:23:52.817;\n\
14a-208;DL_WFCaseTypeStep!473;Review;br;2014-03-22 12:27:57.970;\n\
14a-211;DL_WFCaseTypeStep!473;Review;br;2014-03-22 12:32:49.140;\n\
14a-212;DL_WFCaseTypeStep!473;Review;br;2014-03-22 12:35:20.847;\n\
14a-213;DL_WFCaseTypeStep!473;Review;br;2014-03-22 12:40:39.660;\n\
14a-245;DL_WFCaseTypeStep!473;Review;br;2014-03-22 12:50:31.797;\n\
14a-233;DL_WFCaseTypeStep!473;Review;br;2014-03-22 16:20:57.183;\n\
14a-247;DL_WFCaseTypeStep!473;Review;br;2014-03-22 16:32:05.430;\n\
14a-258;DL_WFCaseTypeStep!473;Review;br;2014-03-22 16:36:22.240;\n\
14a-259;DL_WFCaseTypeStep!473;Review;br;2014-03-22 16:39:33.860;\n\
14a-266;DL_WFCaseTypeStep!473;Review;br;2014-03-22 16:44:15.050;\n\
14a-268;DL_WFCaseTypeStep!473;Review;br;2014-03-22 16:48:05.147;\n\
14a-269;DL_WFCaseTypeStep!473;Review;br;2014-03-22 17:12:19.983;\n\
14a-271;DL_WFCaseTypeStep!473;Review;br;2014-03-22 22:27:40.100;\n\
14a-271;DL_WFCaseTypeStep!473;Review;br;2014-03-22 22:28:01.813;\n\
14a-281;DL_WFCaseTypeStep!473;Review;br;2014-03-22 22:33:36.517;\n\
14a-287;DL_WFCaseTypeStep!473;Review;br;2014-03-22 22:45:39.820;\n\
14a-027;DL_WFCaseTypeStep!473;Review;br;2014-03-22 22:48:43.670;\n\
14a-032;DL_WFCaseTypeStep!473;Review;br;2014-03-22 22:52:48.860;\n\
14a-035;DL_WFCaseTypeStep!473;Review;br;2014-03-22 22:54:26.310;\n\
14a-036;DL_WFCaseTypeStep!473;Review;br;2014-03-22 22:57:49.860;\n\
14a-039;DL_WFCaseTypeStep!473;Review;br;2014-03-22 22:58:37.100;\n\
14a-255;DL_WFCaseTypeStep!473;Review;br;2014-03-22 23:03:27.907;\n\
14a-037;DL_WFCaseTypeStep!473;Review;br;2014-03-22 23:04:02.600;\n\
14a-041;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:02:33.500;\n\
14a-042;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:07:05.933;\n\
14a-046;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:10:04.670;\n\
14a-048;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:15:40.330;\n\
14a-051;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:24:47.067;\n\
14a-054;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:29:10.203;\n\
14a-056;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:31:30.167;\n\
14a-060;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:33:04.200;\n\
14a-064;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:35:25.440;\n\
14a-065;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:39:07.590;\n\
14a-069;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:40:37.597;\n\
14a-071;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:42:52.650;\n\
14a-072;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:44:56.160;\n\
14a-073;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:50:43.407;\n\
14a-076;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:54:01.733;\n\
14a-078;DL_WFCaseTypeStep!473;Review;br;2014-03-23 10:58:15.443;\n\
14a-079;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:09:57.030;\n\
14a-080;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:12:08.720;\n\
14a-081;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:15:10.437;\n\
14a-083;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:17:50.637;\n\
14a-084;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:24:13.080;\n\
14a-088;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:27:56.397;\n\
14a-090;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:29:43.313;\n\
14a-092;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:41:54.077;\n\
14a-095;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:45:54.000;\n\
14a-096;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:50:25.610;\n\
14a-097;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:53:42.090;\n\
14a-098;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:56:38.557;\n\
14a-101;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:57:25.377;\n\
14a-102;DL_WFCaseTypeStep!473;Review;br;2014-03-23 11:58:45.443;\n\
14a-105;DL_WFCaseTypeStep!473;Review;br;2014-03-23 12:04:05.040;\n\
14a-106;DL_WFCaseTypeStep!473;Review;br;2014-03-23 12:06:58.293;\n\
14a-108;DL_WFCaseTypeStep!473;Review;br;2014-03-23 12:09:46.490;\n\
14a-109;DL_WFCaseTypeStep!473;Review;br;2014-03-23 14:15:44.153;\n\
14a-110;DL_WFCaseTypeStep!473;Review;br;2014-03-23 14:21:00.327;\n\
14a-113;DL_WFCaseTypeStep!473;Review;br;2014-03-23 14:31:25.710;\n\
14a-117;DL_WFCaseTypeStep!473;Review;br;2014-03-23 14:34:34.683;\n\
14a-118;DL_WFCaseTypeStep!473;Review;br;2014-03-23 14:48:31.900;\n\
14a-120;DL_WFCaseTypeStep!473;Review;br;2014-03-23 14:49:25.707;\n\
14a-122;DL_WFCaseTypeStep!473;Review;br;2014-03-23 14:50:36.090;\n\
14a-123;DL_WFCaseTypeStep!473;Review;br;2014-03-23 14:51:02.820;\n\
14a-124;DL_WFCaseTypeStep!473;Review;br;2014-03-23 14:52:18.987;\n\
14a-126;DL_WFCaseTypeStep!473;Review;br;2014-03-23 14:55:28.030;\n\
14a-127;DL_WFCaseTypeStep!473;Review;br;2014-03-23 14:58:26.283;\n\
14a-128;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:03:29.113;\n\
14a-132;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:07:08.907;\n\
14a-133;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:08:13.563;\n\
14a-134;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:16:00.633;\n\
14a-135;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:22:30.347;\n\
14a-136;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:24:13.667;\n\
14a-137;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:24:45.363;\n\
14a-138;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:25:58.790;\n\
14a-139;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:33:52.053;\n\
14a-140;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:36:48.317;\n\
14a-141;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:38:58.230;\n\
14a-142;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:46:27.420;\n\
14a-143;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:49:09.953;\n\
14a-145;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:51:39.833;\n\
14a-146;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:53:10.810;\n\
14a-148;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:56:51.960;\n\
14a-149;DL_WFCaseTypeStep!473;Review;br;2014-03-23 15:58:56.533;\n\
14a-150;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:02:53.650;\n\
14a-152;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:04:24.670;\n\
14a-153;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:11:42.190;\n\
14a-155;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:22:40.140;\n\
14a-287;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:27:09.073;\n\
14a-281;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:28:49.490;\n\
14a-157;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:31:09.430;\n\
14a-271;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:31:34.980;\n\
14a-266;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:31:52.170;\n\
14a-265;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:32:57.370;\n\
14a-259;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:33:21.097;\n\
14a-258;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:35:40.750;\n\
14a-158;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:35:48.300;\n\
14a-160;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:37:34.560;\n\
14a-269;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:37:40.350;\n\
14a-268;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:38:03.403;\n\
14a-161;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:38:07.650;\n\
14a-163;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:40:32.003;\n\
14a-164;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:43:35.967;\n\
14a-233;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:45:29.543;\n\
14a-233;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:45:46.567;\n\
14a-213;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:46:44.353;\n\
14a-212;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:47:00.223;\n\
14a-165;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:48:08.540;\n\
14a-053;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:48:17.267;\n\
14a-131;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:48:33.753;\n\
14a-203;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:49:58.370;\n\
14a-162;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:50:17.157;\n\
14a-159;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:50:42.660;\n\
14a-167;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:50:53.550;\n\
14a-166;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:51:13.580;\n\
14a-147;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:51:36.283;\n\
14a-116;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:52:04.877;\n\
14a-107;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:52:16.823;\n\
14a-104;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 16:52:29.857;\n\
14a-168;DL_WFCaseTypeStep!473;Review;br;2014-03-23 16:56:53.747;\n\
14a-169;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:01:09.157;\n\
14a-171;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:04:01.733;\n\
14a-172;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:05:03.453;\n\
14a-173;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:08:29.063;\n\
14a-174;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:09:37.830;\n\
14a-175;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:12:23.383;\n\
14a-176;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:17:08.970;\n\
14a-181;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:21:13.317;\n\
14a-182;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:25:13.467;\n\
14a-183;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:26:10.873;\n\
14a-184;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:27:34.220;\n\
14a-185;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:29:31.093;\n\
14a-186;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:30:02.300;\n\
14a-187;DL_WFCaseTypeStep!473;Review;br;2014-03-23 17:31:27.203;\n\
14a-211;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:21:16.553;\n\
14a-129;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:21:54.460;\n\
14a-100;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:22:06.907;\n\
14a-070;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:23:01.113;\n\
14a-055;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:25:12.577;\n\
14a-049;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:25:27.653;\n\
14a-044;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:25:43.530;\n\
14a-026;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:26:13.777;\n\
14a-285;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:27:19.493;\n\
14a-247;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:28:15.080;\n\
14a-245;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:34:01.567;\n\
14a-208;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:34:18.750;\n\
14a-206;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:34:29.427;\n\
14a-199;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:34:56.053;\n\
14a-198;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:36:34.387;\n\
14a-197;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:44:50.700;\n\
14a-180;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:45:08.767;\n\
14a-179;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:46:33.430;\n\
14a-178;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:46:48.823;\n\
14a-177;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:47:05.333;\n\
14a-170;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:47:51.537;\n\
14a-154;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:48:06.583;\n\
14a-151;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:54:54.980;\n\
14a-130;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:55:14.693;\n\
14a-121;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:55:27.263;\n\
14a-115;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:55:41.630;\n\
14a-114;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:56:37.160;\n\
14a-114;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:57:23.097;\n\
14a-112;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:58:00.757;\n\
14a-111;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:58:16.657;\n\
14a-094;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 21:59:15.013;\n\
14a-082;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:00:03.890;\n\
14a-077;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:00:17.280;\n\
14a-075;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:01:13.960;\n\
14a-074;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:01:29.657;\n\
14a-068;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:01:45.940;\n\
14a-067;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:11:02.087;\n\
14a-066;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:11:30.913;\n\
14a-066;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:11:46.933;\n\
14a-063;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:12:00.457;\n\
14a-061;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:12:16.550;\n\
14a-059;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:12:52.617;\n\
14a-057;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:13:08.987;\n\
14a-052;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:13:21.327;\n\
14a-045;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:13:47.437;\n\
14a-039;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:17:01.223;\n\
14a-038;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:18:11.530;\n\
14a-030;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:18:25.827;\n\
14a-028;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-23 22:19:01.710;\n\
14a-188;DL_WFCaseTypeStep!473;Review;br;2014-03-24 22:57:09.257;\n\
14a-189;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:01:42.430;\n\
14a-190;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:06:13.690;\n\
14a-191;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:08:38.613;\n\
14a-192;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:10:35.547;\n\
14a-193;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:15:27.380;\n\
14a-194;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:17:45.860;\n\
14a-195;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:19:55.430;\n\
14a-196;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:22:24.180;\n\
14a-200;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:25:40.837;\n\
14a-201;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:28:28.583;\n\
14a-202;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:33:22.327;\n\
14a-204;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:37:24.447;\n\
14a-205;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:43:53.700;\n\
14a-207;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:46:59.870;\n\
14a-209;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:48:09.743;\n\
14a-210;DL_WFCaseTypeStep!473;Review;br;2014-03-24 23:50:03.190;\n\
14b-304;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-03-25 08:54:10.973;\n\
14b-304;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-03-25 08:54:15.227;\n\
14a-214;DL_WFCaseTypeStep!473;Review;br;2014-03-25 17:38:01.680;\n\
14a-215;DL_WFCaseTypeStep!473;Review;br;2014-03-25 17:39:38.500;\n\
14a-216;DL_WFCaseTypeStep!473;Review;br;2014-03-25 17:40:29.890;\n\
14a-217;DL_WFCaseTypeStep!473;Review;br;2014-03-25 17:40:50.487;\n\
14a-218;DL_WFCaseTypeStep!473;Review;br;2014-03-25 17:41:39.860;\n\
14a-220;DL_WFCaseTypeStep!473;Review;br;2014-03-25 17:44:09.697;\n\
14a-221;DL_WFCaseTypeStep!473;Review;br;2014-03-25 17:51:25.810;\n\
14a-222;DL_WFCaseTypeStep!473;Review;br;2014-03-25 17:51:56.467;\n\
14a-286;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:10:55.853;\n\
14a-284;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:12:56.157;\n\
14a-280;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:13:22.600;\n\
14a-278;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:14:00.060;\n\
14a-277;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:14:13.003;\n\
14a-276;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:14:25.370;\n\
14a-275;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:14:39.803;\n\
14a-284;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:16:35.247;\n\
14a-274;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:17:07.723;\n\
14a-273;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:17:31.423;\n\
14a-272;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:18:02.903;\n\
14a-270;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:18:47.303;\n\
14a-267;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:19:14.900;\n\
14a-263;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:19:34.537;\n\
14a-262;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:20:32.353;\n\
14a-261;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:21:04.733;\n\
14a-260;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:21:26.393;\n\
14a-257;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:21:45.213;\n\
14a-256;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:22:26.490;\n\
14a-255;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:22:55.250;\n\
14a-253;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:23:59.317;\n\
14a-252;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:24:15.457;\n\
14a-246;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:24:56.953;\n\
14a-244;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:27:08.170;\n\
14a-243;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:27:32.173;\n\
14a-242;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:27:46.760;\n\
14a-241;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:28:03.850;\n\
14a-240;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 19:28:26.170;\n\
14a-223;DL_WFCaseTypeStep!473;Review;br;2014-03-25 19:50:12.970;\n\
14a-224;DL_WFCaseTypeStep!473;Review;br;2014-03-25 19:55:42.733;\n\
14a-225;DL_WFCaseTypeStep!473;Review;br;2014-03-25 19:57:54.073;\n\
14a-226;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:00:15.843;\n\
14a-227;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:03:50.893;\n\
14a-229;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:06:28.390;\n\
14a-230;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:09:58.963;\n\
14a-231;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:10:44.763;\n\
14a-232;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:13:56.523;\n\
14a-234;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:17:05.597;\n\
14a-235;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:23:05.773;\n\
14a-236;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:24:33.160;\n\
14a-237;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:24:58.750;\n\
14a-238;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:27:34.800;\n\
14a-239;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:29:43.267;\n\
14a-240;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:32:03.807;\n\
14a-241;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:34:58.840;\n\
14a-242;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:37:37.347;\n\
14a-243;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:41:57.227;\n\
14a-244;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:45:04.987;\n\
14a-239;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:46:59.317;\n\
14a-246;DL_WFCaseTypeStep!473;Review;br;2014-03-25 20:47:23.023;\n\
14a-238;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:48:02.107;\n\
14a-237;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:48:35.023;\n\
14a-236;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:48:52.040;\n\
14a-235;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:49:14.367;\n\
14a-234;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:49:42.960;\n\
14a-232;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:52:49.293;\n\
14a-231;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:53:28.053;\n\
14a-230;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:54:00.100;\n\
14a-229;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:54:22.003;\n\
14a-227;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:54:46.263;\n\
14a-226;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:55:25.103;\n\
14a-225;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:55:58.957;\n\
14a-224;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:56:45.550;\n\
14a-223;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:59:32.550;\n\
14a-222;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 20:59:54.913;\n\
14a-252;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:00:32.957;\n\
14a-253;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:00:46.160;\n\
14a-221;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:01:00.560;\n\
14a-220;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:01:21.470;\n\
14a-218;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:01:49.093;\n\
14a-217;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:02:02.680;\n\
14a-216;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:02:25.793;\n\
14b-297;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:04:25.450;\n\
14a-296;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:04:42.653;\n\
14a-256;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:06:09.850;\n\
14b-304;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:08:02.793;\n\
14a-215;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:08:44.413;\n\
14a-214;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:09:06.667;\n\
14a-210;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:09:40.860;\n\
14a-209;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:11:05.943;\n\
14a-207;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:11:26.070;\n\
14a-257;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:11:44.930;\n\
14a-205;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:12:03.650;\n\
14a-204;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:12:27.160;\n\
14a-202;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:13:00.443;\n\
14a-201;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:13:30.217;\n\
14a-260;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:13:37.310;\n\
14a-200;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:13:50.707;\n\
14a-261;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:14:42.680;\n\
14a-196;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:15:03.190;\n\
14a-195;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:15:19.700;\n\
14a-194;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:15:41.660;\n\
14a-262;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:16:10.553;\n\
14a-193;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:16:19.620;\n\
14a-263;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:17:19.647;\n\
14a-192;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:18:40.953;\n\
14a-191;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:19:04.263;\n\
14a-265;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:19:52.050;\n\
14a-190;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:20:13.783;\n\
14a-189;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:20:43.730;\n\
14a-188;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:21:02.180;\n\
14a-187;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:21:22.970;\n\
14a-186;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:21:54.010;\n\
14a-267;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:21:59.490;\n\
14a-185;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:22:12.957;\n\
14a-184;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:22:33.800;\n\
14a-270;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:22:53.617;\n\
14a-183;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:23:16.003;\n\
14a-182;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:23:39.713;\n\
14a-181;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:24:01.167;\n\
14a-272;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:26:10.733;\n\
14a-273;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:26:38.333;\n\
14a-176;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:27:23.523;\n\
14a-274;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:27:33.160;\n\
14a-175;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:27:46.103;\n\
14a-275;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:27:46.747;\n\
14a-276;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:28:04.543;\n\
14a-175;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:28:17.490;\n\
14a-277;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:28:21.077;\n\
14a-278;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:28:34.553;\n\
14a-174;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:28:34.583;\n\
14a-280;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:29:04.240;\n\
14a-284;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:30:08.147;\n\
14a-285;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:30:45.280;\n\
14a-174;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:31:37.023;\n\
14a-173;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:32:25.933;\n\
14a-286;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:32:51.737;\n\
14a-172;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:32:56.863;\n\
14a-296;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:33:49.340;\n\
14a-169;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:34:17.790;\n\
14a-168;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:34:43.380;\n\
14a-167;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:35:19.317;\n\
14b-297;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:35:28.477;\n\
14a-165;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:36:29.713;\n\
14a-164;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:36:49.900;\n\
14b-304;DL_WFCaseTypeStep!473;Review;br;2014-03-25 21:38:31.070;\n\
14a-163;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:38:49.237;\n\
14a-161;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:39:02.660;\n\
14a-160;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:39:15.887;\n\
14a-158;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:40:36.310;\n\
14a-157;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:42:44.557;\n\
14a-155;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:44:28.987;\n\
14a-153;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:46:33.493;\n\
14a-152;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:46:49.337;\n\
14a-150;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:47:10.990;\n\
14a-149;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:49:07.210;\n\
14a-148;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:49:24.573;\n\
14a-146;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:49:37.683;\n\
14a-145;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:49:54.047;\n\
14a-143;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:50:11.730;\n\
14a-142;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:50:33.737;\n\
14a-141;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:50:46.470;\n\
14a-140;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:51:46.253;\n\
14a-139;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:52:06.063;\n\
14a-138;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:52:18.463;\n\
14a-137;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:52:33.673;\n\
14a-136;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:52:58.110;\n\
14a-135;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:53:25.253;\n\
14a-134;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:54:27.413;\n\
14a-133;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:54:43.630;\n\
14a-132;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:54:58.633;\n\
14a-128;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:55:48.760;\n\
14a-127;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:56:21.363;\n\
14a-126;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:57:28.117;\n\
14a-124;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:57:47.000;\n\
14a-123;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:58:00.833;\n\
14a-122;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:58:12.810;\n\
14a-120;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:58:44.370;\n\
14a-118;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 21:59:07.870;\n\
14a-117;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:00:37.040;\n\
14a-113;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:01:26.613;\n\
14a-110;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:01:57.690;\n\
14a-109;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:02:11.620;\n\
14a-108;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:03:06.400;\n\
14a-106;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:03:21.980;\n\
14a-105;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:03:50.460;\n\
14a-102;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:04:05.603;\n\
14a-101;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:04:18.643;\n\
14a-098;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:04:41.203;\n\
14a-097;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:05:01.820;\n\
14a-095;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:07:48.667;\n\
14a-096;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:09:19.323;\n\
14a-092;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:10:22.090;\n\
14a-090;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:10:57.487;\n\
14a-088;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:11:16.263;\n\
14a-084;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:11:32.270;\n\
14a-083;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:12:07.153;\n\
14a-081;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:12:23.390;\n\
14a-080;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:12:47.800;\n\
14a-079;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:13:11.600;\n\
14a-076;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:13:33.500;\n\
14a-078;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:13:53.430;\n\
14a-073;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:14:26.803;\n\
14a-072;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:14:45.940;\n\
14a-071;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:15:08.757;\n\
14a-069;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:15:26.620;\n\
14a-065;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:16:01.050;\n\
14a-064;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:16:40.740;\n\
14a-060;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:16:57.743;\n\
14a-056;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:17:22.677;\n\
14a-054;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:17:39.033;\n\
14a-051;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:18:17.857;\n\
14a-048;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:18:40.357;\n\
14a-046;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:19:00.357;\n\
14a-042;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:19:28.927;\n\
14a-041;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:21:42.900;\n\
14a-037;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:22:18.763;\n\
14a-036;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:22:45.857;\n\
14a-035;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:23:02.050;\n\
14a-029;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:23:18.380;\n\
14a-032;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:23:34.873;\n\
14a-027;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-25 22:23:53.800;\n\
14b-304;DL_WFCaseTypeStep!474;Review;bry;2014-03-26 10:25:35.440;\n\
14a-167;DL_WFCaseTypeStep!474;Review;bry;2014-03-26 10:34:50.617;\n\
14a-224;DL_WFCaseTypeStep!474;Review;bry;2014-03-26 12:01:07.390;\n\
14a-028;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:14:54.383;\n\
14a-028;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:15:05.120;\n\
14a-030;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:15:09.183;\n\
14a-044;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:15:20.360;\n\
14a-045;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:15:39.373;\n\
14a-049;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:16:01.917;\n\
14a-030;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:16:02.160;\n\
14a-044;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:16:03.613;\n\
14a-045;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:16:05.057;\n\
14a-052;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:16:15.113;\n\
14a-053;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:16:35.217;\n\
14a-057;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:16:54.457;\n\
14a-049;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:17:01.987;\n\
14a-052;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:17:05.187;\n\
14a-053;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:17:06.480;\n\
14a-057;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:17:08.173;\n\
14a-061;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:17:10.393;\n\
14a-063;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:17:26.517;\n\
14a-067;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:17:41.810;\n\
14a-068;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:17:49.937;\n\
14a-074;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:18:02.923;\n\
14a-061;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:18:04.737;\n\
14a-063;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:18:06.877;\n\
14a-067;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:18:08.170;\n\
14a-068;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:18:09.460;\n\
14a-077;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:18:20.767;\n\
14a-100;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:18:33.357;\n\
14a-104;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:18:42.710;\n\
14a-107;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:18:51.823;\n\
14a-074;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:19:01.950;\n\
14a-111;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:19:02.010;\n\
14a-077;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:19:05.840;\n\
14a-100;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:19:07.110;\n\
14a-104;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:19:08.493;\n\
14a-107;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:19:10.950;\n\
14a-112;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:19:16.637;\n\
14a-111;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:19:27.810;\n\
14a-115;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:19:47.797;\n\
14a-116;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:19:55.473;\n\
14a-121;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:20:03.923;\n\
14a-111;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:20:04.197;\n\
14a-112;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:20:08.350;\n\
14a-115;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:20:10.090;\n\
14a-116;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:20:11.360;\n\
14a-130;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:20:14.137;\n\
14a-131;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:20:22.157;\n\
14a-147;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:20:32.047;\n\
14a-154;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:20:41.663;\n\
14a-121;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:21:01.660;\n\
14a-159;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:21:01.670;\n\
14a-130;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:21:05.873;\n\
14a-131;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:21:07.200;\n\
14a-147;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:21:08.597;\n\
14a-154;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:21:09.937;\n\
14a-159;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:22:02.487;\n\
14a-162;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:24:00.293;\n\
14a-162;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:24:01.720;\n\
14a-166;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:24:15.770;\n\
14a-177;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:24:25.740;\n\
14a-178;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:24:35.220;\n\
14a-180;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:24:45.603;\n\
14a-166;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:25:03.653;\n\
14a-177;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:25:05.117;\n\
14a-178;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:25:06.410;\n\
14a-180;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:25:07.827;\n\
14a-198;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:25:24.083;\n\
14a-199;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:25:36.350;\n\
14a-203;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:25:47.367;\n\
14a-206;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:25:57.037;\n\
14a-198;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:26:04.897;\n\
14a-199;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:26:06.243;\n\
14a-203;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:26:07.600;\n\
14a-206;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:26:09.060;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:26:20.563;\n\
14a-212;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:26:30.650;\n\
14a-247;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:26:48.100;\n\
14a-258;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:26:58.170;\n\
14a-208;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:27:07.867;\n\
14a-212;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:27:09.483;\n\
14a-247;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:27:11.030;\n\
14a-258;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:27:12.370;\n\
14a-259;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:27:12.800;\n\
14a-265;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:27:24.090;\n\
14a-266;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:27:40.363;\n\
14a-259;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:28:05.127;\n\
14a-265;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:28:06.457;\n\
14a-266;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:28:07.747;\n\
14a-268;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:29:16.817;\n\
14a-285;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:29:32.080;\n\
14a-268;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:30:03.603;\n\
14a-285;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:30:05.020;\n\
14a-027;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:33:01.850;\n\
14a-029;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:33:15.690;\n\
14a-032;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:33:30.463;\n\
14a-027;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:34:03.597;\n\
14a-029;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:34:05.053;\n\
14a-032;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:34:06.470;\n\
14a-035;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:34:10.913;\n\
14a-042;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:34:24.403;\n\
14a-046;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:34:37.600;\n\
14a-048;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:34:51.450;\n\
14a-035;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:35:05.930;\n\
14a-042;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:35:07.313;\n\
14a-046;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:35:08.630;\n\
14a-048;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:35:10.077;\n\
14a-051;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:35:16.703;\n\
14a-054;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:35:36.730;\n\
14a-056;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:35:59.977;\n\
14a-051;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:36:04.227;\n\
14a-054;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:36:05.670;\n\
14a-056;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:36:07.120;\n\
14a-060;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:36:25.370;\n\
14a-060;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:37:03.473;\n\
14a-064;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:37:27.950;\n\
14a-065;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:37:43.110;\n\
14a-064;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:38:02.200;\n\
14a-069;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:38:02.220;\n\
14a-065;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:38:03.517;\n\
14a-071;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:38:18.590;\n\
14a-072;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:38:41.717;\n\
14a-073;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:38:57.407;\n\
14a-069;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:39:02.300;\n\
14a-071;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:39:04.310;\n\
14a-072;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:39:05.610;\n\
14a-073;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:39:07.033;\n\
14a-255;DL_WFCaseTypeStep!473;Review;br;2014-03-27 09:54:22.540;\n\
14a-255;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-27 09:54:43.190;\n\
14a-255;DL_WFCaseTypeStep!473;Review;br;2014-03-27 09:54:46.287;\n\
14a-255;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:55:03.493;\n\
14a-255;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:56:01.760;\n\
14a-037;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 09:58:25.527;\n\
14a-037;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 09:59:02.807;\n\
14a-083;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:00:10.087;\n\
14a-083;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-27 10:00:13.437;\n\
14a-083;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 10:01:01.420;\n\
14a-128;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-27 10:01:03.853;\n\
14a-128;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:01:41.110;\n\
14a-128;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 10:02:02.723;\n\
14a-244;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:07:28.410;\n\
14a-244;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 10:08:02.523;\n\
14a-250;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-27 10:11:27.013;\n\
14a-250;DL_WFCaseTypeStep!473;Review;br;2014-03-27 10:12:48.560;\n\
14a-250;DL_WFCaseTypeStep!474;Review;bry;2014-03-27 10:13:45.050;\n\
14a-250;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-27 10:15:22.807;\n\
14a-250;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-27 10:15:45.217;\n\
14a-256;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:23:57.137;\n\
14a-256;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 10:24:03.187;\n\
14a-096;RestartWorkflow;NULL;ls;2014-03-27 10:37:31.173;\n\
14a-096;Initialize;NULL;ANONYMOUS LOGON;2014-03-27 10:37:31.697;\n\
14a-096;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-27 10:37:36.630;\n\
14a-096;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-03-27 10:37:57.030;\n\
14a-096;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-03-27 10:38:04.697;\n\
14a-096;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-03-27 10:38:07.967;\n\
14a-095;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:44:47.020;\n\
14a-095;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 10:45:03.843;\n\
14a-076;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:55:06.510;\n\
14a-078;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:55:19.087;\n\
14a-079;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:55:40.620;\n\
14a-080;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:56:00.870;\n\
14a-076;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 10:56:02.620;\n\
14a-078;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 10:56:04.063;\n\
14a-079;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 10:56:05.367;\n\
14a-081;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:56:16.657;\n\
14a-084;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:56:49.587;\n\
14a-080;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 10:57:01.597;\n\
14a-081;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 10:57:05.007;\n\
14a-084;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 10:57:06.357;\n\
14a-088;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:59:34.530;\n\
14a-090;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 10:59:43.827;\n\
14a-097;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:00:03.050;\n\
14a-088;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:00:03.980;\n\
14a-090;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:00:05.763;\n\
14a-098;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:00:14.743;\n\
14a-105;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:00:30.777;\n\
14a-106;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:00:53.627;\n\
14a-097;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:01:02.387;\n\
14a-098;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:01:05.167;\n\
14a-105;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:01:07.013;\n\
14a-106;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:01:08.360;\n\
14a-108;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:01:15.300;\n\
14a-113;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:02:02.640;\n\
14a-108;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:02:04.817;\n\
14a-117;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:02:26.990;\n\
14a-113;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:03:01.403;\n\
14a-117;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:03:03.123;\n\
14a-036;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:03:57.350;\n\
14a-036;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:04:02.817;\n\
14a-041;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:09:57.483;\n\
14a-041;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:10:03.790;\n\
14a-092;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:12:16.833;\n\
14a-095;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:12:34.233;\n\
14a-092;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:13:03.197;\n\
14a-095;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:13:03.940;\n\
14a-163;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:15:47.713;\n\
14a-163;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:16:03.233;\n\
14a-165;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:17:51.597;\n\
14a-165;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:18:02.450;\n\
14a-171;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-27 11:22:08.097;\n\
14a-171;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:23:38.103;\n\
14a-174;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:23:55.840;\n\
14a-171;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:24:02.133;\n\
14a-174;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:24:03.460;\n\
14a-176;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:28:22.023;\n\
14a-176;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:29:03.220;\n\
14a-232;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:32:36.810;\n\
14a-232;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:33:02.710;\n\
14a-240;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:33:38.063;\n\
14a-240;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:34:03.300;\n\
14a-026;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:51:30.730;\n\
14a-026;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:52:03.620;\n\
14a-055;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:55:35.437;\n\
14a-055;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:56:02.583;\n\
14a-070;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:57:13.127;\n\
14a-070;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:58:01.653;\n\
14a-129;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:58:28.230;\n\
14a-129;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 11:59:02.263;\n\
14a-211;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 11:59:27.283;\n\
14a-211;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:00:04.403;\n\
14a-213;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:02:22.387;\n\
14a-213;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:03:02.763;\n\
14a-233;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:03:32.930;\n\
14a-233;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:04:02.647;\n\
14a-269;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:04:30.290;\n\
14a-269;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:05:03.707;\n\
14a-233;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-03-27 12:05:52.093;\n\
14a-271;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:08:40.733;\n\
14a-281;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:08:55.133;\n\
14a-271;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:09:02.823;\n\
14a-281;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:09:04.460;\n\
14a-287;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:10:29.370;\n\
14a-287;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:11:02.490;\n\
14a-038;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:11:52.270;\n\
14a-038;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:12:02.403;\n\
14a-039;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:19:46.697;\n\
14a-039;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:20:04.023;\n\
14a-059;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:23:10.307;\n\
14a-059;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:24:02.930;\n\
14a-066;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:24:26.037;\n\
14a-075;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:25:00.657;\n\
14a-066;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:25:04.180;\n\
14a-075;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:25:05.520;\n\
14a-094;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:31:07.613;\n\
14a-114;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:32:03.277;\n\
14a-094;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:32:13.433;\n\
14a-114;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:33:05.613;\n\
14a-179;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:37:38.310;\n\
14a-179;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:38:02.967;\n\
14a-197;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:42:52.217;\n\
14a-197;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:43:03.250;\n\
14a-245;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:43:08.810;\n\
14a-245;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:44:02.780;\n\
14b-304;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:45:45.130;\n\
14b-304;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 12:46:02.277;\n\
14b-304;DL_WFCaseTypeStep!472;Architect Review;mp;2014-03-27 12:46:02.377;\n\
14a-039;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-03-27 12:47:43.980;\n\
14a-170;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 12:59:46.750;\n\
14a-170;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 13:00:03.607;\n\
14a-161;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 13:46:13.487;\n\
14a-160;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 13:46:34.997;\n\
14a-158;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 13:46:47.443;\n\
14a-161;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 13:47:03.993;\n\
14a-160;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 13:47:05.427;\n\
14a-158;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 13:47:06.853;\n\
14a-184;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 13:47:45.133;\n\
14a-182;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 13:47:57.380;\n\
14a-184;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 13:48:03.967;\n\
14a-182;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 13:48:05.273;\n\
14a-157;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 13:48:08.923;\n\
14a-155;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 13:48:48.660;\n\
14a-157;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 13:49:03.300;\n\
14a-155;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 13:49:04.583;\n\
14b-297;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 13:49:31.263;\n\
14a-286;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 13:49:40.873;\n\
14a-280;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 13:49:51.610;\n\
14b-297;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 13:50:05.380;\n\
14a-286;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 13:50:06.770;\n\
14a-280;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 13:50:08.023;\n\
14a-136;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:11:43.023;\n\
14a-136;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:12:03.240;\n\
14a-143;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:12:10.860;\n\
14a-146;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:12:19.410;\n\
14a-150;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:12:29.203;\n\
14a-120;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:12:42.480;\n\
14a-126;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:12:59.743;\n\
14a-143;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:13:03.017;\n\
14a-146;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:13:04.313;\n\
14a-150;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:13:05.640;\n\
14a-120;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:13:07.327;\n\
14a-126;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:13:08.637;\n\
14a-124;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:13:10.200;\n\
14a-123;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:13:23.177;\n\
14a-122;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:13:39.787;\n\
14a-110;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:13:55.083;\n\
14a-124;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:14:05.530;\n\
14a-123;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:14:06.797;\n\
14a-122;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:14:08.087;\n\
14a-110;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:14:09.373;\n\
14a-109;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:14:20.283;\n\
14a-102;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:14:28.813;\n\
14a-101;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:14:40.553;\n\
14a-109;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:15:06.103;\n\
14a-102;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:15:07.393;\n\
14a-101;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:15:08.707;\n\
14a-152;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:15:54.753;\n\
14a-173;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:16:03.807;\n\
14a-152;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:16:04.710;\n\
14a-172;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:16:13.210;\n\
14a-169;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:16:22.757;\n\
14a-168;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:16:32.420;\n\
14a-167;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:16:42.217;\n\
14a-164;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:16:56.210;\n\
14a-173;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:17:02.663;\n\
14a-172;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:17:04.670;\n\
14a-169;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:17:06.193;\n\
14a-168;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:17:07.560;\n\
14a-167;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:17:08.913;\n\
14a-164;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:17:10.253;\n\
14a-220;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:17:20.353;\n\
14a-218;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:17:28.190;\n\
14a-195;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:17:38.970;\n\
14a-192;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:17:48.657;\n\
14a-191;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:18:01.227;\n\
14a-220;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:18:06.307;\n\
14a-218;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:18:07.827;\n\
14a-195;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:18:09.160;\n\
14a-192;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:18:10.450;\n\
14a-188;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:18:25.400;\n\
14a-186;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:18:44.237;\n\
14a-185;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:19:00.610;\n\
14a-191;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:19:02.210;\n\
14a-188;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:19:06.357;\n\
14a-186;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:19:07.730;\n\
14a-185;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:19:09.270;\n\
14a-175;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:19:24.893;\n\
14a-234;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:20:04.917;\n\
14a-175;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:20:06.060;\n\
14a-222;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:20:16.813;\n\
14a-221;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:20:33.307;\n\
14a-263;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:20:47.937;\n\
14a-234;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:21:01.983;\n\
14a-261;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:21:02.740;\n\
14a-222;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:21:04.050;\n\
14a-221;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:21:05.347;\n\
14a-263;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:21:06.610;\n\
14a-260;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:21:14.920;\n\
14a-257;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:21:30.760;\n\
14a-261;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:22:02.590;\n\
14a-260;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:22:07.033;\n\
14a-257;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:22:08.303;\n\
14a-253;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:22:12.500;\n\
14a-252;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:22:32.393;\n\
14a-250;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:22:49.747;\n\
14a-253;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:23:04.030;\n\
14a-252;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:23:05.590;\n\
14a-250;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:23:06.910;\n\
14a-242;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:23:16.723;\n\
14a-237;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:23:32.927;\n\
14a-236;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:23:44.563;\n\
14a-242;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:24:03.980;\n\
14a-237;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:24:05.293;\n\
14a-236;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:24:06.577;\n\
14a-267;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:24:18.640;\n\
14a-296;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:24:38.323;\n\
14a-284;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:24:49.710;\n\
14a-267;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:25:05.123;\n\
14a-296;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:25:06.607;\n\
14a-284;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:25:08.560;\n\
14a-278;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:25:15.080;\n\
14a-277;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:25:25.897;\n\
14a-276;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:25:41.777;\n\
14a-275;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:25:52.350;\n\
14a-278;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:26:04.227;\n\
14a-277;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:26:05.507;\n\
14a-276;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:26:06.773;\n\
14a-274;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:26:07.057;\n\
14a-275;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:26:08.040;\n\
14a-274;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:27:04.827;\n\
14a-070;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:28:59.210;\n\
14a-070;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:29:01.477;\n\
14a-070;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 14:30:28.537;\n\
14a-070;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 14:31:01.220;\n\
14b-316;Initialize;NULL;Ansøger;2014-03-27 15:43:15.940;\n\
14b-316;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-27 15:43:15.940;\n\
14b-316;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-03-27 15:52:08.593;\n\
14b-316;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-03-27 15:52:11.273;\n\
14b-316;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 15:55:35.690;\n\
14b-316;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 15:56:03.163;\n\
14b-317;Initialize;NULL;Ansøger;2014-03-27 16:01:08.340;\n\
14b-317;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-27 16:01:08.343;\n\
14b-318;Initialize;NULL;Ansøger;2014-03-27 18:52:08.360;\n\
14b-318;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-27 18:52:08.360;\n\
14b-319;Initialize;NULL;Ansøger;2014-03-27 20:06:11.453;\n\
14b-319;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-27 20:06:11.453;\n\
14a-273;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:20:39.867;\n\
14a-273;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:21:03.727;\n\
14a-272;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:21:25.377;\n\
14a-270;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:21:45.800;\n\
14a-272;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:22:02.293;\n\
14a-270;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:22:03.390;\n\
14a-262;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:22:04.740;\n\
14a-246;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:22:28.570;\n\
14a-262;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:23:02.503;\n\
14a-246;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:23:03.670;\n\
14a-127;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:32:32.807;\n\
14a-118;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:32:45.297;\n\
14a-127;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:33:02.880;\n\
14a-118;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:33:04.290;\n\
14a-243;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:33:59.107;\n\
14a-243;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:34:03.440;\n\
14a-241;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:34:12.447;\n\
14a-239;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:34:26.020;\n\
14a-238;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:34:33.523;\n\
14a-235;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:34:50.390;\n\
14a-231;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:34:56.683;\n\
14a-241;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:35:03.777;\n\
14a-239;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:35:05.050;\n\
14a-238;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:35:06.317;\n\
14a-235;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:35:07.580;\n\
14a-231;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:35:08.883;\n\
14a-230;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:35:17.600;\n\
14a-229;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:35:37.710;\n\
14a-227;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:35:44.390;\n\
14a-230;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:36:05.390;\n\
14a-229;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:36:07.137;\n\
14a-227;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:36:08.377;\n\
14a-226;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:36:22.600;\n\
14a-225;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:36:49.020;\n\
14a-226;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:37:04.077;\n\
14a-225;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:37:05.370;\n\
14a-224;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:37:23.620;\n\
14a-224;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:38:03.733;\n\
14a-183;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-27 22:38:16.273;\n\
14a-183;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-27 22:39:02.883;\n\
14b-320;Initialize;NULL;Ansøger;2014-03-29 15:02:48.087;\n\
14b-320;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-29 15:02:48.087;\n\
14b-321;Initialize;NULL;Ansøger;2014-03-30 12:47:13.970;\n\
14b-321;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-03-30 12:47:13.970;\n\
14a-138;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:28:51.830;\n\
14a-137;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:28:59.653;\n\
14a-138;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:29:04.503;\n\
14a-137;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:29:05.953;\n\
14a-135;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:29:07.620;\n\
14a-134;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:29:21.417;\n\
14a-133;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:29:28.580;\n\
14a-132;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:29:36.493;\n\
14a-145;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:29:50.760;\n\
14a-142;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:29:58.217;\n\
14a-135;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:30:05.157;\n\
14a-141;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:30:05.227;\n\
14a-134;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:30:06.487;\n\
14a-133;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:30:07.753;\n\
14a-132;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:30:09.030;\n\
14a-145;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:30:10.310;\n\
14a-142;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:30:11.580;\n\
14a-140;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:30:13.010;\n\
14a-139;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:30:20.617;\n\
14a-149;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:30:46.553;\n\
14a-148;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:30:54.223;\n\
14a-141;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:31:02.620;\n\
14a-140;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:31:07.893;\n\
14a-139;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:31:09.247;\n\
14a-149;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:31:10.510;\n\
14a-148;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:31:11.883;\n\
14a-153;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:31:13.700;\n\
14a-153;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:32:05.347;\n\
14a-181;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:52:59.297;\n\
14a-181;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:53:02.710;\n\
14a-189;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:53:11.607;\n\
14a-187;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:53:22.160;\n\
14a-190;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:53:37.223;\n\
14a-196;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:53:48.097;\n\
14a-194;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:53:56.057;\n\
14a-189;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:54:02.773;\n\
14a-187;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:54:04.310;\n\
14a-193;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:54:05.553;\n\
14a-190;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:54:06.167;\n\
14a-196;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:54:07.617;\n\
14a-194;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:54:09.120;\n\
14a-202;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:54:30.867;\n\
14a-201;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:54:38.400;\n\
14a-200;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:54:48.930;\n\
14a-193;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:55:04.550;\n\
14a-202;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:55:07.770;\n\
14a-201;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:55:09.130;\n\
14a-200;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:55:10.497;\n\
14a-205;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:55:20.080;\n\
14a-204;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:55:31.497;\n\
14a-210;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:55:45.127;\n\
14a-205;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:56:04.850;\n\
14a-204;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:56:06.097;\n\
14a-209;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:56:06.613;\n\
14a-210;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:56:07.520;\n\
14a-207;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:56:17.663;\n\
14a-214;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:56:29.163;\n\
14a-216;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:56:39.017;\n\
14a-217;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:56:51.007;\n\
14a-209;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:57:04.123;\n\
14a-207;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:57:06.277;\n\
14a-214;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:57:07.537;\n\
14a-216;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:57:08.833;\n\
14a-217;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:57:10.117;\n\
14a-215;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:57:21.860;\n\
14a-223;DL_WFCaseTypeStep!475;Register Decision;ls;2014-03-31 11:57:34.863;\n\
14a-215;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:58:05.550;\n\
14a-223;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-03-31 11:58:06.913;\n\
14b-322;Initialize;NULL;Ansøger;2014-04-01 00:12:51.237;\n\
14b-322;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-01 00:12:51.240;\n\
14a-282;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-04-01 11:38:12.367;\n\
14a-150;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-01 13:34:19.480;\n\
14a-096;RestartWorkflow;NULL;ls;2014-04-03 12:12:45.407;\n\
14a-096;Initialize;NULL;ls;2014-04-03 12:12:45.800;\n\
14a-096;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-03 12:12:50.667;\n\
14a-096;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-03 12:13:33.510;\n\
14a-096;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-03 12:13:35.123;\n\
14a-096;DL_WFCaseTypeStep!475;Register Decision;ls;2014-04-03 12:14:32.070;\n\
14a-096;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-04-03 12:15:03.040;\n\
14a-096;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-03 12:15:09.263;\n\
14a-096;DL_WFCaseTypeStep!544;Pre-process application;ls;2014-04-03 12:18:22.613;\n\
14a-096;DL_WFCaseTypeStep!545;Execute pre-decision;ls;2014-04-03 12:18:24.580;\n\
14a-096;DL_WFCaseTypeStep!477;Reject application;Automatic;2014-04-03 12:18:24.580;\n\
14a-096;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-03 12:19:12.600;\n\
14a-096;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-03 12:19:16.137;\n\
14b-322;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-03 12:56:57.327;\n\
14b-322;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-03 12:57:00.177;\n\
14a-082;DL_WFCaseTypeStep!475;Register Decision;ls;2014-04-03 15:03:09.460;\n\
14a-082;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-04-03 15:04:04.190;\n\
14b-323;Initialize;NULL;Ansøger;2014-04-03 23:16:41.257;\n\
14b-323;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-03 23:16:41.260;\n\
14a-151;DL_WFCaseTypeStep!475;Register Decision;ls;2014-04-04 10:07:46.867;\n\
14a-151;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-04-04 10:08:07.623;\n\
14b-324;Initialize;NULL;Ansøger;2014-04-05 15:40:17.267;\n\
14b-324;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-05 15:40:17.267;\n\
14a-245;DL_WFCaseTypeStep!475;Register Decision;ls;2014-04-07 09:44:06.740;\n\
14a-245;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-04-07 09:45:04.670;\n\
14a-167;DL_WFCaseTypeStep!475;Register Decision;ls;2014-04-07 09:57:33.890;\n\
14a-167;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-04-07 09:58:03.037;\n\
14b-325;Initialize;NULL;Ansøger;2014-04-07 11:00:14.290;\n\
14b-325;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-07 11:00:14.290;\n\
14a-096;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2014-04-08 11:21:14.073;\n\
14a-096;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2014-04-08 11:25:08.223;\n\
14a-096;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2014-04-08 11:25:14.553;\n\
14b-326;Initialize;NULL;Ansøger;2014-04-08 14:12:08.990;\n\
14b-326;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-08 14:12:08.990;\n\
14b-327;Initialize;NULL;Ansøger;2014-04-08 17:07:23.617;\n\
14b-327;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-08 17:07:23.633;\n\
14a-031;AddEvent;NULL;FIX;2014-04-08 23:38:27.653;\n\
14a-031;AddNesting;NULL;FIX;2014-04-08 23:38:27.667;\n\
14a-027;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.030;\n\
14a-028;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.030;\n\
14a-030;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.030;\n\
14a-032;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.033;\n\
14a-029;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.033;\n\
14a-031;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.033;\n\
14a-035;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.033;\n\
14a-036;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.037;\n\
14a-037;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.037;\n\
14a-038;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.037;\n\
14a-039;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.037;\n\
14a-040;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.040;\n\
14a-041;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.040;\n\
14a-042;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.040;\n\
14a-043;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.040;\n\
14a-044;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.040;\n\
14a-045;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.043;\n\
14a-046;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.043;\n\
14a-047;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.043;\n\
14a-048;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.043;\n\
14a-049;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.043;\n\
14a-051;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.047;\n\
14a-052;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.047;\n\
14a-053;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.047;\n\
14a-054;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.047;\n\
14a-055;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.050;\n\
14a-056;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.050;\n\
14a-057;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.050;\n\
14a-059;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.050;\n\
14a-060;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.050;\n\
14a-061;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.053;\n\
14a-063;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.053;\n\
14a-064;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.053;\n\
14a-065;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.053;\n\
14a-066;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.057;\n\
14a-067;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.057;\n\
14a-068;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.057;\n\
14a-069;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.060;\n\
14a-070;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.060;\n\
14a-071;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.060;\n\
14a-072;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.060;\n\
14a-073;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.060;\n\
14a-074;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.063;\n\
14a-075;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.063;\n\
14a-076;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.063;\n\
14a-077;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.063;\n\
14a-078;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.067;\n\
14a-079;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.067;\n\
14a-080;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.067;\n\
14a-081;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.067;\n\
14a-082;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.067;\n\
14a-083;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.070;\n\
14a-084;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.070;\n\
14a-088;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.070;\n\
14a-090;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.070;\n\
14a-091;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.070;\n\
14a-092;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.070;\n\
14a-094;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.073;\n\
14a-095;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.073;\n\
14a-097;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.073;\n\
14a-098;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.073;\n\
14a-100;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.077;\n\
14a-101;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.077;\n\
14a-102;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.077;\n\
14a-103;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.080;\n\
14a-104;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.080;\n\
14a-105;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.080;\n\
14a-106;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.080;\n\
14a-107;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.080;\n\
14a-108;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.080;\n\
14a-109;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.083;\n\
14a-110;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.083;\n\
14a-111;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.083;\n\
14a-112;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.083;\n\
14a-113;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.083;\n\
14a-114;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.087;\n\
14a-115;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.087;\n\
14a-116;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.087;\n\
14a-117;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.087;\n\
14a-118;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.090;\n\
14a-120;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.090;\n\
14a-121;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.090;\n\
14a-122;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.090;\n\
14a-123;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.090;\n\
14a-124;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.093;\n\
14a-125;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.093;\n\
14a-126;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.093;\n\
14a-127;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.093;\n\
14a-128;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.093;\n\
14a-129;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.097;\n\
14a-130;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.097;\n\
14a-131;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.097;\n\
14a-132;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.097;\n\
14a-133;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.100;\n\
14a-134;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.100;\n\
14a-135;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.100;\n\
14a-136;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.100;\n\
14a-137;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.100;\n\
14a-138;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.100;\n\
14a-139;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.103;\n\
14a-140;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.103;\n\
14a-141;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.103;\n\
14a-142;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.103;\n\
14a-143;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.107;\n\
14a-144;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.107;\n\
14a-145;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.107;\n\
14a-146;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.107;\n\
14a-147;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.110;\n\
14a-148;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.110;\n\
14a-149;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.110;\n\
14a-150;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.110;\n\
14a-151;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.110;\n\
14a-152;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.110;\n\
14a-153;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.113;\n\
14a-154;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.113;\n\
14a-155;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.113;\n\
14a-156;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.113;\n\
14a-157;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.117;\n\
14a-158;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.117;\n\
14a-159;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.117;\n\
14a-160;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.117;\n\
14a-161;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.117;\n\
14a-162;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.120;\n\
14a-163;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.120;\n\
14a-164;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.120;\n\
14a-165;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.123;\n\
14a-166;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.123;\n\
14a-167;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.123;\n\
14a-168;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.127;\n\
14a-169;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.127;\n\
14a-170;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.127;\n\
14a-171;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.153;\n\
14a-172;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.167;\n\
14a-173;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.167;\n\
14a-174;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.167;\n\
14a-175;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.170;\n\
14a-176;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.170;\n\
14a-177;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.170;\n\
14a-178;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.170;\n\
14a-179;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.170;\n\
14a-180;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.173;\n\
14a-181;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.173;\n\
14a-182;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.173;\n\
14a-183;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.177;\n\
14a-184;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.177;\n\
14a-185;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.177;\n\
14a-186;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.177;\n\
14a-187;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.180;\n\
14a-188;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.180;\n\
14a-189;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.180;\n\
14a-190;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.180;\n\
14a-191;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.180;\n\
14a-192;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.183;\n\
14a-193;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.183;\n\
14a-194;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.183;\n\
14a-195;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.183;\n\
14a-196;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.187;\n\
14a-197;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.187;\n\
14a-198;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.187;\n\
14a-199;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.187;\n\
14a-200;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.190;\n\
14a-201;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.190;\n\
14a-202;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.190;\n\
14a-203;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.190;\n\
14a-204;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.190;\n\
14a-205;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.193;\n\
14a-206;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.193;\n\
14a-207;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.193;\n\
14a-208;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.193;\n\
14a-209;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.197;\n\
14a-210;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.197;\n\
14a-211;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.197;\n\
14a-212;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.200;\n\
14a-213;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.200;\n\
14a-214;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.200;\n\
14a-215;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.200;\n\
14a-216;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.200;\n\
14a-217;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.203;\n\
14a-218;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.203;\n\
14a-219;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.203;\n\
14a-220;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.203;\n\
14a-221;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.207;\n\
14a-222;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.207;\n\
14a-223;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.207;\n\
14a-224;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.210;\n\
14a-225;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.210;\n\
14a-226;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.210;\n\
14a-227;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.210;\n\
14a-228;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.210;\n\
14a-229;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.213;\n\
14a-230;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.213;\n\
14a-231;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.213;\n\
14a-232;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.213;\n\
14a-233;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.217;\n\
14a-234;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.217;\n\
14a-235;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.217;\n\
14a-236;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.220;\n\
14a-237;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.220;\n\
14a-238;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.220;\n\
14a-239;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.220;\n\
14a-240;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.220;\n\
14a-241;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.223;\n\
14a-242;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.223;\n\
14a-243;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.227;\n\
14a-244;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.227;\n\
14a-245;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.227;\n\
14a-246;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.230;\n\
14a-247;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.230;\n\
14a-249;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.230;\n\
14a-250;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.230;\n\
14a-251;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.230;\n\
14a-252;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.233;\n\
14a-253;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.233;\n\
14a-026;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.233;\n\
14a-058;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.233;\n\
14a-254;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.237;\n\
14a-255;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.237;\n\
14a-256;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.237;\n\
14a-257;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.237;\n\
14a-258;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.240;\n\
14a-259;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.240;\n\
14a-260;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.240;\n\
14a-261;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.240;\n\
14a-262;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.240;\n\
14a-263;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.243;\n\
14a-264;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.243;\n\
14a-265;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.243;\n\
14a-266;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.247;\n\
14a-267;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.247;\n\
14a-268;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.247;\n\
14a-269;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.247;\n\
14a-270;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.250;\n\
14a-271;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.250;\n\
14a-272;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.250;\n\
14a-273;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.250;\n\
14a-274;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.250;\n\
14a-275;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.253;\n\
14a-276;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.253;\n\
14a-277;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.253;\n\
14a-278;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.253;\n\
14a-279;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.257;\n\
14a-280;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.257;\n\
14a-281;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.257;\n\
14a-282;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.257;\n\
14a-099;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.260;\n\
14a-283;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.260;\n\
14a-284;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.260;\n\
14a-285;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.260;\n\
14a-286;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.260;\n\
14a-287;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.263;\n\
14a-288;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.263;\n\
14a-296;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.263;\n\
14b-297;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.267;\n\
14b-304;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.267;\n\
14b-316;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-09 03:00:05.267;\n\
14b-327;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 13:45:32.167;\n\
14b-327;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-04-09 13:45:36.187;\n\
14b-318;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 16:10:11.090;\n\
14b-318;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 16:10:14.847;\n\
14b-298;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 16:12:46.260;\n\
14b-298;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 16:12:49.037;\n\
14b-302;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 16:30:27.557;\n\
14b-302;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 16:30:30.367;\n\
14b-301;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-09 16:33:39.893;\n\
14b-303;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 16:38:10.013;\n\
14b-303;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 16:38:12.750;\n\
14b-307;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 17:04:20.443;\n\
14b-307;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 17:04:23.320;\n\
14b-308;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 17:09:55.867;\n\
14b-308;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 17:09:58.663;\n\
14b-309;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 17:13:00.763;\n\
14b-309;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 17:13:03.470;\n\
14b-310;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 17:16:14.610;\n\
14b-310;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 17:16:17.347;\n\
14b-311;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 17:20:33.780;\n\
14b-311;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 17:20:36.400;\n\
14b-312;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 18:23:15.863;\n\
14b-312;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 18:23:19.597;\n\
14b-313;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 18:33:07.630;\n\
14b-313;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 18:33:10.277;\n\
14b-314;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 18:45:08.207;\n\
14b-314;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 18:45:11.013;\n\
14b-315;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 18:51:24.183;\n\
14b-315;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 18:51:26.833;\n\
14b-317;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 19:03:31.817;\n\
14b-317;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 19:03:34.637;\n\
14b-319;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 19:17:25.243;\n\
14b-319;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 19:17:28.010;\n\
14b-308;RestartWorkflow;NULL;ls;2014-04-09 19:23:06.717;\n\
14b-308;Initialize;NULL;ls;2014-04-09 19:23:07.187;\n\
14b-308;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-09 19:23:11.753;\n\
14b-308;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-09 19:31:19.840;\n\
14b-320;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 19:39:45.307;\n\
14b-320;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 19:39:48.267;\n\
14b-321;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 19:55:24.373;\n\
14b-321;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 19:55:27.240;\n\
14b-323;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 20:42:21.110;\n\
14b-323;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 20:42:25.113;\n\
14b-326;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 20:43:17.200;\n\
14b-326;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 20:43:18.830;\n\
14b-325;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 20:52:06.513;\n\
14b-325;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 20:52:09.187;\n\
14b-324;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-09 20:53:29.487;\n\
14b-305;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-09 21:03:31.690;\n\
14b-305;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-09 21:03:34.423;\n\
14b-301;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 13:23:33.180;\n\
14b-301;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-10 13:23:38.407;\n\
14a-289;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 16:00:29.670;\n\
14a-289;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-10 16:00:32.343;\n\
14a-031;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 16:22:09.117;\n\
14a-031;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-10 16:22:13.990;\n\
14a-228;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 16:41:17.537;\n\
14a-279;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 16:41:17.827;\n\
14a-228;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-10 16:41:26.377;\n\
14a-279;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-10 16:41:29.927;\n\
14a-251;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 16:49:35.247;\n\
14a-251;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-10 16:49:41.733;\n\
14a-219;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 16:57:04.650;\n\
14a-219;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-10 16:57:11.330;\n\
14a-099;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 17:04:33.880;\n\
14a-099;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-10 17:04:38.167;\n\
14a-043;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 17:59:32.827;\n\
14a-043;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-10 17:59:38.160;\n\
14a-038;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 18:05:01.640;\n\
14a-038;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-10 18:05:07.210;\n\
14a-047;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 21:49:20.963;\n\
14a-288;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-10 21:49:21.050;\n\
14a-047;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-10 21:49:41.147;\n\
14a-288;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-10 21:49:45.010;\n\
14b-328;Initialize;NULL;Ansøger;2014-04-11 12:05:08.900;\n\
14b-328;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-11 12:05:08.900;\n\
14b-328;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-11 12:35:05.157;\n\
14b-328;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-11 12:35:09.223;\n\
14b-329;Initialize;NULL;Ansøger;2014-04-12 18:42:33.403;\n\
14b-329;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-12 18:42:33.403;\n\
14a-251;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-12 19:26:42.950;\n\
14a-251;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-12 19:26:45.717;\n\
14b-297;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-13 00:55:41.017;\n\
14a-031;RestartWorkflow;NULL;Administrator;2014-04-13 17:41:33.460;\n\
14a-031;Initialize;NULL;administrator;2014-04-13 17:41:33.833;\n\
14a-031;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-13 17:41:39.770;\n\
14a-031;DL_WFCaseTypeStep!469;Approval - on to the board;Administrator;2014-04-13 17:41:55.467;\n\
14a-031;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-04-13 17:41:57.057;\n\
14a-031;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;Administrator;2014-04-13 17:41:59.843;\n\
14a-031;DL_WFCaseTypeStep!475;Register Decision;administrator;2014-04-13 17:43:05.843;\n\
14a-031;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2014-04-13 17:43:24.097;\n\
14a-031;DL_WFCaseTypeStep!544;Pre-process application;Administrator;2014-04-13 17:43:32.467;\n\
14a-031;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2014-04-13 17:43:33.563;\n\
14a-031;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-04-13 17:43:33.563;\n\
14a-031;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2014-04-13 17:43:42.070;\n\
14a-031;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2014-04-13 17:43:42.073;\n\
14a-031;AddEvent;NULL;FIX;2014-04-13 17:44:28.590;\n\
14a-031;AddNesting;NULL;FIX;2014-04-13 17:44:28.603;\n\
14b-329;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-13 18:38:30.393;\n\
14a-031;DL_WFCaseTypeStep!510;Round Ends;suser;2014-04-14 03:00:04.623;\n\
14a-091;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-14 09:29:47.037;\n\
14a-091;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-14 09:29:55.000;\n\
14a-103;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-14 09:30:55.387;\n\
14a-125;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-14 09:30:55.740;\n\
14a-103;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-14 09:31:00.903;\n\
14a-125;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-14 09:31:06.133;\n\
14a-144;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-14 09:31:54.947;\n\
14a-156;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-14 09:31:55.233;\n\
14a-254;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-14 09:31:55.517;\n\
14a-264;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-14 09:31:55.820;\n\
14a-283;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-14 09:31:56.107;\n\
14a-144;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-14 09:32:06.600;\n\
14a-156;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-14 09:32:10.987;\n\
14a-254;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-14 09:32:15.047;\n\
14a-264;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-14 09:32:18.257;\n\
14a-283;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-14 09:32:21.610;\n\
14b-308;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-14 09:38:59.863;\n\
14b-308;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-14 09:39:05.580;\n\
14b-330;Initialize;NULL;Ansøger;2014-04-14 11:27:04.860;\n\
14b-330;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-14 11:27:04.863;\n\
14b-331;Initialize;NULL;Ansøger;2014-04-14 12:46:46.180;\n\
14b-331;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-14 12:46:46.180;\n\
14b-332;Initialize;NULL;Ansøger;2014-04-14 19:09:06.400;\n\
14b-332;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-14 19:09:06.400;\n\
14b-333;Initialize;NULL;Ansøger;2014-04-14 20:21:40.270;\n\
14b-333;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-14 20:21:40.270;\n\
14a-026;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.960;\n\
14a-027;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.960;\n\
14a-028;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.960;\n\
14a-029;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.960;\n\
14a-030;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.960;\n\
14a-031;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.963;\n\
14a-032;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.963;\n\
14a-035;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.963;\n\
14a-036;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.963;\n\
14a-037;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.963;\n\
14a-039;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.963;\n\
14a-040;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.963;\n\
14a-041;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.963;\n\
14a-042;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.967;\n\
14a-044;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.967;\n\
14a-045;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.967;\n\
14a-046;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.967;\n\
14a-048;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.967;\n\
14a-049;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.967;\n\
14a-051;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.967;\n\
14a-052;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.970;\n\
14a-053;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.970;\n\
14a-054;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.970;\n\
14a-055;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.970;\n\
14a-056;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.970;\n\
14a-057;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.970;\n\
14a-058;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.970;\n\
14a-059;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.970;\n\
14a-060;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.970;\n\
14a-061;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.970;\n\
14a-063;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.970;\n\
14a-064;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.973;\n\
14a-065;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.973;\n\
14a-066;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.973;\n\
14a-067;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.973;\n\
14a-068;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.973;\n\
14a-069;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.973;\n\
14a-070;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.973;\n\
14a-071;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.973;\n\
14a-072;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.977;\n\
14a-073;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.977;\n\
14a-074;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.977;\n\
14a-075;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.977;\n\
14a-076;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.977;\n\
14a-077;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.977;\n\
14a-078;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.977;\n\
14a-079;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.977;\n\
14a-080;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.980;\n\
14a-081;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.980;\n\
14a-082;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.980;\n\
14a-083;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.980;\n\
14a-084;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.980;\n\
14a-088;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.980;\n\
14a-090;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.980;\n\
14a-092;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.980;\n\
14a-094;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.980;\n\
14a-095;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.980;\n\
14a-097;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.983;\n\
14a-098;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.983;\n\
14a-100;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.983;\n\
14a-101;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.983;\n\
14a-102;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.983;\n\
14a-104;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.983;\n\
14a-105;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.983;\n\
14a-106;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.987;\n\
14a-107;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.987;\n\
14a-108;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.987;\n\
14a-109;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.987;\n\
14a-110;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.987;\n\
14a-111;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.987;\n\
14a-112;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.987;\n\
14a-113;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.987;\n\
14a-114;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.990;\n\
14a-115;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.990;\n\
14a-116;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.990;\n\
14a-117;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.990;\n\
14a-118;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.990;\n\
14a-120;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.990;\n\
14a-121;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.990;\n\
14a-122;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.990;\n\
14a-123;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.990;\n\
14a-124;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.990;\n\
14a-126;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.990;\n\
14a-127;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.993;\n\
14a-128;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.993;\n\
14a-129;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.993;\n\
14a-130;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.993;\n\
14a-131;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.993;\n\
14a-132;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.993;\n\
14a-133;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.993;\n\
14a-134;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.993;\n\
14a-135;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.997;\n\
14a-136;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.997;\n\
14a-137;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.997;\n\
14a-138;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.997;\n\
14a-139;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.997;\n\
14a-140;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.997;\n\
14a-141;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.997;\n\
14a-142;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:49.997;\n\
14a-143;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.000;\n\
14a-145;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.000;\n\
14a-146;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.000;\n\
14a-147;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.003;\n\
14a-148;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.003;\n\
14a-149;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.003;\n\
14a-150;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.003;\n\
14a-151;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.007;\n\
14a-152;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.007;\n\
14a-153;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.007;\n\
14a-154;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.007;\n\
14a-155;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.007;\n\
14a-157;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.007;\n\
14a-158;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.007;\n\
14a-159;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.007;\n\
14a-160;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.010;\n\
14a-161;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.010;\n\
14a-162;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.010;\n\
14a-163;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.010;\n\
14a-164;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.010;\n\
14a-165;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.010;\n\
14a-166;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.010;\n\
14a-167;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.010;\n\
14a-168;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.010;\n\
14a-169;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.010;\n\
14a-170;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.013;\n\
14a-171;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.013;\n\
14a-172;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.013;\n\
14a-173;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.013;\n\
14a-174;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.013;\n\
14a-175;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.013;\n\
14a-176;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.013;\n\
14a-177;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.013;\n\
14a-178;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.017;\n\
14a-179;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.017;\n\
14a-180;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.017;\n\
14a-181;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.017;\n\
14a-182;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.017;\n\
14a-183;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.017;\n\
14a-184;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.017;\n\
14a-185;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.020;\n\
14a-186;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.020;\n\
14a-187;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.020;\n\
14a-188;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.020;\n\
14a-189;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.020;\n\
14a-190;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.020;\n\
14a-191;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.020;\n\
14a-192;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.020;\n\
14a-193;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.020;\n\
14a-194;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.020;\n\
14a-195;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.020;\n\
14a-196;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.023;\n\
14a-197;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.023;\n\
14a-198;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.023;\n\
14a-199;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.023;\n\
14a-200;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.023;\n\
14a-201;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.023;\n\
14a-202;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.023;\n\
14a-203;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.023;\n\
14a-204;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.027;\n\
14a-205;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.027;\n\
14a-206;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.027;\n\
14a-207;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.027;\n\
14a-208;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.027;\n\
14a-209;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.027;\n\
14a-210;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.027;\n\
14a-211;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.027;\n\
14a-212;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.030;\n\
14a-213;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.030;\n\
14a-214;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.030;\n\
14a-215;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.030;\n\
14a-216;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.030;\n\
14a-217;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.030;\n\
14a-218;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.030;\n\
14a-220;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.030;\n\
14a-221;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.030;\n\
14a-222;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.030;\n\
14a-223;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.033;\n\
14a-224;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.033;\n\
14a-225;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.033;\n\
14a-226;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.033;\n\
14a-227;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.033;\n\
14a-229;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.033;\n\
14a-230;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.033;\n\
14a-231;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.033;\n\
14a-232;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.037;\n\
14a-233;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.037;\n\
14a-234;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.037;\n\
14a-235;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.037;\n\
14a-236;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.037;\n\
14a-237;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.037;\n\
14a-238;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.037;\n\
14a-239;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.037;\n\
14a-240;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.040;\n\
14a-241;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.040;\n\
14a-242;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.040;\n\
14a-243;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.040;\n\
14a-244;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.040;\n\
14a-245;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.040;\n\
14a-246;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.040;\n\
14a-247;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.040;\n\
14a-249;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.040;\n\
14a-250;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.040;\n\
14a-252;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.043;\n\
14a-253;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.043;\n\
14a-255;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.043;\n\
14a-256;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.043;\n\
14a-257;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.043;\n\
14a-258;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.043;\n\
14a-259;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.043;\n\
14a-260;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.043;\n\
14a-261;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.047;\n\
14a-262;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.047;\n\
14a-263;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.047;\n\
14a-265;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.047;\n\
14a-266;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.047;\n\
14a-267;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.047;\n\
14a-268;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.047;\n\
14a-269;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.050;\n\
14a-270;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.050;\n\
14a-271;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.050;\n\
14a-272;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.050;\n\
14a-273;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.050;\n\
14a-274;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.050;\n\
14a-275;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.050;\n\
14a-276;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.050;\n\
14a-277;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.050;\n\
14a-278;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.050;\n\
14a-280;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.053;\n\
14a-281;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.053;\n\
14a-282;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.053;\n\
14a-284;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.053;\n\
14a-285;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.053;\n\
14a-286;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.053;\n\
14a-287;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.053;\n\
14a-296;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.053;\n\
14b-297;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.057;\n\
14b-304;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.057;\n\
14b-316;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-04-15 14:50:50.057;\n\
14a-031;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-04-15 14:53:37.903;\n\
14a-031;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-04-15 14:56:37.743;\n\
14b-334;Initialize;NULL;Ansøger;2014-04-15 19:29:51.097;\n\
14b-334;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-15 19:29:51.100;\n\
14a-027;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:32:09.167;\n\
14a-028;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:35.517;\n\
14a-030;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:35.580;\n\
14a-029;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:35.647;\n\
14a-032;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:35.710;\n\
14a-035;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:35.770;\n\
14a-037;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:35.830;\n\
14a-036;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:35.893;\n\
14a-041;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:35.957;\n\
14a-042;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.027;\n\
14a-044;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.090;\n\
14a-045;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.153;\n\
14a-046;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.217;\n\
14a-048;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.277;\n\
14a-049;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.340;\n\
14a-051;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.400;\n\
14a-052;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.463;\n\
14a-053;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.527;\n\
14a-054;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.587;\n\
14a-055;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.650;\n\
14a-056;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.713;\n\
14a-057;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.773;\n\
14a-059;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.837;\n\
14a-060;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.900;\n\
14a-061;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:36.960;\n\
14a-063;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.027;\n\
14a-026;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.090;\n\
14a-064;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.157;\n\
14a-065;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.217;\n\
14a-066;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.280;\n\
14a-067;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.340;\n\
14a-068;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.403;\n\
14a-069;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.467;\n\
14a-070;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.530;\n\
14a-071;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.590;\n\
14a-072;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.657;\n\
14a-073;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.877;\n\
14a-074;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:37.980;\n\
14a-075;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.047;\n\
14a-076;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.117;\n\
14a-077;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.177;\n\
14a-078;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.253;\n\
14a-079;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.327;\n\
14a-080;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.400;\n\
14a-081;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.497;\n\
14a-082;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.560;\n\
14a-083;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.627;\n\
14a-084;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.693;\n\
14a-088;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.893;\n\
14a-090;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:38.957;\n\
14a-100;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.023;\n\
14a-101;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.090;\n\
14a-092;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.153;\n\
14a-094;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.230;\n\
14a-095;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.293;\n\
14a-097;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.367;\n\
14a-098;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.430;\n\
14a-102;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.490;\n\
14a-104;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.553;\n\
14a-105;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.617;\n\
14a-106;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.680;\n\
14a-107;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.750;\n\
14a-108;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.813;\n\
14a-110;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.877;\n\
14a-109;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:39.937;\n\
14a-111;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.000;\n\
14a-112;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.063;\n\
14a-113;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.130;\n\
14a-114;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.193;\n\
14a-115;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.260;\n\
14a-116;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.350;\n\
14a-117;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.437;\n\
14a-118;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.560;\n\
14a-120;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.663;\n\
14a-121;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.727;\n\
14a-122;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.787;\n\
14a-123;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.850;\n\
14a-124;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.910;\n\
14a-126;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:40.977;\n\
14a-127;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.043;\n\
14a-128;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.110;\n\
14a-129;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.210;\n\
14a-130;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.270;\n\
14a-131;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.333;\n\
14a-132;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.393;\n\
14a-133;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.457;\n\
14a-134;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.517;\n\
14a-135;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.580;\n\
14a-136;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.640;\n\
14a-137;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.703;\n\
14a-138;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.763;\n\
14a-139;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.827;\n\
14a-140;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.887;\n\
14a-141;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:41.950;\n\
14a-142;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.013;\n\
14a-143;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.077;\n\
14a-145;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.137;\n\
14a-146;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.203;\n\
14a-147;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.267;\n\
14a-148;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.330;\n\
14a-149;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.390;\n\
14a-151;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.453;\n\
14a-152;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.527;\n\
14a-153;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.590;\n\
14a-154;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.653;\n\
14a-155;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.717;\n\
14a-157;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.780;\n\
14a-158;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.840;\n\
14a-159;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.903;\n\
14a-160;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:42.963;\n\
14a-161;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.027;\n\
14a-162;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.090;\n\
14a-163;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.157;\n\
14a-164;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.237;\n\
14a-165;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.300;\n\
14a-166;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.360;\n\
14a-167;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.423;\n\
14a-168;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.483;\n\
14a-170;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.547;\n\
14a-171;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.607;\n\
14a-172;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.670;\n\
14a-173;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.733;\n\
14a-174;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.797;\n\
14a-175;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.863;\n\
14a-177;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.923;\n\
14a-179;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:43.987;\n\
14a-180;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.050;\n\
14a-181;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.120;\n\
14a-182;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.183;\n\
14a-183;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.280;\n\
14a-184;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.340;\n\
14a-185;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.403;\n\
14a-186;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.467;\n\
14a-187;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.527;\n\
14a-188;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.590;\n\
14a-189;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.720;\n\
14a-190;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.783;\n\
14a-191;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.847;\n\
14a-192;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.907;\n\
14a-178;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:44.970;\n\
14a-169;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.030;\n\
14a-176;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.093;\n\
14a-193;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.157;\n\
14a-194;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.230;\n\
14a-195;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.293;\n\
14a-196;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.357;\n\
14a-198;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.420;\n\
14a-199;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.480;\n\
14a-200;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.543;\n\
14a-197;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.607;\n\
14a-201;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.670;\n\
14a-202;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.730;\n\
14a-203;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.793;\n\
14a-204;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.857;\n\
14a-205;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:45.947;\n\
14a-207;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.007;\n\
14a-208;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.070;\n\
14a-209;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.133;\n\
14a-210;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.217;\n\
14a-211;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.280;\n\
14a-213;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.370;\n\
14a-206;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.433;\n\
14a-212;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.497;\n\
14a-214;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.563;\n\
14a-215;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.627;\n\
14a-216;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.690;\n\
14a-217;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.753;\n\
14a-218;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.813;\n\
14a-220;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.877;\n\
14a-221;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:46.937;\n\
14a-222;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.000;\n\
14a-223;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.060;\n\
14a-224;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.123;\n\
14a-226;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.187;\n\
14a-225;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.250;\n\
14a-227;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.313;\n\
14a-229;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.403;\n\
14a-230;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.467;\n\
14a-231;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.530;\n\
14a-232;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.593;\n\
14a-233;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.653;\n\
14a-234;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.717;\n\
14a-235;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.790;\n\
14a-236;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:47.850;\n\
14a-237;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.040;\n\
14a-238;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.137;\n\
14a-239;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.197;\n\
14a-240;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.257;\n\
14a-241;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.323;\n\
14a-242;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.450;\n\
14a-243;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.527;\n\
14a-244;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.597;\n\
14a-245;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.677;\n\
14a-246;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.743;\n\
14a-247;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.807;\n\
14a-250;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.870;\n\
14a-252;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:48.933;\n\
14a-253;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.000;\n\
14a-255;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.063;\n\
14a-256;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.130;\n\
14a-257;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.197;\n\
14a-258;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.260;\n\
14a-259;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.353;\n\
14a-260;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.463;\n\
14a-261;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.527;\n\
14a-262;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.590;\n\
14a-263;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.650;\n\
14a-265;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.713;\n\
14a-266;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.777;\n\
14a-267;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.837;\n\
14a-268;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.900;\n\
14a-269;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:49.963;\n\
14a-270;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.023;\n\
14a-271;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.087;\n\
14a-272;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.187;\n\
14a-273;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.263;\n\
14a-274;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.327;\n\
14a-275;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.397;\n\
14a-276;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.463;\n\
14a-277;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.540;\n\
14a-278;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.627;\n\
14a-280;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.713;\n\
14a-281;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.817;\n\
14a-284;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.897;\n\
14a-285;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:50.957;\n\
14a-286;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:51.020;\n\
14a-287;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:51.083;\n\
14a-296;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:51.153;\n\
14b-304;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:51.217;\n\
14b-316;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-16 10:36:51.280;\n\
14a-058;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-16 11:51:51.410;\n\
14a-058;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-16 11:51:58.440;\n\
14a-026;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.247;\n\
14a-027;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.250;\n\
14a-028;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.253;\n\
14a-029;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.257;\n\
14a-030;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.260;\n\
14a-031;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.270;\n\
14a-032;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.270;\n\
14a-035;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.270;\n\
14a-036;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.273;\n\
14a-037;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.290;\n\
14a-039;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.293;\n\
14a-040;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.293;\n\
14a-041;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.297;\n\
14a-042;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.297;\n\
14a-044;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.297;\n\
14a-045;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.297;\n\
14a-046;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.300;\n\
14a-048;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.300;\n\
14a-049;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.300;\n\
14a-051;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.300;\n\
14a-052;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.313;\n\
14a-053;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.313;\n\
14a-054;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.313;\n\
14a-055;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.313;\n\
14a-056;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.317;\n\
14a-057;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.317;\n\
14a-059;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.317;\n\
14a-060;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.317;\n\
14a-061;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.320;\n\
14a-063;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.320;\n\
14a-064;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.320;\n\
14a-065;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.320;\n\
14a-066;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.320;\n\
14a-067;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.320;\n\
14a-068;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.323;\n\
14a-069;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.323;\n\
14a-070;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.323;\n\
14a-071;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.323;\n\
14a-072;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.327;\n\
14a-073;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.327;\n\
14a-074;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.327;\n\
14a-075;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.327;\n\
14a-076;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.327;\n\
14a-077;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.330;\n\
14a-078;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.330;\n\
14a-079;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.330;\n\
14a-080;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.330;\n\
14a-081;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.333;\n\
14a-082;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.333;\n\
14a-083;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.333;\n\
14a-084;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.333;\n\
14a-088;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.333;\n\
14a-090;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.337;\n\
14a-092;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.337;\n\
14a-094;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.337;\n\
14a-095;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.337;\n\
14a-097;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.340;\n\
14a-098;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.340;\n\
14a-100;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.340;\n\
14a-101;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.340;\n\
14a-102;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.340;\n\
14a-104;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.340;\n\
14a-105;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.343;\n\
14a-106;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.343;\n\
14a-107;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.343;\n\
14a-108;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.347;\n\
14a-109;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.347;\n\
14a-110;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.347;\n\
14a-111;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.347;\n\
14a-112;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.350;\n\
14a-113;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.360;\n\
14a-114;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.360;\n\
14a-115;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.363;\n\
14a-116;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.363;\n\
14a-117;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.363;\n\
14a-118;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.363;\n\
14a-120;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.367;\n\
14a-121;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.367;\n\
14a-122;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.367;\n\
14a-123;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.367;\n\
14a-124;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.367;\n\
14a-126;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.370;\n\
14a-127;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.370;\n\
14a-128;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.370;\n\
14a-129;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.370;\n\
14a-130;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.370;\n\
14a-131;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.370;\n\
14a-132;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.373;\n\
14a-133;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.373;\n\
14a-134;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.373;\n\
14a-135;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.373;\n\
14a-136;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.377;\n\
14a-137;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.377;\n\
14a-138;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.377;\n\
14a-139;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.377;\n\
14a-140;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.380;\n\
14a-141;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.380;\n\
14a-142;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.380;\n\
14a-143;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.380;\n\
14a-145;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.383;\n\
14a-146;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.383;\n\
14a-147;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.383;\n\
14a-148;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.383;\n\
14a-149;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.387;\n\
14a-150;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.387;\n\
14a-151;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.387;\n\
14a-152;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.387;\n\
14a-153;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.387;\n\
14a-154;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.390;\n\
14a-155;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.390;\n\
14a-157;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.390;\n\
14a-158;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.390;\n\
14a-159;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.390;\n\
14a-160;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.393;\n\
14a-161;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.393;\n\
14a-162;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.393;\n\
14a-163;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.393;\n\
14a-164;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.393;\n\
14a-165;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.397;\n\
14a-166;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.397;\n\
14a-167;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.397;\n\
14a-168;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.397;\n\
14a-169;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.400;\n\
14a-170;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.400;\n\
14a-171;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.400;\n\
14a-172;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.400;\n\
14a-173;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.400;\n\
14a-174;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.400;\n\
14a-175;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.403;\n\
14a-176;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.403;\n\
14a-177;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.403;\n\
14a-178;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.407;\n\
14a-179;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.407;\n\
14a-180;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.407;\n\
14a-181;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.407;\n\
14a-182;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.410;\n\
14a-183;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.410;\n\
14a-184;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.410;\n\
14a-185;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.410;\n\
14a-186;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.410;\n\
14a-187;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.413;\n\
14a-188;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.413;\n\
14a-189;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.413;\n\
14a-190;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.413;\n\
14a-191;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.417;\n\
14a-192;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.417;\n\
14a-193;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.417;\n\
14a-194;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.417;\n\
14a-195;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.440;\n\
14a-196;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.440;\n\
14a-197;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.440;\n\
14a-198;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.440;\n\
14a-199;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.443;\n\
14a-200;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.443;\n\
14a-201;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.443;\n\
14a-202;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.443;\n\
14a-203;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.447;\n\
14a-204;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.447;\n\
14a-205;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.447;\n\
14a-206;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.447;\n\
14a-207;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.447;\n\
14a-208;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.450;\n\
14a-209;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.450;\n\
14a-210;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.450;\n\
14a-211;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.450;\n\
14a-212;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.450;\n\
14a-213;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.450;\n\
14a-214;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.453;\n\
14a-215;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.453;\n\
14a-216;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.453;\n\
14a-217;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.453;\n\
14a-218;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.457;\n\
14a-220;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.457;\n\
14a-221;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.457;\n\
14a-222;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.457;\n\
14a-223;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.460;\n\
14a-224;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.460;\n\
14a-225;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.460;\n\
14a-226;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.460;\n\
14a-227;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.460;\n\
14a-229;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.460;\n\
14a-230;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.463;\n\
14a-231;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.463;\n\
14a-232;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.463;\n\
14a-233;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.463;\n\
14a-234;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.467;\n\
14a-235;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.467;\n\
14a-236;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.467;\n\
14a-237;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.467;\n\
14a-238;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.470;\n\
14a-239;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.470;\n\
14a-240;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.470;\n\
14a-241;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.470;\n\
14a-242;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.470;\n\
14a-243;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.473;\n\
14a-244;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.473;\n\
14a-245;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.473;\n\
14a-246;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.473;\n\
14a-247;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.477;\n\
14a-249;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.477;\n\
14a-250;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.477;\n\
14a-252;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.477;\n\
14a-253;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.480;\n\
14a-255;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.480;\n\
14a-256;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.480;\n\
14a-257;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.480;\n\
14a-258;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.480;\n\
14a-259;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.480;\n\
14a-260;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.483;\n\
14a-261;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.483;\n\
14a-262;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.487;\n\
14a-263;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.487;\n\
14a-265;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.487;\n\
14a-266;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.487;\n\
14a-267;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.490;\n\
14a-268;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.490;\n\
14a-269;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.490;\n\
14a-270;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.490;\n\
14a-271;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.490;\n\
14a-272;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.490;\n\
14a-273;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.493;\n\
14a-274;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.493;\n\
14a-275;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.493;\n\
14a-276;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.493;\n\
14a-277;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.497;\n\
14a-278;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.497;\n\
14a-280;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.497;\n\
14a-281;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.497;\n\
14a-282;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.500;\n\
14a-284;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.500;\n\
14a-285;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.500;\n\
14a-286;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.500;\n\
14a-287;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.500;\n\
14a-296;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.503;\n\
14b-297;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.503;\n\
14b-304;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.503;\n\
14b-316;DL_WFCaseTypeStep!485;Round approved;administrator;2014-04-16 19:25:05.503;\n\
14a-026;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.507;\n\
14a-027;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.557;\n\
14a-028;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.560;\n\
14a-029;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.560;\n\
14a-030;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.560;\n\
14a-032;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.560;\n\
14a-035;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.563;\n\
14a-036;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.563;\n\
14a-037;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.567;\n\
14a-039;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.567;\n\
14a-041;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.570;\n\
14a-042;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.570;\n\
14a-044;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.570;\n\
14a-045;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.570;\n\
14a-046;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.570;\n\
14a-048;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.573;\n\
14a-049;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.573;\n\
14a-051;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.573;\n\
14a-052;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.573;\n\
14a-053;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.577;\n\
14a-054;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.577;\n\
14a-055;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.577;\n\
14a-056;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.580;\n\
14a-057;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.580;\n\
14a-059;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.580;\n\
14a-060;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.580;\n\
14a-061;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.580;\n\
14a-063;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.583;\n\
14a-064;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.583;\n\
14a-065;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.583;\n\
14a-066;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.587;\n\
14a-067;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.587;\n\
14a-068;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.587;\n\
14a-069;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.587;\n\
14a-070;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.590;\n\
14a-071;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.590;\n\
14a-072;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.590;\n\
14a-073;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.593;\n\
14a-074;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.593;\n\
14a-075;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.597;\n\
14a-076;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.597;\n\
14a-077;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.597;\n\
14a-078;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.597;\n\
14a-079;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.600;\n\
14a-080;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.600;\n\
14a-081;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.600;\n\
14a-082;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.600;\n\
14a-083;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.600;\n\
14a-084;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.603;\n\
14a-088;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.603;\n\
14a-090;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.603;\n\
14a-092;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.603;\n\
14a-094;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.607;\n\
14a-095;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.607;\n\
14a-097;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.607;\n\
14a-098;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.610;\n\
14a-100;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.610;\n\
14a-101;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.617;\n\
14a-102;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.620;\n\
14a-104;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.620;\n\
14a-105;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.620;\n\
14a-106;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.620;\n\
14a-107;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.623;\n\
14a-108;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.623;\n\
14a-109;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.627;\n\
14a-110;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.627;\n\
14a-111;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.627;\n\
14a-112;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.630;\n\
14a-113;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.630;\n\
14a-114;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.630;\n\
14a-115;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.630;\n\
14a-116;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.633;\n\
14a-117;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.633;\n\
14a-118;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.647;\n\
14a-120;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.647;\n\
14a-121;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.650;\n\
14a-122;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.650;\n\
14a-123;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.650;\n\
14a-124;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.650;\n\
14a-126;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.650;\n\
14a-127;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.653;\n\
14a-128;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.653;\n\
14a-129;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.653;\n\
14a-130;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.653;\n\
14a-131;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.657;\n\
14a-132;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.657;\n\
14a-133;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.657;\n\
14a-134;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.660;\n\
14a-135;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.660;\n\
14a-136;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.660;\n\
14a-137;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.660;\n\
14a-138;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.660;\n\
14a-139;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.663;\n\
14a-140;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.663;\n\
14a-141;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.663;\n\
14a-142;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.663;\n\
14a-143;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.667;\n\
14a-145;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.667;\n\
14a-146;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.667;\n\
14a-147;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.667;\n\
14a-148;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.670;\n\
14a-149;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.670;\n\
14a-150;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.670;\n\
14a-151;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.670;\n\
14a-152;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.670;\n\
14a-153;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.673;\n\
14a-154;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.673;\n\
14a-155;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.673;\n\
14a-157;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.673;\n\
14a-158;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.677;\n\
14a-159;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.677;\n\
14a-160;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.677;\n\
14a-161;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.677;\n\
14a-162;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.680;\n\
14a-163;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.680;\n\
14a-164;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.680;\n\
14a-165;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.680;\n\
14a-166;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.680;\n\
14a-167;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.683;\n\
14a-168;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.683;\n\
14a-169;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.683;\n\
14a-170;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.687;\n\
14a-171;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.687;\n\
14a-172;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.687;\n\
14a-173;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.687;\n\
14a-174;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.690;\n\
14a-175;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.690;\n\
14a-176;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.690;\n\
14a-177;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.693;\n\
14a-178;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.693;\n\
14a-179;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.693;\n\
14a-180;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.693;\n\
14a-181;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.697;\n\
14a-182;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.697;\n\
14a-183;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.697;\n\
14a-184;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.697;\n\
14a-185;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.700;\n\
14a-186;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.700;\n\
14a-187;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.700;\n\
14a-188;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.700;\n\
14a-189;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.700;\n\
14a-190;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.703;\n\
14a-191;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.703;\n\
14a-192;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.703;\n\
14a-193;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.703;\n\
14a-194;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.707;\n\
14a-195;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.707;\n\
14a-196;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.707;\n\
14a-197;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.707;\n\
14a-198;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.710;\n\
14a-199;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.710;\n\
14a-200;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.710;\n\
14a-201;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.710;\n\
14a-202;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.713;\n\
14a-203;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.713;\n\
14a-204;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.713;\n\
14a-205;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.717;\n\
14a-206;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.717;\n\
14a-207;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.720;\n\
14a-208;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.720;\n\
14a-209;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.720;\n\
14a-210;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.720;\n\
14a-211;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.723;\n\
14a-212;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.723;\n\
14a-213;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.723;\n\
14a-214;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.727;\n\
14a-215;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.727;\n\
14a-216;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.727;\n\
14a-217;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.727;\n\
14a-218;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.730;\n\
14a-220;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.730;\n\
14a-221;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.730;\n\
14a-222;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.730;\n\
14a-223;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.730;\n\
14a-224;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.733;\n\
14a-225;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.733;\n\
14a-226;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.733;\n\
14a-227;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.733;\n\
14a-229;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.737;\n\
14a-230;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.737;\n\
14a-231;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.737;\n\
14a-232;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.740;\n\
14a-233;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.740;\n\
14a-234;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.740;\n\
14a-235;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.740;\n\
14a-236;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.740;\n\
14a-237;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.743;\n\
14a-238;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.743;\n\
14a-239;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.743;\n\
14a-240;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.743;\n\
14a-241;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.747;\n\
14a-242;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.747;\n\
14a-243;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.747;\n\
14a-244;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.747;\n\
14a-245;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.750;\n\
14a-246;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.750;\n\
14a-247;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.750;\n\
14a-250;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.750;\n\
14a-252;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.753;\n\
14a-253;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.753;\n\
14a-255;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.753;\n\
14a-256;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.753;\n\
14a-257;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.757;\n\
14a-258;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.757;\n\
14a-259;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.757;\n\
14a-260;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.757;\n\
14a-261;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.760;\n\
14a-262;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.760;\n\
14a-263;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.760;\n\
14a-265;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.760;\n\
14a-266;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.760;\n\
14a-267;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.763;\n\
14a-268;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.763;\n\
14a-269;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.763;\n\
14a-270;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.763;\n\
14a-271;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.767;\n\
14a-272;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.767;\n\
14a-273;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.767;\n\
14a-274;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.767;\n\
14a-275;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.770;\n\
14a-276;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.770;\n\
14a-277;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.770;\n\
14a-278;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.770;\n\
14a-280;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.773;\n\
14a-281;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.773;\n\
14a-284;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.773;\n\
14a-285;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.773;\n\
14a-286;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.777;\n\
14a-287;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.777;\n\
14a-296;DL_WFCaseTypeStep!477;Reject application;administrator;2014-04-16 19:25:05.777;\n\
14b-297;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.780;\n\
14b-304;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.780;\n\
14b-316;DL_WFCaseTypeStep!478;Approve application;administrator;2014-04-16 19:25:05.780;\n\
14a-028;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:15.373;\n\
14a-029;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:16.937;\n\
14a-030;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:18.560;\n\
14a-032;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:20.640;\n\
14a-036;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:24.800;\n\
14a-041;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:29.250;\n\
14a-044;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:31.910;\n\
14a-046;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:34.957;\n\
14a-048;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:36.520;\n\
14a-049;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:37.977;\n\
14a-051;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:39.407;\n\
14a-053;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:42.823;\n\
14a-054;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:44.460;\n\
14a-055;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:46.023;\n\
14a-057;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:49.160;\n\
14a-059;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:50.860;\n\
14a-061;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:53.650;\n\
14a-063;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:55.470;\n\
14a-064;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:56.913;\n\
14a-065;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:58.323;\n\
14a-066;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:28:59.767;\n\
14a-067;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:01.230;\n\
14a-068;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:02.690;\n\
14a-071;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:06.640;\n\
14a-073;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:09.270;\n\
14a-074;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:10.813;\n\
14a-076;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:13.570;\n\
14a-077;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:15.090;\n\
14a-079;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:18.143;\n\
14a-081;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:21.243;\n\
14a-082;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:22.760;\n\
14a-084;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:26.080;\n\
14a-100;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:37.080;\n\
14a-104;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:41.410;\n\
14a-107;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:45.670;\n\
14a-110;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:51.123;\n\
14a-112;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:53.800;\n\
14a-113;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:55.470;\n\
14a-115;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:58.120;\n\
14a-116;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:29:59.820;\n\
14a-117;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:01.507;\n\
14a-118;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:04.420;\n\
14a-131;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:19.097;\n\
14a-132;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:20.653;\n\
14a-133;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:22.337;\n\
14a-134;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:23.820;\n\
14a-135;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:25.320;\n\
14a-136;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:26.793;\n\
14a-139;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:31.190;\n\
14a-142;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:35.433;\n\
14a-143;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:36.850;\n\
14a-145;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:38.437;\n\
14a-146;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:39.953;\n\
14a-149;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:44.540;\n\
14a-150;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:46.680;\n\
14a-151;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:48.313;\n\
14a-154;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:53.140;\n\
14a-157;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:30:56.120;\n\
14a-162;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:02.480;\n\
14a-164;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:05.337;\n\
14a-168;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:10.320;\n\
14a-169;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:11.720;\n\
14a-170;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:13.127;\n\
14a-173;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:17.173;\n\
14a-177;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:22.280;\n\
14a-178;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:23.943;\n\
14a-181;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:28.073;\n\
14a-182;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:29.497;\n\
14a-183;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:30.980;\n\
14a-184;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:32.483;\n\
14a-187;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:36.650;\n\
14a-189;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:39.270;\n\
14a-190;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:40.703;\n\
14a-191;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:42.110;\n\
14a-193;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:44.723;\n\
14a-194;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:46.577;\n\
14a-196;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:49.403;\n\
14a-197;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:50.890;\n\
14a-198;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:52.307;\n\
14a-199;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:53.733;\n\
14a-200;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:55.127;\n\
14a-202;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:57.903;\n\
14a-203;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:31:59.333;\n\
14a-206;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:03.370;\n\
14a-207;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:04.803;\n\
14a-208;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:06.857;\n\
14a-210;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:09.697;\n\
14a-212;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:12.373;\n\
14a-213;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:13.783;\n\
14a-214;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:15.203;\n\
14a-215;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:16.307;\n\
14a-220;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:19.530;\n\
14a-221;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:20.370;\n\
14a-222;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:21.253;\n\
14a-223;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:22.063;\n\
14a-225;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:23.553;\n\
14a-227;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:25.050;\n\
14a-229;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:25.847;\n\
14a-231;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:27.340;\n\
14a-233;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:28.930;\n\
14a-235;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:30.420;\n\
14a-236;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:31.260;\n\
14a-237;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:32.047;\n\
14a-241;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:35.640;\n\
14a-242;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:37.057;\n\
14a-243;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:38.550;\n\
14a-245;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:41.270;\n\
14a-246;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:42.700;\n\
14a-247;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:44.123;\n\
14a-257;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:52.527;\n\
14a-258;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:53.930;\n\
14a-260;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:32:56.533;\n\
14a-265;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:33:01.840;\n\
14a-266;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:33:03.253;\n\
14a-267;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:33:04.790;\n\
14a-268;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:33:06.220;\n\
14a-270;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:33:08.897;\n\
14a-271;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:33:10.427;\n\
14a-272;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:33:11.843;\n\
14a-286;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:33:25.390;\n\
14b-297;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:33:29.080;\n\
14b-304;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:33:30.300;\n\
14b-316;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-04-16 19:33:31.417;\n\
14b-335;Initialize;NULL;Ansøger;2014-04-16 19:52:34.657;\n\
14b-335;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-16 19:52:34.657;\n\
14b-336;Initialize;NULL;Ansøger;2014-04-16 19:59:16.573;\n\
14b-336;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-16 19:59:16.577;\n\
14a-039;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-16 20:17:17.530;\n\
14a-039;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-16 20:17:21.840;\n\
14b-297;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-18 09:10:50.987;\n\
14a-255;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-18 16:56:08.253;\n\
14a-255;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-18 16:56:14.897;\n\
14b-337;Initialize;NULL;Ansøger;2014-04-18 17:21:13.617;\n\
14b-337;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-18 17:21:13.617;\n\
14b-330;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-20 04:42:57.967;\n\
14b-330;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-04-20 04:43:02.123;\n\
14b-331;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-20 05:10:09.333;\n\
14b-331;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-04-20 05:10:13.230;\n\
14b-332;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-20 05:18:50.537;\n\
14b-333;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-20 05:20:54.947;\n\
14b-334;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-20 05:22:48.870;\n\
14b-334;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-04-20 05:22:51.710;\n\
14b-337;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-20 05:25:40.770;\n\
14b-337;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-04-20 05:25:43.407;\n\
14b-335;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-20 05:27:57.010;\n\
14b-336;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-20 05:28:29.930;\n\
14b-338;Initialize;NULL;Ansøger;2014-04-21 21:18:48.577;\n\
14b-338;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-21 21:18:48.577;\n\
14b-338;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-22 10:31:51.490;\n\
14b-338;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-04-22 10:31:55.027;\n\
14b-339;Initialize;NULL;Ansøger;2014-04-23 15:04:30.097;\n\
14b-339;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-23 15:04:30.097;\n\
14b-340;Initialize;NULL;Ansøger;2014-04-23 20:44:54.247;\n\
14b-340;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-23 20:44:54.247;\n\
14b-341;Initialize;NULL;Ansøger;2014-04-24 12:43:47.437;\n\
14b-341;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-24 12:43:47.440;\n\
14b-336;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-25 10:39:21.350;\n\
14b-336;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-25 10:39:26.507;\n\
14b-341;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-25 10:44:04.260;\n\
14b-341;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-25 10:44:07.047;\n\
14b-340;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-25 10:45:51.537;\n\
14b-339;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-25 10:48:10.380;\n\
14b-324;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-25 11:17:21.880;\n\
14b-324;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-25 11:17:27.040;\n\
14b-329;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-25 11:18:00.670;\n\
14b-329;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-25 11:18:05.080;\n\
14b-332;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-25 11:18:22.240;\n\
14b-332;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-25 11:18:26.503;\n\
14b-335;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-25 11:19:42.500;\n\
14b-335;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-25 11:19:46.253;\n\
14b-339;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-25 11:23:44.863;\n\
14b-339;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-25 11:23:49.183;\n\
14b-340;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-25 11:24:42.350;\n\
14b-340;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-25 11:24:45.443;\n\
14b-333;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-25 11:24:58.750;\n\
14b-333;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-25 11:25:04.237;\n\
14b-306;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:28.043;\n\
14b-322;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:28.363;\n\
14b-327;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:28.607;\n\
14b-318;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:28.890;\n\
14b-298;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:29.193;\n\
14b-302;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:29.480;\n\
14b-303;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:29.760;\n\
14b-307;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:30.050;\n\
14b-309;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:30.333;\n\
14b-310;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:30.667;\n\
14b-311;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:30.950;\n\
14b-312;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:31.220;\n\
14b-313;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:31.517;\n\
14b-314;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:31.807;\n\
14b-315;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:32.090;\n\
14b-317;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:32.417;\n\
14b-319;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:32.703;\n\
14b-320;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:32.987;\n\
14b-321;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:33.277;\n\
14b-323;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:33.560;\n\
14b-326;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:33.830;\n\
14b-325;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:34.123;\n\
14b-305;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:34.440;\n\
14b-328;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:34.883;\n\
14b-330;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:35.170;\n\
14b-331;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:35.450;\n\
14b-334;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:35.760;\n\
14b-337;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:36.050;\n\
14b-338;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:36.333;\n\
14b-341;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-25 16:45:36.610;\n\
14b-342;Initialize;NULL;Ansøger;2014-04-25 18:00:34.010;\n\
14b-342;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-25 18:00:34.010;\n\
14b-343;Initialize;NULL;Ansøger;2014-04-26 18:28:28.023;\n\
14b-343;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-26 18:28:28.027;\n\
14b-344;Initialize;NULL;Ansøger;2014-04-28 07:19:02.893;\n\
14b-344;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-28 07:19:02.897;\n\
14a-045;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 11:49:57.250;\n\
14a-052;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 11:49:57.570;\n\
14a-075;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 11:49:57.823;\n\
14a-094;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 11:49:58.117;\n\
14a-111;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 11:49:58.420;\n\
14a-114;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 11:49:58.710;\n\
14a-121;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 11:49:59.017;\n\
14a-130;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 11:49:59.293;\n\
14a-179;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 11:49:59.620;\n\
14a-180;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 11:49:59.930;\n\
14a-285;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 11:50:00.240;\n\
14a-045;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 11:50:07.290;\n\
14a-052;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 11:50:08.183;\n\
14a-075;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 11:50:13.097;\n\
14a-094;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 11:50:18.907;\n\
14a-111;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 11:50:23.970;\n\
14a-114;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 11:50:29.313;\n\
14a-121;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 11:50:34.300;\n\
14a-130;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 11:50:37.573;\n\
14a-179;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 11:50:40.867;\n\
14a-180;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 11:50:44.450;\n\
14a-285;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 11:50:48.193;\n\
14a-070;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:23:47.277;\n\
14a-129;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:23:47.710;\n\
14a-147;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:23:48.000;\n\
14a-159;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:23:48.307;\n\
14a-166;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:23:48.640;\n\
14a-211;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:23:48.947;\n\
14a-026;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:23:49.317;\n\
14a-259;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:23:49.617;\n\
14a-269;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:23:49.917;\n\
14a-281;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:23:50.227;\n\
14a-287;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:23:50.627;\n\
14a-070;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 12:24:01.187;\n\
14a-129;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 12:24:09.670;\n\
14a-147;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 12:24:20.617;\n\
14a-159;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 12:24:28.133;\n\
14a-166;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 12:24:33.987;\n\
14a-211;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 12:24:40.227;\n\
14a-026;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 12:24:46.883;\n\
14a-259;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 12:24:52.447;\n\
14a-269;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 12:24:58.123;\n\
14a-281;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 12:25:04.850;\n\
14a-287;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 12:25:10.547;\n\
14a-153;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 12:33:41.107;\n\
14a-153;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 12:33:47.900;\n\
14a-072;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:11.330;\n\
14a-078;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:11.730;\n\
14a-080;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:12.067;\n\
14a-088;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:12.360;\n\
14a-090;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:12.690;\n\
14a-092;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:13.033;\n\
14a-095;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:13.363;\n\
14a-097;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:13.667;\n\
14a-098;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:13.973;\n\
14a-105;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:14.260;\n\
14a-106;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:14.550;\n\
14a-108;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:14.847;\n\
14a-127;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:15.183;\n\
14a-137;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:15.840;\n\
14a-138;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:16.157;\n\
14a-140;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:16.437;\n\
14a-141;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:16.780;\n\
14a-148;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:17.090;\n\
14a-155;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:17.410;\n\
14a-158;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:17.733;\n\
14a-160;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:18.533;\n\
14a-161;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:18.837;\n\
14a-163;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:19.200;\n\
14a-165;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:19.513;\n\
14a-171;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:19.807;\n\
14a-174;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:20.147;\n\
14a-176;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:20.440;\n\
14a-201;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:20.757;\n\
14a-204;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:21.040;\n\
14a-205;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:21.363;\n\
14a-209;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:21.673;\n\
14a-216;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:21.960;\n\
14a-217;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:22.250;\n\
14a-224;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:22.563;\n\
14a-226;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:22.847;\n\
14a-230;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:23.170;\n\
14a-232;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:23.450;\n\
14a-238;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:23.790;\n\
14a-239;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:24.137;\n\
14a-240;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:24.460;\n\
14a-262;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:24.800;\n\
14a-273;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:25.127;\n\
14a-280;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-28 14:13:25.560;\n\
14a-072;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 14:13:38.763;\n\
14a-078;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 14:13:42.360;\n\
14a-080;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 14:13:45.637;\n\
14a-088;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 14:13:49.063;\n\
14a-090;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 14:13:52.550;\n\
14a-092;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 14:13:56.017;\n\
14a-095;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-28 14:13:59.480;\n\
14a-098;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:05.607;\n\
14a-105;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:08.903;\n\
14a-106;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:12.673;\n\
14a-108;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:16.920;\n\
14a-127;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:21.460;\n\
14a-137;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:24.623;\n\
14a-138;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:27.983;\n\
14a-140;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:31.410;\n\
14a-141;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:34.640;\n\
14a-148;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:38.393;\n\
14a-155;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:41.877;\n\
14a-158;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:45.233;\n\
14a-160;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:48.530;\n\
14a-161;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:51.970;\n\
14a-163;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:55.707;\n\
14a-165;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:14:59.273;\n\
14a-171;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:04.150;\n\
14a-174;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:08.500;\n\
14a-176;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:12.113;\n\
14a-201;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:15.513;\n\
14a-204;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:18.790;\n\
14a-205;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:22.750;\n\
14a-209;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:26.080;\n\
14a-216;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:29.380;\n\
14a-217;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:33.157;\n\
14a-224;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:37.070;\n\
14a-226;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:40.433;\n\
14a-230;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:43.787;\n\
14a-232;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:47.080;\n\
14a-238;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:50.667;\n\
14a-239;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:54.583;\n\
14a-240;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:15:57.887;\n\
14a-262;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:16:01.247;\n\
14a-273;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:16:04.523;\n\
14a-280;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-28 14:16:07.930;\n\
14b-345;Initialize;NULL;Ansøger;2014-04-28 23:29:07.740;\n\
14b-345;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-28 23:29:07.740;\n\
14a-027;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:47.523;\n\
14a-035;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:47.843;\n\
14a-037;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:48.080;\n\
14a-042;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:48.350;\n\
14a-056;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:48.637;\n\
14a-060;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:48.913;\n\
14a-069;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:49.193;\n\
14a-083;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:49.477;\n\
14a-101;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:49.777;\n\
14a-102;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:50.213;\n\
14a-109;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:50.490;\n\
14a-120;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:50.810;\n\
14a-122;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:51.100;\n\
14a-123;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:51.373;\n\
14a-124;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:51.663;\n\
14a-126;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:51.970;\n\
14a-128;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:52.253;\n\
14a-152;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:52.537;\n\
14a-167;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:52.847;\n\
14a-172;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:53.127;\n\
14a-175;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:53.410;\n\
14a-185;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:53.697;\n\
14a-186;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:53.980;\n\
14a-188;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:54.277;\n\
14a-192;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:54.563;\n\
14a-195;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:54.847;\n\
14a-218;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:55.150;\n\
14a-234;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:55.597;\n\
14a-244;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:55.890;\n\
14a-250;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:56.163;\n\
14a-252;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:56.450;\n\
14a-253;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:56.740;\n\
14a-256;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:57.020;\n\
14a-261;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:57.320;\n\
14a-263;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:57.600;\n\
14a-274;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:57.870;\n\
14a-275;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:58.150;\n\
14a-276;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:58.420;\n\
14a-277;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:58.697;\n\
14a-278;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:58.970;\n\
14a-284;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:59.250;\n\
14a-296;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-29 09:59:59.530;\n\
14a-027;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:07.177;\n\
14a-035;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:10.810;\n\
14a-037;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:14.340;\n\
14a-042;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:18.023;\n\
14a-056;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:21.483;\n\
14a-060;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:24.743;\n\
14a-069;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:28.023;\n\
14a-083;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:31.540;\n\
14a-101;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:35.737;\n\
14a-102;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:39.047;\n\
14a-109;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:42.537;\n\
14a-120;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:46.313;\n\
14a-122;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:49.653;\n\
14a-123;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:53.053;\n\
14a-124;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:56.350;\n\
14a-126;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:00:59.817;\n\
14a-128;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:03.427;\n\
14a-152;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:06.733;\n\
14a-167;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:10.020;\n\
14a-172;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:13.280;\n\
14a-175;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:16.757;\n\
14a-185;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:20.037;\n\
14a-186;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:23.270;\n\
14a-188;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:26.530;\n\
14a-192;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:29.760;\n\
14a-195;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:33.030;\n\
14a-218;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:37.710;\n\
14a-234;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:41.280;\n\
14a-244;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:44.583;\n\
14a-250;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:47.810;\n\
14a-252;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:51.460;\n\
14a-253;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:54.753;\n\
14a-256;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:01:58.053;\n\
14a-261;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:02:01.340;\n\
14a-263;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:02:04.770;\n\
14a-274;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:02:08.433;\n\
14a-275;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:02:11.690;\n\
14a-276;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:02:15.010;\n\
14a-277;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:02:18.400;\n\
14a-278;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:02:21.777;\n\
14a-284;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:02:25.227;\n\
14a-296;DL_WFCaseTypeStep!538;Change phase to Abort;suser;2014-04-29 10:02:28.907;\n\
14a-213;DL_WFCaseTypeStep!541;Account number changed;ls;2014-04-29 11:21:36.753;\n\
14b-342;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-29 13:00:46.030;\n\
14b-342;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-29 13:00:49.773;\n\
14b-345;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-29 13:03:28.757;\n\
14b-344;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-29 13:04:04.510;\n\
14b-344;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-29 13:04:06.520;\n\
14b-343;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-29 13:04:39.040;\n\
14b-343;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-04-29 13:04:41.370;\n\
14b-346;Initialize;NULL;Ansøger;2014-04-29 18:07:50.940;\n\
14b-346;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-29 18:07:50.940;\n\
14b-347;Initialize;NULL;Ansøger;2014-04-29 20:22:35.693;\n\
14b-347;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-04-29 20:22:35.697;\n\
14b-345;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-30 08:54:59.660;\n\
14b-345;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-04-30 08:55:06.213;\n\
14b-342;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-30 09:51:14.637;\n\
14b-344;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-30 09:51:15.000;\n\
14b-343;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-04-30 09:51:15.303;\n\
14b-347;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-04-30 12:09:17.943;\n\
14b-347;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-04-30 12:09:21.770;\n\
14b-346;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-04-30 12:10:26.030;\n\
14b-346;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-04-30 13:31:21.810;\n\
14b-346;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-04-30 13:31:27.360;\n\
14b-347;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-05-02 13:38:53.487;\n\
14a-213;DL_WFCaseTypeStep!484;Approve changed account number;Administrator;2014-05-03 13:09:22.277;\n\
14a-213;DL_WFCaseTypeStep!541;Account number changed;administrator;2014-05-03 13:13:49.387;\n\
14a-213;DL_WFCaseTypeStep!541;Account number changed;administrator;2014-05-03 13:13:55.970;\n\
14b-348;Initialize;NULL;Ansøger;2014-05-03 16:58:27.600;\n\
14b-348;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-03 16:58:27.600;\n\
14b-348;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-05-05 09:16:28.630;\n\
14b-349;Initialize;NULL;Ansøger;2014-05-05 10:50:27.963;\n\
14b-349;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-05 10:50:27.967;\n\
14b-350;Initialize;NULL;Ansøger;2014-05-05 20:51:46.120;\n\
14b-350;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-05 20:51:46.120;\n\
14b-351;Initialize;NULL;Ansøger;2014-05-05 22:54:47.853;\n\
14b-351;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-05 22:54:47.853;\n\
14b-352;Initialize;NULL;Ansøger;2014-05-05 23:26:47.827;\n\
14b-352;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-05 23:26:47.827;\n\
14b-353;Initialize;NULL;Ansøger;2014-05-05 23:50:52.897;\n\
14b-353;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-05 23:50:52.897;\n\
14b-354;Initialize;NULL;Ansøger;2014-05-07 16:48:24.210;\n\
14b-354;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-07 16:48:24.210;\n\
14b-355;Initialize;NULL;Ansøger;2014-05-07 18:47:54.700;\n\
14b-355;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-07 18:47:54.703;\n\
14b-356;Initialize;NULL;Ansøger;2014-05-08 16:40:51.470;\n\
14b-356;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-08 16:40:51.470;\n\
14b-357;Initialize;NULL;Ansøger;2014-05-09 11:13:54.197;\n\
14b-357;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-09 11:13:54.197;\n\
14a-031;DL_WFCaseTypeStep!546;Applicant proves relevance of application;Administrator;2014-05-09 16:18:50.187;\n\
14a-031;DL_WFCaseTypeStep!546;Applicant proves relevance of application;Administrator;2014-05-09 16:18:55.443;\n\
14a-031;DL_WFCaseTypeStep!546;Applicant proves relevance of application;Administrator;2014-05-09 16:19:02.117;\n\
14a-031;DL_WFCaseTypeStep!546;Applicant proves relevance of application;Administrator;2014-05-09 16:19:07.930;\n\
14a-031;RestartWorkflow;NULL;Administrator;2014-05-09 16:19:26.840;\n\
14a-031;Initialize;NULL;administrator;2014-05-09 16:19:27.107;\n\
14a-031;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-09 16:19:31.583;\n\
14a-031;DL_WFCaseTypeStep!470;Initial rejection;Administrator;2014-05-09 16:19:41.187;\n\
14a-031;DL_WFCaseTypeStep!486;Applicant informed ;Administrator;2014-05-09 16:19:46.290;\n\
14a-031;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2014-05-09 16:19:49.710;\n\
14a-097;DL_WFCaseTypeStep!477;Reject application;administrator;2014-05-09 16:22:42.577;\n\
14a-097;DL_WFCaseTypeStep!478;Approve application;administrator;2014-05-09 16:22:49.933;\n\
14a-097;DL_WFCaseTypeStep!478;Approve application;administrator;2014-05-09 16:23:29.540;\n\
14a-097;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2014-05-09 16:29:01.900;\n\
14b-358;Initialize;NULL;Ansøger;2014-05-12 10:04:32.643;\n\
14b-358;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-12 10:04:32.647;\n\
14b-359;Initialize;NULL;Ansøger;2014-05-12 15:52:48.090;\n\
14b-359;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-12 15:52:48.090;\n\
14b-360;Initialize;NULL;Ansøger;2014-05-12 23:01:15.067;\n\
14b-360;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-12 23:01:15.067;\n\
14b-361;Initialize;NULL;Ansøger;2014-05-13 23:33:43.673;\n\
14b-361;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-13 23:33:43.673;\n\
14b-348;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-05-14 08:40:53.210;\n\
14b-348;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-05-14 08:40:58.493;\n\
14b-362;Initialize;NULL;Ansøger;2014-05-18 17:23:17.890;\n\
14b-362;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-18 17:23:17.893;\n\
14b-363;Initialize;NULL;Ansøger;2014-05-19 16:28:53.000;\n\
14b-363;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-19 16:28:53.000;\n\
14a-223;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 09:37:35.947;\n\
14a-223;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 09:37:40.950;\n\
14a-225;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 09:46:29.857;\n\
14a-225;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 09:46:33.717;\n\
14a-227;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 09:57:35.120;\n\
14a-227;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 09:57:38.457;\n\
14a-229;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 10:08:11.060;\n\
14a-229;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 10:08:14.793;\n\
14a-231;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 10:13:43.690;\n\
14a-231;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 10:13:46.683;\n\
14a-235;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 12:57:33.383;\n\
14a-235;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 12:57:38.200;\n\
14a-241;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 13:09:33.590;\n\
14a-241;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 13:09:37.107;\n\
14a-243;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 13:50:43.220;\n\
14a-243;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 13:50:47.567;\n\
14a-246;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 14:05:50.093;\n\
14a-246;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 14:05:53.933;\n\
14a-270;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 14:29:21.887;\n\
14a-270;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 14:29:25.477;\n\
14a-272;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 14:34:09.543;\n\
14a-272;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 14:34:12.980;\n\
14b-364;Initialize;NULL;Ansøger;2014-05-20 18:35:40.870;\n\
14b-364;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-20 18:35:40.870;\n\
14a-036;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:12:13.007;\n\
14a-036;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:12:17.783;\n\
14a-041;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:15:20.800;\n\
14a-041;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:15:24.423;\n\
14a-051;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:16:29.440;\n\
14a-051;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:16:32.060;\n\
14a-065;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:17:33.290;\n\
14a-065;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:17:35.637;\n\
14a-071;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:18:16.140;\n\
14a-071;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:18:18.417;\n\
14a-073;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:19:48.237;\n\
14a-073;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:19:50.440;\n\
14a-076;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:21:06.623;\n\
14a-076;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:21:08.850;\n\
14a-081;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:23:55.967;\n\
14a-081;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:23:59.113;\n\
14a-113;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:25:17.647;\n\
14a-113;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:25:19.803;\n\
14a-117;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:26:01.153;\n\
14a-117;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:26:04.110;\n\
14a-118;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:26:48.563;\n\
14a-118;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:26:51.513;\n\
14a-132;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:27:35.190;\n\
14a-132;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:27:37.343;\n\
14a-133;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:28:38.490;\n\
14a-133;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:28:40.727;\n\
14a-134;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:29:27.737;\n\
14a-134;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:29:29.857;\n\
14a-142;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:33:15.423;\n\
14a-142;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:33:19.037;\n\
14a-145;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:33:35.647;\n\
14a-145;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:33:38.130;\n\
14a-139;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:34:07.920;\n\
14a-139;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:34:10.073;\n\
14a-135;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:34:23.740;\n\
14a-135;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:34:26.723;\n\
14a-149;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:35:49.163;\n\
14a-149;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:35:51.347;\n\
14a-181;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:36:09.143;\n\
14a-181;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:36:11.450;\n\
14a-182;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:36:30.590;\n\
14a-182;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:36:33.490;\n\
14a-183;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:37:06.967;\n\
14a-183;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:37:09.193;\n\
14a-184;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:37:27.450;\n\
14a-184;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:37:30.300;\n\
14a-187;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:37:47.613;\n\
14a-187;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:37:50.503;\n\
14a-189;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:38:17.807;\n\
14a-189;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:38:19.877;\n\
14a-190;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:38:37.463;\n\
14a-190;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:38:40.313;\n\
14a-193;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:39:09.750;\n\
14a-193;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:39:11.947;\n\
14a-194;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:39:31.860;\n\
14a-194;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:39:34.830;\n\
14a-196;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:39:51.583;\n\
14a-196;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:39:54.563;\n\
14a-200;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:40:11.053;\n\
14a-200;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:40:13.367;\n\
14a-202;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:40:27.337;\n\
14a-202;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:40:30.193;\n\
14a-207;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:40:50.103;\n\
14a-207;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:40:52.967;\n\
14a-210;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:41:08.517;\n\
14a-210;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:41:10.690;\n\
14a-214;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:41:28.833;\n\
14a-214;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:41:31.577;\n\
14a-215;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-20 20:41:50.990;\n\
14a-215;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-20 20:41:53.833;\n\
14a-029;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:09:05.027;\n\
14a-029;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:09:09.890;\n\
14a-032;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:15:01.630;\n\
14a-032;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:15:05.440;\n\
14a-046;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:15:56.473;\n\
14a-046;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:15:59.543;\n\
14a-048;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:18:13.080;\n\
14a-048;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:18:16.533;\n\
14a-054;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:19:04.470;\n\
14a-054;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:19:06.633;\n\
14a-064;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:19:55.693;\n\
14a-064;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:19:58.570;\n\
14a-084;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:20:40.823;\n\
14a-084;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:20:43.627;\n\
14a-110;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:22:58.867;\n\
14a-110;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:23:02.410;\n\
14a-136;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:24:16.460;\n\
14a-136;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:24:18.560;\n\
14a-143;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:24:45.700;\n\
14a-143;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:24:48.597;\n\
14a-146;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:25:16.920;\n\
14a-146;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:25:19.080;\n\
14a-150;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:26:02.093;\n\
14a-150;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:26:04.233;\n\
14a-164;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:26:43.600;\n\
14a-164;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:26:46.690;\n\
14a-168;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:39:37.910;\n\
14a-168;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:39:41.447;\n\
14a-169;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:41:49.310;\n\
14a-169;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:41:52.750;\n\
14a-173;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:42:53.467;\n\
14a-173;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:42:55.607;\n\
14a-191;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:44:03.177;\n\
14a-191;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:44:04.763;\n\
14a-220;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 08:58:43.123;\n\
14a-220;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 08:58:46.990;\n\
14a-221;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:00:17.500;\n\
14a-221;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:00:19.753;\n\
14a-222;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:03:20.973;\n\
14a-222;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:03:24.590;\n\
14a-236;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:05:50.857;\n\
14a-236;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:05:54.583;\n\
14a-237;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:07:11.540;\n\
14a-237;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:07:13.900;\n\
14a-242;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:10:02.023;\n\
14a-242;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:10:05.887;\n\
14a-257;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:11:30.887;\n\
14a-257;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:11:33.063;\n\
14a-260;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:12:58.533;\n\
14a-260;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:13:01.260;\n\
14a-267;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:14:21.490;\n\
14a-267;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:14:23.647;\n\
14a-044;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:16:55.240;\n\
14a-044;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:16:58.923;\n\
14a-049;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:18:05.320;\n\
14a-049;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:18:07.530;\n\
14a-053;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:20:59.147;\n\
14a-053;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:21:02.960;\n\
14a-055;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:21:56.877;\n\
14a-055;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:22:00.183;\n\
14a-100;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:23:08.420;\n\
14a-100;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:23:10.670;\n\
14a-104;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:24:18.957;\n\
14a-104;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:24:21.053;\n\
14a-107;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:26:21.750;\n\
14a-107;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:26:24.077;\n\
14a-116;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:27:34.897;\n\
14a-116;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:27:37.037;\n\
14a-131;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:29:19.217;\n\
14a-131;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:29:21.333;\n\
14a-162;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:30:33.040;\n\
14a-162;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:30:35.400;\n\
14a-203;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:33:02.620;\n\
14a-203;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:33:06.323;\n\
14a-212;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:34:39.580;\n\
14a-212;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:34:41.917;\n\
14a-213;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:35:56.737;\n\
14a-213;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:35:58.833;\n\
14a-233;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:36:57.793;\n\
14a-233;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:37:00.033;\n\
14a-258;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:38:16.640;\n\
14a-258;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:38:18.947;\n\
14a-265;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:39:15.927;\n\
14a-265;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:39:18.070;\n\
14a-266;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:40:16.820;\n\
14a-266;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:40:18.967;\n\
14a-268;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:41:09.147;\n\
14a-268;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:41:11.263;\n\
14a-271;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 09:42:00.210;\n\
14a-271;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 09:42:04.030;\n\
14a-028;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 11:15:41.840;\n\
14a-028;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 11:15:46.330;\n\
14a-030;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 11:18:57.120;\n\
14a-030;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 11:19:00.890;\n\
14a-057;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 11:32:28.030;\n\
14a-057;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 11:32:31.517;\n\
14a-063;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 11:41:31.030;\n\
14a-063;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 11:41:34.533;\n\
14a-082;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 11:45:02.480;\n\
14a-082;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 11:45:06.407;\n\
14a-115;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 11:50:15.077;\n\
14a-115;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 11:50:18.680;\n\
14a-151;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 12:21:26.950;\n\
14a-151;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 12:21:30.530;\n\
14a-154;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 12:31:02.437;\n\
14a-154;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 12:31:05.930;\n\
14a-197;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 13:08:16.380;\n\
14a-197;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 13:08:21.047;\n\
14a-245;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 15:24:39.253;\n\
14a-245;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 15:24:44.163;\n\
14a-208;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-21 15:36:46.267;\n\
14a-208;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-21 15:36:49.957;\n\
14a-178;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-22 09:20:39.920;\n\
14a-178;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-22 09:20:44.363;\n\
14a-247;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-22 10:30:25.710;\n\
14a-247;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-22 10:30:30.430;\n\
14a-059;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-22 10:50:52.727;\n\
14a-059;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-22 10:50:56.310;\n\
14a-077;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-22 11:13:52.543;\n\
14a-077;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-22 11:13:56.497;\n\
14a-079;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-22 11:38:47.060;\n\
14a-079;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-22 11:38:51.720;\n\
14a-206;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-22 12:03:13.713;\n\
14a-206;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-22 12:03:18.087;\n\
14a-066;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-22 12:50:12.830;\n\
14a-066;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-22 12:50:17.383;\n\
14a-067;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-22 12:51:56.537;\n\
14a-067;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-22 12:51:58.510;\n\
14a-170;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-22 13:03:19.620;\n\
14a-170;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-22 13:03:23.160;\n\
14a-068;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-22 13:07:04.350;\n\
14a-068;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-22 13:07:07.933;\n\
14a-199;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-22 13:44:40.640;\n\
14a-199;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-22 13:44:45.230;\n\
14b-365;Initialize;NULL;Ansøger;2014-05-22 15:02:23.353;\n\
14b-365;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-22 15:02:23.353;\n\
14b-366;Initialize;NULL;Ansøger;2014-05-22 18:32:26.870;\n\
14b-366;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-22 18:32:26.870;\n\
14b-367;Initialize;NULL;Ansøger;2014-05-23 07:59:47.377;\n\
14b-367;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-23 07:59:47.377;\n\
14a-198;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-23 08:12:57.070;\n\
14a-198;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-23 08:13:00.560;\n\
14a-177;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-23 08:14:21.120;\n\
14a-177;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-23 08:14:23.337;\n\
14a-112;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-23 08:16:08.613;\n\
14a-112;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-23 08:16:10.807;\n\
14a-074;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-23 08:17:47.610;\n\
14a-074;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-23 08:17:49.800;\n\
14a-061;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-23 08:22:44.377;\n\
14a-061;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-23 08:22:48.033;\n\
14b-368;Initialize;NULL;Ansøger;2014-05-23 12:00:13.573;\n\
14b-368;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-23 12:00:13.573;\n\
14b-349;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-05-23 14:52:23.157;\n\
14b-349;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-05-23 14:52:28.400;\n\
14b-369;Initialize;NULL;Ansøger;2014-05-24 00:10:28.500;\n\
14b-369;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-24 00:10:28.500;\n\
14b-370;Initialize;NULL;Ansøger;2014-05-24 13:10:15.387;\n\
14b-370;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-24 13:10:15.387;\n\
14b-371;Initialize;NULL;Ansøger;2014-05-26 22:10:35.687;\n\
14b-371;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-26 22:10:35.687;\n\
14b-372;Initialize;NULL;Ansøger;2014-05-27 00:18:48.370;\n\
14b-372;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-27 00:18:48.370;\n\
14b-351;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-05-27 10:17:06.610;\n\
14b-357;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-05-27 10:18:42.957;\n\
14b-363;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-05-27 10:19:37.710;\n\
14b-365;DL_WFCaseTypeStep!470;Initial rejection;ls;2014-05-27 10:20:28.853;\n\
14a-157;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-27 12:00:21.410;\n\
14a-157;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2014-05-27 12:00:25.993;\n\
14b-375;Initialize;NULL;Ansøger;2014-05-27 22:03:36.737;\n\
14b-375;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-27 22:03:36.740;\n\
14b-376;Initialize;NULL;Ansøger;2014-05-28 13:20:55.647;\n\
14b-376;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-28 13:20:55.670;\n\
14b-377;Initialize;NULL;Ansøger;2014-05-28 13:25:10.790;\n\
14b-377;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-28 13:25:10.793;\n\
14b-377;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-05-28 14:14:56.277;\n\
14b-377;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-05-28 14:15:00.157;\n\
14b-377;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-05-28 14:15:41.023;\n\
14b-377;DL_WFCaseTypeStep!475;Register Decision;ls;2014-05-28 14:19:53.323;\n\
14b-377;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-05-28 14:20:03.567;\n\
14b-377;DL_WFCaseTypeStep!544;Pre-process application;ls;2014-05-28 14:20:14.340;\n\
14b-377;DL_WFCaseTypeStep!545;Execute pre-decision;ls;2014-05-28 14:20:16.243;\n\
14b-377;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-05-28 14:20:16.243;\n\
14b-377;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-05-28 14:21:02.540;\n\
14b-377;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-05-28 14:21:02.543;\n\
14b-377;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-05-28 14:21:22.263;\n\
14b-377;DL_WFCaseTypeStep!546;Applicant proves relevance of application;ls;2014-05-28 14:24:37.833;\n\
14b-377;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-05-28 14:24:40.740;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;ls;2014-05-28 14:33:37.817;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;ls;2014-05-28 14:49:42.690;\n\
14b-377;AddEvent;NULL;ls;2014-05-28 14:51:07.883;\n\
14b-377;AddNesting;NULL;ls;2014-05-28 14:51:07.887;\n\
14b-377;AddEvent;NULL;ls;2014-05-28 14:51:07.977;\n\
14b-377;AddNesting;NULL;ls;2014-05-28 14:51:07.980;\n\
14b-377;AddRelation;NULL;ls;2014-05-28 14:51:07.983;\n\
14b-377;AddRelation;NULL;ls;2014-05-28 14:51:07.983;\n\
14b-377;AddRelation;NULL;ls;2014-05-28 14:51:07.983;\n\
14b-377;AddRelation;NULL;ls;2014-05-28 14:51:07.983;\n\
14b-377;AddEvent;NULL;ls;2014-05-28 14:51:07.983;\n\
14b-377;AddRelation;NULL;ls;2014-05-28 14:51:07.983;\n\
14b-378;Initialize;NULL;Ansøger;2014-05-28 21:52:40.367;\n\
14b-378;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-28 21:52:40.367;\n\
14b-379;Initialize;NULL;Ansøger;2014-05-29 11:50:49.223;\n\
14b-379;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-29 11:50:49.223;\n\
14b-380;Initialize;NULL;Ansøger;2014-05-29 13:24:36.660;\n\
14b-380;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-29 13:24:36.660;\n\
14b-381;Initialize;NULL;Ansøger;2014-05-29 14:01:51.770;\n\
14b-381;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-29 14:01:51.770;\n\
14b-383;Initialize;NULL;Ansøger;2014-05-29 15:25:28.187;\n\
14b-383;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-29 15:25:28.190;\n\
14a-271;DL_WFCaseTypeStep!541;Account number changed;ls;2014-05-30 10:32:51.123;\n\
14b-386;Initialize;NULL;Ansøger;2014-05-31 19:47:14.027;\n\
14b-386;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-31 19:47:14.027;\n\
14b-387;Initialize;NULL;Ansøger;2014-05-31 22:11:31.167;\n\
14b-387;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-05-31 22:11:31.170;\n\
14b-388;Initialize;NULL;Ansøger;2014-06-01 10:37:03.450;\n\
14b-388;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-01 10:37:03.460;\n\
14a-073;DL_WFCaseTypeStep!541;Account number changed;ls;2014-06-02 12:56:41.910;\n\
14b-389;Initialize;NULL;Ansøger;2014-06-02 13:36:31.860;\n\
14b-389;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-02 13:36:31.860;\n\
14a-029;AddEvent;NULL;ls;2014-06-02 13:54:03.913;\n\
14a-029;AddNesting;NULL;ls;2014-06-02 13:54:03.917;\n\
14a-029;DL_DCREventTaskPersonal!25268;NULL;ls;2014-06-02 13:54:40.183;\n\
14a-029;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2014-06-02 13:54:46.830;\n\
14a-029;AddEvent;NULL;mmq;2014-06-02 14:03:46.280;\n\
14a-029;AddNesting;NULL;mmq;2014-06-02 14:03:46.280;\n\
14a-028;AddEvent;NULL;mmq;2014-06-02 14:23:33.330;\n\
14a-028;AddNesting;NULL;mmq;2014-06-02 14:23:33.330;\n\
14a-030;AddEvent;NULL;mmq;2014-06-02 14:23:33.343;\n\
14a-030;AddNesting;NULL;mmq;2014-06-02 14:23:33.343;\n\
14a-032;AddEvent;NULL;mmq;2014-06-02 14:23:33.360;\n\
14a-032;AddNesting;NULL;mmq;2014-06-02 14:23:33.360;\n\
14a-036;AddEvent;NULL;mmq;2014-06-02 14:23:33.363;\n\
14a-036;AddNesting;NULL;mmq;2014-06-02 14:23:33.367;\n\
14a-040;AddEvent;NULL;mmq;2014-06-02 14:23:33.373;\n\
14a-040;AddNesting;NULL;mmq;2014-06-02 14:23:33.373;\n\
14a-041;AddEvent;NULL;mmq;2014-06-02 14:23:33.467;\n\
14a-041;AddNesting;NULL;mmq;2014-06-02 14:23:33.467;\n\
14a-044;AddEvent;NULL;mmq;2014-06-02 14:23:33.467;\n\
14a-044;AddNesting;NULL;mmq;2014-06-02 14:23:33.467;\n\
14a-046;AddEvent;NULL;mmq;2014-06-02 14:23:33.470;\n\
14a-046;AddNesting;NULL;mmq;2014-06-02 14:23:33.470;\n\
14a-048;AddEvent;NULL;mmq;2014-06-02 14:23:33.470;\n\
14a-048;AddNesting;NULL;mmq;2014-06-02 14:23:33.470;\n\
14a-049;AddEvent;NULL;mmq;2014-06-02 14:23:33.473;\n\
14a-049;AddNesting;NULL;mmq;2014-06-02 14:23:33.473;\n\
14a-051;AddEvent;NULL;mmq;2014-06-02 14:23:33.477;\n\
14a-051;AddNesting;NULL;mmq;2014-06-02 14:23:33.477;\n\
14a-053;AddEvent;NULL;mmq;2014-06-02 14:23:33.480;\n\
14a-053;AddNesting;NULL;mmq;2014-06-02 14:23:33.480;\n\
14a-054;AddEvent;NULL;mmq;2014-06-02 14:23:33.480;\n\
14a-054;AddNesting;NULL;mmq;2014-06-02 14:23:33.480;\n\
14a-055;AddEvent;NULL;mmq;2014-06-02 14:23:33.480;\n\
14a-055;AddNesting;NULL;mmq;2014-06-02 14:23:33.483;\n\
14a-057;AddEvent;NULL;mmq;2014-06-02 14:23:33.487;\n\
14a-057;AddNesting;NULL;mmq;2014-06-02 14:23:33.487;\n\
14a-059;AddEvent;NULL;mmq;2014-06-02 14:23:33.487;\n\
14a-059;AddNesting;NULL;mmq;2014-06-02 14:23:33.487;\n\
14a-061;AddEvent;NULL;mmq;2014-06-02 14:23:33.490;\n\
14a-061;AddNesting;NULL;mmq;2014-06-02 14:23:33.490;\n\
14a-063;AddEvent;NULL;mmq;2014-06-02 14:23:33.490;\n\
14a-063;AddNesting;NULL;mmq;2014-06-02 14:23:33.490;\n\
14a-064;AddEvent;NULL;mmq;2014-06-02 14:23:33.493;\n\
14a-064;AddNesting;NULL;mmq;2014-06-02 14:23:33.493;\n\
14a-065;AddEvent;NULL;mmq;2014-06-02 14:23:33.493;\n\
14a-065;AddNesting;NULL;mmq;2014-06-02 14:23:33.497;\n\
14a-066;AddEvent;NULL;mmq;2014-06-02 14:23:33.497;\n\
14a-066;AddNesting;NULL;mmq;2014-06-02 14:23:33.497;\n\
14a-067;AddEvent;NULL;mmq;2014-06-02 14:23:33.500;\n\
14a-067;AddNesting;NULL;mmq;2014-06-02 14:23:33.500;\n\
14a-068;AddEvent;NULL;mmq;2014-06-02 14:23:33.500;\n\
14a-068;AddNesting;NULL;mmq;2014-06-02 14:23:33.500;\n\
14a-071;AddEvent;NULL;mmq;2014-06-02 14:23:33.503;\n\
14a-071;AddNesting;NULL;mmq;2014-06-02 14:23:33.503;\n\
14a-073;AddEvent;NULL;mmq;2014-06-02 14:23:33.503;\n\
14a-073;AddNesting;NULL;mmq;2014-06-02 14:23:33.503;\n\
14a-074;AddEvent;NULL;mmq;2014-06-02 14:23:33.507;\n\
14a-074;AddNesting;NULL;mmq;2014-06-02 14:23:33.507;\n\
14a-076;AddEvent;NULL;mmq;2014-06-02 14:23:33.510;\n\
14a-076;AddNesting;NULL;mmq;2014-06-02 14:23:33.510;\n\
14a-077;AddEvent;NULL;mmq;2014-06-02 14:23:33.510;\n\
14a-077;AddNesting;NULL;mmq;2014-06-02 14:23:33.510;\n\
14a-079;AddEvent;NULL;mmq;2014-06-02 14:23:33.510;\n\
14a-079;AddNesting;NULL;mmq;2014-06-02 14:23:33.510;\n\
14a-081;AddEvent;NULL;mmq;2014-06-02 14:23:33.513;\n\
14a-081;AddNesting;NULL;mmq;2014-06-02 14:23:33.513;\n\
14a-082;AddEvent;NULL;mmq;2014-06-02 14:23:33.517;\n\
14a-082;AddNesting;NULL;mmq;2014-06-02 14:23:33.517;\n\
14a-084;AddEvent;NULL;mmq;2014-06-02 14:23:33.517;\n\
14a-084;AddNesting;NULL;mmq;2014-06-02 14:23:33.517;\n\
14a-100;AddEvent;NULL;mmq;2014-06-02 14:23:33.520;\n\
14a-100;AddNesting;NULL;mmq;2014-06-02 14:23:33.520;\n\
14a-104;AddEvent;NULL;mmq;2014-06-02 14:23:33.520;\n\
14a-104;AddNesting;NULL;mmq;2014-06-02 14:23:33.520;\n\
14a-107;AddEvent;NULL;mmq;2014-06-02 14:23:33.523;\n\
14a-107;AddNesting;NULL;mmq;2014-06-02 14:23:33.523;\n\
14a-110;AddEvent;NULL;mmq;2014-06-02 14:23:33.527;\n\
14a-110;AddNesting;NULL;mmq;2014-06-02 14:23:33.527;\n\
14a-112;AddEvent;NULL;mmq;2014-06-02 14:23:33.527;\n\
14a-112;AddNesting;NULL;mmq;2014-06-02 14:23:33.530;\n\
14a-113;AddEvent;NULL;mmq;2014-06-02 14:23:33.530;\n\
14a-113;AddNesting;NULL;mmq;2014-06-02 14:23:33.530;\n\
14a-115;AddEvent;NULL;mmq;2014-06-02 14:23:33.533;\n\
14a-115;AddNesting;NULL;mmq;2014-06-02 14:23:33.533;\n\
14a-116;AddEvent;NULL;mmq;2014-06-02 14:23:33.533;\n\
14a-116;AddNesting;NULL;mmq;2014-06-02 14:23:33.533;\n\
14a-117;AddEvent;NULL;mmq;2014-06-02 14:23:33.537;\n\
14a-117;AddNesting;NULL;mmq;2014-06-02 14:23:33.537;\n\
14a-118;AddEvent;NULL;mmq;2014-06-02 14:23:33.540;\n\
14a-118;AddNesting;NULL;mmq;2014-06-02 14:23:33.540;\n\
14a-131;AddEvent;NULL;mmq;2014-06-02 14:23:33.540;\n\
14a-131;AddNesting;NULL;mmq;2014-06-02 14:23:33.540;\n\
14a-132;AddEvent;NULL;mmq;2014-06-02 14:23:33.543;\n\
14a-132;AddNesting;NULL;mmq;2014-06-02 14:23:33.543;\n\
14a-133;AddEvent;NULL;mmq;2014-06-02 14:23:33.543;\n\
14a-133;AddNesting;NULL;mmq;2014-06-02 14:23:33.550;\n\
14a-134;AddEvent;NULL;mmq;2014-06-02 14:23:33.553;\n\
14a-134;AddNesting;NULL;mmq;2014-06-02 14:23:33.553;\n\
14a-135;AddEvent;NULL;mmq;2014-06-02 14:23:33.557;\n\
14a-135;AddNesting;NULL;mmq;2014-06-02 14:23:33.557;\n\
14a-136;AddEvent;NULL;mmq;2014-06-02 14:23:33.557;\n\
14a-136;AddNesting;NULL;mmq;2014-06-02 14:23:33.557;\n\
14a-139;AddEvent;NULL;mmq;2014-06-02 14:23:33.560;\n\
14a-139;AddNesting;NULL;mmq;2014-06-02 14:23:33.560;\n\
14a-142;AddEvent;NULL;mmq;2014-06-02 14:23:33.570;\n\
14a-142;AddNesting;NULL;mmq;2014-06-02 14:23:33.570;\n\
14a-143;AddEvent;NULL;mmq;2014-06-02 14:23:33.570;\n\
14a-143;AddNesting;NULL;mmq;2014-06-02 14:23:33.570;\n\
14a-145;AddEvent;NULL;mmq;2014-06-02 14:23:33.573;\n\
14a-145;AddNesting;NULL;mmq;2014-06-02 14:23:33.573;\n\
14a-146;AddEvent;NULL;mmq;2014-06-02 14:23:33.573;\n\
14a-146;AddNesting;NULL;mmq;2014-06-02 14:23:33.577;\n\
14a-149;AddEvent;NULL;mmq;2014-06-02 14:23:33.577;\n\
14a-149;AddNesting;NULL;mmq;2014-06-02 14:23:33.577;\n\
14a-150;AddEvent;NULL;mmq;2014-06-02 14:23:33.580;\n\
14a-150;AddNesting;NULL;mmq;2014-06-02 14:23:33.580;\n\
14a-151;AddEvent;NULL;mmq;2014-06-02 14:23:33.580;\n\
14a-151;AddNesting;NULL;mmq;2014-06-02 14:23:33.580;\n\
14a-154;AddEvent;NULL;mmq;2014-06-02 14:23:33.583;\n\
14a-154;AddNesting;NULL;mmq;2014-06-02 14:23:33.583;\n\
14a-157;AddEvent;NULL;mmq;2014-06-02 14:23:33.587;\n\
14a-157;AddNesting;NULL;mmq;2014-06-02 14:23:33.587;\n\
14a-162;AddEvent;NULL;mmq;2014-06-02 14:23:33.587;\n\
14a-162;AddNesting;NULL;mmq;2014-06-02 14:23:33.587;\n\
14a-164;AddEvent;NULL;mmq;2014-06-02 14:23:33.590;\n\
14a-164;AddNesting;NULL;mmq;2014-06-02 14:23:33.590;\n\
14a-168;AddEvent;NULL;mmq;2014-06-02 14:23:33.590;\n\
14a-168;AddNesting;NULL;mmq;2014-06-02 14:23:33.590;\n\
14a-169;AddEvent;NULL;mmq;2014-06-02 14:23:33.593;\n\
14a-169;AddNesting;NULL;mmq;2014-06-02 14:23:33.593;\n\
14a-170;AddEvent;NULL;mmq;2014-06-02 14:23:33.597;\n\
14a-170;AddNesting;NULL;mmq;2014-06-02 14:23:33.597;\n\
14a-173;AddEvent;NULL;mmq;2014-06-02 14:23:33.597;\n\
14a-173;AddNesting;NULL;mmq;2014-06-02 14:23:33.597;\n\
14a-177;AddEvent;NULL;mmq;2014-06-02 14:23:33.620;\n\
14a-177;AddNesting;NULL;mmq;2014-06-02 14:23:33.620;\n\
14a-178;AddEvent;NULL;mmq;2014-06-02 14:23:33.620;\n\
14a-178;AddNesting;NULL;mmq;2014-06-02 14:23:33.620;\n\
14a-181;AddEvent;NULL;mmq;2014-06-02 14:23:33.623;\n\
14a-181;AddNesting;NULL;mmq;2014-06-02 14:23:33.623;\n\
14a-182;AddEvent;NULL;mmq;2014-06-02 14:23:33.627;\n\
14a-182;AddNesting;NULL;mmq;2014-06-02 14:23:33.627;\n\
14a-183;AddEvent;NULL;mmq;2014-06-02 14:23:33.637;\n\
14a-183;AddNesting;NULL;mmq;2014-06-02 14:23:33.637;\n\
14a-184;AddEvent;NULL;mmq;2014-06-02 14:23:33.640;\n\
14a-184;AddNesting;NULL;mmq;2014-06-02 14:23:33.640;\n\
14a-187;AddEvent;NULL;mmq;2014-06-02 14:23:33.640;\n\
14a-187;AddNesting;NULL;mmq;2014-06-02 14:23:33.643;\n\
14a-189;AddEvent;NULL;mmq;2014-06-02 14:23:33.643;\n\
14a-189;AddNesting;NULL;mmq;2014-06-02 14:23:33.643;\n\
14a-190;AddEvent;NULL;mmq;2014-06-02 14:23:33.647;\n\
14a-190;AddNesting;NULL;mmq;2014-06-02 14:23:33.647;\n\
14a-191;AddEvent;NULL;mmq;2014-06-02 14:23:33.650;\n\
14a-191;AddNesting;NULL;mmq;2014-06-02 14:23:33.670;\n\
14a-193;AddEvent;NULL;mmq;2014-06-02 14:23:33.673;\n\
14a-193;AddNesting;NULL;mmq;2014-06-02 14:23:33.673;\n\
14a-194;AddEvent;NULL;mmq;2014-06-02 14:23:33.673;\n\
14a-194;AddNesting;NULL;mmq;2014-06-02 14:23:33.673;\n\
14a-196;AddEvent;NULL;mmq;2014-06-02 14:23:33.677;\n\
14a-196;AddNesting;NULL;mmq;2014-06-02 14:23:33.677;\n\
14a-197;AddEvent;NULL;mmq;2014-06-02 14:23:33.680;\n\
14a-197;AddNesting;NULL;mmq;2014-06-02 14:23:33.680;\n\
14a-198;AddEvent;NULL;mmq;2014-06-02 14:23:33.683;\n\
14a-198;AddNesting;NULL;mmq;2014-06-02 14:23:33.683;\n\
14a-199;AddEvent;NULL;mmq;2014-06-02 14:23:33.687;\n\
14a-199;AddNesting;NULL;mmq;2014-06-02 14:23:33.690;\n\
14a-200;AddEvent;NULL;mmq;2014-06-02 14:23:33.690;\n\
14a-200;AddNesting;NULL;mmq;2014-06-02 14:23:33.690;\n\
14a-202;AddEvent;NULL;mmq;2014-06-02 14:23:33.693;\n\
14a-202;AddNesting;NULL;mmq;2014-06-02 14:23:33.693;\n\
14a-203;AddEvent;NULL;mmq;2014-06-02 14:23:33.697;\n\
14a-203;AddNesting;NULL;mmq;2014-06-02 14:23:33.697;\n\
14a-206;AddEvent;NULL;mmq;2014-06-02 14:23:33.700;\n\
14a-206;AddNesting;NULL;mmq;2014-06-02 14:23:33.700;\n\
14a-207;AddEvent;NULL;mmq;2014-06-02 14:23:33.700;\n\
14a-207;AddNesting;NULL;mmq;2014-06-02 14:23:33.700;\n\
14a-208;AddEvent;NULL;mmq;2014-06-02 14:23:33.703;\n\
14a-208;AddNesting;NULL;mmq;2014-06-02 14:23:33.703;\n\
14a-210;AddEvent;NULL;mmq;2014-06-02 14:23:33.707;\n\
14a-210;AddNesting;NULL;mmq;2014-06-02 14:23:33.707;\n\
14a-212;AddEvent;NULL;mmq;2014-06-02 14:23:33.710;\n\
14a-212;AddNesting;NULL;mmq;2014-06-02 14:23:33.710;\n\
14a-213;AddEvent;NULL;mmq;2014-06-02 14:23:33.710;\n\
14a-213;AddNesting;NULL;mmq;2014-06-02 14:23:33.710;\n\
14a-214;AddEvent;NULL;mmq;2014-06-02 14:23:33.713;\n\
14a-214;AddNesting;NULL;mmq;2014-06-02 14:23:33.713;\n\
14a-215;AddEvent;NULL;mmq;2014-06-02 14:23:33.717;\n\
14a-215;AddNesting;NULL;mmq;2014-06-02 14:23:33.717;\n\
14a-220;AddEvent;NULL;mmq;2014-06-02 14:23:33.720;\n\
14a-220;AddNesting;NULL;mmq;2014-06-02 14:23:33.720;\n\
14a-221;AddEvent;NULL;mmq;2014-06-02 14:23:33.720;\n\
14a-221;AddNesting;NULL;mmq;2014-06-02 14:23:33.720;\n\
14a-222;AddEvent;NULL;mmq;2014-06-02 14:23:33.723;\n\
14a-222;AddNesting;NULL;mmq;2014-06-02 14:23:33.723;\n\
14a-223;AddEvent;NULL;mmq;2014-06-02 14:23:33.727;\n\
14a-223;AddNesting;NULL;mmq;2014-06-02 14:23:33.727;\n\
14a-225;AddEvent;NULL;mmq;2014-06-02 14:23:33.730;\n\
14a-225;AddNesting;NULL;mmq;2014-06-02 14:23:33.730;\n\
14a-227;AddEvent;NULL;mmq;2014-06-02 14:23:33.730;\n\
14a-227;AddNesting;NULL;mmq;2014-06-02 14:23:33.730;\n\
14a-229;AddEvent;NULL;mmq;2014-06-02 14:23:33.737;\n\
14a-229;AddNesting;NULL;mmq;2014-06-02 14:23:33.737;\n\
14a-231;AddEvent;NULL;mmq;2014-06-02 14:23:33.740;\n\
14a-231;AddNesting;NULL;mmq;2014-06-02 14:23:33.740;\n\
14a-233;AddEvent;NULL;mmq;2014-06-02 14:23:33.740;\n\
14a-233;AddNesting;NULL;mmq;2014-06-02 14:23:33.740;\n\
14a-235;AddEvent;NULL;mmq;2014-06-02 14:23:33.743;\n\
14a-235;AddNesting;NULL;mmq;2014-06-02 14:23:33.743;\n\
14a-236;AddEvent;NULL;mmq;2014-06-02 14:23:33.757;\n\
14a-236;AddNesting;NULL;mmq;2014-06-02 14:23:33.757;\n\
14a-237;AddEvent;NULL;mmq;2014-06-02 14:23:33.757;\n\
14a-237;AddNesting;NULL;mmq;2014-06-02 14:23:33.760;\n\
14a-241;AddEvent;NULL;mmq;2014-06-02 14:23:33.760;\n\
14a-241;AddNesting;NULL;mmq;2014-06-02 14:23:33.760;\n\
14a-242;AddEvent;NULL;mmq;2014-06-02 14:23:33.763;\n\
14a-242;AddNesting;NULL;mmq;2014-06-02 14:23:33.763;\n\
14a-243;AddEvent;NULL;mmq;2014-06-02 14:23:33.763;\n\
14a-243;AddNesting;NULL;mmq;2014-06-02 14:23:33.763;\n\
14a-245;AddEvent;NULL;mmq;2014-06-02 14:23:33.767;\n\
14a-245;AddNesting;NULL;mmq;2014-06-02 14:23:33.767;\n\
14a-246;AddEvent;NULL;mmq;2014-06-02 14:23:33.770;\n\
14a-246;AddNesting;NULL;mmq;2014-06-02 14:23:33.770;\n\
14a-247;AddEvent;NULL;mmq;2014-06-02 14:23:33.770;\n\
14a-247;AddNesting;NULL;mmq;2014-06-02 14:23:33.773;\n\
14a-249;AddEvent;NULL;mmq;2014-06-02 14:23:33.773;\n\
14a-249;AddNesting;NULL;mmq;2014-06-02 14:23:33.773;\n\
14a-257;AddEvent;NULL;mmq;2014-06-02 14:23:33.777;\n\
14a-257;AddNesting;NULL;mmq;2014-06-02 14:23:33.777;\n\
14a-258;AddEvent;NULL;mmq;2014-06-02 14:23:33.780;\n\
14a-258;AddNesting;NULL;mmq;2014-06-02 14:23:33.780;\n\
14a-260;AddEvent;NULL;mmq;2014-06-02 14:23:33.780;\n\
14a-260;AddNesting;NULL;mmq;2014-06-02 14:23:33.780;\n\
14a-265;AddEvent;NULL;mmq;2014-06-02 14:23:33.783;\n\
14a-265;AddNesting;NULL;mmq;2014-06-02 14:23:33.783;\n\
14a-266;AddEvent;NULL;mmq;2014-06-02 14:23:33.787;\n\
14a-266;AddNesting;NULL;mmq;2014-06-02 14:23:33.787;\n\
14a-267;AddEvent;NULL;mmq;2014-06-02 14:23:33.787;\n\
14a-267;AddNesting;NULL;mmq;2014-06-02 14:23:33.790;\n\
14a-268;AddEvent;NULL;mmq;2014-06-02 14:23:33.790;\n\
14a-268;AddNesting;NULL;mmq;2014-06-02 14:23:33.790;\n\
14a-270;AddEvent;NULL;mmq;2014-06-02 14:23:33.793;\n\
14a-270;AddNesting;NULL;mmq;2014-06-02 14:23:33.793;\n\
14a-271;AddEvent;NULL;mmq;2014-06-02 14:23:33.797;\n\
14a-271;AddNesting;NULL;mmq;2014-06-02 14:23:33.797;\n\
14a-272;AddEvent;NULL;mmq;2014-06-02 14:23:33.797;\n\
14a-272;AddNesting;NULL;mmq;2014-06-02 14:23:33.797;\n\
14a-282;AddEvent;NULL;mmq;2014-06-02 14:23:33.800;\n\
14a-282;AddNesting;NULL;mmq;2014-06-02 14:23:33.800;\n\
14a-073;DL_DCREventTaskPersonal!25294;NULL;ls;2014-06-02 16:38:38.237;\n\
14a-073;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-02 16:38:42.040;\n\
14a-100;DL_DCREventTaskPersonal!25302;NULL;ls;2014-06-02 16:39:02.240;\n\
14a-100;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-02 16:39:03.607;\n\
14a-203;DL_DCREventTaskPersonal!25352;NULL;ls;2014-06-02 16:39:22.110;\n\
14a-203;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-02 16:39:24.080;\n\
14a-272;DL_DCREventTaskPersonal!25389;NULL;ls;2014-06-02 16:39:43.797;\n\
14a-272;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-02 16:39:45.810;\n\
14a-271;DL_DCREventTaskPersonal!25388;NULL;ls;2014-06-02 16:40:00.183;\n\
14a-271;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-02 16:40:02.713;\n\
14a-107;DL_DCREventTaskPersonal!25304;NULL;ls;2014-06-02 16:40:18.307;\n\
14a-107;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-02 16:40:20.377;\n\
14a-242;DL_DCREventTaskPersonal!25374;NULL;ls;2014-06-02 16:40:54.350;\n\
14a-242;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-02 16:40:56.357;\n\
14b-349;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-06-02 17:57:53.993;\n\
14b-390;Initialize;NULL;Ansøger;2014-06-02 22:04:28.933;\n\
14b-390;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-02 22:04:28.933;\n\
14b-391;Initialize;NULL;Ansøger;2014-06-02 22:12:30.073;\n\
14b-391;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-02 22:12:30.077;\n\
14b-371;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 10:38:04.233;\n\
14b-371;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 10:38:08.540;\n\
14b-392;Initialize;NULL;Ansøger;2014-06-03 12:28:08.480;\n\
14b-392;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-03 12:28:08.480;\n\
14b-350;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 12:40:42.243;\n\
14b-350;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 12:40:45.127;\n\
14b-351;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-06-03 12:54:27.363;\n\
14b-351;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-06-03 12:54:30.790;\n\
14b-357;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-06-03 13:01:06.650;\n\
14b-357;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-06-03 13:01:10.880;\n\
14b-363;DL_WFCaseTypeStep!486;Applicant informed ;ls;2014-06-03 13:03:34.180;\n\
14b-363;DL_WFCaseTypeStep!538;Change phase to Abort;ls;2014-06-03 13:03:36.397;\n\
14b-365;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 13:05:19.050;\n\
14b-365;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 13:05:21.020;\n\
14b-352;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 14:19:37.603;\n\
14b-352;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 14:19:41.933;\n\
14b-353;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 14:20:17.493;\n\
14b-353;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 14:20:21.103;\n\
14b-354;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 14:21:10.307;\n\
14b-354;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 14:21:12.410;\n\
14b-355;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 14:22:00.647;\n\
14b-355;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 14:22:04.650;\n\
14b-356;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 14:59:01.993;\n\
14b-356;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 14:59:05.490;\n\
14b-359;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 15:07:21.997;\n\
14b-359;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 15:07:24.790;\n\
14b-360;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 15:09:04.463;\n\
14b-360;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 15:09:07.220;\n\
14b-358;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 15:13:23.247;\n\
14b-358;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 15:13:27.057;\n\
14a-249;DL_WFCaseTypeStep!482;Inform applicant about the grant;Administrator;2014-06-03 15:26:23.490;\n\
14a-249;DL_WFCaseTypeStep!482;Inform applicant about the grant;Administrator;2014-06-03 15:28:25.317;\n\
14b-361;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-03 16:02:20.710;\n\
14b-361;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-03 16:02:24.570;\n\
14b-393;Initialize;NULL;Ansøger;2014-06-03 19:32:26.270;\n\
14b-393;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-03 19:32:26.270;\n\
14b-394;Initialize;NULL;Ansøger;2014-06-03 21:56:19.967;\n\
14b-394;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-03 21:56:19.970;\n\
14b-395;Initialize;NULL;Ansøger;2014-06-03 23:43:49.700;\n\
14b-395;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-03 23:43:49.700;\n\
14b-377;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-06-04 09:39:32.453;\n\
14a-271;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-06-04 09:42:56.970;\n\
14a-073;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-06-04 09:45:22.460;\n\
14a-272;DL_WFCaseTypeStep!479;First payout;ls;2014-06-04 09:58:27.890;\n\
14a-242;DL_WFCaseTypeStep!479;First payout;ls;2014-06-04 09:58:27.903;\n\
14a-100;DL_WFCaseTypeStep!479;First payout;ls;2014-06-04 09:58:27.917;\n\
14a-107;DL_WFCaseTypeStep!479;First payout;ls;2014-06-04 09:58:27.927;\n\
14a-203;DL_WFCaseTypeStep!479;First payout;ls;2014-06-04 09:58:27.937;\n\
14a-073;DL_WFCaseTypeStep!479;First payout;ls;2014-06-04 09:59:05.540;\n\
14a-271;DL_WFCaseTypeStep!479;First payout;ls;2014-06-04 09:59:05.550;\n\
14b-377;DL_WFCaseTypeStep!479;First payout;ls;2014-06-04 09:59:05.560;\n\
14b-377;DF_Udbetaling!882;NULL;ls;2014-06-04 09:59:05.583;\n\
14a-272;DL_WFCaseTypeStep!498;Undo payment;ls;2014-06-04 10:28:26.287;\n\
14a-272;DL_WFCaseTypeStep!479;First payout;ls;2014-06-04 10:52:22.960;\n\
14a-272;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-04 10:53:01.860;\n\
14b-396;Initialize;NULL;Ansøger;2014-06-04 13:59:26.243;\n\
14b-396;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-04 13:59:26.247;\n\
14b-397;Initialize;NULL;Ansøger;2014-06-04 22:36:59.873;\n\
14b-397;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-04 22:36:59.873;\n\
14b-398;Initialize;NULL;Ansøger;2014-06-05 17:35:32.530;\n\
14b-398;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-05 17:35:32.530;\n\
14b-399;Initialize;NULL;Ansøger;2014-06-05 22:02:55.787;\n\
14b-399;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-05 22:02:55.787;\n\
14a-046;DL_DCREventTaskPersonal!25277;NULL;ls;2014-06-06 11:28:13.577;\n\
14a-046;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-06 11:28:17.303;\n\
14a-029;DL_DCREventTaskPersonal!25269;NULL;ls;2014-06-06 12:14:23.207;\n\
14a-029;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-06 12:14:26.730;\n\
14a-257;DL_DCREventTaskPersonal!25380;NULL;ls;2014-06-06 12:46:45.457;\n\
14a-257;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-06 12:46:59.947;\n\
14b-367;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-06 13:04:41.590;\n\
14b-367;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-06 13:04:45.180;\n\
14b-367;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-06-06 13:05:07.390;\n\
14b-367;DL_WFCaseTypeStep!475;Register Decision;ls;2014-06-06 13:12:12.460;\n\
14b-367;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-06-06 13:13:03.173;\n\
14b-367;DL_WFCaseTypeStep!544;Pre-process application;ls;2014-06-06 13:13:42.117;\n\
14b-367;DL_WFCaseTypeStep!545;Execute pre-decision;ls;2014-06-06 13:13:44.060;\n\
14b-367;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-06-06 13:13:44.060;\n\
14b-367;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-06-06 13:14:02.903;\n\
14b-367;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-06-06 13:14:02.913;\n\
14b-367;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-06-06 13:14:18.710;\n\
14b-367;DL_WFCaseTypeStep!546;Applicant proves relevance of application;ls;2014-06-06 13:17:03.080;\n\
14b-367;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-06 13:17:06.030;\n\
14b-401;Initialize;NULL;Ansøger;2014-06-06 14:06:36.560;\n\
14b-401;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-06 14:06:36.560;\n\
14a-272;DL_WFCaseTypeStep!480;Receive final report;ls;2014-06-07 17:44:17.333;\n\
14a-272;DL_WFCaseTypeStep!520;Change phase to Complete;ls;2014-06-07 17:44:20.973;\n\
14a-242;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-07 19:19:19.360;\n\
14a-100;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-07 19:19:19.360;\n\
14a-107;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-07 19:19:19.370;\n\
14a-203;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-07 19:19:19.370;\n\
14a-242;DL_WFCaseTypeStep!521;Change Phase to End Report;ls;2014-06-07 19:19:42.967;\n\
14a-100;DL_WFCaseTypeStep!521;Change Phase to End Report;ls;2014-06-07 19:19:44.023;\n\
14a-107;DL_WFCaseTypeStep!521;Change Phase to End Report;ls;2014-06-07 19:19:45.140;\n\
14a-203;DL_WFCaseTypeStep!521;Change Phase to End Report;ls;2014-06-07 19:19:46.213;\n\
14a-073;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-07 19:33:25.923;\n\
14a-271;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-07 19:33:25.927;\n\
14b-377;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-07 19:33:25.927;\n\
14b-377;DL_DCREventDBTrigger!15376;NULL;CompletePayments;2014-06-07 19:33:25.927;\n\
14a-073;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-07 19:34:02.280;\n\
14a-271;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-07 19:34:03.563;\n\
14b-377;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-07 19:34:06.453;\n\
14a-154;DL_WFCaseTypeStep!541;Account number changed;ls;2014-06-07 19:46:04.673;\n\
14a-040;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-08 17:55:13.040;\n\
14a-040;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-08 17:56:01.333;\n\
14b-402;Initialize;NULL;Ansøger;2014-06-08 18:15:32.163;\n\
14b-402;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-08 18:15:32.163;\n\
14b-403;Initialize;NULL;Ansøger;2014-06-09 00:01:55.080;\n\
14b-403;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-09 00:01:55.080;\n\
14b-404;Initialize;NULL;Ansøger;2014-06-09 11:01:34.013;\n\
14b-404;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-09 11:01:34.013;\n\
14b-371;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-06-09 23:49:03.883;\n\
14a-057;DL_DCREventTaskPersonal!25284;NULL;ls;2014-06-10 12:46:01.413;\n\
14a-057;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-10 12:46:05.280;\n\
14a-212;DL_DCREventTaskPersonal!25357;NULL;ls;2014-06-10 13:18:34.203;\n\
14a-212;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-10 13:18:37.123;\n\
14b-405;Initialize;NULL;Ansøger;2014-06-10 15:07:10.770;\n\
14b-405;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-10 15:07:10.770;\n\
14b-406;Initialize;NULL;Ansøger;2014-06-10 15:18:06.020;\n\
14b-406;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-10 15:18:06.020;\n\
14b-407;Initialize;NULL;Ansøger;2014-06-10 16:06:37.273;\n\
14b-407;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-10 16:06:37.273;\n\
14a-053;DL_DCREventTaskPersonal!25281;NULL;ls;2014-06-10 16:07:57.113;\n\
14a-053;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-10 16:07:58.650;\n\
14a-265;DL_DCREventTaskPersonal!25383;NULL;ls;2014-06-10 16:17:09.767;\n\
14a-265;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-10 16:17:12.997;\n\
14a-169;DL_DCREventTaskPersonal!25331;NULL;ls;2014-06-10 17:04:20.593;\n\
14a-169;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-10 17:04:24.210;\n\
14b-408;Initialize;NULL;Ansøger;2014-06-11 13:16:28.630;\n\
14b-408;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-11 13:16:28.630;\n\
14a-041;DL_DCREventTaskPersonal!25275;NULL;ls;2014-06-11 13:33:13.930;\n\
14a-041;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-11 13:33:16.790;\n\
14a-041;DL_WFCaseTypeStep!541;Account number changed;ls;2014-06-11 13:34:14.500;\n\
14a-221;DL_DCREventTaskPersonal!25362;NULL;ls;2014-06-11 15:00:14.043;\n\
14a-221;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-11 15:00:17.990;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;ls;2014-06-11 15:14:30.970;\n\
14b-377;AddEvent;NULL;ls;2014-06-11 15:18:03.637;\n\
14b-377;AddNesting;NULL;ls;2014-06-11 15:18:03.653;\n\
14b-377;AddEvent;NULL;ls;2014-06-11 15:18:03.730;\n\
14b-377;AddNesting;NULL;ls;2014-06-11 15:18:03.730;\n\
14b-377;AddRelation;NULL;ls;2014-06-11 15:18:03.733;\n\
14b-377;AddRelation;NULL;ls;2014-06-11 15:18:03.737;\n\
14b-377;AddRelation;NULL;ls;2014-06-11 15:18:03.737;\n\
14b-377;AddRelation;NULL;ls;2014-06-11 15:18:03.737;\n\
14b-377;AddEvent;NULL;ls;2014-06-11 15:18:03.737;\n\
14b-377;AddRelation;NULL;ls;2014-06-11 15:18:03.737;\n\
14b-409;Initialize;NULL;Ansøger;2014-06-12 12:05:37.233;\n\
14b-409;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-12 12:05:37.233;\n\
14b-410;Initialize;NULL;Ansøger;2014-06-13 09:08:36.813;\n\
14b-410;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-13 09:08:36.813;\n\
14b-411;Initialize;NULL;Ansøger;2014-06-13 09:09:49.593;\n\
14b-411;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-13 09:09:49.593;\n\
14a-054;DL_DCREventTaskPersonal!25282;NULL;ls;2014-06-13 14:22:15.340;\n\
14a-054;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-13 14:22:18.387;\n\
14a-214;DL_DCREventTaskPersonal!25359;NULL;ls;2014-06-13 14:30:20.920;\n\
14a-214;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-13 14:30:23.903;\n\
14b-412;Initialize;NULL;Ansøger;2014-06-13 15:40:09.750;\n\
14b-412;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-13 15:40:09.750;\n\
14b-413;Initialize;NULL;Ansøger;2014-06-13 22:50:20.010;\n\
14b-413;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-13 22:50:20.010;\n\
14b-414;Initialize;NULL;Ansøger;2014-06-14 14:54:56.300;\n\
14b-414;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-14 14:54:56.303;\n\
14b-415;Initialize;NULL;Ansøger;2014-06-15 21:47:39.710;\n\
14b-415;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-15 21:47:39.710;\n\
14b-416;Initialize;NULL;Ansøger;2014-06-15 23:43:03.633;\n\
14b-416;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-15 23:43:03.633;\n\
14a-041;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-06-16 10:07:28.100;\n\
14b-377;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-06-16 10:11:08.850;\n\
14b-367;DL_WFCaseTypeStep!541;Account number changed;ls;2014-06-16 10:15:51.323;\n\
14b-367;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-06-16 10:20:16.793;\n\
14b-367;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-06-16 10:20:52.617;\n\
14a-079;DL_WFCaseTypeStep!541;Account number changed;ls;2014-06-16 11:02:33.833;\n\
14a-079;DL_DCREventTaskPersonal!25298;NULL;ls;2014-06-16 11:03:11.683;\n\
14a-079;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-16 11:03:13.070;\n\
14a-029;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 11:05:56.740;\n\
14a-046;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 11:05:56.750;\n\
14a-257;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 11:05:56.760;\n\
14a-057;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 11:05:56.770;\n\
14a-079;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-06-16 11:10:13.443;\n\
14a-169;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 11:30:48.670;\n\
14a-221;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 11:30:48.683;\n\
14a-053;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 11:30:48.700;\n\
14a-212;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 11:30:48.713;\n\
14a-265;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 11:30:48.737;\n\
14a-214;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 12:07:16.517;\n\
14a-054;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 12:07:16.537;\n\
14a-041;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 12:21:52.977;\n\
14b-367;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 12:21:52.983;\n\
14b-377;DF_Udbetaling!884;NULL;ls;2014-06-16 12:21:52.993;\n\
14b-377;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-16 12:22:05.140;\n\
14a-079;DL_WFCaseTypeStep!479;First payout;ls;2014-06-16 12:29:37.313;\n\
14b-371;DL_WFCaseTypeStep!475;Register Decision;ls;2014-06-16 16:26:13.957;\n\
14b-371;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-06-16 16:27:03.373;\n\
14b-420;Initialize;NULL;Ansøger;2014-06-16 20:51:47.770;\n\
14b-420;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-16 20:51:47.787;\n\
14b-421;Initialize;NULL;Ansøger;2014-06-17 00:00:14.097;\n\
14b-421;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-17 00:00:14.097;\n\
14b-299;DL_WFCaseTypeStep!472;Architect Review;mp;2014-06-17 13:26:45.433;\n\
14b-299;DL_WFCaseTypeStep!472;Architect Review;mp;2014-06-17 13:26:50.437;\n\
14a-257;DL_WFCaseTypeStep!541;Account number changed;ls;2014-06-18 11:49:11.910;\n\
14a-265;DL_WFCaseTypeStep!541;Account number changed;ls;2014-06-18 11:50:50.920;\n\
14b-422;Initialize;NULL;Ansøger;2014-06-18 12:07:36.423;\n\
14b-422;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-18 12:07:36.423;\n\
14b-420;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-18 12:23:14.060;\n\
14b-420;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-18 12:23:16.907;\n\
14b-371;DL_WFCaseTypeStep!475;Register Decision;ls;2014-06-18 13:06:46.510;\n\
14b-371;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-06-18 13:07:03.293;\n\
14b-371;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;ls;2014-06-18 13:08:51.023;\n\
14b-371;DL_WFCaseTypeStep!544;Pre-process application;ls;2014-06-18 13:08:58.030;\n\
14b-371;DL_WFCaseTypeStep!545;Execute pre-decision;ls;2014-06-18 13:08:58.967;\n\
14b-371;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-06-18 13:08:58.967;\n\
14b-371;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-06-18 13:09:02.707;\n\
14b-371;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-06-18 13:09:02.720;\n\
14b-371;DL_WFCaseTypeStep!482;Inform applicant about the grant;ls;2014-06-18 13:22:44.480;\n\
14b-406;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-18 14:29:02.437;\n\
14b-406;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-18 14:29:06.217;\n\
14b-423;Initialize;NULL;Ansøger;2014-06-18 22:43:47.410;\n\
14b-423;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-18 22:43:47.410;\n\
14b-424;Initialize;NULL;Ansøger;2014-06-19 08:31:50.587;\n\
14b-424;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-19 08:31:50.587;\n\
14b-425;Initialize;NULL;Ansøger;2014-06-19 11:54:44.237;\n\
14b-425;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-19 11:54:44.237;\n\
14b-426;Initialize;NULL;Ansøger;2014-06-19 13:38:19.653;\n\
14b-426;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-19 13:38:19.653;\n\
14b-420;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-06-20 00:33:25.927;\n\
14b-299;DL_WFCaseTypeStep!472;Architect Review;mp;2014-06-20 10:26:32.980;\n\
14b-299;DL_WFCaseTypeStep!472;Architect Review;mp;2014-06-20 10:30:47.917;\n\
14b-299;DL_WFCaseTypeStep!475;Register Decision;ls;2014-06-20 10:32:12.480;\n\
14b-299;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-06-20 10:33:04.113;\n\
14b-299;DL_WFCaseTypeStep!472;Architect Review;mp;2014-06-20 10:33:48.617;\n\
14a-041;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-20 11:56:09.783;\n\
14b-367;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-20 11:56:09.783;\n\
14b-377;DL_DCREventDBTrigger!15548;NULL;CompletePayments;2014-06-20 11:56:09.783;\n\
14a-041;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-20 11:57:01.527;\n\
14b-367;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-20 11:57:02.613;\n\
14b-427;Initialize;NULL;Ansøger;2014-06-20 14:23:06.300;\n\
14b-427;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-20 14:23:06.303;\n\
14a-214;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-22 18:16:10.570;\n\
14a-054;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-22 18:16:11.037;\n\
14a-214;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-22 18:17:02.070;\n\
14a-054;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-22 18:17:03.240;\n\
14b-428;Initialize;NULL;Ansøger;2014-06-23 10:30:55.517;\n\
14b-428;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-23 10:30:55.520;\n\
14b-429;Initialize;NULL;Ansøger;2014-06-23 11:41:36.157;\n\
14b-429;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-23 11:41:36.160;\n\
14b-430;Initialize;NULL;Ansøger;2014-06-23 14:47:02.770;\n\
14b-430;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-23 14:47:02.770;\n\
14a-266;DL_WFCaseTypeStep!550;Carry out lapses;ls;2014-06-23 15:16:58.703;\n\
14b-299;DL_WFCaseTypeStep!472;Architect Review;mp;2014-06-23 15:26:23.993;\n\
14b-431;Initialize;NULL;Ansøger;2014-06-23 17:46:01.760;\n\
14b-431;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-23 17:46:01.763;\n\
14b-432;Initialize;NULL;Ansøger;2014-06-24 10:05:49.547;\n\
14b-432;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-24 10:05:49.550;\n\
14b-355;DL_WFCaseTypeStep!475;Register Decision;ls;2014-06-24 13:12:18.223;\n\
14b-355;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-06-24 13:13:04.577;\n\
14b-433;Initialize;NULL;Ansøger;2014-06-24 13:20:31.103;\n\
14b-433;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-24 13:20:31.103;\n\
14a-169;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-24 13:56:52.223;\n\
14a-221;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-24 13:56:52.230;\n\
14a-053;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-24 13:56:52.230;\n\
14a-212;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-24 13:56:52.240;\n\
14a-265;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-24 13:56:52.240;\n\
14a-169;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-24 13:57:01.950;\n\
14a-221;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-24 13:57:03.013;\n\
14a-053;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-24 13:57:04.117;\n\
14a-212;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-24 13:57:05.293;\n\
14a-265;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-24 13:57:06.507;\n\
14a-029;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-24 14:26:11.177;\n\
14a-046;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-24 14:26:11.177;\n\
14a-257;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-24 14:26:11.187;\n\
14a-057;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-24 14:26:11.187;\n\
14a-029;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-24 14:27:01.953;\n\
14a-046;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-24 14:27:03.490;\n\
14a-257;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-24 14:27:04.920;\n\
14a-057;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-24 14:27:06.320;\n\
14b-434;Initialize;NULL;Ansøger;2014-06-24 15:12:14.237;\n\
14b-434;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-24 15:12:14.240;\n\
14b-435;Initialize;NULL;Ansøger;2014-06-24 17:28:14.813;\n\
14b-435;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-24 17:28:14.813;\n\
14b-436;Initialize;NULL;Ansøger;2014-06-25 11:59:37.883;\n\
14b-436;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-25 11:59:37.883;\n\
14b-425;DL_WFCaseTypeStep!469;Approval - on to the board;ls;2014-06-25 12:29:33.053;\n\
14b-425;DL_WFCaseTypeStep!518;Change phase to review;ls;2014-06-25 12:29:36.073;\n\
14b-437;Initialize;NULL;Ansøger;2014-06-25 13:55:24.323;\n\
14b-437;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-25 13:55:24.323;\n\
14b-420;DL_WFCaseTypeStep!475;Register Decision;ls;2014-06-25 14:54:09.253;\n\
14b-420;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-06-25 14:55:03.520;\n\
14b-420;DL_WFCaseTypeStep!474;Review;bry;2014-06-25 15:10:53.893;\n\
14b-438;Initialize;NULL;Ansøger;2014-06-25 16:47:21.593;\n\
14b-438;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-25 16:47:21.593;\n\
14b-439;Initialize;NULL;Ansøger;2014-06-25 17:19:28.443;\n\
14b-439;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-25 17:19:28.443;\n\
14b-440;Initialize;NULL;Ansøger;2014-06-26 11:23:39.940;\n\
14b-440;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-26 11:23:39.943;\n\
14a-065;DL_DCREventTaskPersonal!25289;NULL;ls;2014-06-26 12:24:31.960;\n\
14a-065;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-26 12:24:35.813;\n\
14a-030;DL_DCREventTaskPersonal!25271;NULL;ls;2014-06-26 12:26:32.993;\n\
14a-030;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-26 12:26:34.450;\n\
14a-063;DL_DCREventTaskPersonal!25287;NULL;ls;2014-06-26 12:33:21.433;\n\
14a-063;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-26 12:33:24.490;\n\
14a-182;DL_DCREventTaskPersonal!25337;NULL;ls;2014-06-26 12:35:03.530;\n\
14a-182;DL_WFCaseTypeStep!522;Change Phase to Payout;ls;2014-06-26 12:35:04.697;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;ls;2014-06-26 12:48:24.813;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;ls;2014-06-26 12:53:15.163;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;ls;2014-06-26 12:56:18.243;\n\
14b-441;Initialize;NULL;Ansøger;2014-06-26 13:00:33.527;\n\
14b-441;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-26 13:00:33.527;\n\
14a-065;DL_WFCaseTypeStep!479;First payout;ls;2014-06-26 13:35:47.347;\n\
14a-030;DL_WFCaseTypeStep!479;First payout;ls;2014-06-26 13:35:47.370;\n\
14a-182;DL_WFCaseTypeStep!479;First payout;ls;2014-06-26 14:02:15.830;\n\
14a-063;DL_WFCaseTypeStep!479;First payout;ls;2014-06-26 14:02:15.850;\n\
14b-377;AddEvent;NULL;ls;2014-06-26 14:13:23.917;\n\
14b-377;AddNesting;NULL;ls;2014-06-26 14:13:23.920;\n\
14b-377;AddEvent;NULL;ls;2014-06-26 14:13:24.020;\n\
14b-377;AddNesting;NULL;ls;2014-06-26 14:13:24.020;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:13:24.023;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:13:24.023;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:13:24.023;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:13:24.023;\n\
14b-377;AddEvent;NULL;ls;2014-06-26 14:13:24.040;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:13:24.040;\n\
14b-377;AddEvent;NULL;ls;2014-06-26 14:17:47.223;\n\
14b-377;AddNesting;NULL;ls;2014-06-26 14:17:47.223;\n\
14b-377;AddEvent;NULL;ls;2014-06-26 14:17:47.227;\n\
14b-377;AddNesting;NULL;ls;2014-06-26 14:17:47.227;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:17:47.227;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:17:47.227;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:17:47.227;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:17:47.227;\n\
14b-377;AddEvent;NULL;ls;2014-06-26 14:17:47.227;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:17:47.227;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;ANONYMOUS LOGON;2014-06-26 14:23:52.817;\n\
14b-377;AddEvent;NULL;ls;2014-06-26 14:26:59.893;\n\
14b-377;AddNesting;NULL;ls;2014-06-26 14:26:59.893;\n\
14b-377;AddEvent;NULL;ls;2014-06-26 14:26:59.893;\n\
14b-377;AddNesting;NULL;ls;2014-06-26 14:26:59.893;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:26:59.897;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:26:59.897;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:26:59.897;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:26:59.897;\n\
14b-377;AddEvent;NULL;ls;2014-06-26 14:26:59.897;\n\
14b-377;AddRelation;NULL;ls;2014-06-26 14:26:59.900;\n\
14b-377;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-06-26 14:29:53.633;\n\
14b-442;Initialize;NULL;Ansøger;2014-06-26 14:50:43.990;\n\
14b-442;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-26 14:50:43.990;\n\
14b-377;DF_Udbetaling!885;NULL;ls;2014-06-26 15:04:02.437;\n\
14b-377;DF_Udbetaling!886;NULL;ls;2014-06-26 15:04:02.460;\n\
14b-377;DF_Udbetaling!887;NULL;ls;2014-06-26 15:04:02.483;\n\
14b-377;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-26 15:05:04.890;\n\
14b-443;Initialize;NULL;Ansøger;2014-06-26 16:40:11.820;\n\
14b-443;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-26 16:40:11.820;\n\
14b-377;DL_DCREventDBTrigger!15779;NULL;CompletePayments;2014-06-26 20:05:35.420;\n\
14b-377;DL_DCREventDBTrigger!15780;NULL;CompletePayments;2014-06-26 20:05:35.420;\n\
14b-377;DL_DCREventDBTrigger!15781;NULL;CompletePayments;2014-06-26 20:05:35.420;\n\
14a-182;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-26 20:54:12.270;\n\
14a-063;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-26 20:54:12.270;\n\
14a-182;DL_WFCaseTypeStep!521;Change Phase to End Report;ls;2014-06-26 20:54:47.783;\n\
14a-063;DL_WFCaseTypeStep!521;Change Phase to End Report;ls;2014-06-26 20:54:49.253;\n\
14a-065;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-26 20:54:50.270;\n\
14a-030;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-06-26 20:54:50.270;\n\
14a-065;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-26 20:55:03.067;\n\
14a-030;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-26 20:55:03.880;\n\
14b-444;Initialize;NULL;Ansøger;2014-06-27 11:34:35.457;\n\
14b-444;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-27 11:34:35.460;\n\
14b-445;Initialize;NULL;Ansøger;2014-06-27 12:56:29.067;\n\
14b-445;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-27 12:56:29.067;\n\
14b-446;Initialize;NULL;Ansøger;2014-06-27 12:57:14.020;\n\
14b-446;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-27 12:57:14.020;\n\
14b-447;Initialize;NULL;Ansøger;2014-06-27 13:22:28.663;\n\
14b-447;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-27 13:22:28.663;\n\
14b-448;Initialize;NULL;Ansøger;2014-06-27 13:39:43.117;\n\
14b-448;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-27 13:39:43.117;\n\
14b-449;Initialize;NULL;Ansøger;2014-06-27 13:41:48.363;\n\
14b-449;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-27 13:41:48.363;\n\
14b-450;Initialize;NULL;Ansøger;2014-06-27 14:05:43.487;\n\
14b-450;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-27 14:05:43.487;\n\
14b-451;Initialize;NULL;Ansøger;2014-06-27 15:50:50.247;\n\
14b-451;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-27 15:50:50.247;\n\
14b-452;Initialize;NULL;Ansøger;2014-06-27 16:50:26.627;\n\
14b-452;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-27 16:50:26.627;\n\
14b-453;Initialize;NULL;Ansøger;2014-06-28 13:34:12.903;\n\
14b-453;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-28 13:34:12.907;\n\
14b-454;Initialize;NULL;Ansøger;2014-06-28 13:57:17.127;\n\
14b-454;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-28 13:57:17.127;\n\
14b-455;Initialize;NULL;Ansøger;2014-06-28 18:17:21.053;\n\
14b-455;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-28 18:17:21.053;\n\
14b-456;Initialize;NULL;Ansøger;2014-06-28 23:00:26.993;\n\
14b-456;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-28 23:00:26.997;\n\
14b-457;Initialize;NULL;Ansøger;2014-06-29 16:28:30.440;\n\
14b-457;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-29 16:28:30.440;\n\
14b-458;Initialize;NULL;Ansøger;2014-06-29 22:14:10.930;\n\
14b-458;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-29 22:14:10.930;\n\
14b-459;Initialize;NULL;Ansøger;2014-06-30 01:29:30.700;\n\
14b-459;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-30 01:29:30.700;\n\
14b-460;Initialize;NULL;Ansøger;2014-06-30 10:12:47.760;\n\
14b-460;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-30 10:12:47.760;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;pdm;2014-06-30 11:55:32.867;\n\
14b-377;AddEvent;NULL;pdm;2014-06-30 11:57:01.930;\n\
14b-377;AddNesting;NULL;pdm;2014-06-30 11:57:01.933;\n\
14b-377;AddEvent;NULL;pdm;2014-06-30 11:57:02.053;\n\
14b-377;AddNesting;NULL;pdm;2014-06-30 11:57:02.053;\n\
14b-377;AddRelation;NULL;pdm;2014-06-30 11:57:02.060;\n\
14b-377;AddRelation;NULL;pdm;2014-06-30 11:57:02.060;\n\
14b-377;AddRelation;NULL;pdm;2014-06-30 11:57:02.060;\n\
14b-377;AddRelation;NULL;pdm;2014-06-30 11:57:02.060;\n\
14b-377;AddEvent;NULL;pdm;2014-06-30 11:57:02.060;\n\
14b-377;AddRelation;NULL;pdm;2014-06-30 11:57:02.063;\n\
14b-377;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-06-30 12:07:48.753;\n\
14b-377;DF_Udbetaling!888;NULL;pdm;2014-06-30 12:12:08.160;\n\
14b-377;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-06-30 12:13:02.620;\n\
14b-461;Initialize;NULL;Ansøger;2014-06-30 12:35:58.047;\n\
14b-461;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-30 12:35:58.050;\n\
14b-462;Initialize;NULL;Ansøger;2014-06-30 12:50:31.957;\n\
14b-462;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-30 12:50:31.957;\n\
14b-463;Initialize;NULL;Ansøger;2014-06-30 13:20:54.583;\n\
14b-463;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-30 13:20:54.583;\n\
14b-460;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-06-30 13:31:32.027;\n\
14b-460;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-06-30 13:31:34.853;\n\
14b-460;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-06-30 14:17:29.420;\n\
14b-464;Initialize;NULL;Ansøger;2014-06-30 15:03:54.977;\n\
14b-464;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-30 15:03:54.980;\n\
14b-465;Initialize;NULL;Ansøger;2014-06-30 17:19:37.747;\n\
14b-465;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-06-30 17:19:37.747;\n\
14b-466;Initialize;NULL;Ansøger;2014-07-01 12:53:55.317;\n\
14b-466;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-01 12:53:55.317;\n\
14b-466;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-01 15:17:48.147;\n\
14b-466;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-01 15:17:51.173;\n\
14b-465;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-01 15:55:40.030;\n\
14b-467;Initialize;NULL;Ansøger;2014-07-01 16:04:02.413;\n\
14b-467;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-01 16:04:02.413;\n\
14b-364;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-01 16:11:47.143;\n\
14b-364;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-01 16:11:49.963;\n\
14a-082;AddEvent;NULL;pdm;2014-07-02 12:54:10.020;\n\
14a-082;AddNesting;NULL;pdm;2014-07-02 12:54:10.040;\n\
14a-082;DL_DCREventTaskPersonal!25843;NULL;pdm;2014-07-02 12:59:28.457;\n\
14b-468;Initialize;NULL;Ansøger;2014-07-02 13:00:33.130;\n\
14b-468;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-02 13:00:33.130;\n\
14b-469;Initialize;NULL;Ansøger;2014-07-03 11:15:24.207;\n\
14b-469;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-03 11:15:24.207;\n\
14b-460;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-07-03 11:25:58.400;\n\
14b-460;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-07-03 11:26:03.143;\n\
14b-355;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-07-03 11:28:40.210;\n\
14b-355;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-07-03 11:29:02.350;\n\
14b-470;Initialize;NULL;Ansøger;2014-07-03 12:01:28.183;\n\
14b-470;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-03 12:01:28.183;\n\
14b-471;Initialize;NULL;Ansøger;2014-07-03 13:17:45.133;\n\
14b-471;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-03 13:17:45.133;\n\
14b-466;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-07-03 14:37:24.783;\n\
14b-466;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-07-03 14:38:03.867;\n\
14b-362;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-03 15:30:20.333;\n\
14b-362;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-03 15:31:09.320;\n\
14b-366;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-03 15:34:53.263;\n\
14b-368;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-03 15:37:19.860;\n\
14b-368;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-03 15:37:22.660;\n\
14b-366;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-03 15:39:16.497;\n\
14b-369;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-03 15:42:00.330;\n\
14b-370;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-03 15:44:42.837;\n\
14b-472;Initialize;NULL;Ansøger;2014-07-03 16:10:56.710;\n\
14b-472;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-03 16:10:56.710;\n\
14b-473;Initialize;NULL;Ansøger;2014-07-03 18:20:32.207;\n\
14b-473;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-03 18:20:32.207;\n\
14b-474;Initialize;NULL;Ansøger;2014-07-04 10:04:34.990;\n\
14b-474;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 10:04:34.990;\n\
14b-372;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 10:49:41.537;\n\
14b-372;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 10:49:45.187;\n\
14b-372;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 10:49:52.610;\n\
14b-375;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-04 11:02:48.933;\n\
14b-378;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 11:33:29.483;\n\
14b-378;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 11:33:32.707;\n\
14b-378;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 11:33:37.147;\n\
14b-379;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 12:03:34.820;\n\
14b-379;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 12:03:39.257;\n\
14b-379;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 12:03:44.287;\n\
14b-475;Initialize;NULL;Ansøger;2014-07-04 12:08:09.540;\n\
14b-475;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 12:08:09.540;\n\
14b-380;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 12:13:30.757;\n\
14b-380;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 12:13:33.430;\n\
14b-380;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 12:13:40.750;\n\
14b-381;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 12:15:13.457;\n\
14b-381;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 12:15:15.160;\n\
14b-381;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 12:15:21.043;\n\
14b-383;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-04 12:17:15.737;\n\
14b-386;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-04 12:20:44.547;\n\
14b-387;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 12:23:23.027;\n\
14b-387;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 12:23:25.807;\n\
14b-387;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 12:23:30.977;\n\
14b-388;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-04 12:25:10.610;\n\
14b-389;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-04 12:26:50.580;\n\
14b-390;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-04 12:28:27.583;\n\
14b-391;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-04 12:29:20.803;\n\
14b-392;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-04 12:30:57.620;\n\
14b-393;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 12:33:11.890;\n\
14b-393;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 12:33:14.560;\n\
14b-393;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 12:33:21.937;\n\
14b-394;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 12:36:27.777;\n\
14b-394;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 12:36:30.590;\n\
14b-394;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 12:36:33.207;\n\
14b-395;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 12:38:29.707;\n\
14b-395;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 12:38:32.570;\n\
14b-395;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 12:38:35.530;\n\
14b-396;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 12:42:06.910;\n\
14b-396;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 12:42:09.920;\n\
14b-396;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 12:42:13.377;\n\
14b-397;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 12:44:42.823;\n\
14b-397;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 12:44:45.650;\n\
14b-397;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 12:44:49.317;\n\
14b-398;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 12:47:53.037;\n\
14b-398;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 12:47:55.987;\n\
14b-398;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 12:48:00.187;\n\
14b-399;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 12:49:25.653;\n\
14b-399;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 12:49:27.483;\n\
14b-399;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 12:49:32.703;\n\
14b-476;Initialize;NULL;Ansøger;2014-07-04 12:52:54.250;\n\
14b-476;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 12:52:54.250;\n\
14b-401;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:17:57.083;\n\
14b-401;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:18:00.950;\n\
14b-401;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:18:04.703;\n\
14b-402;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:20:03.670;\n\
14b-402;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:20:06.393;\n\
14b-402;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:20:10.063;\n\
14b-403;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:21:44.010;\n\
14b-403;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:21:45.677;\n\
14b-403;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:21:49.530;\n\
14b-404;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:26:07.913;\n\
14b-404;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:26:10.687;\n\
14b-404;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:26:14.293;\n\
14b-477;Initialize;NULL;Ansøger;2014-07-04 13:27:21.430;\n\
14b-477;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 13:27:21.430;\n\
14b-478;Initialize;NULL;Ansøger;2014-07-04 13:29:27.557;\n\
14b-478;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 13:29:27.557;\n\
14b-405;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:29:27.937;\n\
14b-405;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:29:35.887;\n\
14b-405;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:29:39.057;\n\
14b-406;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-07-04 13:32:23.063;\n\
14b-406;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-07-04 13:33:02.850;\n\
14b-407;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:33:40.720;\n\
14b-407;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:33:43.130;\n\
14b-407;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:33:45.837;\n\
14b-408;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:35:59.940;\n\
14b-408;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:36:03.340;\n\
14b-408;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:36:06.807;\n\
14b-409;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:38:10.570;\n\
14b-409;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:38:13.360;\n\
14b-409;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:38:17.123;\n\
14b-410;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:40:01.960;\n\
14b-410;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:40:05.653;\n\
14b-410;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:40:08.593;\n\
14b-411;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:40:55.080;\n\
14b-411;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:40:56.750;\n\
14b-411;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:41:01.320;\n\
14b-479;Initialize;NULL;Ansøger;2014-07-04 13:41:54.600;\n\
14b-479;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 13:41:54.600;\n\
14b-412;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-04 13:43:51.140;\n\
14b-413;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:46:45.577;\n\
14b-413;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:46:48.317;\n\
14b-413;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:46:58.340;\n\
14b-414;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-04 13:48:17.057;\n\
14b-415;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:50:30.460;\n\
14b-415;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:50:33.447;\n\
14b-415;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:50:35.593;\n\
14b-416;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-04 13:54:10.270;\n\
14b-416;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-04 13:54:13.123;\n\
14b-416;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-04 13:54:15.400;\n\
14b-480;Initialize;NULL;Ansøger;2014-07-04 13:59:33.660;\n\
14b-480;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 13:59:33.660;\n\
14b-421;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-04 14:10:52.720;\n\
14b-481;Initialize;NULL;Ansøger;2014-07-04 14:31:58.553;\n\
14b-481;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 14:31:58.557;\n\
14b-482;Initialize;NULL;Ansøger;2014-07-04 16:42:50.450;\n\
14b-482;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 16:42:50.450;\n\
14b-483;Initialize;NULL;Ansøger;2014-07-04 17:24:38.913;\n\
14b-483;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 17:24:38.917;\n\
14b-484;Initialize;NULL;Ansøger;2014-07-04 17:49:39.470;\n\
14b-484;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 17:49:39.470;\n\
14b-485;Initialize;NULL;Ansøger;2014-07-04 20:51:21.410;\n\
14b-485;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-04 20:51:21.410;\n\
14b-486;Initialize;NULL;Ansøger;2014-07-05 14:48:04.810;\n\
14b-486;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-05 14:48:04.820;\n\
14b-487;Initialize;NULL;Ansøger;2014-07-05 19:30:10.833;\n\
14b-487;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-05 19:30:10.837;\n\
14b-488;Initialize;NULL;Ansøger;2014-07-06 15:22:05.593;\n\
14b-488;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-06 15:22:05.593;\n\
14b-489;Initialize;NULL;Ansøger;2014-07-06 17:41:45.850;\n\
14b-489;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-06 17:41:45.850;\n\
14b-470;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-07 09:31:45.790;\n\
14b-490;Initialize;NULL;Ansøger;2014-07-07 09:55:57.110;\n\
14b-490;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-07 09:55:57.110;\n\
14b-422;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-07 10:48:10.790;\n\
14b-422;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-07 10:48:14.637;\n\
14b-422;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-07 10:48:17.940;\n\
14b-423;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-07 11:02:03.470;\n\
14b-424;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-07 11:03:26.903;\n\
14b-426;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-07 11:08:43.590;\n\
14b-427;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-07 11:10:22.507;\n\
14b-427;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-07 11:10:24.607;\n\
14b-427;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-07 11:10:30.243;\n\
14b-428;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-07 11:22:04.520;\n\
14b-429;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-07 11:24:47.750;\n\
14b-429;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-07 11:24:50.397;\n\
14b-429;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-07 11:24:53.260;\n\
14b-430;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-07 11:26:12.713;\n\
14b-430;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-07 11:26:14.410;\n\
14b-430;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-07 11:26:16.610;\n\
14b-431;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-07 11:27:54.763;\n\
14b-491;Initialize;NULL;Ansøger;2014-07-07 12:23:45.383;\n\
14b-491;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-07 12:23:45.383;\n\
14b-492;Initialize;NULL;Ansøger;2014-07-07 13:39:41.863;\n\
14b-492;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-07 13:39:41.863;\n\
14b-432;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-07 14:10:44.457;\n\
14b-432;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-07 14:10:48.393;\n\
14b-432;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-07 14:10:53.187;\n\
14b-433;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-07 14:15:47.657;\n\
14b-433;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-07 14:15:50.390;\n\
14b-433;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-07 14:16:13.810;\n\
14b-434;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-07 14:33:21.407;\n\
14b-434;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-07 14:33:24.163;\n\
14b-434;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-07 14:33:29.940;\n\
14b-435;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-07 14:34:53.770;\n\
14b-436;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-07 14:36:23.110;\n\
14b-437;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-07 14:37:25.227;\n\
14b-438;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-07 14:45:56.717;\n\
14b-438;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-07 14:45:59.527;\n\
14b-438;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-07 14:46:05.193;\n\
14b-439;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-07 14:47:02.713;\n\
14b-440;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-07 14:48:08.200;\n\
14b-440;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-07 14:48:11.090;\n\
14b-440;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-07 14:48:13.077;\n\
14b-493;Initialize;NULL;Ansøger;2014-07-07 17:07:18.073;\n\
14b-493;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-07 17:07:18.073;\n\
14b-494;Initialize;NULL;Ansøger;2014-07-07 22:33:51.790;\n\
14b-494;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-07 22:33:51.790;\n\
14b-495;Initialize;NULL;Ansøger;2014-07-08 08:47:26.047;\n\
14b-495;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-08 08:47:26.047;\n\
14b-496;Initialize;NULL;Ansøger;2014-07-08 10:20:32.427;\n\
14b-496;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-08 10:20:32.427;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;ANONYMOUS LOGON;2014-07-08 10:46:19.003;\n\
14b-377;AddEvent;NULL;pdm;2014-07-08 10:52:38.920;\n\
14b-377;AddNesting;NULL;pdm;2014-07-08 10:52:38.923;\n\
14b-377;AddEvent;NULL;pdm;2014-07-08 10:52:39.017;\n\
14b-377;AddNesting;NULL;pdm;2014-07-08 10:52:39.017;\n\
14b-377;AddRelation;NULL;pdm;2014-07-08 10:52:39.020;\n\
14b-377;AddRelation;NULL;pdm;2014-07-08 10:52:39.023;\n\
14b-377;AddRelation;NULL;pdm;2014-07-08 10:52:39.023;\n\
14b-377;AddRelation;NULL;pdm;2014-07-08 10:52:39.023;\n\
14b-377;AddEvent;NULL;pdm;2014-07-08 10:52:39.030;\n\
14b-377;AddRelation;NULL;pdm;2014-07-08 10:52:39.030;\n\
14b-377;DL_DCREventTaskPersonal!25816;NULL;pdm;2014-07-08 10:53:28.143;\n\
14b-377;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-07-08 10:56:00.640;\n\
14b-377;DF_Udbetaling!888;NULL;ANONYMOUS LOGON;2014-07-08 11:06:03.810;\n\
14b-497;Initialize;NULL;Ansøger;2014-07-08 11:34:03.127;\n\
14b-497;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-08 11:34:03.127;\n\
14b-498;Initialize;NULL;Ansøger;2014-07-08 13:01:48.773;\n\
14b-498;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-08 13:01:48.773;\n\
14b-499;Initialize;NULL;Ansøger;2014-07-08 13:02:41.533;\n\
14b-499;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-08 13:02:41.533;\n\
14b-500;Initialize;NULL;Ansøger;2014-07-08 13:57:02.460;\n\
14b-500;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-08 13:57:02.460;\n\
14b-501;Initialize;NULL;Ansøger;2014-07-08 14:33:26.320;\n\
14b-501;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-08 14:33:26.323;\n\
14b-502;Initialize;NULL;Ansøger;2014-07-08 14:59:42.637;\n\
14b-502;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-08 14:59:42.637;\n\
14b-503;Initialize;NULL;Ansøger;2014-07-08 17:31:30.737;\n\
14b-503;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-08 17:31:30.737;\n\
14b-504;Initialize;NULL;Ansøger;2014-07-08 22:26:18.673;\n\
14b-504;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-08 22:26:18.673;\n\
14b-505;Initialize;NULL;Ansøger;2014-07-09 09:47:10.863;\n\
14b-505;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 09:47:10.863;\n\
14b-352;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 10:17:17.200;\n\
14b-353;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 10:21:12.810;\n\
14b-354;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 10:23:25.877;\n\
14b-355;RestartWorkflow;NULL;pdm;2014-07-09 10:26:27.973;\n\
14b-355;Initialize;NULL;pdm;2014-07-09 10:26:29.010;\n\
14b-355;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 10:26:33.840;\n\
14b-355;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-09 10:27:51.903;\n\
14b-355;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-09 10:27:53.483;\n\
14b-355;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 10:27:56.670;\n\
14b-356;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 10:30:42.790;\n\
14b-505;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-09 10:53:48.740;\n\
14b-505;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-09 10:53:52.387;\n\
14b-358;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 10:54:36.907;\n\
14b-359;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 10:56:30.823;\n\
14b-360;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 10:58:14.563;\n\
14b-361;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 10:59:56.690;\n\
14b-362;RestartWorkflow;NULL;pdm;2014-07-09 11:04:16.393;\n\
14b-362;Initialize;NULL;pdm;2014-07-09 11:04:16.623;\n\
14b-362;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 11:04:21.907;\n\
14b-362;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-09 11:04:50.540;\n\
14b-362;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-09 11:04:52.147;\n\
14b-362;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 11:04:55.320;\n\
14b-365;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 11:23:28.563;\n\
14b-506;Initialize;NULL;Ansøger;2014-07-09 11:32:41.030;\n\
14b-506;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 11:32:41.030;\n\
14b-507;Initialize;NULL;Ansøger;2014-07-09 11:41:45.197;\n\
14b-507;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 11:41:45.197;\n\
14b-366;RestartWorkflow;NULL;pdm;2014-07-09 12:17:25.930;\n\
14b-366;Initialize;NULL;pdm;2014-07-09 12:17:26.180;\n\
14b-366;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 12:17:31.280;\n\
14b-366;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-09 12:18:23.243;\n\
14b-366;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-09 12:18:24.800;\n\
14b-366;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 12:18:38.143;\n\
14b-368;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 12:29:57.820;\n\
14b-369;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 12:34:46.653;\n\
14b-369;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 12:34:50.950;\n\
14b-370;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 12:41:24.340;\n\
14b-370;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 12:41:28.610;\n\
14b-508;Initialize;NULL;Ansøger;2014-07-09 12:49:09.460;\n\
14b-508;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 12:49:09.460;\n\
14b-379;RestartWorkflow;NULL;pdm;2014-07-09 13:29:54.400;\n\
14b-379;Initialize;NULL;pdm;2014-07-09 13:29:54.677;\n\
14b-379;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 13:30:00.343;\n\
14b-379;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-09 13:30:24.100;\n\
14b-379;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 13:30:28.820;\n\
14b-379;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 13:30:32.050;\n\
14b-383;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 13:44:53.273;\n\
14b-383;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 13:44:58.400;\n\
14b-386;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 13:49:06.427;\n\
14b-386;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 13:49:10.720;\n\
14b-387;RestartWorkflow;NULL;pdm;2014-07-09 14:02:16.337;\n\
14b-387;Initialize;NULL;pdm;2014-07-09 14:02:16.517;\n\
14b-387;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 14:02:21.323;\n\
14b-387;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-09 14:04:02.473;\n\
14b-387;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 14:04:07.233;\n\
14b-387;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 14:04:10.403;\n\
14b-388;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 14:06:51.520;\n\
14b-388;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 14:06:55.700;\n\
14b-389;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 14:09:09.173;\n\
14b-389;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 14:09:13.380;\n\
14b-390;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 14:11:04.360;\n\
14b-390;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 14:11:07.803;\n\
14b-391;RestartWorkflow;NULL;pdm;2014-07-09 14:13:37.807;\n\
14b-391;Initialize;NULL;pdm;2014-07-09 14:13:38.040;\n\
14b-391;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 14:13:41.930;\n\
14b-391;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-09 14:14:29.747;\n\
14b-391;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-09 14:15:17.170;\n\
14b-391;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 14:15:21.603;\n\
14b-391;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 14:15:25.287;\n\
14b-391;RestartWorkflow;NULL;pdm;2014-07-09 14:16:13.533;\n\
14b-391;Initialize;NULL;pdm;2014-07-09 14:16:13.677;\n\
14b-391;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 14:16:17.860;\n\
14b-391;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-09 14:16:36.870;\n\
14b-391;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-09 14:16:38.473;\n\
14b-391;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 14:16:41.610;\n\
14b-392;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 14:20:58.140;\n\
14b-392;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 14:21:02.620;\n\
14b-408;RestartWorkflow;NULL;pdm;2014-07-09 14:55:07.967;\n\
14b-408;Initialize;NULL;pdm;2014-07-09 14:55:08.150;\n\
14b-408;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 14:55:14.747;\n\
14b-408;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-09 14:55:29.987;\n\
14b-408;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 14:55:35.077;\n\
14b-408;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 14:55:38.863;\n\
14b-412;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 15:00:18.180;\n\
14b-412;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 15:00:22.603;\n\
14b-414;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 15:03:15.640;\n\
14b-414;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 15:03:19.853;\n\
14b-420;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 15:07:20.783;\n\
14b-421;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 15:08:05.910;\n\
14b-421;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 15:08:09.283;\n\
14b-423;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 15:11:34.983;\n\
14b-423;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 15:11:39.660;\n\
14b-424;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 15:12:34.137;\n\
14b-424;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 15:12:37.267;\n\
14b-425;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-09 15:13:28.813;\n\
14b-426;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-09 15:14:15.657;\n\
14b-426;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-09 15:14:18.917;\n\
14b-509;Initialize;NULL;Ansøger;2014-07-09 21:36:46.980;\n\
14b-509;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 21:36:46.980;\n\
14b-510;Initialize;NULL;Ansøger;2014-07-09 23:20:08.630;\n\
14b-510;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-09 23:20:08.630;\n\
14b-511;Initialize;NULL;Ansøger;2014-07-10 10:17:45.193;\n\
14b-511;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-10 10:17:45.193;\n\
14b-375;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-10 10:28:23.550;\n\
14b-375;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-10 10:28:26.893;\n\
14b-428;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-10 10:31:40.097;\n\
14b-428;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-10 10:31:44.393;\n\
14b-431;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-10 10:35:21.823;\n\
14b-431;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-10 10:35:32.127;\n\
14b-435;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-10 10:40:53.523;\n\
14b-435;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-10 10:40:57.873;\n\
14b-436;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-10 10:43:26.167;\n\
14b-436;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-10 10:43:31.127;\n\
14b-437;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-10 10:45:52.943;\n\
14b-437;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-10 10:45:57.183;\n\
14b-512;Initialize;NULL;Ansøger;2014-07-10 11:15:13.120;\n\
14b-512;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-10 11:15:13.123;\n\
14b-513;Initialize;NULL;Ansøger;2014-07-10 11:32:28.007;\n\
14b-513;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-10 11:32:28.007;\n\
14b-439;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-10 12:02:17.983;\n\
14b-439;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-10 12:02:23.360;\n\
14b-441;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-10 12:08:18.590;\n\
14b-441;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-10 12:08:23.680;\n\
14b-441;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-10 12:08:27.017;\n\
14b-442;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-10 12:10:16.950;\n\
14b-442;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-10 12:10:21.177;\n\
14b-442;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-10 12:10:24.433;\n\
14b-443;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-10 12:11:49.167;\n\
14b-443;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-10 12:11:50.773;\n\
14b-443;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-10 12:11:58.930;\n\
14b-444;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-10 12:13:31.757;\n\
14b-444;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-10 12:13:35.530;\n\
14b-444;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-10 12:13:38.783;\n\
14b-445;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-10 12:15:48.047;\n\
14b-445;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-10 12:15:58.213;\n\
14b-445;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-10 12:16:01.963;\n\
14b-446;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-10 12:17:38.693;\n\
14b-446;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-10 12:17:40.293;\n\
14b-446;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-10 12:18:13.020;\n\
14b-447;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-10 12:21:04.683;\n\
14b-447;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-10 12:21:07.367;\n\
14b-447;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-10 12:21:09.933;\n\
14b-377;DF_Udbetaling!889;NULL;ANONYMOUS LOGON;2014-07-10 13:08:53.583;\n\
14b-377;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-07-10 13:09:03.033;\n\
14b-448;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-10 13:41:35.687;\n\
14b-448;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-10 13:41:40.110;\n\
14b-448;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-10 13:41:43.027;\n\
14b-449;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-10 13:43:32.230;\n\
14b-449;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-10 13:43:35.033;\n\
14b-449;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-10 13:43:37.837;\n\
14b-450;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-10 13:49:42.550;\n\
14b-450;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-10 13:49:45.270;\n\
14b-450;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-10 13:49:48.640;\n\
14b-514;Initialize;NULL;Ansøger;2014-07-10 14:31:53.600;\n\
14b-514;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-10 14:31:53.600;\n\
14b-515;Initialize;NULL;Ansøger;2014-07-10 17:41:53.980;\n\
14b-515;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-10 17:41:53.983;\n\
14b-516;Initialize;NULL;Ansøger;2014-07-10 23:15:03.277;\n\
14b-516;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-10 23:15:03.277;\n\
14b-451;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-11 09:43:12.143;\n\
14b-451;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-11 09:43:18.423;\n\
14b-451;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-11 09:43:22.020;\n\
14b-452;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 09:45:32.237;\n\
14b-452;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 09:45:33.910;\n\
14b-452;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 09:45:37.330;\n\
14b-517;Initialize;NULL;Ansøger;2014-07-11 09:46:23.310;\n\
14b-517;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 09:46:23.310;\n\
14b-453;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 09:48:58.107;\n\
14b-453;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 09:49:00.967;\n\
14b-453;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 09:49:03.353;\n\
14b-454;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 09:50:59.117;\n\
14b-454;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 09:51:01.883;\n\
14b-454;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 09:51:04.023;\n\
14b-455;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:00:46.503;\n\
14b-455;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:00:49.230;\n\
14b-455;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:00:51.813;\n\
14b-456;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:02:38.647;\n\
14b-456;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:02:41.353;\n\
14b-456;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:02:43.490;\n\
14b-457;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-11 10:04:22.297;\n\
14b-457;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-11 10:04:26.250;\n\
14b-457;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-11 10:04:29.403;\n\
14b-458;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:06:13.603;\n\
14b-458;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:06:15.357;\n\
14b-458;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:06:18.187;\n\
14b-459;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:07:35.747;\n\
14b-459;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:07:37.407;\n\
14b-459;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:07:39.737;\n\
14b-461;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-11 10:09:37.273;\n\
14b-461;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-11 10:09:42.257;\n\
14b-461;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-11 10:09:46.630;\n\
14b-462;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:12:15.300;\n\
14b-462;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:12:17.970;\n\
14b-462;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:12:20.960;\n\
14b-463;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-11 10:13:46.090;\n\
14b-463;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-11 10:13:50.410;\n\
14b-463;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-11 10:13:53.803;\n\
14b-464;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:17:11.797;\n\
14b-464;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:17:14.457;\n\
14b-464;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:17:16.880;\n\
14b-465;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:20:06.600;\n\
14b-465;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:20:09.557;\n\
14b-465;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:20:12.100;\n\
14b-466;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:23:43.460;\n\
14b-466;RestartWorkflow;NULL;pdm;2014-07-11 10:23:51.530;\n\
14b-466;Initialize;NULL;pdm;2014-07-11 10:23:51.720;\n\
14b-466;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 10:23:55.317;\n\
14b-466;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:24:22.297;\n\
14b-466;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:24:24.063;\n\
14b-466;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:24:26.100;\n\
14b-518;Initialize;NULL;Ansøger;2014-07-11 10:26:10.780;\n\
14b-518;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 10:26:10.780;\n\
14b-467;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:26:40.300;\n\
14b-467;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:26:50.340;\n\
14b-467;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:26:57.187;\n\
14b-468;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:28:17.953;\n\
14b-468;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:28:20.320;\n\
14b-468;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:28:22.147;\n\
14b-471;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:42:23.750;\n\
14b-471;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:42:26.420;\n\
14b-471;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:42:29.003;\n\
14b-472;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 10:44:23.613;\n\
14b-472;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 10:44:26.330;\n\
14b-472;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 10:44:29.720;\n\
14b-519;Initialize;NULL;Ansøger;2014-07-11 11:01:26.440;\n\
14b-519;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 11:01:26.443;\n\
14b-473;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 11:26:08.533;\n\
14b-473;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 11:26:12.247;\n\
14b-473;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 11:26:14.390;\n\
14b-474;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:21:09.417;\n\
14b-474;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:21:13.080;\n\
14b-474;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:21:17.763;\n\
14b-520;Initialize;NULL;Ansøger;2014-07-11 12:22:10.287;\n\
14b-520;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 12:22:10.287;\n\
14b-521;Initialize;NULL;Ansøger;2014-07-11 12:22:26.657;\n\
14b-521;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 12:22:26.657;\n\
14b-475;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:24:10.760;\n\
14b-475;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:24:12.460;\n\
14b-475;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:24:15.043;\n\
14b-476;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:27:22.287;\n\
14b-476;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:27:25.003;\n\
14b-476;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:27:27.120;\n\
14b-477;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:28:47.193;\n\
14b-477;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:28:48.820;\n\
14b-477;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:28:50.643;\n\
14b-478;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:30:08.420;\n\
14b-478;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:30:10.007;\n\
14b-478;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:30:12.137;\n\
14b-479;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:31:45.057;\n\
14b-479;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:31:46.613;\n\
14b-479;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:31:48.520;\n\
14b-480;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:33:33.070;\n\
14b-480;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:33:35.827;\n\
14b-480;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:33:38.190;\n\
14b-481;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:35:27.857;\n\
14b-481;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:35:30.563;\n\
14b-481;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:35:32.633;\n\
14b-482;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:44:49.027;\n\
14b-482;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:44:51.847;\n\
14b-482;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:44:55.470;\n\
14b-483;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:48:32.160;\n\
14b-483;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:48:34.860;\n\
14b-483;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:48:36.757;\n\
14b-484;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:49:57.530;\n\
14b-484;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:49:59.100;\n\
14b-484;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:50:01.110;\n\
14b-485;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:51:43.080;\n\
14b-485;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:51:44.600;\n\
14b-485;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:51:46.540;\n\
14b-486;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-11 12:54:15.407;\n\
14b-486;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-11 12:54:20.080;\n\
14b-486;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-11 12:54:23.683;\n\
14b-487;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-11 12:56:11.640;\n\
14b-487;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-11 12:56:15.783;\n\
14b-487;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-11 12:56:19.333;\n\
14b-488;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:57:28.593;\n\
14b-488;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:57:30.247;\n\
14b-488;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:57:32.560;\n\
14b-489;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 12:58:37.517;\n\
14b-489;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 12:58:39.127;\n\
14b-489;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 12:58:41.670;\n\
14b-490;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-11 12:59:55.437;\n\
14b-490;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-11 13:00:00.277;\n\
14b-490;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-11 13:00:06.117;\n\
14b-491;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 13:01:12.010;\n\
14b-491;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 13:01:13.680;\n\
14b-491;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 13:01:16.077;\n\
14b-522;Initialize;NULL;Ansøger;2014-07-11 13:02:43.017;\n\
14b-522;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 13:02:43.017;\n\
14b-491;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 13:03:17.607;\n\
14b-492;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 13:03:50.370;\n\
14b-492;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 13:03:52.017;\n\
14b-492;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 13:03:54.900;\n\
14b-493;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 13:14:24.240;\n\
14b-493;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 13:14:26.993;\n\
14b-493;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 13:14:29.010;\n\
14b-494;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 13:20:09.620;\n\
14b-494;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 13:20:12.330;\n\
14b-494;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 13:20:15.620;\n\
14b-495;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 13:27:18.507;\n\
14b-495;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 13:27:21.183;\n\
14b-495;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 13:27:23.567;\n\
14b-496;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 13:29:54.250;\n\
14b-496;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 13:29:57.070;\n\
14b-496;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 13:29:59.147;\n\
14b-497;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 13:36:18.587;\n\
14b-497;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 13:36:21.227;\n\
14b-497;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 13:36:23.613;\n\
14b-498;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-11 13:38:16.690;\n\
14b-498;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-11 13:38:19.357;\n\
14b-498;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-11 13:38:21.603;\n\
14b-499;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-11 13:39:50.863;\n\
14b-499;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-11 13:39:55.270;\n\
14b-499;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-11 13:39:58.640;\n\
14b-523;Initialize;NULL;Ansøger;2014-07-11 13:58:41.277;\n\
14b-523;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 13:58:41.277;\n\
14b-524;Initialize;NULL;Ansøger;2014-07-11 14:41:51.993;\n\
14b-524;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 14:41:51.993;\n\
14b-525;Initialize;NULL;Ansøger;2014-07-11 15:19:26.650;\n\
14b-525;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 15:19:26.650;\n\
14b-526;Initialize;NULL;Ansøger;2014-07-11 15:38:15.917;\n\
14b-526;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 15:38:15.920;\n\
14b-527;Initialize;NULL;Ansøger;2014-07-11 16:07:21.380;\n\
14b-527;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 16:07:21.380;\n\
14b-528;Initialize;NULL;Ansøger;2014-07-11 18:05:14.883;\n\
14b-528;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 18:05:14.883;\n\
14b-529;Initialize;NULL;Ansøger;2014-07-11 20:51:44.143;\n\
14b-529;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 20:51:44.143;\n\
14b-530;Initialize;NULL;Ansøger;2014-07-11 22:01:20.233;\n\
14b-530;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-11 22:01:20.233;\n\
14b-531;Initialize;NULL;Ansøger;2014-07-12 12:51:14.660;\n\
14b-531;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-12 12:51:14.663;\n\
14b-532;Initialize;NULL;Ansøger;2014-07-12 15:11:17.563;\n\
14b-532;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-12 15:11:17.563;\n\
14b-533;Initialize;NULL;Ansøger;2014-07-12 17:50:50.343;\n\
14b-533;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-12 17:50:50.343;\n\
14b-534;Initialize;NULL;Ansøger;2014-07-12 21:10:03.200;\n\
14b-534;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-12 21:10:03.200;\n\
14b-535;Initialize;NULL;Ansøger;2014-07-12 22:25:19.797;\n\
14b-535;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-12 22:25:19.800;\n\
14b-536;Initialize;NULL;Ansøger;2014-07-13 13:30:17.277;\n\
14b-536;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-13 13:30:17.277;\n\
14b-537;Initialize;NULL;Ansøger;2014-07-13 14:23:28.050;\n\
14b-537;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-13 14:23:28.050;\n\
14b-538;Initialize;NULL;Ansøger;2014-07-13 15:40:24.623;\n\
14b-538;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-13 15:40:24.627;\n\
14b-539;Initialize;NULL;Ansøger;2014-07-13 16:50:41.600;\n\
14b-539;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-13 16:50:41.600;\n\
14b-540;Initialize;NULL;Ansøger;2014-07-13 17:41:42.503;\n\
14b-540;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-13 17:41:42.507;\n\
14b-541;Initialize;NULL;Ansøger;2014-07-13 18:12:33.433;\n\
14b-541;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-13 18:12:33.433;\n\
14b-542;Initialize;NULL;Ansøger;2014-07-13 22:01:13.953;\n\
14b-542;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-13 22:01:13.953;\n\
14b-543;Initialize;NULL;Ansøger;2014-07-13 22:03:17.143;\n\
14b-543;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-13 22:03:17.143;\n\
14b-544;Initialize;NULL;Ansøger;2014-07-13 23:29:35.300;\n\
14b-544;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-13 23:29:35.303;\n\
14b-545;Initialize;NULL;Ansøger;2014-07-14 00:58:09.783;\n\
14b-545;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 00:58:09.783;\n\
14b-546;Initialize;NULL;Ansøger;2014-07-14 08:49:56.150;\n\
14b-546;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 08:49:56.153;\n\
14b-525;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 09:23:01.720;\n\
14b-525;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 09:23:05.460;\n\
14b-525;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 09:23:07.440;\n\
14b-500;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 09:25:11.610;\n\
14b-500;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 09:25:14.283;\n\
14b-500;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 09:25:16.270;\n\
14b-501;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 09:27:32.333;\n\
14b-501;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 09:27:34.933;\n\
14b-501;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 09:27:37.037;\n\
14b-502;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 09:31:11.080;\n\
14b-502;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 09:31:13.803;\n\
14b-502;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 09:31:15.850;\n\
14b-503;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 09:33:01.970;\n\
14b-503;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 09:33:04.677;\n\
14b-503;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 09:33:06.483;\n\
14b-504;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 09:34:54.477;\n\
14b-504;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 09:34:57.833;\n\
14b-504;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 09:35:01.040;\n\
14b-505;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 09:36:00.790;\n\
14b-506;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-14 09:39:12.327;\n\
14b-506;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-14 09:39:17.533;\n\
14b-506;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-14 09:39:20.950;\n\
14b-507;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 09:39:49.797;\n\
14b-507;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 09:39:52.167;\n\
14b-507;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 09:39:53.863;\n\
14b-547;Initialize;NULL;Ansøger;2014-07-14 09:40:16.253;\n\
14b-547;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 09:40:16.253;\n\
14b-508;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 09:44:10.363;\n\
14b-508;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 09:44:13.163;\n\
14b-508;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 09:44:15.273;\n\
14b-548;Initialize;NULL;Ansøger;2014-07-14 10:04:29.470;\n\
14b-548;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 10:04:29.470;\n\
14b-509;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 10:14:37.917;\n\
14b-509;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 10:14:40.677;\n\
14b-509;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 10:14:44.150;\n\
14b-510;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 10:47:47.330;\n\
14b-510;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 10:47:52.823;\n\
14b-510;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 10:47:54.883;\n\
14b-511;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 10:48:56.390;\n\
14b-511;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 10:49:01.290;\n\
14b-511;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 10:49:03.037;\n\
14b-512;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 10:52:11.890;\n\
14b-512;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 10:52:14.687;\n\
14b-512;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 10:52:16.790;\n\
14b-513;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 10:54:13.747;\n\
14b-513;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 10:54:16.513;\n\
14b-513;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 10:54:18.423;\n\
14b-514;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 10:55:54.980;\n\
14b-514;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 10:55:56.813;\n\
14b-514;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 10:55:58.727;\n\
14b-515;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 10:58:27.493;\n\
14b-515;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 10:58:30.273;\n\
14b-515;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 10:58:32.517;\n\
14b-516;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:00:12.120;\n\
14b-516;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 11:00:14.220;\n\
14b-516;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:00:15.940;\n\
14b-549;Initialize;NULL;Ansøger;2014-07-14 11:06:20.447;\n\
14b-549;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 11:06:20.450;\n\
14b-517;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:06:44.330;\n\
14b-517;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:06:53.120;\n\
14b-517;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:06:55.660;\n\
14b-518;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:07:49.797;\n\
14b-518;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:07:51.340;\n\
14b-518;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:07:53.330;\n\
14b-519;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:08:45.003;\n\
14b-519;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:08:46.573;\n\
14b-519;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:08:48.173;\n\
14b-520;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:09:37.750;\n\
14b-520;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:09:39.340;\n\
14b-520;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:09:41.160;\n\
14b-521;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:10:51.397;\n\
14b-521;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:10:53.120;\n\
14b-521;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:10:55.240;\n\
14b-522;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:12:15.497;\n\
14b-522;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:12:17.200;\n\
14b-522;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:12:19.000;\n\
14b-523;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:13:11.107;\n\
14b-523;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:13:12.683;\n\
14b-523;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:13:14.263;\n\
14b-524;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-14 11:18:49.843;\n\
14b-524;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-14 11:18:54.507;\n\
14b-524;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-14 11:18:58.560;\n\
14b-526;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:19:50.710;\n\
14b-526;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:19:52.690;\n\
14b-526;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:19:55.307;\n\
14b-527;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:20:49.627;\n\
14b-527;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:20:51.453;\n\
14b-527;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:20:53.180;\n\
14b-528;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:24:36.887;\n\
14b-528;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:24:39.577;\n\
14b-528;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:24:41.377;\n\
14b-529;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:25:35.513;\n\
14b-529;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:25:37.110;\n\
14b-529;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:25:38.883;\n\
14b-530;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:26:26.970;\n\
14b-530;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:26:28.670;\n\
14b-530;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:26:30.267;\n\
14b-531;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:32:35.780;\n\
14b-531;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:32:38.537;\n\
14b-531;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:32:40.367;\n\
14b-532;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:33:36.913;\n\
14b-532;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:33:38.777;\n\
14b-532;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:33:42.663;\n\
14b-533;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:34:32.880;\n\
14b-533;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:34:34.713;\n\
14b-533;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:34:36.337;\n\
14b-534;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-14 11:48:15.023;\n\
14b-534;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-14 11:48:19.517;\n\
14b-534;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-07-14 11:48:23.633;\n\
14b-535;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:50:01.287;\n\
14b-535;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 11:50:03.747;\n\
14b-535;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:50:06.100;\n\
14b-536;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:51:08.820;\n\
14b-536;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:51:10.543;\n\
14b-536;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:51:12.160;\n\
14b-537;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:52:25.480;\n\
14b-537;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:52:27.333;\n\
14b-537;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:52:28.693;\n\
14b-538;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:53:29.700;\n\
14b-538;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:53:31.413;\n\
14b-538;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:53:33.427;\n\
14b-539;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:54:41.123;\n\
14b-539;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 11:54:42.963;\n\
14b-539;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:54:44.900;\n\
14b-540;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:55:56.203;\n\
14b-540;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 11:55:57.767;\n\
14b-540;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:55:59.477;\n\
14b-541;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:56:53.040;\n\
14b-541;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 11:56:54.613;\n\
14b-541;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:56:56.143;\n\
14b-542;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 11:58:08.467;\n\
14b-542;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 11:58:10.063;\n\
14b-542;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 11:58:11.827;\n\
14b-543;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-14 11:59:07.733;\n\
14b-543;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-14 11:59:13.610;\n\
14b-543;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-14 11:59:17.153;\n\
14b-540;RestartWorkflow;NULL;pdm;2014-07-14 12:03:39.120;\n\
14b-540;Initialize;NULL;ANONYMOUS LOGON;2014-07-14 12:03:39.273;\n\
14b-540;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 12:03:43.920;\n\
14b-540;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-14 12:03:53.580;\n\
14b-540;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-14 12:03:57.733;\n\
14b-540;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-14 12:04:01.110;\n\
14b-544;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-14 12:06:00.713;\n\
14b-544;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-14 12:06:06.960;\n\
14b-544;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-14 12:06:10.353;\n\
14b-545;RestartWorkflow;NULL;pdm;2014-07-14 12:08:29.340;\n\
14b-545;Initialize;NULL;ANONYMOUS LOGON;2014-07-14 12:08:29.487;\n\
14b-545;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 12:08:35.460;\n\
14b-545;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 12:08:46.017;\n\
14b-545;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 12:08:47.650;\n\
14b-545;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 12:08:49.740;\n\
14b-546;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 12:09:57.633;\n\
14b-546;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 12:09:59.303;\n\
14b-546;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 12:10:01.217;\n\
14b-547;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 12:10:49.210;\n\
14b-547;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 12:10:50.857;\n\
14b-547;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 12:10:52.313;\n\
14b-548;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 12:11:45.413;\n\
14b-548;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 12:11:47.117;\n\
14b-548;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 12:11:48.880;\n\
14b-549;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 12:12:56.787;\n\
14b-549;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-14 12:12:58.543;\n\
14b-549;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 12:13:00.243;\n\
14b-376;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 12:14:25.993;\n\
14b-376;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 12:14:27.853;\n\
14b-376;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 12:14:29.790;\n\
14b-469;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-14 12:15:39.700;\n\
14b-469;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-14 12:15:41.503;\n\
14b-469;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-14 12:15:46.033;\n\
14b-550;Initialize;NULL;Ansøger;2014-07-14 12:34:18.773;\n\
14b-550;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 12:34:18.773;\n\
14a-213;DL_DCREventTaskPersonal!25358;NULL;pdm;2014-07-14 12:42:58.987;\n\
14a-213;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-07-14 12:43:01.637;\n\
14a-246;DL_DCREventTaskPersonal!25377;NULL;pdm;2014-07-14 12:53:49.567;\n\
14a-246;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-07-14 12:53:52.620;\n\
14a-246;DL_WFCaseTypeStep!479;First payout;pdm;2014-07-14 12:56:53.207;\n\
14b-551;Initialize;NULL;Ansøger;2014-07-14 12:58:59.787;\n\
14b-551;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 12:58:59.787;\n\
14a-118;DL_DCREventTaskPersonal!25311;NULL;pdm;2014-07-14 13:05:51.683;\n\
14a-118;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-07-14 13:05:54.600;\n\
14b-552;Initialize;NULL;Ansøger;2014-07-14 13:19:30.093;\n\
14b-552;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 13:19:30.097;\n\
14a-055;DL_DCREventTaskPersonal!25283;NULL;pdm;2014-07-14 13:58:34.030;\n\
14a-055;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-07-14 13:58:37.793;\n\
14b-553;Initialize;NULL;Ansøger;2014-07-14 13:59:57.260;\n\
14b-553;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 13:59:57.260;\n\
14a-055;DL_WFCaseTypeStep!479;First payout;pdm;2014-07-14 14:01:10.487;\n\
14b-554;Initialize;NULL;Ansøger;2014-07-14 14:12:59.560;\n\
14b-554;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 14:12:59.560;\n\
14a-258;DL_DCREventTaskPersonal!25381;NULL;pdm;2014-07-14 14:27:12.410;\n\
14a-258;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-07-14 14:27:15.290;\n\
14a-258;DL_WFCaseTypeStep!479;First payout;pdm;2014-07-14 14:30:06.960;\n\
14b-555;Initialize;NULL;Ansøger;2014-07-14 14:41:01.473;\n\
14b-555;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 14:41:01.473;\n\
14b-556;Initialize;NULL;Ansøger;2014-07-14 14:47:55.650;\n\
14b-556;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 14:47:55.653;\n\
14b-557;Initialize;NULL;Ansøger;2014-07-14 15:01:20.000;\n\
14b-557;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 15:01:20.000;\n\
14b-558;Initialize;NULL;Ansøger;2014-07-14 15:02:48.053;\n\
14b-558;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 15:02:48.053;\n\
14b-559;Initialize;NULL;Ansøger;2014-07-14 15:25:59.007;\n\
14b-559;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 15:25:59.010;\n\
14b-560;Initialize;NULL;Ansøger;2014-07-14 15:48:39.847;\n\
14b-560;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 15:48:39.850;\n\
14b-561;Initialize;NULL;Ansøger;2014-07-14 16:11:12.270;\n\
14b-561;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 16:11:12.270;\n\
14b-562;Initialize;NULL;Ansøger;2014-07-14 16:22:32.710;\n\
14b-562;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 16:22:32.713;\n\
14b-563;Initialize;NULL;Ansøger;2014-07-14 16:25:41.507;\n\
14b-563;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 16:25:41.507;\n\
14b-564;Initialize;NULL;Ansøger;2014-07-14 16:52:12.133;\n\
14b-564;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 16:52:12.133;\n\
14b-565;Initialize;NULL;Ansøger;2014-07-14 18:02:55.253;\n\
14b-565;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 18:02:55.253;\n\
14b-566;Initialize;NULL;Ansøger;2014-07-14 18:05:28.863;\n\
14b-566;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 18:05:28.863;\n\
14b-567;Initialize;NULL;Ansøger;2014-07-14 18:10:44.993;\n\
14b-567;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 18:10:44.993;\n\
14b-568;Initialize;NULL;Ansøger;2014-07-14 18:13:49.540;\n\
14b-568;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 18:13:49.540;\n\
14b-569;Initialize;NULL;Ansøger;2014-07-14 18:20:57.400;\n\
14b-569;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 18:20:57.400;\n\
14b-570;Initialize;NULL;Ansøger;2014-07-14 18:49:31.587;\n\
14b-570;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 18:49:31.587;\n\
14b-571;Initialize;NULL;Ansøger;2014-07-14 19:02:43.783;\n\
14b-571;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 19:02:43.783;\n\
14b-572;Initialize;NULL;Ansøger;2014-07-14 19:28:02.253;\n\
14b-572;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 19:28:02.253;\n\
14b-573;Initialize;NULL;Ansøger;2014-07-14 19:40:45.260;\n\
14b-573;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 19:40:45.260;\n\
14b-574;Initialize;NULL;Ansøger;2014-07-14 20:44:21.840;\n\
14b-574;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 20:44:21.840;\n\
14b-575;Initialize;NULL;Ansøger;2014-07-14 20:54:50.240;\n\
14b-575;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 20:54:50.240;\n\
14b-576;Initialize;NULL;Ansøger;2014-07-14 21:37:10.883;\n\
14b-576;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 21:37:10.887;\n\
14b-577;Initialize;NULL;Ansøger;2014-07-14 21:53:08.140;\n\
14b-577;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 21:53:08.140;\n\
14b-578;Initialize;NULL;Ansøger;2014-07-14 21:58:06.567;\n\
14b-578;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 21:58:06.567;\n\
14b-579;Initialize;NULL;Ansøger;2014-07-14 22:35:44.557;\n\
14b-579;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 22:35:44.557;\n\
14b-580;Initialize;NULL;Ansøger;2014-07-14 23:14:48.700;\n\
14b-580;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 23:14:48.700;\n\
14b-581;Initialize;NULL;Ansøger;2014-07-14 23:30:46.230;\n\
14b-581;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 23:30:46.230;\n\
14b-582;Initialize;NULL;Ansøger;2014-07-14 23:33:29.567;\n\
14b-582;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 23:33:29.567;\n\
14b-583;Initialize;NULL;Ansøger;2014-07-14 23:35:00.137;\n\
14b-583;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-14 23:35:00.137;\n\
14b-584;Initialize;NULL;Ansøger;2014-07-15 00:21:58.820;\n\
14b-584;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 00:21:58.823;\n\
14b-585;Initialize;NULL;Ansøger;2014-07-15 00:34:00.840;\n\
14b-585;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 00:34:00.840;\n\
14b-586;Initialize;NULL;Ansøger;2014-07-15 00:35:28.243;\n\
14b-586;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 00:35:28.243;\n\
14b-587;Initialize;NULL;Ansøger;2014-07-15 01:57:04.610;\n\
14b-587;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 01:57:04.610;\n\
14b-588;Initialize;NULL;Ansøger;2014-07-15 08:31:58.790;\n\
14b-588;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 08:31:58.793;\n\
14b-589;Initialize;NULL;Ansøger;2014-07-15 08:51:35.713;\n\
14b-589;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 08:51:35.713;\n\
14b-559;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-15 09:15:26.860;\n\
14b-559;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-15 09:15:32.093;\n\
14b-559;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-15 09:15:35.973;\n\
14b-460;RestartWorkflow;NULL;pdm;2014-07-15 09:43:53.017;\n\
14b-460;Initialize;NULL;pdm;2014-07-15 09:43:53.370;\n\
14b-460;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 09:43:59.680;\n\
14b-460;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 09:44:28.200;\n\
14b-460;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 09:44:29.780;\n\
14b-460;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 09:44:31.753;\n\
14b-460;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-07-15 09:45:35.273;\n\
14b-460;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-07-15 09:46:02.057;\n\
14b-590;Initialize;NULL;Ansøger;2014-07-15 10:01:39.290;\n\
14b-590;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 10:01:39.290;\n\
14b-591;Initialize;NULL;Ansøger;2014-07-15 10:31:17.917;\n\
14b-591;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 10:31:17.920;\n\
14b-592;Initialize;NULL;Ansøger;2014-07-15 10:33:56.797;\n\
14b-592;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 10:33:56.797;\n\
14b-593;Initialize;NULL;Ansøger;2014-07-15 11:07:34.947;\n\
14b-593;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 11:07:34.950;\n\
14b-550;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 11:11:05.480;\n\
14b-550;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 11:11:08.087;\n\
14b-550;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 11:11:10.907;\n\
14b-551;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 11:12:32.593;\n\
14b-551;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 11:12:34.190;\n\
14b-551;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 11:12:36.707;\n\
14b-552;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 11:13:35.297;\n\
14b-552;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 11:13:37.017;\n\
14b-552;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 11:13:39.660;\n\
14b-594;Initialize;NULL;Ansøger;2014-07-15 11:18:38.130;\n\
14b-594;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 11:18:38.130;\n\
14b-595;Initialize;NULL;Ansøger;2014-07-15 11:23:12.790;\n\
14b-595;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 11:23:12.790;\n\
14b-553;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 11:24:15.797;\n\
14b-553;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 11:24:17.357;\n\
14b-553;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 11:24:19.203;\n\
14b-554;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-15 11:25:50.293;\n\
14b-554;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 11:25:53.457;\n\
14b-554;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 11:25:55.257;\n\
14b-555;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-15 11:26:56.517;\n\
14b-555;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 11:26:59.317;\n\
14b-555;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 11:27:01.177;\n\
14b-556;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 11:27:58.633;\n\
14b-556;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 11:28:00.867;\n\
14b-556;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 11:28:03.187;\n\
14b-557;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-15 11:34:18.577;\n\
14b-557;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-15 11:34:23.987;\n\
14b-557;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-07-15 11:34:27.673;\n\
14b-558;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 11:35:24.567;\n\
14b-558;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-15 11:35:26.130;\n\
14b-558;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 11:35:28.297;\n\
14b-596;Initialize;NULL;Ansøger;2014-07-15 11:37:58.537;\n\
14b-596;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 11:37:58.537;\n\
14b-560;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:11:52.517;\n\
14b-560;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:11:56.160;\n\
14b-560;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:11:58.190;\n\
14b-561;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:14:21.750;\n\
14b-561;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:14:24.363;\n\
14b-561;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:14:26.550;\n\
14b-562;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:21:08.710;\n\
14b-562;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:21:11.297;\n\
14b-562;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:21:13.357;\n\
14b-563;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-15 12:22:44.633;\n\
14b-563;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-15 12:22:49.680;\n\
14b-563;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-15 12:22:53.110;\n\
14b-564;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:23:44.197;\n\
14b-564;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:23:45.750;\n\
14b-564;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:23:47.660;\n\
14b-565;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:24:35.947;\n\
14b-565;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:24:37.493;\n\
14b-565;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:24:40.887;\n\
14b-597;Initialize;NULL;Ansøger;2014-07-15 12:26:10.087;\n\
14b-597;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 12:26:10.087;\n\
14b-598;Initialize;NULL;Ansøger;2014-07-15 12:27:46.373;\n\
14b-598;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 12:27:46.373;\n\
14b-566;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:27:56.937;\n\
14b-566;DL_WFCaseTypeStep!518;Change phase to review;suser;2014-07-15 12:28:06.417;\n\
14b-566;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:28:10.027;\n\
14b-567;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:29:17.823;\n\
14b-567;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:29:19.433;\n\
14b-567;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:29:21.137;\n\
14b-568;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:30:18.633;\n\
14b-568;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:30:20.227;\n\
14b-568;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:30:21.933;\n\
14b-569;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:31:18.730;\n\
14b-569;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:31:20.483;\n\
14b-569;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:31:22.280;\n\
14b-570;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:32:24.257;\n\
14b-570;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:32:25.980;\n\
14b-570;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:32:27.597;\n\
14b-571;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:33:08.533;\n\
14b-571;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:33:10.120;\n\
14b-571;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:33:11.663;\n\
14b-572;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:34:14.247;\n\
14b-572;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:34:15.913;\n\
14b-572;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:34:18.283;\n\
14b-573;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-15 12:35:25.433;\n\
14b-573;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-15 12:35:30.343;\n\
14b-573;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-15 12:35:33.633;\n\
14b-574;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:37:13.463;\n\
14b-574;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:37:15.373;\n\
14b-574;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:37:17.090;\n\
14b-575;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:38:23.843;\n\
14b-575;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:38:25.443;\n\
14b-575;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:38:27.307;\n\
14b-576;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-15 12:39:31.047;\n\
14b-576;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:39:34.033;\n\
14b-576;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:39:36.277;\n\
14b-577;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:40:16.783;\n\
14b-577;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:40:18.417;\n\
14b-577;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:40:20.207;\n\
14b-578;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:46:09.370;\n\
14b-578;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:46:12.020;\n\
14b-578;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:46:15.373;\n\
14b-579;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 12:47:22.720;\n\
14b-579;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 12:47:24.310;\n\
14b-579;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 12:47:26.220;\n\
14b-599;Initialize;NULL;Ansøger;2014-07-15 12:53:24.103;\n\
14b-599;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 12:53:24.107;\n\
14b-600;Initialize;NULL;Ansøger;2014-07-15 12:53:52.320;\n\
14b-600;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 12:53:52.320;\n\
14b-580;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:08:54.257;\n\
14b-580;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:08:57.120;\n\
14b-580;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:08:59.497;\n\
14b-581;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:09:50.927;\n\
14b-581;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:09:52.530;\n\
14b-581;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:09:54.257;\n\
14b-582;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:10:43.930;\n\
14b-582;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:10:45.540;\n\
14b-582;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:10:47.327;\n\
14b-601;Initialize;NULL;Ansøger;2014-07-15 13:11:21.710;\n\
14b-601;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 13:11:21.710;\n\
14b-583;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:11:26.210;\n\
14b-583;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:11:34.210;\n\
14b-583;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:11:36.367;\n\
14b-584;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:12:25.387;\n\
14b-584;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:12:27.100;\n\
14b-584;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:12:29.117;\n\
14b-585;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:13:13.827;\n\
14b-585;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:13:15.603;\n\
14b-585;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:13:16.993;\n\
14b-586;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:13:56.780;\n\
14b-586;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:13:58.377;\n\
14b-586;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:13:59.943;\n\
14b-587;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:14:41.363;\n\
14b-587;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:14:42.977;\n\
14b-587;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:14:44.997;\n\
14b-588;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-15 13:16:03.963;\n\
14b-588;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-15 13:16:09.913;\n\
14b-588;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-15 13:16:14.260;\n\
14b-589;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:18:02.123;\n\
14b-589;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:18:03.743;\n\
14b-590;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:19:15.753;\n\
14b-590;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:19:17.353;\n\
14b-590;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:19:19.023;\n\
14b-591;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:31:46.077;\n\
14b-591;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:31:48.767;\n\
14b-591;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:31:50.403;\n\
14b-592;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:32:33.410;\n\
14b-592;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:32:34.980;\n\
14b-592;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:32:36.600;\n\
14b-593;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:33:54.270;\n\
14b-593;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:33:55.857;\n\
14b-593;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:34:00.140;\n\
14b-594;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:35:09.050;\n\
14b-594;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:35:10.680;\n\
14b-594;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:35:12.597;\n\
14b-595;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:35:54.003;\n\
14b-595;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:35:55.640;\n\
14b-595;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:35:57.110;\n\
14b-596;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:36:33.977;\n\
14b-596;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:36:35.690;\n\
14b-596;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:36:37.870;\n\
14b-597;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:37:16.853;\n\
14b-597;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:37:18.617;\n\
14b-597;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:37:20.760;\n\
14b-598;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:38:13.587;\n\
14b-598;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:38:15.193;\n\
14b-598;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:38:17.133;\n\
14b-599;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:39:08.923;\n\
14b-599;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:39:10.517;\n\
14b-599;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:39:12.200;\n\
14b-600;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:39:48.613;\n\
14b-600;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:39:50.283;\n\
14b-600;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:39:51.637;\n\
14b-601;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-15 13:40:27.833;\n\
14b-601;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-15 13:40:29.510;\n\
14b-601;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-15 13:40:31.173;\n\
14b-602;Initialize;NULL;Ansøger;2014-07-15 14:45:33.737;\n\
14b-602;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 14:45:33.737;\n\
14b-603;Initialize;NULL;Ansøger;2014-07-15 14:56:09.973;\n\
14b-603;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 14:56:09.973;\n\
14b-604;Initialize;NULL;Ansøger;2014-07-15 14:58:53.887;\n\
14b-604;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 14:58:53.890;\n\
14b-605;Initialize;NULL;Ansøger;2014-07-15 18:37:19.050;\n\
14b-605;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 18:37:19.050;\n\
14b-606;Initialize;NULL;Ansøger;2014-07-15 18:42:15.383;\n\
14b-606;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 18:42:15.383;\n\
14b-607;Initialize;NULL;Ansøger;2014-07-15 18:44:36.690;\n\
14b-607;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 18:44:36.690;\n\
14b-608;Initialize;NULL;Ansøger;2014-07-15 19:03:56.030;\n\
14b-608;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 19:03:56.033;\n\
14b-609;Initialize;NULL;Ansøger;2014-07-15 19:15:38.880;\n\
14b-609;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 19:15:38.880;\n\
14b-610;Initialize;NULL;Ansøger;2014-07-15 19:18:25.393;\n\
14b-610;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 19:18:25.393;\n\
14b-611;Initialize;NULL;Ansøger;2014-07-15 19:30:39.923;\n\
14b-611;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 19:30:39.923;\n\
14b-612;Initialize;NULL;Ansøger;2014-07-15 19:55:33.320;\n\
14b-612;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 19:55:33.320;\n\
14b-613;Initialize;NULL;Ansøger;2014-07-15 20:33:04.727;\n\
14b-613;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 20:33:04.727;\n\
14b-614;Initialize;NULL;Ansøger;2014-07-15 20:57:39.890;\n\
14b-614;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 20:57:39.890;\n\
14b-615;Initialize;NULL;Ansøger;2014-07-15 21:03:37.287;\n\
14b-615;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 21:03:37.287;\n\
14b-616;Initialize;NULL;Ansøger;2014-07-15 21:14:14.540;\n\
14b-616;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 21:14:14.540;\n\
14b-617;Initialize;NULL;Ansøger;2014-07-15 22:12:25.303;\n\
14b-617;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 22:12:25.307;\n\
14b-618;Initialize;NULL;Ansøger;2014-07-15 22:22:13.313;\n\
14b-618;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 22:22:13.313;\n\
14b-619;Initialize;NULL;Ansøger;2014-07-15 22:29:26.523;\n\
14b-619;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 22:29:26.523;\n\
14b-620;Initialize;NULL;Ansøger;2014-07-15 22:55:21.000;\n\
14b-620;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 22:55:21.000;\n\
14b-621;Initialize;NULL;Ansøger;2014-07-15 23:36:25.283;\n\
14b-621;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 23:36:25.290;\n\
14b-622;Initialize;NULL;Ansøger;2014-07-15 23:38:49.317;\n\
14b-622;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 23:38:49.317;\n\
14b-623;Initialize;NULL;Ansøger;2014-07-15 23:45:38.820;\n\
14b-623;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 23:45:38.820;\n\
14b-624;Initialize;NULL;Ansøger;2014-07-15 23:52:12.387;\n\
14b-624;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-15 23:52:12.390;\n\
14b-625;Initialize;NULL;Ansøger;2014-07-16 00:00:12.047;\n\
14b-625;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-16 00:00:12.047;\n\
14b-626;Initialize;NULL;Ansøger;2014-07-16 01:16:25.150;\n\
14b-626;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-16 01:16:25.150;\n\
14b-627;Initialize;NULL;Ansøger;2014-07-16 04:53:47.173;\n\
14b-627;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-16 04:53:47.173;\n\
14b-465;RestartWorkflow;NULL;pdm;2014-07-17 13:27:20.430;\n\
14b-465;Initialize;NULL;pdm;2014-07-17 13:27:21.287;\n\
14b-465;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-17 13:27:28.507;\n\
14b-465;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 13:28:05.833;\n\
14b-465;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 13:28:07.337;\n\
14b-465;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 13:28:09.537;\n\
14b-602;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 14:43:35.940;\n\
14b-602;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 14:43:40.103;\n\
14b-602;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 14:43:42.407;\n\
14b-603;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-17 14:45:46.100;\n\
14b-603;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-17 14:45:52.057;\n\
14b-603;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-17 14:45:55.547;\n\
14b-604;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:07:48.683;\n\
14b-604;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:07:52.667;\n\
14b-604;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:07:55.590;\n\
14b-605;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:09:45.010;\n\
14b-605;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:09:48.067;\n\
14b-605;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:09:49.870;\n\
14b-606;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:10:37.520;\n\
14b-606;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:10:40.013;\n\
14b-606;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:10:41.987;\n\
14b-607;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:11:53.407;\n\
14b-607;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:11:55.560;\n\
14b-607;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:11:57.260;\n\
14b-608;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:12:40.483;\n\
14b-608;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:12:43.467;\n\
14b-608;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:12:45.470;\n\
14b-609;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:13:29.980;\n\
14b-609;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:13:31.863;\n\
14b-609;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:13:34.833;\n\
14b-610;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:14:11.973;\n\
14b-610;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:14:13.807;\n\
14b-610;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:14:15.657;\n\
14b-611;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:14:48.177;\n\
14b-611;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:14:50.040;\n\
14b-611;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:14:51.720;\n\
14b-612;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:15:54.923;\n\
14b-612;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:15:56.757;\n\
14b-612;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:15:58.390;\n\
14b-613;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:28:26.520;\n\
14b-613;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:28:29.490;\n\
14b-613;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:28:31.357;\n\
14b-614;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:29:07.620;\n\
14b-614;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:29:09.357;\n\
14b-614;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:29:11.313;\n\
14b-615;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-17 15:30:11.813;\n\
14b-615;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-17 15:30:16.307;\n\
14b-615;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-17 15:30:19.620;\n\
14b-616;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-17 15:31:53.787;\n\
14b-616;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-17 15:31:58.380;\n\
14b-616;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-17 15:32:02.047;\n\
14b-617;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:33:49.483;\n\
14b-617;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:33:51.097;\n\
14b-617;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:33:52.783;\n\
14b-618;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:35:05.737;\n\
14b-618;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:35:07.453;\n\
14b-618;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:35:10.000;\n\
14b-619;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:35:55.680;\n\
14b-619;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:35:57.330;\n\
14b-619;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:35:59.613;\n\
14b-620;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:36:53.870;\n\
14b-620;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:36:55.567;\n\
14b-620;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:36:57.033;\n\
14b-621;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:37:48.673;\n\
14b-621;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:37:50.300;\n\
14b-621;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:37:52.013;\n\
14b-622;DL_WFCaseTypeStep!470;Initial rejection;pdm;2014-07-17 15:38:37.370;\n\
14b-622;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-07-17 15:38:41.053;\n\
14b-622;DL_WFCaseTypeStep!538;Change phase to Abort;pdm;2014-07-17 15:38:44.280;\n\
14b-623;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:40:36.017;\n\
14b-623;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:40:37.607;\n\
14b-623;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:40:39.100;\n\
14b-624;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:42:06.367;\n\
14b-624;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:42:08.127;\n\
14b-624;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:42:10.607;\n\
14b-625;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:42:55.377;\n\
14b-625;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:42:57.043;\n\
14b-625;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:42:58.870;\n\
14b-626;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:43:37.667;\n\
14b-626;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:43:39.253;\n\
14b-626;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:43:41.273;\n\
14b-627;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-17 15:51:58.330;\n\
14b-627;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-17 15:52:01.567;\n\
14b-627;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-17 15:52:04.110;\n\
14b-628;Initialize;NULL;Ansøger;2014-07-17 16:22:37.157;\n\
14b-628;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-17 16:22:37.157;\n\
14b-460;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-07-18 09:36:29.767;\n\
14b-460;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-07-18 09:37:05.210;\n\
14b-460;DL_WFCaseTypeStep!550;Carry out lapses;pdm;2014-07-18 09:47:47.123;\n\
14b-460;DL_WFCaseTypeStep!551;Change Phase to Lapsed;pdm;2014-07-18 09:47:54.853;\n\
14b-460;DL_WFCaseTypeStep!550;Carry out lapses;pdm;2014-07-18 09:48:30.917;\n\
14b-460;DL_WFCaseTypeStep!551;Change Phase to Lapsed;pdm;2014-07-18 09:48:33.403;\n\
14b-460;RestartWorkflow;NULL;pdm;2014-07-18 09:48:46.853;\n\
14b-460;Initialize;NULL;pdm;2014-07-18 09:48:47.280;\n\
14b-460;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-18 09:48:54.583;\n\
14b-628;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-07-18 10:54:06.373;\n\
14b-628;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-07-18 10:54:10.260;\n\
14b-628;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-07-18 10:54:11.543;\n\
14b-629;Initialize;NULL;Ansøger;2014-07-21 15:36:21.203;\n\
14b-629;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-21 15:36:21.207;\n\
14b-558;RestartWorkflow;NULL;morten;2014-07-29 15:17:11.380;\n\
14b-558;Initialize;NULL;ANONYMOUS LOGON;2014-07-29 15:17:11.643;\n\
14b-558;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:17:18.187;\n\
14b-558;DL_WFCaseTypeStep!469;Approval - on to the board;morten;2014-07-29 15:17:35.587;\n\
14b-558;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-29 15:17:38.050;\n\
14b-558;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;morten;2014-07-29 15:17:45.660;\n\
14b-558;RestartWorkflow;NULL;Morten;2014-07-29 15:31:59.670;\n\
14b-558;Initialize;NULL;ANONYMOUS LOGON;2014-07-29 15:31:59.830;\n\
14b-558;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:32:06.030;\n\
14b-558;DL_WFCaseTypeStep!469;Approval - on to the board;Morten;2014-07-29 15:32:14.850;\n\
14b-558;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-29 15:32:17.570;\n\
14b-558;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;Morten;2014-07-29 15:32:20.027;\n\
14b-355;RestartWorkflow;NULL;Morten;2014-07-29 15:40:19.703;\n\
14b-355;Initialize;NULL;ANONYMOUS LOGON;2014-07-29 15:40:19.893;\n\
14b-355;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:40:24.987;\n\
14b-355;DL_WFCaseTypeStep!469;Approval - on to the board;Morten;2014-07-29 15:40:33.847;\n\
14b-355;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-29 15:40:36.207;\n\
14b-355;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;Morten;2014-07-29 15:40:38.143;\n\
14b-465;RestartWorkflow;NULL;mmq;2014-07-29 15:53:19.503;\n\
14b-465;Initialize;NULL;administrator;2014-07-29 15:53:19.767;\n\
14b-465;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:53:26.797;\n\
14b-465;AddEvent;NULL;mmq;2014-07-29 15:53:28.960;\n\
14b-465;AddEvent;NULL;mmq;2014-07-29 15:53:30.373;\n\
14b-465;DL_WFCaseTypeStep!469;Approval - on to the board;Morten;2014-07-29 15:55:20.380;\n\
14b-465;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-07-29 15:55:23.813;\n\
14b-465;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;Morten;2014-07-29 15:55:26.667;\n\
14b-465;RestartWorkflow;NULL;mmq;2014-07-29 15:56:39.300;\n\
14b-465;Initialize;NULL;administrator;2014-07-29 15:56:39.460;\n\
14b-465;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:56:44.147;\n\
14b-465;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:56:46.650;\n\
14b-465;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:56:49.347;\n\
14b-465;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:56:49.383;\n\
14b-362;RestartWorkflow;NULL;mmq;2014-07-29 15:57:46.337;\n\
14b-362;Initialize;NULL;administrator;2014-07-29 15:57:46.550;\n\
14b-362;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:57:50.723;\n\
14b-362;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:57:52.810;\n\
14b-362;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:57:54.960;\n\
14b-362;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:57:55.000;\n\
14b-366;RestartWorkflow;NULL;mmq;2014-07-29 15:57:57.163;\n\
14b-366;Initialize;NULL;administrator;2014-07-29 15:57:57.383;\n\
14b-366;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:58:01.293;\n\
14b-366;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:58:04.493;\n\
14b-366;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:58:06.500;\n\
14b-366;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:58:06.523;\n\
14b-391;RestartWorkflow;NULL;mmq;2014-07-29 15:58:08.353;\n\
14b-391;Initialize;NULL;administrator;2014-07-29 15:58:08.587;\n\
14b-391;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:58:12.810;\n\
14b-391;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:58:15.713;\n\
14b-391;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:58:18.903;\n\
14b-391;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:58:19.043;\n\
14b-429;RestartWorkflow;NULL;mmq;2014-07-29 15:58:20.803;\n\
14b-429;Initialize;NULL;administrator;2014-07-29 15:58:21.070;\n\
14b-429;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:58:24.927;\n\
14b-429;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:58:26.907;\n\
14b-429;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:58:28.907;\n\
14b-429;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:58:28.940;\n\
14b-430;RestartWorkflow;NULL;mmq;2014-07-29 15:58:30.370;\n\
14b-430;Initialize;NULL;administrator;2014-07-29 15:58:30.533;\n\
14b-430;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:58:35.700;\n\
14b-430;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:58:38.417;\n\
14b-430;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:58:40.120;\n\
14b-430;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:58:40.147;\n\
14b-432;RestartWorkflow;NULL;mmq;2014-07-29 15:58:42.247;\n\
14b-432;Initialize;NULL;administrator;2014-07-29 15:58:42.550;\n\
14b-432;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:58:46.370;\n\
14b-432;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:58:48.737;\n\
14b-432;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:58:50.560;\n\
14b-432;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:58:50.590;\n\
14b-433;RestartWorkflow;NULL;mmq;2014-07-29 15:58:52.043;\n\
14b-433;Initialize;NULL;administrator;2014-07-29 15:58:52.247;\n\
14b-433;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:58:56.750;\n\
14b-433;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:58:58.510;\n\
14b-433;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:59:00.223;\n\
14b-433;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:59:00.260;\n\
14b-434;RestartWorkflow;NULL;mmq;2014-07-29 15:59:02.547;\n\
14b-434;Initialize;NULL;administrator;2014-07-29 15:59:02.870;\n\
14b-434;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:59:06.667;\n\
14b-434;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:59:08.810;\n\
14b-434;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:59:10.507;\n\
14b-434;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:59:10.533;\n\
14b-438;RestartWorkflow;NULL;mmq;2014-07-29 15:59:11.897;\n\
14b-438;Initialize;NULL;administrator;2014-07-29 15:59:12.083;\n\
14b-438;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:59:15.873;\n\
14b-438;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:59:17.530;\n\
14b-438;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:59:19.517;\n\
14b-438;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:59:19.543;\n\
14b-440;RestartWorkflow;NULL;mmq;2014-07-29 15:59:20.927;\n\
14b-440;Initialize;NULL;administrator;2014-07-29 15:59:21.100;\n\
14b-440;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:59:24.913;\n\
14b-440;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:59:26.647;\n\
14b-440;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:59:28.310;\n\
14b-440;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:59:28.337;\n\
14b-443;RestartWorkflow;NULL;mmq;2014-07-29 15:59:29.720;\n\
14b-443;Initialize;NULL;administrator;2014-07-29 15:59:29.960;\n\
14b-443;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:59:33.563;\n\
14b-443;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:59:35.187;\n\
14b-443;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:59:36.967;\n\
14b-443;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:59:36.990;\n\
14b-446;RestartWorkflow;NULL;mmq;2014-07-29 15:59:38.387;\n\
14b-446;Initialize;NULL;administrator;2014-07-29 15:59:38.600;\n\
14b-446;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:59:42.273;\n\
14b-446;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:59:44.653;\n\
14b-446;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:59:46.330;\n\
14b-446;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:59:46.357;\n\
14b-447;RestartWorkflow;NULL;mmq;2014-07-29 15:59:48.013;\n\
14b-447;Initialize;NULL;administrator;2014-07-29 15:59:48.200;\n\
14b-447;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 15:59:52.383;\n\
14b-447;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 15:59:54.067;\n\
14b-447;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 15:59:55.820;\n\
14b-447;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 15:59:55.847;\n\
14b-448;RestartWorkflow;NULL;mmq;2014-07-29 15:59:57.297;\n\
14b-448;Initialize;NULL;administrator;2014-07-29 15:59:57.627;\n\
14b-448;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:00:02.820;\n\
14b-448;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:00:04.900;\n\
14b-448;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:00:06.707;\n\
14b-448;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:00:06.733;\n\
14b-449;RestartWorkflow;NULL;mmq;2014-07-29 16:00:08.100;\n\
14b-449;Initialize;NULL;administrator;2014-07-29 16:00:08.343;\n\
14b-449;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:00:11.680;\n\
14b-449;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:00:13.390;\n\
14b-449;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:00:15.183;\n\
14b-449;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:00:15.233;\n\
14b-450;RestartWorkflow;NULL;mmq;2014-07-29 16:00:16.643;\n\
14b-450;Initialize;NULL;administrator;2014-07-29 16:00:16.803;\n\
14b-450;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:00:20.497;\n\
14b-450;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:00:22.250;\n\
14b-450;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:00:23.950;\n\
14b-450;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:00:23.973;\n\
14b-452;RestartWorkflow;NULL;mmq;2014-07-29 16:00:25.333;\n\
14b-452;Initialize;NULL;administrator;2014-07-29 16:00:25.500;\n\
14b-452;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:00:29.193;\n\
14b-452;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:00:30.903;\n\
14b-452;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:00:32.807;\n\
14b-452;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:00:32.833;\n\
14b-453;RestartWorkflow;NULL;mmq;2014-07-29 16:00:34.207;\n\
14b-453;Initialize;NULL;administrator;2014-07-29 16:00:34.437;\n\
14b-453;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:00:38.123;\n\
14b-453;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:00:39.883;\n\
14b-453;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:00:41.610;\n\
14b-453;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:00:41.640;\n\
14b-454;RestartWorkflow;NULL;mmq;2014-07-29 16:00:43.033;\n\
14b-454;Initialize;NULL;administrator;2014-07-29 16:00:43.180;\n\
14b-454;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:00:47.420;\n\
14b-454;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:00:49.090;\n\
14b-454;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:00:50.907;\n\
14b-454;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:00:50.930;\n\
14b-455;RestartWorkflow;NULL;mmq;2014-07-29 16:00:52.340;\n\
14b-455;Initialize;NULL;administrator;2014-07-29 16:00:52.527;\n\
14b-455;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:00:56.487;\n\
14b-455;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:00:58.370;\n\
14b-455;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:01:00.080;\n\
14b-455;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:01:00.103;\n\
14b-456;RestartWorkflow;NULL;mmq;2014-07-29 16:01:01.693;\n\
14b-456;Initialize;NULL;administrator;2014-07-29 16:01:01.917;\n\
14b-456;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:01:06.003;\n\
14b-456;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:01:07.700;\n\
14b-456;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:01:09.590;\n\
14b-456;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:01:09.613;\n\
14b-458;RestartWorkflow;NULL;mmq;2014-07-29 16:01:11.030;\n\
14b-458;Initialize;NULL;administrator;2014-07-29 16:01:11.293;\n\
14b-458;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:01:15.003;\n\
14b-458;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:01:16.620;\n\
14b-458;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:01:18.337;\n\
14b-458;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:01:18.363;\n\
14b-459;RestartWorkflow;NULL;mmq;2014-07-29 16:01:19.733;\n\
14b-459;Initialize;NULL;administrator;2014-07-29 16:01:19.923;\n\
14b-459;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:01:23.527;\n\
14b-459;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:01:25.300;\n\
14b-459;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:01:26.987;\n\
14b-459;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:01:27.010;\n\
14b-462;RestartWorkflow;NULL;mmq;2014-07-29 16:01:28.413;\n\
14b-462;Initialize;NULL;administrator;2014-07-29 16:01:28.570;\n\
14b-462;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:01:32.307;\n\
14b-462;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:01:33.937;\n\
14b-462;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:01:35.790;\n\
14b-462;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:01:35.817;\n\
14b-464;RestartWorkflow;NULL;mmq;2014-07-29 16:01:37.367;\n\
14b-464;Initialize;NULL;administrator;2014-07-29 16:01:37.660;\n\
14b-464;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:01:42.157;\n\
14b-464;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:01:44.127;\n\
14b-464;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:01:45.987;\n\
14b-464;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:01:46.010;\n\
14b-466;RestartWorkflow;NULL;mmq;2014-07-29 16:01:47.363;\n\
14b-466;Initialize;NULL;administrator;2014-07-29 16:01:47.573;\n\
14b-466;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:01:51.160;\n\
14b-466;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:01:52.933;\n\
14b-466;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:01:54.893;\n\
14b-466;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:01:54.930;\n\
14b-467;RestartWorkflow;NULL;mmq;2014-07-29 16:01:56.410;\n\
14b-467;Initialize;NULL;administrator;2014-07-29 16:01:56.600;\n\
14b-467;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:02:00.390;\n\
14b-467;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:02:02.880;\n\
14b-467;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:02:04.573;\n\
14b-467;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:02:04.600;\n\
14b-468;RestartWorkflow;NULL;mmq;2014-07-29 16:02:05.957;\n\
14b-468;Initialize;NULL;administrator;2014-07-29 16:02:06.133;\n\
14b-468;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:02:11.047;\n\
14b-468;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:02:12.663;\n\
14b-468;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:02:14.457;\n\
14b-468;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:02:14.480;\n\
14b-469;RestartWorkflow;NULL;mmq;2014-07-29 16:02:15.930;\n\
14b-469;Initialize;NULL;administrator;2014-07-29 16:02:16.130;\n\
14b-469;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:02:20.880;\n\
14b-469;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:02:22.540;\n\
14b-469;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:02:24.477;\n\
14b-469;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:02:24.500;\n\
14b-471;RestartWorkflow;NULL;mmq;2014-07-29 16:02:25.973;\n\
14b-471;Initialize;NULL;administrator;2014-07-29 16:02:26.187;\n\
14b-471;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:02:30.110;\n\
14b-471;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:02:31.713;\n\
14b-471;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:02:33.680;\n\
14b-471;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:02:33.707;\n\
14b-472;RestartWorkflow;NULL;mmq;2014-07-29 16:02:35.347;\n\
14b-472;Initialize;NULL;administrator;2014-07-29 16:02:35.587;\n\
14b-472;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:02:39.580;\n\
14b-472;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:02:41.220;\n\
14b-472;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:02:42.907;\n\
14b-472;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:02:42.933;\n\
14b-473;RestartWorkflow;NULL;mmq;2014-07-29 16:02:44.280;\n\
14b-473;Initialize;NULL;administrator;2014-07-29 16:02:44.453;\n\
14b-473;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:02:48.563;\n\
14b-473;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:02:50.530;\n\
14b-473;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:02:52.730;\n\
14b-473;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:02:52.757;\n\
14b-474;RestartWorkflow;NULL;mmq;2014-07-29 16:02:54.187;\n\
14b-474;Initialize;NULL;administrator;2014-07-29 16:02:54.387;\n\
14b-474;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:02:58.073;\n\
14b-474;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:02:59.730;\n\
14b-474;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:03:01.467;\n\
14b-474;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:03:01.490;\n\
14b-475;RestartWorkflow;NULL;mmq;2014-07-29 16:03:03.423;\n\
14b-475;Initialize;NULL;administrator;2014-07-29 16:03:03.633;\n\
14b-475;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:03:07.390;\n\
14b-475;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:03:09.153;\n\
14b-475;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:03:10.877;\n\
14b-475;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:03:10.903;\n\
14b-476;RestartWorkflow;NULL;mmq;2014-07-29 16:03:12.323;\n\
14b-476;Initialize;NULL;administrator;2014-07-29 16:03:12.533;\n\
14b-476;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:03:16.170;\n\
14b-476;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:03:17.840;\n\
14b-476;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:03:19.637;\n\
14b-476;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:03:19.660;\n\
14b-477;RestartWorkflow;NULL;mmq;2014-07-29 16:03:21.080;\n\
14b-477;Initialize;NULL;administrator;2014-07-29 16:03:21.277;\n\
14b-477;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:03:25.140;\n\
14b-477;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:03:26.943;\n\
14b-477;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:03:29.077;\n\
14b-477;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:03:29.110;\n\
14b-478;RestartWorkflow;NULL;mmq;2014-07-29 16:03:30.580;\n\
14b-478;Initialize;NULL;administrator;2014-07-29 16:03:30.740;\n\
14b-478;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:03:34.347;\n\
14b-478;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:03:36.043;\n\
14b-478;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:03:37.840;\n\
14b-478;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:03:37.860;\n\
14b-479;RestartWorkflow;NULL;mmq;2014-07-29 16:03:39.233;\n\
14b-479;Initialize;NULL;administrator;2014-07-29 16:03:39.480;\n\
14b-479;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:03:43.390;\n\
14b-479;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:03:45.087;\n\
14b-479;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:03:46.800;\n\
14b-479;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:03:46.827;\n\
14b-480;RestartWorkflow;NULL;mmq;2014-07-29 16:03:48.357;\n\
14b-480;Initialize;NULL;administrator;2014-07-29 16:03:48.517;\n\
14b-480;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:03:52.363;\n\
14b-480;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:03:54.557;\n\
14b-480;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:03:56.220;\n\
14b-480;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:03:56.247;\n\
14b-481;RestartWorkflow;NULL;mmq;2014-07-29 16:03:57.630;\n\
14b-481;Initialize;NULL;administrator;2014-07-29 16:03:57.893;\n\
14b-481;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:04:01.957;\n\
14b-481;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:04:03.730;\n\
14b-481;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:04:05.400;\n\
14b-481;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:04:05.427;\n\
14b-482;RestartWorkflow;NULL;mmq;2014-07-29 16:04:06.790;\n\
14b-482;Initialize;NULL;administrator;2014-07-29 16:04:06.973;\n\
14b-482;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:04:11.347;\n\
14b-482;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:04:13.027;\n\
14b-482;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:04:14.950;\n\
14b-482;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:04:14.973;\n\
14b-483;RestartWorkflow;NULL;mmq;2014-07-29 16:04:16.357;\n\
14b-483;Initialize;NULL;administrator;2014-07-29 16:04:16.533;\n\
14b-483;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:04:20.290;\n\
14b-483;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:04:21.687;\n\
14b-483;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:04:22.767;\n\
14b-483;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:04:22.783;\n\
14b-484;RestartWorkflow;NULL;mmq;2014-07-29 16:04:23.837;\n\
14b-484;Initialize;NULL;administrator;2014-07-29 16:04:23.917;\n\
14b-484;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:04:26.257;\n\
14b-484;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:04:27.263;\n\
14b-484;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:04:28.303;\n\
14b-484;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:04:28.320;\n\
14b-485;RestartWorkflow;NULL;mmq;2014-07-29 16:04:29.637;\n\
14b-485;Initialize;NULL;administrator;2014-07-29 16:04:29.780;\n\
14b-485;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:04:34.143;\n\
14b-485;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:04:35.793;\n\
14b-485;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:04:37.520;\n\
14b-485;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:04:37.543;\n\
14b-488;RestartWorkflow;NULL;mmq;2014-07-29 16:04:38.890;\n\
14b-488;Initialize;NULL;administrator;2014-07-29 16:04:39.050;\n\
14b-488;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:04:42.693;\n\
14b-488;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:04:44.310;\n\
14b-488;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:04:46.043;\n\
14b-488;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:04:46.070;\n\
14b-489;RestartWorkflow;NULL;mmq;2014-07-29 16:04:47.503;\n\
14b-489;Initialize;NULL;administrator;2014-07-29 16:04:47.653;\n\
14b-489;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:04:51.427;\n\
14b-489;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:04:53.193;\n\
14b-489;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:04:55.073;\n\
14b-489;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:04:55.097;\n\
14b-491;RestartWorkflow;NULL;mmq;2014-07-29 16:04:56.497;\n\
14b-491;Initialize;NULL;administrator;2014-07-29 16:04:56.703;\n\
14b-491;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:05:00.260;\n\
14b-491;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:05:02.533;\n\
14b-491;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:05:04.870;\n\
14b-491;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:05:04.890;\n\
14b-492;RestartWorkflow;NULL;mmq;2014-07-29 16:05:05.900;\n\
14b-492;Initialize;NULL;administrator;2014-07-29 16:05:05.980;\n\
14b-492;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:05:08.310;\n\
14b-492;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:05:09.620;\n\
14b-492;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:05:11.513;\n\
14b-492;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:05:11.603;\n\
14b-493;RestartWorkflow;NULL;mmq;2014-07-29 16:05:13.620;\n\
14b-493;Initialize;NULL;administrator;2014-07-29 16:05:15.400;\n\
14b-493;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:05:17.913;\n\
14b-493;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:05:19.590;\n\
14b-493;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:05:21.390;\n\
14b-493;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:05:21.420;\n\
14b-494;RestartWorkflow;NULL;mmq;2014-07-29 16:05:23.267;\n\
14b-494;Initialize;NULL;administrator;2014-07-29 16:05:23.467;\n\
14b-494;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:05:27.650;\n\
14b-494;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:05:29.453;\n\
14b-494;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:05:32.157;\n\
14b-494;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:05:32.200;\n\
14b-495;RestartWorkflow;NULL;mmq;2014-07-29 16:05:34.680;\n\
14b-495;Initialize;NULL;administrator;2014-07-29 16:05:34.827;\n\
14b-495;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:05:38.590;\n\
14b-495;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:05:40.453;\n\
14b-495;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:05:42.857;\n\
14b-495;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:05:42.890;\n\
14b-496;RestartWorkflow;NULL;mmq;2014-07-29 16:05:44.267;\n\
14b-496;Initialize;NULL;administrator;2014-07-29 16:05:44.437;\n\
14b-496;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:05:48.187;\n\
14b-496;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:05:49.943;\n\
14b-496;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:05:51.757;\n\
14b-496;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:05:51.780;\n\
14b-497;RestartWorkflow;NULL;mmq;2014-07-29 16:05:53.283;\n\
14b-497;Initialize;NULL;administrator;2014-07-29 16:05:53.507;\n\
14b-497;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:05:59.013;\n\
14b-497;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:06:01.197;\n\
14b-497;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:06:04.533;\n\
14b-497;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:06:04.560;\n\
14b-498;RestartWorkflow;NULL;mmq;2014-07-29 16:06:06.657;\n\
14b-498;Initialize;NULL;administrator;2014-07-29 16:06:06.827;\n\
14b-498;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:06:10.530;\n\
14b-498;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:06:12.350;\n\
14b-498;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:06:14.183;\n\
14b-498;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:06:14.223;\n\
14b-500;RestartWorkflow;NULL;mmq;2014-07-29 16:06:15.587;\n\
14b-500;Initialize;NULL;administrator;2014-07-29 16:06:15.860;\n\
14b-500;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:06:19.447;\n\
14b-500;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:06:21.123;\n\
14b-500;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:06:22.817;\n\
14b-500;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:06:22.860;\n\
14b-501;RestartWorkflow;NULL;mmq;2014-07-29 16:06:24.383;\n\
14b-501;Initialize;NULL;administrator;2014-07-29 16:06:24.583;\n\
14b-501;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:06:28.263;\n\
14b-501;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:06:29.967;\n\
14b-501;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:06:31.727;\n\
14b-501;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:06:31.757;\n\
14b-502;RestartWorkflow;NULL;mmq;2014-07-29 16:06:33.393;\n\
14b-502;Initialize;NULL;administrator;2014-07-29 16:06:33.587;\n\
14b-502;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:06:38.160;\n\
14b-502;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:06:40.183;\n\
14b-502;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:06:41.997;\n\
14b-502;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:06:42.030;\n\
14b-503;RestartWorkflow;NULL;mmq;2014-07-29 16:06:43.607;\n\
14b-503;Initialize;NULL;administrator;2014-07-29 16:06:43.763;\n\
14b-503;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:06:47.507;\n\
14b-503;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:06:49.620;\n\
14b-503;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:06:51.480;\n\
14b-503;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:06:51.510;\n\
14b-504;RestartWorkflow;NULL;mmq;2014-07-29 16:06:53.067;\n\
14b-504;Initialize;NULL;administrator;2014-07-29 16:06:53.250;\n\
14b-504;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:06:56.870;\n\
14b-504;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:06:58.740;\n\
14b-504;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:07:00.930;\n\
14b-504;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:07:00.977;\n\
14b-505;RestartWorkflow;NULL;mmq;2014-07-29 16:07:02.980;\n\
14b-505;Initialize;NULL;administrator;2014-07-29 16:07:03.163;\n\
14b-505;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:07:08.120;\n\
14b-505;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:07:09.783;\n\
14b-505;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:07:11.790;\n\
14b-505;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:07:11.813;\n\
14b-507;RestartWorkflow;NULL;mmq;2014-07-29 16:07:13.200;\n\
14b-507;Initialize;NULL;administrator;2014-07-29 16:07:13.430;\n\
14b-507;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:07:17.253;\n\
14b-507;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:07:18.930;\n\
14b-507;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:07:20.907;\n\
14b-507;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:07:20.930;\n\
14b-508;RestartWorkflow;NULL;mmq;2014-07-29 16:07:22.530;\n\
14b-508;Initialize;NULL;administrator;2014-07-29 16:07:22.697;\n\
14b-508;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:07:26.613;\n\
14b-508;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:07:28.280;\n\
14b-508;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:07:30.147;\n\
14b-508;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:07:30.177;\n\
14b-509;RestartWorkflow;NULL;mmq;2014-07-29 16:07:31.557;\n\
14b-509;Initialize;NULL;administrator;2014-07-29 16:07:31.750;\n\
14b-509;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:07:35.437;\n\
14b-509;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:07:37.133;\n\
14b-509;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:07:38.850;\n\
14b-509;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:07:38.877;\n\
14b-510;RestartWorkflow;NULL;mmq;2014-07-29 16:07:40.267;\n\
14b-510;Initialize;NULL;administrator;2014-07-29 16:07:40.413;\n\
14b-510;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:07:44.723;\n\
14b-510;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:07:46.520;\n\
14b-510;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:07:48.227;\n\
14b-510;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:07:48.253;\n\
14b-511;RestartWorkflow;NULL;mmq;2014-07-29 16:07:49.653;\n\
14b-511;Initialize;NULL;administrator;2014-07-29 16:07:49.887;\n\
14b-511;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:07:53.693;\n\
14b-511;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:07:55.467;\n\
14b-511;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:07:57.483;\n\
14b-511;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:07:57.510;\n\
14b-512;RestartWorkflow;NULL;mmq;2014-07-29 16:07:58.897;\n\
14b-512;Initialize;NULL;administrator;2014-07-29 16:07:59.100;\n\
14b-512;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:08:04.877;\n\
14b-512;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:08:07.473;\n\
14b-512;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:08:09.137;\n\
14b-512;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:08:09.160;\n\
14b-513;RestartWorkflow;NULL;mmq;2014-07-29 16:08:10.900;\n\
14b-513;Initialize;NULL;administrator;2014-07-29 16:08:11.153;\n\
14b-513;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:08:14.783;\n\
14b-513;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:08:16.480;\n\
14b-513;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:08:18.443;\n\
14b-513;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:08:18.473;\n\
14b-514;RestartWorkflow;NULL;mmq;2014-07-29 16:08:19.913;\n\
14b-514;Initialize;NULL;administrator;2014-07-29 16:08:20.107;\n\
14b-514;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:08:23.960;\n\
14b-514;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:08:25.593;\n\
14b-514;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:08:27.600;\n\
14b-514;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:08:27.620;\n\
14b-515;RestartWorkflow;NULL;mmq;2014-07-29 16:08:29.017;\n\
14b-515;Initialize;NULL;administrator;2014-07-29 16:08:29.173;\n\
14b-515;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:08:32.837;\n\
14b-515;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:08:34.550;\n\
14b-515;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:08:36.870;\n\
14b-515;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:08:36.913;\n\
14b-516;RestartWorkflow;NULL;mmq;2014-07-29 16:08:38.500;\n\
14b-516;Initialize;NULL;administrator;2014-07-29 16:08:38.680;\n\
14b-516;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:08:43.257;\n\
14b-516;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:08:45.023;\n\
14b-516;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:08:46.760;\n\
14b-516;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:08:46.787;\n\
14b-517;RestartWorkflow;NULL;mmq;2014-07-29 16:08:48.197;\n\
14b-517;Initialize;NULL;administrator;2014-07-29 16:08:48.343;\n\
14b-517;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:08:51.920;\n\
14b-517;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:08:53.677;\n\
14b-517;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:08:55.477;\n\
14b-517;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:08:55.507;\n\
14b-518;RestartWorkflow;NULL;mmq;2014-07-29 16:08:56.900;\n\
14b-518;Initialize;NULL;administrator;2014-07-29 16:08:57.080;\n\
14b-518;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:09:01.200;\n\
14b-518;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:09:04.420;\n\
14b-518;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:09:06.173;\n\
14b-518;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:09:06.200;\n\
14b-519;RestartWorkflow;NULL;mmq;2014-07-29 16:09:08.027;\n\
14b-519;Initialize;NULL;administrator;2014-07-29 16:09:08.250;\n\
14b-519;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:09:12.453;\n\
14b-519;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:09:14.223;\n\
14b-519;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:09:15.927;\n\
14b-519;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:09:15.950;\n\
14b-520;RestartWorkflow;NULL;mmq;2014-07-29 16:09:17.333;\n\
14b-520;Initialize;NULL;administrator;2014-07-29 16:09:17.500;\n\
14b-520;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:09:21.670;\n\
14b-520;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:09:23.337;\n\
14b-520;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:09:25.540;\n\
14b-520;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:09:25.563;\n\
14b-521;RestartWorkflow;NULL;mmq;2014-07-29 16:09:26.923;\n\
14b-521;Initialize;NULL;administrator;2014-07-29 16:09:27.073;\n\
14b-521;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:09:30.920;\n\
14b-521;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:09:32.610;\n\
14b-521;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:09:34.737;\n\
14b-521;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:09:34.757;\n\
14b-522;RestartWorkflow;NULL;mmq;2014-07-29 16:09:36.297;\n\
14b-522;Initialize;NULL;administrator;2014-07-29 16:09:36.507;\n\
14b-522;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:09:40.400;\n\
14b-522;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:09:42.140;\n\
14b-522;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:09:44.167;\n\
14b-522;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:09:44.203;\n\
14b-523;RestartWorkflow;NULL;mmq;2014-07-29 16:09:46.023;\n\
14b-523;Initialize;NULL;administrator;2014-07-29 16:09:46.597;\n\
14b-523;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:09:50.293;\n\
14b-523;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:09:52.383;\n\
14b-523;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:09:54.370;\n\
14b-523;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:09:54.400;\n\
14b-525;RestartWorkflow;NULL;mmq;2014-07-29 16:09:55.900;\n\
14b-525;Initialize;NULL;administrator;2014-07-29 16:09:56.303;\n\
14b-525;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:10:00.047;\n\
14b-525;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:10:02.780;\n\
14b-525;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:10:05.307;\n\
14b-525;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:10:05.380;\n\
14b-526;RestartWorkflow;NULL;mmq;2014-07-29 16:10:07.350;\n\
14b-526;Initialize;NULL;administrator;2014-07-29 16:10:07.600;\n\
14b-526;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:10:11.290;\n\
14b-526;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:10:12.637;\n\
14b-526;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:10:13.970;\n\
14b-526;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:10:13.990;\n\
14b-527;RestartWorkflow;NULL;mmq;2014-07-29 16:10:15.540;\n\
14b-527;Initialize;NULL;administrator;2014-07-29 16:10:15.813;\n\
14b-527;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:10:19.880;\n\
14b-527;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:10:21.787;\n\
14b-527;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:10:23.997;\n\
14b-527;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:10:24.020;\n\
14b-528;RestartWorkflow;NULL;mmq;2014-07-29 16:10:25.447;\n\
14b-528;Initialize;NULL;administrator;2014-07-29 16:10:25.637;\n\
14b-528;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:10:29.507;\n\
14b-528;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:10:31.260;\n\
14b-528;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:10:32.720;\n\
14b-528;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:10:32.743;\n\
14b-529;RestartWorkflow;NULL;mmq;2014-07-29 16:10:34.477;\n\
14b-529;Initialize;NULL;administrator;2014-07-29 16:10:35.477;\n\
14b-529;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:10:40.517;\n\
14b-529;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:10:42.183;\n\
14b-529;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:10:43.957;\n\
14b-529;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:10:43.990;\n\
14b-530;RestartWorkflow;NULL;mmq;2014-07-29 16:10:45.653;\n\
14b-530;Initialize;NULL;administrator;2014-07-29 16:10:46.083;\n\
14b-530;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:10:50.060;\n\
14b-530;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:10:51.517;\n\
14b-530;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:10:52.743;\n\
14b-530;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:10:52.803;\n\
14b-531;RestartWorkflow;NULL;mmq;2014-07-29 16:10:53.980;\n\
14b-531;Initialize;NULL;administrator;2014-07-29 16:10:54.073;\n\
14b-531;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:10:56.720;\n\
14b-531;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:10:58.237;\n\
14b-531;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:11:00.690;\n\
14b-531;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:11:00.773;\n\
14b-532;RestartWorkflow;NULL;mmq;2014-07-29 16:11:02.580;\n\
14b-532;Initialize;NULL;administrator;2014-07-29 16:11:02.877;\n\
14b-532;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:11:08.320;\n\
14b-532;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:11:11.250;\n\
14b-532;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:11:13.243;\n\
14b-532;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:11:13.270;\n\
14b-533;RestartWorkflow;NULL;mmq;2014-07-29 16:11:14.933;\n\
14b-533;Initialize;NULL;administrator;2014-07-29 16:11:15.150;\n\
14b-533;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:11:19.017;\n\
14b-533;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:11:20.760;\n\
14b-533;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:11:22.130;\n\
14b-533;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:11:22.150;\n\
14b-535;RestartWorkflow;NULL;mmq;2014-07-29 16:11:24.757;\n\
14b-535;Initialize;NULL;administrator;2014-07-29 16:11:24.937;\n\
14b-535;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:11:29.100;\n\
14b-535;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:11:31.123;\n\
14b-535;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:11:33.070;\n\
14b-535;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:11:33.097;\n\
14b-536;RestartWorkflow;NULL;mmq;2014-07-29 16:11:34.873;\n\
14b-536;Initialize;NULL;administrator;2014-07-29 16:11:35.033;\n\
14b-536;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:11:39.077;\n\
14b-536;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:11:40.880;\n\
14b-536;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:11:42.760;\n\
14b-536;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:11:42.783;\n\
14b-537;RestartWorkflow;NULL;mmq;2014-07-29 16:11:44.637;\n\
14b-537;Initialize;NULL;administrator;2014-07-29 16:11:44.820;\n\
14b-537;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:11:49.280;\n\
14b-537;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:11:51.247;\n\
14b-537;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:11:53.213;\n\
14b-537;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:11:53.290;\n\
14b-538;RestartWorkflow;NULL;mmq;2014-07-29 16:11:55.393;\n\
14b-538;Initialize;NULL;administrator;2014-07-29 16:11:55.557;\n\
14b-538;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:11:59.167;\n\
14b-538;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:12:00.890;\n\
14b-538;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:12:03.610;\n\
14b-538;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:12:03.653;\n\
14b-539;RestartWorkflow;NULL;mmq;2014-07-29 16:12:05.047;\n\
14b-539;Initialize;NULL;administrator;2014-07-29 16:12:05.210;\n\
14b-539;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:12:08.800;\n\
14b-539;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:12:10.473;\n\
14b-539;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:12:12.380;\n\
14b-539;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:12:12.407;\n\
14b-541;RestartWorkflow;NULL;mmq;2014-07-29 16:12:14.290;\n\
14b-541;Initialize;NULL;administrator;2014-07-29 16:12:14.517;\n\
14b-541;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:12:18.993;\n\
14b-541;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:12:20.603;\n\
14b-541;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:12:22.307;\n\
14b-541;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:12:22.333;\n\
14b-542;RestartWorkflow;NULL;mmq;2014-07-29 16:12:23.700;\n\
14b-542;Initialize;NULL;administrator;2014-07-29 16:12:23.850;\n\
14b-542;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:12:27.590;\n\
14b-542;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:12:29.363;\n\
14b-542;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:12:31.027;\n\
14b-542;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:12:31.060;\n\
14b-545;RestartWorkflow;NULL;mmq;2014-07-29 16:12:32.410;\n\
14b-545;Initialize;NULL;administrator;2014-07-29 16:12:32.610;\n\
14b-545;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:12:36.677;\n\
14b-545;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:12:38.323;\n\
14b-545;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:12:40.023;\n\
14b-545;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:12:40.050;\n\
14b-546;RestartWorkflow;NULL;mmq;2014-07-29 16:12:41.403;\n\
14b-546;Initialize;NULL;administrator;2014-07-29 16:12:41.550;\n\
14b-546;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:12:45.247;\n\
14b-546;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:12:46.877;\n\
14b-546;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:12:48.553;\n\
14b-546;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:12:48.580;\n\
14b-547;RestartWorkflow;NULL;mmq;2014-07-29 16:12:49.987;\n\
14b-547;Initialize;NULL;administrator;2014-07-29 16:12:50.183;\n\
14b-547;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:12:53.883;\n\
14b-547;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:12:55.920;\n\
14b-547;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:12:57.540;\n\
14b-547;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:12:57.563;\n\
14b-548;RestartWorkflow;NULL;mmq;2014-07-29 16:12:58.910;\n\
14b-548;Initialize;NULL;administrator;2014-07-29 16:12:59.127;\n\
14b-548;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:13:06.670;\n\
14b-548;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:13:08.477;\n\
14b-548;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:13:10.153;\n\
14b-548;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:13:10.177;\n\
14b-549;RestartWorkflow;NULL;mmq;2014-07-29 16:13:11.620;\n\
14b-549;Initialize;NULL;administrator;2014-07-29 16:13:11.820;\n\
14b-549;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:13:15.500;\n\
14b-549;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:13:17.230;\n\
14b-549;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:13:18.957;\n\
14b-549;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:13:18.983;\n\
14b-550;RestartWorkflow;NULL;mmq;2014-07-29 16:13:20.393;\n\
14b-550;Initialize;NULL;administrator;2014-07-29 16:13:20.560;\n\
14b-550;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:13:24.750;\n\
14b-550;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:13:26.437;\n\
14b-550;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:13:28.227;\n\
14b-550;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:13:28.250;\n\
14b-551;RestartWorkflow;NULL;mmq;2014-07-29 16:13:29.780;\n\
14b-551;Initialize;NULL;administrator;2014-07-29 16:13:29.953;\n\
14b-551;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:13:33.477;\n\
14b-551;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:13:35.200;\n\
14b-551;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:13:36.943;\n\
14b-551;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:13:36.970;\n\
14b-552;RestartWorkflow;NULL;mmq;2014-07-29 16:13:38.390;\n\
14b-552;Initialize;NULL;administrator;2014-07-29 16:13:38.560;\n\
14b-552;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:13:42.097;\n\
14b-552;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:13:43.813;\n\
14b-552;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:13:45.623;\n\
14b-552;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:13:45.750;\n\
14b-553;RestartWorkflow;NULL;mmq;2014-07-29 16:13:47.127;\n\
14b-553;Initialize;NULL;administrator;2014-07-29 16:13:47.330;\n\
14b-553;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:13:51.153;\n\
14b-553;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:13:52.820;\n\
14b-553;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:13:54.490;\n\
14b-553;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:13:54.513;\n\
14b-556;RestartWorkflow;NULL;mmq;2014-07-29 16:13:55.893;\n\
14b-556;Initialize;NULL;administrator;2014-07-29 16:13:56.093;\n\
14b-556;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:13:59.877;\n\
14b-556;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:14:02.440;\n\
14b-556;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:14:04.190;\n\
14b-556;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:14:04.217;\n\
14b-560;RestartWorkflow;NULL;mmq;2014-07-29 16:14:05.530;\n\
14b-560;Initialize;NULL;administrator;2014-07-29 16:14:05.880;\n\
14b-560;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:14:09.500;\n\
14b-560;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:14:11.163;\n\
14b-560;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:14:12.850;\n\
14b-560;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:14:12.873;\n\
14b-561;RestartWorkflow;NULL;mmq;2014-07-29 16:14:14.253;\n\
14b-561;Initialize;NULL;administrator;2014-07-29 16:14:14.440;\n\
14b-561;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:14:18.163;\n\
14b-561;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:14:19.983;\n\
14b-561;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:14:21.797;\n\
14b-561;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:14:21.820;\n\
14b-562;RestartWorkflow;NULL;mmq;2014-07-29 16:14:23.160;\n\
14b-562;Initialize;NULL;administrator;2014-07-29 16:14:23.330;\n\
14b-562;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:14:26.950;\n\
14b-562;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:14:28.600;\n\
14b-562;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:14:30.373;\n\
14b-562;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:14:30.400;\n\
14b-564;RestartWorkflow;NULL;mmq;2014-07-29 16:14:31.750;\n\
14b-564;Initialize;NULL;administrator;2014-07-29 16:14:31.913;\n\
14b-564;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:14:35.590;\n\
14b-564;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:14:37.283;\n\
14b-564;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:14:38.960;\n\
14b-564;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:14:38.983;\n\
14b-565;RestartWorkflow;NULL;mmq;2014-07-29 16:14:40.447;\n\
14b-565;Initialize;NULL;administrator;2014-07-29 16:14:40.730;\n\
14b-565;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:14:44.433;\n\
14b-565;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:14:46.477;\n\
14b-565;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:14:48.570;\n\
14b-565;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:14:48.590;\n\
14b-566;RestartWorkflow;NULL;mmq;2014-07-29 16:14:49.950;\n\
14b-566;Initialize;NULL;administrator;2014-07-29 16:14:50.127;\n\
14b-566;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:14:53.737;\n\
14b-566;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:14:55.377;\n\
14b-566;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:14:57.067;\n\
14b-566;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:14:57.090;\n\
14b-567;RestartWorkflow;NULL;mmq;2014-07-29 16:14:58.590;\n\
14b-567;Initialize;NULL;administrator;2014-07-29 16:14:58.773;\n\
14b-567;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:15:05.940;\n\
14b-567;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:15:07.663;\n\
14b-567;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:15:09.440;\n\
14b-567;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:15:09.460;\n\
14b-568;RestartWorkflow;NULL;mmq;2014-07-29 16:15:10.907;\n\
14b-568;Initialize;NULL;administrator;2014-07-29 16:15:11.070;\n\
14b-568;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:15:16.950;\n\
14b-568;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:15:18.583;\n\
14b-568;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:15:21.017;\n\
14b-568;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:15:21.053;\n\
14b-569;RestartWorkflow;NULL;mmq;2014-07-29 16:15:22.410;\n\
14b-569;Initialize;NULL;administrator;2014-07-29 16:15:22.607;\n\
14b-569;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:15:26.660;\n\
14b-569;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:15:28.430;\n\
14b-569;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:15:30.530;\n\
14b-569;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:15:30.567;\n\
14b-570;RestartWorkflow;NULL;mmq;2014-07-29 16:15:32.000;\n\
14b-570;Initialize;NULL;administrator;2014-07-29 16:15:32.207;\n\
14b-570;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:15:35.980;\n\
14b-570;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:15:37.883;\n\
14b-570;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:15:39.673;\n\
14b-570;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:15:39.700;\n\
14b-571;RestartWorkflow;NULL;mmq;2014-07-29 16:15:41.180;\n\
14b-571;Initialize;NULL;administrator;2014-07-29 16:15:41.377;\n\
14b-571;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:15:45.153;\n\
14b-571;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:15:46.893;\n\
14b-571;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:15:48.673;\n\
14b-571;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:15:48.700;\n\
14b-572;RestartWorkflow;NULL;mmq;2014-07-29 16:15:50.107;\n\
14b-572;Initialize;NULL;administrator;2014-07-29 16:15:50.260;\n\
14b-572;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:15:53.823;\n\
14b-572;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:15:55.483;\n\
14b-572;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:15:57.160;\n\
14b-572;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:15:57.187;\n\
14b-574;RestartWorkflow;NULL;mmq;2014-07-29 16:15:58.583;\n\
14b-574;Initialize;NULL;administrator;2014-07-29 16:15:58.740;\n\
14b-574;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:16:03.143;\n\
14b-574;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:16:05.200;\n\
14b-574;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:16:07.003;\n\
14b-574;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:16:07.030;\n\
14b-575;RestartWorkflow;NULL;mmq;2014-07-29 16:16:08.390;\n\
14b-575;Initialize;NULL;administrator;2014-07-29 16:16:08.560;\n\
14b-575;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:16:12.213;\n\
14b-575;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:16:15.000;\n\
14b-575;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:16:17.230;\n\
14b-575;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:16:17.267;\n\
14b-577;RestartWorkflow;NULL;mmq;2014-07-29 16:16:19.027;\n\
14b-577;Initialize;NULL;administrator;2014-07-29 16:16:19.187;\n\
14b-577;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:16:24.207;\n\
14b-577;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:16:26.113;\n\
14b-577;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:16:27.950;\n\
14b-577;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:16:27.997;\n\
14b-578;RestartWorkflow;NULL;mmq;2014-07-29 16:16:29.693;\n\
14b-578;Initialize;NULL;administrator;2014-07-29 16:16:29.860;\n\
14b-578;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:16:33.643;\n\
14b-578;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:16:35.470;\n\
14b-578;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:16:37.437;\n\
14b-578;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:16:37.460;\n\
14b-579;RestartWorkflow;NULL;mmq;2014-07-29 16:16:39.210;\n\
14b-579;Initialize;NULL;administrator;2014-07-29 16:16:39.403;\n\
14b-579;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:16:43.073;\n\
14b-579;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:16:44.747;\n\
14b-579;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:16:46.423;\n\
14b-579;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:16:46.463;\n\
14b-580;RestartWorkflow;NULL;mmq;2014-07-29 16:16:48.050;\n\
14b-580;Initialize;NULL;administrator;2014-07-29 16:16:48.207;\n\
14b-580;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:16:51.740;\n\
14b-580;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:16:53.520;\n\
14b-580;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:16:55.197;\n\
14b-580;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:16:55.220;\n\
14b-581;RestartWorkflow;NULL;mmq;2014-07-29 16:16:56.613;\n\
14b-581;Initialize;NULL;administrator;2014-07-29 16:16:56.777;\n\
14b-581;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:17:00.450;\n\
14b-581;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:17:03.597;\n\
14b-581;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:17:05.473;\n\
14b-581;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:17:05.500;\n\
14b-582;RestartWorkflow;NULL;mmq;2014-07-29 16:17:07.013;\n\
14b-582;Initialize;NULL;administrator;2014-07-29 16:17:07.360;\n\
14b-582;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:17:11.127;\n\
14b-582;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:17:12.837;\n\
14b-582;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:17:14.630;\n\
14b-582;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:17:14.653;\n\
14b-583;RestartWorkflow;NULL;mmq;2014-07-29 16:17:16.020;\n\
14b-583;Initialize;NULL;administrator;2014-07-29 16:17:16.220;\n\
14b-583;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:17:19.920;\n\
14b-583;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:17:21.527;\n\
14b-583;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:17:23.260;\n\
14b-583;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:17:23.287;\n\
14b-584;RestartWorkflow;NULL;mmq;2014-07-29 16:17:25.147;\n\
14b-584;Initialize;NULL;administrator;2014-07-29 16:17:25.333;\n\
14b-584;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:17:28.860;\n\
14b-584;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:17:30.770;\n\
14b-584;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:17:32.463;\n\
14b-584;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:17:32.510;\n\
14b-585;RestartWorkflow;NULL;mmq;2014-07-29 16:17:33.863;\n\
14b-585;Initialize;NULL;administrator;2014-07-29 16:17:34.020;\n\
14b-585;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:17:37.667;\n\
14b-585;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:17:39.317;\n\
14b-585;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:17:41.103;\n\
14b-585;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:17:41.130;\n\
14b-586;RestartWorkflow;NULL;mmq;2014-07-29 16:17:42.563;\n\
14b-586;Initialize;NULL;administrator;2014-07-29 16:17:42.733;\n\
14b-586;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:17:46.683;\n\
14b-586;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:17:48.667;\n\
14b-586;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:17:50.647;\n\
14b-586;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:17:50.673;\n\
14b-587;RestartWorkflow;NULL;mmq;2014-07-29 16:17:52.170;\n\
14b-587;Initialize;NULL;administrator;2014-07-29 16:17:52.420;\n\
14b-587;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:17:56.283;\n\
14b-587;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:17:57.917;\n\
14b-587;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:17:59.587;\n\
14b-587;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:17:59.630;\n\
14b-589;RestartWorkflow;NULL;mmq;2014-07-29 16:18:01.017;\n\
14b-589;Initialize;NULL;administrator;2014-07-29 16:18:01.197;\n\
14b-589;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:18:05.700;\n\
14b-589;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:18:07.407;\n\
14b-589;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:18:09.227;\n\
14b-589;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:18:09.253;\n\
14b-590;RestartWorkflow;NULL;mmq;2014-07-29 16:18:10.840;\n\
14b-590;Initialize;NULL;administrator;2014-07-29 16:18:11.040;\n\
14b-590;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:18:15.130;\n\
14b-590;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:18:17.313;\n\
14b-590;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:18:19.060;\n\
14b-590;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:18:19.167;\n\
14b-591;RestartWorkflow;NULL;mmq;2014-07-29 16:18:20.940;\n\
14b-591;Initialize;NULL;administrator;2014-07-29 16:18:21.073;\n\
14b-591;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:18:24.880;\n\
14b-591;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:18:26.940;\n\
14b-591;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:18:29.127;\n\
14b-591;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:18:29.167;\n\
14b-592;RestartWorkflow;NULL;mmq;2014-07-29 16:18:30.617;\n\
14b-592;Initialize;NULL;administrator;2014-07-29 16:18:30.797;\n\
14b-592;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:18:34.530;\n\
14b-592;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:18:36.160;\n\
14b-592;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:18:38.103;\n\
14b-592;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:18:38.130;\n\
14b-593;RestartWorkflow;NULL;mmq;2014-07-29 16:18:39.800;\n\
14b-593;Initialize;NULL;administrator;2014-07-29 16:18:39.997;\n\
14b-593;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:18:43.603;\n\
14b-593;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:18:45.337;\n\
14b-593;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:18:47.103;\n\
14b-593;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:18:47.130;\n\
14b-594;RestartWorkflow;NULL;mmq;2014-07-29 16:18:48.540;\n\
14b-594;Initialize;NULL;administrator;2014-07-29 16:18:48.670;\n\
14b-594;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:18:52.220;\n\
14b-594;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:18:53.873;\n\
14b-594;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:18:55.677;\n\
14b-594;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:18:55.700;\n\
14b-595;RestartWorkflow;NULL;mmq;2014-07-29 16:18:57.077;\n\
14b-595;Initialize;NULL;administrator;2014-07-29 16:18:57.223;\n\
14b-595;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:19:00.917;\n\
14b-595;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:19:03.050;\n\
14b-595;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:19:04.727;\n\
14b-595;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:19:04.750;\n\
14b-596;RestartWorkflow;NULL;mmq;2014-07-29 16:19:06.110;\n\
14b-596;Initialize;NULL;administrator;2014-07-29 16:19:06.273;\n\
14b-596;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:19:09.883;\n\
14b-596;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:19:11.703;\n\
14b-596;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:19:13.387;\n\
14b-596;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:19:13.410;\n\
14b-597;RestartWorkflow;NULL;mmq;2014-07-29 16:19:15.240;\n\
14b-597;Initialize;NULL;administrator;2014-07-29 16:19:15.380;\n\
14b-597;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:19:19.570;\n\
14b-597;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:19:21.360;\n\
14b-597;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:19:23.167;\n\
14b-597;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:19:23.197;\n\
14b-598;RestartWorkflow;NULL;mmq;2014-07-29 16:19:24.657;\n\
14b-598;Initialize;NULL;administrator;2014-07-29 16:19:24.857;\n\
14b-598;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:19:28.720;\n\
14b-598;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:19:30.913;\n\
14b-598;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:19:32.930;\n\
14b-598;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:19:32.967;\n\
14b-599;RestartWorkflow;NULL;mmq;2014-07-29 16:19:35.100;\n\
14b-599;Initialize;NULL;administrator;2014-07-29 16:19:35.277;\n\
14b-599;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:19:39.530;\n\
14b-599;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:19:41.290;\n\
14b-599;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:19:43.047;\n\
14b-599;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:19:43.070;\n\
14b-600;RestartWorkflow;NULL;mmq;2014-07-29 16:19:44.443;\n\
14b-600;Initialize;NULL;administrator;2014-07-29 16:19:44.610;\n\
14b-600;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:19:48.520;\n\
14b-600;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:19:50.317;\n\
14b-600;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:19:52.137;\n\
14b-600;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:19:52.160;\n\
14b-601;RestartWorkflow;NULL;mmq;2014-07-29 16:19:53.547;\n\
14b-601;Initialize;NULL;administrator;2014-07-29 16:19:53.723;\n\
14b-601;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:19:57.520;\n\
14b-601;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:19:59.477;\n\
14b-601;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:20:00.773;\n\
14b-601;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:20:00.807;\n\
14b-602;RestartWorkflow;NULL;mmq;2014-07-29 16:20:02.930;\n\
14b-602;Initialize;NULL;administrator;2014-07-29 16:20:03.257;\n\
14b-602;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:20:06.883;\n\
14b-602;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:20:09.013;\n\
14b-602;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:20:11.000;\n\
14b-602;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:20:11.023;\n\
14b-604;RestartWorkflow;NULL;mmq;2014-07-29 16:20:12.577;\n\
14b-604;Initialize;NULL;administrator;2014-07-29 16:20:13.027;\n\
14b-604;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:20:16.837;\n\
14b-604;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:20:18.493;\n\
14b-604;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:20:20.390;\n\
14b-604;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:20:20.413;\n\
14b-605;RestartWorkflow;NULL;mmq;2014-07-29 16:20:21.763;\n\
14b-605;Initialize;NULL;administrator;2014-07-29 16:20:21.913;\n\
14b-605;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:20:26.530;\n\
14b-605;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:20:28.473;\n\
14b-605;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:20:30.547;\n\
14b-605;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:20:30.577;\n\
14b-606;RestartWorkflow;NULL;mmq;2014-07-29 16:20:32.177;\n\
14b-606;Initialize;NULL;administrator;2014-07-29 16:20:32.357;\n\
14b-606;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:20:35.960;\n\
14b-606;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:20:37.617;\n\
14b-606;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:20:39.317;\n\
14b-606;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:20:39.343;\n\
14b-607;RestartWorkflow;NULL;mmq;2014-07-29 16:20:40.763;\n\
14b-607;Initialize;NULL;administrator;2014-07-29 16:20:40.903;\n\
14b-607;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:20:44.583;\n\
14b-607;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:20:46.213;\n\
14b-607;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:20:47.920;\n\
14b-607;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:20:47.947;\n\
14b-608;RestartWorkflow;NULL;mmq;2014-07-29 16:20:49.347;\n\
14b-608;Initialize;NULL;administrator;2014-07-29 16:20:49.517;\n\
14b-608;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:20:53.260;\n\
14b-608;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:20:54.897;\n\
14b-608;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:20:56.610;\n\
14b-608;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:20:56.657;\n\
14b-609;RestartWorkflow;NULL;mmq;2014-07-29 16:20:58.007;\n\
14b-609;Initialize;NULL;administrator;2014-07-29 16:20:58.180;\n\
14b-609;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:21:02.150;\n\
14b-609;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:21:04.837;\n\
14b-609;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:21:06.517;\n\
14b-609;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:21:06.540;\n\
14b-610;RestartWorkflow;NULL;mmq;2014-07-29 16:21:07.893;\n\
14b-610;Initialize;NULL;administrator;2014-07-29 16:21:08.057;\n\
14b-610;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:21:12.637;\n\
14b-610;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:21:14.430;\n\
14b-610;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:21:16.137;\n\
14b-610;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:21:16.163;\n\
14b-611;RestartWorkflow;NULL;mmq;2014-07-29 16:21:17.523;\n\
14b-611;Initialize;NULL;administrator;2014-07-29 16:21:17.677;\n\
14b-611;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:21:21.633;\n\
14b-611;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:21:23.340;\n\
14b-611;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:21:25.133;\n\
14b-611;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:21:25.157;\n\
14b-612;RestartWorkflow;NULL;mmq;2014-07-29 16:21:26.510;\n\
14b-612;Initialize;NULL;administrator;2014-07-29 16:21:26.653;\n\
14b-612;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:21:30.453;\n\
14b-612;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:21:32.250;\n\
14b-612;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:21:34.073;\n\
14b-612;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:21:34.100;\n\
14b-613;RestartWorkflow;NULL;mmq;2014-07-29 16:21:35.527;\n\
14b-613;Initialize;NULL;administrator;2014-07-29 16:21:35.690;\n\
14b-613;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:21:39.260;\n\
14b-613;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:21:40.907;\n\
14b-613;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:21:42.587;\n\
14b-613;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:21:42.610;\n\
14b-614;RestartWorkflow;NULL;mmq;2014-07-29 16:21:44.217;\n\
14b-614;Initialize;NULL;administrator;2014-07-29 16:21:44.373;\n\
14b-614;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:21:48.810;\n\
14b-614;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:21:50.873;\n\
14b-614;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:21:52.757;\n\
14b-614;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:21:52.780;\n\
14b-617;RestartWorkflow;NULL;mmq;2014-07-29 16:21:54.617;\n\
14b-617;Initialize;NULL;administrator;2014-07-29 16:21:54.750;\n\
14b-617;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:21:58.347;\n\
14b-617;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:22:00.190;\n\
14b-617;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:22:02.033;\n\
14b-617;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:22:02.060;\n\
14b-618;RestartWorkflow;NULL;mmq;2014-07-29 16:22:05.383;\n\
14b-618;Initialize;NULL;administrator;2014-07-29 16:22:05.560;\n\
14b-618;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:22:09.980;\n\
14b-618;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:22:11.723;\n\
14b-618;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:22:13.390;\n\
14b-618;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:22:13.417;\n\
14b-619;RestartWorkflow;NULL;mmq;2014-07-29 16:22:14.777;\n\
14b-619;Initialize;NULL;administrator;2014-07-29 16:22:15.083;\n\
14b-619;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:22:18.673;\n\
14b-619;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:22:20.380;\n\
14b-619;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:22:22.070;\n\
14b-619;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:22:22.093;\n\
14b-620;RestartWorkflow;NULL;mmq;2014-07-29 16:22:23.440;\n\
14b-620;Initialize;NULL;administrator;2014-07-29 16:22:23.603;\n\
14b-620;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:22:27.347;\n\
14b-620;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:22:28.953;\n\
14b-558;DL_WFCaseTypeStep!471;Lawyer Review;cbc;2014-07-29 16:22:30.383;\n\
14b-620;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:22:30.880;\n\
14b-620;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:22:30.903;\n\
14b-621;RestartWorkflow;NULL;mmq;2014-07-29 16:22:33.657;\n\
14b-621;Initialize;NULL;administrator;2014-07-29 16:22:33.867;\n\
14b-621;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:22:37.963;\n\
14b-621;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:22:39.633;\n\
14b-621;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:22:41.387;\n\
14b-621;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:22:41.413;\n\
14b-623;RestartWorkflow;NULL;mmq;2014-07-29 16:22:42.787;\n\
14b-623;Initialize;NULL;administrator;2014-07-29 16:22:42.960;\n\
14b-623;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:22:46.650;\n\
14b-623;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:22:48.310;\n\
14b-623;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:22:50.167;\n\
14b-623;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:22:50.210;\n\
14b-624;RestartWorkflow;NULL;mmq;2014-07-29 16:22:51.543;\n\
14b-624;Initialize;NULL;administrator;2014-07-29 16:22:51.703;\n\
14b-624;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:22:55.370;\n\
14b-624;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:22:57.117;\n\
14b-624;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:22:58.883;\n\
14b-624;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:22:58.907;\n\
14b-625;RestartWorkflow;NULL;mmq;2014-07-29 16:23:00.280;\n\
14b-625;Initialize;NULL;administrator;2014-07-29 16:23:00.433;\n\
14b-625;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:23:05.967;\n\
14b-625;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:23:07.893;\n\
14b-625;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:23:09.730;\n\
14b-625;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:23:09.753;\n\
14b-626;RestartWorkflow;NULL;mmq;2014-07-29 16:23:11.150;\n\
14b-626;Initialize;NULL;administrator;2014-07-29 16:23:11.320;\n\
14b-626;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:23:14.940;\n\
14b-626;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:23:16.577;\n\
14b-626;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:23:18.387;\n\
14b-626;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:23:18.410;\n\
14b-627;RestartWorkflow;NULL;mmq;2014-07-29 16:23:19.940;\n\
14b-627;Initialize;NULL;administrator;2014-07-29 16:23:20.087;\n\
14b-627;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:23:23.613;\n\
14b-627;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:23:25.293;\n\
14b-627;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:23:27.090;\n\
14b-627;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:23:27.123;\n\
14b-628;RestartWorkflow;NULL;mmq;2014-07-29 16:23:29.150;\n\
14b-628;Initialize;NULL;administrator;2014-07-29 16:23:29.340;\n\
14b-628;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 16:23:33.033;\n\
14b-628;DL_WFCaseTypeStep!469;Approval - on to the board;mmq;2014-07-29 16:23:34.747;\n\
14b-628;DL_WFCaseTypeStep!518;Change phase to review;administrator;2014-07-29 16:23:36.873;\n\
14b-628;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;mmq;2014-07-29 16:23:36.907;\n\
14b-576;RestartWorkflow;NULL;Administrator;2014-07-29 17:03:15.437;\n\
14b-576;Initialize;NULL;administrator;2014-07-29 17:03:15.643;\n\
14b-576;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 17:03:19.600;\n\
14b-576;DL_WFCaseTypeStep!470;Initial rejection;Administrator;2014-07-29 17:03:30.880;\n\
14b-576;DL_WFCaseTypeStep!486;Applicant informed ;Administrator;2014-07-29 17:03:36.537;\n\
14b-576;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2014-07-29 17:03:40.160;\n\
14b-554;RestartWorkflow;NULL;Administrator;2014-07-29 17:04:20.487;\n\
14b-554;Initialize;NULL;administrator;2014-07-29 17:04:20.667;\n\
14b-554;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 17:04:24.563;\n\
14b-554;DL_WFCaseTypeStep!470;Initial rejection;Administrator;2014-07-29 17:04:32.987;\n\
14b-554;DL_WFCaseTypeStep!486;Applicant informed ;Administrator;2014-07-29 17:04:37.550;\n\
14b-554;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2014-07-29 17:04:40.923;\n\
14b-555;RestartWorkflow;NULL;Administrator;2014-07-29 17:04:59.437;\n\
14b-555;Initialize;NULL;administrator;2014-07-29 17:04:59.630;\n\
14b-555;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-29 17:05:06.740;\n\
14b-555;DL_WFCaseTypeStep!470;Initial rejection;Administrator;2014-07-29 17:05:15.253;\n\
14b-555;DL_WFCaseTypeStep!486;Applicant informed ;Administrator;2014-07-29 17:05:20.493;\n\
14b-555;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2014-07-29 17:05:24.293;\n\
14b-558;DL_WFCaseTypeStep!474;Review;bry;2014-07-29 17:56:55.267;\n\
14b-630;Initialize;NULL;Ansøger;2014-07-30 23:16:44.977;\n\
14b-630;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-07-30 23:16:44.997;\n\
14b-631;Initialize;NULL;Ansøger;2014-08-01 15:33:19.507;\n\
14b-631;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-01 15:33:19.507;\n\
14b-630;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-11 09:12:42.630;\n\
14b-630;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-11 09:12:46.960;\n\
14b-630;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-08-11 09:12:53.167;\n\
14b-629;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-11 09:14:59.817;\n\
14b-629;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-11 09:15:03.830;\n\
14b-629;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-08-11 09:15:05.720;\n\
14b-631;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-11 09:18:31.447;\n\
14b-631;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-11 09:18:34.297;\n\
14b-631;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-08-11 09:18:36.253;\n\
14a-233;DL_WFCaseTypeStep!550;Carry out lapses;pdm;2014-08-12 10:32:50.810;\n\
14a-233;DL_WFCaseTypeStep!550;Carry out lapses;pdm;2014-08-12 10:33:15.647;\n\
14a-233;DL_WFCaseTypeStep!550;Carry out lapses;pdm;2014-08-12 10:38:09.673;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;pdm;2014-08-12 13:33:33.443;\n\
14a-233;DL_WFCaseTypeStep!550;Carry out lapses;pdm;2014-08-12 13:47:39.140;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;pdm;2014-08-12 14:12:33.810;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;pdm;2014-08-12 14:15:19.510;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;pdm;2014-08-12 14:17:53.930;\n\
14b-558;DL_WFCaseTypeStep!472;Architect Review;mp;2014-08-13 11:06:31.563;\n\
14b-420;DL_WFCaseTypeStep!472;Architect Review;mp;2014-08-13 11:07:54.073;\n\
14a-044;DL_DCREventTaskPersonal!25276;NULL;pdm;2014-08-13 11:27:27.623;\n\
14a-044;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-13 11:27:30.527;\n\
14a-208;DL_DCREventTaskPersonal!25355;NULL;pdm;2014-08-13 11:41:43.300;\n\
14a-208;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-13 11:41:46.637;\n\
14a-082;DL_DCREventTaskPersonal!25300;NULL;pdm;2014-08-13 13:04:38.070;\n\
14a-082;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-13 13:04:41.943;\n\
14a-082;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-13 13:07:31.940;\n\
14b-328;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-08-13 13:18:28.367;\n\
14b-328;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-08-13 13:19:02.833;\n\
14b-406;DL_WFCaseTypeStep!544;Pre-process application;pdm;2014-08-13 13:23:30.070;\n\
14b-406;DL_WFCaseTypeStep!545;Execute pre-decision;pdm;2014-08-13 13:23:31.937;\n\
14b-406;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-08-13 13:23:31.937;\n\
14b-406;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-08-13 13:23:36.027;\n\
14b-406;DL_WFCaseTypeStep!531;Change phase to Preparation;pdm;2014-08-13 13:23:38.663;\n\
14b-406;DL_WFCaseTypeStep!542;Set to Pre-approved;pdm;2014-08-13 13:23:38.670;\n\
14b-406;DL_WFCaseTypeStep!546;Applicant proves relevance of application;pdm;2014-08-13 13:23:46.677;\n\
14b-406;DL_WFCaseTypeStep!482;Inform applicant about the grant;pdm;2014-08-13 13:23:52.123;\n\
14b-406;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-13 13:23:55.027;\n\
14b-406;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-13 13:26:41.270;\n\
14a-208;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-13 13:28:01.353;\n\
14a-044;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-13 13:34:42.517;\n\
14a-131;DL_DCREventTaskPersonal!25312;NULL;pdm;2014-08-13 15:06:15.410;\n\
14a-131;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-13 15:06:19.707;\n\
14a-131;DL_WFCaseTypeStep!480;Receive final report;pdm;2014-08-13 15:06:23.013;\n\
14b-420;DL_WFCaseTypeStep!473;Review;br;2014-08-13 18:04:30.853;\n\
14a-131;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-14 11:36:59.837;\n\
14a-131;DL_WFCaseTypeStep!537;Payment completed;pdm;2014-08-14 11:37:05.473;\n\
14a-131;DL_WFCaseTypeStep!521;Change Phase to End Report;pdm;2014-08-14 11:37:07.877;\n\
14a-131;DL_WFCaseTypeStep!520;Change phase to Complete;suser;2014-08-14 11:38:03.210;\n\
14a-084;DL_DCREventTaskPersonal!25301;NULL;pdm;2014-08-14 14:29:29.180;\n\
14a-084;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-14 14:29:33.670;\n\
14a-064;DL_DCREventTaskPersonal!25288;NULL;pdm;2014-08-14 14:44:08.330;\n\
14a-064;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-14 14:44:11.883;\n\
14a-164;DL_DCREventTaskPersonal!25329;NULL;pdm;2014-08-14 14:53:03.477;\n\
14a-164;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-14 14:53:06.313;\n\
14a-110;DL_DCREventTaskPersonal!25305;NULL;pdm;2014-08-14 15:01:53.027;\n\
14a-110;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-14 15:01:56.443;\n\
14b-558;DL_WFCaseTypeStep!473;Review;br;2014-08-18 09:02:00.847;\n\
14b-377;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-08-18 09:21:53.047;\n\
14a-110;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-18 09:40:13.057;\n\
14a-110;DL_WFCaseTypeStep!498;Undo payment;pdm;2014-08-18 09:45:56.617;\n\
14a-110;RestartWorkflow;NULL;pdm;2014-08-18 09:49:03.813;\n\
14a-110;Initialize;NULL;pdm;2014-08-18 09:49:04.600;\n\
14a-110;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-18 09:49:09.377;\n\
14a-110;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-18 09:49:25.363;\n\
14a-110;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-18 09:49:27.430;\n\
14a-110;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-08-18 09:49:30.823;\n\
14a-110;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-08-18 09:54:48.790;\n\
14a-110;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-08-18 09:55:03.863;\n\
14a-110;DL_WFCaseTypeStep!544;Pre-process application;pdm;2014-08-18 09:55:31.510;\n\
14a-110;DL_WFCaseTypeStep!545;Execute pre-decision;pdm;2014-08-18 09:55:35.670;\n\
14a-110;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-08-18 09:55:35.670;\n\
14a-110;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-08-18 09:56:03.897;\n\
14a-110;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-08-18 09:56:03.900;\n\
14b-558;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-08-18 10:02:50.607;\n\
14b-558;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-08-18 10:03:03.843;\n\
14b-558;DL_WFCaseTypeStep!544;Pre-process application;pdm;2014-08-18 10:06:50.170;\n\
14b-558;DL_WFCaseTypeStep!545;Execute pre-decision;pdm;2014-08-18 10:06:52.357;\n\
14b-558;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-08-18 10:06:52.357;\n\
14b-558;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-08-18 10:07:03.353;\n\
14b-558;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-08-18 10:07:03.360;\n\
14b-558;DL_WFCaseTypeStep!482;Inform applicant about the grant;pdm;2014-08-18 10:07:19.360;\n\
14a-110;DL_WFCaseTypeStep!482;Inform applicant about the grant;pdm;2014-08-18 10:08:33.330;\n\
14a-110;DL_WFCaseTypeStep!546;Applicant proves relevance of application;pdm;2014-08-18 10:08:41.540;\n\
14a-110;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-18 10:08:43.047;\n\
14a-110;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-18 10:12:01.960;\n\
14a-164;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-18 11:02:46.963;\n\
14a-084;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-18 11:03:55.893;\n\
14b-406;AddEvent;NULL;pdm;2014-08-18 11:11:33.683;\n\
14b-406;AddNesting;NULL;pdm;2014-08-18 11:11:33.690;\n\
14b-406;AddEvent;NULL;pdm;2014-08-18 11:11:33.780;\n\
14b-406;AddNesting;NULL;pdm;2014-08-18 11:11:33.780;\n\
14b-406;AddRelation;NULL;pdm;2014-08-18 11:11:33.783;\n\
14b-406;AddRelation;NULL;pdm;2014-08-18 11:11:33.783;\n\
14b-406;AddRelation;NULL;pdm;2014-08-18 11:11:33.783;\n\
14b-406;AddRelation;NULL;pdm;2014-08-18 11:11:33.783;\n\
14b-406;AddEvent;NULL;pdm;2014-08-18 11:11:33.787;\n\
14b-406;AddRelation;NULL;pdm;2014-08-18 11:11:33.787;\n\
14b-406;DF_Udbetaling!892;NULL;pdm;2014-08-18 11:23:51.303;\n\
14b-371;DL_WFCaseTypeStep!546;Applicant proves relevance of application;pdm;2014-08-18 11:34:07.203;\n\
14b-371;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-18 11:34:10.780;\n\
14b-371;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-18 11:37:10.593;\n\
14a-110;RestartWorkflow;NULL;pdm;2014-08-18 11:54:20.527;\n\
14a-110;Initialize;NULL;pdm;2014-08-18 11:54:20.710;\n\
14a-110;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-18 11:54:25.703;\n\
14a-110;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-18 11:56:30.273;\n\
14a-110;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-18 11:56:33.817;\n\
14a-110;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-08-18 11:56:37.120;\n\
14a-110;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-08-18 11:59:32.630;\n\
14a-110;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-08-18 12:00:04.077;\n\
14a-110;DL_WFCaseTypeStep!544;Pre-process application;pdm;2014-08-18 12:00:15.227;\n\
14a-110;DL_WFCaseTypeStep!545;Execute pre-decision;pdm;2014-08-18 12:00:18.160;\n\
14a-110;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-08-18 12:00:18.160;\n\
14a-110;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-08-18 12:01:04.017;\n\
14a-110;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-08-18 12:01:04.020;\n\
14a-110;DL_WFCaseTypeStep!482;Inform applicant about the grant;pdm;2014-08-18 12:01:12.640;\n\
14a-110;DL_WFCaseTypeStep!546;Applicant proves relevance of application;pdm;2014-08-18 12:01:19.820;\n\
14a-110;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-18 12:01:21.690;\n\
14a-110;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-18 12:03:16.950;\n\
14a-104;DL_DCREventTaskPersonal!25303;NULL;pdm;2014-08-18 12:13:35.680;\n\
14a-104;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-18 12:13:38.990;\n\
14a-104;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-18 12:16:07.460;\n\
14b-406;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-18 13:17:13.117;\n\
14a-110;DL_WFCaseTypeStep!510;Round Ends;suser;2014-08-19 03:00:05.707;\n\
14a-151;DL_DCREventTaskPersonal!25325;NULL;pdm;2014-08-19 11:05:28.907;\n\
14a-151;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-19 11:05:32.237;\n\
14a-151;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-19 11:08:17.343;\n\
14a-118;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-19 12:50:11.190;\n\
14b-304;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-19 13:24:37.027;\n\
14b-304;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-19 13:24:40.753;\n\
14b-304;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-19 13:25:01.303;\n\
14b-304;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-19 13:25:05.017;\n\
14b-304;RestartWorkflow;NULL;pdm;2014-08-19 13:30:03.457;\n\
14b-304;Initialize;NULL;pdm;2014-08-19 13:30:04.070;\n\
14b-304;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-19 13:30:09.247;\n\
14b-304;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-19 13:30:22.870;\n\
14b-304;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-19 13:30:25.370;\n\
14b-304;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-08-19 13:30:29.263;\n\
14b-304;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-08-19 13:31:21.833;\n\
14b-304;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-08-19 13:32:02.803;\n\
14b-304;DL_WFCaseTypeStep!544;Pre-process application;pdm;2014-08-19 13:32:57.167;\n\
14b-304;DL_WFCaseTypeStep!545;Execute pre-decision;pdm;2014-08-19 13:33:01.010;\n\
14b-304;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-08-19 13:33:01.010;\n\
14b-304;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-08-19 13:34:03.900;\n\
14b-304;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-08-19 13:34:03.900;\n\
14b-304;DL_WFCaseTypeStep!482;Inform applicant about the grant;pdm;2014-08-19 13:34:13.990;\n\
14b-304;DL_WFCaseTypeStep!546;Applicant proves relevance of application;pdm;2014-08-19 13:34:23.343;\n\
14b-304;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-19 13:34:24.967;\n\
14b-304;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-19 13:37:09.690;\n\
14b-304;DL_WFCaseTypeStep!510;Round Ends;suser;2014-08-20 03:00:06.583;\n\
14a-131;RestartWorkflow;NULL;pdm;2014-08-20 10:35:22.620;\n\
14a-131;Initialize;NULL;pdm;2014-08-20 10:35:23.373;\n\
14a-131;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-20 10:35:29.797;\n\
14a-131;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-20 10:35:41.513;\n\
14a-131;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-20 10:35:43.837;\n\
14a-131;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-08-20 10:35:47.520;\n\
14a-131;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-08-20 10:37:10.557;\n\
14a-131;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-08-20 10:38:03.333;\n\
14a-131;DL_WFCaseTypeStep!544;Pre-process application;pdm;2014-08-20 10:40:27.090;\n\
14a-131;DL_WFCaseTypeStep!545;Execute pre-decision;pdm;2014-08-20 10:40:27.953;\n\
14a-131;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-08-20 10:40:27.953;\n\
14a-131;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-08-20 10:41:03.140;\n\
14a-131;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-08-20 10:41:03.143;\n\
14a-131;DL_WFCaseTypeStep!482;Inform applicant about the grant;pdm;2014-08-20 10:41:14.307;\n\
14a-131;DL_WFCaseTypeStep!546;Applicant proves relevance of application;pdm;2014-08-20 10:41:21.607;\n\
14a-131;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-20 10:41:23.250;\n\
14a-131;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-20 10:44:32.593;\n\
14b-558;DL_WFCaseTypeStep!546;Applicant proves relevance of application;pdm;2014-08-20 13:23:41.817;\n\
14b-558;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-20 13:23:45.767;\n\
14b-558;RestartWorkflow;NULL;pdm;2014-08-20 13:27:07.720;\n\
14b-558;Initialize;NULL;pdm;2014-08-20 13:27:07.963;\n\
14b-558;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-20 13:27:11.610;\n\
14b-558;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-20 13:29:26.303;\n\
14b-558;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-20 13:29:29.767;\n\
14b-558;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-08-20 13:29:33.013;\n\
14b-558;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-08-20 13:30:21.767;\n\
14b-558;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-08-20 13:31:01.987;\n\
14b-558;DL_WFCaseTypeStep!544;Pre-process application;pdm;2014-08-20 13:31:11.760;\n\
14b-558;DL_WFCaseTypeStep!545;Execute pre-decision;pdm;2014-08-20 13:31:14.043;\n\
14b-558;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-08-20 13:31:14.043;\n\
14b-558;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-08-20 13:32:04.050;\n\
14b-558;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-08-20 13:32:04.050;\n\
14b-558;DL_WFCaseTypeStep!546;Applicant proves relevance of application;pdm;2014-08-20 13:32:32.660;\n\
14b-558;DL_WFCaseTypeStep!482;Inform applicant about the grant;pdm;2014-08-20 13:32:38.240;\n\
14b-558;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-20 13:32:40.640;\n\
14b-558;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-20 13:36:12.700;\n\
14b-406;RestartWorkflow;NULL;pdm;2014-08-20 14:04:23.430;\n\
14b-406;Initialize;NULL;pdm;2014-08-20 14:04:23.840;\n\
14b-406;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-20 14:04:29.800;\n\
14b-406;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-20 14:04:40.567;\n\
14b-406;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-20 14:04:42.717;\n\
14b-406;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-08-20 14:04:46.230;\n\
14b-406;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-08-20 14:06:04.133;\n\
14b-406;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-08-20 14:07:04.117;\n\
14b-406;DL_WFCaseTypeStep!544;Pre-process application;pdm;2014-08-20 14:07:10.717;\n\
14b-406;DL_WFCaseTypeStep!545;Execute pre-decision;pdm;2014-08-20 14:07:14.560;\n\
14b-406;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-08-20 14:07:14.560;\n\
14b-406;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-08-20 14:08:04.443;\n\
14b-406;DL_WFCaseTypeStep!542;Set to Pre-approved;suser;2014-08-20 14:08:04.473;\n\
14b-406;DL_WFCaseTypeStep!482;Inform applicant about the grant;pdm;2014-08-20 14:08:09.787;\n\
14b-406;DL_WFCaseTypeStep!546;Applicant proves relevance of application;pdm;2014-08-20 14:08:17.283;\n\
14b-406;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-20 14:08:19.090;\n\
14b-406;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-20 14:11:06.030;\n\
14a-131;DL_WFCaseTypeStep!510;Round Ends;suser;2014-08-21 03:00:05.267;\n\
14a-151;DL_WFCaseTypeStep!541;Account number changed;pdm;2014-08-21 13:51:18.690;\n\
14a-151;DL_WFCaseTypeStep!498;Undo payment;pdm;2014-08-21 13:51:47.540;\n\
14a-151;DL_WFCaseTypeStep!484;Approve changed account number;pdm;2014-08-21 13:52:38.227;\n\
14a-071;DL_DCREventTaskPersonal!25293;NULL;pdm;2014-08-21 14:02:06.933;\n\
14a-071;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-21 14:02:09.890;\n\
14a-071;DL_WFCaseTypeStep!541;Account number changed;pdm;2014-08-21 14:03:23.367;\n\
14a-229;DL_WFCaseTypeStep!541;Account number changed;pdm;2014-08-21 14:12:10.097;\n\
14a-229;DL_DCREventTaskPersonal!25367;NULL;pdm;2014-08-21 14:12:34.193;\n\
14a-229;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-21 14:12:39.653;\n\
14a-082;DL_WFCaseTypeStep!541;Account number changed;pdm;2014-08-21 15:09:33.947;\n\
14a-208;RestartWorkflow;NULL;pdm;2014-08-22 10:07:58.460;\n\
14a-208;Initialize;NULL;pdm;2014-08-22 10:07:59.193;\n\
14a-208;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-22 10:08:06.877;\n\
14a-208;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-22 10:09:29.497;\n\
14a-208;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-22 10:09:31.160;\n\
14a-208;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;pdm;2014-08-22 10:09:34.777;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-08-22 10:10:51.283;\n\
14a-208;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-08-22 10:11:02.003;\n\
14a-208;DL_WFCaseTypeStep!544;Pre-process application;pdm;2014-08-22 10:11:13.790;\n\
14a-208;DL_WFCaseTypeStep!545;Execute pre-decision;pdm;2014-08-22 10:11:16.407;\n\
14a-208;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-08-22 10:11:16.407;\n\
14a-208;DL_WFCaseTypeStep!469;Approval - on to the board;pdm;2014-08-22 10:14:56.090;\n\
14a-208;DL_WFCaseTypeStep!518;Change phase to review;pdm;2014-08-22 10:14:59.733;\n\
14b-406;DL_WFCaseTypeStep!498;Undo payment;Morten;2014-08-22 11:36:17.343;\n\
14a-246;DL_WFCaseTypeStep!498;Undo payment;Morten;2014-08-22 11:40:43.860;\n\
14a-208;RestartWorkflow;NULL;Morten;2014-08-22 11:45:58.267;\n\
14a-208;Initialize;NULL;ANONYMOUS LOGON;2014-08-22 11:45:58.433;\n\
14a-208;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-22 11:46:04.240;\n\
14a-208;DL_WFCaseTypeStep!469;Approval - on to the board;Morten;2014-08-22 11:46:14.450;\n\
14a-208;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-08-22 11:46:16.167;\n\
14a-208;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;Morten;2014-08-22 11:46:19.010;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;ANONYMOUS LOGON;2014-08-22 11:48:52.730;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;Exformatics;2014-08-22 11:48:54.977;\n\
14a-208;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-08-22 11:49:03.750;\n\
14a-131;DL_WFCaseTypeStep!498;Undo payment;Morten;2014-08-22 11:56:23.920;\n\
14a-131;RestartWorkflow;NULL;Morten;2014-08-22 12:05:52.490;\n\
14a-131;Initialize;NULL;ANONYMOUS LOGON;2014-08-22 12:05:52.667;\n\
14a-131;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-22 12:05:57.690;\n\
14a-131;DL_WFCaseTypeStep!469;Approval - on to the board;Morten;2014-08-22 12:06:12.527;\n\
14a-131;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-08-22 12:06:14.743;\n\
14a-131;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;Morten;2014-08-22 12:06:17.597;\n\
14a-131;DL_WFCaseTypeStep!475;Register Decision;Exformatics;2014-08-22 12:07:44.687;\n\
14a-131;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2014-08-22 12:07:49.153;\n\
14a-131;DL_WFCaseTypeStep!475;Register Decision;ANONYMOUS LOGON;2014-08-22 12:08:37.657;\n\
14a-131;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2014-08-22 12:08:43.183;\n\
14a-131;DL_WFCaseTypeStep!544;Pre-process application;Morten;2014-08-22 12:08:46.187;\n\
14a-131;DL_WFCaseTypeStep!545;Execute pre-decision;ANONYMOUS LOGON;2014-08-22 12:08:48.477;\n\
14a-131;DL_WFCaseTypeStep!478;Approve application;Automatic;2014-08-22 12:08:48.477;\n\
14a-131;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2014-08-22 12:08:57.730;\n\
14a-131;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2014-08-22 12:08:57.730;\n\
14a-131;DL_WFCaseTypeStep!482;Inform applicant about the grant;Morten;2014-08-22 12:09:02.750;\n\
14a-131;DL_WFCaseTypeStep!546;Applicant proves relevance of application;Morten;2014-08-22 12:09:07.370;\n\
14a-131;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2014-08-22 12:09:09.300;\n\
14a-208;DL_WFCaseTypeStep!510;Round Ends;suser;2014-08-23 03:00:05.990;\n\
14a-131;DL_WFCaseTypeStep!510;Round Ends;suser;2014-08-23 03:00:05.997;\n\
14a-131;DL_WFCaseTypeStep!485;Round approved;Exformatics;2014-08-24 11:14:32.567;\n\
14a-208;DL_WFCaseTypeStep!485;Round approved;Exformatics;2014-08-24 11:15:45.200;\n\
14a-208;DL_WFCaseTypeStep!478;Approve application;Exformatics;2014-08-24 11:18:28.420;\n\
14a-208;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2014-08-24 11:18:32.830;\n\
14a-208;DL_WFCaseTypeStep!478;Approve application;Exformatics;2014-08-24 11:22:02.763;\n\
14a-208;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2014-08-24 11:22:08.760;\n\
14a-208;RestartWorkflow;NULL;mmq;2014-08-24 11:24:54.493;\n\
14a-208;Initialize;NULL;administrator;2014-08-24 11:24:54.787;\n\
14a-208;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-24 11:25:00.380;\n\
14a-208;DL_WFCaseTypeStep!469;Approval - on to the board;Morten;2014-08-24 11:25:21.597;\n\
14a-208;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-08-24 11:25:23.570;\n\
14a-208;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;Morten;2014-08-24 11:25:40.990;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;ANONYMOUS LOGON;2014-08-24 11:26:25.153;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;Exformatics;2014-08-24 11:26:27.673;\n\
14a-208;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2014-08-24 11:26:31.820;\n\
14a-208;DL_WFCaseTypeStep!485;Round approved;Exformatics;2014-08-24 11:27:37.200;\n\
14a-208;DL_WFCaseTypeStep!510;Round Ends;Exformatics;2014-08-24 11:27:50.303;\n\
14a-208;DL_WFCaseTypeStep!478;Approve application;Exformatics;2014-08-24 11:28:43.797;\n\
14a-208;DL_WFCaseTypeStep!477;Reject application;Exformatics;2014-08-24 11:29:46.987;\n\
14a-208;DL_WFCaseTypeStep!485;Round approved;Exformatics;2014-08-24 11:33:25.733;\n\
14a-208;DL_WFCaseTypeStep!478;Approve application;Exformatics;2014-08-24 11:34:03.203;\n\
14a-208;DL_WFCaseTypeStep!531;Change phase to Preparation;suser;2014-08-24 11:34:04.843;\n\
14a-208;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2014-08-24 16:47:47.070;\n\
14a-208;RestartWorkflow;NULL;mmq;2014-08-24 16:52:58.160;\n\
14a-208;Initialize;NULL;administrator;2014-08-24 16:52:58.340;\n\
14a-208;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-24 16:53:03.683;\n\
14a-208;DL_WFCaseTypeStep!469;Approval - on to the board;Morten;2014-08-24 16:53:14.700;\n\
14a-208;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-08-24 16:53:16.550;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;ANONYMOUS LOGON;2014-08-24 16:53:51.947;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;Exformatics;2014-08-24 16:53:56.550;\n\
14a-208;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2014-08-24 16:54:00.677;\n\
14a-208;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;Morten;2014-08-24 16:54:06.270;\n\
14a-208;DL_WFCaseTypeStep!510;Round Ends;Exformatics;2014-08-24 16:54:33.770;\n\
14a-208;DL_WFCaseTypeStep!485;Round approved;Exformatics;2014-08-24 16:54:49.283;\n\
14a-208;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2014-08-24 16:55:29.167;\n\
14a-208;DL_WFCaseTypeStep!531;Change phase to Preparation;ANONYMOUS LOGON;2014-08-24 16:55:30.353;\n\
14a-208;RestartWorkflow;NULL;mmq;2014-08-24 16:59:23.670;\n\
14a-208;Initialize;NULL;administrator;2014-08-24 16:59:23.853;\n\
14a-208;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-24 16:59:29.077;\n\
14a-208;DL_WFCaseTypeStep!469;Approval - on to the board;Morten;2014-08-24 16:59:38.387;\n\
14a-208;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-08-24 16:59:41.363;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;ANONYMOUS LOGON;2014-08-24 17:00:01.277;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;Exformatics;2014-08-24 17:00:03.423;\n\
14a-208;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2014-08-24 17:00:07.503;\n\
14a-208;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;Morten;2014-08-24 17:00:13.683;\n\
14a-208;DL_WFCaseTypeStep!510;Round Ends;ANONYMOUS LOGON;2014-08-24 17:00:32.920;\n\
14a-208;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-08-24 17:00:41.647;\n\
14a-208;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2014-08-24 17:00:47.130;\n\
14a-208;DL_WFCaseTypeStep!531;Change phase to Preparation;ANONYMOUS LOGON;2014-08-24 17:00:48.410;\n\
14a-208;DL_WFCaseTypeStep!545;Execute pre-decision;ANONYMOUS LOGON;2014-08-24 17:01:56.220;\n\
14a-208;DL_WFCaseTypeStep!482;Inform applicant about the grant;Morten;2014-08-24 17:02:03.700;\n\
14a-208;RestartWorkflow;NULL;mmq;2014-08-24 17:13:09.240;\n\
14a-208;Initialize;NULL;administrator;2014-08-24 17:13:09.563;\n\
14a-208;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-24 17:13:14.710;\n\
14a-208;RestartWorkflow;NULL;mmq;2014-08-24 17:16:15.950;\n\
14a-208;Initialize;NULL;administrator;2014-08-24 17:16:16.130;\n\
14a-208;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-24 17:16:20.897;\n\
14a-208;DL_WFCaseTypeStep!469;Approval - on to the board;Morten;2014-08-24 17:16:31.640;\n\
14a-208;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-08-24 17:16:33.363;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;ANONYMOUS LOGON;2014-08-24 17:16:54.740;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;Exformatics;2014-08-24 17:16:57.403;\n\
14a-208;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-08-24 17:17:03.130;\n\
14a-208;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;Morten;2014-08-24 17:17:05.917;\n\
14a-208;DL_WFCaseTypeStep!510;Round Ends;ANONYMOUS LOGON;2014-08-24 17:17:41.410;\n\
14a-208;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2014-08-24 17:17:46.540;\n\
14a-208;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2014-08-24 17:17:57.050;\n\
14a-208;DL_WFCaseTypeStep!531;Change phase to Preparation;ANONYMOUS LOGON;2014-08-24 17:17:58.117;\n\
14a-208;RestartWorkflow;NULL;mmq;2014-08-26 11:34:58.113;\n\
14a-208;Initialize;NULL;administrator;2014-08-26 11:34:59.300;\n\
14a-208;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-26 11:35:07.897;\n\
14a-208;RestartWorkflow;NULL;mmq;2014-08-26 11:35:32.510;\n\
14a-208;Initialize;NULL;administrator;2014-08-26 11:35:32.700;\n\
14a-208;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2014-08-26 11:35:36.527;\n\
14a-208;DL_WFCaseTypeStep!469;Approval - on to the board;Morten;2014-08-26 11:35:54.430;\n\
14a-208;DL_WFCaseTypeStep!518;Change phase to review;ANONYMOUS LOGON;2014-08-26 11:35:56.447;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;ANONYMOUS LOGON;2014-08-26 11:36:16.303;\n\
14a-208;DL_WFCaseTypeStep!475;Register Decision;Exformatics;2014-08-26 11:36:19.193;\n\
14a-208;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2014-08-26 11:36:24.627;\n\
14a-208;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;Morten;2014-08-26 11:36:38.410;\n\
14a-071;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-08-26 11:37:15.007;\n\
14a-208;DL_WFCaseTypeStep!510;Round Ends;Exformatics;2014-08-26 11:37:42.560;\n\
14a-213;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-08-26 11:37:52.953;\n\
14a-208;DL_WFCaseTypeStep!485;Round approved;Exformatics;2014-08-26 11:38:15.997;\n\
14a-229;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-08-26 11:38:59.193;\n\
14a-208;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2014-08-26 11:39:09.983;\n\
14a-208;DL_WFCaseTypeStep!531;Change phase to Preparation;ANONYMOUS LOGON;2014-08-26 11:39:19.990;\n\
14a-082;DL_WFCaseTypeStep!484;Approve changed account number;va;2014-08-26 11:39:27.473;\n\
14a-272;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 13:29:02.937;\n\
14b-377;AddEvent;NULL;va;2014-08-26 14:08:59.193;\n\
14b-377;AddNesting;NULL;va;2014-08-26 14:08:59.200;\n\
14b-377;AddEvent;NULL;va;2014-08-26 14:08:59.200;\n\
14b-377;AddNesting;NULL;va;2014-08-26 14:08:59.200;\n\
14b-377;AddRelation;NULL;va;2014-08-26 14:08:59.210;\n\
14b-377;AddRelation;NULL;va;2014-08-26 14:08:59.210;\n\
14b-377;AddRelation;NULL;va;2014-08-26 14:08:59.210;\n\
14b-377;AddRelation;NULL;va;2014-08-26 14:08:59.210;\n\
14b-377;AddEvent;NULL;va;2014-08-26 14:08:59.217;\n\
14b-377;AddRelation;NULL;va;2014-08-26 14:08:59.217;\n\
14a-044;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 14:20:36.597;\n\
14a-044;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-26 14:21:02.460;\n\
14b-377;DL_DCREventDBTrigger!15878;NULL;CompletePayments;2014-08-26 14:55:57.367;\n\
14a-055;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 14:56:32.730;\n\
14a-055;DL_WFCaseTypeStep!521;Change Phase to End Report;pdm;2014-08-26 14:56:52.747;\n\
14a-079;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 14:56:56.570;\n\
14a-079;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-26 14:57:07.887;\n\
14a-084;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 15:04:08.500;\n\
14a-084;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-26 15:05:03.197;\n\
14a-164;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 15:06:07.710;\n\
14a-164;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-26 15:07:02.470;\n\
14a-110;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 15:09:23.490;\n\
14a-110;DL_WFCaseTypeStep!521;Change Phase to End Report;pdm;2014-08-26 15:09:32.703;\n\
14b-371;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 15:09:33.793;\n\
14b-371;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-26 15:10:05.080;\n\
14a-104;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 15:12:05.303;\n\
14a-104;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-26 15:13:02.567;\n\
14a-118;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 15:14:04.943;\n\
14a-118;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-26 15:15:02.200;\n\
14b-558;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 15:19:15.390;\n\
14b-558;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-26 15:20:03.280;\n\
14a-258;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 15:40:09.153;\n\
14b-406;DL_WFCaseTypeStep!479;First payout;administrator;2014-08-26 15:40:55.790;\n\
14a-258;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-26 15:41:01.560;\n\
14b-406;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-26 15:41:03.337;\n\
14a-082;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-26 15:46:43.430;\n\
14a-082;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-26 15:47:01.607;\n\
14b-406;DL_WFCaseTypeStep!479;First payout;administrator;2014-08-26 15:52:12.353;\n\
14b-406;DL_WFCaseTypeStep!479;First payout;administrator;2014-08-26 15:54:14.290;\n\
14b-406;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2014-08-26 17:35:19.840;\n\
14a-064;DL_WFCaseTypeStep!479;First payout;pdm;2014-08-27 11:45:04.757;\n\
14b-406;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-27 12:14:20.997;\n\
14a-064;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2014-08-27 14:45:38.100;\n\
14a-064;DL_WFCaseTypeStep!521;Change Phase to End Report;suser;2014-08-27 14:46:02.117;\n\
14a-077;DL_WFCaseTypeStep!541;Account number changed;pdm;2014-08-28 11:08:21.620;\n\
14a-235;DL_DCREventTaskPersonal!25370;NULL;pdm;2014-08-28 11:23:48.107;\n\
14a-235;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-08-28 11:23:51.010;\n\
14b-420;DL_WFCaseTypeStep!475;Register Decision;pdm;2014-09-01 08:58:28.503;\n\
14b-420;DL_WFCaseTypeStep!519;Change phase to Board meeting;suser;2014-09-01 08:59:04.373;\n\
14a-208;DL_WFCaseTypeStep!482;Inform applicant about the grant;pdm;2014-09-01 10:21:07.203;\n\
14a-208;DL_WFCaseTypeStep!546;Applicant proves relevance of application;pdm;2014-09-01 10:21:13.807;\n\
14a-208;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-09-01 10:21:15.873;\n\
14a-245;DL_WFCaseTypeStep!541;Account number changed;pdm;2014-09-03 09:40:39.300;\n\
14a-245;DL_DCREventTaskPersonal!25376;NULL;pdm;2014-09-03 09:41:03.823;\n\
14a-245;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-09-03 09:41:08.060;\n\
14b-377;AddEvent;NULL;pdm;2014-09-04 09:47:29.107;\n\
14b-377;AddNesting;NULL;pdm;2014-09-04 09:47:29.110;\n\
14b-377;AddEvent;NULL;pdm;2014-09-04 09:47:29.180;\n\
14b-377;AddNesting;NULL;pdm;2014-09-04 09:47:29.180;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:47:29.183;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:47:29.183;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:47:29.183;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:47:29.183;\n\
14b-377;AddEvent;NULL;pdm;2014-09-04 09:47:29.187;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:47:29.187;\n\
14b-377;AddEvent;NULL;pdm;2014-09-04 09:48:39.703;\n\
14b-377;AddNesting;NULL;pdm;2014-09-04 09:48:39.703;\n\
14b-377;AddEvent;NULL;pdm;2014-09-04 09:48:39.707;\n\
14b-377;AddNesting;NULL;pdm;2014-09-04 09:48:39.707;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:48:39.707;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:48:39.707;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:48:39.707;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:48:39.707;\n\
14b-377;AddEvent;NULL;pdm;2014-09-04 09:48:39.710;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:48:39.710;\n\
14b-377;AddEvent;NULL;pdm;2014-09-04 09:52:02.227;\n\
14b-377;AddNesting;NULL;pdm;2014-09-04 09:52:02.227;\n\
14b-377;AddEvent;NULL;pdm;2014-09-04 09:52:02.227;\n\
14b-377;AddNesting;NULL;pdm;2014-09-04 09:52:02.230;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:52:02.230;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:52:02.230;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:52:02.230;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:52:02.230;\n\
14b-377;AddEvent;NULL;pdm;2014-09-04 09:52:02.230;\n\
14b-377;AddRelation;NULL;pdm;2014-09-04 09:52:02.230;\n\
14a-150;DL_DCREventTaskPersonal!25324;NULL;pdm;2014-09-04 10:16:25.213;\n\
14a-150;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2014-09-04 10:16:28.117;\n\
14a-222;DL_DCREventTaskPersonal!25363;NULL;pdm;2014-09-04 10:30:51.280;\n\
14a-222;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-09-04 10:30:54.620;\n\
14a-077;DL_DCREventTaskPersonal!25297;NULL;pdm;2014-09-04 10:31:18.867;\n\
14a-077;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2014-09-04 10:31:21.117;\n\
14a-206;DL_DCREventTaskPersonal!25353;NULL;pdm;2014-09-04 10:31:39.290;\n\
14a-206;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2014-09-04 10:31:42.740;\n\
14a-178;DL_DCREventTaskPersonal!25335;NULL;pdm;2014-09-04 10:32:00.897;\n\
14a-178;DL_WFCaseTypeStep!522;Change Phase to Payout;suser;2014-09-04 10:32:04.530;\n\
14a-154;DL_DCREventTaskPersonal!25326;NULL;pdm;2014-09-04 10:32:22.760;\n\
14a-154;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2014-09-04 10:32:26.867;\n\
14b-470;DL_WFCaseTypeStep!486;Applicant informed ;pdm;2014-09-04 10:42:42.980;\n\
14b-470;DL_WFCaseTypeStep!538;Change phase to Abort;ANONYMOUS LOGON;2014-09-04 10:42:47.543;\n\
14b-631;DL_WFCaseTypeStep!472;Architect Review;mp;2015-05-03 13:24:33.517;\n\
14b-631;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 13:25:27.533;\n\
14b-631;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 13:25:39.850;\n\
14b-631;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 13:26:10.670;\n\
14b-631;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 13:26:14.530;\n\
14b-631;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 13:27:09.023;\n\
14b-631;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 13:27:26.800;\n\
14b-631;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 13:27:28.870;\n\
14b-631;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 13:27:28.873;\n\
14b-631;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 13:29:28.390;\n\
14b-631;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 13:29:34.443;\n\
14b-631;DL_WFCaseTypeStep!478;Approve application;Automatic;2015-05-03 13:29:34.443;\n\
14b-631;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 13:29:47.087;\n\
14b-631;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 13:29:51.647;\n\
14b-631;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 13:29:51.650;\n\
14b-631;DL_WFCaseTypeStep!482;Inform applicant about the grant;recover;2015-05-03 13:30:29.037;\n\
14b-631;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 13:31:07.587;\n\
14b-631;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 13:31:26.387;\n\
14b-630;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 13:36:08.427;\n\
14b-630;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 13:36:11.527;\n\
14b-630;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 13:36:13.173;\n\
14b-630;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 13:36:14.557;\n\
14b-630;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 13:36:16.690;\n\
14b-630;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 13:36:16.697;\n\
14b-630;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 13:36:19.240;\n\
14b-630;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 13:36:19.247;\n\
14b-630;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 13:36:19.367;\n\
14b-630;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 13:36:22.777;\n\
14b-630;DL_WFCaseTypeStep!478;Approve application;Automatic;2015-05-03 13:36:22.777;\n\
14b-630;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 13:36:22.877;\n\
14b-630;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 13:36:25.957;\n\
14b-630;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 13:36:26.000;\n\
14b-630;DL_WFCaseTypeStep!482;Inform applicant about the grant;recover;2015-05-03 13:36:27.070;\n\
14b-630;DL_WFCaseTypeStep!546;Applicant proves relevance of application;recover;2015-05-03 13:36:31.717;\n\
14b-630;DL_WFCaseTypeStep!522;Change Phase to Payout;administrator;2015-05-03 13:36:34.127;\n\
14b-630;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 13:36:34.130;\n\
14b-630;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 13:36:37.077;\n\
14b-630;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 13:40:11.277;\n\
14b-629;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 13:58:42.640;\n\
14b-629;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 13:58:46.050;\n\
14b-629;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 13:58:46.053;\n\
14b-629;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 13:58:49.213;\n\
14b-629;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 13:58:49.223;\n\
14b-629;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 13:58:49.370;\n\
14b-629;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 13:58:53.077;\n\
14b-629;DL_WFCaseTypeStep!478;Approve application;Automatic;2015-05-03 13:58:53.077;\n\
14b-629;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 13:58:53.213;\n\
14b-629;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 13:58:56.017;\n\
14b-629;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 13:58:56.020;\n\
14b-629;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 13:58:57.433;\n\
14b-629;RestartWorkflow;NULL;mmq;2015-05-03 14:05:14.780;\n\
14b-629;Initialize;NULL;administrator;2015-05-03 14:05:17.273;\n\
14b-629;DL_WFCaseTypeStep!497;Fill out Application;Ansøger;2015-05-03 14:05:25.573;\n\
14b-629;DL_WFCaseTypeStep!469;Approval - on to the board;recover;2015-05-03 14:05:43.787;\n\
14b-629;DL_WFCaseTypeStep!518;Change phase to review;administrator;2015-05-03 14:05:45.937;\n\
14b-629;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 14:05:46.013;\n\
14b-629;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 14:05:48.517;\n\
14b-629;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 14:05:49.253;\n\
14b-629;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 14:05:50.043;\n\
14b-629;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 14:05:50.850;\n\
14b-629;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 14:05:52.870;\n\
14b-629;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 14:05:52.877;\n\
14b-629;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 14:05:54.910;\n\
14b-629;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 14:05:54.913;\n\
14b-629;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;recover;2015-05-03 14:05:55.010;\n\
14b-629;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 14:05:57.820;\n\
14b-629;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 14:05:59.737;\n\
14b-629;DL_WFCaseTypeStep!478;Approve application;Automatic;2015-05-03 14:05:59.737;\n\
14b-629;DL_WFCaseTypeStep!482;Inform applicant about the grant;recover;2015-05-03 14:06:00.073;\n\
14b-629;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 14:06:02.370;\n\
14b-629;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 14:06:02.373;\n\
14b-629;DL_WFCaseTypeStep!546;Applicant proves relevance of application;recover;2015-05-03 14:06:04.237;\n\
14b-629;DL_WFCaseTypeStep!522;Change Phase to Payout;administrator;2015-05-03 14:06:07.680;\n\
14b-629;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 14:06:07.683;\n\
14b-629;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 14:06:10.047;\n\
14b-628;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 14:06:48.660;\n\
14b-628;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 14:06:49.913;\n\
14b-628;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 14:06:52.500;\n\
14b-628;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 14:06:53.510;\n\
14b-628;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 14:06:55.340;\n\
14b-628;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 14:06:55.347;\n\
14b-628;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 14:06:57.403;\n\
14b-628;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 14:06:58.367;\n\
14b-628;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 14:06:58.370;\n\
14b-628;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 14:06:58.470;\n\
14b-628;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 14:07:00.410;\n\
14b-628;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 14:07:04.697;\n\
14b-628;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 14:07:05.070;\n\
14b-628;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 14:07:41.407;\n\
14b-298;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:02.313;\n\
14b-298;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:06.730;\n\
14b-302;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:06.737;\n\
14b-302;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:09.450;\n\
14b-305;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:09.477;\n\
14b-305;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:11.920;\n\
14b-307;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:11.927;\n\
14b-307;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:14.100;\n\
14b-310;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:14.110;\n\
14b-310;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:16.223;\n\
14b-312;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:16.230;\n\
14b-312;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:18.440;\n\
14b-317;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:18.447;\n\
14b-317;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:20.590;\n\
14b-321;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:20.597;\n\
14b-321;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:22.690;\n\
14b-322;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:22.697;\n\
14b-322;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:25.073;\n\
14b-325;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:25.080;\n\
14b-325;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:27.343;\n\
14b-327;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:27.370;\n\
14b-327;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:29.513;\n\
14b-338;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:29.520;\n\
14b-338;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:31.927;\n\
14b-342;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:31.933;\n\
14b-342;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:34.130;\n\
14b-344;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:34.143;\n\
14b-344;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:36.307;\n\
14b-347;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:36.313;\n\
14b-347;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:39.127;\n\
14b-349;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:39.130;\n\
14b-349;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:41.383;\n\
14b-353;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:41.390;\n\
14b-353;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:43.507;\n\
14b-354;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:43.513;\n\
14b-354;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:45.853;\n\
14b-360;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:45.860;\n\
14b-360;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:48.030;\n\
14b-361;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:44:48.033;\n\
14b-361;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:50.223;\n\
14b-362;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:44:50.230;\n\
14b-362;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:52.400;\n\
14b-364;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 17:44:52.407;\n\
14b-364;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:54.777;\n\
14b-366;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:44:54.790;\n\
14b-366;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:57.107;\n\
14b-396;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 17:44:57.110;\n\
14b-396;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:44:59.290;\n\
14b-407;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 17:44:59.337;\n\
14b-407;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:45:03.077;\n\
14b-409;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 17:45:03.307;\n\
14b-409;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:45:05.373;\n\
14b-410;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 17:45:05.380;\n\
14b-410;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:45:07.587;\n\
14b-411;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 17:45:07.593;\n\
14b-298;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 17:45:20.150;\n\
14b-411;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:45:21.837;\n\
14b-299;DL_WFCaseTypeStep!471;Lawyer Review;suser;2015-05-03 17:45:23.190;\n\
14b-307;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 17:45:24.520;\n\
14b-321;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 17:45:25.860;\n\
14b-328;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 17:45:27.247;\n\
14b-338;DL_WFCaseTypeStep!471;Lawyer Review;ANONYMOUS LOGON;2015-05-03 17:45:28.650;\n\
14b-347;DL_WFCaseTypeStep!471;Lawyer Review;ANONYMOUS LOGON;2015-05-03 17:45:30.090;\n\
14b-349;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 17:45:32.980;\n\
14b-354;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 17:45:34.293;\n\
14b-361;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 17:45:35.563;\n\
14b-366;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:36.873;\n\
14b-409;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 17:45:38.410;\n\
14b-410;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 17:45:39.773;\n\
14b-411;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 17:45:41.263;\n\
14b-413;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 17:45:42.570;\n\
14b-446;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:43.833;\n\
14b-448;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:45.343;\n\
14b-462;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:46.963;\n\
14b-480;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:48.420;\n\
14b-493;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:49.887;\n\
14b-501;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:51.460;\n\
14b-512;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:52.937;\n\
14b-514;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:54.397;\n\
14b-515;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:55.660;\n\
14b-525;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:56.947;\n\
14b-533;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:58.250;\n\
14b-538;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:45:59.550;\n\
14b-564;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:46:00.820;\n\
14b-607;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:46:02.250;\n\
14b-620;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:46:03.443;\n\
14b-298;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:12.650;\n\
14b-302;DL_WFCaseTypeStep!472;Architect Review;mp;2015-05-03 17:46:13.513;\n\
14b-305;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 17:46:14.897;\n\
14b-307;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:16.047;\n\
14b-310;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 17:46:16.800;\n\
14b-312;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 17:46:18.277;\n\
14b-317;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 17:46:19.613;\n\
14b-321;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:21.030;\n\
14b-322;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 17:46:21.780;\n\
14b-325;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 17:46:23.177;\n\
14b-327;DL_WFCaseTypeStep!472;Architect Review;ANONYMOUS LOGON;2015-05-03 17:46:24.533;\n\
14b-328;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:25.920;\n\
14b-338;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:26.700;\n\
14b-342;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 17:46:27.460;\n\
14b-344;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 17:46:28.763;\n\
14b-347;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:30.033;\n\
14b-349;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:30.623;\n\
14b-353;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 17:46:31.183;\n\
14b-354;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:32.150;\n\
14b-360;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 17:46:32.743;\n\
14b-361;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:34.033;\n\
14b-362;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:34.777;\n\
14b-364;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 17:46:36.397;\n\
14b-366;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:37.700;\n\
14b-396;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 17:46:38.357;\n\
14b-407;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 17:46:39.843;\n\
14b-409;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:41.167;\n\
14b-410;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:41.800;\n\
14b-411;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:42.437;\n\
14b-413;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:43.020;\n\
14b-422;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 17:46:43.630;\n\
14b-425;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 17:46:44.817;\n\
14b-427;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 17:46:46.030;\n\
14b-432;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:47.277;\n\
14b-440;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:48.567;\n\
14b-446;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:49.923;\n\
14b-448;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:50.560;\n\
14b-458;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:51.160;\n\
14b-462;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:52.500;\n\
14b-464;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:53.137;\n\
14b-466;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:54.433;\n\
14b-467;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:55.703;\n\
14b-468;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:56.967;\n\
14b-469;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:58.360;\n\
14b-474;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:46:59.803;\n\
14b-475;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:01.137;\n\
14b-477;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:02.397;\n\
14b-478;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:03.713;\n\
14b-480;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:04.913;\n\
14b-483;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:05.510;\n\
14b-489;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:06.847;\n\
14b-491;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:08.150;\n\
14b-492;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:09.447;\n\
14b-493;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:10.743;\n\
14b-495;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:11.383;\n\
14b-496;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:12.717;\n\
14b-501;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:14.057;\n\
14b-502;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:14.687;\n\
14b-507;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:16.023;\n\
14b-508;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:17.283;\n\
14b-509;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:18.553;\n\
14b-510;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:19.783;\n\
14b-511;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:21.097;\n\
14b-512;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:22.373;\n\
14b-514;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:22.970;\n\
14b-515;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:23.580;\n\
14b-516;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:24.203;\n\
14b-518;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:25.447;\n\
14b-522;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:26.823;\n\
14b-525;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:28.310;\n\
14b-527;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:29.003;\n\
14b-529;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:30.260;\n\
14b-530;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:31.597;\n\
14b-533;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:32.950;\n\
14b-538;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:33.550;\n\
14b-539;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:34.237;\n\
14b-547;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:35.557;\n\
14b-549;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:37.037;\n\
14b-553;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:38.343;\n\
14b-561;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:39.837;\n\
14b-562;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:41.103;\n\
14b-564;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:42.593;\n\
14b-568;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:43.247;\n\
14b-570;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:44.543;\n\
14b-571;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:45.817;\n\
14b-574;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:47.037;\n\
14b-581;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:48.257;\n\
14b-582;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:49.593;\n\
14b-583;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:50.973;\n\
14b-585;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:52.210;\n\
14b-586;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:53.487;\n\
14b-589;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:54.743;\n\
14b-592;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:55.990;\n\
14b-593;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:57.390;\n\
14b-594;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:47:58.783;\n\
14b-595;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:00.063;\n\
14b-596;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:01.417;\n\
14b-599;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:02.927;\n\
14b-600;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:04.330;\n\
14b-602;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:05.690;\n\
14b-607;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:06.960;\n\
14b-608;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:07.553;\n\
14b-609;DL_WFCaseTypeStep!472;Architect Review;mp;2015-05-03 17:48:08.900;\n\
14b-611;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:10.307;\n\
14b-614;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:11.560;\n\
14b-617;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:12.890;\n\
14b-618;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:14.077;\n\
14b-619;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:15.283;\n\
14b-620;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:16.533;\n\
14b-621;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:17.153;\n\
14b-624;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:18.463;\n\
14b-625;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:19.743;\n\
14b-626;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:21.007;\n\
14b-627;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 17:48:22.240;\n\
14b-302;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:32.677;\n\
14b-305;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:33.480;\n\
14b-310;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:34.080;\n\
14b-312;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:34.697;\n\
14b-317;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:35.327;\n\
14b-322;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:35.990;\n\
14b-325;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:36.620;\n\
14b-327;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:37.220;\n\
14b-342;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:37.850;\n\
14b-344;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:38.460;\n\
14b-353;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:39.077;\n\
14b-360;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:39.713;\n\
14b-362;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:40.297;\n\
14b-364;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:40.907;\n\
14b-396;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:41.523;\n\
14b-407;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:42.393;\n\
14b-422;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:43.073;\n\
14b-425;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:43.693;\n\
14b-427;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:44.343;\n\
14b-432;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:44.943;\n\
14b-440;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:45.663;\n\
14b-458;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:46.337;\n\
14b-464;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:46.957;\n\
14b-466;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:47.570;\n\
14b-467;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:48.170;\n\
14b-468;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:48.780;\n\
14b-469;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:49.380;\n\
14b-474;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:49.977;\n\
14b-475;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:50.600;\n\
14b-477;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:51.207;\n\
14b-478;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:51.790;\n\
14b-483;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:52.380;\n\
14b-489;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:53.033;\n\
14b-491;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:53.710;\n\
14b-492;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:54.380;\n\
14b-495;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:55.077;\n\
14b-496;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:55.767;\n\
14b-502;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:56.360;\n\
14b-507;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:57.293;\n\
14b-508;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:57.900;\n\
14b-509;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:58.520;\n\
14b-510;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:59.120;\n\
14b-511;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:48:59.713;\n\
14b-516;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:00.320;\n\
14b-518;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:00.950;\n\
14b-522;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:01.650;\n\
14b-527;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:02.240;\n\
14b-529;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:02.843;\n\
14b-530;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:03.547;\n\
14b-539;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:04.177;\n\
14b-547;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:04.790;\n\
14b-549;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:05.423;\n\
14b-553;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:06.047;\n\
14b-561;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:06.697;\n\
14b-562;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:07.300;\n\
14b-568;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:07.903;\n\
14b-570;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:08.517;\n\
14b-571;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:09.140;\n\
14b-574;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:09.770;\n\
14b-581;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:10.357;\n\
14b-582;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:11.010;\n\
14b-583;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:11.620;\n\
14b-585;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:12.213;\n\
14b-586;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:12.803;\n\
14b-589;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:13.430;\n\
14b-592;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:14.047;\n\
14b-593;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:14.657;\n\
14b-594;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:15.303;\n\
14b-595;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:16.990;\n\
14b-596;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:17.747;\n\
14b-599;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:18.460;\n\
14b-600;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:19.237;\n\
14b-602;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:20.043;\n\
14b-608;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:20.793;\n\
14b-609;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:21.420;\n\
14b-611;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:22.113;\n\
14b-614;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:22.727;\n\
14b-617;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:23.343;\n\
14b-618;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:23.970;\n\
14b-619;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:24.570;\n\
14b-621;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:25.240;\n\
14b-624;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:25.837;\n\
14b-625;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:26.433;\n\
14b-626;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:27.050;\n\
14b-627;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 17:49:27.750;\n\
14b-298;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:49:59.267;\n\
14b-299;DL_WFCaseTypeStep!473;Review;suser;2015-05-03 17:50:00.090;\n\
14b-302;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:00.983;\n\
14b-305;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:03.417;\n\
14b-307;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:03.970;\n\
14b-310;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:04.530;\n\
14b-312;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:05.143;\n\
14b-317;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:05.833;\n\
14b-321;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:06.470;\n\
14b-322;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:07.070;\n\
14b-325;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:07.690;\n\
14b-327;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:08.307;\n\
14b-328;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:08.900;\n\
14b-338;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:09.633;\n\
14b-342;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:10.257;\n\
14b-344;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:10.907;\n\
14b-347;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:11.553;\n\
14b-349;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:12.213;\n\
14b-353;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:12.877;\n\
14b-354;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:13.557;\n\
14b-360;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:14.180;\n\
14b-361;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:14.793;\n\
14b-362;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:15.440;\n\
14b-364;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:16.217;\n\
14b-366;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:16.867;\n\
14b-396;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:17.513;\n\
14b-407;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:18.200;\n\
14b-409;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:18.857;\n\
14b-410;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:19.537;\n\
14b-411;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:20.157;\n\
14b-413;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:20.810;\n\
14b-422;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:21.420;\n\
14b-425;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:22.023;\n\
14b-427;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:22.650;\n\
14b-432;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:23.343;\n\
14b-440;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:23.957;\n\
14b-446;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:24.693;\n\
14b-448;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:25.350;\n\
14b-458;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:25.960;\n\
14b-462;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:26.653;\n\
14b-464;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:27.387;\n\
14b-466;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:28.030;\n\
14b-467;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:28.720;\n\
14b-468;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:29.320;\n\
14b-469;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:29.937;\n\
14b-474;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:30.543;\n\
14b-475;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:31.203;\n\
14b-477;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:31.870;\n\
14b-478;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:32.540;\n\
14b-480;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:33.187;\n\
14b-483;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:33.907;\n\
14b-489;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:34.600;\n\
14b-491;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:35.253;\n\
14b-492;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:35.910;\n\
14b-493;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:36.537;\n\
14b-495;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:37.170;\n\
14b-496;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:37.800;\n\
14b-501;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:38.430;\n\
14b-502;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:39.120;\n\
14b-507;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:39.790;\n\
14b-508;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:40.413;\n\
14b-509;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:41.033;\n\
14b-510;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:41.727;\n\
14b-511;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:42.343;\n\
14b-512;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:43.020;\n\
14b-514;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:43.643;\n\
14b-515;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:44.337;\n\
14b-516;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:44.980;\n\
14b-518;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:45.810;\n\
14b-522;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:46.470;\n\
14b-525;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:47.233;\n\
14b-527;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:47.943;\n\
14b-529;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:48.600;\n\
14b-530;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:49.253;\n\
14b-533;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:49.900;\n\
14b-538;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:50.547;\n\
14b-539;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:51.220;\n\
14b-547;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:51.843;\n\
14b-549;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:52.660;\n\
14b-553;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:53.437;\n\
14b-561;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:54.130;\n\
14b-562;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:54.797;\n\
14b-564;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:55.533;\n\
14b-568;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:56.197;\n\
14b-570;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:56.827;\n\
14b-571;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:57.857;\n\
14b-574;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:58.727;\n\
14b-581;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:59.337;\n\
14b-582;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:50:59.957;\n\
14b-583;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:00.587;\n\
14b-585;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:01.243;\n\
14b-586;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:01.853;\n\
14b-589;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:02.463;\n\
14b-592;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:03.137;\n\
14b-593;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:03.800;\n\
14b-594;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:04.413;\n\
14b-595;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:05.030;\n\
14b-596;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:05.643;\n\
14b-599;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:06.287;\n\
14b-600;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:06.960;\n\
14b-602;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:07.670;\n\
14b-607;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:08.357;\n\
14b-608;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:09.010;\n\
14b-609;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:09.770;\n\
14b-611;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:10.417;\n\
14b-614;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:11.050;\n\
14b-617;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:11.717;\n\
14b-618;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:12.343;\n\
14b-619;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:12.967;\n\
14b-620;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:13.600;\n\
14b-621;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:14.310;\n\
14b-624;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:14.960;\n\
14b-625;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:15.680;\n\
14b-626;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:16.363;\n\
14b-627;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 17:51:17.040;\n\
14b-298;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:51:52.907;\n\
14b-299;DL_WFCaseTypeStep!474;Review;suser;2015-05-03 17:51:53.707;\n\
14b-302;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:51:54.343;\n\
14b-305;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:51:54.977;\n\
14b-307;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:51:55.710;\n\
14b-310;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:51:56.350;\n\
14b-312;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:51:56.973;\n\
14b-317;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:51:57.670;\n\
14b-321;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:51:58.693;\n\
14b-322;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:51:59.417;\n\
14b-325;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:00.087;\n\
14b-327;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:00.890;\n\
14b-328;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:01.590;\n\
14b-338;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:02.270;\n\
14b-342;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:02.883;\n\
14b-344;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:03.593;\n\
14b-347;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:04.260;\n\
14b-349;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:04.870;\n\
14b-353;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:05.510;\n\
14b-354;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:06.110;\n\
14b-360;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:06.723;\n\
14b-361;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:07.350;\n\
14b-362;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:07.973;\n\
14b-364;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:08.603;\n\
14b-366;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:09.240;\n\
14b-396;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:09.890;\n\
14b-407;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:10.633;\n\
14b-409;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:11.277;\n\
14b-410;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:11.903;\n\
14b-411;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:12.543;\n\
14b-413;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:13.230;\n\
14b-422;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:13.847;\n\
14b-425;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:14.473;\n\
14b-427;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:15.080;\n\
14b-432;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:15.740;\n\
14b-440;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:16.427;\n\
14b-446;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:17.053;\n\
14b-448;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:17.737;\n\
14b-458;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:18.377;\n\
14b-462;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:19.053;\n\
14b-464;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:19.790;\n\
14b-466;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:20.470;\n\
14b-467;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:21.113;\n\
14b-468;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:21.743;\n\
14b-469;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:22.437;\n\
14b-474;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:23.070;\n\
14b-475;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:23.747;\n\
14b-477;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:24.383;\n\
14b-478;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:25.040;\n\
14b-480;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:25.620;\n\
14b-483;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:26.200;\n\
14b-489;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:26.820;\n\
14b-491;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:27.563;\n\
14b-492;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:28.210;\n\
14b-493;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:28.837;\n\
14b-495;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:29.593;\n\
14b-496;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:30.253;\n\
14b-501;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:30.977;\n\
14b-502;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:31.650;\n\
14b-507;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:32.307;\n\
14b-508;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:32.910;\n\
14b-509;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:33.547;\n\
14b-510;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:34.200;\n\
14b-511;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:34.830;\n\
14b-512;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:35.457;\n\
14b-514;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:36.157;\n\
14b-515;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:36.773;\n\
14b-516;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:37.453;\n\
14b-518;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:38.083;\n\
14b-522;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:38.737;\n\
14b-525;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:39.377;\n\
14b-527;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:40.033;\n\
14b-529;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:40.690;\n\
14b-530;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:41.337;\n\
14b-533;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:41.943;\n\
14b-538;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:42.643;\n\
14b-539;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:43.357;\n\
14b-547;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:44.000;\n\
14b-549;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:44.653;\n\
14b-553;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:45.300;\n\
14b-561;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:45.950;\n\
14b-562;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:46.630;\n\
14b-564;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:47.323;\n\
14b-568;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:47.960;\n\
14b-570;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:48.593;\n\
14b-571;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:49.283;\n\
14b-574;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:50.087;\n\
14b-581;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:50.780;\n\
14b-582;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:51.517;\n\
14b-583;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:52.167;\n\
14b-585;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:52.833;\n\
14b-586;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:53.477;\n\
14b-589;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:54.110;\n\
14b-592;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:54.773;\n\
14b-593;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:55.430;\n\
14b-594;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:56.583;\n\
14b-595;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:57.620;\n\
14b-596;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:58.230;\n\
14b-599;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:58.850;\n\
14b-600;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:52:59.523;\n\
14b-602;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:00.180;\n\
14b-607;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:00.863;\n\
14b-608;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:01.520;\n\
14b-609;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:02.153;\n\
14b-611;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:02.803;\n\
14b-614;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:03.463;\n\
14b-617;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:04.120;\n\
14b-618;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:04.833;\n\
14b-619;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:05.513;\n\
14b-620;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:06.130;\n\
14b-621;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:06.763;\n\
14b-624;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:07.510;\n\
14b-625;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:08.140;\n\
14b-626;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:08.793;\n\
14b-627;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 17:53:09.473;\n\
14b-413;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 17:53:23.787;\n\
14b-413;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:25.593;\n\
14b-422;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 17:53:25.600;\n\
14b-422;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:28.310;\n\
14b-425;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 17:53:28.313;\n\
14b-425;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:30.680;\n\
14b-427;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 17:53:30.683;\n\
14b-427;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:33.150;\n\
14b-432;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:53:33.157;\n\
14b-432;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:35.767;\n\
14b-440;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:53:35.777;\n\
14b-440;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:38.340;\n\
14b-446;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:53:38.347;\n\
14b-446;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:40.937;\n\
14b-448;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:53:40.940;\n\
14b-448;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:43.357;\n\
14b-458;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:53:43.363;\n\
14b-458;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:45.913;\n\
14b-462;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:53:45.920;\n\
14b-462;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:48.663;\n\
14b-464;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:53:48.673;\n\
14b-464;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:51.087;\n\
14b-466;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:53:51.130;\n\
14b-466;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:53.620;\n\
14b-467;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:53:53.623;\n\
14b-467;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:56.170;\n\
14b-468;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:53:56.177;\n\
14b-468;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:53:58.990;\n\
14b-469;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:53:58.997;\n\
14b-469;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:01.570;\n\
14b-474;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:01.600;\n\
14b-474;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:04.063;\n\
14b-475;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:04.070;\n\
14b-475;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:06.510;\n\
14b-477;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:06.517;\n\
14b-477;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:08.937;\n\
14b-478;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:08.940;\n\
14b-478;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:11.347;\n\
14b-480;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:11.353;\n\
14b-480;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:13.803;\n\
14b-483;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:13.850;\n\
14b-483;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:16.297;\n\
14b-489;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:16.303;\n\
14b-489;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:18.687;\n\
14b-491;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:18.690;\n\
14b-491;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:21.230;\n\
14b-492;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:21.237;\n\
14b-492;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:23.733;\n\
14b-493;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:23.740;\n\
14b-493;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:26.130;\n\
14b-495;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:26.137;\n\
14b-495;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:28.380;\n\
14b-496;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:28.387;\n\
14b-496;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:30.963;\n\
14b-501;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:30.970;\n\
14b-501;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:33.547;\n\
14b-502;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:33.553;\n\
14b-502;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:36.140;\n\
14b-507;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:36.147;\n\
14b-507;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:38.503;\n\
14b-508;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:38.510;\n\
14b-508;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:40.930;\n\
14b-509;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:40.937;\n\
14b-509;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:43.470;\n\
14b-510;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:43.520;\n\
14b-510;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:46.057;\n\
14b-511;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:46.063;\n\
14b-511;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:48.467;\n\
14b-512;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:48.470;\n\
14b-512;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:50.997;\n\
14b-514;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:51.007;\n\
14b-514;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:53.540;\n\
14b-515;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:53.547;\n\
14b-515;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:56.203;\n\
14b-516;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:56.210;\n\
14b-516;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:54:58.733;\n\
14b-518;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:54:58.740;\n\
14b-518;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:01.597;\n\
14b-522;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:01.623;\n\
14b-522;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:05.100;\n\
14b-525;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:05.160;\n\
14b-525;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:07.417;\n\
14b-527;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:07.430;\n\
14b-527;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:09.740;\n\
14b-529;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:09.743;\n\
14b-529;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:12.290;\n\
14b-530;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:12.300;\n\
14b-530;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:14.753;\n\
14b-533;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:14.763;\n\
14b-533;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:17.230;\n\
14b-538;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:17.233;\n\
14b-538;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:19.680;\n\
14b-539;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:19.683;\n\
14b-539;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:22.360;\n\
14b-547;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:22.367;\n\
14b-547;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:24.763;\n\
14b-549;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:24.773;\n\
14b-549;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:27.223;\n\
14b-553;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:27.230;\n\
14b-553;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:29.893;\n\
14b-561;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:29.903;\n\
14b-561;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:33.373;\n\
14b-562;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:33.377;\n\
14b-562;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:35.930;\n\
14b-564;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:35.937;\n\
14b-564;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:38.333;\n\
14b-568;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:38.337;\n\
14b-568;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:40.653;\n\
14b-570;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:40.660;\n\
14b-570;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:43.100;\n\
14b-571;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:43.107;\n\
14b-571;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:45.520;\n\
14b-574;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:45.523;\n\
14b-574;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:47.957;\n\
14b-581;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:47.960;\n\
14b-581;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:50.350;\n\
14b-582;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:50.763;\n\
14b-582;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:53.313;\n\
14b-583;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:53.320;\n\
14b-583;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:55.817;\n\
14b-585;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:55.820;\n\
14b-585;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:55:58.450;\n\
14b-586;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:55:58.453;\n\
14b-586;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:01.390;\n\
14b-589;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:01.397;\n\
14b-589;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:03.537;\n\
14b-592;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:03.540;\n\
14b-592;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:05.870;\n\
14b-593;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:05.873;\n\
14b-593;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:08.380;\n\
14b-594;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:08.387;\n\
14b-594;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:10.893;\n\
14b-595;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:10.900;\n\
14b-595;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:13.333;\n\
14b-596;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:13.340;\n\
14b-596;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:15.777;\n\
14b-599;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:15.780;\n\
14b-599;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:18.080;\n\
14b-600;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:18.087;\n\
14b-600;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:20.733;\n\
14b-602;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:20.740;\n\
14b-602;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:23.230;\n\
14b-607;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:23.237;\n\
14b-607;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:25.760;\n\
14b-608;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:25.763;\n\
14b-608;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:28.273;\n\
14b-609;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:28.280;\n\
14b-609;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:30.633;\n\
14b-611;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:30.640;\n\
14b-611;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:32.927;\n\
14b-614;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:32.940;\n\
14b-614;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:35.473;\n\
14b-617;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:35.543;\n\
14b-617;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:38.010;\n\
14b-618;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:38.013;\n\
14b-618;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:40.407;\n\
14b-619;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:40.413;\n\
14b-619;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:42.850;\n\
14b-620;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:42.853;\n\
14b-620;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:45.257;\n\
14b-621;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:45.260;\n\
14b-621;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:47.753;\n\
14b-624;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:47.767;\n\
14b-624;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:50.190;\n\
14b-625;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:50.193;\n\
14b-625;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:52.603;\n\
14b-626;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:52.610;\n\
14b-626;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:55.173;\n\
14b-627;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 17:56:55.180;\n\
14b-627;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 17:56:57.570;\n\
14b-306;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 18:14:11.997;\n\
14b-313;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 18:14:14.690;\n\
14b-318;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 18:14:16.067;\n\
14b-319;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 18:14:17.580;\n\
14b-358;DL_WFCaseTypeStep!471;Lawyer Review;ls;2015-05-03 18:14:19.140;\n\
14b-368;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:14:20.463;\n\
14b-416;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:14:21.873;\n\
14b-429;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:23.307;\n\
14b-471;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:24.757;\n\
14b-472;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:26.283;\n\
14b-476;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:27.660;\n\
14b-488;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:29.200;\n\
14b-503;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:30.597;\n\
14b-505;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:32.287;\n\
14b-537;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:33.810;\n\
14b-556;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:35.323;\n\
14b-569;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:36.820;\n\
14b-572;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:38.247;\n\
14b-577;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:39.690;\n\
14b-578;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:14:41.077;\n\
14b-303;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:14:48.983;\n\
14b-306;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:14:50.310;\n\
14b-309;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:14:50.900;\n\
14b-311;DL_WFCaseTypeStep!472;Architect Review;mp;2015-05-03 18:14:52.100;\n\
14b-313;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:14:53.647;\n\
14b-314;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:14:54.233;\n\
14b-315;DL_WFCaseTypeStep!472;Architect Review;mp;2015-05-03 18:14:55.420;\n\
14b-318;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:14:56.603;\n\
14b-319;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:14:57.200;\n\
14b-320;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:14:58.163;\n\
14b-323;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:14:59.543;\n\
14b-326;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:15:00.990;\n\
14b-337;DL_WFCaseTypeStep!472;Architect Review;mp;2015-05-03 18:15:03.573;\n\
14b-341;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:15:04.833;\n\
14b-350;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:15:06.130;\n\
14b-358;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:07.337;\n\
14b-359;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:15:07.990;\n\
14b-368;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:09.183;\n\
14b-376;DL_WFCaseTypeStep!472;Architect Review;ANONYMOUS LOGON;2015-05-03 18:15:09.767;\n\
14b-395;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 18:15:10.947;\n\
14b-416;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:12.177;\n\
14b-429;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:12.830;\n\
14b-433;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:13.433;\n\
14b-438;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:14.780;\n\
14b-471;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:16.017;\n\
14b-472;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:16.670;\n\
14b-476;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:17.307;\n\
14b-482;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:18.007;\n\
14b-484;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:19.280;\n\
14b-488;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:20.507;\n\
14b-497;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:21.120;\n\
14b-500;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:22.437;\n\
14b-503;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:23.960;\n\
14b-505;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:24.553;\n\
14b-517;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:25.143;\n\
14b-528;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:26.447;\n\
14b-535;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:27.747;\n\
14b-537;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:29.277;\n\
14b-541;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:29.890;\n\
14b-542;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:32.810;\n\
14b-546;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:34.033;\n\
14b-550;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:35.270;\n\
14b-556;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:36.530;\n\
14b-567;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:37.210;\n\
14b-569;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:38.553;\n\
14b-572;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:39.177;\n\
14b-575;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:39.817;\n\
14b-577;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:41.120;\n\
14b-578;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:41.753;\n\
14b-587;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:42.343;\n\
14b-591;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:43.623;\n\
14b-598;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:44.880;\n\
14b-604;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:46.230;\n\
14b-605;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:15:47.570;\n\
14b-303;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:15:52.543;\n\
14b-309;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:15:53.233;\n\
14b-311;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:15:53.997;\n\
14b-314;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:15:54.630;\n\
14b-315;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:15:55.217;\n\
14b-320;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:15:55.823;\n\
14b-323;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:15:56.443;\n\
14b-326;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:15:57.057;\n\
14b-337;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:15:57.800;\n\
14b-341;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:15:58.403;\n\
14b-350;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:15:59.453;\n\
14b-359;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:00.080;\n\
14b-376;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:00.673;\n\
14b-395;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:01.253;\n\
14b-433;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:01.883;\n\
14b-438;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:02.513;\n\
14b-482;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:03.063;\n\
14b-484;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:03.653;\n\
14b-497;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:04.140;\n\
14b-500;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:04.620;\n\
14b-517;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:05.210;\n\
14b-528;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:05.803;\n\
14b-535;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:06.397;\n\
14b-541;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:06.993;\n\
14b-542;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:07.697;\n\
14b-546;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:08.363;\n\
14b-550;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:08.960;\n\
14b-567;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:09.630;\n\
14b-575;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:10.253;\n\
14b-587;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:10.883;\n\
14b-591;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:11.510;\n\
14b-598;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:12.127;\n\
14b-604;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:12.790;\n\
14b-605;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:16:13.427;\n\
14b-303;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:20.420;\n\
14b-306;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:21.123;\n\
14b-309;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:21.730;\n\
14b-311;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:22.370;\n\
14b-313;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:22.953;\n\
14b-314;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:23.643;\n\
14b-315;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:24.417;\n\
14b-318;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:25.063;\n\
14b-319;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:25.670;\n\
14b-320;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:26.250;\n\
14b-323;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:26.860;\n\
14b-326;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:27.467;\n\
14b-337;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:28.103;\n\
14b-341;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:28.760;\n\
14b-350;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:29.367;\n\
14b-358;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:30.050;\n\
14b-359;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:30.657;\n\
14b-368;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:31.263;\n\
14b-376;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:31.860;\n\
14b-395;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:32.503;\n\
14b-416;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:33.160;\n\
14b-429;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:33.767;\n\
14b-433;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:34.447;\n\
14b-438;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:35.113;\n\
14b-471;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:35.793;\n\
14b-472;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:36.427;\n\
14b-476;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:37.060;\n\
14b-482;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:37.683;\n\
14b-484;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:38.370;\n\
14b-488;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:39.110;\n\
14b-497;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:39.740;\n\
14b-500;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:40.450;\n\
14b-503;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:41.080;\n\
14b-505;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:41.727;\n\
14b-517;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:42.350;\n\
14b-528;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:42.977;\n\
14b-535;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:43.620;\n\
14b-537;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:44.270;\n\
14b-541;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:44.880;\n\
14b-542;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:45.667;\n\
14b-546;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:46.357;\n\
14b-550;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:46.970;\n\
14b-556;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:47.590;\n\
14b-567;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:48.307;\n\
14b-569;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:48.970;\n\
14b-572;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:49.627;\n\
14b-575;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:50.240;\n\
14b-577;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:50.893;\n\
14b-578;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:51.547;\n\
14b-587;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:52.217;\n\
14b-591;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:52.863;\n\
14b-598;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:53.490;\n\
14b-604;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:54.247;\n\
14b-605;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:16:54.890;\n\
14b-303;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:16:58.503;\n\
14b-306;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:16:59.190;\n\
14b-309;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:16:59.817;\n\
14b-311;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:00.420;\n\
14b-313;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:01.103;\n\
14b-314;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:01.697;\n\
14b-315;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:02.210;\n\
14b-318;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:02.703;\n\
14b-319;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:03.200;\n\
14b-320;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:03.790;\n\
14b-323;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:04.440;\n\
14b-326;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:05.117;\n\
14b-337;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:05.720;\n\
14b-341;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:06.303;\n\
14b-350;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:06.900;\n\
14b-358;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:07.530;\n\
14b-359;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:08.120;\n\
14b-368;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:08.707;\n\
14b-376;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:09.323;\n\
14b-395;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:09.920;\n\
14b-416;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:10.610;\n\
14b-429;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:11.253;\n\
14b-433;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:11.893;\n\
14b-438;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:12.560;\n\
14b-471;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:13.193;\n\
14b-472;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:13.813;\n\
14b-476;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:14.437;\n\
14b-482;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:15.147;\n\
14b-484;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:15.787;\n\
14b-488;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:16.527;\n\
14b-497;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:17.267;\n\
14b-500;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:17.927;\n\
14b-503;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:18.560;\n\
14b-505;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:19.220;\n\
14b-517;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:19.960;\n\
14b-528;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:20.593;\n\
14b-535;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:21.220;\n\
14b-537;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:21.920;\n\
14b-541;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:22.673;\n\
14b-542;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:23.320;\n\
14b-546;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:23.967;\n\
14b-550;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:24.590;\n\
14b-556;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:25.273;\n\
14b-567;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:25.933;\n\
14b-569;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:26.647;\n\
14b-572;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:27.250;\n\
14b-575;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:27.943;\n\
14b-577;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:28.813;\n\
14b-578;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:29.447;\n\
14b-587;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:30.170;\n\
14b-591;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:30.857;\n\
14b-598;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:31.543;\n\
14b-604;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:32.200;\n\
14b-605;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:17:32.820;\n\
14b-303;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:18:32.900;\n\
14b-303;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:18:34.670;\n\
14b-306;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:18:34.677;\n\
14b-306;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:18:36.850;\n\
14b-309;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:18:36.857;\n\
14b-309;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:18:39.140;\n\
14b-311;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:18:39.210;\n\
14b-311;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:18:41.527;\n\
14b-313;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:18:41.533;\n\
14b-313;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:18:43.943;\n\
14b-314;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:18:43.950;\n\
14b-314;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:18:46.220;\n\
14b-315;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:18:46.227;\n\
14b-315;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:18:48.470;\n\
14b-318;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:18:48.473;\n\
14b-318;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:18:50.653;\n\
14b-319;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:18:50.660;\n\
14b-319;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:18:52.943;\n\
14b-320;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:18:52.950;\n\
14b-320;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:18:55.310;\n\
14b-323;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:18:55.317;\n\
14b-323;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:18:57.780;\n\
14b-326;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:18:57.783;\n\
14b-326;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:00.020;\n\
14b-337;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:19:00.030;\n\
14b-337;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:02.420;\n\
14b-341;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:19:02.440;\n\
14b-341;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:04.880;\n\
14b-350;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:19:04.887;\n\
14b-350;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:07.123;\n\
14b-358;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:19:07.127;\n\
14b-358;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:09.470;\n\
14b-359;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:19:09.477;\n\
14b-359;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:11.847;\n\
14b-368;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:19:11.853;\n\
14b-368;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:14.270;\n\
14b-376;DL_WFCaseTypeStep!475;Register Decision;ANONYMOUS LOGON;2015-05-03 18:19:14.300;\n\
14b-376;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:16.747;\n\
14b-395;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:19:16.750;\n\
14b-395;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:19.203;\n\
14b-416;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:19:19.210;\n\
14b-416;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:21.537;\n\
14b-429;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:21.540;\n\
14b-429;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:24.020;\n\
14b-433;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:24.023;\n\
14b-433;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:26.503;\n\
14b-438;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:26.510;\n\
14b-438;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:29.197;\n\
14b-471;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:29.203;\n\
14b-471;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:31.533;\n\
14b-472;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:31.540;\n\
14b-472;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:34.003;\n\
14b-476;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:34.020;\n\
14b-476;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:37.023;\n\
14b-482;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:37.030;\n\
14b-482;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:39.443;\n\
14b-484;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:39.447;\n\
14b-484;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:41.930;\n\
14b-488;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:41.937;\n\
14b-488;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:44.723;\n\
14b-497;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:44.730;\n\
14b-497;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:47.553;\n\
14b-500;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:47.557;\n\
14b-500;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:50.150;\n\
14b-503;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:50.157;\n\
14b-503;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:52.590;\n\
14b-505;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:52.617;\n\
14b-505;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:54.973;\n\
14b-517;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:54.990;\n\
14b-517;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:19:58.060;\n\
14b-528;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:19:58.070;\n\
14b-528;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:01.190;\n\
14b-535;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:01.203;\n\
14b-535;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:03.770;\n\
14b-537;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:03.817;\n\
14b-537;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:06.930;\n\
14b-541;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:06.937;\n\
14b-541;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:09.513;\n\
14b-542;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:09.520;\n\
14b-542;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:12.027;\n\
14b-546;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:12.030;\n\
14b-546;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:14.463;\n\
14b-550;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:14.470;\n\
14b-550;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:17.527;\n\
14b-556;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:17.533;\n\
14b-556;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:20.130;\n\
14b-567;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:20.140;\n\
14b-567;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:22.723;\n\
14b-569;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:22.727;\n\
14b-569;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:25.050;\n\
14b-572;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:25.057;\n\
14b-572;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:27.603;\n\
14b-575;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:27.610;\n\
14b-575;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:30.087;\n\
14b-577;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:30.097;\n\
14b-577;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:32.773;\n\
14b-578;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:32.780;\n\
14b-578;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:35.900;\n\
14b-587;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:35.993;\n\
14b-587;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:39.183;\n\
14b-591;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:39.187;\n\
14b-591;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:42.057;\n\
14b-598;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:42.060;\n\
14b-598;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:44.730;\n\
14b-604;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:44.740;\n\
14b-299;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:20:44.927;\n\
14b-299;DL_WFCaseTypeStep!472;Architect Review;mp;2015-05-03 18:20:44.930;\n\
14b-604;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:47.267;\n\
14b-605;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:20:47.273;\n\
14b-299;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:47.940;\n\
14b-605;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:20:50.863;\n\
14b-320;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:23:53.123;\n\
14b-328;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:26:27.057;\n\
14b-465;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:27:42.810;\n\
14b-328;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:27:45.163;\n\
14b-352;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:27:52.010;\n\
14b-380;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 18:27:54.867;\n\
14b-456;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:27:56.247;\n\
14b-465;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:27:57.827;\n\
14b-560;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:27:58.630;\n\
14b-352;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:28:03.487;\n\
14b-380;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:28:04.303;\n\
14b-456;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:28:05.097;\n\
14b-560;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:28:05.873;\n\
14b-352;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:28:08.487;\n\
14b-380;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:28:09.287;\n\
14b-456;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:28:10.120;\n\
14b-465;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:28:11.030;\n\
14b-560;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:28:11.903;\n\
14b-352;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:28:13.647;\n\
14b-380;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:28:14.540;\n\
14b-456;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:28:15.340;\n\
14b-465;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:28:16.147;\n\
14b-560;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:28:17.067;\n\
14b-352;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:28:35.113;\n\
14b-352;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:28:36.780;\n\
14b-380;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:28:36.787;\n\
14b-380;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:28:39.247;\n\
14b-456;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:28:39.250;\n\
14b-456;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:28:41.840;\n\
14b-465;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:28:41.847;\n\
14b-465;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:28:44.353;\n\
14b-560;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:28:44.363;\n\
14b-560;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:28:46.810;\n\
14b-352;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:28:53.423;\n\
14b-352;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:28:55.073;\n\
14b-352;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:28:55.077;\n\
14b-352;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 18:28:55.140;\n\
14b-352;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 18:28:57.417;\n\
14b-352;DL_WFCaseTypeStep!478;Approve application;Automatic;2015-05-03 18:28:57.417;\n\
14b-352;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 18:28:57.527;\n\
14b-352;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:28:59.840;\n\
14b-352;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:28:59.843;\n\
14b-352;DL_WFCaseTypeStep!482;Inform applicant about the grant;recover;2015-05-03 18:29:00.390;\n\
14b-352;DL_WFCaseTypeStep!546;Applicant proves relevance of application;recover;2015-05-03 18:29:03.983;\n\
14b-352;DL_WFCaseTypeStep!522;Change Phase to Payout;administrator;2015-05-03 18:29:05.877;\n\
14b-352;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:29:05.883;\n\
14b-352;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:29:07.827;\n\
14b-380;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:29:08.983;\n\
14b-380;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:29:10.040;\n\
14b-380;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:29:10.050;\n\
14b-380;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 18:29:10.157;\n\
14b-380;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 18:29:12.673;\n\
14b-380;DL_WFCaseTypeStep!478;Approve application;Automatic;2015-05-03 18:29:12.673;\n\
14b-380;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 18:29:12.767;\n\
14b-380;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:29:15.073;\n\
14b-380;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:29:15.077;\n\
14b-380;DL_WFCaseTypeStep!482;Inform applicant about the grant;recover;2015-05-03 18:29:15.690;\n\
14b-380;DL_WFCaseTypeStep!546;Applicant proves relevance of application;recover;2015-05-03 18:29:19.297;\n\
14b-380;DL_WFCaseTypeStep!522;Change Phase to Payout;administrator;2015-05-03 18:29:20.930;\n\
14b-380;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:29:20.933;\n\
14b-380;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:29:22.847;\n\
14b-456;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:29:24.027;\n\
14b-456;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:29:25.017;\n\
14b-456;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:29:25.020;\n\
14b-456;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 18:29:25.117;\n\
14b-456;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 18:29:27.440;\n\
14b-456;DL_WFCaseTypeStep!478;Approve application;Automatic;2015-05-03 18:29:27.440;\n\
14b-456;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 18:29:27.550;\n\
14b-456;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:29:30.137;\n\
14b-456;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:29:30.140;\n\
14b-456;DL_WFCaseTypeStep!482;Inform applicant about the grant;recover;2015-05-03 18:29:30.740;\n\
14b-456;DL_WFCaseTypeStep!546;Applicant proves relevance of application;recover;2015-05-03 18:29:34.223;\n\
14b-456;DL_WFCaseTypeStep!522;Change Phase to Payout;administrator;2015-05-03 18:29:35.900;\n\
14b-456;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:29:35.903;\n\
14b-456;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:29:38.043;\n\
14b-465;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:29:39.363;\n\
14b-465;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:29:40.373;\n\
14b-465;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:29:40.377;\n\
14b-465;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 18:29:40.490;\n\
14b-465;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 18:29:42.940;\n\
14b-465;DL_WFCaseTypeStep!478;Approve application;Automatic;2015-05-03 18:29:42.940;\n\
14b-465;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 18:29:43.077;\n\
14b-465;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:29:45.453;\n\
14b-465;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:29:45.457;\n\
14b-465;DL_WFCaseTypeStep!482;Inform applicant about the grant;recover;2015-05-03 18:29:46.023;\n\
14b-465;DL_WFCaseTypeStep!546;Applicant proves relevance of application;recover;2015-05-03 18:29:49.383;\n\
14b-465;DL_WFCaseTypeStep!522;Change Phase to Payout;administrator;2015-05-03 18:29:50.997;\n\
14b-465;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:29:51.030;\n\
14b-465;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:29:53.073;\n\
14b-560;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:29:54.487;\n\
14b-560;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:29:55.480;\n\
14b-560;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:29:55.483;\n\
14b-560;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 18:29:55.580;\n\
14b-560;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 18:29:57.967;\n\
14b-560;DL_WFCaseTypeStep!478;Approve application;Automatic;2015-05-03 18:29:57.967;\n\
14b-560;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 18:29:58.077;\n\
14b-560;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:30:00.510;\n\
14b-560;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:30:00.513;\n\
14b-560;DL_WFCaseTypeStep!482;Inform applicant about the grant;recover;2015-05-03 18:30:01.193;\n\
14b-560;DL_WFCaseTypeStep!546;Applicant proves relevance of application;recover;2015-05-03 18:30:06.007;\n\
14b-560;DL_WFCaseTypeStep!522;Change Phase to Payout;administrator;2015-05-03 18:30:07.727;\n\
14b-560;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:30:07.730;\n\
14b-560;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:30:09.690;\n\
14b-447;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:30:23.933;\n\
14b-447;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:30:27.477;\n\
14b-447;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:30:34.330;\n\
14b-447;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:30:37.173;\n\
14b-447;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:30:39.753;\n\
14b-447;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:30:41.520;\n\
14b-447;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:30:44.040;\n\
14b-447;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:30:45.970;\n\
14b-447;DL_WFCaseTypeStep!550;Carry out lapses;recover;2015-05-03 18:30:47.387;\n\
14b-447;DL_WFCaseTypeStep!551;Change Phase to Lapsed;administrator;2015-05-03 18:30:50.217;\n\
14b-447;DL_WFCaseTypeStep!551;Change Phase to Lapsed;recover;2015-05-03 18:30:51.200;\n\
14b-355;DL_WFCaseTypeStep!471;Lawyer Review;ANONYMOUS LOGON;2015-05-03 18:31:06.553;\n\
14b-381;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:31:07.920;\n\
14b-391;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:09.170;\n\
14b-393;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:31:10.443;\n\
14b-394;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:31:11.660;\n\
14b-398;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:31:12.890;\n\
14b-399;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:31:14.213;\n\
14b-401;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:31:15.410;\n\
14b-402;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:31:16.653;\n\
14b-404;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:31:17.883;\n\
14b-405;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:31:19.213;\n\
14b-415;DL_WFCaseTypeStep!471;Lawyer Review;pdm;2015-05-03 18:31:20.407;\n\
14b-430;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:21.657;\n\
14b-434;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:22.953;\n\
14b-443;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:24.327;\n\
14b-449;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:25.677;\n\
14b-450;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:26.947;\n\
14b-453;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:28.267;\n\
14b-454;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:29.820;\n\
14b-459;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:31.190;\n\
14b-485;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:32.473;\n\
14b-498;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:33.867;\n\
14b-519;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:35.450;\n\
14b-536;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:36.853;\n\
14b-548;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:38.167;\n\
14b-565;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:39.477;\n\
14b-590;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:40.907;\n\
14b-610;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:31:42.167;\n\
14b-330;DL_WFCaseTypeStep!472;Architect Review;ANONYMOUS LOGON;2015-05-03 18:31:47.003;\n\
14b-331;DL_WFCaseTypeStep!472;Architect Review;ANONYMOUS LOGON;2015-05-03 18:31:48.360;\n\
14b-334;DL_WFCaseTypeStep!472;Architect Review;ANONYMOUS LOGON;2015-05-03 18:31:49.620;\n\
14b-343;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:31:50.860;\n\
14b-355;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:31:52.087;\n\
14b-356;DL_WFCaseTypeStep!472;Architect Review;ls;2015-05-03 18:31:52.693;\n\
14b-372;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 18:31:53.773;\n\
14b-378;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 18:31:55.070;\n\
14b-381;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:31:56.300;\n\
14b-391;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:31:56.937;\n\
14b-393;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:31:57.600;\n\
14b-394;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:31:58.193;\n\
14b-397;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 18:31:58.860;\n\
14b-398;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:00.140;\n\
14b-399;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:00.773;\n\
14b-401;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:01.353;\n\
14b-402;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:01.973;\n\
14b-403;DL_WFCaseTypeStep!472;Architect Review;pdm;2015-05-03 18:32:02.580;\n\
14b-404;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:03.787;\n\
14b-405;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:04.397;\n\
14b-415;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:05.073;\n\
14b-430;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:05.683;\n\
14b-434;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:06.300;\n\
14b-443;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:06.923;\n\
14b-449;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:07.560;\n\
14b-450;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:08.147;\n\
14b-452;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:08.830;\n\
14b-453;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:10.203;\n\
14b-454;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:10.923;\n\
14b-455;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:11.650;\n\
14b-459;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:12.960;\n\
14b-473;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:13.577;\n\
14b-481;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:14.833;\n\
14b-485;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:16.193;\n\
14b-494;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:16.790;\n\
14b-498;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:17.857;\n\
14b-504;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:18.530;\n\
14b-513;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:19.813;\n\
14b-519;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:20.897;\n\
14b-520;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:21.523;\n\
14b-521;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:22.987;\n\
14b-523;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:24.223;\n\
14b-526;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:25.470;\n\
14b-531;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:26.490;\n\
14b-532;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:27.773;\n\
14b-536;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:29.053;\n\
14b-545;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:29.650;\n\
14b-548;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:31.077;\n\
14b-551;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:31.680;\n\
14b-552;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:33.103;\n\
14b-565;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:34.620;\n\
14b-566;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:35.227;\n\
14b-579;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:36.523;\n\
14b-580;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:37.877;\n\
14b-584;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:39.163;\n\
14b-590;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:40.513;\n\
14b-597;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:41.120;\n\
14b-601;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:42.460;\n\
14b-606;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:43.900;\n\
14b-610;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:45.193;\n\
14b-612;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:45.897;\n\
14b-613;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:47.527;\n\
14b-623;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-03 18:32:48.833;\n\
14b-330;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:32:53.177;\n\
14b-331;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:32:53.777;\n\
14b-334;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:32:54.363;\n\
14b-343;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:32:54.973;\n\
14b-356;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:32:55.580;\n\
14b-372;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:32:56.223;\n\
14b-378;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:32:56.890;\n\
14b-397;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:32:57.580;\n\
14b-403;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:32:58.227;\n\
14b-452;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:32:58.830;\n\
14b-455;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:32:59.487;\n\
14b-473;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:00.113;\n\
14b-481;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:00.757;\n\
14b-494;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:01.373;\n\
14b-504;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:02.043;\n\
14b-513;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:02.690;\n\
14b-520;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:03.467;\n\
14b-523;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:04.123;\n\
14b-526;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:04.763;\n\
14b-531;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:05.417;\n\
14b-545;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:06.113;\n\
14b-551;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:06.927;\n\
14b-552;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:07.550;\n\
14b-566;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:08.170;\n\
14b-579;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:08.807;\n\
14b-580;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:09.437;\n\
14b-584;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:10.570;\n\
14b-597;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:11.257;\n\
14b-601;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:12.373;\n\
14b-606;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:13.260;\n\
14b-612;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:13.950;\n\
14b-613;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:14.617;\n\
14b-623;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-03 18:33:15.347;\n\
14b-330;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:41.463;\n\
14b-331;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:42.167;\n\
14b-334;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:42.883;\n\
14b-343;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:43.483;\n\
14b-355;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:44.127;\n\
14b-356;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:44.783;\n\
14b-372;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:45.390;\n\
14b-378;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:45.990;\n\
14b-381;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:46.587;\n\
14b-391;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:47.317;\n\
14b-393;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:47.990;\n\
14b-394;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:48.623;\n\
14b-397;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:49.350;\n\
14b-398;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:50.140;\n\
14b-399;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:50.757;\n\
14b-401;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:51.297;\n\
14b-402;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:51.850;\n\
14b-403;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:52.467;\n\
14b-404;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:53.123;\n\
14b-405;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:53.710;\n\
14b-415;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:54.343;\n\
14b-430;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:54.987;\n\
14b-434;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:55.657;\n\
14b-443;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:56.350;\n\
14b-449;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:57.007;\n\
14b-450;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:57.700;\n\
14b-452;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:58.510;\n\
14b-453;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:59.217;\n\
14b-454;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:33:59.893;\n\
14b-455;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:00.580;\n\
14b-459;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:01.340;\n\
14b-473;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:02.117;\n\
14b-481;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:02.773;\n\
14b-485;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:03.417;\n\
14b-494;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:04.130;\n\
14b-498;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:04.833;\n\
14b-504;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:05.680;\n\
14b-513;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:06.313;\n\
14b-519;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:07.000;\n\
14b-520;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:07.637;\n\
14b-521;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:08.350;\n\
14b-523;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:08.973;\n\
14b-526;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:09.690;\n\
14b-531;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:10.403;\n\
14b-532;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:11.033;\n\
14b-536;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:11.703;\n\
14b-545;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:12.420;\n\
14b-548;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:13.123;\n\
14b-551;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:13.760;\n\
14b-552;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:14.433;\n\
14b-565;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:15.160;\n\
14b-566;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:15.793;\n\
14b-579;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:16.450;\n\
14b-580;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:17.137;\n\
14b-584;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:17.780;\n\
14b-590;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:18.507;\n\
14b-597;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:19.103;\n\
14b-601;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:19.823;\n\
14b-606;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:20.463;\n\
14b-610;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:21.153;\n\
14b-612;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:21.830;\n\
14b-613;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:22.440;\n\
14b-623;DL_WFCaseTypeStep!473;Review;administrator;2015-05-03 18:34:23.177;\n\
14b-330;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:31.067;\n\
14b-331;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:31.697;\n\
14b-334;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:32.323;\n\
14b-343;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:32.977;\n\
14b-355;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:33.637;\n\
14b-356;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:34.323;\n\
14b-372;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:34.993;\n\
14b-378;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:35.633;\n\
14b-381;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:36.290;\n\
14b-391;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:37.163;\n\
14b-393;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:37.880;\n\
14b-394;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:38.513;\n\
14b-397;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:39.197;\n\
14b-398;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:39.857;\n\
14b-399;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:40.480;\n\
14b-401;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:41.183;\n\
14b-402;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:41.803;\n\
14b-403;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:42.760;\n\
14b-404;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:43.403;\n\
14b-405;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:44.010;\n\
14b-415;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:44.700;\n\
14b-430;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:45.307;\n\
14b-434;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:45.960;\n\
14b-443;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:46.650;\n\
14b-449;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:47.430;\n\
14b-450;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:48.057;\n\
14b-452;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:48.710;\n\
14b-453;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:49.420;\n\
14b-454;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:50.100;\n\
14b-455;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:50.770;\n\
14b-459;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:51.437;\n\
14b-473;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:52.067;\n\
14b-481;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:52.800;\n\
14b-485;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:53.473;\n\
14b-494;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:54.170;\n\
14b-498;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:54.933;\n\
14b-504;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:55.640;\n\
14b-513;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:56.370;\n\
14b-519;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:57.043;\n\
14b-520;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:57.707;\n\
14b-521;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:58.380;\n\
14b-523;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:59.047;\n\
14b-526;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:34:59.760;\n\
14b-531;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:00.430;\n\
14b-532;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:01.450;\n\
14b-536;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:02.807;\n\
14b-545;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:03.540;\n\
14b-548;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:04.297;\n\
14b-551;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:05.000;\n\
14b-552;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:05.673;\n\
14b-565;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:06.377;\n\
14b-566;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:07.100;\n\
14b-579;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:07.813;\n\
14b-580;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:08.503;\n\
14b-584;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:09.213;\n\
14b-590;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:09.970;\n\
14b-597;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:10.663;\n\
14b-601;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:11.287;\n\
14b-606;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:11.960;\n\
14b-610;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:12.610;\n\
14b-612;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:13.273;\n\
14b-613;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:13.973;\n\
14b-623;DL_WFCaseTypeStep!474;Review;administrator;2015-05-03 18:35:14.610;\n\
14b-330;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:35:25.560;\n\
14b-330;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:27.303;\n\
14b-331;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:35:27.310;\n\
14b-331;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:30.020;\n\
14b-334;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:35:30.030;\n\
14b-334;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:33.770;\n\
14b-343;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:35:33.773;\n\
14b-343;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:36.480;\n\
14b-355;DL_WFCaseTypeStep!475;Register Decision;ANONYMOUS LOGON;2015-05-03 18:35:36.490;\n\
14b-355;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:39.747;\n\
14b-356;DL_WFCaseTypeStep!475;Register Decision;ls;2015-05-03 18:35:39.753;\n\
14b-356;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:42.353;\n\
14b-372;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:35:42.367;\n\
14b-372;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:45.043;\n\
14b-378;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:35:45.063;\n\
14b-378;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:47.593;\n\
14b-381;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:35:47.597;\n\
14b-381;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:50.050;\n\
14b-391;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:35:50.053;\n\
14b-391;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:52.740;\n\
14b-393;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:35:52.747;\n\
14b-393;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:55.363;\n\
14b-394;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:35:55.367;\n\
14b-394;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:35:58.030;\n\
14b-397;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:35:58.037;\n\
14b-397;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:00.440;\n\
14b-398;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:36:00.447;\n\
14b-398;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:02.900;\n\
14b-401;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:36:03.553;\n\
14b-401;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:04.587;\n\
14b-402;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:36:04.590;\n\
14b-402;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:07.140;\n\
14b-403;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:36:07.150;\n\
14b-403;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:09.440;\n\
14b-404;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:36:09.447;\n\
14b-404;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:12.027;\n\
14b-405;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:36:12.030;\n\
14b-405;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:14.317;\n\
14b-415;DL_WFCaseTypeStep!475;Register Decision;pdm;2015-05-03 18:36:14.330;\n\
14b-415;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:16.560;\n\
14b-430;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:16.563;\n\
14b-430;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:19.053;\n\
14b-434;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:19.063;\n\
14b-434;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:21.430;\n\
14b-443;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:21.437;\n\
14b-443;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:24.090;\n\
14b-449;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:24.097;\n\
14b-449;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:26.510;\n\
14b-450;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:26.517;\n\
14b-450;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:29.010;\n\
14b-452;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:29.023;\n\
14b-452;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:31.470;\n\
14b-453;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:31.473;\n\
14b-453;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:33.860;\n\
14b-454;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:33.863;\n\
14b-454;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:36.450;\n\
14b-455;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:36.453;\n\
14b-455;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:39.153;\n\
14b-459;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:39.163;\n\
14b-459;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:42.090;\n\
14b-473;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:42.093;\n\
14b-473;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:44.780;\n\
14b-481;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:44.817;\n\
14b-481;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:47.443;\n\
14b-485;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:47.450;\n\
14b-485;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:49.730;\n\
14b-494;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:49.733;\n\
14b-494;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:52.230;\n\
14b-498;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:52.237;\n\
14b-498;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:54.850;\n\
14b-504;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:54.853;\n\
14b-504;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:57.333;\n\
14b-513;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:57.340;\n\
14b-513;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:36:59.013;\n\
14b-519;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:36:59.017;\n\
14b-519;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:00.740;\n\
14b-520;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:00.743;\n\
14b-520;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:02.287;\n\
14b-521;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:02.290;\n\
14b-521;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:04.467;\n\
14b-523;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:04.483;\n\
14b-523;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:07.317;\n\
14b-526;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:07.323;\n\
14b-526;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:09.893;\n\
14b-531;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:09.900;\n\
14b-531;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:12.317;\n\
14b-532;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:12.323;\n\
14b-532;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:14.830;\n\
14b-536;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:14.840;\n\
14b-536;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:17.330;\n\
14b-545;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:17.337;\n\
14b-545;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:19.907;\n\
14b-548;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:19.917;\n\
14b-548;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:22.377;\n\
14b-551;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:22.383;\n\
14b-551;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:25.080;\n\
14b-552;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:25.117;\n\
14b-552;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:27.630;\n\
14b-565;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:27.637;\n\
14b-565;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:30.650;\n\
14b-566;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:30.657;\n\
14b-566;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:33.260;\n\
14b-579;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:33.270;\n\
14b-579;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:35.900;\n\
14b-580;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:35.903;\n\
14b-580;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:38.530;\n\
14b-584;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:38.540;\n\
14b-584;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:41.123;\n\
14b-590;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:41.233;\n\
14b-590;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:44.167;\n\
14b-597;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:44.173;\n\
14b-597;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:46.770;\n\
14b-601;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:46.790;\n\
14b-601;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:49.287;\n\
14b-606;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:49.290;\n\
14b-606;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:51.880;\n\
14b-610;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:51.887;\n\
14b-610;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:55.703;\n\
14b-612;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:55.710;\n\
14b-612;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:37:58.157;\n\
14b-613;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:37:58.163;\n\
14b-613;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:38:00.593;\n\
14b-623;DL_WFCaseTypeStep!475;Register Decision;administrator;2015-05-03 18:38:00.600;\n\
14b-623;DL_WFCaseTypeStep!519;Change phase to Board meeting;administrator;2015-05-03 18:38:03.113;\n\
14b-330;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:20.980;\n\
14b-330;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:23.153;\n\
14b-330;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:23.157;\n\
14b-331;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:23.343;\n\
14b-331;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:25.927;\n\
14b-331;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:26.067;\n\
14b-334;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:26.160;\n\
14b-334;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:28.543;\n\
14b-334;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:28.547;\n\
14b-343;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:28.637;\n\
14b-343;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:31.380;\n\
14b-343;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:31.383;\n\
14b-355;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:31.523;\n\
14b-355;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:33.880;\n\
14b-355;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:33.883;\n\
14b-356;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:33.977;\n\
14b-356;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:36.600;\n\
14b-356;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:36.600;\n\
14b-372;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:36.677;\n\
14b-372;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:38.713;\n\
14b-372;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:38.713;\n\
14b-378;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:38.770;\n\
14b-378;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:41.043;\n\
14b-378;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:41.090;\n\
14b-381;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:41.147;\n\
14b-381;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:43.120;\n\
14b-381;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:43.120;\n\
14b-391;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:43.163;\n\
14b-391;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:45.380;\n\
14b-391;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:45.437;\n\
14b-393;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:45.573;\n\
14b-393;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:47.970;\n\
14b-393;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:47.973;\n\
14b-394;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:48.100;\n\
14b-394;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:50.650;\n\
14b-394;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:50.657;\n\
14b-397;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:50.743;\n\
14b-397;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:53.330;\n\
14b-397;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:53.337;\n\
14b-398;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:53.460;\n\
14b-398;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:55.903;\n\
14b-398;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:55.907;\n\
14b-401;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:56.160;\n\
14b-401;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:38:58.553;\n\
14b-401;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:38:58.560;\n\
14b-402;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:38:58.670;\n\
14b-402;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:02.200;\n\
14b-402;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:02.207;\n\
14b-403;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:02.317;\n\
14b-403;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:05.053;\n\
14b-403;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:05.057;\n\
14b-404;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:05.203;\n\
14b-404;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:07.983;\n\
14b-404;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:08.007;\n\
14b-405;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:08.133;\n\
14b-405;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:10.780;\n\
14b-405;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:10.787;\n\
14b-415;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:11.027;\n\
14b-415;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:13.710;\n\
14b-415;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:13.713;\n\
14b-420;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:13.800;\n\
14b-420;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:16.483;\n\
14b-420;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:16.490;\n\
14b-430;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:16.587;\n\
14b-430;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:19.547;\n\
14b-430;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:19.563;\n\
14b-434;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:19.680;\n\
14b-434;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:22.210;\n\
14b-434;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:22.213;\n\
14b-443;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:22.317;\n\
14b-443;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:24.840;\n\
14b-443;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:24.843;\n\
14b-449;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:24.943;\n\
14b-449;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:27.540;\n\
14b-449;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:27.553;\n\
14b-450;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:27.887;\n\
14b-450;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:31.227;\n\
14b-450;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:31.237;\n\
14b-452;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:31.353;\n\
14b-452;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:33.847;\n\
14b-452;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:33.847;\n\
14b-453;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:33.960;\n\
14b-453;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:36.760;\n\
14b-453;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:36.767;\n\
14b-454;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:36.943;\n\
14b-454;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:39.713;\n\
14b-454;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:39.720;\n\
14b-455;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:39.813;\n\
14b-455;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:42.553;\n\
14b-455;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:42.560;\n\
14b-459;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:42.663;\n\
14b-459;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:45.587;\n\
14b-459;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:45.590;\n\
14b-473;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:45.687;\n\
14b-473;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:48.103;\n\
14b-473;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:48.107;\n\
14b-481;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:48.260;\n\
14b-481;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:50.653;\n\
14b-481;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:50.663;\n\
14b-485;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:50.780;\n\
14b-485;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:53.393;\n\
14b-485;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:53.397;\n\
14b-494;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:53.517;\n\
14b-494;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:56.050;\n\
14b-494;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:56.050;\n\
14b-498;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:56.147;\n\
14b-498;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:39:58.793;\n\
14b-498;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:39:58.813;\n\
14b-504;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:39:58.920;\n\
14b-504;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:02.160;\n\
14b-504;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:02.170;\n\
14b-513;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:02.533;\n\
14b-513;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:05.467;\n\
14b-513;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:05.470;\n\
14b-519;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:05.573;\n\
14b-519;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:08.130;\n\
14b-519;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:08.133;\n\
14b-520;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:08.283;\n\
14b-520;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:10.937;\n\
14b-520;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:10.940;\n\
14b-521;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:11.037;\n\
14b-521;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:13.613;\n\
14b-521;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:13.620;\n\
14b-523;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:13.720;\n\
14b-523;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:16.460;\n\
14b-523;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:16.463;\n\
14b-526;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:16.557;\n\
14b-526;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:19.267;\n\
14b-526;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:19.270;\n\
14b-531;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:19.370;\n\
14b-531;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:22.003;\n\
14b-531;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:22.013;\n\
14b-532;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:22.140;\n\
14b-532;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:24.767;\n\
14b-532;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:24.773;\n\
14b-536;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:24.887;\n\
14b-536;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:27.863;\n\
14b-536;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:27.867;\n\
14b-545;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:27.970;\n\
14b-545;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:30.440;\n\
14b-545;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:30.443;\n\
14b-548;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:30.533;\n\
14b-548;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:33.097;\n\
14b-548;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:33.107;\n\
14b-551;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:33.220;\n\
14b-551;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:35.857;\n\
14b-551;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:35.860;\n\
14b-552;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:35.957;\n\
14b-552;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:38.750;\n\
14b-552;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:38.757;\n\
14b-565;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:38.843;\n\
14b-565;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:41.557;\n\
14b-565;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:41.560;\n\
14b-566;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:41.657;\n\
14b-566;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:44.810;\n\
14b-566;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:44.813;\n\
14b-579;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:44.947;\n\
14b-579;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:47.623;\n\
14b-579;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:47.627;\n\
14b-580;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:47.703;\n\
14b-580;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:52.700;\n\
14b-580;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:52.707;\n\
14b-584;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:52.840;\n\
14b-584;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:40:56.920;\n\
14b-584;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:40:56.923;\n\
14b-590;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:40:57.043;\n\
14b-590;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:41:00.080;\n\
14b-590;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:41:00.083;\n\
14b-597;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:41:00.177;\n\
14b-597;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:41:03.353;\n\
14b-597;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:41:03.360;\n\
14b-601;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:41:03.560;\n\
14b-601;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:41:06.890;\n\
14b-601;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:41:06.897;\n\
14b-606;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:41:07.020;\n\
14b-606;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:41:09.950;\n\
14b-606;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:41:09.950;\n\
14b-610;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:41:10.043;\n\
14b-610;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:41:12.660;\n\
14b-610;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:41:12.660;\n\
14b-612;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:41:12.753;\n\
14b-612;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:41:15.560;\n\
14b-612;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:41:15.567;\n\
14b-613;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:41:15.697;\n\
14b-613;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:41:18.453;\n\
14b-613;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:41:18.457;\n\
14b-623;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 18:41:18.553;\n\
14b-623;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 18:41:21.103;\n\
14b-623;DL_WFCaseTypeStep!542;Set to Pre-approved;administrator;2015-05-03 18:41:21.107;\n\
14b-330;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:43:39.230;\n\
14b-331;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:43:47.993;\n\
14b-334;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:43:49.793;\n\
14b-343;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:43:51.187;\n\
14b-355;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:43:52.547;\n\
14b-356;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:43:54.000;\n\
14b-367;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:43:55.457;\n\
14b-371;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:43:56.930;\n\
14b-372;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:43:58.473;\n\
14b-377;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:00.060;\n\
14b-378;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:03.423;\n\
14b-381;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:04.997;\n\
14b-391;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:06.433;\n\
14b-393;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:08.027;\n\
14b-394;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:09.363;\n\
14b-397;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:10.717;\n\
14b-398;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:12.067;\n\
14b-399;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:13.140;\n\
14b-401;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:14.160;\n\
14b-402;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:15.250;\n\
14b-403;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:16.280;\n\
14b-404;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:17.387;\n\
14b-405;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:18.523;\n\
14b-406;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:19.867;\n\
14b-415;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:21.170;\n\
14b-420;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:22.410;\n\
14b-430;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:23.557;\n\
14b-434;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:24.757;\n\
14b-443;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:26.030;\n\
14b-449;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:27.307;\n\
14b-450;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:29.647;\n\
14b-452;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:31.080;\n\
14b-453;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:32.350;\n\
14b-454;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:33.720;\n\
14b-455;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:35.170;\n\
14b-459;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:36.447;\n\
14b-473;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:37.853;\n\
14b-481;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:39.140;\n\
14b-485;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:40.443;\n\
14b-494;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:41.800;\n\
14b-498;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:43.033;\n\
14b-504;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:44.207;\n\
14b-513;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:45.113;\n\
14b-519;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:46.360;\n\
14b-520;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:47.687;\n\
14b-521;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:48.780;\n\
14b-523;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:49.997;\n\
14b-526;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:51.080;\n\
14b-531;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:52.210;\n\
14b-532;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:53.470;\n\
14b-536;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:55.063;\n\
14b-545;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:56.230;\n\
14b-548;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:57.510;\n\
14b-551;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:58.710;\n\
14b-552;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:44:59.953;\n\
14b-558;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:01.240;\n\
14b-565;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:03.600;\n\
14b-566;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:04.547;\n\
14b-579;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:05.833;\n\
14b-580;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:07.227;\n\
14b-584;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:08.717;\n\
14b-590;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:10.067;\n\
14b-597;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:11.267;\n\
14b-601;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:12.417;\n\
14b-606;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:13.577;\n\
14b-610;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:15.227;\n\
14b-612;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:16.590;\n\
14b-613;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:17.827;\n\
14b-623;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 18:45:19.020;\n\
14b-330;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:28.297;\n\
14b-331;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:29.773;\n\
14b-334;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:33.300;\n\
14b-343;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:34.540;\n\
14b-355;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:36.007;\n\
14b-356;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:37.203;\n\
14b-367;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:38.457;\n\
14b-371;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:39.640;\n\
14b-372;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:40.937;\n\
14b-377;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:42.373;\n\
14b-378;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:46.280;\n\
14b-381;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:47.527;\n\
14b-391;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:48.687;\n\
14b-393;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:50.060;\n\
14b-394;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:51.267;\n\
14b-397;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:52.423;\n\
14b-398;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:53.403;\n\
14b-399;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:54.770;\n\
14b-401;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:55.800;\n\
14b-402;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:57.057;\n\
14b-403;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:58.320;\n\
14b-404;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:45:59.563;\n\
14b-405;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:00.840;\n\
14b-406;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:02.033;\n\
14b-415;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:03.153;\n\
14b-420;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:04.113;\n\
14b-430;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:04.927;\n\
14b-434;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:06.183;\n\
14b-443;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:07.437;\n\
14b-449;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:08.637;\n\
14b-450;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:09.923;\n\
14b-452;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:11.367;\n\
14b-453;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:12.733;\n\
14b-454;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:13.977;\n\
14b-455;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:15.350;\n\
14b-459;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:16.410;\n\
14b-473;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:17.793;\n\
14b-481;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:19.020;\n\
14b-485;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:20.353;\n\
14b-494;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:21.727;\n\
14b-498;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:23.403;\n\
14b-504;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:24.630;\n\
14b-513;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:25.983;\n\
14b-519;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:27.177;\n\
14b-520;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:28.377;\n\
14b-521;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:29.797;\n\
14b-523;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:31.123;\n\
14b-526;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:32.533;\n\
14b-531;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:33.977;\n\
14b-532;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:35.790;\n\
14b-536;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:37.173;\n\
14b-545;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:38.460;\n\
14b-548;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:39.817;\n\
14b-551;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:41.220;\n\
14b-552;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:42.557;\n\
14b-558;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:44.067;\n\
14b-565;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:45.270;\n\
14b-566;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:46.600;\n\
14b-579;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:47.853;\n\
14b-580;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:49.127;\n\
14b-584;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:50.360;\n\
14b-590;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:51.733;\n\
14b-597;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:52.993;\n\
14b-601;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:54.247;\n\
14b-606;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:55.470;\n\
14b-610;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:56.727;\n\
14b-612;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:58.027;\n\
14b-613;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:46:59.223;\n\
14b-623;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 18:47:00.620;\n\
14b-330;AddEvent;NULL;mmq;2015-05-03 18:50:55.880;\n\
14b-330;AddEvent;NULL;mmq;2015-05-03 18:54:05.983;\n\
14b-330;AddEvent;NULL;mmq;2015-05-03 18:54:29.127;\n\
14b-330;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 18:55:02.793;\n\
14b-331;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 18:55:04.363;\n\
14b-334;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 18:55:05.823;\n\
14b-343;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 18:55:06.947;\n\
14b-343;DL_WFCaseTypeStep!541;Account number changed;recover;2015-05-03 18:55:07.950;\n\
14b-355;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 18:55:09.203;\n\
14b-356;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 18:55:10.177;\n\
14b-298;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:24:15.910;\n\
14b-298;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:24:20.187;\n\
14b-298;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:24:22.023;\n\
14b-298;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:24:23.750;\n\
14b-298;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:24:27.880;\n\
14b-298;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:24:28.193;\n\
14b-299;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:24:29.927;\n\
14b-299;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:24:31.590;\n\
14b-299;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:24:32.903;\n\
14b-299;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:24:34.397;\n\
14b-299;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:24:38.193;\n\
14b-299;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:24:38.503;\n\
14b-302;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:24:40.493;\n\
14b-302;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:24:41.937;\n\
14b-302;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:24:43.170;\n\
14b-302;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:24:44.663;\n\
14b-302;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:24:48.573;\n\
14b-302;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:24:48.910;\n\
14b-305;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:24:50.593;\n\
14b-305;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:24:51.750;\n\
14b-305;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:24:52.773;\n\
14b-305;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:24:53.963;\n\
14b-305;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:24:57.577;\n\
14b-305;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:24:57.913;\n\
14b-307;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:24:58.883;\n\
14b-307;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:25:00.157;\n\
14b-307;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:25:02.080;\n\
14b-307;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:25:03.780;\n\
14b-307;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:25:07.373;\n\
14b-307;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:25:07.687;\n\
14b-310;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:25:09.003;\n\
14b-310;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:25:10.213;\n\
14b-310;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:25:11.290;\n\
14b-310;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:25:12.490;\n\
14b-310;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:25:16.330;\n\
14b-310;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:25:16.640;\n\
14b-312;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:25:17.843;\n\
14b-312;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:25:18.983;\n\
14b-312;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:25:20.073;\n\
14b-312;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:25:21.317;\n\
14b-312;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:25:24.730;\n\
14b-312;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:25:25.020;\n\
14b-317;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:25:26.263;\n\
14b-317;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:25:27.483;\n\
14b-317;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:25:28.493;\n\
14b-317;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:25:29.663;\n\
14b-317;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:25:34.617;\n\
14b-317;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:25:34.897;\n\
14b-321;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:25:36.140;\n\
14b-321;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:25:37.463;\n\
14b-321;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:25:38.467;\n\
14b-321;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:25:39.577;\n\
14b-321;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:25:43.937;\n\
14b-321;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:25:44.567;\n\
14b-322;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:25:45.783;\n\
14b-322;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:25:46.987;\n\
14b-322;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:25:48.013;\n\
14b-322;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:25:49.190;\n\
14b-322;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:25:53.480;\n\
14b-322;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:25:53.853;\n\
14b-325;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:25:55.130;\n\
14b-325;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:25:56.320;\n\
14b-325;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:25:57.330;\n\
14b-325;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:25:58.553;\n\
14b-325;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:26:02.233;\n\
14b-325;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:26:02.640;\n\
14b-327;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:26:03.613;\n\
14b-327;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:26:04.850;\n\
14b-327;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:26:05.883;\n\
14b-327;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:26:06.997;\n\
14b-327;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:26:11.307;\n\
14b-327;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:26:11.717;\n\
14b-328;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:26:12.963;\n\
14b-328;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:26:14.197;\n\
14b-328;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:26:15.200;\n\
14b-328;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:26:16.287;\n\
14b-328;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:26:20.457;\n\
14b-328;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:26:20.813;\n\
14b-338;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:26:22.090;\n\
14b-338;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:26:23.360;\n\
14b-338;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:26:24.287;\n\
14b-338;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:26:24.287;\n\
14b-338;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:26:24.397;\n\
14b-338;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:26:26.170;\n\
14b-338;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:26:30.297;\n\
14b-338;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:26:30.743;\n\
14b-342;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:26:32.070;\n\
14b-342;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:26:33.270;\n\
14b-342;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:26:34.217;\n\
14b-342;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:26:34.217;\n\
14b-342;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:26:34.313;\n\
14b-342;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:26:36.183;\n\
14b-342;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:26:37.250;\n\
14b-342;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:26:38.343;\n\
14b-342;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:26:41.933;\n\
14b-342;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:26:42.243;\n\
14b-344;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:26:43.567;\n\
14b-344;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:26:45.187;\n\
14b-344;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:26:46.023;\n\
14b-344;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:26:46.023;\n\
14b-344;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:26:46.137;\n\
14b-344;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:26:47.907;\n\
14b-344;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:26:51.597;\n\
14b-344;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:26:51.967;\n\
14b-347;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:26:53.193;\n\
14b-347;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:26:54.660;\n\
14b-347;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:26:55.450;\n\
14b-347;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:26:55.450;\n\
14b-347;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:26:55.560;\n\
14b-347;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:26:57.373;\n\
14b-347;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:26:58.517;\n\
14b-347;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:26:59.770;\n\
14b-347;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:27:03.370;\n\
14b-347;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:27:03.673;\n\
14b-349;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:27:04.967;\n\
14b-349;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:27:06.213;\n\
14b-349;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:27:07.040;\n\
14b-349;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:27:07.040;\n\
14b-349;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:27:07.133;\n\
14b-349;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:27:08.910;\n\
14b-349;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:27:12.550;\n\
14b-349;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:27:12.880;\n\
14b-353;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:27:14.233;\n\
14b-353;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:27:15.490;\n\
14b-353;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:27:16.377;\n\
14b-353;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:27:16.377;\n\
14b-353;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:27:16.467;\n\
14b-353;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:27:18.337;\n\
14b-353;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:27:19.400;\n\
14b-353;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:27:20.493;\n\
14b-353;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:27:24.477;\n\
14b-353;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:27:24.783;\n\
14b-354;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:27:26.087;\n\
14b-354;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:27:27.303;\n\
14b-354;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:27:28.113;\n\
14b-354;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:27:28.113;\n\
14b-354;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:27:28.210;\n\
14b-354;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:27:29.923;\n\
14b-354;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:27:33.633;\n\
14b-354;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:27:33.960;\n\
14b-360;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:27:35.317;\n\
14b-360;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:27:36.803;\n\
14b-360;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:27:37.720;\n\
14b-360;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:27:37.720;\n\
14b-360;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:27:37.850;\n\
14b-360;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:27:39.660;\n\
14b-360;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:27:43.430;\n\
14b-360;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:27:44.307;\n\
14b-361;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:27:45.593;\n\
14b-361;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:27:46.833;\n\
14b-361;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:27:47.683;\n\
14b-361;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:27:47.687;\n\
14b-361;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:27:47.780;\n\
14b-361;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:27:49.583;\n\
14b-361;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:27:53.150;\n\
14b-361;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:27:53.480;\n\
14b-362;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:27:54.880;\n\
14b-362;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:27:56.140;\n\
14b-362;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:27:57.017;\n\
14b-362;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:27:57.017;\n\
14b-362;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:27:57.140;\n\
14b-362;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:27:59.273;\n\
14b-362;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:28:03.107;\n\
14b-362;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:28:03.467;\n\
14b-364;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:28:04.897;\n\
14b-364;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;recover;2015-05-03 20:28:06.107;\n\
14b-364;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:28:06.720;\n\
14b-364;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:28:07.670;\n\
14b-364;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:28:07.670;\n\
14b-364;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:28:07.783;\n\
14b-364;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:28:09.580;\n\
14b-364;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:28:14.153;\n\
14b-364;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:28:14.630;\n\
14b-366;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:28:15.920;\n\
14b-366;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:28:17.110;\n\
14b-366;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:28:17.990;\n\
14b-366;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:28:17.990;\n\
14b-366;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:28:18.257;\n\
14b-366;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:28:20.113;\n\
14b-366;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:28:23.837;\n\
14b-366;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:28:24.207;\n\
14b-396;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:28:25.580;\n\
14b-396;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:28:26.907;\n\
14b-396;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:28:27.783;\n\
14b-396;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:28:27.783;\n\
14b-396;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:28:27.903;\n\
14b-396;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:28:29.793;\n\
14b-396;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:28:33.313;\n\
14b-396;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:28:33.653;\n\
14b-407;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:28:34.970;\n\
14b-407;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:28:36.220;\n\
14b-407;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:28:37.030;\n\
14b-407;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:28:37.030;\n\
14b-407;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:28:37.123;\n\
14b-407;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:28:38.957;\n\
14b-407;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:28:42.637;\n\
14b-407;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:28:42.963;\n\
14b-409;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:28:44.423;\n\
14b-409;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:28:45.677;\n\
14b-409;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:28:46.547;\n\
14b-409;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:28:46.547;\n\
14b-409;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:28:46.643;\n\
14b-409;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:28:48.403;\n\
14b-409;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:28:53.060;\n\
14b-409;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:28:53.563;\n\
14b-410;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:28:55.360;\n\
14b-410;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:28:56.643;\n\
14b-410;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:28:57.520;\n\
14b-410;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:28:57.520;\n\
14b-410;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:28:57.653;\n\
14b-410;DL_WFCaseTypeStep!519;Change phase to Board meeting;recover;2015-05-03 20:28:59.477;\n\
14b-410;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:29:00.113;\n\
14b-410;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:29:03.867;\n\
14b-410;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:29:04.227;\n\
14b-411;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:29:05.620;\n\
14b-411;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:29:06.940;\n\
14b-411;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:29:07.700;\n\
14b-411;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:29:07.700;\n\
14b-411;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:29:07.793;\n\
14b-411;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:29:09.547;\n\
14b-411;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:29:13.300;\n\
14b-411;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:29:13.620;\n\
14b-413;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:29:14.873;\n\
14b-413;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:29:16.200;\n\
14b-413;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:29:17.053;\n\
14b-413;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:29:17.057;\n\
14b-413;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:29:17.170;\n\
14b-413;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:29:18.910;\n\
14b-413;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:29:22.607;\n\
14b-413;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:29:23.170;\n\
14b-422;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:29:24.583;\n\
14b-422;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:29:25.760;\n\
14b-422;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:29:26.627;\n\
14b-422;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:29:26.627;\n\
14b-422;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:29:26.720;\n\
14b-422;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:29:28.460;\n\
14b-422;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:29:32.273;\n\
14b-422;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:29:32.733;\n\
14b-425;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:29:34.123;\n\
14b-425;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:29:35.357;\n\
14b-425;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:29:36.223;\n\
14b-425;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:29:36.223;\n\
14b-425;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:29:36.357;\n\
14b-425;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:29:38.193;\n\
14b-425;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:29:41.790;\n\
14b-425;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:29:42.140;\n\
14b-427;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:29:43.520;\n\
14b-427;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:29:44.797;\n\
14b-427;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:29:45.623;\n\
14b-427;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:29:45.623;\n\
14b-427;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:29:46.033;\n\
14b-427;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:29:47.973;\n\
14b-427;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:29:51.600;\n\
14b-427;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:29:51.973;\n\
14b-432;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:29:53.267;\n\
14b-432;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:29:54.547;\n\
14b-432;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:29:55.367;\n\
14b-432;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:29:55.370;\n\
14b-432;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:29:55.470;\n\
14b-432;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:29:57.320;\n\
14b-432;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:30:01.143;\n\
14b-432;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:30:02.467;\n\
14b-440;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:30:03.867;\n\
14b-440;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:30:05.167;\n\
14b-440;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:30:05.990;\n\
14b-440;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:30:05.990;\n\
14b-440;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:30:06.110;\n\
14b-440;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:30:07.963;\n\
14b-440;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:30:11.593;\n\
14b-440;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:30:11.953;\n\
14b-446;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:30:13.473;\n\
14b-446;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:30:14.760;\n\
14b-446;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:30:15.577;\n\
14b-446;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:30:15.577;\n\
14b-446;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:30:15.680;\n\
14b-446;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:30:17.533;\n\
14b-446;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:30:21.153;\n\
14b-446;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:30:21.540;\n\
14b-448;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:30:22.880;\n\
14b-448;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:30:24.300;\n\
14b-448;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:30:25.170;\n\
14b-448;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:30:25.170;\n\
14b-448;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:30:25.270;\n\
14b-448;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:30:27.167;\n\
14b-448;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:30:30.830;\n\
14b-448;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:30:31.183;\n\
14b-458;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:30:32.520;\n\
14b-458;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:30:34.040;\n\
14b-458;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:30:35.280;\n\
14b-458;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:30:35.280;\n\
14b-458;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:30:35.347;\n\
14b-458;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:30:37.303;\n\
14b-458;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:30:41.030;\n\
14b-458;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:30:41.383;\n\
14b-462;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:30:42.713;\n\
14b-462;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:30:43.973;\n\
14b-462;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:30:44.867;\n\
14b-462;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:30:44.867;\n\
14b-462;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:30:44.970;\n\
14b-462;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:30:46.843;\n\
14b-462;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:30:50.817;\n\
14b-462;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:30:51.167;\n\
14b-464;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:30:52.467;\n\
14b-464;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:30:53.713;\n\
14b-464;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:30:54.440;\n\
14b-464;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:30:54.440;\n\
14b-464;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:30:54.620;\n\
14b-464;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:30:56.453;\n\
14b-464;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:31:00.353;\n\
14b-464;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:31:00.760;\n\
14b-466;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:31:02.150;\n\
14b-466;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:31:03.407;\n\
14b-466;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:31:04.243;\n\
14b-466;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:31:04.247;\n\
14b-466;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:31:04.347;\n\
14b-466;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:31:06.333;\n\
14b-466;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:31:10.003;\n\
14b-466;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:31:10.407;\n\
14b-467;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:31:11.740;\n\
14b-467;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:31:13.087;\n\
14b-467;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:31:13.913;\n\
14b-467;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:31:13.913;\n\
14b-467;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:31:14.013;\n\
14b-467;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:31:15.940;\n\
14b-467;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:31:20.080;\n\
14b-467;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:31:20.543;\n\
14b-468;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:31:21.830;\n\
14b-468;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:31:23.243;\n\
14b-468;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:31:24.080;\n\
14b-468;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:31:24.080;\n\
14b-468;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:31:24.190;\n\
14b-468;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:31:26.203;\n\
14b-468;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:31:29.793;\n\
14b-468;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:31:30.157;\n\
14b-469;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:31:31.853;\n\
14b-469;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:31:33.337;\n\
14b-469;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:31:34.207;\n\
14b-469;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:31:34.207;\n\
14b-469;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:31:34.307;\n\
14b-469;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:31:36.203;\n\
14b-469;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:31:39.907;\n\
14b-469;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:31:40.280;\n\
14b-474;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:31:41.523;\n\
14b-474;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:31:42.550;\n\
14b-474;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:31:43.410;\n\
14b-474;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:31:43.410;\n\
14b-474;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:31:43.547;\n\
14b-474;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:31:45.363;\n\
14b-474;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:31:49.443;\n\
14b-474;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:31:50.003;\n\
14b-475;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:31:51.313;\n\
14b-475;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:31:52.577;\n\
14b-475;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:31:53.293;\n\
14b-475;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:31:53.293;\n\
14b-475;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:31:53.373;\n\
14b-475;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:31:55.233;\n\
14b-475;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:32:00.100;\n\
14b-475;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:32:00.600;\n\
14b-477;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:32:01.920;\n\
14b-477;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:32:04.157;\n\
14b-477;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:32:05.070;\n\
14b-477;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:32:05.070;\n\
14b-477;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:32:05.200;\n\
14b-477;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:32:07.063;\n\
14b-477;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:32:10.930;\n\
14b-477;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:32:11.280;\n\
14b-478;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:32:12.627;\n\
14b-478;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:32:14.013;\n\
14b-478;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:32:14.833;\n\
14b-478;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:32:14.833;\n\
14b-478;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:32:14.937;\n\
14b-478;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:32:16.740;\n\
14b-478;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:32:20.773;\n\
14b-478;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:32:21.173;\n\
14b-480;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:32:22.550;\n\
14b-480;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:32:23.963;\n\
14b-480;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:32:24.797;\n\
14b-480;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:32:24.797;\n\
14b-480;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:32:24.930;\n\
14b-480;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:32:26.510;\n\
14b-480;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:32:30.290;\n\
14b-480;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:32:30.650;\n\
14b-483;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:32:31.957;\n\
14b-483;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:32:33.190;\n\
14b-483;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:32:34.060;\n\
14b-483;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:32:34.060;\n\
14b-483;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:32:34.177;\n\
14b-483;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:32:35.977;\n\
14b-483;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:32:39.783;\n\
14b-483;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:32:40.167;\n\
14b-489;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:32:41.757;\n\
14b-489;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:32:43.323;\n\
14b-489;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:32:44.187;\n\
14b-489;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:32:44.190;\n\
14b-489;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:32:44.290;\n\
14b-489;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:32:46.153;\n\
14b-489;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:32:49.930;\n\
14b-489;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:32:50.317;\n\
14b-491;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:32:51.710;\n\
14b-491;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:32:52.797;\n\
14b-491;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:32:53.407;\n\
14b-491;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:32:53.407;\n\
14b-491;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:32:53.467;\n\
14b-491;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:32:55.453;\n\
14b-491;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:33:00.153;\n\
14b-491;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:33:00.630;\n\
14b-492;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:33:01.993;\n\
14b-492;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:33:03.240;\n\
14b-492;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:33:04.100;\n\
14b-492;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:33:04.100;\n\
14b-492;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:33:04.230;\n\
14b-492;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:33:06.007;\n\
14b-492;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:33:10.113;\n\
14b-492;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:33:10.853;\n\
14b-493;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:33:12.160;\n\
14b-493;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:33:13.400;\n\
14b-493;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:33:14.267;\n\
14b-493;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:33:14.267;\n\
14b-493;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:33:14.380;\n\
14b-493;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:33:16.377;\n\
14b-493;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:33:19.910;\n\
14b-493;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:33:20.300;\n\
14b-495;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:33:21.650;\n\
14b-495;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:33:22.970;\n\
14b-495;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:33:23.847;\n\
14b-495;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:33:23.847;\n\
14b-495;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:33:23.960;\n\
14b-495;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:33:25.877;\n\
14b-495;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:33:29.893;\n\
14b-495;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:33:30.390;\n\
14b-496;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:33:32.037;\n\
14b-496;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:33:33.353;\n\
14b-496;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:33:34.207;\n\
14b-496;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:33:34.207;\n\
14b-496;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:33:34.327;\n\
14b-496;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:33:36.210;\n\
14b-496;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:33:39.967;\n\
14b-496;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:33:40.323;\n\
14b-501;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:33:41.673;\n\
14b-501;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:33:42.990;\n\
14b-501;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:33:43.850;\n\
14b-501;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:33:43.850;\n\
14b-501;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:33:43.963;\n\
14b-501;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:33:45.923;\n\
14b-501;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:33:49.583;\n\
14b-501;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:33:50.353;\n\
14b-502;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:33:51.980;\n\
14b-502;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:33:53.283;\n\
14b-502;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:33:54.170;\n\
14b-502;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:33:54.170;\n\
14b-502;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:33:54.287;\n\
14b-502;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:33:56.180;\n\
14b-502;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:33:59.907;\n\
14b-502;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:34:00.277;\n\
14b-507;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:34:01.687;\n\
14b-507;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:34:03.030;\n\
14b-507;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:34:03.897;\n\
14b-507;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:34:03.897;\n\
14b-507;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:34:04.000;\n\
14b-507;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:34:05.793;\n\
14b-507;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:34:09.500;\n\
14b-507;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:34:09.867;\n\
14b-508;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:34:11.163;\n\
14b-508;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:34:12.570;\n\
14b-508;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:34:13.383;\n\
14b-508;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:34:13.383;\n\
14b-508;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:34:13.480;\n\
14b-508;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:34:15.327;\n\
14b-508;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:34:19.027;\n\
14b-508;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:34:19.380;\n\
14b-509;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:34:20.660;\n\
14b-509;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:34:21.903;\n\
14b-509;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:34:22.743;\n\
14b-509;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:34:22.743;\n\
14b-509;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:34:22.843;\n\
14b-509;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:34:24.800;\n\
14b-509;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:34:28.507;\n\
14b-509;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:34:28.877;\n\
14b-510;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:34:30.190;\n\
14b-510;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:34:31.450;\n\
14b-510;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:34:32.403;\n\
14b-510;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:34:32.407;\n\
14b-510;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:34:32.617;\n\
14b-510;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:34:34.480;\n\
14b-510;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:34:38.230;\n\
14b-510;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:34:38.577;\n\
14b-511;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:34:39.830;\n\
14b-511;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:34:41.097;\n\
14b-511;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:34:41.950;\n\
14b-511;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:34:41.950;\n\
14b-511;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:34:42.050;\n\
14b-511;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:34:44.040;\n\
14b-511;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:34:47.660;\n\
14b-511;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:34:48.050;\n\
14b-512;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:34:49.350;\n\
14b-512;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:34:50.703;\n\
14b-512;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:34:51.543;\n\
14b-512;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:34:51.547;\n\
14b-512;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:34:51.653;\n\
14b-512;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:34:53.597;\n\
14b-512;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:34:57.380;\n\
14b-512;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:34:57.740;\n\
14b-514;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:34:59.097;\n\
14b-514;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:35:00.400;\n\
14b-514;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:35:01.357;\n\
14b-514;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:35:01.357;\n\
14b-514;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:35:01.557;\n\
14b-514;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:35:04.230;\n\
14b-514;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:35:07.327;\n\
14b-514;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:35:07.707;\n\
14b-515;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:35:08.993;\n\
14b-515;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:35:10.350;\n\
14b-515;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:35:11.177;\n\
14b-515;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:35:11.177;\n\
14b-515;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:35:11.277;\n\
14b-515;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:35:13.110;\n\
14b-515;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:35:17.650;\n\
14b-515;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:35:18.210;\n\
14b-516;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:35:19.590;\n\
14b-516;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:35:20.850;\n\
14b-516;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:35:21.690;\n\
14b-516;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:35:21.690;\n\
14b-516;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:35:21.790;\n\
14b-516;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:35:23.643;\n\
14b-516;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:35:27.673;\n\
14b-516;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:35:28.093;\n\
14b-518;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:35:29.397;\n\
14b-518;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:35:30.777;\n\
14b-518;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:35:31.720;\n\
14b-518;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:35:31.720;\n\
14b-518;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:35:32.347;\n\
14b-518;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:35:34.993;\n\
14b-518;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:35:38.997;\n\
14b-518;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:35:39.370;\n\
14b-522;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:35:40.813;\n\
14b-522;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:35:42.117;\n\
14b-522;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:35:43.197;\n\
14b-522;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:35:44.443;\n\
14b-522;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:35:48.477;\n\
14b-522;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:35:48.853;\n\
14b-525;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:35:50.110;\n\
14b-525;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:35:51.330;\n\
14b-525;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:35:52.227;\n\
14b-525;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:35:52.227;\n\
14b-525;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:35:52.323;\n\
14b-525;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:35:54.537;\n\
14b-525;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:35:58.680;\n\
14b-525;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:35:59.360;\n\
14b-527;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:36:00.630;\n\
14b-527;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:36:02.003;\n\
14b-527;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:36:02.857;\n\
14b-527;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:36:02.860;\n\
14b-527;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:36:02.980;\n\
14b-527;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:36:04.577;\n\
14b-527;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:36:07.240;\n\
14b-527;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:36:07.643;\n\
14b-529;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:36:08.970;\n\
14b-529;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:36:10.400;\n\
14b-529;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:36:11.307;\n\
14b-529;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:36:11.310;\n\
14b-529;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:36:11.410;\n\
14b-529;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:36:13.227;\n\
14b-529;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:36:16.810;\n\
14b-529;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:36:17.247;\n\
14b-530;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:36:18.643;\n\
14b-530;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:36:19.970;\n\
14b-530;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:36:20.813;\n\
14b-530;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:36:20.813;\n\
14b-530;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:36:20.917;\n\
14b-530;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:36:22.780;\n\
14b-530;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:36:26.417;\n\
14b-530;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:36:26.827;\n\
14b-533;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:36:28.213;\n\
14b-533;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:36:29.560;\n\
14b-533;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:36:30.440;\n\
14b-533;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:36:30.440;\n\
14b-533;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:36:30.577;\n\
14b-533;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:36:32.753;\n\
14b-533;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:36:37.980;\n\
14b-533;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:36:38.600;\n\
14b-538;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:36:39.913;\n\
14b-538;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:36:41.290;\n\
14b-538;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:36:42.080;\n\
14b-538;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:36:42.080;\n\
14b-538;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:36:42.173;\n\
14b-538;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:36:44.100;\n\
14b-538;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:36:48.153;\n\
14b-538;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:36:48.507;\n\
14b-539;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:36:49.740;\n\
14b-539;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:36:50.687;\n\
14b-539;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:36:51.560;\n\
14b-539;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:36:51.560;\n\
14b-539;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:36:51.667;\n\
14b-539;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:36:53.950;\n\
14b-539;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:36:57.993;\n\
14b-539;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:36:58.407;\n\
14b-547;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:37:00.413;\n\
14b-547;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:37:01.790;\n\
14b-547;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:37:02.703;\n\
14b-547;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:37:02.703;\n\
14b-547;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:37:02.820;\n\
14b-547;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:37:05.387;\n\
14b-547;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:37:09.400;\n\
14b-547;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:37:09.823;\n\
14b-549;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:37:11.127;\n\
14b-549;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:37:12.503;\n\
14b-549;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:37:13.393;\n\
14b-549;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:37:13.393;\n\
14b-549;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:37:13.550;\n\
14b-549;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:37:15.610;\n\
14b-549;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:37:19.490;\n\
14b-549;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:37:20.043;\n\
14b-553;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:37:21.553;\n\
14b-553;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:37:23.520;\n\
14b-553;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:37:24.713;\n\
14b-553;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:37:24.713;\n\
14b-553;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:37:24.987;\n\
14b-553;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:37:26.900;\n\
14b-553;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:37:31.187;\n\
14b-553;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:37:31.593;\n\
14b-561;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:37:33.573;\n\
14b-561;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:37:34.960;\n\
14b-561;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:37:35.810;\n\
14b-561;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:37:35.810;\n\
14b-561;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:37:35.907;\n\
14b-561;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:37:37.673;\n\
14b-561;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:37:41.397;\n\
14b-561;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:37:41.740;\n\
14b-562;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:37:43.070;\n\
14b-562;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:37:44.360;\n\
14b-562;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:37:45.240;\n\
14b-562;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:37:45.240;\n\
14b-562;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:37:45.340;\n\
14b-562;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:37:47.217;\n\
14b-562;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:37:50.860;\n\
14b-562;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:37:51.193;\n\
14b-564;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:37:52.503;\n\
14b-564;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:37:53.730;\n\
14b-564;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:37:54.657;\n\
14b-564;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:37:54.660;\n\
14b-564;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:37:54.767;\n\
14b-564;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:37:56.753;\n\
14b-564;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:38:00.400;\n\
14b-564;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:38:00.797;\n\
14b-568;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:38:02.243;\n\
14b-568;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:38:03.487;\n\
14b-568;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:38:04.317;\n\
14b-568;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:38:04.317;\n\
14b-568;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:38:04.413;\n\
14b-568;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:38:06.350;\n\
14b-568;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:38:10.057;\n\
14b-568;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:38:10.413;\n\
14b-570;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:38:11.787;\n\
14b-570;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:38:13.140;\n\
14b-570;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:38:13.990;\n\
14b-570;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:38:13.990;\n\
14b-570;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:38:14.090;\n\
14b-570;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:38:16.037;\n\
14b-570;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:38:19.783;\n\
14b-570;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:38:20.130;\n\
14b-571;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:38:21.550;\n\
14b-571;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:38:22.913;\n\
14b-571;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:38:23.710;\n\
14b-571;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:38:23.710;\n\
14b-571;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:38:23.803;\n\
14b-571;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:38:25.590;\n\
14b-571;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:38:30.093;\n\
14b-571;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:38:30.577;\n\
14b-574;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:38:31.927;\n\
14b-574;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 20:38:33.280;\n\
14b-574;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 20:38:34.390;\n\
14b-574;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:38:35.720;\n\
14b-574;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:38:39.640;\n\
14b-574;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:38:40.020;\n\
14b-581;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:38:41.300;\n\
14b-581;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:38:42.673;\n\
14b-581;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:38:43.523;\n\
14b-581;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:38:43.523;\n\
14b-581;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:38:43.650;\n\
14b-581;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:38:45.563;\n\
14b-581;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:38:49.823;\n\
14b-581;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:38:50.290;\n\
14b-582;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:38:51.680;\n\
14b-582;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:38:53.183;\n\
14b-582;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:38:54.457;\n\
14b-582;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:38:54.457;\n\
14b-582;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:38:54.653;\n\
14b-582;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:38:56.687;\n\
14b-582;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:39:01.060;\n\
14b-582;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:39:01.480;\n\
14b-583;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:39:02.813;\n\
14b-583;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:39:04.617;\n\
14b-583;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:39:05.483;\n\
14b-583;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:39:05.483;\n\
14b-583;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:39:05.580;\n\
14b-583;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:39:07.393;\n\
14b-583;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:39:11.163;\n\
14b-583;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:39:11.550;\n\
14b-585;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:39:13.083;\n\
14b-585;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:39:14.483;\n\
14b-585;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:39:15.457;\n\
14b-585;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:39:15.457;\n\
14b-585;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:39:15.557;\n\
14b-585;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:39:17.350;\n\
14b-585;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:39:21.400;\n\
14b-585;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:39:21.813;\n\
14b-586;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:39:23.090;\n\
14b-586;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:39:24.487;\n\
14b-586;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:39:25.380;\n\
14b-586;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:39:25.380;\n\
14b-586;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:39:25.513;\n\
14b-586;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:39:27.403;\n\
14b-586;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:39:31.690;\n\
14b-586;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:39:32.070;\n\
14b-589;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:39:33.397;\n\
14b-589;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:39:34.700;\n\
14b-589;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:39:38.583;\n\
14b-589;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:39:38.583;\n\
14b-589;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:39:38.680;\n\
14b-589;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:39:40.600;\n\
14b-589;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:39:45.133;\n\
14b-589;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:39:45.583;\n\
14b-592;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:39:46.910;\n\
14b-592;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:39:48.260;\n\
14b-592;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:39:49.200;\n\
14b-592;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:39:49.200;\n\
14b-592;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:39:49.300;\n\
14b-592;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:39:51.120;\n\
14b-592;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:39:55.323;\n\
14b-592;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:39:55.787;\n\
14b-593;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:39:57.327;\n\
14b-593;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:39:58.620;\n\
14b-593;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:39:59.453;\n\
14b-593;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:39:59.453;\n\
14b-593;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:39:59.570;\n\
14b-593;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:40:02.533;\n\
14b-593;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:40:06.740;\n\
14b-593;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:40:07.220;\n\
14b-594;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:40:08.517;\n\
14b-594;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:40:09.820;\n\
14b-594;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:40:10.657;\n\
14b-594;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:40:10.657;\n\
14b-594;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:40:10.840;\n\
14b-594;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:40:12.723;\n\
14b-594;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:40:17.757;\n\
14b-594;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:40:18.260;\n\
14b-595;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:40:19.600;\n\
14b-595;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:40:20.847;\n\
14b-595;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:40:21.743;\n\
14b-595;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:40:21.747;\n\
14b-595;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:40:21.840;\n\
14b-595;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:40:23.720;\n\
14b-595;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:40:27.657;\n\
14b-595;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:40:28.180;\n\
14b-596;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:40:29.460;\n\
14b-596;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:40:30.730;\n\
14b-596;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:40:31.700;\n\
14b-596;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:40:31.700;\n\
14b-596;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:40:31.800;\n\
14b-596;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:40:33.710;\n\
14b-596;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:40:38.553;\n\
14b-596;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:40:39.023;\n\
14b-599;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:40:40.290;\n\
14b-599;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:40:41.567;\n\
14b-599;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:40:42.493;\n\
14b-599;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:40:42.493;\n\
14b-599;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:40:42.670;\n\
14b-599;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:40:44.547;\n\
14b-599;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:40:48.460;\n\
14b-599;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:40:48.953;\n\
14b-600;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:40:50.260;\n\
14b-600;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:40:51.637;\n\
14b-600;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:40:52.580;\n\
14b-600;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:40:52.580;\n\
14b-600;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:40:52.687;\n\
14b-600;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:40:54.580;\n\
14b-600;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:40:59.263;\n\
14b-600;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:40:59.760;\n\
14b-602;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:41:01.147;\n\
14b-602;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:41:02.390;\n\
14b-602;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:41:03.263;\n\
14b-602;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:41:03.267;\n\
14b-602;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:41:03.370;\n\
14b-602;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:41:05.223;\n\
14b-602;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:41:09.050;\n\
14b-602;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:41:09.730;\n\
14b-607;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:41:11.087;\n\
14b-607;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:41:12.397;\n\
14b-607;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:41:13.290;\n\
14b-607;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:41:13.290;\n\
14b-607;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:41:13.387;\n\
14b-607;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:41:15.260;\n\
14b-607;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 20:41:19.873;\n\
14b-607;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 20:41:20.397;\n\
14b-608;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 20:41:21.760;\n\
14b-608;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 20:41:23.033;\n\
14b-608;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 20:41:23.873;\n\
14b-608;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 20:41:23.873;\n\
14b-608;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 20:41:23.980;\n\
14b-608;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 20:41:25.803;\n\
14b-609;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:42:28.610;\n\
14b-609;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:42:33.683;\n\
14b-609;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:42:35.223;\n\
14b-609;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:42:35.223;\n\
14b-609;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:42:35.353;\n\
14b-609;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:42:38.580;\n\
14b-609;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:42:43.700;\n\
14b-609;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:42:44.397;\n\
14b-611;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:42:46.280;\n\
14b-611;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:42:47.887;\n\
14b-611;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:42:49.020;\n\
14b-611;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:42:49.020;\n\
14b-611;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:42:49.137;\n\
14b-611;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:42:51.937;\n\
14b-611;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:42:56.127;\n\
14b-611;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:42:56.567;\n\
14b-614;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:42:58.557;\n\
14b-614;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:43:00.323;\n\
14b-614;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:43:02.133;\n\
14b-614;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:43:02.133;\n\
14b-614;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:43:02.270;\n\
14b-614;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:43:04.980;\n\
14b-614;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:43:09.987;\n\
14b-614;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:43:10.617;\n\
14b-617;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:43:12.007;\n\
14b-617;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:43:13.610;\n\
14b-617;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:43:14.527;\n\
14b-617;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:43:14.527;\n\
14b-617;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:43:14.627;\n\
14b-617;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:43:16.397;\n\
14b-617;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:43:20.063;\n\
14b-617;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:43:21.003;\n\
14b-618;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:43:22.243;\n\
14b-618;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:43:23.620;\n\
14b-618;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:43:24.463;\n\
14b-618;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:43:24.467;\n\
14b-618;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:43:24.617;\n\
14b-618;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:43:26.390;\n\
14b-618;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:43:30.207;\n\
14b-618;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:43:30.577;\n\
14b-619;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:43:31.890;\n\
14b-619;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:43:33.533;\n\
14b-619;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:43:34.310;\n\
14b-619;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:43:34.310;\n\
14b-619;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:43:34.417;\n\
14b-619;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:43:36.203;\n\
14b-619;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:43:40.247;\n\
14b-619;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:43:40.670;\n\
14b-620;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:43:42.880;\n\
14b-620;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:43:44.467;\n\
14b-620;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:43:45.327;\n\
14b-620;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:43:45.327;\n\
14b-620;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:43:45.453;\n\
14b-620;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:43:47.170;\n\
14b-620;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:43:50.927;\n\
14b-620;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:43:51.310;\n\
14b-621;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:43:52.657;\n\
14b-621;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:43:53.797;\n\
14b-621;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:43:54.700;\n\
14b-621;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:43:54.700;\n\
14b-621;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:43:54.810;\n\
14b-621;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:43:56.640;\n\
14b-621;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:44:00.817;\n\
14b-621;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:44:01.273;\n\
14b-624;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:44:02.820;\n\
14b-624;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:44:04.263;\n\
14b-624;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:44:05.200;\n\
14b-624;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:44:05.200;\n\
14b-624;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:44:05.297;\n\
14b-624;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:44:07.117;\n\
14b-624;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:44:11.217;\n\
14b-624;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:44:11.737;\n\
14b-625;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:44:13.047;\n\
14b-625;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:44:14.297;\n\
14b-625;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:44:15.523;\n\
14b-625;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:44:15.523;\n\
14b-625;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:44:16.010;\n\
14b-625;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:44:17.920;\n\
14b-625;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:44:22.450;\n\
14b-625;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:44:22.993;\n\
14b-626;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:44:24.233;\n\
14b-626;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:44:25.537;\n\
14b-626;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:44:26.353;\n\
14b-626;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:44:26.357;\n\
14b-626;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:44:26.457;\n\
14b-626;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:44:28.237;\n\
14b-626;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:44:31.853;\n\
14b-626;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:44:32.210;\n\
14b-627;DL_WFCaseTypeStep!477;Reject application;recover;2015-05-03 22:44:33.527;\n\
14b-627;DL_WFCaseTypeStep!544;Pre-process application;recover;2015-05-03 22:44:34.780;\n\
14b-627;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-03 22:44:35.710;\n\
14b-627;DL_WFCaseTypeStep!477;Reject application;Automatic;2015-05-03 22:44:35.710;\n\
14b-627;DL_WFCaseTypeStep!545;Execute pre-decision;recover;2015-05-03 22:44:35.887;\n\
14b-627;DL_WFCaseTypeStep!486;Applicant informed ;recover;2015-05-03 22:44:37.757;\n\
14b-627;DL_WFCaseTypeStep!538;Change phase to Abort;administrator;2015-05-03 22:44:41.113;\n\
14b-627;DL_WFCaseTypeStep!538;Change phase to Abort;recover;2015-05-03 22:44:41.473;\n\
14b-608;DL_WFCaseTypeStep!538;Change phase to Abort;mmq;2015-05-03 23:13:19.463;\n\
14b-303;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:16:54.147;\n\
14b-306;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:16:57.253;\n\
14b-309;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:16:58.917;\n\
14b-311;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:00.403;\n\
14b-313;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:02.030;\n\
14b-314;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:03.543;\n\
14b-315;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:04.917;\n\
14b-318;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:06.447;\n\
14b-319;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:07.953;\n\
14b-320;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:09.330;\n\
14b-323;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:10.760;\n\
14b-326;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:12.300;\n\
14b-337;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:13.947;\n\
14b-341;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:15.350;\n\
14b-350;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:17.090;\n\
14b-358;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:18.710;\n\
14b-359;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:20.270;\n\
14b-368;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:22.110;\n\
14b-376;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:24.390;\n\
14b-395;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:25.543;\n\
14b-416;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:26.947;\n\
14b-429;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:28.750;\n\
14b-433;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:29.997;\n\
14b-438;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:31.210;\n\
14b-471;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:32.550;\n\
14b-472;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:33.980;\n\
14b-476;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:35.473;\n\
14b-482;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:36.843;\n\
14b-484;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:38.070;\n\
14b-488;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:39.310;\n\
14b-497;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:40.593;\n\
14b-500;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:41.843;\n\
14b-503;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:43.113;\n\
14b-505;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:44.370;\n\
14b-517;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:45.720;\n\
14b-528;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:46.983;\n\
14b-535;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:48.270;\n\
14b-537;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:50.037;\n\
14b-541;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:51.450;\n\
14b-542;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:53.087;\n\
14b-546;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:54.470;\n\
14b-550;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:55.597;\n\
14b-556;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:56.623;\n\
14b-567;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:58.067;\n\
14b-569;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:17:59.290;\n\
14b-572;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:18:00.770;\n\
14b-575;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:18:02.797;\n\
14b-577;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:18:04.087;\n\
14b-578;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:18:05.347;\n\
14b-587;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:18:06.820;\n\
14b-591;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:18:08.813;\n\
14b-598;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:18:10.363;\n\
14b-604;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:18:11.647;\n\
14b-605;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-03 23:18:13.160;\n\
14b-303;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:21:48.133;\n\
14b-306;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:21:49.707;\n\
14b-309;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:21:51.373;\n\
14b-311;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:21:52.880;\n\
14b-313;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:21:54.000;\n\
14b-314;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:21:55.747;\n\
14b-315;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:21:57.437;\n\
14b-318;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:21:58.890;\n\
14b-319;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:00.383;\n\
14b-320;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:01.970;\n\
14b-323;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:03.473;\n\
14b-326;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:05.040;\n\
14b-337;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:06.540;\n\
14b-341;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:08.203;\n\
14b-350;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:09.700;\n\
14b-358;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:11.277;\n\
14b-359;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:12.893;\n\
14b-368;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:14.363;\n\
14b-376;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:15.930;\n\
14b-395;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:17.530;\n\
14b-416;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:19.110;\n\
14b-429;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:20.593;\n\
14b-433;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:22.177;\n\
14b-438;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:24.130;\n\
14b-471;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:25.703;\n\
14b-472;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:26.817;\n\
14b-476;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:28.550;\n\
14b-482;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:30.047;\n\
14b-484;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:31.707;\n\
14b-488;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:33.597;\n\
14b-497;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:35.380;\n\
14b-500;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:36.970;\n\
14b-503;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:38.437;\n\
14b-505;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:40.037;\n\
14b-517;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:41.607;\n\
14b-528;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:43.227;\n\
14b-535;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:44.813;\n\
14b-537;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:46.393;\n\
14b-541;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:47.970;\n\
14b-542;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:49.750;\n\
14b-546;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:51.447;\n\
14b-550;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:53.350;\n\
14b-556;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:55.173;\n\
14b-567;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:56.757;\n\
14b-569;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:22:58.697;\n\
14b-572;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:23:00.560;\n\
14b-575;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:23:02.150;\n\
14b-577;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:23:03.663;\n\
14b-578;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:23:05.413;\n\
14b-587;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:23:06.990;\n\
14b-591;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:23:08.613;\n\
14b-598;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:23:10.243;\n\
14b-604;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:23:12.150;\n\
14b-605;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-03 23:23:13.750;\n\
14b-303;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:04.690;\n\
14b-303;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:07.493;\n\
14b-306;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:07.657;\n\
14b-306;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:10.867;\n\
14b-309;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:10.997;\n\
14b-309;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:13.660;\n\
14b-311;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:13.783;\n\
14b-311;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:16.030;\n\
14b-313;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:16.153;\n\
14b-313;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:18.553;\n\
14b-314;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:18.693;\n\
14b-314;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:21.840;\n\
14b-315;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:21.970;\n\
14b-315;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:24.347;\n\
14b-318;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:24.470;\n\
14b-318;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:26.807;\n\
14b-319;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:26.930;\n\
14b-319;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:29.943;\n\
14b-320;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:30.190;\n\
14b-320;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:34.173;\n\
14b-323;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:34.453;\n\
14b-323;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:36.940;\n\
14b-326;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:37.180;\n\
14b-326;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:38.987;\n\
14b-337;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:39.100;\n\
14b-337;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:41.113;\n\
14b-341;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:41.237;\n\
14b-341;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:43.243;\n\
14b-350;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:43.370;\n\
14b-350;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:45.433;\n\
14b-358;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:45.570;\n\
14b-358;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:47.317;\n\
14b-359;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:47.460;\n\
14b-359;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:49.163;\n\
14b-368;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:49.307;\n\
14b-368;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:51.897;\n\
14b-376;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:52.020;\n\
14b-376;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:54.280;\n\
14b-395;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:54.433;\n\
14b-395;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:56.337;\n\
14b-416;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:56.460;\n\
14b-416;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:35:58.387;\n\
14b-429;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:35:58.547;\n\
14b-429;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:00.843;\n\
14b-433;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:01.110;\n\
14b-433;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:02.903;\n\
14b-438;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:03.017;\n\
14b-438;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:04.290;\n\
14b-471;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:04.360;\n\
14b-471;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:06.280;\n\
14b-472;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:06.433;\n\
14b-472;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:08.443;\n\
14b-476;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:08.580;\n\
14b-476;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:10.660;\n\
14b-482;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:10.820;\n\
14b-482;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:13.103;\n\
14b-484;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:13.307;\n\
14b-484;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:15.140;\n\
14b-488;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:15.403;\n\
14b-488;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:16.963;\n\
14b-497;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:17.040;\n\
14b-497;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:18.850;\n\
14b-500;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:19.013;\n\
14b-500;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:20.837;\n\
14b-503;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:20.967;\n\
14b-503;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:22.830;\n\
14b-505;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:22.960;\n\
14b-505;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:24.840;\n\
14b-517;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:25.003;\n\
14b-517;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:26.773;\n\
14b-528;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:26.910;\n\
14b-528;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:28.770;\n\
14b-535;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:28.903;\n\
14b-535;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:31.657;\n\
14b-537;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:31.793;\n\
14b-537;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:34.423;\n\
14b-541;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:34.580;\n\
14b-541;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:36.543;\n\
14b-542;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:36.807;\n\
14b-542;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:38.787;\n\
14b-546;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:38.903;\n\
14b-546;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:40.620;\n\
14b-550;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:40.740;\n\
14b-550;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:42.483;\n\
14b-556;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:42.823;\n\
14b-556;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:44.863;\n\
14b-567;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:45.007;\n\
14b-567;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:46.820;\n\
14b-569;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:46.943;\n\
14b-569;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:48.677;\n\
14b-572;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:48.890;\n\
14b-572;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:50.637;\n\
14b-575;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:50.837;\n\
14b-575;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:52.537;\n\
14b-577;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:52.680;\n\
14b-577;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:54.490;\n\
14b-578;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:54.693;\n\
14b-578;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:56.413;\n\
14b-587;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:56.547;\n\
14b-587;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:36:58.250;\n\
14b-591;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:36:58.407;\n\
14b-591;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:37:00.180;\n\
14b-598;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:37:00.343;\n\
14b-598;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:37:02.130;\n\
14b-604;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:37:02.270;\n\
14b-604;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:37:03.983;\n\
14b-605;DL_WFCaseTypeStep!478;Approve application;recover;2015-05-03 23:37:04.127;\n\
14b-605;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-03 23:37:05.840;\n\
14b-350;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;recover;2015-05-03 23:42:40.440;\n\
14a-055;DL_WFCaseTypeStep!480;Receive final report;administrator;2015-05-03 23:51:27.927;\n\
14a-063;DL_WFCaseTypeStep!480;Receive final report;administrator;2015-05-03 23:51:28.023;\n\
14a-073;DL_WFCaseTypeStep!480;Receive final report;administrator;2015-05-03 23:51:28.033;\n\
14a-182;DL_WFCaseTypeStep!480;Receive final report;administrator;2015-05-03 23:51:28.040;\n\
14a-221;DL_WFCaseTypeStep!480;Receive final report;administrator;2015-05-03 23:51:28.043;\n\
14a-245;DL_WFCaseTypeStep!480;Receive final report;administrator;2015-05-03 23:51:28.053;\n\
14b-352;DL_WFCaseTypeStep!480;Receive final report;administrator;2015-05-03 23:51:28.057;\n\
14b-380;DL_WFCaseTypeStep!480;Receive final report;administrator;2015-05-03 23:51:28.060;\n\
14b-456;DL_WFCaseTypeStep!480;Receive final report;administrator;2015-05-03 23:51:28.070;\n\
14b-465;DL_WFCaseTypeStep!480;Receive final report;administrator;2015-05-03 23:51:28.070;\n\
14b-560;DL_WFCaseTypeStep!480;Receive final report;administrator;2015-05-03 23:51:28.073;\n\
14a-055;DL_WFCaseTypeStep!520;Change phase to Complete;administrator;2015-05-03 23:52:00.050;\n\
14a-063;DL_WFCaseTypeStep!520;Change phase to Complete;administrator;2015-05-03 23:52:02.440;\n\
14a-073;DL_WFCaseTypeStep!520;Change phase to Complete;administrator;2015-05-03 23:52:04.453;\n\
14a-182;DL_WFCaseTypeStep!520;Change phase to Complete;administrator;2015-05-03 23:52:06.357;\n\
14a-221;DL_WFCaseTypeStep!520;Change phase to Complete;administrator;2015-05-03 23:52:08.100;\n\
14b-479;DL_WFCaseTypeStep!471;Lawyer Review;administrator;2015-05-04 00:01:12.573;\n\
14b-479;DL_WFCaseTypeStep!474;Review;administrator;2015-05-04 00:01:43.020;\n\
14b-479;DL_WFCaseTypeStep!473;Review;administrator;2015-05-04 00:01:43.023;\n\
14b-479;DL_WFCaseTypeStep!472;Architect Review;administrator;2015-05-04 00:01:43.027;\n\
14b-365;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-04 00:02:48.797;\n\
14b-460;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-04 00:02:49.603;\n\
14b-479;DL_WFCaseTypeStep!510;Round Ends;recover;2015-05-04 00:02:50.177;\n\
14b-365;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-04 00:03:02.857;\n\
14b-460;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-04 00:03:03.757;\n\
14b-479;DL_WFCaseTypeStep!485;Round approved;recover;2015-05-04 00:03:04.370;\n\
14b-330;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:00.267;\n\
14b-330;DL_WFCaseTypeStep!546;Applicant proves relevance of application;administrator;2015-05-04 00:11:03.850;\n\
14b-331;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:05.427;\n\
14b-331;DL_WFCaseTypeStep!546;Applicant proves relevance of application;administrator;2015-05-04 00:11:07.687;\n\
14b-334;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:08.770;\n\
14b-334;DL_WFCaseTypeStep!546;Applicant proves relevance of application;administrator;2015-05-04 00:11:10.677;\n\
14b-343;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:11.773;\n\
14b-343;DL_WFCaseTypeStep!546;Applicant proves relevance of application;administrator;2015-05-04 00:11:13.667;\n\
14b-352;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:14.637;\n\
14b-355;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:16.333;\n\
14b-355;DL_WFCaseTypeStep!546;Applicant proves relevance of application;administrator;2015-05-04 00:11:18.603;\n\
14b-356;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:19.620;\n\
14b-356;DL_WFCaseTypeStep!546;Applicant proves relevance of application;administrator;2015-05-04 00:11:21.460;\n\
14b-367;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:22.420;\n\
14b-371;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:24.190;\n\
14b-372;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:25.600;\n\
14b-377;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:26.950;\n\
14b-378;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:34.627;\n\
14b-380;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:35.713;\n\
14b-381;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:37.480;\n\
14b-391;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:38.757;\n\
14b-393;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:40.097;\n\
14b-394;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:41.173;\n\
14b-397;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:42.320;\n\
14b-398;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:43.660;\n\
14b-399;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:44.957;\n\
14b-401;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:45.830;\n\
14b-402;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:47.023;\n\
14b-403;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:48.403;\n\
14b-404;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:49.427;\n\
14b-405;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:50.903;\n\
14b-406;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:51.967;\n\
14b-415;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:53.660;\n\
14b-420;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:54.740;\n\
14b-430;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:55.920;\n\
14b-434;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:57.000;\n\
14b-443;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:58.407;\n\
14b-449;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:11:59.590;\n\
14b-450;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:00.700;\n\
14b-452;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:01.883;\n\
14b-453;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:02.967;\n\
14b-454;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:04.077;\n\
14b-455;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:05.150;\n\
14b-456;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:06.507;\n\
14b-459;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:08.070;\n\
14b-465;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:09.333;\n\
14b-473;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:11.213;\n\
14b-476;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:12.373;\n\
14b-481;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:13.057;\n\
14b-485;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:14.260;\n\
14b-494;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:15.397;\n\
14b-498;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:16.517;\n\
14b-504;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:17.877;\n\
14b-513;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:19.770;\n\
14b-519;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:21.073;\n\
14b-520;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:22.310;\n\
14b-521;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:24.083;\n\
14b-523;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:25.467;\n\
14b-526;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:26.590;\n\
14b-531;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:27.500;\n\
14b-532;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:28.890;\n\
14b-536;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:30.490;\n\
14b-545;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:31.670;\n\
14b-548;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:32.840;\n\
14b-551;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:33.980;\n\
14b-552;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:35.453;\n\
14b-558;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:36.980;\n\
14b-560;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:38.383;\n\
14b-565;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:40.920;\n\
14b-566;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:42.087;\n\
14b-579;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:43.257;\n\
14b-580;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:44.383;\n\
14b-584;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:45.750;\n\
14b-590;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:47.080;\n\
14b-597;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:48.700;\n\
14b-601;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:49.893;\n\
14b-606;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:51.370;\n\
14b-610;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:52.697;\n\
14b-612;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:53.873;\n\
14b-613;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:55.153;\n\
14b-623;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:56.320;\n\
14b-629;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:57.530;\n\
14b-630;DL_WFCaseTypeStep!497;Fill out Application;recover;2015-05-04 00:12:59.260;\n\
14a-249;DL_DCREventTaskPersonal!25379;NULL;morten;2015-05-06 22:43:24.757;\n\
14a-249;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:43:42.147;\n\
14a-036;DL_DCREventTaskPersonal!25273;NULL;morten;2015-05-06 22:44:09.353;\n\
14a-036;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:44:12.400;\n\
14a-048;DL_DCREventTaskPersonal!25278;NULL;morten;2015-05-06 22:50:35.873;\n\
14a-048;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:50:40.420;\n\
14a-049;DL_DCREventTaskPersonal!25279;NULL;morten;2015-05-06 22:50:53.250;\n\
14a-049;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:50:56.043;\n\
14a-074;DL_DCREventTaskPersonal!25295;NULL;morten;2015-05-06 22:51:09.600;\n\
14a-074;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:51:12.133;\n\
14a-077;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-06 22:51:26.610;\n\
14a-081;DL_DCREventTaskPersonal!25299;NULL;morten;2015-05-06 22:51:43.407;\n\
14a-081;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:51:44.937;\n\
14a-113;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-06 22:52:01.330;\n\
14a-113;DL_DCREventTaskPersonal!25307;NULL;morten;2015-05-06 22:52:05.067;\n\
14a-113;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:52:06.833;\n\
14a-115;DL_DCREventTaskPersonal!25308;NULL;morten;2015-05-06 22:52:19.280;\n\
14a-115;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:52:21.787;\n\
14a-132;DL_DCREventTaskPersonal!25313;NULL;morten;2015-05-06 22:52:37.510;\n\
14a-132;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:52:40.073;\n\
14a-133;DL_DCREventTaskPersonal!25314;NULL;morten;2015-05-06 22:52:52.867;\n\
14a-133;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:52:55.260;\n\
14a-135;DL_DCREventTaskPersonal!25316;NULL;morten;2015-05-06 22:53:08.250;\n\
14a-135;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:53:10.500;\n\
14a-136;DL_DCREventTaskPersonal!25317;NULL;morten;2015-05-06 22:53:22.673;\n\
14a-136;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:53:24.683;\n\
14a-154;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-06 22:53:57.057;\n\
14a-162;DL_DCREventTaskPersonal!25328;NULL;morten;2015-05-06 22:54:12.177;\n\
14a-162;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:54:13.533;\n\
14a-173;DL_DCREventTaskPersonal!25333;NULL;morten;2015-05-06 22:54:26.433;\n\
14a-173;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:54:28.543;\n\
14a-189;DL_DCREventTaskPersonal!25341;NULL;morten;2015-05-06 22:54:41.573;\n\
14a-189;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:54:43.557;\n\
14a-191;DL_DCREventTaskPersonal!25343;NULL;morten;2015-05-06 22:54:56.437;\n\
14a-191;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:54:58.507;\n\
14a-194;DL_DCREventTaskPersonal!25345;NULL;morten;2015-05-06 22:55:11.097;\n\
14a-194;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:55:13.097;\n\
14a-200;DL_DCREventTaskPersonal!25350;NULL;morten;2015-05-06 22:55:24.957;\n\
14a-200;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:55:26.947;\n\
14a-202;DL_DCREventTaskPersonal!25351;NULL;morten;2015-05-06 22:55:40.003;\n\
14a-202;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:55:42.060;\n\
14a-206;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-06 22:55:53.823;\n\
14a-223;DL_DCREventTaskPersonal!25364;NULL;morten;2015-05-06 22:56:07.460;\n\
14a-223;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:56:08.813;\n\
14a-245;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-06 22:56:22.853;\n\
14a-247;DL_DCREventTaskPersonal!25378;NULL;morten;2015-05-06 22:56:37.130;\n\
14a-247;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:56:38.660;\n\
14a-270;DL_DCREventTaskPersonal!25387;NULL;morten;2015-05-06 22:57:10.253;\n\
14a-270;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:57:12.590;\n\
14a-268;DL_DCREventTaskPersonal!25386;NULL;morten;2015-05-06 22:57:28.373;\n\
14a-268;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-06 22:57:30.410;\n\
14a-178;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-06 23:03:05.760;\n\
14b-304;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:07:06.390;\n\
14b-304;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:13:33.877;\n\
14b-377;DL_DCREventDBTrigger!16060;NULL;CompletePayments;2015-05-06 23:14:48.277;\n\
14a-229;DL_WFCaseTypeStep!479;First payout;morten;2015-05-06 23:15:50.730;\n\
14a-235;DL_WFCaseTypeStep!479;First payout;morten;2015-05-06 23:15:50.770;\n\
14a-246;DL_WFCaseTypeStep!479;First payout;morten;2015-05-06 23:15:50.820;\n\
14a-071;DL_WFCaseTypeStep!479;First payout;morten;2015-05-06 23:15:50.893;\n\
14a-131;DL_WFCaseTypeStep!479;First payout;morten;2015-05-06 23:15:50.977;\n\
14a-213;DL_WFCaseTypeStep!479;First payout;morten;2015-05-06 23:15:51.017;\n\
14b-377;DF_Udbetaling!902;NULL;morten;2015-05-06 23:15:51.053;\n\
14a-208;DL_WFCaseTypeStep!479;First payout;morten;2015-05-06 23:15:51.083;\n\
14b-377;DF_Udbetaling!904;NULL;morten;2015-05-06 23:15:51.160;\n\
14b-377;DF_Udbetaling!905;NULL;morten;2015-05-06 23:15:51.187;\n\
14b-377;DF_Udbetaling!906;NULL;morten;2015-05-06 23:15:51.213;\n\
14a-246;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-06 23:16:03.753;\n\
14a-229;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:18:55.987;\n\
14a-235;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:18:56.000;\n\
14a-246;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:18:56.000;\n\
14a-071;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:18:56.000;\n\
14a-131;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:18:56.003;\n\
14a-213;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:18:56.003;\n\
14b-377;DL_DCREventDBTrigger!17859;NULL;CompletePayments;2015-05-06 23:18:56.003;\n\
14a-208;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:18:56.003;\n\
14b-377;DL_DCREventDBTrigger!17875;NULL;CompletePayments;2015-05-06 23:18:56.003;\n\
14b-377;DL_DCREventDBTrigger!17876;NULL;CompletePayments;2015-05-06 23:18:56.007;\n\
14b-377;DL_DCREventDBTrigger!17877;NULL;CompletePayments;2015-05-06 23:18:56.007;\n\
14a-229;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-06 23:24:04.843;\n\
14a-235;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-06 23:24:07.050;\n\
14a-071;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-06 23:24:10.357;\n\
14a-131;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-06 23:24:12.620;\n\
14a-213;DL_WFCaseTypeStep!521;Change Phase to End Report;morten;2015-05-06 23:24:15.363;\n\
14a-208;DL_WFCaseTypeStep!521;Change Phase to End Report;morten;2015-05-06 23:24:20.927;\n\
14a-223;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:40.927;\n\
14a-270;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:40.963;\n\
14a-036;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.003;\n\
14a-081;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.067;\n\
14a-132;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.130;\n\
14a-133;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.153;\n\
14a-135;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.173;\n\
14a-189;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.213;\n\
14a-194;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.270;\n\
14a-200;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.303;\n\
14a-202;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.397;\n\
14a-048;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.427;\n\
14a-136;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.453;\n\
14a-150;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.493;\n\
14a-173;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.533;\n\
14a-191;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.560;\n\
14a-049;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.593;\n\
14a-162;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.647;\n\
14a-115;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.680;\n\
14a-151;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.707;\n\
14a-245;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.780;\n\
14a-247;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.840;\n\
14a-074;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.897;\n\
14a-113;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.923;\n\
14a-154;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.950;\n\
14a-178;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-06 23:34:41.987;\n\
14a-223;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.400;\n\
14a-270;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.400;\n\
14a-036;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.400;\n\
14a-081;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.400;\n\
14a-132;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.403;\n\
14a-133;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.403;\n\
14a-135;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.403;\n\
14a-189;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.403;\n\
14a-194;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.403;\n\
14a-200;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.407;\n\
14a-202;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.407;\n\
14a-048;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.407;\n\
14a-136;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.407;\n\
14a-150;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.407;\n\
14a-173;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.410;\n\
14a-191;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.410;\n\
14a-049;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.410;\n\
14a-162;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.410;\n\
14a-115;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.410;\n\
14a-245;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.410;\n\
14a-247;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.413;\n\
14a-074;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.413;\n\
14a-113;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.413;\n\
14a-154;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.413;\n\
14a-178;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-06 23:36:49.413;\n\
14a-194;DL_WFCaseTypeStep!480;Receive final report;morten;2015-05-06 23:38:34.880;\n\
14a-223;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:37.860;\n\
14a-270;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:39.403;\n\
14a-036;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:40.903;\n\
14a-081;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:42.883;\n\
14a-132;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:44.473;\n\
14a-133;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:45.947;\n\
14a-135;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:47.343;\n\
14a-189;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:48.997;\n\
14a-194;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:50.270;\n\
14a-200;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:51.520;\n\
14a-202;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:52.707;\n\
14a-048;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:53.913;\n\
14a-136;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:55.387;\n\
14a-150;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:57.077;\n\
14a-173;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:38:58.727;\n\
14a-191;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:39:00.960;\n\
14a-049;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:39:02.243;\n\
14a-162;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:39:03.507;\n\
14a-115;DL_WFCaseTypeStep!521;Change Phase to End Report;ANONYMOUS LOGON;2015-05-06 23:39:04.927;\n\
14a-194;DL_WFCaseTypeStep!480;Receive final report;morten;2015-05-06 23:39:11.603;\n\
14a-245;DL_WFCaseTypeStep!521;Change Phase to End Report;morten;2015-05-06 23:39:13.500;\n\
14a-247;DL_WFCaseTypeStep!521;Change Phase to End Report;morten;2015-05-06 23:39:14.903;\n\
14a-074;DL_WFCaseTypeStep!521;Change Phase to End Report;morten;2015-05-06 23:39:16.133;\n\
14a-113;DL_WFCaseTypeStep!521;Change Phase to End Report;morten;2015-05-06 23:39:17.177;\n\
14a-154;DL_WFCaseTypeStep!521;Change Phase to End Report;morten;2015-05-06 23:39:18.283;\n\
14a-178;DL_WFCaseTypeStep!521;Change Phase to End Report;morten;2015-05-06 23:39:19.357;\n\
14a-194;DL_WFCaseTypeStep!520;Change phase to Complete;morten;2015-05-06 23:39:27.490;\n\
14a-245;DL_WFCaseTypeStep!480;Receive final report;morten;2015-05-06 23:43:51.167;\n\
14a-245;DL_WFCaseTypeStep!520;Change phase to Complete;morten;2015-05-06 23:43:54.420;\n\
14a-151;AddEvent;NULL;administrator;2015-05-06 23:58:54.583;\n\
14a-151;AddEvent;NULL;administrator;2015-05-07 00:00:39.227;\n\
14a-206;DL_WFCaseTypeStep!541;Account number changed;morten;2015-05-07 00:08:00.760;\n\
14a-249;DL_WFCaseTypeStep!541;Account number changed;morten;2015-05-07 00:09:37.050;\n\
14a-268;DL_WFCaseTypeStep!541;Account number changed;morten;2015-05-07 00:10:23.593;\n\
14a-206;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-07 00:10:46.653;\n\
14a-268;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-07 00:10:55.687;\n\
14a-249;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-07 00:11:04.863;\n\
14a-249;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 00:20:19.023;\n\
14a-268;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 00:20:19.060;\n\
14a-206;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 00:20:19.090;\n\
14a-249;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 00:22:09.647;\n\
14a-268;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 00:22:09.650;\n\
14a-206;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 00:22:09.650;\n\
14a-249;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 00:22:11.480;\n\
14a-268;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 00:22:12.640;\n\
14a-206;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 00:22:13.827;\n\
14a-222;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-07 00:34:42.473;\n\
14b-331;DL_WFCaseTypeStep!482;Inform applicant about the grant;morten;2015-05-07 17:49:20.597;\n\
14b-331;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-07 17:49:26.237;\n\
14b-334;DL_WFCaseTypeStep!482;Inform applicant about the grant;morten;2015-05-07 17:50:05.563;\n\
14b-334;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-07 17:50:10.353;\n\
14b-343;DL_WFCaseTypeStep!482;Inform applicant about the grant;morten;2015-05-07 17:50:34.913;\n\
14b-343;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-07 17:50:39.067;\n\
14b-355;DL_WFCaseTypeStep!482;Inform applicant about the grant;morten;2015-05-07 17:51:49.377;\n\
14b-355;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-07 17:51:53.497;\n\
14b-306;AddEvent;NULL;administrator;2015-05-07 22:29:43.247;\n\
14b-306;DL_WFCaseTypeStep!539;Inform applicant that Best are examining the case;morten;2015-05-07 22:30:55.487;\n\
14b-306;AddEvent;NULL;administrator;2015-05-07 22:31:48.137;\n\
14b-330;DL_WFCaseTypeStep!482;Inform applicant about the grant;morten;2015-05-07 22:48:13.347;\n\
14b-330;DL_WFCaseTypeStep!522;Change Phase to Payout;morten;2015-05-07 22:48:17.837;\n\
14b-378;AddEvent;NULL;administrator;2015-05-07 22:51:59.013;\n\
14b-399;AddEvent;NULL;administrator;2015-05-07 23:03:21.193;\n\
14b-630;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:16.293;\n\
14b-629;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:16.397;\n\
14b-352;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:16.827;\n\
14b-380;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:16.900;\n\
14b-456;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.007;\n\
14b-465;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.180;\n\
14b-560;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.230;\n\
14b-331;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.293;\n\
14b-334;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.333;\n\
14b-343;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.367;\n\
14b-355;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.407;\n\
14b-306;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.450;\n\
14b-313;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.490;\n\
14b-314;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.520;\n\
14b-318;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.557;\n\
14b-319;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.600;\n\
14b-320;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.627;\n\
14b-323;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.653;\n\
14b-330;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.683;\n\
14b-378;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.713;\n\
14b-381;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.740;\n\
14b-391;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.770;\n\
14b-393;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.803;\n\
14b-394;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.833;\n\
14b-397;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.863;\n\
14b-398;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.893;\n\
14b-399;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:10:17.920;\n\
14b-630;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.190;\n\
14b-629;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.203;\n\
14b-352;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.203;\n\
14b-380;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.207;\n\
14b-456;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.207;\n\
14b-465;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.207;\n\
14b-560;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.207;\n\
14b-331;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.207;\n\
14b-334;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.210;\n\
14b-343;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.210;\n\
14b-355;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.210;\n\
14b-330;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-07 23:12:38.210;\n\
14b-630;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:36.177;\n\
14b-629;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:37.537;\n\
14b-352;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:38.967;\n\
14b-380;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:40.447;\n\
14b-456;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:41.810;\n\
14b-465;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:43.190;\n\
14b-560;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:44.593;\n\
14b-331;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:45.763;\n\
14b-334;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:47.037;\n\
14b-343;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:48.177;\n\
14b-355;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:49.340;\n\
14b-330;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-07 23:13:50.567;\n\
14b-352;DL_WFCaseTypeStep!520;Change phase to Complete;administrator;2015-05-07 23:14:06.430;\n\
14b-380;DL_WFCaseTypeStep!520;Change phase to Complete;administrator;2015-05-07 23:14:07.550;\n\
14b-456;DL_WFCaseTypeStep!520;Change phase to Complete;administrator;2015-05-07 23:14:08.663;\n\
14b-465;DL_WFCaseTypeStep!520;Change phase to Complete;administrator;2015-05-07 23:14:09.777;\n\
14b-560;DL_WFCaseTypeStep!520;Change phase to Complete;administrator;2015-05-07 23:14:10.710;\n\
14b-498;AddEvent;NULL;morten;2015-05-07 23:50:40.117;\n\
14b-498;AddNesting;NULL;morten;2015-05-07 23:50:40.147;\n\
14b-498;AddEvent;NULL;morten;2015-05-07 23:50:40.283;\n\
14b-498;AddNesting;NULL;morten;2015-05-07 23:50:40.283;\n\
14b-498;AddRelation;NULL;morten;2015-05-07 23:50:40.287;\n\
14b-498;AddRelation;NULL;morten;2015-05-07 23:50:40.287;\n\
14b-498;AddRelation;NULL;morten;2015-05-07 23:50:40.287;\n\
14b-498;AddRelation;NULL;morten;2015-05-07 23:50:40.287;\n\
14b-498;AddEvent;NULL;morten;2015-05-07 23:50:40.290;\n\
14b-498;AddRelation;NULL;morten;2015-05-07 23:50:40.290;\n\
14b-401;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:19.660;\n\
14b-402;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:19.687;\n\
14b-403;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:19.710;\n\
14b-404;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:19.733;\n\
14b-405;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:19.760;\n\
14b-415;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:19.800;\n\
14b-420;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:19.857;\n\
14b-430;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:19.880;\n\
14b-434;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:19.953;\n\
14b-443;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:19.980;\n\
14b-449;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.017;\n\
14b-450;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.053;\n\
14b-452;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.087;\n\
14b-453;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.167;\n\
14b-454;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.230;\n\
14b-455;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.270;\n\
14b-459;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.303;\n\
14b-473;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.333;\n\
14b-481;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.363;\n\
14b-485;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.400;\n\
14b-494;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.440;\n\
14b-498;DL_WFCaseTypeStep!479;First payout;morten;2015-05-07 23:53:20.497;\n\
14b-498;DF_Udbetaling!1071;NULL;morten;2015-05-07 23:53:20.523;\n\
14b-504;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.520;\n\
14b-513;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.570;\n\
14b-519;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.607;\n\
14b-520;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.650;\n\
14b-521;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.683;\n\
14b-523;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.713;\n\
14b-526;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.747;\n\
14b-531;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.807;\n\
14b-532;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.873;\n\
14b-536;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.913;\n\
14b-545;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.957;\n\
14b-548;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:30.993;\n\
14b-551;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.040;\n\
14b-552;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.077;\n\
14b-565;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.110;\n\
14b-566;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.140;\n\
14b-579;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.190;\n\
14b-580;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.227;\n\
14b-584;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.257;\n\
14b-590;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.283;\n\
14b-597;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.317;\n\
14b-601;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.357;\n\
14b-606;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.413;\n\
14b-610;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.450;\n\
14b-612;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.490;\n\
14b-613;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.527;\n\
14b-623;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 00:25:31.560;\n\
14a-077;DL_WFCaseTypeStep!479;First payout;morten;2015-05-08 01:22:30.430;\n\
14a-077;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-08 01:24:35.623;\n\
14a-131;AddEvent;NULL;morten;2015-05-08 09:53:01.827;\n\
14a-131;AddNesting;NULL;morten;2015-05-08 09:53:01.830;\n\
14a-131;AddEvent;NULL;morten;2015-05-08 09:53:01.927;\n\
14a-131;AddNesting;NULL;morten;2015-05-08 09:53:01.927;\n\
14a-131;AddRelation;NULL;morten;2015-05-08 09:53:01.930;\n\
14a-131;AddRelation;NULL;morten;2015-05-08 09:53:01.930;\n\
14a-131;AddRelation;NULL;morten;2015-05-08 09:53:01.930;\n\
14a-131;AddRelation;NULL;morten;2015-05-08 09:53:01.930;\n\
14a-131;AddEvent;NULL;morten;2015-05-08 09:53:01.933;\n\
14a-131;AddRelation;NULL;morten;2015-05-08 09:53:01.933;\n\
14a-077;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-08 09:53:23.010;\n\
14b-356;DL_WFCaseTypeStep!482;Inform applicant about the grant;morten;2015-05-08 09:57:43.787;\n\
14b-356;DL_WFCaseTypeStep!522;Change Phase to Payout;ANONYMOUS LOGON;2015-05-08 09:57:48.120;\n\
14b-367;AddEvent;NULL;morten;2015-05-08 09:58:37.633;\n\
14b-367;AddNesting;NULL;morten;2015-05-08 09:58:37.653;\n\
14b-367;AddEvent;NULL;morten;2015-05-08 09:58:37.657;\n\
14b-367;AddNesting;NULL;morten;2015-05-08 09:58:37.657;\n\
14b-367;AddRelation;NULL;morten;2015-05-08 09:58:37.657;\n\
14b-367;AddRelation;NULL;morten;2015-05-08 09:58:37.657;\n\
14b-367;AddRelation;NULL;morten;2015-05-08 09:58:37.657;\n\
14b-367;AddRelation;NULL;morten;2015-05-08 09:58:37.657;\n\
14b-367;AddEvent;NULL;morten;2015-05-08 09:58:37.663;\n\
14b-367;AddRelation;NULL;morten;2015-05-08 09:58:37.663;\n\
14b-377;DL_WFCaseTypeStep!541;Account number changed;ANONYMOUS LOGON;2015-05-08 10:05:45.440;\n\
14b-377;AddEvent;NULL;morten;2015-05-08 10:06:57.797;\n\
14b-377;AddNesting;NULL;morten;2015-05-08 10:06:57.797;\n\
14b-377;AddEvent;NULL;morten;2015-05-08 10:06:57.800;\n\
14b-377;AddNesting;NULL;morten;2015-05-08 10:06:57.800;\n\
14b-377;AddRelation;NULL;morten;2015-05-08 10:06:57.800;\n\
14b-377;AddRelation;NULL;morten;2015-05-08 10:06:57.800;\n\
14b-377;AddRelation;NULL;morten;2015-05-08 10:06:57.800;\n\
14b-377;AddRelation;NULL;morten;2015-05-08 10:06:57.800;\n\
14b-377;AddEvent;NULL;morten;2015-05-08 10:06:57.800;\n\
14b-377;AddRelation;NULL;morten;2015-05-08 10:06:57.803;\n\
14b-377;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-08 10:16:59.427;\n\
14a-131;DF_Udbetaling!1100;NULL;ANONYMOUS LOGON;2015-05-08 10:18:42.440;\n\
14b-356;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-08 10:18:42.470;\n\
14b-367;DF_Udbetaling!1102;NULL;ANONYMOUS LOGON;2015-05-08 10:18:42.497;\n\
14b-372;DL_WFCaseTypeStep!479;First payout;ANONYMOUS LOGON;2015-05-08 10:18:42.520;\n\
14b-377;DF_Udbetaling!1104;NULL;ANONYMOUS LOGON;2015-05-08 10:18:42.543;\n\
14a-131;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-08 10:18:55.503;\n\
14b-367;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-08 10:18:58.833;\n\
14a-131;DL_DCREventDBTrigger!20014;NULL;CompletePayments;2015-05-08 10:21:19.857;\n\
14b-356;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-08 10:21:19.870;\n\
14b-367;DL_DCREventDBTrigger!20016;NULL;CompletePayments;2015-05-08 10:21:19.870;\n\
14b-377;DL_DCREventDBTrigger!20017;NULL;CompletePayments;2015-05-08 10:21:19.870;\n\
14b-356;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-08 10:21:26.507;\n\
14a-151;DL_WFCaseTypeStep!541;Account number changed;ANONYMOUS LOGON;2015-05-08 15:42:58.277;\n\
14a-151;DL_WFCaseTypeStep!484;Approve changed account number;morten;2015-05-08 15:43:56.460;\n\
14b-575;DL_WFCaseTypeStep!479;First payout;pdm;2015-05-12 13:57:37.243;\n\
14b-575;AddEvent;NULL;morten;2015-05-12 14:23:32.930;\n\
14b-575;AddNesting;NULL;morten;2015-05-12 14:23:32.933;\n\
14b-575;AddEvent;NULL;mmq;2015-05-12 14:24:43.297;\n\
14b-575;AddEvent;NULL;mmq;2015-05-12 14:27:52.517;\n\
14a-236;DL_DCREventTaskPersonal!25371;NULL;pdm;2015-05-12 14:30:14.277;\n\
14a-236;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2015-05-12 14:30:17.620;\n\
14a-236;DL_WFCaseTypeStep!479;First payout;pdm;2015-05-12 14:32:35.383;\n\
14b-567;DL_WFCaseTypeStep!479;First payout;pdm;2015-05-12 14:59:08.190;\n\
14a-117;DL_DCREventTaskPersonal!25310;NULL;morten;2015-05-12 16:54:00.150;\n\
14a-117;DL_WFCaseTypeStep!522;Change Phase to Payout;morten;2015-05-12 16:54:01.850;\n\
14a-210;DL_DCREventTaskPersonal!25356;NULL;morten;2015-05-12 16:54:43.750;\n\
14a-210;DL_WFCaseTypeStep!522;Change Phase to Payout;morten;2015-05-12 16:54:45.443;\n\
14a-267;DL_DCREventTaskPersonal!25385;NULL;morten;2015-05-12 16:56:35.343;\n\
14a-267;DL_WFCaseTypeStep!522;Change Phase to Payout;morten;2015-05-12 16:56:38.657;\n\
14b-303;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.737;\n\
14b-306;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.740;\n\
14b-309;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.740;\n\
14b-311;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.740;\n\
14b-313;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.740;\n\
14b-314;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.740;\n\
14b-315;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.740;\n\
14b-318;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.743;\n\
14b-319;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.743;\n\
14b-320;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.743;\n\
14b-323;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.743;\n\
14b-326;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.743;\n\
14b-330;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.743;\n\
14b-331;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.747;\n\
14b-334;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.747;\n\
14b-337;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.747;\n\
14b-341;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.747;\n\
14b-343;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.750;\n\
14b-350;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.750;\n\
14b-352;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.750;\n\
14b-355;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.750;\n\
14b-356;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.750;\n\
14b-358;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.750;\n\
14b-359;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.750;\n\
14b-365;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.753;\n\
14b-367;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.753;\n\
14b-368;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.753;\n\
14b-371;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.753;\n\
14b-372;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.753;\n\
14b-376;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.753;\n\
14b-377;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.757;\n\
14b-378;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.757;\n\
14b-380;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.757;\n\
14b-381;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.757;\n\
14b-391;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.757;\n\
14b-393;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.760;\n\
14b-394;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.760;\n\
14b-395;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.760;\n\
14b-397;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.760;\n\
14b-398;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.760;\n\
14b-399;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.760;\n\
14b-401;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.760;\n\
14b-402;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.760;\n\
14b-403;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.763;\n\
14b-404;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.763;\n\
14b-405;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.763;\n\
14b-406;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.763;\n\
14b-415;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.763;\n\
14b-416;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.767;\n\
14b-420;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.767;\n\
14b-429;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.767;\n\
14b-430;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.767;\n\
14b-433;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.767;\n\
14b-434;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.767;\n\
14b-438;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.770;\n\
14b-443;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.770;\n\
14b-449;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.770;\n\
14b-450;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.770;\n\
14b-452;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.770;\n\
14b-453;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.770;\n\
14b-454;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.773;\n\
14b-455;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.773;\n\
14b-456;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.773;\n\
14b-459;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.773;\n\
14b-460;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.773;\n\
14b-465;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.777;\n\
14b-471;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.777;\n\
14b-472;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.777;\n\
14b-473;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.777;\n\
14b-476;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.777;\n\
14b-479;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.777;\n\
14b-481;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.780;\n\
14b-482;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.780;\n\
14b-484;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.780;\n\
14b-485;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.780;\n\
14b-488;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.780;\n\
14b-494;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.780;\n\
14b-497;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.783;\n\
14b-498;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.783;\n\
14b-500;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.783;\n\
14b-503;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.783;\n\
14b-504;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.787;\n\
14b-505;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.787;\n\
14b-513;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.787;\n\
14b-517;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.787;\n\
14b-519;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.787;\n\
14b-520;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.790;\n\
14b-521;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.790;\n\
14b-523;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.790;\n\
14b-526;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.790;\n\
14b-528;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.790;\n\
14b-531;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.790;\n\
14b-532;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.790;\n\
14b-535;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.793;\n\
14b-536;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.793;\n\
14b-537;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.793;\n\
14b-541;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.793;\n\
14b-542;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.793;\n\
14b-545;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.793;\n\
14b-546;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.797;\n\
14b-548;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.797;\n\
14b-550;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.797;\n\
14b-551;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.797;\n\
14b-552;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.797;\n\
14b-556;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.800;\n\
14b-558;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.800;\n\
14b-560;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.800;\n\
14b-565;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.800;\n\
14b-566;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.800;\n\
14b-567;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.800;\n\
14b-569;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.800;\n\
14b-572;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.803;\n\
14b-575;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.803;\n\
14b-577;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.803;\n\
14b-578;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.803;\n\
14b-579;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.803;\n\
14b-580;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.803;\n\
14b-584;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.807;\n\
14b-587;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.807;\n\
14b-590;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.807;\n\
14b-591;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.807;\n\
14b-597;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.807;\n\
14b-598;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.810;\n\
14b-601;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.810;\n\
14b-604;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.810;\n\
14b-605;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.810;\n\
14b-606;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.810;\n\
14b-608;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.810;\n\
14b-610;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.810;\n\
14b-612;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.813;\n\
14b-613;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.813;\n\
14b-623;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.813;\n\
14b-629;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.813;\n\
14b-630;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.813;\n\
14b-631;DL_WFCaseTypeStep!485;Round approved;ANONYMOUS LOGON;2015-05-12 19:58:55.817;\n\
14b-303;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.933;\n\
14b-306;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.963;\n\
14b-309;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.963;\n\
14b-311;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.963;\n\
14b-313;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.963;\n\
14b-314;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.967;\n\
14b-315;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.967;\n\
14b-318;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.967;\n\
14b-319;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.967;\n\
14b-320;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.967;\n\
14b-323;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.967;\n\
14b-326;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.970;\n\
14b-337;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.970;\n\
14b-341;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.970;\n\
14b-350;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.970;\n\
14b-358;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.970;\n\
14b-359;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.973;\n\
14b-368;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.973;\n\
14b-372;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.973;\n\
14b-376;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.973;\n\
14b-378;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.977;\n\
14b-381;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.977;\n\
14b-391;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.977;\n\
14b-393;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.977;\n\
14b-394;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.977;\n\
14b-395;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.977;\n\
14b-397;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.980;\n\
14b-398;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.980;\n\
14b-401;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.980;\n\
14b-402;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.980;\n\
14b-403;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.980;\n\
14b-404;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.980;\n\
14b-405;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.980;\n\
14b-415;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.983;\n\
14b-416;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.983;\n\
14b-420;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.983;\n\
14b-429;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.983;\n\
14b-430;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.987;\n\
14b-433;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.987;\n\
14b-434;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.987;\n\
14b-438;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.987;\n\
14b-443;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.990;\n\
14b-449;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.990;\n\
14b-450;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.990;\n\
14b-452;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.990;\n\
14b-453;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.990;\n\
14b-454;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.990;\n\
14b-455;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.993;\n\
14b-459;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.993;\n\
14b-471;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.993;\n\
14b-472;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.997;\n\
14b-473;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.997;\n\
14b-476;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.997;\n\
14b-481;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:55.997;\n\
14b-482;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.000;\n\
14b-484;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.000;\n\
14b-485;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.000;\n\
14b-488;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.000;\n\
14b-494;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.000;\n\
14b-497;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.000;\n\
14b-498;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.003;\n\
14b-500;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.003;\n\
14b-503;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.003;\n\
14b-504;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.003;\n\
14b-505;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.007;\n\
14b-513;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.007;\n\
14b-517;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.007;\n\
14b-519;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.007;\n\
14b-520;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.010;\n\
14b-521;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.010;\n\
14b-523;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.010;\n\
14b-526;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.010;\n\
14b-528;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.010;\n\
14b-531;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.010;\n\
14b-532;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.013;\n\
14b-535;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.013;\n\
14b-536;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.013;\n\
14b-537;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.013;\n\
14b-541;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.017;\n\
14b-542;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.017;\n\
14b-545;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.017;\n\
14b-546;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.017;\n\
14b-548;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.017;\n\
14b-550;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.020;\n\
14b-551;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.020;\n\
14b-552;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.020;\n\
14b-556;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.020;\n\
14b-565;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.020;\n\
14b-566;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.023;\n\
14b-567;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.023;\n\
14b-569;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.023;\n\
14b-572;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.023;\n\
14b-575;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.027;\n\
14b-577;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.027;\n\
14b-578;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.027;\n\
14b-579;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.027;\n\
14b-580;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.030;\n\
14b-584;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.030;\n\
14b-587;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.030;\n\
14b-590;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.030;\n\
14b-591;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.030;\n\
14b-597;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.033;\n\
14b-598;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.033;\n\
14b-601;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.033;\n\
14b-604;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.033;\n\
14b-605;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.033;\n\
14b-606;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.037;\n\
14b-608;DL_WFCaseTypeStep!477;Reject application;ANONYMOUS LOGON;2015-05-12 19:58:56.037;\n\
14b-610;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.037;\n\
14b-612;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.037;\n\
14b-613;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.040;\n\
14b-623;DL_WFCaseTypeStep!478;Approve application;ANONYMOUS LOGON;2015-05-12 19:58:56.040;\n\
14b-303;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:36.523;\n\
14b-306;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:38.550;\n\
14b-309;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:40.137;\n\
14b-311;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:40.873;\n\
14b-313;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:42.773;\n\
14b-314;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:44.350;\n\
14b-315;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:45.553;\n\
14b-318;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:46.327;\n\
14b-319;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:47.960;\n\
14b-320;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:49.497;\n\
14b-323;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:50.357;\n\
14b-326;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:51.270;\n\
14b-337;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:52.220;\n\
14b-341;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:53.320;\n\
14b-350;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:55.087;\n\
14b-358;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:56.267;\n\
14b-359;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:57.040;\n\
14b-368;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:58.060;\n\
14b-372;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:01:59.210;\n\
14b-376;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:00.307;\n\
14b-378;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:01.600;\n\
14b-381;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:03.143;\n\
14b-391;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:04.560;\n\
14b-393;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:06.070;\n\
14b-394;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:07.490;\n\
14b-395;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:09.300;\n\
14b-397;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:11.607;\n\
14b-398;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:14.023;\n\
14b-401;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:15.130;\n\
14b-402;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:16.190;\n\
14b-403;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:17.293;\n\
14b-404;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:18.720;\n\
14b-405;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:19.793;\n\
14b-415;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:21.117;\n\
14b-416;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:22.227;\n\
14b-420;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:24.600;\n\
14b-429;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:25.660;\n\
14b-430;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:26.643;\n\
14b-433;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:27.570;\n\
14b-434;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:28.620;\n\
14b-438;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:29.557;\n\
14b-443;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:30.930;\n\
14b-449;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:32.620;\n\
14b-450;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:34.183;\n\
14b-452;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:35.260;\n\
14b-453;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:36.310;\n\
14b-454;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:37.370;\n\
14b-455;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:38.413;\n\
14b-459;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:39.507;\n\
14b-471;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:40.567;\n\
14b-472;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:41.523;\n\
14b-473;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:42.503;\n\
14b-476;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:43.580;\n\
14b-481;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:44.897;\n\
14b-482;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:45.917;\n\
14b-484;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:46.840;\n\
14b-485;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:48.387;\n\
14b-488;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:49.890;\n\
14b-494;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:51.423;\n\
14b-497;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:52.480;\n\
14b-498;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:53.727;\n\
14b-500;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:54.760;\n\
14b-503;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:55.587;\n\
14b-504;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:56.617;\n\
14b-505;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:57.697;\n\
14b-513;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:58.700;\n\
14b-517;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:02:59.613;\n\
14b-519;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:00.603;\n\
14b-520;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:01.740;\n\
14b-521;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:02.810;\n\
14b-523;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:03.897;\n\
14b-526;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:04.940;\n\
14b-528;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:05.777;\n\
14b-531;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:06.740;\n\
14b-532;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:07.820;\n\
14b-535;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:08.623;\n\
14b-536;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:09.763;\n\
14b-537;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:10.677;\n\
14b-541;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:11.683;\n\
14b-542;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:12.787;\n\
14b-545;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:13.800;\n\
14b-546;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:14.620;\n\
14b-548;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:15.763;\n\
14b-550;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:16.770;\n\
14b-551;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:17.977;\n\
14b-552;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:19.020;\n\
14b-556;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:19.970;\n\
14b-565;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:21.073;\n\
14b-566;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:22.143;\n\
14b-567;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:22.970;\n\
14b-569;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:23.873;\n\
14b-572;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:24.700;\n\
14b-575;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:25.577;\n\
14b-577;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:26.467;\n\
14b-578;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:27.670;\n\
14b-579;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:28.873;\n\
14b-580;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:30.000;\n\
14b-584;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:31.077;\n\
14b-587;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:31.963;\n\
14b-590;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:32.993;\n\
14b-591;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:33.863;\n\
14b-597;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:34.827;\n\
14b-598;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:35.730;\n\
14b-601;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:36.703;\n\
14b-604;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:37.663;\n\
14b-605;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:38.523;\n\
14b-606;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:39.530;\n\
14b-610;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:41.820;\n\
14b-612;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:43.387;\n\
14b-613;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:44.653;\n\
14b-623;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-12 20:03:45.787;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-13 03:00:05.833;\n\
14a-210;DL_WFCaseTypeStep!541;Account number changed;pdm;2015-05-13 09:56:34.893;\n\
14a-150;DL_WFCaseTypeStep!480;Receive final report;pdm;2015-05-13 11:19:25.157;\n\
14a-150;DL_WFCaseTypeStep!520;Change phase to Complete;pdm;2015-05-13 11:19:28.403;\n\
14a-117;DL_WFCaseTypeStep!479;First payout;pdm;2015-05-13 11:27:36.163;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-14 03:00:05.220;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-15 03:00:06.590;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-16 03:00:06.243;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-17 03:00:05.647;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-18 03:00:04.953;\n\
14a-267;DL_WFCaseTypeStep!541;Account number changed;pdm;2015-05-18 10:57:09.363;\n\
14a-117;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-18 13:09:07.270;\n\
14a-117;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-18 13:10:04.140;\n\
14a-210;DL_WFCaseTypeStep!484;Approve changed account number;va;2015-05-18 14:23:47.900;\n\
14a-267;DL_WFCaseTypeStep!484;Approve changed account number;va;2015-05-18 14:27:11.000;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-19 03:00:05.697;\n\
14a-041;DL_WFCaseTypeStep!541;Account number changed;pdm;2015-05-19 14:15:42.100;\n\
14a-041;AddEvent;NULL;pdm;2015-05-19 14:16:33.020;\n\
14a-041;AddNesting;NULL;pdm;2015-05-19 14:16:33.023;\n\
14a-041;AddEvent;NULL;pdm;2015-05-19 14:16:33.120;\n\
14a-041;AddNesting;NULL;pdm;2015-05-19 14:16:33.120;\n\
14a-041;AddRelation;NULL;pdm;2015-05-19 14:16:33.123;\n\
14a-041;AddRelation;NULL;pdm;2015-05-19 14:16:33.123;\n\
14a-041;AddRelation;NULL;pdm;2015-05-19 14:16:33.123;\n\
14a-041;AddRelation;NULL;pdm;2015-05-19 14:16:33.123;\n\
14a-041;AddEvent;NULL;pdm;2015-05-19 14:16:33.127;\n\
14a-041;AddRelation;NULL;pdm;2015-05-19 14:16:33.127;\n\
14a-118;AddEvent;NULL;pdm;2015-05-19 14:21:35.367;\n\
14a-118;AddNesting;NULL;pdm;2015-05-19 14:21:35.367;\n\
14a-118;AddEvent;NULL;pdm;2015-05-19 14:21:35.370;\n\
14a-118;AddNesting;NULL;pdm;2015-05-19 14:21:35.370;\n\
14a-118;AddRelation;NULL;pdm;2015-05-19 14:21:35.397;\n\
14a-118;AddRelation;NULL;pdm;2015-05-19 14:21:35.397;\n\
14a-118;AddRelation;NULL;pdm;2015-05-19 14:21:35.397;\n\
14a-118;AddRelation;NULL;pdm;2015-05-19 14:21:35.397;\n\
14a-118;AddEvent;NULL;pdm;2015-05-19 14:21:35.400;\n\
14a-118;AddRelation;NULL;pdm;2015-05-19 14:21:35.400;\n\
14a-041;DL_WFCaseTypeStep!484;Approve changed account number;va;2015-05-19 14:21:38.987;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-20 03:00:06.277;\n\
14a-210;DL_WFCaseTypeStep!479;First payout;pdm;2015-05-20 12:51:34.067;\n\
14a-267;DL_WFCaseTypeStep!479;First payout;pdm;2015-05-20 12:51:34.103;\n\
14a-041;DF_Udbetaling!1109;NULL;pdm;2015-05-20 12:51:34.503;\n\
14a-118;DF_Udbetaling!1110;NULL;pdm;2015-05-20 12:51:34.530;\n\
14a-041;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-20 12:55:23.107;\n\
14a-118;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-20 12:55:24.743;\n\
14a-068;DL_DCREventTaskPersonal!25292;NULL;pdm;2015-05-20 12:57:10.377;\n\
14a-068;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2015-05-20 12:57:17.247;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-21 03:00:05.920;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-22 03:00:06.873;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-23 03:00:06.217;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-24 03:00:05.290;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-25 03:00:05.053;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-26 03:00:05.380;\n\
14b-541;DL_WFCaseTypeStep!541;Account number changed;pdm;2015-05-26 11:21:26.117;\n\
14b-541;DL_WFCaseTypeStep!541;Account number changed;morten;2015-05-26 16:33:25.850;\n\
14b-541;DL_WFCaseTypeStep!541;Account number changed;morten;2015-05-26 16:33:28.227;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-27 03:00:04.900;\n\
14b-541;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-27 08:59:48.077;\n\
14b-541;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-27 09:02:58.340;\n\
14b-541;DL_WFCaseTypeStep!485;Round approved;administrator;2015-05-27 09:04:24.110;\n\
14b-541;DL_WFCaseTypeStep!478;Approve application;administrator;2015-05-27 09:05:17.937;\n\
14b-541;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-27 09:05:23.447;\n\
14b-541;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-27 09:07:52.607;\n\
14b-541;DL_WFCaseTypeStep!531;Change phase to Preparation;administrator;2015-05-27 09:10:48.840;\n\
14b-541;DL_WFCaseTypeStep!482;Inform applicant about the grant;administrator;2015-05-27 09:13:48.177;\n\
14b-541;DL_WFCaseTypeStep!546;Applicant proves relevance of application;administrator;2015-05-27 09:43:59.960;\n\
14b-541;DL_WFCaseTypeStep!545;Execute pre-decision;administrator;2015-05-27 09:45:03.643;\n\
14b-541;DL_WFCaseTypeStep!482;Inform applicant about the grant;morten;2015-05-27 09:45:36.720;\n\
14b-541;DL_WFCaseTypeStep!546;Applicant proves relevance of application;morten;2015-05-27 09:45:43.470;\n\
14b-541;DL_WFCaseTypeStep!522;Change Phase to Payout;morten;2015-05-27 09:45:47.087;\n\
14a-168;DL_DCREventTaskPersonal!25330;NULL;pdm;2015-05-27 15:34:50.617;\n\
14a-168;DL_WFCaseTypeStep!522;Change Phase to Payout;pdm;2015-05-27 15:34:55.210;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-28 03:01:10.037;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-29 03:00:07.340;\n\
14a-210;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-29 10:16:12.420;\n\
14a-267;DL_WFCaseTypeStep!537;Payment completed;CompletePayments;2015-05-29 10:16:12.470;\n\
14a-041;DL_DCREventDBTrigger!20130;NULL;CompletePayments;2015-05-29 10:16:12.847;\n\
14a-118;DL_DCREventDBTrigger!20131;NULL;CompletePayments;2015-05-29 10:16:12.880;\n\
14a-210;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-29 10:20:05.237;\n\
14a-267;DL_WFCaseTypeStep!521;Change Phase to End Report;administrator;2015-05-29 10:20:08.420;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-30 03:00:06.770;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-05-31 03:00:05.903;\n\
14b-608;DL_WFCaseTypeStep!510;Round Ends;administrator;2015-06-01 03:00:07.947;'