/* eslint-disable no-loop-func */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Loader, Icon, Whisper, Tooltip, Popover, Table, Button, ButtonToolbar, ButtonGroup, IconButton, Input, AutoComplete, Checkbox } from 'rsuite';
import { LogRecord } from '../../models/engine-types';
import { IFileData } from '../../models/file';
import { AttributeCol, GDPRAttr, getShapeActionIds, SeparatorInfo } from '../../models/shape';
import { TimeFormat } from '../../models/timeformat';
import { arrayEquals, divideRecord, formatDate, formatDateInfo, ShapeColors, WHISPER_DELAY } from '../../utils/utils';
import Regex from '../shapePanelComponents/Regex';

/* Displays the content of a log as well as information on the translation of each record */

interface ContentDisplayProps{
    fdata:IFileData
    loading:boolean
    filterShape: number | undefined
    activeShape: number
    activeColumn: number[]
    activeRecord?: number
    setActiveColumn: (n:number[]) => void
    setActiveRecord: (n:number) => void
    setActiveShape: (n:number) => void
    onSeparatorInfoChange: (si:SeparatorInfo) => void
    onTimestampRegexChange: (timestampRegex: string) => void,
    onAttributeChange: () => void,
    addShape: () => void
    t: (k:string) => string
}

export class ContentDisplay extends React.PureComponent<ContentDisplayProps>{

    getSeparatedRecord(record:string, separatorInfo:SeparatorInfo, matchMultiple:boolean, attributes: AttributeCol[], timeformat:string){
        let { type, value } = separatorInfo
        if (!(value && type && record)) return []
        return divideRecord(record, separatorInfo, matchMultiple, attributes, timeformat)
    }

    setAttribute(column_indices:number[], attribute:GDPRAttr, value?:string){
        const s =  this.props.fdata.shapes[this.props.activeShape]

        if(column_indices.length === 0) return
        const newAttr = {
            attribute: attribute, 
            column: column_indices[column_indices.length-1],
            regex: attribute === 'constant' ? value : undefined
        }
        if(column_indices.length === 1){
            s.attributes = s.attributes.filter(a => 
                a.column !== column_indices[0] || 
                (attribute === 'dsid' && a.attribute === 'dataid') || 
                (attribute === 'dataid' && a.attribute === 'dsid')
            )
            s.attributes.push(newAttr)
        } else {
            const ss = s.attributes.filter(a => a.column === column_indices[0] && a.attribute === 'split')
            if(!ss || ss.length <= 0) throw Error('Index of out bounds of children array.')
            let attr = ss[0]
            let i = 1
            while(i < column_indices.length-1){
                if(attr.children === undefined) throw Error('Index of out bounds of children array.')
                const search = attr.children.filter(a => a.column === column_indices[i] && a.attribute === 'split')
                if(!search || search.length <= 0) throw Error('Index of out bounds of children array.')
                attr = search[0]
                i++
            }
            
            if(attr !== undefined){
                if(attr.children !== undefined){
                    attr.children = attr.children.filter(a => 
                        a.column !== column_indices[column_indices.length-1] || 
                        (attribute === 'dsid' && a.attribute === 'dataid') || 
                        (attribute === 'dataid' && a.attribute === 'dsid')
                    )
                    attr.children.push(newAttr)
                } else {
                    attr.children = [newAttr]
                }
            }
        }

        this.props.onAttributeChange()
    }

    clearColumn(column_indices:number[]){
        const s =  this.props.fdata.shapes[this.props.activeShape]

        if(column_indices.length === 0) return
        if(column_indices.length === 1){
            s.attributes = s.attributes.filter(a => a.column !== column_indices[0])
        } else {
            const ss = s.attributes.filter(a => a.column === column_indices[0] && a.attribute === 'split')
            if(!ss || ss.length <= 0) throw Error('Index of out bounds of children array.')
            let attr = ss[0]
            let i = 1
            while(i < column_indices.length-1){
                if(!attr.children) throw Error('Index of out bounds of children array.')
                const search = attr.children.filter(a => a.column === column_indices[i] && a.attribute === 'split')
                if(!search || search.length <= 0) throw Error('Index of out bounds of children array.')
                attr = search[0]
                i++
            }

            if(attr.children === undefined) return
            attr.children = attr.children.filter(a => a.column !== column_indices[column_indices.length-1])

        }
        this.props.onAttributeChange()
    }

    attributeMenu(g:{value:string, matched:boolean, shapeMatch: boolean}){
        return (
            <ButtonGroup style={{overflowWrap: 'normal'}} vertical>

                <Button
                    size='sm'
                    color='cyan' 
                    onClick={() => this.setAttribute(this.props.activeColumn, 'timestamp', g.value)}
                >
                    Time
                </Button>

                <Button 
                    size='sm'
                    color='red' 
                    onClick={() => this.setAttribute(this.props.activeColumn, 'dsid', g.value)}
                >
                    DS Id
                </Button>

                <Button 
                    size='sm'
                    color='yellow' 
                    onClick={() => this.setAttribute(this.props.activeColumn, 'dataid', g.value)}
                >
                    Data Id
                </Button>

                <Button 
                    size='sm'
                    color='green' 
                    onClick={() => this.setAttribute(this.props.activeColumn, 'action', g.value)}
                >
                    Action
                </Button>

                <Button 
                    size='sm'
                    color='violet'
                    onClick={() => this.setAttribute(this.props.activeColumn, 'constant', g.value)}
                >
                    Constant
                </Button>

                <Button 
                    size='sm'
                    appearance='ghost'
                    color='blue'
                    onClick={() => this.setAttribute(this.props.activeColumn, 'split', g.value)}
                >
                    Split
                </Button>

                <Button 
                    size='sm'
                    color='blue'
                    onClick={() => this.clearColumn(this.props.activeColumn)}
                >
                    None
                </Button>
            </ButtonGroup>
        )
    }

    recordButton(attribute:AttributeCol[], i:number[], g:{value:string, matched:boolean, shapeMatch: boolean}){
        const shape = this.props.fdata.shapes[this.props.activeShape]
        const actionIds = Array.from(getShapeActionIds(shape))

        let date = this.props.t('timestamp_match_error')
        const timestampRegex = this.props.fdata.shapes[this.props.activeShape].timestampRegex
        if (attribute.length > 0 && attribute[0].attribute === 'timestamp') {   
            try {
                if(timestampRegex.length === 0) {date = formatDateInfo(new Date(Date.parse(g.value)))}
                else {date = new TimeFormat(timestampRegex ? timestampRegex : '').getMatchResult(g.value)}
            } catch (e) {

            } 
        }

        const splitGroups = attribute.length ? 
            this.getSeparatedRecord(
                g.value, 
                { type: 'regex', value: attribute[0].regex || '' },
                attribute[0].matchMultiple || false, 
                attribute[0].children || [],
                timestampRegex
            )
            : []

        const cogwheel =                   
            <IconButton 
                appearance='subtle' 
                icon={<Icon icon={'cog'}/>} 
                size='xs' 
                style={{marginTop: -16, marginLeft: -6}} 
            /> 

        let numMatched = 0

        return (
            <span style={{whiteSpace: 'nowrap'}}>
                {!g.matched ?
                <Whisper enterable delay={WHISPER_DELAY} trigger={'hover'} speaker={<Popover>{g.value}</Popover>}>
                    <span>
                    <Button 
                        style={{
                            marginLeft: 2,
                            marginBottom: 2,
                            maxWidth: 120,
                            textOverflow: 'ellipsis',
                            overflowWrap: 'anywhere',
                            cursor: 'default'
                        }}
                        appearance='subtle'
                        size='xs'
                    >
                        {g.value || ' - '}
                    </Button> 
                    </span>
                </Whisper>
                :
                <Whisper
                    trigger={'click'}
                    preventOverflow
                    speaker={
                        !arrayEquals(i, this.props.activeColumn) ? <Popover/> :
                        <Popover>
                            {this.attributeMenu(g)}
                        </Popover>
                    }
                >
                    <Button 
                        color={
                            attribute.length <= 0 ? 'blue' : 
                            attribute.length > 1 ? 'orange' : 
                            attribute[0].attribute === 'timestamp' ? 'cyan' :
                            attribute[0].attribute === 'dataid' ? 'yellow' :
                            attribute[0].attribute === 'dsid' ? 'red' :
                            attribute[0].attribute === 'action' ? 'green' :
                            attribute[0].attribute === 'split' ? 'cyan' :
                            attribute[0].attribute === 'constant' ? 'violet' :
                            'cyan'
                        }
                        style={{
                            marginLeft: 2,
                            marginBottom: 2,
                            maxWidth: attribute.length && attribute[0].attribute === 'split' ? '' : 300,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            overflowWrap: 'anywhere',
                            border: g.shapeMatch ? '' : '2px solid red'
                        }}
                        size='xs'
                        disabled={!g.matched}
                        onClick={() => 
                            attribute.length > 0 && 
                            attribute[0].attribute === 'split' && 
                            splitGroups.filter(r => r.matched).length > 0 ? 
                                {} : 
                                this.props.setActiveColumn(i)
                        }
                        active={arrayEquals(i, this.props.activeColumn)}
                        appearance={
                            attribute.length === 1 && attribute[0].attribute === 'split' ? 
                            'ghost' : 
                            g.matched ?
                            'default' :
                            'subtle'
                        }
                    >
                        {
                            g.value ? 
                                attribute.length === 1 && attribute[0].attribute === 'split' && attribute[0].regex ?
                                splitGroups.map(p => {
                                    if(p.matched) numMatched++
                                    const idx = attribute[0].matchMultiple ? [...i, 0] : [...i, numMatched-1]
                                    const a:AttributeCol[] = attribute[0].children ? 
                                        attribute[0].children.filter(a => a.column === idx[idx.length-1]) : 
                                        []
                                    return this.recordButton(a, idx, p)
                                }) :
                                g.value :
                                ' - '
                        }
                    </Button>
                </Whisper>
                }
                    
                {attribute.length > 0 && attribute[0].attribute === 'timestamp' && g.matched ? 
                    <Whisper trigger={'click'} speaker={
                        <Popover>
                            <>                      
                                <Input
                                    placeholder={this.props.t('required_timestamp_format')} 
                                    value={this.props.fdata.shapes[this.props.activeShape].timestampRegex}
                                    onChange={(r:string, e) => this.props.onTimestampRegexChange(r)}
                                />
                                {date}
                            </>
                        </Popover>
                    }>
                        {cogwheel}
                    </Whisper>
                    : ''
                }
                {attribute.length > 0 && (attribute[0].attribute === 'split' || attribute[0].attribute === 'constant') && g.matched ? 
                    <Whisper trigger={'click'} speaker={
                        <Popover>
                            <div>
                                <Input    
                                    placeholder={this.props.t('regex')} 
                                    value={attribute[0].regex}
                                    onChange={(r:string, e) => {
                                        attribute[0].regex = r
                                        this.props.onAttributeChange()
                                    }}
                                />
                                {attribute[0].attribute === 'split' ?
                                    <Checkbox 
                                        value={attribute[0].matchMultiple} 
                                        onChange={(v, b) => {
                                            attribute[0].matchMultiple = b
                                            this.props.onAttributeChange()
                                        }} 
                                        checked={attribute[0].matchMultiple}
                                        title={this.props.t('match_multiple_info')}
                                    >
                                        {this.props.t('match_multiple_text')}
                                    </Checkbox>
                                    : ''
                                }
                            </div>                     
                        </Popover>
                    }>
                        {cogwheel}
                    </Whisper>
                    : ''
                }
                {attribute.length > 0 && attribute[0].attribute === 'action' && g.matched ?
                    <Whisper trigger={'click'} speaker={
                        <Popover>
                            <AutoComplete 
                                name={""} 
                                placeholder={this.props.t('action_id')} 
                                value={attribute[0].actionid}
                                filterBy={(s, item:{value:string, label:string}) => 
                                    s !== item.value && item.value.includes(s)}// don't show a suggestion that is the same as the current input
                                data={actionIds}
                                onChange={(r:string, e) => {attribute[0].actionid = r; this.props.onAttributeChange()}}
                                required="required"
                            />
                        </Popover>
                    }>
                        {cogwheel}
                    </Whisper>
                    : ''
                }
            </span>
        )
    }

    regexComponent(){
        return (
            <Regex 
                separatorInfo={this.props.fdata.shapes[this.props.activeShape].separatorInfo}
                onBlur={() => {}}
                onChange={this.props.onSeparatorInfoChange}
            />
        )
    }

    activeRow(r:LogRecord){
        const shape = this.props.fdata.shapes[this.props.activeShape]

        if(
            this.props.activeShape >= 0 && 
            this.props.fdata.shapes.length > this.props.activeShape && 
            this.props.fdata.shapes[this.props.activeShape].separatorInfo.value
        ){
            const timestampRegex = shape.timestampRegex
            return (
                <div style={{marginTop: -42, marginLeft: 50, width: '90%'}}>
                    <ButtonToolbar style={{overflowWrap: 'normal', marginBottom: 15, overflow: 'auto', maxHeight: 90, minHeight: 50}}>
                        {
                            this.getSeparatedRecord(r.record, shape.separatorInfo, false, shape.attributes, timestampRegex).map((p,i) => {
                                const attribute:AttributeCol[] = shape.attributes.filter(a => a.column === i)
                                return this.recordButton(attribute, [i], p)
                            })
                        }
                    </ButtonToolbar>
                    {this.regexComponent()}
                </div>
            )
        } else {
            return (
                <div style={{overflowWrap: 'normal', marginTop: -42, marginLeft: 60, width: '90%'}}>
                    <div style={{marginBottom: 20, overflow: 'auto', maxHeight: 70}}>
                        {r.record}
                    </div>
                    {
                        this.props.fdata.shapes.length > this.props.activeShape && 
                        this.props.fdata.shapes[this.props.activeShape] !== undefined ? 
                            this.regexComponent()
                            : ''
                    }
                </div>
            )
        }
    }

    render(){
        const recs = this.props.filterShape !== undefined ? 
            this.props.fdata.records.filter(r => r.shapeid === this.props.filterShape) : 
            this.props.fdata.records

        return !this.props.loading ?
            <Table 
                virtualized
                showHeader={false} 
                height={1000}
                data={recs} 
                id="table"
                hover={false}
                rowKey={'index'}
                expandedRowKeys={this.props.activeRecord ? [this.props.activeRecord] : []}
                renderRowExpanded={this.activeRow.bind(this)}
                rowExpandedHeight={120}
                onRowClick={r => this.props.activeShape >= 0 ? this.props.setActiveRecord(r.index) : {}}
            >
                <Table.Column width={50}>
                    <Table.HeaderCell>{''}</Table.HeaderCell>
                    <Table.Cell>
                        {
                        (r:LogRecord) => 
                            r.shapeid >= 0 ? 
                            <Whisper 
                                delay={WHISPER_DELAY} 
                                speaker={
                                    <Tooltip>
                                        {`Entry line ${r.index+1} matched by entry shape ${r.shapeid+1}.\nClick to go to shape.`}
                                    </Tooltip>
                                }
                            >
                                <Icon 
                                    icon={'circle'} 
                                    style={{marginLeft: 10, color: ShapeColors[r.shapeid], cursor: 'pointer'}}
                                    onClick={() => {
                                        this.props.setActiveShape(r.shapeid)
                                        this.props.setActiveRecord(r.index)
                                    }}
                                />
                            </Whisper>
                            : 
                            <Whisper 
                                delay={WHISPER_DELAY} 
                                speaker={
                                    <Tooltip>
                                        {`Entry line ${r.index+1} not matched by any shape.\nClick to create a new shape for this entry.`}
                                    </Tooltip>
                                }
                            >
                                <Icon 
                                    icon={'circle-o'} 
                                    style={{marginLeft: 10, cursor: 'pointer'}}
                                    onClick={() => {
                                        this.props.addShape()
                                        this.props.setActiveRecord(r.index)
                                    }}
                                />
                            </Whisper>
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>{''}</Table.HeaderCell>
                    <Table.Cell>
                        {
                        (r:LogRecord) => 
                            this.props.activeRecord === r.index 
                            ? 
                            <p></p>
                            :
                            <span style={{marginLeft: 10}}>{r.record}</span>
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={50}>
                    <Table.HeaderCell>{''}</Table.HeaderCell>
                    <Table.Cell>
                        {
                        (r:LogRecord) => 
                            <Whisper
                                delay={WHISPER_DELAY} 
                                placement='topStart' 
                                speaker={
                                    <Popover>
                                        {r.shapeid >= 0 ?
                                        <p style={{fontSize: 14}}>
                                            <b>Actions:</b> <ul>{r.actions.map(a => <li>{a}</li>)}</ul>
                                            <b>DS id:</b> {r.dsid}
                                            <b>Data id:</b> {r.dataid}
                                            <br/>
                                            <b>Timestamp:</b> {formatDate(r.timestamp)}
                                        </p>
                                        : <p>Entry not matched</p>}
                                    </Popover>
                                }
                            >
                                <Icon icon={'more'} style={{cursor: 'pointer'}} />
                            </Whisper> 
                        }
                    </Table.Cell>
                </Table.Column>
            </Table>
            : 
            <Loader size="xs" content="Loading content" />
    }
}

export default withTranslation()(ContentDisplay)