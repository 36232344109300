import { Component } from 'react';
import { Alert, Divider, FlexboxGrid, Panel} from 'rsuite';
import { AnalysisInfo, LogInfo, MappingInfo, ShapeInfo, WorkspaceInfo } from '../../../../common/ClientServerInterface';
import UploadPanel from '../helperComponents/UploadPanel';
import { Repo } from '../../repositories/Repo';
import MyModal from '../helperComponents/MyModal';
import ResourceList from '../helperComponents/ResourceList';
import { EditText } from '../helperComponents/EditableText';
import { withTranslation } from 'react-i18next';
import { ERROR_TIME, formatDate } from '../../utils/utils';

/* Modal to access the resources saved in the workspace */

interface WorkspaceResourceModalProps {
    userId: number
    workspace: WorkspaceInfo|undefined
    saveWorkspaceName: (workspace:WorkspaceInfo, newName:string) => Promise<void>
    onClose: () => void
    show: boolean
    t: (k:string) => string
    tReady: boolean
}

interface WorkspaceResourceModalState {
    logs:LogInfo[]
    shapes:ShapeInfo[]
    mappings:MappingInfo[]
    analyses:AnalysisInfo[]
    showContentModal: boolean
    content: string
}

class WorkspaceResourceModal extends Component<WorkspaceResourceModalProps> {
    state:WorkspaceResourceModalState
    userId: number

    constructor(props:WorkspaceResourceModalProps) {
        super(props);
        this.userId = props.userId
        this.state = {
            logs: [],
            shapes: [],
            mappings: [],
            analyses: [],
            showContentModal: false,
            content: ''
        }
    }

    showContentModal(content:string){
        this.setState({content, showContentModal: true})
    }

    async removeLog(id:number){
        await Repo.removeLog(this.props.workspace!.id, id)
        .then(res => {
            if (res.success) {
                const logs = this.state.logs
                this.setState({logs: []}) //To fix table update bug
                this.setState({logs: logs.filter(log => log.id !== id)})
            } else {
                Alert.error(this.props.t('remove_log_error') + res.error_msg, ERROR_TIME);
            }
        })
    }

    async removeShape(id:number){
        await Repo.removeShape(this.props.workspace!.id, id)
        .then(res => {
            if (res.success) {
                const shapes = this.state.shapes
                this.setState({shapes: []}) //To fix table update bug
                this.setState({shapes: shapes.filter(shape => shape.id !== id)})
            } else {
                Alert.error(this.props.t('remove_shape_error') + res.error_msg, ERROR_TIME);
            }
        })
    }

    async removeMapping(id:number){
        await Repo.removeMapping(this.props.workspace!.id, id)
        .then(res => {
            if (res.success) {
                const mappings = this.state.mappings
                this.setState({mappings: []}) //To fix table update bug
                this.setState({mappings: mappings.filter(mapping => mapping.id !== id)})
            } else {
                Alert.error(this.props.t('remove_mapping_error') + res.error_msg, ERROR_TIME);
            }
        })
    }

    async removeAnalysis(id:number){
        await Repo.removeAnalysis(this.props.workspace!.id, id)
        .then(res => {
            if (res.success) {
                const analyses = this.state.analyses
                this.setState({analyses: []}) //To fix table update bug
                this.setState({analyses: analyses.filter(analysis => analysis.id !== id)})
            } else {
                Alert.error(this.props.t('remove_analysis_error') + res.error_msg, ERROR_TIME);
            }
        })
    }

    async saveLogName(id:number, name:string){
        await Repo.editLog(this.props.workspace!.id, id, {log_name: name})
        .then(res => {
            if(res.success) {
                Alert.info(this.props.t('edit_log_success'));
            } else {
                Alert.error(this.props.t('edit_log_error') + res.error_msg, ERROR_TIME);
            }
        })
    }

    async saveShapeName(id:number, name:string){
        await Repo.editShape(this.props.workspace!.id, id, {shape_name: name})
        .then(res => {
            if(res.success) {
                Alert.info(this.props.t('edit_shape_success'));
            } else {
                Alert.error(this.props.t('edit_shape_error') + res.error_msg, ERROR_TIME);
            }
        })
    }

    async saveMappingName(id:number, name:string){
        await Repo.editMapping(this.props.workspace!.id, id, {mapping_name: name})
        .then(res => {
            if(res.success) {
                Alert.info(this.props.t('edit_mapping_success'));
            } else {
                Alert.error(this.props.t('edit_mapping_error') + res.error_msg, ERROR_TIME);
            }
        })
    }

    async saveAnalysisName(id:number, name:string){
        await Repo.editAnalysis(this.props.workspace!.id, id, {name: name})
        .then(res => {
            if(res.success) {
                Alert.info(this.props.t('edit_analysis_success'));
            } else {
                Alert.error(this.props.t('edit_analysis_error') + res.error_msg, ERROR_TIME);
            }
        })
    }

    async fetchWorkspaceInfo(){
        let logs:LogInfo[] = []
        let shapes:ShapeInfo[] = []
        let mappings:MappingInfo[] = []
        let analyses:AnalysisInfo[] = []

        if(this.props.workspace){
            const res = await Repo.getWorkspace(this.props.workspace?.id)

            if(!res.success){
                Alert.error(this.props.t('get_workspace_error') + res.error_msg, ERROR_TIME)
            } else {
                logs = res.payload!.logs
                shapes = res.payload!.shapes
                mappings = res.payload!.mappings
                analyses = res.payload!.analyses

                this.setState({
                    logs: logs, 
                    shapes: shapes, 
                    mappings: mappings,
                    analyses: analyses
                })
            }
        }
    }

    getDurations(){
        const hour = this.props.t('hour')
        const hours = this.props.t('hours')
        const day = this.props.t('day')
        const days = this.props.t('days')
        const week = this.props.t('week')

        return [
            {val: 1, lab: '1'+hour},
            {val: 5, lab: '5'+hours},
            {val: 24, lab: '1'+day},
            {val: 24*3, lab: '3'+days},
            {val: 24*7, lab: '1'+week}
        ]
    }

    componentDidMount() {
        this.fetchWorkspaceInfo()
    }

    render() {
        const acc_role = this.props.workspace?.role

        return (
            this.props.workspace ?
            <MyModal 
                buttons={[]} 
                title={
                    <>
                    {acc_role !== 'Upload' ? 
                        <EditText 
                            onSave={(s) => this.props.saveWorkspaceName(this.props.workspace!, s)} 
                            value={this.props.workspace.name} 
                            editClassName="form-control" 
                            size={30}
                        />
                        :
                        this.props.workspace.name
                    }
                    <Divider></Divider>
                    </>
                } 
                titleSize={'36px'}
                show={this.props.show} 
                onClose={this.props.onClose} onAccept={this.props.onClose} 
                full={true} 
                onShow={this.fetchWorkspaceInfo.bind(this)}
            >
                <FlexboxGrid justify="center" style={{padding:  20}}>
                    <FlexboxGrid.Item colspan={16}>

                        <Panel bordered header={<h4 className='list-head'>{this.props.t('logs_in_workspace')}</h4>}>
                            <ResourceList 
                                data={this.state.logs.map(l => ({
                                    id: l.id, 
                                    name: l.name, 
                                    info: this.props.t('uploaded_on') + ' ' + 
                                        formatDate(new Date(Date.parse(String(l.pub_date)))) + ' ' + 
                                        this.props.t('by') + ' ' + 
                                        l.uploaded_by_username + '.',
                                    access: acc_role === 'Full' || Number(l.uploaded_by) === Number(this.userId),
                                    content: l.content
                                }))} 
                                emptyMessage={
                                    acc_role === 'Full' || acc_role === 'Standard'?
                                    this.props.t('no_logs') : 
                                    this.props.t('no_logs_upload_access')
                                } 
                                saveName={this.saveLogName.bind(this)}
                                remove={this.removeLog.bind(this)}
                                showContent={this.showContentModal.bind(this)} 
                                access={acc_role !== 'Upload'}
                            />
                        </Panel>

                        <div style={{marginTop:20, marginLeft:10}}>
                            <UploadPanel 
                                update={this.fetchWorkspaceInfo.bind(this)} 
                                workspaceId={this.props.workspace.id}
                            />
                        </div>

                        <div style={{marginTop: 50}}>
                            <Panel bordered header={
                                <h4 className='list-head'>
                                    {this.props.t('shapes_in_workspace')}
                                </h4>}
                            >
                                <ResourceList 
                                    data={this.state.shapes.map(s => ({
                                        id: s.id, 
                                        name: s.name, 
                                        info: this.props.t('uploaded_on') + ' ' + 
                                            formatDate(new Date(Date.parse(String(s.pub_date)))) + '.',
                                        access: acc_role === 'Full' || Number(s.uploaded_by) === Number(this.userId),
                                        content: s.content
                                    }))} 
                                    emptyMessage={
                                        acc_role === 'Full' || acc_role === 'Standard'? 
                                        this.props.t('no_shapes') : 
                                        this.props.t('no_shapes_upload_access')
                                    } 
                                    saveName={this.saveShapeName.bind(this)}
                                    remove={this.removeShape.bind(this)}
                                    showContent={this.showContentModal.bind(this)} 
                                    access={acc_role !== 'Upload'}
                                />
                            </Panel>
                        </div>

                        <div style={{marginTop: 50}}>
                            <Panel bordered header={
                                <h4 className='list-head'>
                                    {this.props.t('mappings_in_workspace')}
                                </h4>}
                            >
                                <ResourceList 
                                    data={this.state.mappings.map(m => ({
                                        id: m.id, 
                                        name: m.name, 
                                        info: this.props.t('uploaded_on') + ' ' + 
                                            formatDate(new Date(Date.parse(String(m.pub_date)))) + '.',
                                        access: acc_role === 'Full' || Number(m.uploaded_by) === Number(this.userId),
                                        content: m.content
                                    }))}
                                    emptyMessage={
                                        acc_role === 'Full' || acc_role === 'Standard'? 
                                        this.props.t('no_mappings') : 
                                        this.props.t('no_mappings_upload_access')
                                    } 
                                    saveName={this.saveMappingName.bind(this)}
                                    remove={this.removeMapping.bind(this)}
                                    showContent={this.showContentModal.bind(this)} 
                                    access={acc_role !== 'Upload'}
                                />
                            </Panel>
                        </div>

                        <div style={{marginTop: 50}}>
                            <Panel bordered header={
                                <h4 className='list-head'>
                                    {this.props.t('analyses_in_workspace')}
                                </h4>}
                            >
                                <ResourceList 
                                    data={this.state.analyses.map(a => ({
                                        id: a.id, 
                                        name: a.name,
                                        info: this.props.t('uploaded_on') + ' ' + 
                                            formatDate(new Date(Date.parse(String(a.pub_date)))) + '.',
                                        access: acc_role === 'Full',
                                        content: ''
                                    }))}
                                    emptyMessage={
                                        acc_role === 'Full' || acc_role === 'Standard'? 
                                        this.props.t('no_analyses') : 
                                        this.props.t('no_analyses_upload_access')
                                    } 
                                    saveName={this.saveAnalysisName.bind(this)}
                                    remove={this.removeAnalysis.bind(this)}
                                    showContent={this.showContentModal.bind(this)}
                                    access={acc_role !== 'Upload'}
                                />
                            </Panel>
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <MyModal 
                    title={this.props.t('content')} 
                    show={this.state.showContentModal} 
                    onClose={() => this.setState({showContentModal: false})} 
                    onAccept={() => this.setState({showContentModal: false})}
                >
                    {this.state.content}
                </MyModal>
            </MyModal>
            : ''
        )
    }
}

export default withTranslation()(WorkspaceResourceModal)

