import { Component } from "react"
import { Alert, Button, Checkbox, Icon, IconButton, Input, Tooltip, Whisper } from "rsuite"
import { CommentInfo } from "../../../../common/ClientServerInterface"
import { Printer } from "../../engine/printer"
import { Violation } from "../../models/engine-types"
import { Repo } from "../../repositories/Repo"
import { ERROR_TIME, formatDate, WHISPER_DELAY } from "../../utils/utils"
import { withTranslation } from 'react-i18next';
import Comment from "./Comment"
import MyModal from "../helperComponents/MyModal"
import { ShowMoreText } from "../helperComponents/ShowMoreText"

/* Renders a single violation and the comments on it */

interface ViolationState{
    visibility: [boolean, number]
    comment_text: string
    comments: CommentInfo[]
    checked: boolean
    showFPCommentModal: boolean
    newFPCommentText: string
    fpComment: string
}

interface IViolationProps {
    v: Violation, 
    title: string,
    visible: [boolean, number]
    comments: CommentInfo[]
    workspaceId: number
    analysisId: number
    userId: number
    checked: boolean
    ruleName: string
    fpComment: string
    t: (k:string) => string
    tReady: boolean
}

function color (flagged: boolean) : React.CSSProperties {
    let color = flagged ? "red" : "grey"
    return { color }
}

class ViolationElement extends Component<IViolationProps, ViolationState>{

    constructor(props:IViolationProps){
        super(props)
        this.state={
            visibility: [false, Date.now()],
            comment_text: '',
            comments: props.comments,
            checked: props.checked,
            showFPCommentModal: false,
            newFPCommentText: '',
            fpComment: props.fpComment
        }
    }

    visibility(){
        return this.props.visible[1] >= this.state.visibility[1] ? this.props.visible[0]: this.state.visibility[0]
    }

    setFalsePositive(remove: boolean){
        Repo.setFalsePositive(this.props.workspaceId, 
            {
                remove: remove, 
                rule: this.props.ruleName, 
                line: this.props.v[0].e.index, 
                analysisid: this.props.analysisId, 
                logid: this.props.v[0].e.fileid, 
                comment: this.state.newFPCommentText, 
                data: 'data' in this.props.v[0].e ? this.props.v[0].e.data : ''
            }
        )
        .then(res => {
            if(res.success){
                this.setState({
                    checked: !this.state.checked, 
                    showFPCommentModal: false, 
                    newFPCommentText: '', 
                    fpComment: this.state.newFPCommentText
                })
            } else{
                Alert.error(this.props.t('add_fp_error') + res.error_msg, ERROR_TIME)
            }
        })
    }

    addComment(log_id:number, line:number){
        Repo.postComment(this.props.workspaceId, {
            text: this.state.comment_text, 
            line: line, 
            analysisid: this.props.analysisId, 
            logid: log_id,
            rule: this.props.ruleName,
            data: 'data' in this.props.v[0].e ? this.props.v[0].e.data : ''
        })
        .then(res => {
            if(res.success){
                this.state.comments.push(res.payload!)
                this.setState({comments: this.state.comments, comment_text: ''})
            } else{
                Alert.error(this.props.t('add_comment_error') + res.error_msg, ERROR_TIME)
            }
        })
    }

    removeComment(comment_id:number){
        Repo.removeComment(this.props.workspaceId, comment_id)
        .then(res => {
            if(res.success){
                const coms = this.state.comments.filter(c => c.id !== comment_id)
                this.setState({comments: coms})
            } else {
                Alert.error(this.props.t('remove_comment_error'), ERROR_TIME)
            }
        })
    }

    editComment(comment_id:number, text:string){
        Repo.editComment(this.props.workspaceId, comment_id, {text: text})
        .then(res => {
            if(res.success){
                const com = this.state.comments.find(c => c.id === comment_id)
                if(com){
                    let coms = this.state.comments.filter(c => c.id !== comment_id)
                    const newCom:CommentInfo = {
                        text: text, 
                        id: res.payload!.id, 
                        line: com.line, 
                        logId: com.logId,
                        rule: com.rule,
                        data: com.data,
                        uploadedAt: com.uploadedAt,
                        uploadedBy: com.uploadedBy,
                        uploadedByUsername: com.uploadedByUsername,
                        editedAt: new Date(Date.now()),
                        removed: false,
                        history: [...com.history, com]
                    }
                    coms.push(newCom)
                    coms.sort((a,b) => new Date(a.uploadedAt).getTime() - new Date(b.uploadedAt).getTime())
                    this.setState({comments: coms})
                }
            } else {
                Alert.error(this.props.t('edit_comment_error') + res.error_msg, ERROR_TIME)
            }
        })
    }

    render(){ 

        const comments = this.state.comments.filter(c =>
            c.logId === this.props.v[0].e.fileid && 
            c.line === this.props.v[0].e.index &&
            c.rule === this.props.ruleName && 
            (c.data === '' ||
            ('data' in this.props.v[0].e &&
            c.data === this.props.v[0].e.data))
        ).sort((a,b)=>new Date(a.uploadedAt).getTime()-new Date(b.uploadedAt).getTime())

        let [r, ...hist] = this.props.v
        
        let cursor = (hist.length > 0) ? "pointer" : "default"
    
        let dropdown = 
            <IconButton 
                appearance="subtle" 
                icon={<Icon icon={this.state.visibility[0] ? 'minus-square-o' : 'plus-square-o'}/>} 
                onClick={() => this.setState({visibility: [!this.visibility(), Date.now()]})}
                size="xs"
            ></IconButton>
    
        return (
            <div style={{ marginLeft: "10px", overflow: "auto"}}> 
                <div style={{ display: "flex", alignItems: "center"}}>
                    {this.props.analysisId ? 
                        <Whisper 
                            placement={'top'} 
                            delay={WHISPER_DELAY} 
                            trigger={'hover'} 
                            speaker={this.state.checked ? 
                                <Tooltip>Marked as false positive: <i>{this.state.fpComment}</i></Tooltip> : 
                                <Tooltip>Mark as false positive</Tooltip>}
                        >
                            <div><Checkbox checked={this.state.checked} onClick={() => 
                                this.state.checked ? 
                                this.setFalsePositive(true) : 
                                this.setState({showFPCommentModal: true})}></Checkbox></div>
                        </Whisper>
                        : ''
                    }

                    {dropdown}

                    <div 
                        style={{ wordWrap: "break-word", cursor, overflow: "visible", flex:"1"}} 
                        onClick = {() => this.setState({visibility: [!this.visibility(), Date.now()]})}
                    >
                        { this.props.title + "   " + r.desc }
                    </div> 
                </div>
                <div style={{marginLeft: 40}}>
                    { this.visibility() && hist.length > 0 && // history
                        <>
                            <hr></hr>
                            <b>{this.props.t('log_entries')}</b>
                            {hist.map( h => 
                                <div style={{ marginTop:"14px", marginLeft: "40px" }}>
                                    <div style={{ display: "inline-block", marginRight: "20px", fontSize: "12px" }}>
                                        <div style={{...color(h.flag), width: '100%'}}>{ formatDate(h.e.timestamp) } {Printer.ppFile(h.e)} </div>
                                        <div style={{color: "white"}}>{ "." }</div>
                                        <div style={{...color(h.flag), width: '100%', marginTop: -10}}>{ h.desc } </div>
                                        <div style={{color: "white"}}>{ "." }</div>
                                        <div style={{...color(h.flag), marginTop: -10, width: '100%'}}><ShowMoreText length={200} value={h.e.record} /></div>
                                        <div style={{color: "white"}}>{ "." }</div>
                                    </div>
                                </div>

                            )}
                        </>
                    }
                    { this.visibility() && this.props.analysisId !== 0 &&
                        <>
                            <hr></hr>
                            <p><b>{this.props.t('comments')}</b></p>
                            <div style={{marginLeft: 40, marginTop: 10, width: '50%'}}>
                                {comments ? comments.map(c => 
                                    <Comment 
                                        editedAt={c.editedAt} 
                                        history={c.history} 
                                        access={Number(this.props.userId) === Number(c.uploadedBy)} 
                                        id={c.id} 
                                        text={c.text} 
                                        uploadedAt={c.uploadedAt} 
                                        uploadedByUsername={c.uploadedByUsername} 
                                        workspaceId={this.props.workspaceId} 
                                        editComment={this.editComment.bind(this)} 
                                        removeComment={this.removeComment.bind(this)}
                                    />) : 
                                        ''
                                }
                                <div style={{ display: "flex", marginTop: 20, marginBottom: 20}}>
                                    <textarea 
                                        rows={1}
                                        style={{width: '70%'}} 
                                        onChange={(s) => this.setState({comment_text: s.target.value})} 
                                        value={this.state.comment_text}
                                    />
                                    <Button 
                                        style={{marginLeft: 10, height: 40, marginTop: 'auto'}} 
                                        appearance='ghost' 
                                        onClick={() => 
                                            this.addComment(this.props.v[0].e.fileid, this.props.v[0].e.index)
                                        }
                                    >
                                        {this.props.t('comment')}
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                </div>

                <MyModal 
                    title={'Mark as false positive'} 
                    show={this.state.showFPCommentModal} 
                    onClose={() => this.setState({showFPCommentModal: false, newFPCommentText: ''})}
                    onAccept={() => this.setFalsePositive(false)}
                >
                    <div>Comment:</div>
                    <Input value={this.state.newFPCommentText} onChange={s => this.setState({newFPCommentText: s})}/>
                </MyModal>
            </div>
        )
    }
}

export default withTranslation()(ViolationElement)