export type ShapeInput = {             // The class used to handle the user input to create a shape
    separatorInfo: SeparatorInfo       //  - the separator between columns (e.g. using ',' or a regex)
    timestampRegex: string             //  - regex to extract the day, month, year, hours, minutes, seconds and millisecond from timestamp
    attributes: AttributeCol[]          //  - column numbers of gdpr attributes (event, dsid, timestamp, dataid)
    matchedRecords?: string[]          //  - the records matched by the shape
    preserveCase: boolean              //  - whether to preserve the case of the actions returned by the shape
    id: number
    changes: boolean
    name: string
}

export type SeparatorInfo = {
    type: SeperatorType,
    value: string
}

export type SeperatorType = "delimiter" | "regex"
export const delimiters : SeperatorType[] = ["delimiter","regex"] // For the dropdown

export type GDPRAttr = "dsid" | "dataid" | "timestamp" | "action" | "split" | "constant"// We need the column numbers of these attributes

export type AttributeCol = {
    attribute: GDPRAttr
    column: number | undefined
    regex?: string
    children?: AttributeCol[]
    actionid?: string
    matchMultiple?: boolean
}

export type Shape = {            
    separatorInfo: SeparatorInfo
    timeformat: string
    attributes: AttributeCol[]
    preserveCase: boolean
}

export const newShape = (id?:number) : ShapeInput => ({ 
    separatorInfo: { type: "delimiter", value: "" },
    attributes: [
        // {attribute: 'dataid', column: undefined}, 
        // {attribute: 'dsid', column: undefined},
        // {attribute: 'timestamp', column: undefined},
        // {attribute: 'action', column: undefined},
    ],
    timestampRegex: "",
    preserveCase: true,
    id: 0,
    changes: true,
    name: id ? `New shape (${id})` : 'New shape'
})

export const getShapeActionIds = (shape:ShapeInput) : Set<string> => {
    const flatten = (attributes:AttributeCol[]) => {
        return attributes.reduce((acc:AttributeCol[], a, i) => {
          if(a.children && a.children.length) {
            acc = [...acc, a].concat(flatten(a.children))
          } else {
            acc.push(a)
          }
    
          return acc;
        }, [])
    }

    const actionIds = new Set(
        flatten(shape.attributes)
        .filter(a => a.attribute === 'action')
        .map(a => a.actionid || '')
    )

    return actionIds
}

export const toJSON = (shape: ShapeInput) : string => {
    return JSON.stringify({
        attributes: shape.attributes,
        separatorInfo: shape.separatorInfo,
        timestampRegex: shape.timestampRegex,
        preserveCase: shape.preserveCase,
        name: shape.name
    })
}

export const fromJSON = (s: string | null) : ShapeInput => {
    if (s) {
        try {            
            const json = JSON.parse(s)
            return json
        } catch (error) {
            throw error
        }
    }
    return newShape()
}