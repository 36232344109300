import { AnalysisParameters, GDPRRule } from '../../engine/rules';
import { Button } from 'rsuite';
import MyModal from '../helperComponents/MyModal';
import * as jsPDF from 'jspdf';
import { Component } from 'react';
import { CommentInfo, FalsePositiveInfo } from '../../../../common/ClientServerInterface';
import { Rule } from '../violationsComponents/Rule';

/* Display of all the violations as result of an analysis */

interface ViolationsContainerProps{
    rules: GDPRRule[], 
    allVisible: [boolean, number]
    comments: CommentInfo[]
    workspaceId: number
    analysisId: number
    userId: number
    analysisParameters: AnalysisParameters
    falsePositives: FalsePositiveInfo[]
}

class ViolationsContainer extends Component<ViolationsContainerProps>{

    render(){
        return(
            <>
                {this.props.rules.map((r,i) => 
                    <Rule 
                        rule={r} 
                        key={i} 
                        allVisible={this.props.allVisible} 
                        comments={this.props.comments} 
                        workspaceId={this.props.workspaceId} 
                        analysisId={this.props.analysisId} 
                        userId={this.props.userId}
                        analysisParameters={this.props.analysisParameters}
                        falsePositives={this.props.falsePositives}
                    />
                )}
            </>
        )
    }
}

interface ViolationDisplayState{
    allVisible: boolean
}

interface ViolationsDisplayProps {
    title: string, 
    show: boolean,
    onClose: () => void,
    onShow?: () => void,
    color: string,
    rules: GDPRRule[]
    workspaceId?: number
    analysisId?: number
    userId?: number
    comments: CommentInfo[]
    analysisParameters: AnalysisParameters
    falsePositives: FalsePositiveInfo[]
}

class ViolationsDisplay extends Component<ViolationsDisplayProps, ViolationDisplayState> {

    constructor(props: ViolationsDisplayProps){
        super(props)
        this.state = {allVisible: false}
    }

    render(){ 
        
        const date = Date.now()
        
        const downloadViolationsPDF = () => {
            let doc = new jsPDF()
            let source = document.getElementById("violations")
            doc.fromHTML(source, 5, 5, { width: 200 })
            doc.save("violations.pdf")
        }
    
    
        let saveButton = <Button default onClick={() => downloadViolationsPDF()}> Save to PDF</Button>
    
        let dropdownBtn = <Button onClick={() => {this.setState({allVisible: true})}}>Expand all</Button>
        let closeBtn = <Button onClick={() => {this.setState({allVisible: false})}}>Close all</Button>
    
        return (
            <MyModal 
                buttons={[dropdownBtn, closeBtn, saveButton]} 
                title={this.props.title} 
                titleSize={'36px'} 
                show={this.props.show} 
                onClose={this.props.onClose} 
                color={this.props.color} 
                full={true}
                onShow={this.props.onShow}
            >
                {<div id="violations">       
                    <ViolationsContainer 
                        rules={this.props.rules} 
                        allVisible={[this.state.allVisible, date]} 
                        comments={this.props.comments} 
                        workspaceId={this.props.workspaceId ? this.props.workspaceId : 0} 
                        analysisId={this.props.analysisId ? this.props.analysisId : 0} 
                        userId={this.props.userId ? this.props.userId : 0}
                        analysisParameters={this.props.analysisParameters}
                        falsePositives={this.props.falsePositives}
                    />
                </div>}
            </MyModal>
        )
    }

}

export default ViolationsDisplay;
