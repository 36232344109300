import { ShapeInput } from "./shape"
import { ActionsToGdpr, LogRecord} from "./engine-types"
import { MappingCommentInfo } from "../../../common/ClientServerInterface"


/* IFileData contains all relevant information for uploaded log files */
export type IFileData = {
    id: number
    file: MyFile,
    content: string,
    shapes: ShapeInput[],
    selected?: boolean,
    actionMapping: ActionsToGdpr,    // mapping from log-event to gdpr-signature(s)
    actionMappingid: number,
    mappingComments?: MappingCommentInfo[]
    mappingChanges?: boolean,
    actions: Map<string, LogRecord[]>,
    records: LogRecord[],
    filterShape?: number
}

export interface MyFile {
    name: string,
    text(): Promise<string> 
}

export class EmptyFile implements MyFile {
    constructor(public readonly name: string) {}
    text = () => { throw new Error("Not implemented")}
}

export function newFileData (file:File) : IFileData  {
    let f = {
        file,
        content: "",
        selected: false,
        shapes: [],
        actionMapping: {},
        id: 0,
        actionMappingid: 0,
        actions: new Map(),
        records: []
    }
    return f
}