import React from 'react'
import { Button, ButtonProps } from 'rsuite';

let buttonStyle = { borderStyle: "solid", borderWidth: "1px"}

export const MyButton = (props: ButtonProps) : React.ReactElement => {
    return (
        <Button
            {...props}
            style={{...props.style, ...buttonStyle}}
            appearance="default"
        >
            {props.children}
        </Button>
    )
}
