import { FalsePositiveInfo } from '../../../common/ClientServerInterface';
import { ActionsToGdpr, Violation } from '../models/engine-types';
import { AttributeCol, SeparatorInfo } from '../models/shape';
import { TimeFormat } from '../models/timeformat';

export const WHISPER_DELAY = 500
export const ERROR_TIME = 5000

// *********** Helper functions *************

const handleAttribute = (a:AttributeCol, r:string, timeformat:string) => {
    try {
        if(a.attribute === 'timestamp') {
            let date:Date;
            if(timeformat.length === 0){
                date = new Date(r)
                if(isNaN(date.getTime())) throw new Error(`Timeformat does not match`)
            }
            else {
                const timeformatter = new TimeFormat(timeformat)
                date = timeformatter.match(r) // test whether timestamp matches timeformat
            }
        }
        else if(a.regex){
            const matches = r.match(a.regex)
    
            if(!matches){
                throw new Error(`Timeformat does not match`)
            }
        }

        return true
    } catch (error) {
        return false
    }
}

export function divideRecord(record: string, {value, type}: SeparatorInfo, matchMultiple:boolean, attributes:AttributeCol[], timeformat:string){
    const grps = tryDivideWithUnmatched(record, {value, type}, matchMultiple)

    let shapeMatched = []
    let i = 0
    let column = 0
    while(i < grps.length){
        let g = grps[i]
        const c = column
        if(g.matched){
            let colAttr = attributes.filter(a => a.column === c)

            let atrributesMatch = colAttr.reduce((acc, atr) => acc && handleAttribute(atr, g.value, timeformat), true)
            
            shapeMatched.push({...g, shapeMatch: atrributesMatch})

            column ++
        } else{
            shapeMatched.push({...g, shapeMatch: true})
        }

        i++
    }

    return shapeMatched
}

export function tryDivideWithUnmatched(record: string, {value, type}: SeparatorInfo, matchMultiple?:boolean) : {value: string, matched: boolean}[] {
    if (type === "regex") {
        try {
            let regx = new RegExp(value, 'mg')
            let res = regx.exec(record)
            if (res) {
                if(matchMultiple) {
                    let index = res.index
                    const firstGrp = record.substring(0, index)
                    let grps:{value:string, matched:boolean}[] = firstGrp.length > 0 ? [{value: firstGrp, matched: false}] : []
                    const {entry} = tryDivide(record, {value, type}, matchMultiple)
                    entry?.forEach(g => {
                        const newIndex = record.indexOf(g, index)
                        if(index !== newIndex) grps.push({value: record.substring(index, newIndex), matched: false})
                        grps.push({value: g, matched: true})
                        index = newIndex+g.length
                    })
                    if(index < record.length-1) grps.push({value: record.substring(index), matched: false})
                    return grps
                }
                let index = res.index
                const firstGrp = record.substring(0, index)
                let grps:{value:string, matched:boolean}[] = firstGrp.length > 0 ? [{value: firstGrp, matched: false}] : []
                const {entry} = tryDivide(record, {value, type})
                entry?.forEach(g => {
                    const newIndex = record.indexOf(g, index)
                    if(index !== newIndex) grps.push({value: record.substring(index, newIndex), matched: false})
                    grps.push({value: g, matched: true})
                    index = newIndex+g.length
                })
                if(index < record.length-1) grps.push({value: record.substring(index), matched: false})
                return grps
            }
            else throw Error('Regex does not match')
        } catch (e) {
            return [{value: record, matched: false}]
        }
    }
    return record.split(value).map(m => ({value: m, matched: true})) 
}

export function tryDivide(record: string, {value, type}: SeparatorInfo, matchMultiple?:boolean) : {entry: string[] | null, err: string} {
    if (type === "regex") {
        try {
            let regx = new RegExp(value, 'mg');
            let res = regx.exec(record)
            if (res){
                if (matchMultiple) {return {entry: record.match(regx), err: ""}}
                else return { entry: res.slice(1), err: "" }
            } 
            else return { entry: null, err: "The regex does not match the given record" }
        } catch (e) {
            return { entry: null, err: "Invalid regex" }
        }
    }
    return { entry: record.split(value), err: "" } 
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    if (value === null || value === undefined) return false;
    return true;
}

export function addToItems<K, T>(key: K, item: T, map: Map<K, T[]>) : void {
    let lst = map.get(key)
    if (lst) lst.push(item)
    else map.set(key, [item])
}

export function daysToMilliseconds(days: number) : number {
    return 86400000 * days
}

export const downloadString = (content: string, fileName: string) : void => {
    const element = document.createElement("a");
    const file = new Blob([content], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

export const downloadCSV = (content: string, fileName: string) : void => {
    const encodedUri = encodeURI(content);
    const element = document.createElement("a");
    element.setAttribute("href", encodedUri);
    element.setAttribute("download", fileName);
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click()
}

export const removeMultilines = (text:string) => {
    return text.replace(/"[^"]*(?:""[^"]*)*"/g, m => m.replace(/\n/g, ' ').replace(/,/g, ''))
}

export const formatDateInfo = (date:Date) : string => {
    const hours = date.getHours() >= 10 ? date.getHours()+'' : '0'+date.getHours()
    const minutes = date.getMinutes() >= 10 ? date.getMinutes()+'' : '0'+date.getMinutes()
    const seconds = date.getSeconds() >= 10 ? date.getSeconds()+'' : '0'+date.getSeconds()
    const milliseconds = date.getMilliseconds() >= 10 ? date.getMilliseconds()+'' : '0'+date.getMilliseconds()
    return `Year: ${date.getFullYear()}, month: ${date.getMonth()+1}, day: ${date.getDate()}, hours: ${hours}, minutes: ${minutes}, seconds: ${seconds}, milliseconds: ${milliseconds}`
}

export const formatDate = (date:Date, onlyDate?:boolean) : string => {
    const hours = date.getHours() >= 10 ? date.getHours()+'' : '0'+date.getHours()
    const minutes = date.getMinutes() >= 10 ? date.getMinutes()+'' : '0'+date.getMinutes()
    const seconds = date.getSeconds() >= 10 ? date.getSeconds()+'' : '0'+date.getSeconds()
    return onlyDate ? 
        `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}` : 
        `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${hours}:${minutes}:${seconds}`
}

export const getGroups = (regex:string) => {
    let groups = []
    let i = 0
    while (i < regex.length){
        const c = regex[i]
        if(c === '\\') {i++}
        else if(c === '[') {
            i++
            while (i < regex.length && regex[i] !== ']'){
                i++
            }
        }
        else if(c === '('){
            i++
            if(regex[i] === '?' && regex[i+1] === '<'){
                let name = ''
                i += 2
                while (i < regex.length && regex[i] !== '>'){
                    name += regex[i]
                    i++
                }
                groups.push(name)
            } else{
                groups.push('unnamed')
            }
            while (i < regex.length && regex[i] !== ')'){
                i++
            }
        }
        i++
    }

    return groups
}

export function array_move<T>(arr:Array<T>, old_index:number, new_index:number) {
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

export function mappingFromJSON(s: string | null) : ActionsToGdpr{
    if (s) {
        try {            
            const json = JSON.parse(s)
            return json
        } catch (error) {
            throw error
        }
    }
    return {}
}

export function intersection<T>(setA:Set<T>, setB:Set<T>):Set<T> {
    return new Set(Array.from(setA).filter(value => setB.has(value)))
}

export function falsePositiveViolationEquality(fp:FalsePositiveInfo, v:Violation, ruleName:string){
    return fp.line === v[0].e.index && fp.rule === ruleName && fp.logid === v[0].e.fileid && ('data' in v[0].e ? fp.data === v[0].e.data : true)
}

export function arrayEquals(a:number[], b:number[]) {
    return a.length === b.length &&
      a.every((val, index) => val === b[index]);
}
  
export const thirtyDaysInMs = 2592000000
export const dayInMs = 86400000

export const ShapeColors = ['#2196f3', '#ff9800', '#673ab7', '#f44336', '#ffca28', '#4caf50', '#00bcd4']