interface Matcher {
    match : (timestamp: string) => Date;
    getMatchResult : (timestamp: string) => string; // return the matched year, month, day hours, minutes, seconds and milliseconds as string
}

/**
 * The TimeFormat takes in a regex or letter-format, checks whether the timestamp match the format, and returns a Date object
 * 
 * A letter format (exmaples below) use specific letters to denote year, month etc.
 * Two examples:    'yyyy/mm/dd hh:ii:ss.jjj'     'dd-mm-yyyy hh:ii:ss'
 */
export class TimeFormat implements Matcher {
    private readonly matcher: Matcher;
    private readonly regex:string;

    constructor(regex: string) {
        this.regex = regex
        if (regex.includes("?<")) this.matcher = new TimeFormatRegex(regex)
        else this.matcher = new TimeFormatRegex(convertLettersToRegex(regex))
    }
    
    match = (timestamp: string) => {
        return this.matcher.match(timestamp)
    }
    getMatchResult = (timestamp: string) : string => {
        return this.matcher.getMatchResult(timestamp)
    }

    getRegex = () => this.regex
}

function convertLettersToRegex(regex: string) {
    regex = regex.toLocaleLowerCase()
    regex = regex.replace("yyyy", '(?<year>[0-9]{4})');
    regex = regex.replace("yy", '(?<year>[0-9]{2})');
    regex = regex.replace("mm", '(?<month>[0-9]{2})');
    regex = regex.replace("dd", '(?<day>[0-9]{2})');
    regex = regex.replace("hh", '(?<hours>[0-9]{2})');
    regex = regex.replace("ii", '(?<minutes>[0-9]{2})');
    regex = regex.replace("ss", '(?<seconds>[0-9]{2})');
    regex = regex.replace("jjj", '(?<milliseconds>[0-9]+)');
    return regex
}


class TimeFormatRegex implements Matcher {
    public readonly regx: string;
    public readonly groups = ["year", "month", "day", "hours", "minutes", "seconds", "milliseconds"]

    constructor(regex: string) {
        if (!regex.includes(`?<year>`)) throw new Error(`Timeformat: format missing 'year' in groups`)
        if (!regex.includes(`?<month>`)) throw new Error(`Timeformat: format missing 'month' in groups`)
        this.regx = regex.toLocaleLowerCase()
    }

    getDate = (timestamp: string) => {
        let match = timestamp.match(this.regx)

        if (!match) throw new Error(`Timeformat: timestamp not a match`)
        if (!match['groups']) throw new Error(`Timeformat: missing groups in match ${match}`)

        let matchGroups : Record<string, number | undefined> = {};
        Object.entries(match['groups']).forEach( ([k,v]) => {
            if (v) matchGroups[k] = parseInt(v,10)
            else matchGroups[k] = 0
        });

        let { year, month, day, hours, minutes, seconds, milliseconds } = matchGroups
        if (!year) throw new Error(`Timeformat: timestamp missing 'year'`)
        if(year < 40) year = Number('20'+year)
        if (!month) throw new Error(`Timeformat: timestamp missing 'month'`)
        if (!day) day = 0
        if (!hours) hours = 0
        if (!minutes) minutes = 0
        if (!seconds) seconds = 0
        if (!milliseconds) milliseconds = 0

        let date = new Date(year, month-1, day, hours, minutes, seconds, milliseconds)
        if (isNaN(date.getTime())) throw new Error(`Invalid date`)
        return { date, year, month, day, hours, minutes, seconds, milliseconds }
    }
    
    getMatchResult = (timestamp: string) : string => {
        let { year, month, day, hours, minutes, seconds, milliseconds } = this.getDate(timestamp)
        return `year: ${year}, month: ${month}, day: ${day}, hours: ${hours}, 
                minutes: ${minutes}, seconds: ${seconds}, milliseconds: ${milliseconds} `
    }

    match = (timestamp: string) : Date => {
        let { date } = this.getDate(timestamp)
        return date
    }
}