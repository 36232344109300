import { TransformedLogRecord } from "../models/engine-types";
import { CoreGDPRSignature } from "../models/gdpr-signatures";

/**
 * The printer takes in a Rule and displays a summary and violations in a nice format
 */

export const Printer = {
    ppSummary(article: string, errs: TransformedLogRecord[] | any[], baseOnly: boolean = false) {
        let base = `GDPR article ${article} has been violated ${errs.length} time${errs.length !== 1 ? 's' : ''}`
        if (baseOnly) return base
        return (errs.length > 0) ? base + ` as follows:` : base + `.`
    },

    ppTimestampAndTitle(r: TransformedLogRecord, description: string, printTimestamp: boolean = true) {
        if (printTimestamp) return this.ppTimestamp(r) + description
        else return description
    },

    ppFileAndLogrecord(r: TransformedLogRecord) {
        let fileAndLine = `[${r.filename}:${r.index}]    `
        return fileAndLine + r.record
    },

    ppTimestamp(r: TransformedLogRecord) {
        return `[${r.timestamp.getDate()}/${r.timestamp.getMonth()}/${r.timestamp.getFullYear()}/
            ${r.timestamp.getHours()}:${r.timestamp.getMinutes()}:
            ${r.timestamp.getSeconds() >= 10 ? r.timestamp.getSeconds() : "0" + r.timestamp.getSeconds()}]`
    },

    ppFile(r: TransformedLogRecord) {
        return `[${r.filename}:${r.index}]`
    },

    ppSignature(sig: CoreGDPRSignature) {
        return `${sig.name}(${'data' in sig ? sig.data : ''})`
    }
}