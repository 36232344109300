import { EmptyFile, IFileData } from "../models/file"
import { AttributeCol, SeparatorInfo, ShapeInput } from "../models/shape"
import { ActionsToGdpr } from "../models/engine-types"
import { dreyerContent } from "./logfile_dreyer"
import { thirtyDaysInMs } from "../utils/utils"

let dsid = "", dataid = ""
const actionToGdpr : ActionsToGdpr = {
    'NULL'                                      : {gdprActions: [] , description: ''},
    'Change phase to review'                    : {gdprActions: [] , description: ''},
    'Change phase to Board meeting'             : {gdprActions: [] , description: ''},
    'Applicant informed '                       : {gdprActions: [] , description: ''},
    'Change phase to Abort'                     : {gdprActions: [] , description: ''},
    'Change phase to Preparation'               : {gdprActions: [] , description: ''},
    'Change Phase to Payout'                    : {gdprActions: [] , description: ''},
    'Change Phase to End Report'                : {gdprActions: [] , description: ''},
    'Change Phase to Lapsed'                    : {gdprActions: [] , description: ''},
    'Round Ends'                                : {gdprActions: [] , description: ''},
    'Round approved'                            : {gdprActions: [] , description: ''},
    'Account number changed'                    : {gdprActions: [{signature: { name: "Processing", data: "ACCOUNTNUMBER", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Reject application'                        : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Architect Review'	                        : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Change phase to Complete'	                : {gdprActions: [{signature: { name: "Delete", data: "APPLICATION", dsid, dataid, graceperiod: 0 }, startOfCase: false, ifDataCollected: false},
                                                                 {signature: { name: "Delete", data: "ACCOUNTNUMBER", dsid, dataid, graceperiod: 0 }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Execute pre-decision'	                    : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Fill out Application'                      : {gdprActions: [{signature: { name: "Ds Consent", data: "APPLICATION", dsid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'First payout'                              : {gdprActions: [{signature: { name: "Processing", data: "ACCOUNTNUMBER", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Approve application'                       : {gdprActions: [{signature: { name: "Legal Grounds", data: "ACCOUNTNUMBER", dsid, info: '' }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Approve changed account number'            : {gdprActions: [{signature: { name: "Processing", data: "ACCOUNTNUMBER", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Approval - on to the board'        	    : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Initial rejection'                         : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Inform applicant that Best are examining the case'  : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Inform applicant about the grant'	        : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Lawyer Review'	                            : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Receive final report'                      : {gdprActions: [{signature: { name: "Processing", data: "ACCOUNTNUMBER", dsid, dataid }, startOfCase: false, ifDataCollected: false},
                                                                 {signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Payment completed'                         : {gdprActions: [{signature: { name: "Processing", data: "ACCOUNTNUMBER", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Pre-process application'	                : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Register Decision'	                        : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Review'	                                : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Set to Pre-approved'	                    : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Carry out lapses'                          : {gdprActions: [{signature: { name: "Ds Delete", data: "APPLICATION", dsid, dataid, timelimit: thirtyDaysInMs }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Undo payment'                              : {gdprActions: [{signature: { name: "Processing", data: "ACCOUNTNUMBER", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Applicant proves relevance of application' : {gdprActions: [{signature: { name: "Processing", data: "APPLICATION", dsid, dataid }, startOfCase: false, ifDataCollected: false}], description: ''},
    'Applicant informed'                        : {gdprActions: [{signature: { name: "Delete", data: "APPLICATION", dsid, dataid, graceperiod: 0 }, startOfCase: false, ifDataCollected: false}], description: ''}
}

export const shape = () : ShapeInput => {
    const seperatorInfo : SeparatorInfo = { 
        type: "delimiter", 
        value: ";"
    }
    
    const attributes : AttributeCol[] = [
            {column: 0, attribute: 'dsid'},
            {column: 0, attribute: 'dataid'},
            {column: 2, attribute: 'action'},
            {column: 4, attribute: 'timestamp'},
    ]

    const timestampRegex = "yyyy-mm-dd hh:ii:ss.jjj" // 2011/12/15 16:01:00.000

    return {
       separatorInfo: seperatorInfo, 
       attributes,
       timestampRegex,
       preserveCase: true,
       id: 0,
       changes: true,
       name: 'Shape 1'
    }
}

export const DreyerInputs = () : IFileData => {
    let fileData : IFileData = {
        file: new EmptyFile("demo_log_ready.csv"),
        content: dreyerContent,
        shapes: [shape()],
        actionMapping: actionToGdpr,
        id: 0,
        actionMappingid: 0,
        actions: new Map(),
        records: []
    }
    
    return fileData
}

export const DreyerInputsEmpty = () : IFileData => {
    let fileData : IFileData = {
        file: new EmptyFile("demo_log_empty.csv"),
        content: dreyerContent,
        shapes: [],
        actionMapping: {},
        id: 0,
        actionMappingid: 0,
        actions: new Map(),
        records: []
    }
    
    return fileData
}