import React from 'react';
import { Icon, Loader, Nav, Panel, Tooltip, Whisper } from 'rsuite'
import { newFileData, IFileData } from '../../models/file';
import { withTranslation } from 'react-i18next';
import { TutorialTooltip } from '../helperComponents/TutorialTooltip';
import { WHISPER_DELAY } from '../../utils/utils';

/* Shows the files available for the analysis and allows to import a new file */

const MyFileUploader = (
    {text, style, handleChange}: { text:string, style: React.CSSProperties, 
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void }
    ) => (
        <div style={style} >
            <label htmlFor="file-upload" style={{ display: "inline-block", cursor: "pointer" }}>
                <i className="fa fa-cloud-upload"></i> <span className="rs-btn rs-btn-default">{text}</span>
            </label>
            <input id="file-upload" type="file" multiple onChange={ handleChange } style={{display: "none"}}/>
        </div>
)

interface IFilechooserProps {
    onSelect: (files: IFileData[]) => void,
    onFileDelete: (fileIndex: number) => void,
    onAddRemove: (fileIndex: number) => void,
    onFileClick: (fileIndex: number) => void,
    files: IFileData[]
    loading: boolean
    demoStep?: number
    t: (k:string) => string
    tReady: boolean
}

interface IFilechooserState {
    loading: number
}

class Filechooser extends React.Component<IFilechooserProps, IFilechooserState> {

    constructor(props:IFilechooserProps){
        super(props)
        this.state = {loading: -1}
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) : void => {
        if (!e.target.files) return;
        let chosenFiles = Array.from(e.target.files);
        let fileData = chosenFiles.map( f => newFileData(f))
        this.props.onSelect(fileData)
    } 

    async onFileClick(id:number){
        this.setState({
            loading: id
          }, () => {
            setTimeout(() => {
                this.props.onFileClick(id)
                this.setState({loading: -1});
            }, 0);
          })
    }
    
    render() {
        const childStyle = { display: "block", margin: "8px" }

        const add_tooltip = (
            <Tooltip>
              {this.props.t('add_log_analysis')}
            </Tooltip>
        );

        const remove_tooltip = (
            <Tooltip>
              {this.props.t('remove_log_analysis')}
            </Tooltip>
        );

        const notSelectedFiles = this.props.files.filter(f => !f.selected)
        const selectedFiles = this.props.files.filter(f => f.selected)
          
        return (
            <div className="block-children" style={{ marginBottom: "20px" }}>
                <h2 style={childStyle} className="heading">
                    {this.props.t('add_log_files')}
                    <TutorialTooltip text={this.props.t('tutorial_add_files')}/>
                </h2>
                <MyFileUploader 
                    text={this.props.t('import_files') + '...'} 
                    style={childStyle} 
                    handleChange={this.handleChange.bind(this)}
                />
                <hr></hr>

                {/*<p style={{marginLeft:10}}>{this.props.t('logs_to_not_be_submitted')}:</p>*/}

                <h2 className="heading" style={{marginBottom: -10}}>
                    {this.props.t('available_logs')}
                    <TutorialTooltip active={this.props.demoStep === 0} text={this.props.t('tutorial_available_files')}/>
                </h2>
                <Panel bordered>
                <Nav style={childStyle} vertical>
                    {this.props.loading ? <Loader center size='sm'/> : 
                        notSelectedFiles.length > 0 ?
                        this.props.files.map((fData,i) => fData.selected ? '' : (
                            <Nav.Item key={i} eventKey={i}>

                                <div style={{ display: "flex"}}>
                                    <div style={{width:'90%'}}  onClick={() => this.onFileClick(i)}>
                                        <span style={{
                                            display: 'inline-block',
                                            maxWidth: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}>{this.state.loading === i ? <Loader center /> : fData.file.name}</span>
                                    </div>
                                    <Whisper 
                                        placement="top" 
                                        trigger="hover" 
                                        speaker={add_tooltip}
                                    >
                                        <Icon 
                                            icon='plus'
                                            onClick={ () => this.props.onAddRemove(i) } 
                                            style={{marginLeft:'auto', marginTop:4}}
                                        />
                                    </Whisper>
                                    <span className="rs-ripple-pond">
                                        <span className="rs-ripple"></span>
                                    </span>
                                </div>
                            </Nav.Item>
                        ))
                        :<p style={{fontSize: 12}}>{this.props.t('no_logs_available')}</p>
                        
                    }
                </Nav>
                </Panel>

                <h2 className="heading" style={{marginBottom: -10}}>
                    {this.props.t('analysis')}
                </h2>

                <Panel bordered>
                    <Nav style={{...childStyle, padding: 0}} vertical>
                        {this.props.loading ? <Loader center size='sm'/> : 
                            selectedFiles.length > 0 ?
                            this.props.files.map((fData,i) => !fData.selected ? '' : (
                                <Nav.Item key={i} eventKey={i}>
                                    <span className="rs-ripple-pond">
                                        <span className="rs-ripple"></span>
                                    </span>

                                    <div style={{ display: "flex"}}>
                                        <div style={{width:'90%'}}  onClick={async () => this.onFileClick(i)}>
                                            <span style={{
                                                display: 'inline-block',
                                                maxWidth: '90%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                marginLeft: 5
                                            }}>{this.state.loading === i ? <Loader center /> : fData.file.name}</span>
                                        </div>

                                        <Whisper 
                                            placement="top" 
                                            trigger="hover" 
                                            speaker={remove_tooltip}
                                            delay={WHISPER_DELAY}
                                        >
                                            <Icon 
                                                icon='minus'
                                                onClick={ () => this.props.onAddRemove(i) } 
                                                style={{marginLeft:'auto', marginTop:4}}
                                            />
                                        </Whisper>
                                        
                                    </div>
                                </Nav.Item>
                            ))
                            :<p style={{fontSize: 12}}>{this.props.t('no_logs_analysis')}</p>
                        }
                    </Nav>
                </Panel>
            </div>
        );
    }
}

export default withTranslation()(Filechooser)