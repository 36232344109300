import React from "react"
import { Icon } from "rsuite"

/* Text that can be edited if clicked */

interface EditableTextProps{
    value: string
    editClassName: string
    onSave: (value:string) => void
    size: number
}

interface EditableTextState{
    value: string
    editClassName: string
    edit: boolean
    backup: string
}

export class EditText extends React.Component<EditableTextProps, EditableTextState>{

    constructor(props:EditableTextProps){
        super(props)
        this.state = {
            value: props.value||'',
            editClassName: props.editClassName,
            edit: false,
            backup: '',
        }
    }

    edit() {
        this.setState({edit: this.state.edit!==false})
    }

    render() {
        return this.state.edit ? 
            <input 
                size={this.props.size}
                value={this.state.value}
                className={this.state.editClassName}
                autoFocus
                onFocus={event=>{
                    const value = event.target.value
                    event.target.value = ''
                    event.target.value = value
                    this.setState({backup:this.state.value})
                }}
                onChange={event=>{
                    this.setState({value:event.target.value})
                }}
                onBlur={event=>{
                    if(this.state.value !== this.state.backup) this.props.onSave(this.state.value)
                    this.setState({edit:false})
                }}
            /> 
            :
            <span onClick={event=>{this.setState({edit:this.state.edit!==true})}}>
                {this.state.value}
                <Icon icon='pencil' style={{marginLeft: 10}}/>
            </span>
    }
}