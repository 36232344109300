import React from 'react'
import { Icon, Tooltip, Whisper } from 'rsuite';
import { WHISPER_DELAY } from '../../utils/utils';

interface TutorialTooltipProps {
    text: string, 
    active?: boolean
    style?: React.CSSProperties
}

export const TutorialTooltip = (props: TutorialTooltipProps) : React.ReactElement => {
    return (
        <Whisper placement='auto' delay={WHISPER_DELAY} speaker={<Tooltip>{props.text}</Tooltip>}>
            {props.active ?
                <Icon {...props} size='lg' icon={'question-circle'} style={{...props.style, marginLeft: 10, color: '#ffca28'}} />
                :
                <Icon {...props} icon={'question-circle'} style={{...props.style, marginLeft: 10}} />
            }
        </Whisper>
    )
}
