import { LogRecord, ActionsToGdpr, TransformedLogRecord, FileData } from '../models/engine-types';
import { CoreGDPRSignature, newAction } from '../models/gdpr-signatures';

/**
 * This file contains the functions used to convert the records of a list of logs to GDPR actions using the action mappings
 */


/* Converts a list of log files to GDPR actions (TransformedLogRecord) */
export function convertToGdprLog(fileInputs:FileData[]) : TransformedLogRecord[] {
    return fileInputs
        .flatMap(f => transformRecords(f.records, f.actionToGdpr))
        .sort((first, second) => first.timestamp.getTime() - second.timestamp.getTime())
}

/* Converts a single record to a GDPR action based on the GDPR signature */
function convert(r:LogRecord, s:CoreGDPRSignature, ts:Date):TransformedLogRecord[]{
    if(s.name === 'Collect And Inform'){ // Insert both a collect and inform
        return [{...r, ...newAction({name: 'Collect', dsid: s.dsid, dataid: s.dataid, data: s.data, info: s.info}, r.dsid, r.dataid, 0, 0, s.info), timestamp: ts}, 
                {...r, ...newAction({name: 'Inform', dsid: s.dsid, data: s.data}, r.dsid, r.dataid), timestamp: ts}]
    } else {
        return [{...r, ...newAction(s, r.dsid, r.dataid, 'graceperiod' in s ? s.graceperiod : 0, 'timelimit' in s ? s.timelimit : 0, 'info' in s ? s.info : ''), timestamp: ts}]
    }
}

/* Transforms all records to a sorted list of GDPR actions by the respective action mappings */
export function transformRecords(records: LogRecord[], actionToGdpr: ActionsToGdpr) : TransformedLogRecord[]  {
    const collectedData:Set<string> = new Set() // List of all collected data
    const startOfCaseDates:Map<string, Date> = new Map() // The date of the first record for each case

    const recordsWithActions = records.filter(r => r.actions.length > 0)

    // All actions that are not either "start of case" or "if data collected"
    const standardActions:TransformedLogRecord[] = recordsWithActions.flatMap(r => {
        if(!startOfCaseDates.has(r.dsid) || startOfCaseDates.get(r.dsid)!.getTime() > r.timestamp.getTime()){startOfCaseDates.set(r.dsid, r.timestamp)}
        let gdprSignatures = r.actions.flatMap(ra => actionToGdpr[ra].gdprActions.filter(s => !s.startOfCase && !s.ifDataCollected))
        return gdprSignatures.flatMap(s => {
            if(s.signature.name === 'Collect' || s.signature.name === 'Collect And Inform') collectedData.add(r.dsid + ' - ' + s.signature.data)
            return convert(r, s.signature, r.timestamp)
        })
    })
        
    // All actions marked as start of case
    const startOfCaseActions:TransformedLogRecord[] = recordsWithActions.flatMap(r => {
        let gdprSignatures = r.actions.flatMap(ra => actionToGdpr[ra].gdprActions.filter(s => s.startOfCase))
        return gdprSignatures.flatMap(s => {
            if(s.signature.name === 'Collect' || s.signature.name === 'Collect And Inform') collectedData.add(r.dsid + ' - ' + s.signature.data)
            return convert(r, s.signature, startOfCaseDates.get(r.dsid) ? new Date(startOfCaseDates.get(r.dsid)!.valueOf() - 1) : r.timestamp)
        })
    })

    // All actions marked with if data collected
    const ifDataCollectedActions:TransformedLogRecord[] = recordsWithActions.flatMap(r => {
        let gdprSignatures = r.actions.flatMap(ra => actionToGdpr[ra].gdprActions.filter(s => s.ifDataCollected))
        return gdprSignatures.filter(s => 'data' in s.signature && collectedData.has(r.dsid + ' - ' + s.signature.data)).flatMap(s => 
            convert(r, s.signature, r.timestamp)
        )
    })
    
    // Sorts the actions by date
    const sortedActions = standardActions.concat(ifDataCollectedActions)
        .sort((first, second) => first.timestamp.getTime() - second.timestamp.getTime())

    // Start of case inserted in front - could also be sorted by date but need to make sure they are in front of other records for same case
    return startOfCaseActions.concat(sortedActions)
}