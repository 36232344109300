import AnalysisPage, { AnalysisPageState } from './containers/AnalysisPage';
import { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoginPage from './containers/LoginPage';
import { RootPage } from './containers/Root';
import RegisterPage from './containers/RegisterPage';
import withAuth from './containers/withAuth';
import { Repo } from './repositories/Repo';
import WorkspacesPage from './containers/WorkspacesPage';
import { CredentialsReq } from '../../common/ClientServerInterface';
import { Alert, Loader } from 'rsuite';
import InvitationHandler from './containers/InvitationHandler';
import { withTranslation } from 'react-i18next';
import HomePage from './containers/HomePage';
import AnalysisOverview, { AnalysisOverviewFromLink, AnalysisOverviewProps } from './components/analysisPageComponents/AnalysisOverview';

export interface ResponseWithError extends Response {
  error:string
}


interface AppProps{
  t: (k:string) => string
  tReady: boolean
}

interface AppState {
  isLoggedIn: boolean
  user: string
  userId: number
  workspaceId: number
  analysisId: number
  redirectToAnalysis: boolean
  invitationLink: string
  loading: boolean
  overviewProps?: AnalysisOverviewProps
  analysisState?: AnalysisPageState
  showPrintableSummary: boolean
  stopWorkspaceRedirect: boolean
  tutorial?: boolean
}

class App extends Component<AppProps, AppState> {

  constructor(props:AppProps){
    super(props)
    this.state = {
      isLoggedIn: false,
      user: '',
      userId: 0,
      workspaceId: 0,
      analysisId: 0,
      redirectToAnalysis: false,
      invitationLink: '',
      loading: true,
      showPrintableSummary: false,
      stopWorkspaceRedirect: false
    }
    
    this.checkIfLoggedIn()
  }

  private async checkIfLoggedIn(){
    return await Repo.isLoggedIn()
    .then(res => {
      if(res.success) {
        if(!this.state.isLoggedIn || this.state.loading) this.setState({isLoggedIn: true, user: res.payload!.username, userId: res.payload!.id, loading: false})
        return Number(res.payload!.id)
      }
      else {
        if(this.state.isLoggedIn || this.state.loading) this.setState({isLoggedIn: false, loading: false})
        return 0
      }
    })
  }

  private async login(credentials:CredentialsReq, callback?: () => void){
    await Repo.authenticate(credentials)
    .then(res => {
      if (res.success) {
        this.setState({isLoggedIn: true, user: credentials.username, loading: false, userId: res.payload!.id, stopWorkspaceRedirect: true})
        if(callback) callback()
        if(this.state.invitationLink.length > 0) {
          this.joinWorkspace(this.state.invitationLink)
        }
      } else {
        Alert.error(this.props.t('login_error') + res.error_msg);
      }
    })
  }

  private async logout(){
    let isLoggedOut = await Repo.logOut().then(res => res.success)
    this.setState({isLoggedIn: !isLoggedOut, loading: false, analysisId: 0, workspaceId: 0})
  }

  private chooseAnalysis(workspace_id:number, analysis_id:number){
    this.setState({workspaceId: workspace_id, analysisId: analysis_id, redirectToAnalysis: true})
  }

  private stopRedirect(){
    this.setState({redirectToAnalysis: false})
  }

  private setInvitation(link:string){
    this.setState({invitationLink: link})
  }

  private setOverviewProps(props:AnalysisOverviewProps){
    this.setState({overviewProps : props})
  }

  private setAnalysisState(state:AnalysisPageState){
    this.setState({analysisState : state})
  }

  private showPrintableSummary(b:boolean){
    this.setState({showPrintableSummary: b})
  }

  async joinWorkspace(link:string){
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await Repo.useInvitation(link)
    .then(res => {
        if(res.success){Alert.info(this.props.t('join_workspace_success'))}
        else {Alert.error(this.props.t('join_workspace_error') + res.error_msg)}
        this.setState({invitationLink: ''})
    })
  }

  render(){
    if(this.state.loading || !this.props.tReady){return (
        <Loader backdrop content="Loading..." vertical />
    )}
    else return (
        this.state.showPrintableSummary && this.state.overviewProps ?
          <AnalysisOverview
            {...this.state.overviewProps}
            printableVersion={true}
            showPrintableSummary={this.showPrintableSummary.bind(this)}
          />
        :
          <RootPage 
            header={'Home'} 
            isLoggedIn={this.state.isLoggedIn} 
            logout={this.logout.bind(this)} 
            username={this.state.user} 
            stopRedirect={this.stopRedirect.bind(this)}
            tutorial={this.state.tutorial}
            setTutorial={(b:boolean) => this.setState({tutorial: b})}
          >

            <Switch>

              <Route 
                path="/" 
                exact
                render={props =>
                  <HomePage 
                    {...props}
                    loggedIn={this.state.isLoggedIn}
                  />
                } 
              />

              <Route 
                path="/demo" 
                exact 
                render={props =>
                  <AnalysisPage
                    {...props} 
                    workspaceId={0}
                    analysisId={0}
                    login={this.login.bind(this)} 
                    userId={this.state.userId}
                    setOverviewProps={this.setOverviewProps.bind(this)}
                    setAnalysisState={this.setAnalysisState.bind(this)}
                    showPrintableSummary={this.showPrintableSummary.bind(this)}
                    demo
                  />
                } 
              />

              <Route 
                path="/analysis" 
                exact 
                render={props =>
                  this.state.isLoggedIn && !this.state.workspaceId && !this.state.stopWorkspaceRedirect ?
                  <Redirect to="/workspaces"/> :
                  <AnalysisPage 
                    {...props} 
                    workspaceId={this.state.workspaceId}
                    analysisId={this.state.analysisId}
                    login={this.login.bind(this)} 
                    userId={this.state.userId}
                    setOverviewProps={this.setOverviewProps.bind(this)}
                    setAnalysisState={this.setAnalysisState.bind(this)}
                    showPrintableSummary={this.showPrintableSummary.bind(this)}
                    analysisState={this.state.analysisState}
                  />
                } 
              />

              <Route 
                path="/login" 
                exact 
                render={props => 
                  <LoginPage {...props} 
                    nextPage={'/workspaces'} 
                    login={this.login.bind(this)} 
                    loggedIn={this.state.isLoggedIn}
                  />
                }
              />
              
              <Route 
                path="/register" 
                exact
                render={props => 
                  this.state.isLoggedIn ? 
                  <Redirect to="/workspaces"/> : 
                  <RegisterPage {...props} login={this.login.bind(this)}/>
                }
              />
              
              <Route 
                path="/workspaces" 
                exact 
                render={
                  props =>
                  this.state.redirectToAnalysis ?  
                  <Redirect to='/analysis'/> : 
                  withAuth(this.checkIfLoggedIn.bind(this), WorkspacesPage, this.chooseAnalysis.bind(this))
                } 
              />

              <Route 
                path="/invitation/:link" 
                exact 
                render={(props => 
                  <InvitationHandler 
                    setInvitation={this.setInvitation.bind(this)} 
                    isLoggedIn={this.state.isLoggedIn} 
                    joinWorkspace={this.joinWorkspace.bind(this)}
                  />
                )} 
              />

              <Route 
                path="/summary/:link" 
                exact 
                render={(props =>
                  <AnalysisOverviewFromLink t={this.props.t} showPrintableSummary={this.showPrintableSummary.bind(this)} />
                )} 
              />

            </Switch>
          </RootPage>
    )
  }
}

export default withTranslation()(App);
