import React, { ReactElement } from 'react';
import { Sidebar, Container, Alert, Message, Button, Input, Nav, Divider, Panel, DatePicker, Whisper, Tooltip, SelectPicker, Loader, Steps, Icon } from 'rsuite';
import { EmptyFile, IFileData } from '../models/file';
import { FileData, TransformedLogRecord, FlaggedLogRecord, Violation, ActionsToGdpr, LogRecord } from '../models/engine-types';
import { inferActions, parseRecords, parseRecordsWithoutShape, setNumMatched, validateAndParseIFileData, validateShapeInputs } from '../engine/inputConverter';
import { GDPRRule, defaultRules, AnalysisParameters, AnalysisDateOption, totalViolations } from '../engine/rules';
import { convertToGdprLog } from '../engine/engine';
import { TranslationView } from '../components/analysisPageComponents/TranslationView';
import FileView from '../components/analysisPageComponents/FileView';
import ViolationsDisplay from '../components/analysisPageComponents/ViolationsDisplay';
import Filechooser from '../components/analysisPageComponents/Filechooser';
import { MyButton } from '../components/helperComponents/MyButton';
import { fromJSON, newShape, SeparatorInfo, Shape, ShapeInput, toJSON } from '../models/shape';
import { Repo } from '../repositories/Repo';
import { withTranslation } from 'react-i18next';
import { ERROR_TIME, formatDate, mappingFromJSON, removeMultilines, WHISPER_DELAY } from '../utils/utils';
import MyModal from '../components/helperComponents/MyModal';
import RegisterPage from './RegisterPage';
import { CommentInfo, CredentialsReq, FalsePositiveInfo, LogInfo, MappingInfo, PostAnalysisReq, PostWorkspaceReq, ShapeInfo } from '../../../common/ClientServerInterface';
import CommentOverview from '../components/violationsComponents/CommentOverview';
import DataShareOverview from '../components/analysisPageComponents/DataShareOverview';
import LoginPage from './LoginPage';
import LogManager from '../components/analysisPageComponents/LogManager';
import AnalysisOverview, { AnalysisOverviewProps, FileMapping } from '../components/analysisPageComponents/AnalysisOverview';
import { Link } from 'react-router-dom';
import { RiErrorWarningFill } from 'react-icons/ri'
import ContentDisplay from '../components/analysisPageComponents/ContentDisplay';
import { DreyerInputs, DreyerInputsEmpty } from '../testData/InputDreyer';
import { GeneratedLogInputs } from '../testData/InputGenerated';

/* The main page for creating analyses */

export interface SubmitResult {
    timestamp: string
    gdprLog : TransformedLogRecord[]
    rules : GDPRRule[]
    mappings: FileMapping[]
    parameters: AnalysisParameters
}

export interface AnalysisPageProps {
    workspaceId: number
    analysisId: number
    userId: number
    setOverviewProps: (p:AnalysisOverviewProps) => void
    setAnalysisState: (s:AnalysisPageState) => void
    showPrintableSummary: (b:boolean) => void
    t: (k:string) => string
    tReady: boolean
    login: (cred:CredentialsReq, callBack?:() => void) => Promise<void>
    analysisState?: AnalysisPageState
    demo?: boolean
}

export interface AnalysisPageState { 
    workspaceId: number //Id of the current workspace - 0 if none
    analysisId: number //Id of the current analysis - 0 if none
    files: IFileData[] //The current files - either from the workspace or new imported files
    shapes: ShapeInfo[] //The shapes saved in the workspace
    mappings: MappingInfo[] //The mappings saved in the workspace
    chosenFile: number //Keeps track of which file is currently selected
    submitResult: SubmitResult | null //The result of running the current analysis
    workspaceName: string //The name of the current workspace
    analysis_info?: PostAnalysisReq //Information that should be stored if the analysis is saved
    queuedAction: () => void //Action to be run after succesful login - used if the user is logged out after enterring the page and the logs in again using the login modal
    showLoginModal: boolean
    showResult: boolean
    showAnalysisOverview: boolean
    showTranslation: boolean
    showCommentsModal: boolean
    showDataShareModal: boolean
    showAnalysisNameModal: boolean
    showLogManager: boolean
    newAnalysisName: string //Used to name a new analysis
    oldAnalysisName: string //Name of the previous analysis, used to 'overwrite' the previous analysis
    comments: CommentInfo[] //Comments written to the violations
    activePage: string //Used to change page in the login modal
    parameters: AnalysisParameters //The parameters used to run the current analysis for which results are shown
    falsePositives: FalsePositiveInfo[] //The violations marked as false positives
    loading: boolean //Loading until the user's files have been fetched
    analysisLoad: boolean //Loading while an analysis is being run
    summaryLink: string //Link to analysis summary
    unsavedChanges: boolean //True if a new analysis has been submitted
    activeShape: number
    activeColumn: number[]
    activeRecord?: number
    demoStep: number
}

class MainPage extends React.Component<AnalysisPageProps, AnalysisPageState> {

    constructor(props:AnalysisPageProps) {
        super(props);
        this.state = {
            files: [],
            shapes: [],
            mappings: [],
            chosenFile: -1,
            showResult: false,
            showAnalysisOverview: false,
            showTranslation: false,
            submitResult: null,
            workspaceName: '',
            showLoginModal: false,
            workspaceId: this.props.workspaceId,
            analysisId: 0,
            comments: [],
            showCommentsModal: false,
            showDataShareModal: false,
            newAnalysisName: '',
            oldAnalysisName: '',
            showAnalysisNameModal: false,
            showLogManager : false,
            activePage: 'login',
            parameters: {dateOption: 'None'},
            queuedAction: () => {},
            falsePositives: [],
            loading: true,
            analysisLoad: false,
            summaryLink: '',
            unsavedChanges: false,
            activeShape: -1,
            activeColumn: [],
            demoStep: -1
        };
    }

    private async loadDreyerLog(){
        this.setState({
            files: [{...DreyerInputs(), selected: true}],
            loading: false
        })
    }

    /* Gets the files stored in the workspace */
    private async fetchUserFiles(){
        if(this.props.demo){
            this.setState({
                files: [DreyerInputs(), DreyerInputsEmpty(), GeneratedLogInputs()],
                shapes: [],
                mappings: [],
                demoStep: 0,
                loading: false
            })
            return
        }

        if(this.props.analysisId){
            await this.fetchAnalysis(this.props.analysisId)
        }

        const fileIds = this.state.files.map(f => f.id)

        if(this.state.workspaceId){
            await Repo.getWorkspace(this.state.workspaceId)
            .then(res => {
                if(res.success){
                    let available_files:IFileData[] = res.payload!.logs
                        .filter(l => !fileIds.includes(l.id))
                        .map((l:LogInfo) => ({
                            file: new EmptyFile(l.name),
                            selected: false,
                            content: removeMultilines(l.content),
                            shapes: [],
                            actionMapping: {},
                            id: l.id,
                            actionMappingid: 0,
                            actions: new Map(),
                            records: []
                        }))
    
                    available_files = available_files.map(f => ({...f, records: parseRecordsWithoutShape(f)}))
    
                    this.setState({
                        files: this.state.files.concat(available_files),
                        shapes: res.payload!.shapes,
                        mappings: res.payload!.mappings,
                        loading: false
                    })
                }
            })
        } else {
            this.setState({loading: false})
        }
    }

    /* Gets the shapes stored in the workspace */
    getShapes = async () => {
        Repo.getShapes(this.state.workspaceId)
        .then(res => {
            if(res.success) this.setState({shapes: res.payload!})
        })
    }

    /* Gets the mappings stored in the workspace */
    getMappings = async () => {
        Repo.getMappings(this.state.workspaceId)
        .then(res => {
            if(res.success) this.setState({mappings: res.payload!})
        })
    }

    handleSelectFiles = (files: IFileData[]) => {
        this.setState({ files: this.state.files.concat(files) });
    };

    handleFileClick = (idx: number) => {
        const chosenFile = this.state.files[idx]
        this.setState({ chosenFile: idx, activeShape: chosenFile.shapes.length > 0 ? 0 : -1, activeRecord: undefined }, () => {
            if (!chosenFile.content) this.loadFileContent(idx)
            else {
                this.applyShapes(chosenFile, true)
                if(chosenFile.actionMappingid) this.getMappingComments(idx, chosenFile.actionMappingid)
            }
        });
        if(this.state.demoStep === 0 && idx === 0) this.setState({demoStep: 1})
    }


    loadFileContent = (idx: number) => {
        const file = this.state.files[idx]
        file.file.text().then((c) => {
            const content = removeMultilines(c)
            const records:LogRecord[] = parseRecordsWithoutShape({...file, content})

            let files = [...this.state.files];
            const newFile:IFileData = { ...file, content, records }
            files[idx] = newFile

            this.setState({ files });
        });
    };

    changeCurrentFileData = (fdata: IFileData) => {
        let files = [...this.state.files];
        files[this.state.chosenFile] = { ...fdata };
        this.setState({ files });
    };

    handleFileDelete = (id: number) => {
        const newFiles = this.state.files;
        newFiles.splice(id, 1);
        this.setState({ files: newFiles });
    };

    /* Handles when the files is added or removed from the analysis */
    handleAddRemove = (id: number) => {
        const file = this.state.files[id];
        file.selected = !file.selected
        this.setState({ files: this.state.files});
    };

    /* Sets the number of matched records on each shape */
    setNumMatched(fdata:IFileData){
        setNumMatched(fdata)
        this.setState({ files: this.state.files });
    }

    sortViolations = (rules: GDPRRule[]) => {
        const violationRules = rules.filter((rule) => {
            return rule.getViolations(this.state.submitResult?.parameters).length > 0;
        });
        const noViolationRules = rules.filter((rule) => {
            return rule.getViolations(this.state.submitResult?.parameters).length === 0;
        });
        rules = violationRules.concat(noViolationRules);
        return rules;
    };

    modalTitle = (rules: GDPRRule[] | []): string => {
        const numViolations: number = totalViolations(rules, this.state.submitResult?.parameters);
        if (numViolations !== 0) {
            return this.props.t('total_violations_found') + ": " + numViolations;
        } else return this.props.t('no_violations_found');
    };

    titleStyle = (rules: GDPRRule[] | []): string => {
        const numViolations: number = totalViolations(rules, this.state.submitResult?.parameters);
        if (numViolations !== 0) return "rgb(228, 34, 34)";
        else return "green";
    };

    setShowResult = (show: boolean) => {
        this.setState({ showResult: show });
    };

    setShowTranslation = (show: boolean) => {
        this.setState({ showTranslation: show });
    };

    goToShape(shapeIdx:number){
        this.setState({activeShape: shapeIdx})
    }

    addShape(fdata:IFileData, s?:string){
        const shape = newShape(fdata.shapes.length + 1)
        fdata.shapes.push(shape)
        this.setState({files: this.state.files, activeShape: fdata.shapes.length-1})
    }

    async createAndSetWorkspace(){
        const req:PostWorkspaceReq = {
            workspace_name: 'My new workspace'
        }
        await Repo.addWorkspace(req)
        .then(res => {
            if(res.success){
                return this.setState({workspaceId: res.payload!.id, workspaceName: res.payload!.name})
            } else{
                Alert.error(this.props.t('create_workspace_error') + res.error_msg, ERROR_TIME)
            }
        
            this.setState({showLoginModal: false})
        })
        .then(res => res)
    }

    getComments(){
        if(this.state.workspaceId && this.state.analysisId){
            Repo.getComments(this.state.workspaceId, this.state.analysisId)
            .then(res => {
                if(res.success){
                    this.setState({comments: res.payload!})
                }
                else{
                    Alert.error(this.props.t('get_comments_error') + res.error_msg, ERROR_TIME)
                }
            })
        }
    }

    getMappingComments(fileIdx:number, mappingId:number){
        Repo.getMappingComments(this.state.workspaceId, mappingId)
        .then(res => {
            if(res.success){
                const files = this.state.files
                files[fileIdx].mappingComments = res.payload!
                this.setState({files})
            } else{
                Alert.error(this.props.t('get_comments_error') + res.error_msg, ERROR_TIME)
            }
        })
    }

    getFalsePositives(){
        if(this.state.workspaceId && this.state.analysisId){
            Repo.getFalsePositives(this.state.workspaceId, this.state.analysisId)
            .then(res => {
                if(res.success){
                    this.setState({falsePositives: res.payload!})
                }
                else{
                    Alert.error(this.props.t('get_false_positives_error') + res.error_msg, ERROR_TIME)
                }
            })
        }
    }

    showLoginModal(qa?:() => void){
        this.setState({showLoginModal: true, queuedAction: qa ? qa : () => {}})
    }

    onChangeTimestampRegex = (regex: string) => {
        const s = this.state.files[this.state.chosenFile].shapes[this.state.activeShape]
        if (regex === s.timestampRegex) return;   // Nothing has changed
        s.timestampRegex = regex
        s.changes = true
        this.forceUpdate()
    }

    onSeparatorInfoChange = (si:SeparatorInfo) => {
        const fdata = this.state.files[this.state.chosenFile]
        fdata.shapes[this.state.activeShape].separatorInfo = si
        fdata.shapes[this.state.activeShape].changes = true
        this.changeCurrentFileData(fdata)
    }

    onShapeAttributeChange = () => {
        const fdata = this.state.files[this.state.chosenFile]
        fdata.shapes[this.state.activeShape].changes = true
        this.forceUpdate()
    }

    contentDisplay(fdata:IFileData):ReactElement{
        return (
            <ContentDisplay
                onSeparatorInfoChange={this.onSeparatorInfoChange.bind(this)}         
                fdata={fdata}
                loading={!fdata.content}
                setActiveShape={n => this.setState({activeShape: n})}
                filterShape={fdata.filterShape}
                activeShape={this.state.activeShape}
                addShape={() => this.addShape(fdata)}
                activeColumn={this.state.activeColumn}
                setActiveColumn={(n:number[]) => this.setState({activeColumn: n})}
                activeRecord={this.state.activeRecord}
                setActiveRecord={(n:number) => this.setState({activeRecord: n})}
                onTimestampRegexChange={this.onChangeTimestampRegex.bind(this)}
                onAttributeChange={this.onShapeAttributeChange.bind(this)}
            />
        )
    }

    /* Applies the shapes to the file - i.e. the log is translated to actions, which can be mapped */
    applyShapes = (fdata: IFileData, ignoreError?:boolean) => {
        try {
            let cleanShapes : Shape[] = validateShapeInputs(fdata.shapes)
            let records = parseRecords(fdata, cleanShapes)
            let actions = inferActions(records)
            let prevMap = fdata.actionMapping
        
            Array.from(actions.keys()).forEach(a => {
                if(!prevMap[a]) {fdata.mappingChanges = true}
                fdata.actionMapping[a] = prevMap[a] ? prevMap[a] : {gdprActions: [], description: ''}
            })
            
            fdata.actions = actions
            fdata.records = records

            setNumMatched(fdata)
            this.forceUpdate()
        } catch (error:any) {
            if(!ignoreError) Alert.error(error.message, ERROR_TIME)
        }
    }

    /* Applies the shapes of each log file */
    applyToAll(logs:IFileData[], shapes:ShapeInfo[], mapping?:MappingInfo){
        for(const l of logs){
            l.shapes = shapes.map(s => ({...fromJSON(s.content), id: s.id}))
            this.applyShapes(l, true)
            if(mapping){
                const json = mappingFromJSON(mapping.content)
                for(const a in l.actionMapping){
                    l.actionMapping[a] = json[a] ? json[a] : {gdprActions: [], description: ''}
                }
                for(const a in json){
                    if(!l.actionMapping[a]) l.actionMapping[a] = json[a]
                }
                l.actionMappingid = mapping.id
            }
        }
    }

    updateMappings(old_id:number, new_id:number, mapping_content:ActionsToGdpr){
        this.state.files.forEach(f => {
            if(f.actionMappingid === old_id && !f.mappingChanges){
                f.actionMapping = mapping_content
                f.actionMappingid = new_id
            }
        })
    }

    updateShapes(old_id:number, new_id:number, shape_content:ShapeInput){
        this.state.files.forEach(f => {
            f.shapes.forEach(s => {
                if(s.id === old_id && !s.changes){
                    s.attributes = shape_content.attributes
                    s.preserveCase = shape_content.preserveCase
                    s.separatorInfo = shape_content.separatorInfo
                    s.timestampRegex = shape_content.timestampRegex
                    s.id = new_id
                }
            })
        })
    }

    saveAnalysis(deleteCurrent?:boolean){
        if(this.state.analysis_info)
            Repo.postAnalysis(this.state.workspaceId, {
                ...this.state.analysis_info, 
                name: deleteCurrent ? this.state.oldAnalysisName : this.state.newAnalysisName, 
                transferComments: deleteCurrent ? this.state.analysisId : undefined
            })
            .then(res => {
                if(res.success){
                    Alert.info(this.props.t('save_analysis_success'))
                    if(deleteCurrent) Repo.removeAnalysis(this.state.workspaceId, this.state.analysisId)
                    this.setState({
                        analysisId: Number(res.payload?.id), 
                        showAnalysisNameModal: false, 
                        newAnalysisName: '', 
                        unsavedChanges: false
                    })
                    this.fetchAnalysis(Number(res.payload?.id))
                } else{
                    Repo.isLoggedIn().then(loginRes =>{//Try to log in if not logged in
                        if(loginRes.success) {
                            Alert.error(this.props.t('save_analysis_error') + res.error_msg, ERROR_TIME)
                        }
                        else this.showLoginModal(() => this.saveAnalysis(deleteCurrent))
                    })
                }
            })
    }

    async fetchAnalysis(analysisId:number){
        await Repo.getOneAnalysis(this.state.workspaceId, analysisId)
        .then(res => {
            if(res.success){
                const analysis = res.payload!
                let submittedFiles:IFileData[] = analysis.logs.map(l => ({
                    file: new EmptyFile(l.name),
                    content: removeMultilines(l.content),
                    shapes: l.shapes.map(s => ({...fromJSON(s.content), id: s.id})),
                    id: l.id,
                    selected: true,
                    actionMapping: JSON.parse(l.mapping.content),
                    actionMappingid: l.mapping.id,
                    actions: new Map(),
                    records: []
                }))

                submittedFiles = submittedFiles.map(f => ({...f, records: parseRecordsWithoutShape(f)}))

                if(submittedFiles.length <= 0){
                    Alert.error(this.props.t('no_files_submit'), ERROR_TIME)
                    return
                }

                let analysis_info:PostAnalysisReq = {
                    name: analysis.name,
                    analysisParameters: analysis.parameters,
                    logs: submittedFiles.map(f => ({
                        log_name: f.file.name, 
                        log_content: f.content, 
                        id: f.id, 
                        shapes: f.shapes.map(s => ({
                            id: s.id, 
                            content: toJSON(s), 
                            shape_name: s.name
                        })), 
                        mapping: {
                            id: f.actionMappingid, 
                            content: JSON.stringify(f.actionMapping), 
                            mapping_name: "mapping_"+formatDate(new Date(Date.now()))
                        }}
                    ))
                }

                this.setState({ 
                    files: [...this.state.files.filter(f => !f.selected), ...submittedFiles], 
                    analysis_info: analysis_info, 
                    falsePositives: analysis.falsePositives, 
                    analysisId, 
                    oldAnalysisName: analysis.name
                })
                submittedFiles.forEach(f => {this.setNumMatched(f)})

                try {
                    let timestamp = formatDate(new Date(analysis.pub_date))
                    let submitResult = this.analyse(submittedFiles, timestamp, JSON.parse(JSON.stringify(analysis.parameters)))
                    this.setState({ submitResult })
                    this.props.setOverviewProps({          
                        timestamp: formatDate(new Date(analysis.pub_date)),                                  
                        rules: submitResult.rules,
                        analysisParameters: submitResult.parameters,
                        records: submitResult.gdprLog,
                        mappings: submitResult.mappings,
                        falsePositives: analysis.falsePositives,
                        showPrintableSummary: this.props.showPrintableSummary
                    })
                    this.getComments()
                } catch (error:any) { 
                    Alert.error(error.message, ERROR_TIME)
                }
            } else{
                Alert.error(this.props.t('get_analysis_error'), ERROR_TIME)
            }
        })
    }

    /* Runs the analysis */
    analyse(submittedFiles:IFileData[], timestamp:string, parameters:AnalysisParameters) : SubmitResult {
        submittedFiles.forEach(f => this.applyShapes(f, true))
        let cleanData: FileData[] = submittedFiles.map(f => validateAndParseIFileData(f))
        let gdprLog: TransformedLogRecord[] = convertToGdprLog(cleanData)
        let rules: GDPRRule[] = defaultRules()
        rules.forEach((r:GDPRRule) => r.stepMany(gdprLog))
        rules = this.sortViolations(rules)
        return { 
            rules, 
            gdprLog, 
            timestamp, 
            parameters: JSON.parse(JSON.stringify(parameters)), 
            mappings: submittedFiles.map(f => ({mapping: JSON.parse(JSON.stringify(f.actionMapping)), fileName: f.file.name})) 
        }
    }

    /* Sets loading to true before running the analysis */
    onRunAnalysis(){
        this.setState({
            analysisLoad: true
          }, () => {
            setTimeout(() => {
                this.onSubmit()
                this.setState({analysisLoad: false, unsavedChanges: true, demoStep: this.state.demoStep === 2 ? this.state.demoStep+1 : this.state.demoStep});
            }, 0);
          })
    }

    /* Calls this.analyse and sets the result in the state */ 
    onSubmit(){
        if (this.state.showTranslation) return

        const submittedFiles = this.state.files.filter(f => f.selected)
        if(submittedFiles.length <= 0){
            Alert.error(this.props.t('no_files_submit'), ERROR_TIME)
            return
        }
        try {
            let timestamp = formatDate(new Date(Date.now()))
            let submitResult = this.analyse(submittedFiles, timestamp, this.state.parameters)
            let analysis_info:PostAnalysisReq = {
                name: 'My Analysis', 
                analysisParameters: JSON.stringify(submitResult.parameters),
                logs: submittedFiles.map(f => ({
                    log_name: f.file.name, 
                    log_content: f.content, 
                    id: f.id,
                    shapes: f.shapes.map(s => ({
                        id: 0, 
                        content: toJSON(s),
                        shape_name: s.name
                    })), 
                    mapping: {
                            id: f.mappingChanges ? 0 : f.actionMappingid,
                            content: JSON.stringify(f.actionMapping),
                            mapping_name: "mapping_"+formatDate(new Date(Date.now()))},
                            transferComments: f.mappingChanges ? f.actionMappingid : 0
                    }
                ))}
            this.setState({ submitResult, analysis_info, showResult: true, falsePositives: [] })
            this.props.setOverviewProps({                                            
                    timestamp: timestamp,                                  
                    rules: submitResult.rules,
                    analysisParameters: submitResult.parameters,
                    records: submitResult.gdprLog,
                    mappings: submitResult.mappings,
                    falsePositives: [],
                    showPrintableSummary: this.props.showPrintableSummary
            })
        } catch (error:any) { 
            Alert.error(error.message, 10000)
        }
    }

    componentDidMount(){
        this.props.analysisState ?
            this.setState(this.props.analysisState)
            :
            this.fetchUserFiles()
    }

    render() {
        let fdata =
        this.state.chosenFile >= 0
            ? this.state.files[this.state.chosenFile]
            : undefined;
        
        let title = fdata ? fdata.file.name : this.props.t('no_file_selected');

        let flaggedRecords:FlaggedLogRecord[] = []
        if(this.state.submitResult){
            let recs = this.state.submitResult.gdprLog
            flaggedRecords = recs.map(r => ({record: r, flags: new Map<string, boolean>()}))
            this.state.submitResult.rules.forEach(rule => {
                let violations = rule.getViolations(this.state.submitResult?.parameters)
                flaggedRecords.forEach(fe => {
                    let flag = violations!.reduce((found:boolean, vs:Violation) => found || (vs.filter(x => 
                        x.e.id === fe.record.id
                        ).length > 0), false)
                    fe.flags.set(rule.name, flag)
                })
            })
        }

        const not_in_workspace_title = this.props.t('not_in_workspace_title')
        const not_in_workspace_description = (
            <>
                {this.props.t('not_in_workspace_description')}
                <Button 
                    appearance='link' 
                    onClick={()=> this.setState({showLoginModal: true})}
                >
                    {this.props.t('or_register')}
                </Button>
            </>
        )

        const dateOptions:{label: string, value: AnalysisDateOption}[] = [
            {label: 'Consider unmet requests as violations', value: 'None'}, 
            {label: 'Choose an analysis date', value: 'Date'}, 
            {label: 'Ignore unmet requests', value: 'Ignore'}
        ]

        const demoSteps = [
            {title: this.props.t('demo_click_on_file_title'), description: this.props.t('demo_click_on_file')},
            {title: this.props.t('demo_shape_and_mapping_title'), description: 
                <>
                    <p>{this.props.t('demo_shape_and_mapping_a')}</p>
                    <p>{this.props.t('demo_shape_and_mapping_b')}</p>
                    <p>{this.props.t('demo_shape_and_mapping_c')}</p>
                </>
            },
            {title: this.props.t('demo_run_analysis_title'), description: this.props.t('demo_run_analysis')},
            {title: this.props.t('demo_results_title'), description: this.props.t('demo_results')},
            {title: this.props.t('demo_create_a_shape_title'), description: this.props.t('demo_create_a_shape')},
            {title: this.props.t('demo_create_a_mapping_title'), description: 
                <>
                    <p>{this.props.t('demo_create_a_mapping')}</p>
                    <p>{this.props.t('demo_create_a_mapping_b')}</p>
                </>
            }
        ]

        return (
            <>
            <Container>
                <div className="main-page container" style={{ width: "100vw" }}>
                    <Sidebar style={{ padding:'10px', display: "flex", flexDirection: "column"}}>
                        <Filechooser
                            onSelect={this.handleSelectFiles}
                            onFileClick={this.handleFileClick}
                            onFileDelete={this.handleFileDelete}
                            onAddRemove={this.handleAddRemove}
                            files={this.state.files}
                            loading={this.state.loading}
                            demoStep={this.state.demoStep}
                        />
                        <div style={{textAlign: "center"}}>

                            <Whisper speaker={<Tooltip>{this.props.t('manage_logs_tooltip')}</Tooltip>}>
                                <Button 
                                    onClick={() => this.setState({showLogManager: true})} 
                                    style={{width: '80%', marginTop: 0}} 
                                    appearance='ghost'
                                >
                                    {this.props.t('manage_logs')}...
                                </Button>
                            </Whisper>

                            <Divider/>

                            <div style={{marginTop: 20}}>
                                <Whisper speaker={<Tooltip>{this.props.t('analysis_date_description')}</Tooltip>}>    
                                    <SelectPicker 
                                        data={dateOptions} 
                                        onSelect={(v:AnalysisDateOption) => 
                                            this.setState({parameters: {dateOption: v}})}
                                        style={{width: '80%'}}
                                        searchable={false}
                                        value={this.state.parameters.dateOption}
                                        cleanable={false}
                                    />
                                </Whisper>
                                {this.state.parameters.dateOption === 'Date' ?
                                    <div style={{marginTop: 20}}> 
                                        <p>{this.props.t('analysis_date')}:</p>
                                        <Whisper speaker={<Tooltip>{this.props.t('choose_date')}</Tooltip>}>    
                                            <DatePicker 
                                                onChange={d => this.setState({parameters: {dateOption: 'Date', date: d}})} 
                                                onClean={() => this.setState({parameters: {dateOption: 'None'}})}
                                                value={this.state.parameters.date}
                                                style={{width: '80%'}}
                                                placement='rightEnd'
                                                ranges={[
                                                    {
                                                    label: 'Today',
                                                    value: new Date()
                                                    },
                                                ]}
                                            />
                                        </Whisper>
                                    </div>
                                    :
                                    ''
                                }
                            </div>

                            <Button 
                                onClick={this.onRunAnalysis.bind(this)} 
                                style={{width: '80%', marginTop: 20, height: 40}} 
                                appearance='primary'
                            >
                                {this.state.analysisLoad ? <Loader center/> : this.props.t('submit')}
                            </Button>

                            {this.state.submitResult && 
                                <div>
                                    <Divider/>

                                    {this.state.unsavedChanges && !this.props.demo ? 
                                        <Whisper 
                                            speaker={<Tooltip>{this.props.t('analysis_not_saved')}</Tooltip>}
                                        >
                                            <RiErrorWarningFill size={30} color='#ffdf00' />
                                        </Whisper> 
                                        : 
                                        ''
                                    }

                                    <p>{this.props.t('result_submitted')}:</p> 
                                    <p style={{marginTop: -5}}>{this.state.submitResult.timestamp}</p>

                                    <Whisper delay={WHISPER_DELAY} speaker={<Tooltip>{this.props.t('show_violations_info')}</Tooltip>}>
                                        <MyButton
                                            onClick={ () => this.setShowResult(true) }
                                            disabled={this.state.submitResult === null}
                                            style={{marginTop: 20, width: '80%'}}
                                        >
                                            {this.props.t('show_violations')}
                                        </MyButton>
                                    </Whisper>

                                    <Whisper delay={WHISPER_DELAY} speaker={<Tooltip>{this.props.t('show_analysis_summary_info')}</Tooltip>}>
                                        <MyButton
                                            onClick={ () => this.setState({showAnalysisOverview: true, demoStep: this.state.demoStep === 3 ? 4 : this.state.demoStep}) }
                                            disabled={this.state.submitResult === null}
                                            style={{marginTop: 10, width: '80%'}}
                                        >
                                            {this.props.t('show_analysis_summary')}
                                        </MyButton>
                                    </Whisper>

                                    <Whisper delay={WHISPER_DELAY} speaker={<Tooltip>{this.props.t('show_translation_info')}</Tooltip>}>
                                        <MyButton
                                            onClick={() => this.setShowTranslation(true) }
                                            disabled={this.state.submitResult === null}
                                            style={{marginTop: 10, width: '80%'}}
                                        >
                                            {this.props.t('show_translation')}
                                        </MyButton>
                                    </Whisper>

                                    <Whisper delay={WHISPER_DELAY} speaker={<Tooltip>{this.props.t('show_data_share_info')}</Tooltip>}>
                                        <MyButton
                                            onClick={() => this.setState({showDataShareModal: true}) }
                                            disabled={this.state.submitResult === null}
                                            style={{marginTop: 10, width: '80%'}}
                                        >
                                            {this.props.t('show_data_share')}
                                        </MyButton>
                                    </Whisper>

                                    <Whisper delay={WHISPER_DELAY} speaker={<Tooltip>{this.props.t('update_analysis_info')}</Tooltip>}>
                                        <MyButton
                                            onClick={() => this.saveAnalysis(true) }
                                            disabled={this.state.analysisId === 0}
                                            style={{marginTop: 10, width: '80%'}}
                                        >
                                            {this.props.t('update_analysis')}
                                        </MyButton>
                                    </Whisper>

                                    <Whisper delay={WHISPER_DELAY} speaker={<Tooltip>{this.props.t('save_analysis_info')}</Tooltip>}>
                                        <MyButton
                                            onClick={() => this.setState({showAnalysisNameModal: true}) }
                                            disabled={this.state.submitResult === null || this.props.demo}
                                            style={{marginTop: 10, width: '80%'}}
                                        >
                                            {this.props.t('save_analysis')}
                                        </MyButton>
                                    </Whisper>

                                    <Whisper delay={WHISPER_DELAY} speaker={<Tooltip>{this.props.t('show_comments_info')}</Tooltip>}>
                                        <MyButton
                                            onClick={() => this.setState({showCommentsModal: true}) }
                                            disabled={this.state.analysisId === 0}
                                            style={{marginTop: 10, width: '80%'}}
                                        >
                                            {this.props.t('show_comments')}
                                        </MyButton>
                                    </Whisper>

                                    <ViolationsDisplay
                                        rules={this.state.submitResult.rules}
                                        title={this.modalTitle(this.state.submitResult.rules)}
                                        show={this.state.showResult}
                                        onClose={() => {this.setShowResult(false); this.getComments(); this.getFalsePositives()}}
                                        color={this.titleStyle(this.state.submitResult.rules)}
                                        workspaceId={this.state.workspaceId}
                                        analysisId={this.state.analysisId}
                                        userId={this.props.userId}
                                        comments={this.state.comments}
                                        analysisParameters={this.state.submitResult.parameters}
                                        falsePositives={this.state.falsePositives}
                                    />

                                    <MyModal 
                                        title={'Analysis Summary'} 
                                        titleSize={'36px'}
                                        show={this.state.showAnalysisOverview} 
                                        onClose={() => this.setState({showAnalysisOverview: false})} 
                                        full={true}
                                        buttons={[
                                            <span>
                                                {
                                                    this.state.summaryLink ? 
                                                    `https://gdpr-check.net/summary/${this.state.summaryLink}`
                                                    :
                                                    ''
                                                }
                                            </span>,
                                            <Button 
                                                onClick={() => 
                                                    Repo.postSummary({workspaceId: this.state.workspaceId, analysisId: this.state.analysisId})
                                                    .then(res => {
                                                        if(res.success){
                                                            this.setState({summaryLink: res.payload!.link})
                                                        } else{
                                                            Alert.error(this.props.t('get_summary_link_error') + res.error_msg, ERROR_TIME)
                                                        }
                                                    })
                                                }
                                                disabled={this.props.analysisId <= 0}
                                                style={{marginLeft: 10}}
                                            >
                                                {this.props.t('get_link')}
                                            </Button>,
                                            <Link onClick={() => {this.props.showPrintableSummary(true); this.props.setAnalysisState(this.state)}} to="/summary" >
                                                <Button>{this.props.t('printable_version')}</Button>
                                            </Link>,
                                        ]}
                                    >   
                                        <AnalysisOverview
                                            timestamp={this.state.submitResult.timestamp} 
                                            rules={this.state.submitResult.rules}
                                            analysisParameters={this.state.submitResult.parameters}
                                            records={this.state.submitResult.gdprLog}
                                            mappings={this.state.submitResult.mappings}
                                            falsePositives={this.state.falsePositives}
                                            showPrintableSummary={this.props.showPrintableSummary}
                                            fromLink
                                        />
                                    </MyModal>

                                    <TranslationView
                                        records={flaggedRecords}
                                        show={this.state.showTranslation}
                                        onClose={() => this.setShowTranslation(false)}
                                        gdprRules={this.state.submitResult.rules}
                                        analysisParameters={this.state.submitResult.parameters}
                                    />

                                    <MyModal 
                                        full={false} 
                                        title={this.props.t('save_analysis')} 
                                        show={this.state.showAnalysisNameModal} 
                                        onAccept={() => {this.saveAnalysis()}} 
                                        onClose={() => this.setState({showAnalysisNameModal: false})}
                                    >
                                        {this.props.t('analysis_name')}:
                                        <Input 
                                            value={this.state.newAnalysisName} 
                                            onChange={(name) => this.setState({newAnalysisName: name})}
                                        />
                                    </MyModal>

                                    <MyModal 
                                        full={true} 
                                        title={this.props.t('comment_overview')} 
                                        show={this.state.showCommentsModal} 
                                        onClose={() => {this.setState({showCommentsModal: false}); this.getComments()}}
                                    >
                                        <CommentOverview 
                                            comments={this.state.comments.sort((a,b)=>
                                                new Date(a.uploadedAt).getTime()-new Date(b.uploadedAt).getTime())} 
                                            workspaceId={this.state.workspaceId} analysisId={this.state.analysisId}
                                            violations={this.state.submitResult?.rules.flatMap(r => r.getViolations())!}
                                            userId={this.props.userId}
                                            showLoginModal={this.showLoginModal.bind(this)}
                                        />
                                    </MyModal>

                                    <MyModal 
                                        full={true} 
                                        title={this.props.t('data_share_overview')} 
                                        show={this.state.showDataShareModal} 
                                        onClose={() => this.setState({showDataShareModal: false})}
                                    >
                                        <DataShareOverview 
                                            analysisResult={this.state.submitResult}
                                        />
                                    </MyModal>

                                </div>
                            }
                        </div>
                    </Sidebar>

                    <Container style={{ width: "100vh", padding: "0 10px" }}>
                        {this.props.demo && this.state.demoStep >= 0 ?
                            <Panel style={{marginTop: 30}}>
                                <Steps current={this.state.demoStep}>
                                    {demoSteps.map(ds => <Steps.Item title={ds.title}></Steps.Item>)}
                                </Steps>
                                <Message 
                                    type="info" 
                                    title={
                                        <>
                                            <Icon icon={'circle'} style={{color: '#ffca28'}} />
                                            <span style={{marginLeft: 10}}>
                                                {`${this.props.t('tutorial_step')} ${(this.state.demoStep + 1)}: ${demoSteps[this.state.demoStep].title}`}
                                            </span>
                                        </>
                                    }
                                    description={
                                        <p>
                                            {demoSteps[this.state.demoStep].description}
                                            <div style={{marginLeft: -10}}>
                                                {this.state.demoStep > 0 ?
                                                    <Button 
                                                        appearance='link' 
                                                        onClick={() => this.setState({demoStep: this.state.demoStep-1})}
                                                    >
                                                        {'<< ' + this.props.t('prev_step')}
                                                    </Button> 
                                                    : ''
                                                }
                                                {this.state.demoStep < demoSteps.length-1 ?
                                                    <Button 
                                                        appearance='link' 
                                                        onClick={() => this.setState({demoStep: this.state.demoStep+1})}
                                                    >
                                                        {this.props.t('next_step') + ' >>'}
                                                    </Button>
                                                    : ''
                                                }
                                            </div>
                                        </p>
                                    }                                    
                                    style={{width: '50%', marginLeft: '25%', marginTop: 30}}
                                /> 
                            </Panel>
                            :
                            this.state.workspaceId !== 0 ?
                            '' 
                            :
                            <Message 
                                type="warning" 
                                closable 
                                title={not_in_workspace_title} 
                                description={not_in_workspace_description} 
                                style={{width: '50%', marginLeft: '25%', marginTop: 10}}
                            />
                        }
                        <h2 className="heading text-center" style={{marginTop: 20}}>{title}</h2>
                        {
                            fdata ? '' :
                            <p className="text-center">
                                {this.props.t('select_a_file')}
                            </p>
                        }
                        {
                            fdata && (
                                <FileView
                                    fdata={fdata}
                                    onChange={this.changeCurrentFileData.bind(this)}
                                    workspaceId={this.state.workspaceId}
                                    showLoginModal={this.showLoginModal.bind(this)}
                                    applyShapes={this.applyShapes.bind(this)}
                                    updateMappings={this.updateMappings.bind(this)}
                                    updateShapes={this.updateShapes.bind(this)}
                                    getMappings={this.getMappings.bind(this)}
                                    getShapes={this.getShapes.bind(this)}
                                    mappings={this.state.mappings}
                                    shapes={this.state.shapes}
                                    userId={this.props.userId}
                                    setNumMatched={fdata ? () => this.setNumMatched(fdata!) : () => {}}
                                    activeShape={this.state.activeShape}
                                    setActiveShape={this.goToShape.bind(this)}
                                    recordExample={this.state.activeRecord && this.state.files[this.state.chosenFile].records.length ?
                                        this.state.files[this.state.chosenFile].records[this.state.activeRecord].record 
                                        : ''
                                    }
                                    demoStep={this.state.demoStep}
                                    setDemoStep={(n) => this.setState({demoStep: n})}
                                >
                                    {this.contentDisplay(this.state.files[this.state.chosenFile])}
                                </FileView>
                            )
                        }

                    </Container>

                    <MyModal 
                        full={true} 
                        title={this.props.t('login_title')} 
                        subTitle={this.props.t('login_subtitle')}
                        show={this.state.showLoginModal} 
                        onClose={() => this.setState({showLoginModal: false})}
                    >
                        <Nav 
                            onSelect={(s:string) => this.setState({activePage: s})} 
                            appearance='tabs' 
                            activeKey={this.state.activePage}
                        >
                            <Nav.Item eventKey="login">{this.props.t('login')}</Nav.Item>
                            <Nav.Item eventKey="register">{this.props.t('register')}</Nav.Item>
                        </Nav>
                        {
                            this.state.activePage === 'login' ?
                                <LoginPage 
                                    login={creds => this.props.login(creds, async () => {
                                        await this.createAndSetWorkspace()
                                        this.state.queuedAction()
                                        this.setState({showLoginModal: false, queuedAction: () => {}})
                                    })} 
                                    loggedIn={false} 
                                    nextPage={''} 
                                    stopRedirect
                                /> 
                                :
                                <RegisterPage 
                                    login={creds => this.props.login(creds, async () => {
                                        await this.createAndSetWorkspace()
                                        this.state.queuedAction()
                                        this.setState({showLoginModal: false, queuedAction: () => {}})
                                    })} 
                                    stopRedirect
                                />
                        }
                    </MyModal>

                    <LogManager
                        files={this.state.files}
                        shapes={this.state.shapes}
                        mappings={this.state.mappings}
                        show={this.state.showLogManager}
                        onClose={() => this.setState({showLogManager: false})}
                        apply={this.applyToAll.bind(this)}
                    />
                </div>
            </Container>

            <Panel style={{height: 200}}/>

            </>
        );
    }
}

export default withTranslation()(MainPage);
