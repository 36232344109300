import { Component } from 'react';
import { Button, Checkbox, Divider, FlexboxGrid, Radio, RadioGroup, TagPicker} from 'rsuite';
import { MappingInfo, ShapeInfo } from '../../../../common/ClientServerInterface';
import MyModal from '../helperComponents/MyModal';
import { withTranslation } from 'react-i18next';
import { IFileData } from '../../models/file';

/* Allows to apply shapes and/or a mapping to multiple log files at once */

interface LogManagerProps {
    files: IFileData[]
    shapes:ShapeInfo[]
    mappings:MappingInfo[]
    onClose: () => void
    show: boolean
    apply: (l:IFileData[], s:ShapeInfo[], m?:MappingInfo) => void
    t: (k:string) => string
    tReady: boolean
}

interface LogManagerState {
    selectedLogs: IFileData[]
    selectedShapes: ShapeInfo[]
    selectedMapping?: MappingInfo
}

class LogManager extends Component<LogManagerProps, LogManagerState> {

    constructor(props:LogManagerProps) {
        super(props);
        this.state = {
            selectedLogs: [],
            selectedShapes: [],
            selectedMapping: undefined
        }
    }

    onChange(log:IFileData, checked:boolean){
        if(checked){
            this.state.selectedLogs.push(log)
            this.setState({selectedLogs: this.state.selectedLogs})
        } else{
            this.setState({selectedLogs: this.state.selectedLogs.filter(l => l.id !== log.id)})
        }
    }

    clearData(){
        this.setState({
            selectedLogs: [],
            selectedShapes: [],
            selectedMapping: undefined
        })
    }

    handleCheckAll(value:IFileData[], checked:boolean){
        this.setState({selectedLogs: checked ? this.props.files : []})
    }

    render() {

        return (
            <MyModal
                title={'Manage Logs'} 
                buttons={[
                    <Button 
                        appearance='primary' 
                        onClick={() => {
                                this.props.apply(this.state.selectedLogs, this.state.selectedShapes, this.state.selectedMapping)
                                this.clearData();this.props.onClose()
                            }
                        }
                    >
                        Apply
                    </Button>
                ]} 
                titleSize={'36px'} 
                show={this.props.show} 
                onClose={this.props.onClose}
                full={true} 
            >
                <FlexboxGrid justify="center" style={{padding:  20}}>

                    <FlexboxGrid.Item colspan={8}>
                        <h4>Logs</h4>
                        <Divider/>
                        <Checkbox
                            indeterminate={this.state.selectedLogs.length > 0 && 
                                this.state.selectedLogs.length < this.props.files.length}
                            checked={this.state.selectedLogs.length === this.props.files.length}
                            onChange={this.handleCheckAll.bind(this)}
                            style={{marginBottom: 15}}
                        >
                            Check all
                        </Checkbox>
                        {this.props.files.map((f, i) => (
                            <Checkbox 
                                key={i} 
                                value={f} 
                                onChange={this.onChange.bind(this)} 
                                checked={this.state.selectedLogs.find(l => l.id === f.id && l.file.name === f.file.name) !== undefined}
                            >
                                {f.file.name}
                            </Checkbox>
                        ))}
                        
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item colspan={8}>
                    <   h4>Shapes</h4>
                        <Divider/>
                        <TagPicker 
                            data={this.props.shapes.map(s => ({label: s.name, value: s}))} 
                            value={this.state.selectedShapes} 
                            onChange={selectedShapes => this.setState({selectedShapes})}
                            style={{width: '70%'}}
                            searchable
                        />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item colspan={8}>
                        <h4>Mappings</h4>
                        <Divider/>
                        <RadioGroup 
                            value={this.state.selectedMapping} 
                            onChange={selectedMapping => this.setState({selectedMapping})}
                        >
                            <Radio 
                                key={0} 
                                value={undefined} 
                                style={{marginBottom: 15}} 
                                checked={this.state.selectedMapping === undefined}
                            >
                                {'No mapping'}
                            </Radio>                            
                            {this.props.mappings.map(m => (
                                <Radio key={m.id} value={m}>
                                    {m.name}
                                </Radio>
                            ))}
                        </RadioGroup>
                    </FlexboxGrid.Item>

                </FlexboxGrid>

            </MyModal>
        );
    }
}

export default withTranslation()(LogManager)

