import React from "react"
import { Button } from "rsuite"

/* Text that can be expanded by a show more button/link */

interface ShowMoreTextProps{
    value: string
    length:number
}

interface ShowMoreTextState{
    expand: boolean
}

export class ShowMoreText extends React.Component<ShowMoreTextProps, ShowMoreTextState>{

    constructor(props:ShowMoreTextProps){
        super(props)
        this.state = {
            expand: false
        }
    }

    edit() {
        this.setState({expand: this.state.expand!==false})
    }

    render() {
        const linkStyle ={padding: 0, marginLeft: 5, marginTop: -2}
        return this.state.expand ? 
            <div style={{ overflow: "visible"}} >
                {this.props.value}
                <Button appearance='link' onClick={() => this.setState({expand: false})} style={linkStyle}>
                    Show less
                </Button>
            </div>
            :
            <div style={{display: 'flex'}}>
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        {this.props.value.substring(0, this.props.length)}{this.props.value.length > this.props.length ? '...' : ''}
                    </div>
                    {
                        this.props.value.length > this.props.length ?
                        <Button appearance='link' onClick={() => this.setState({expand: true})} style={linkStyle}>
                            Show more
                        </Button>
                        : ''
                    }
            </div>
    }
}