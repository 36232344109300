/** **** Data for Engine *****
    When the input is complete, all lines can be parsed to LogRecords. So the engine expects a File 
    where all records have been parsed to LogRecords, and a mapping from events to gdpr actions. 
    The engine transforms LogRecords to TransformedLogRecords, and merges files into one big list
    of TransformedLogRecords. 
    It also run the log through the gdpr rules, to find violations.
*/
import { CoreGDPRSignature } from "./gdpr-signatures"

export const LogRecordId = (filename: string, index: number) => `${filename}${index}`

export type LogRecord = {   // The data we need from each record/entry in the logfile
    id: string
    filename: string
    record: string
    index: number
    dsid: string
    actions: string[]
    timestamp: Date
    dataid: string
    fileid: number
    shapeid: number
}

export type TransformedLogRecord = LogRecord & CoreGDPRSignature    // A normal log entry, but with the GDPR actions
export type FlaggedLogRecord = {record: TransformedLogRecord, flags: Map<string, boolean>}
export type CaseMapping = Map<string, TransformedLogRecord[]>       // A mapping from case id to all records in the tranformed log

export type ViolationEntry = { e: TransformedLogRecord, desc: string, flag: boolean }
export type Violation = ViolationEntry[]
export const newViolationEntry = (e: TransformedLogRecord, desc: string, flag: boolean) => ({ e, desc, flag })

export type GDPRAction = {signature: CoreGDPRSignature, startOfCase:boolean, ifDataCollected: boolean}
export type Mapping = {gdprActions: GDPRAction[], description: string}

export type ActionsToGdpr = Record<string, Mapping>

export type FileData = {
    filename: string
    records: LogRecord[]
    actionToGdpr: ActionsToGdpr
}