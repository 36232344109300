import { AnalysisParameters, defaultRules, GDPRRule, getRuleFromList, RightToErasure2, totalViolations } from '../../engine/rules';
import React, { Component } from 'react';
import { ActionsToGdpr, FileData, LogRecord, TransformedLogRecord, Violation } from '../../models/engine-types';
import { Collect, CollectAndInform, CoreGDPRSignature, Delete, DsDelete, DsObject, DsRestrict, LegalGrounds, Processing, ShareWith } from '../../models/gdpr-signatures';
import { Alert, AutoComplete, Button, Form, FormGroup, Icon, IconButton, Input, Panel, SelectPicker, Table } from 'rsuite';
import { RiErrorWarningFill } from 'react-icons/ri'
import { FalsePositiveInfo } from '../../../../common/ClientServerInterface';
import { downloadCSV, ERROR_TIME, falsePositiveViolationEquality, formatDate, intersection, removeMultilines } from '../../utils/utils';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Repo } from '../../repositories/Repo';
import { EmptyFile, IFileData } from '../../models/file';
import { fromJSON } from '../../models/shape';
import { parseRecordsWithoutShape, validateAndParseIFileData } from '../../engine/inputConverter';
import { convertToGdprLog } from '../../engine/engine';
import MyModal from '../helperComponents/MyModal';

/* Shows a summary of the analysis */

export interface FileMapping{
    mapping: ActionsToGdpr
    fileName: string
}

function getCases(recs:TransformedLogRecord[]):Set<string>{
    const cases:Set<string> = new Set()
    for(const r of recs){
        if(r.dsid) cases.add(r.dsid)
    }
    return cases
}

type TableData = {
    name: string|undefined
    article: string|undefined
    violated: Set<string>
    cases: number|undefined
}

// Table to show rule information - violated cases and so on
const ruleTable = (data:TableData[]) => {
    return (
        <Table
            rowKey='id'
            bordered
            cellBordered
            data={data}
            style={{marginTop: 20}}
            height={data.length*50+40}
            headerHeight={40}
            rowHeight={50}
        >
            <Table.Column width={400}>
                <Table.HeaderCell>Rule</Table.HeaderCell>
                <Table.Cell>{(rowData:TableData) => rowData.name + ' - ' + rowData.article}</Table.Cell>
            </Table.Column>

            <Table.Column width={150} align='center'>
                <Table.HeaderCell>Passed for all cases</Table.HeaderCell>
                <Table.Cell>{(rowData:TableData) => rowData.cases ? !rowData.violated.size ? <Icon style={{color: 'green'}} icon='check'/> : <Icon style={{color: 'red'}} icon='warning'/> : <RiErrorWarningFill style={{color: '#ebd900'}}/>}</Table.Cell>
            </Table.Column>

            <Table.Column width={150} align='center'>
                <Table.HeaderCell>Applicable cases</Table.HeaderCell>
                <Table.Cell dataKey="cases" />
            </Table.Column>

            <Table.Column width={150} align='center'>
                <Table.HeaderCell>Cases with violations</Table.HeaderCell>
                <Table.Cell>{(rowData:TableData) => rowData.violated.size}</Table.Cell>
            </Table.Column>
        </Table>
    )
}

interface DataInfo{
    data: string
    dataType: 'Almindelig' | 'Fortrolig' | 'Følsom'
    subjectTypes: string[]
}

interface FormData{
    processPurpose: string
    dataResponsibility: string
    dataTypes: Map<string, string[]>
    subjectTypes: Map<string, string[]>
    legalBasis: Map<string, string[]>
    providers: Map<string, string[]>
    shareInfo: Map<string, string[]>
    dataInfo: DataInfo[]
}

export interface AnalysisOverviewProps {
    timestamp: string
    rules: GDPRRule[]
    analysisParameters: AnalysisParameters
    records: TransformedLogRecord[]
    mappings:FileMapping[]
    printableVersion?: boolean
    falsePositives: FalsePositiveInfo[]
    showPrintableSummary: (b:boolean) => void
    fromLink?: boolean
}

interface AnalysisOverviewState{
    showForm: boolean
    formData: FormData
}

class AnalysisOverview extends Component<AnalysisOverviewProps, AnalysisOverviewState> {

    constructor(props:AnalysisOverviewProps){
        super(props)
        this.state = {
            showForm: false,
            formData: {
                processPurpose: '',
                dataResponsibility: '',
                dataTypes: new Map(),
                subjectTypes: new Map(),
                legalBasis: new Map(),
                providers: new Map(),
                shareInfo: new Map(),
                dataInfo: []
            }
        }
    }

    violatedCases(rule:GDPRRule, cases:Set<string>):Set<string>{
        if(!rule){return new Set()}
        const violations = this.filterFP(rule.getViolations(this.props.analysisParameters), rule.name)
        const violatedCases:Set<string> = new Set()
        for(const v of violations){
            violatedCases.add(v[0].e.dsid)
        }
    
        return intersection(violatedCases, cases)
    }

    filterFP(violations:Violation[], rule:string){
        return violations.filter(v => !this.props.falsePositives.find(fp => falsePositiveViolationEquality(fp, v, rule)))
    }

    ruleInfo(rule:GDPRRule|undefined):string{
        return rule ? `${rule.description} - ${rule.article}` : 'Unidentified rule'
    }

    onShow(){
        const recordsByData:Map<string, TransformedLogRecord[]> = new Map()
        for(const r of this.props.records){            
            if('data' in r && r.data){
                if(recordsByData.has(r.data)){
                    recordsByData.get(r.data)!.push(r)
                } else{
                    recordsByData.set(r.data, [r])
                }
            }
        }
        Array.from(recordsByData.keys()).forEach(d => {
            const records = recordsByData.get(d)

            const collects:(Collect|CollectAndInform)[] = records!.filter(dd => dd.name === 'Collect' || dd.name === 'Collect And Inform') as (Collect|CollectAndInform)[]
            const providers = new Set(collects.map(dd => dd.info))

            const legalGrounds:LegalGrounds[] = records!.filter(dd => dd.name === 'Legal Grounds')as (LegalGrounds)[]
            const legalBasis = new Set(legalGrounds.map(dd => dd.info))

            const shares:ShareWith[] = records!.filter(dd => dd.name === 'Share With')as (ShareWith)[]
            const shareInfos = new Set(shares.map(dd => dd.info))

            providers.forEach(p => {
                if(this.state.formData.providers.has(p)) this.state.formData.providers.get(p)!.push(d)
                else this.state.formData.providers.set(p, [d])
            })

            legalBasis.forEach(lb => {
                if(this.state.formData.legalBasis.has(lb)) this.state.formData.legalBasis.get(lb)!.push(d)
                else this.state.formData.legalBasis.set(lb, [d])
            })

            shareInfos.forEach(lb => {
                if(this.state.formData.legalBasis.has(lb)) this.state.formData.legalBasis.get(lb)!.push(d)
                else this.state.formData.legalBasis.set(lb, [d])
            })
        })
        const personData = Array.from(recordsByData.keys())
        this.setState({formData: {...this.state.formData, dataInfo: personData.map(pd => ({data: pd, dataType: 'Almindelig', subjectTypes: []}))}})
    }

    render(){ 
        
        const recordsByData:Map<string, TransformedLogRecord[]> = new Map()
        const recordsByCase:Map<string, TransformedLogRecord[]> = new Map()
        const recordsByType:Map<string, TransformedLogRecord[]> = new Map()
        for(const r of this.props.records){
            if(r.name){
                if(recordsByType.has(r.name)){
                    recordsByType.get(r.name)!.push(r)
                } else{
                    recordsByType.set(r.name, [r])
                }
            }
            
            if(r.dsid){
                if(recordsByCase.has(r.dsid)){
                    recordsByCase.get(r.dsid)!.push(r)
                } else{
                    recordsByCase.set(r.dsid, [r])
                }
            }
            
            if('data' in r && r.data){
                if(recordsByData.has(r.data)){
                    recordsByData.get(r.data)!.push(r)
                } else{
                    recordsByData.set(r.data, [r])
                }
            }
        }
        const numCases = recordsByCase.size

        const dataSignaturesByData:Map<string, Map<string, number>> = new Map()
        for(const r of this.props.records){
            if('data' in r){
                if(dataSignaturesByData.has(r.data)){
                    const map = dataSignaturesByData.get(r.data)!
                    if(map.has(r.name)){
                        map.set(r.name, map.get(r.name)!+1)
                    } else {
                        map.set(r.name, 1)
                    }
                } else{
                    const newMap = new Map()
                    newMap.set(r.name, 1)
                    dataSignaturesByData.set(r.data, newMap)
                }
            }
        }


        const collects =        recordsByType.get('Collect') as (Collect&TransformedLogRecord)[]|undefined
        const collectAndInforms = recordsByType.get('Collect And Inform') as (CollectAndInform&TransformedLogRecord)[]|undefined
        const processings =     recordsByType.get('Processing') as (Processing&TransformedLogRecord)[]|undefined
        const accessRequests =  recordsByType.get('Ds Access Request')
        const deleteRequests =  recordsByType.get('Ds Delete')  as (DsDelete&TransformedLogRecord)[]|undefined
        const deletes =         recordsByType.get('Delete')  as (Delete&TransformedLogRecord)[]|undefined
        const objects =         recordsByType.get('Ds Object')  as (DsObject&TransformedLogRecord)[]|undefined
        const restricts =       recordsByType.get('Ds Restric')  as (DsRestrict&TransformedLogRecord)[]|undefined

        const collectFound =        collects && collects.length > 0
        const processingFound =     processings && processings.length > 0
        const accessRequestFound =  accessRequests && accessRequests.length > 0
        const deleteRequestFound =  deleteRequests && deleteRequests.length > 0
        const deleteFound =         deletes && deletes.length > 0
        const objectFound =         objects && objects.length > 0
        const restrictFound =       restricts && restricts.length > 0

        const casesWithCollect:Set<string> =            collects ? getCases(collects) : new Set()
        const casesWithProcessing:Set<string> =         processings ? getCases(processings) : new Set()
        const casesWithAccessRequest:Set<string> =      accessRequests ? getCases(accessRequests) : new Set()
        const casesWithDeleteRequest:Set<string> =      deleteRequests ? getCases(deleteRequests) : new Set()
        const casesWithDelete:Set<string> =             deletes ? getCases(deletes) : new Set()
        const casesWithRestrict:Set<string> =           restricts ? getCases(restricts) : new Set()
        const casesWithObject:Set<string> =             objects ? getCases(objects) : new Set()
        const casesWithProcAndDelete:Set<string> =      intersection(casesWithProcessing, casesWithDelete)
        const casesWithProcAndRestrict:Set<string> =    intersection(casesWithProcessing, casesWithRestrict)
        const casesWithProcAndObject:Set<string> =      intersection(casesWithProcessing, casesWithObject)

        const lp =      getRuleFromList(this.props.rules, 'Lawful processing and consent')
        const ioc =     getRuleFromList(this.props.rules, 'Info on collect')
        const dm =      getRuleFromList(this.props.rules, 'Data minimisation')
        const sl =      getRuleFromList(this.props.rules, 'Storage limitation')
        const rta =     getRuleFromList(this.props.rules, 'Right to access')
        const rte11 =   getRuleFromList(this.props.rules, 'Right to erasure 1.1')
        const rte12 =   getRuleFromList(this.props.rules, 'Right to erasure 1.2')
        const rte2 =    getRuleFromList(this.props.rules, 'Right to erasure 2')
        const rto =     getRuleFromList(this.props.rules, 'Right to object')
        const rtr =     getRuleFromList(this.props.rules, 'Right to restriction')

        const startOfCaseMappings:(CoreGDPRSignature&{data: string})[] = []
        const collectAndInformMappings:CoreGDPRSignature[] = []
        this.props.mappings.forEach(m => {
            for(const a in m.mapping){
                for(const g of m.mapping[a].gdprActions){
                    if(g.startOfCase){
                        if('data' in g.signature) startOfCaseMappings.push(g.signature)
                    }
                    if(g.signature.name === 'Collect And Inform'){
                        collectAndInformMappings.push(g.signature)
                    }
                }
            }
        })

        const totalActions:string[] = []
        this.props.mappings.forEach(m => {
            for(const a in m.mapping){
                for(const g of m.mapping[a].gdprActions){
                    totalActions.push(g.signature.name)
                }
            }
        })

        const collectMapped = totalActions.includes('Collect') || totalActions.includes('Collect And Inform')
        const processingMapped = totalActions.includes('Processing')
        const deleteRequestMapped = totalActions.includes('Ds Delete')
        const accessRequestMapped = totalActions.includes('Ds Access Request')
        const deleteMapped = totalActions.includes('Delete')
        const objectionMapped = totalActions.includes('Ds Object')
        const restrictionMapped = totalActions.includes('Ds Restrict')

        const ruleInfoByData = (data_type:string):TableData[] => {
            return [
                {
                    name: lp?.name,
                    article: lp?.article,
                    cases:  collects ? getCases(collects.filter(r => r.data === data_type)).size : 0,
                    violated: lp ? new Set(this.filterFP(lp.getViolations(this.props.analysisParameters).filter(v => 'data' in v[0].e && v[0].e.data === data_type), lp.name).map(v => (v[0].e.dsid))) : new Set()
                },
                {
                    name: ioc?.name,
                    article: ioc?.article,
                    cases:  collects ? getCases(collects.filter(r => r.data === data_type)).size : 0,
                    violated: ioc ? new Set(this.filterFP(ioc.getViolations(this.props.analysisParameters).filter(v => 'data' in v[0].e && v[0].e.data === data_type), ioc.name).map(v => (v[0].e.dsid))) : new Set()
                },
                {
                    name: dm?.name,
                    article: dm?.article,
                    cases:  collects ? getCases(collects.filter(r => r.data === data_type)).size : 0,
                    violated: dm ? new Set(this.filterFP(dm.getViolations(this.props.analysisParameters).filter(v => 'data' in v[0].e && v[0].e.data === data_type), dm.name).map(v => (v[0].e.dsid))) : new Set()
                },
                {
                    name: sl?.name,
                    article: sl?.article,
                    cases: collects ? getCases(collects.filter(r => r.data === data_type)).size : 0,
                    violated: sl ? new Set(this.filterFP(sl.getViolations(this.props.analysisParameters).filter(v => 'data' in v[0].e && v[0].e.data === data_type), sl.name).map(v => (v[0].e.dsid))) : new Set()
                },
                {
                    name: rte12?.name,
                    article: rte12?.article,
                    cases:  processings && deletes ? intersection(getCases(processings.filter(r => r.data === data_type)), getCases(deletes.filter(r => r.data === data_type))).size : 0,
                    violated: rte12 ? new Set(this.filterFP(rte12.getViolations(this.props.analysisParameters).filter(v => 'data' in v[0].e && v[0].e.data === data_type), rte12.name).map(v => (v[0].e.dsid))) : new Set()
                },
                {
                    name: rtr?.name,
                    article: rtr?.article,
                    cases: processings && restricts ? intersection(getCases(processings.filter(r => r.data === data_type)), getCases(restricts.filter(r => r.data === data_type))).size : 0,
                    violated: rtr ? new Set(this.filterFP(rtr.getViolations(this.props.analysisParameters).filter(v => 'data' in v[0].e && v[0].e.data === data_type), rtr.name).map(v => (v[0].e.dsid))) : new Set()
                },
                {
                    name: rto?.name,
                    article: rto?.article,
                    cases:  processings && objects ? intersection(getCases(processings.filter(r => r.data === data_type)), getCases(objects.filter(r => r.data === data_type))).size : 0,
                    violated: rto ? new Set(this.filterFP(rto.getViolations(this.props.analysisParameters).filter(v => 'data' in v[0].e && v[0].e.data === data_type), rto.name).map(v => (v[0].e.dsid))) : new Set()
                },
                {
                    name: rte11?.name,
                    article: rte11?.article,
                    cases:  deleteRequests ? getCases(deleteRequests.filter(r => r.data === data_type)).size : 0,
                    violated: rte11 ? new Set(this.filterFP(rte11.getViolations(this.props.analysisParameters).filter(v => 'data' in v[0].e && v[0].e.data === data_type), rte11.name).map(v => (v[0].e.dsid))) : new Set()
                },
                {
                    name: rte2?.name,
                    article: rte2?.article,
                    cases:  deleteRequests ? getCases(deleteRequests.filter(r => r.data === data_type)).size : 0,
                    violated: rte2 ? new Set(this.filterFP(rte2.getViolations(this.props.analysisParameters).filter(v => 'data' in v[0].e && v[0].e.data === data_type), rte2.name).map(v => (v[0].e.dsid))) : new Set()
                }
            ]
        }

        const ruleInfo:TableData[] = 
            [
                {
                    name: lp?.name,
                    article: lp?.article,
                    cases: casesWithCollect.size,
                    violated:  this.violatedCases(lp!, casesWithCollect)
                },
                {
                    name: dm?.name,
                    article: dm?.article,
                    cases: casesWithCollect.size,
                    violated:  this.violatedCases(dm!, casesWithCollect)
                },
                {
                    name: sl?.name,
                    article: sl?.article,
                    cases: casesWithCollect.size,
                    violated:  this.violatedCases(sl!, casesWithCollect)
                },
                {
                    name: ioc?.name,
                    article: ioc?.article,
                    cases: casesWithCollect.size,
                    violated:  this.violatedCases(ioc!, casesWithCollect)
                },
                {
                    name: rte12?.name,
                    article: rte12?.article,
                    cases: casesWithProcAndDelete.size,
                    violated:  this.violatedCases(rte12!, casesWithProcAndDelete)
                },
                {
                    name: rtr?.name,
                    article: rtr?.article,
                    cases: casesWithProcAndRestrict.size,
                    violated:  this.violatedCases(rtr!, casesWithProcAndRestrict)
                },
                {
                    name: rto?.name,
                    article: rto?.article,
                    cases: casesWithProcAndObject.size,
                    violated:  this.violatedCases(rto!, casesWithProcAndObject)
                },
                {
                    name: rte11?.name,
                    article: rte11?.article,
                    cases: casesWithDeleteRequest.size,
                    violated:  this.violatedCases(rte11!, casesWithDeleteRequest)
                },
                {
                    name: rte2?.name,
                    article: rte2?.article,
                    cases: casesWithDeleteRequest.size,
                    violated:  this.violatedCases(rte2!, casesWithDeleteRequest)
                },
                {
                    name: rta?.name,
                    article: rta?.article,
                    cases: casesWithAccessRequest.size,
                    violated:  this.violatedCases(rta!, casesWithAccessRequest)
                }
            ]

        type fpType = {v: Violation, comment: string}
        const falsePositiveViolations_ = this.props.falsePositives.flatMap(fp => 
            getRuleFromList(this.props.rules, fp.rule) ? 
            getRuleFromList(this.props.rules, fp.rule)!.getViolations(this.props.analysisParameters).map(v => falsePositiveViolationEquality(fp, v, fp.rule) ? {v, comment: fp.comment} as fpType : undefined) 
            : []
        )

        const fpSet = new Set()
        const falsePositiveViolations = []
        for(const fp of falsePositiveViolations_.filter(v => v)){
            if(!fpSet.has(fp!.v[0].desc)){
                falsePositiveViolations.push(fp!)
                fpSet.add(fp!.v[0].desc)
            }
        }

        const uniqueDataCOI = new Set(collectAndInformMappings.map(sig => (sig as CollectAndInform).data))
        const uniqueDataSOC = new Set(startOfCaseMappings.map(sig => 'data' in sig ? sig.data : ''))

        const numViolations = totalViolations(this.props.rules, this.props.analysisParameters)
        const unmarkedViolations = numViolations - falsePositiveViolations.length

        const notPresentInMapping = "not present in the used action mappings."

        const panelStyle = {marginTop: 20}

        // const personData = Array.from(recordsByData.keys())
        // const aboutWho = Array.from(recordsByCase.keys())
        // const whereIsDataProcessed = Array.from(new Set(recordsByType.get('Processing')?.flatMap(r => r.actions)))
        const notifiedAboutCollect = rte2 ? rte2.getViolations().length : 0
        const deletedData = sl ? sl.getViolations().length : 0 //All collected data in the following categories was eventually deleted: 
        const shareWith:Map<string, Map<string, (ShareWith & LogRecord)[]>> = (getRuleFromList(this.props.rules, 'Right to erasure 2') as RightToErasure2).getSharedData()
        const legalGrounds:LegalGrounds[] = recordsByType.get('Legal Grounds')?.flatMap(r => r) as LegalGrounds[]
        // const consent = new Set(recordsByType.get('Consent')?.flatMap(r => r.actions))

        // function makeCSV(){
        //     let rows:string[][] = [["Collected data", "Cases", "Notify on collect violations", "Storage limitation violations", "Processings",
        //                         "Shared data", "External processors"]]

        //     let i = 0
        //     while(i < 100) {
        //         rows.push(['', '', '', '', '', ''])
        //         i++
        //     }

        //     //make more rows
        //     personData.forEach((d,i) => rows[i+1][0] = d)
        //     aboutWho.forEach((d,i) => rows[i+1][1] = d)
        //     rows[1][2] = notifiedAboutCollect+""
        //     rows[1][3] = deletedData+""
        //     whereIsDataProcessed.forEach((d,i) => rows[i+1][4] = d)
        //     Array.from(shareWith.keys()).forEach((d,i) => rows[i+1][5] = d)
        //     Array.from(new Set(recordsByType.get('Share With')?.map(r => (r as ShareWith).processorid))).forEach((d,i) => rows[i+1][6] = d)
        
        //     let csvContent = "data:text/csv;charset=utf-8,"
        
        //     rows.forEach(function(rowArray) {
        //         let row = rowArray.join(",")
        //         csvContent += row + "\r\n"
        //     });

        //     downloadCSV(csvContent, 'csv.csv')
        // }

        function makeCSV2(formData:FormData){
            const subjects:Map<string, string[]> = new Map()
            formData.dataInfo.forEach(di => di.subjectTypes.forEach(st => {
                if(subjects.has(st)) subjects.get(st)!.push(di.data)
                else subjects.set(st, [di.data])
            }))

            const legalBasisAlmindelig:Map<string, string[]> = new Map()
            const legalBasisFortrolig:Map<string, string[]> = new Map()
            const legalBasisFoelsom:Map<string, string[]> = new Map()
            legalGrounds.forEach(lg => {
                if(lg.info){
                    if(formData.dataInfo.filter(d => d.dataType === 'Almindelig' && d.data === lg.data).length > 0){
                        if(legalBasisAlmindelig.has(lg.info)) legalBasisAlmindelig.get(lg.info)!.push(lg.data)
                        else legalBasisAlmindelig.set(lg.info, [lg.data])
                    }

                    if(formData.dataInfo.filter(d => d.dataType === 'Fortrolig' && d.data === lg.data).length > 0){
                        if(legalBasisFortrolig.has(lg.info)) legalBasisFortrolig.get(lg.info)!.push(lg.data)
                        else legalBasisFortrolig.set(lg.info, [lg.data])
                    }

                    if(formData.dataInfo.filter(d => d.dataType === 'Følsom' && d.data === lg.data).length > 0){
                        if(legalBasisFoelsom.has(lg.info)) legalBasisFoelsom.get(lg.info)!.push(lg.data)
                        else legalBasisFoelsom.set(lg.info, [lg.data])
                    }
                }
            })

            const providers:Map<string, string[]> = new Map()
            const allCollects:((Collect|CollectAndInform)&LogRecord)[] = []
            collects?.forEach(c => allCollects.push(c))
            collectAndInforms?.forEach(c => allCollects.push(c))
            allCollects.forEach(c => {
                if(c.info){
                    if(providers.has(c.info)) providers.get(c.info)!.push(c.data)
                    else providers.set(c.info, [c.data])
                }
            })

            const c10 = 'Almindelig: ' + formData.dataInfo.filter(d => d.dataType === 'Almindelig').map(d => d.data).join(' ')
            const c11 = 'Fortrolig: ' + formData.dataInfo.filter(d => d.dataType === 'Fortrolig').map(d => d.data).join(' ')
            const c12 = 'Følsom: ' + formData.dataInfo.filter(d => d.dataType === 'Følsom').map(d => d.data).join(' ')
            const d10 = formData.processPurpose
            const e10 = formData.dataResponsibility
            const f10 = Array.from(subjects.keys()).map(st => `${st}: ${subjects.get(st)?.join(' ')}`).join( ' --- ')
            const h10 = Array.from(legalBasisAlmindelig.keys()).map(lb => `${lb}: ${Array.from(new Set(legalBasisAlmindelig.get(lb))).join(' ')}`).join( ' --- ')
            const h11 = Array.from(legalBasisFortrolig.keys()).map(lb => `${lb}: ${Array.from(new Set(legalBasisFortrolig.get(lb))).join(' ')}`).join( ' --- ')
            const h12 = Array.from(legalBasisFoelsom.keys()).map(lb => `${lb}: ${Array.from(new Set(legalBasisFoelsom.get(lb))).join(' ')}`).join( ' --- ')
            const i10 = Array.from(providers.keys()).map(p => `${p}: ${Array.from(new Set(providers.get(p))).join(' ')}`).join( ' --- ')
            const j10 = notifiedAboutCollect === 0 ? "In all cases of the analysis there is a notification after each collect" : `There are ${notifiedAboutCollect} violations of no notification on collect in the analysis` //hvilke data punkter?
            const k10 = deletedData === 0 ? "All data included in the analysis was eventually deleted" : `There are ${deletedData} violations of data not deleted in the analysis` //hvilke data punkter?
            const l10 = 'Almindelig: ' + Array.from(shareWith.keys()).filter(r => formData.dataInfo.filter(d => d.dataType === 'Almindelig' && d.data === r).length > 0).join(' ')
            const l11 = 'Fortrolig: ' + Array.from(shareWith.keys()).filter(r => formData.dataInfo.filter(d => d.dataType === 'Fortrolig' && d.data === r).length > 0).join(' ')
            const l12 = 'Følsom: ' + Array.from(shareWith.keys()).filter(r => formData.dataInfo.filter(d => d.dataType === 'Følsom' && d.data === r).length > 0).join(' ')
            const m10 = Array.from(new Set(recordsByType.get('Share With')?.filter(r => 'data' in r && formData.dataInfo.filter(d => d.dataType === 'Almindelig' && d.data === r.data).length > 0).map(r => (r as ShareWith).processorid))).join(' ')
            const m11 = Array.from(new Set(recordsByType.get('Share With')?.filter(r => 'data' in r && formData.dataInfo.filter(d => d.dataType === 'Fortrolig' && d.data === r.data).length > 0).map(r => (r as ShareWith).processorid))).join(' ')
            const m12 = Array.from(new Set(recordsByType.get('Share With')?.filter(r => 'data' in r && formData.dataInfo.filter(d => d.dataType === 'Følsom' && d.data === r.data).length > 0).map(r => (r as ShareWith).processorid))).join(' ')
            const n10 = Array.from(new Set(recordsByType.get('Share With')?.filter(r => 'data' in r && formData.dataInfo.filter(d => d.dataType === 'Almindelig' && d.data === r.data).length > 0).map(r => `${(r as ShareWith).processorid}:  ${(r as ShareWith).info ? (r as ShareWith).info : 'No info'}`))).join(' ')
            const n11 = Array.from(new Set(recordsByType.get('Share With')?.filter(r => 'data' in r && formData.dataInfo.filter(d => d.dataType === 'Fortrolig' && d.data === r.data).length > 0).map(r => `${(r as ShareWith).processorid}:  ${(r as ShareWith).info ? (r as ShareWith).info : 'No info'}`))).join(' ')
            const n12 = Array.from(new Set(recordsByType.get('Share With')?.filter(r => 'data' in r && formData.dataInfo.filter(d => d.dataType === 'Følsom' && d.data === r.data).length > 0).map(r => `${(r as ShareWith).processorid}:  ${(r as ShareWith).info ? (r as ShareWith).info : 'No info'}`))).join(' ')

            let rows:string[][] = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                ["", "", c10, d10, e10, f10, c10, h10, i10, j10, k10, l10, m10, n10],
                ["", "", c11, "", "", "", c11, h11, "", "", "", l11, m11, n11],
                ["", "", c12, "", "", "", c12, h12, "", "", "", l12, m12, n12],
            ]
        
            let csvContent = "data:text/csv;charset=utf-8,"
        
            rows.forEach(function(rowArray) {
                let row = rowArray.join(",")
                csvContent += row + "\r\n"
            });

            downloadCSV(csvContent, 'csv.csv')
        }

        return (
            
            <div style={{fontSize: 16, width: '95%'}}>

            <div style={{display: 'flex'}}>
                <Button onClick={() => this.setState({showForm: true})} style={{marginLeft: 'auto'}}>Vis Kortlægningsværktøj</Button>
                {/* <Button onClick={makeCSV} style={{marginLeft: 10}}>Download Info</Button> */}
                {/* <Button onClick={() => makeCSV2(this.state.formData)} style={{marginLeft: 10}}>Download "Kortlægningsværktøj"</Button> */}
            </div>
                
            {!this.props.fromLink ? <Link to='/analysis' onClick={() => this.props.showPrintableSummary(false)}><Button>Back</Button></Link> : ''}

            {this.props.printableVersion ? <div style={{textAlign: 'center', marginBottom: 50, marginTop: 50}}><h3>Analysis performed on {this.props.timestamp}</h3></div> : ''}

            {/* Analysis overview */}
            <Panel bordered id={"overview"}>
                <p style={{color: unmarkedViolations ? 'red' : 'green'}}><b>
                    { numViolations ? 
                        `The analysis found ${numViolations} possible GDPR violation${numViolations === 1 ? '' : 's'}.` 
                        : 'The analysis did not find any violations.'
                    }
                </b></p>

                {falsePositiveViolations.length > 0 ? 
                    <p><b>{`${falsePositiveViolations.length} violations were marked as false positives.`}</b></p> 
                    : ''
                }

                <p style={{marginTop: 15}}><b>
                    {`The analysis processed a total of ${numCases} case${numCases === 1 ? '' : 's'} with the following types of data:`}
                </b></p>
                <ul className="dashed">
                    {Array.from(recordsByData.keys()).map(d => (
                        <li> {d}</li>
                    ))}
                </ul>

                <p style={{marginTop: 15}}><b>{'Processed the following files:'}</b></p>
                <ul className="dashed">
                    {this.props.mappings.map(m => (
                        <li> {m.fileName}</li>
                    ))}
                </ul>
            </Panel>

            {/* List of rules that were checked */}
            {
                collectFound || processingFound || deleteRequestFound || accessRequestFound ?
                <Panel bordered style={panelStyle} id={"checked_rules"}>
                    <p><b>{`The following GDPR rules were checked:`}</b></p>                            
                    <ul className="dashed">
                        {
                            collectFound ?
                            <>
                                <li>{`${this.ruleInfo(lp)}.`}</li>
                                <li>{`${this.ruleInfo(dm)}.`}</li>
                                <li>{`${this.ruleInfo(sl)}.`}</li>
                                <li>{`${this.ruleInfo(ioc)}.`}</li>
                            </>
                            : ''
                        }
                        {
                            processingFound && deleteFound ?
                            <li>{`${this.ruleInfo(rte12)}.`}</li>
                            : ''
                        }
                        {
                            processingFound && restrictFound ?
                            <li>{`${this.ruleInfo(rtr)}.`}</li>
                            : ''
                        }
                        {
                            processingFound && objectFound ?
                            <li>{`${this.ruleInfo(rto)}.`}</li>
                            : ''
                        }
                        {
                            deleteRequestFound ?
                            <>
                                <li>{`${this.ruleInfo(rte11)}.`}</li>
                                <li>{`${this.ruleInfo(rte2)}.`}</li>
                            </>
                            : ''
                        }
                        {accessRequestFound ?
                            <li>{`${this.ruleInfo(rta)}.`}</li>
                            :
                            ''
                        }


                    </ul>

                    {ruleTable(ruleInfo.filter(r => r.cases))}

                </Panel>
                :
                ''
            }

            {/* List of rules unable to be checked - actions not in mappings */}
            {
                !collectMapped || !processingMapped || !deleteRequestMapped || !accessRequestMapped?
                <Panel bordered style={panelStyle} id={"unmapped_rules"}>
                    <p><b>{`The following rules were not checked due to actions not being present in the action mappings:`}</b></p>                            
                    <ul className="dashed">
                        {
                            !collectMapped ?
                            <>
                            <li> {`${this.ruleInfo(lp)} - collect ${notPresentInMapping}`}</li>
                            <li> {`${this.ruleInfo(dm)} - collect ${notPresentInMapping}`}</li>
                            <li> {`${this.ruleInfo(sl)} - collect ${notPresentInMapping}`}</li>
                            <li> {`${this.ruleInfo(ioc)} - collect ${notPresentInMapping}`}</li>                                
                            </>
                            : ''
                        }
                        {!processingFound && processingMapped ?
                            <>
                            <li> {`${this.ruleInfo(rte12)} - processing ${notPresentInMapping}`}</li> 
                            <li> {`${this.ruleInfo(rtr)} - processing ${notPresentInMapping}`}</li>  
                            <li> {`${this.ruleInfo(rto)} - processing ${notPresentInMapping}`}</li>  
                            </>
                            :
                            <>
                                {!processingMapped || !deleteMapped ?
                                    <li> {`${this.ruleInfo(rte12)} - delete ${notPresentInMapping}`}</li> 
                                    : ''
                                }
                                {!processingMapped || !restrictionMapped ?
                                    <li> {`${this.ruleInfo(rtr)} - restriction ${notPresentInMapping}`}</li>  
                                    : ''
                                }
                                {!processingMapped || !objectionMapped ?
                                    <li> {`${this.ruleInfo(rto)} - object ${notPresentInMapping}`}</li>  
                                    : ''
                                }
                            </>
                        }
                        {!deleteRequestMapped ?
                            <>
                            <li> {`${this.ruleInfo(rte11)} - deletion request ${notPresentInMapping}`}</li>                                
                            <li> {`${this.ruleInfo(rte2)} - deletion request ${notPresentInMapping}`}</li>
                            </>
                            : ''
                        }
                        {!accessRequestMapped ?
                            <li> {`${this.ruleInfo(rta)} - access request ${notPresentInMapping}`}</li>                                
                            : ''
                        }
                    </ul>
                </Panel>
                :
                ''
            }

            {/* List of rules not checked due to actions not found in log */}
            {
                (!collectFound && collectMapped) && 
                (!processingFound && processingMapped) && 
                (!deleteRequestFound && deleteRequestMapped) && 
                (!accessRequestFound && accessRequestMapped) ?

                <Panel bordered style={panelStyle} id={"missing_rules"}>
                    <p><b>{`The following rules were not checked due to missing actions:`}</b></p>                            
                    <ul className="dashed">
                        {!collectFound && collectMapped ?
                            <>
                            <li> {`${this.ruleInfo(lp)} - did not find any collects of data.`}</li>
                            <li> {`${this.ruleInfo(dm)} - did not find any collects of data.`}</li>
                            <li> {`${this.ruleInfo(sl)} - did not find any collects of data.`}</li>
                            <li> {`${this.ruleInfo(ioc)} - did not find any collects of data.`}</li>                                
                            </>
                            :
                            ''
                        }
                        {!processingFound && processingMapped ?
                            <>
                            <li> {`${this.ruleInfo(rte12)} - did not find any processings of data.`}</li>                                
                            <li> {`${this.ruleInfo(rtr)} - did not find any processings of data.`}</li>                                
                            <li> {`${this.ruleInfo(rto)} - did not find any processings of data.`}</li>
                            </>
                            :
                            <>
                                {!deleteFound && deleteMapped ?
                                    <li> {`${this.ruleInfo(rte12)} - did not find any deletes.`}</li> 
                                    : ''
                                }
                                {!restrictFound && restrictionMapped ?
                                    <li> {`${this.ruleInfo(rtr)} - did not find any restricts.`}</li>  
                                    : ''
                                }
                                {!objectFound && objectionMapped ?
                                    <li> {`${this.ruleInfo(rto)} - did not find any objects.`}</li>  
                                    : ''
                                }
                            </>
                        }
                        {!deleteRequestFound && deleteRequestMapped ?
                            <>
                            <li> {`${this.ruleInfo(rte11)} - did not find any deletion requests.`}</li>                                
                            <li> {`${this.ruleInfo(rte2)} - did not find any deletion requests.`}</li>
                            </>
                            :
                            ''
                        }
                        {!accessRequestFound && accessRequestMapped ?
                            <>
                            <li> {`${this.ruleInfo(rta)} - did not find any access requests.`}</li>                                
                            </>
                            :
                            ''
                        }
                    </ul>
                </Panel>
                :
                ''
            }

            {/* Panel for each data type */}
            {Array.from(recordsByData.keys()).map(d => 
                {
                    const violations = new Set(ruleInfoByData(d).flatMap(r => Array.from(r.violated))).size
                    return(
                    <Panel 
                        collapsible
                        defaultExpanded={this.props.printableVersion}
                        bordered 
                        style={panelStyle} 
                        header={
                            <div style={{height: 20}}>
                                <div style={{float: 'left', marginTop: -1}}>
                                    {violations ? <Icon icon='warning' style={{color: 'red'}}/> : <Icon style={{color: 'green'}} icon='check'/>}
                                </div>

                                <div style={{float: 'left'}}>
                                    <text style={{marginLeft: 10, marginTop: 10}}>
                                        {`${d} - processed in ${getCases(recordsByData.get(d)!).size} of the 31 cases. `}
                                    </text>
                                </div>

                                <div style={{float: 'left', marginLeft: 5}}>
                                    {violations ? 
                                        <text style={{color: 'red'}}>
                                            {` Found violations in ${violations} cases.`}
                                        </text> 
                                        : ''
                                    }
                                </div>
                            </div>
                        }
                    >
                        {ruleTable(ruleInfoByData(d))}
                        
                        <Table
                            rowKey='id'
                            bordered
                            cellBordered
                            autoHeight
                            data={Array.from(dataSignaturesByData.get(d)!.keys()).map(sig => (
                                {
                                    label: sig,
                                    count: dataSignaturesByData.get(d)!.get(sig),
                                    cases: getCases(recordsByData.get(d)!.filter(r => r.name === sig)).size
                                }
                            ))}
                            style={{marginTop: 20}}
                        >
                            <Table.Column width={400}>
                                <Table.HeaderCell>Distribution of actions on {d}</Table.HeaderCell>
                                <Table.Cell dataKey="label" />
                            </Table.Column>


                            <Table.Column width={150} align='center'>
                                <Table.HeaderCell>Total Count</Table.HeaderCell>
                                <Table.Cell dataKey="count" />
                            </Table.Column>

                            <Table.Column width={150} align='center'>
                                    <Table.HeaderCell>Number of cases</Table.HeaderCell>
                                    <Table.Cell dataKey="cases" />
                            </Table.Column>

                        </Table>
                    </Panel>
                    )
                }
            )}

            {/* List of analysis parameters */}
            <Panel bordered style={panelStyle}>
                <p><b>{'The analysis was run with the following parameters:'}</b></p>
                <ul className="dashed">
                    {this.props.analysisParameters.dateOption === 'Date' ? 
                        <li> {`The date of the analysis is set to ${this.props.analysisParameters.date}. Thus, unmet deletion and access requests in the logs will be considered overdue based on this date.`}</li> 
                        : ''
                    }
                    {this.props.analysisParameters.dateOption === 'Date' ? 
                        <li> {`Deletion and access requests are shown are considered overdue after 30 days.`}</li> 
                        : ''
                    }
                    {this.props.analysisParameters.dateOption === 'Ignore' ? 
                        <li> {`Unmet deletion and access requests are not considered violations.`}</li> 
                        : ''
                    }
                    {this.props.analysisParameters.dateOption === 'None' ? 
                        <li> {`Unmet deletion and access requests are considered violations regardless of the date of the requests.`}</li> 
                        : ''
                    }
                </ul>
            </Panel>

            {/* List of violations marked as false positives */}
            {falsePositiveViolations.length > 0 ?
                <Panel bordered style={{marginTop: 20}}>
                    <p><b>{'The following violations were marked as false positives:'}</b></p>
                        <ul className='dashed' style={{marginTop: 5}}>
                            {falsePositiveViolations.map(fp => (<li>{fp.v[0].desc} <p><i>{fp.comment}</i></p></li>))}
                        </ul>
                </Panel>
                : ''
            }

            {/* List of data types for which a collect and inform was present */}
            <Panel bordered style={{marginTop: 20}}>
                <p><b>{'For the following data types there are actions assumed to include both a collect of data and an inform on the collect to the subject:'}</b></p>
                    <ul className='dashed' style={{marginTop: 5}}>
                        {Array.from(uniqueDataCOI).map(d => 
                            <li>{' ' + d}</li>)
                        }
                    </ul>
            </Panel>

            {/* List of data types for which a start of case was present */}
            <Panel bordered style={{marginTop: 20}}>
                <p><b>{'For the following data types there are actions assumed to induce GDPR actions at the start of the cases they occur in:'}</b></p>
                <ul className='dashed'>
                    {Array.from(uniqueDataSOC).map(d => d.length > 0 ? 
                        <li>
                            {' ' + d}
                            <ul className='dashed'>
                                {Array.from(new Set(startOfCaseMappings.filter(s => s.data === d).map(s => s.name))).map(s => 
                                    <li>{' ' + s}</li>
                                )}
                            </ul>
                            <br/>
                        </li> 
                        : ''
                    )}
                </ul>
            </Panel>

            <MyModal
                title='Kortlægningsværktøj'
                onClose={() => this.setState({showForm: false})}
                show={this.state.showForm}
                full
                buttons={[
                    <Button appearance='primary' onClick={() => makeCSV2(this.state.formData)} style={{marginLeft: 10}}>Download</Button>
                ]}
                onShow={this.onShow.bind(this)}
            >

                  <Form fluid>
                    <FormGroup controlId="processPurpose">
                        <p><b>Hvad er formålet med processen?</b></p>
                        <p>Formålet skal være tilstrækkeligt præcist, og der må som udgangspunkt ikke ske behandling til andre formål.</p>
                        <Input
                            componentClass='textarea'
                            rows={3} 
                            value={this.state.formData.processPurpose} 
                            style={{width: '100%'}}
                            onChange={s => {this.setState({...this.state, formData: {...this.state.formData, processPurpose: s}})}} 
                        />
                    </FormGroup>
                    <FormGroup controlId="dataResponsible">
                        <p><b>Dataansvarlige eller databehandlere?</b></p>
                        <p>Hvem har fastlagt formålet med processen? Hvem bestemmer, hvad der skal ske med oplysningerne? Instrueres nogen i, hvordan oplysningerne skal behandles?</p>
                        <Input
                            componentClass='textarea' 
                            rows={3} 
                            value={this.state.formData.dataResponsibility} 
                            style={{width: '100%'}}
                            onChange={s => {this.setState({...this.state, formData: {...this.state.formData, dataResponsibility: s}})}} 
                        />
                    </FormGroup>
                    {this.state.formData.dataInfo.map(pd => (
                        <Panel style={{marginTop: 10}} header={pd.data} collapsible bordered defaultExpanded={false}>
                            <FormGroup controlId="dataType">
                                <p>Type af personoplysning:</p>
                                <SelectPicker 
                                    data={[
                                        {label: 'Almindelig', value: 'Almindelig'}, 
                                        {label: 'Fortrolig', value: 'Fortrolig'}, 
                                        {label: 'Følsom', value: 'Følsom'}
                                    ]} 
                                    value={pd.dataType} 
                                    onChange={s => {pd.dataType = s; this.forceUpdate()}}
                                    style={{width: 200}}
                                    searchable={false}
                                    cleanable={false}
                                />
                            </FormGroup>
                            <FormGroup controlId="subjectTypes">
                                <>Kategorier af registrede med denne personoplysning:</>
                                <Button 
                                    size='sm' 
                                    appearance='ghost' 
                                    onClick={() => {pd.subjectTypes.push(''); this.forceUpdate()}}
                                    style={{marginLeft: 20}}
                                >
                                    Tilføj kategori
                                </Button>
                                {pd.subjectTypes.map((st,i) => (
                                    <div style={{display: 'flex', marginTop: 10}}>
                                        <AutoComplete 
                                            name={""} 
                                            placeholder={"Fx borgere, ansatte, kandidater, kunder, potentielle kunder, tidligere ansatte"} 
                                            value={st}
                                            filterBy={(s, item:{value:string, label:string}) => 
                                                s !== item.value && item.value.includes(s)}// don't show a suggestion that is the same as the current input
                                            data={Array.from(new Set(this.state.formData.dataInfo.flatMap(di => di.subjectTypes)))}
                                            onChange={s => 
                                                {pd.subjectTypes[i] = s; this.forceUpdate()}
                                            }
                                            style={{width: '50%'}}
                                        />
                                        <IconButton 
                                            icon={<Icon icon='minus'/>} 
                                            style={{marginLeft: 20}}
                                            onClick={() => {pd.subjectTypes = pd.subjectTypes.filter((pd_, i_) => i_ !== i); this.forceUpdate()}} 
                                        />
                                    </div>

                                ))}
                            </FormGroup>
                        </Panel>
                    ))}
                </Form>
            </MyModal>
                
            </div>
        )
    }

}

export interface AnalysisOverviewWrapperState {
    overviewProps?: AnalysisOverviewProps
}

export interface AnalysisOverviewWrapperProps extends RouteComponentProps {
    t: (k:string) => string
    showPrintableSummary: (b:boolean) => void
}

interface ParamWithLink {link:string}

class AnalysisOverviewWrapper extends Component<AnalysisOverviewWrapperProps, AnalysisOverviewWrapperState>{

    constructor(props:AnalysisOverviewWrapperProps){
        super(props)
        this.state = {overviewProps: undefined}
    }

    async fetchAnalysis(workspaceId:number, analysisId:number){
        await Repo.getOneAnalysis(workspaceId, analysisId)
        .then(res => {
            if(res.success){
                const analysis = res.payload!
                let submittedFiles:IFileData[] = analysis.logs.map(l => ({
                    file: new EmptyFile(l.name),
                    content: removeMultilines(l.content),
                    shapes: l.shapes.map(s => ({...fromJSON(s.content), expanded: true, id: s.id})),
                    id: l.id,
                    selected: true,
                    actionMapping: JSON.parse(l.mapping.content),
                    actionMappingid: l.mapping.id,
                    actions: new Map(),
                    records: []
                }))

                submittedFiles = submittedFiles.map(f => ({...f, records: parseRecordsWithoutShape(f)}))

                
                try {
                    let timestamp = formatDate(new Date(analysis.pub_date))
                    let cleanData: FileData[] = submittedFiles.map(f => validateAndParseIFileData(f))
                    let gdprLog: TransformedLogRecord[] = convertToGdprLog(cleanData)
                    let rules: GDPRRule[] = defaultRules()
                    rules.forEach((r:GDPRRule) => r.stepMany(gdprLog))
                    let analysis_info:AnalysisOverviewProps = {
                        timestamp: timestamp,
                        analysisParameters: JSON.parse(JSON.stringify(analysis.parameters)),
                        rules: rules,
                        records: gdprLog,
                        mappings: submittedFiles.map(f => ({mapping: JSON.parse(JSON.stringify(f.actionMapping)), fileName: f.file.name})),
                        falsePositives: analysis.falsePositives,
                        showPrintableSummary: this.props.showPrintableSummary
                    }

                    this.setState({overviewProps: analysis_info})

                } catch (error:any) { 
                    Alert.error(error.message, ERROR_TIME)
                }
            } else{
                Alert.error(this.props.t('get_analysis_error')  + res.error_msg, ERROR_TIME)
            }
        })
    }

    componentDidMount(){
        const id = (this.props.match.params as ParamWithLink).link;

        Repo.getSummary(id).then(res => {
            if(res.success){
                this.fetchAnalysis(res.payload!.workspaceId, res.payload!.analysisId)
                
            } else {
                Alert.error(this.props.t('get_summary_error') + res.error_msg, ERROR_TIME)
            }
        })
    }

    render(){
        return(
            this.state.overviewProps ?
            <AnalysisOverview
                timestamp={this.state.overviewProps.timestamp}
                analysisParameters={this.state.overviewProps.analysisParameters}
                rules={this.state.overviewProps.rules}
                records={this.state.overviewProps.records}
                mappings={this.state.overviewProps.mappings}
                falsePositives={this.state.overviewProps.falsePositives}
                showPrintableSummary={this.props.showPrintableSummary}
                fromLink
                printableVersion
            />
            :
            ''
        )
    }
}

export const AnalysisOverviewFromLink = withRouter(AnalysisOverviewWrapper)

export default AnalysisOverview;
