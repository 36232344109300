import React from 'react';
import '../common.css'
import { Button, ButtonGroup, FlexboxGrid, Form, FormControl, Icon, IconButton, Popover, SelectPicker, Table, Tooltip, Whisper } from 'rsuite';
import { TimeFormat } from '../../models/timeformat';
import { AttributeCol, GDPRAttr } from '../../models/shape';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import { Component } from 'react';
import { formatDateInfo, WHISPER_DELAY } from '../../utils/utils';
import { withTranslation } from 'react-i18next';

/* Component used to select which part of the records match the GDPR attributes for a shape */

const { Column, HeaderCell, Cell } = Table;

interface ISelectorProps<T> {
    value?: T
    style?: React.CSSProperties
    disabled?: boolean
    onSelect: (item:T) => void
    onClean: () => void
    data: T[]
    tooltip?: string
}

type SelectData = { label: string, col: number }

class GDPRAttributeSelector extends Component<ISelectorProps<SelectData>>{
    
    render(){
        return(
            <Whisper 
                delay={WHISPER_DELAY}  
                trigger="hover" 
                placement={'top'} 
                speaker={<Tooltip>{this.props.tooltip}</Tooltip>}
            >
                <SelectPicker
                    style={this.props.style || { width: "100%" }}
                    disabled={this.props.disabled}
                    value={ this.props.value !== undefined ? this.props.value.col : null }
                    placeholder="Choose column..."
                    data={this.props.data}
                    labelKey={"label"}
                    valueKey={"col"}
                    searchable={false}
                    onSelect={(v:any, item:any, event:React.SyntheticEvent<any,Event>) => 
                        this.props.onSelect(item as SelectData)}
                    cleanable={false}
                    preventOverflow
                />
            </Whisper>
        )
    }
}

type RowData = { 
    attribute: GDPRAttr, 
    col: number | undefined, 
    regex: string, 
    idx: number[], 
    actionid?: string
}

interface ColumnSelectorProps {
    style: any,
    attributes: AttributeCol[]
    recordPieces: string[]
    timestampRegex: string,
    onAttributeSelect: (i:number[], attribute:GDPRAttr, c?:number) => void,
    onAttributeRegexChange: (i:number[], regex:string) => void,
    onActionIdChange: (i:number[], regex:string) => void,
    onTimestampRegexChange: (timestampRegex: string) => void,
    onAddAttribute: (action: GDPRAttr, idx:number[]) => void,
    onRemoveColumn: (i:number[]) => void,
    onBlur: () => void
    t: (k:string) => string
}

class ColumnSelector extends Component<ColumnSelectorProps>{

    addAttributeButton(id:number[]){
        return (
            <Whisper 
                trigger={'focus'}
                placement='auto'
                speaker={
                    <Popover>
                        <ButtonGroup vertical>
                        <Button style={{marginLeft: 10}} size='sm' appearance='ghost' onClick={() => this.props.onAddAttribute('dsid', id)}>Add dsid</Button>
                        <Button style={{marginLeft: 10}} size='sm' appearance='ghost' onClick={() => this.props.onAddAttribute('dataid', id)}>Add dataid</Button>
                        <Button style={{marginLeft: 10}} size='sm' appearance='ghost' onClick={() => this.props.onAddAttribute('timestamp', id)}>Add time</Button>
                        <Button style={{marginLeft: 10}} size='sm' appearance='ghost' onClick={() => this.props.onAddAttribute('action', id)}>Add action</Button>
                        </ButtonGroup>
                    </Popover>
                }
            >
                <IconButton 
                    appearance='ghost' 
                    style={{width: '100%'}} 
                    icon={<Icon icon='plus'/>}
                > 
                    {this.props.t('add_attribute')}
                </IconButton>
            </Whisper>
        )
    }

    render(){

        let { style, timestampRegex, recordPieces, attributes } = this.props

        const flatten = (attributes:AttributeCol[], idx:number[]) => {
            return attributes.reduce((acc:RowData[], a, i) => {
              if(a.children && a.children.length) {
                acc = [
                    ...acc, 
                    { 
                        attribute: a.attribute, 
                        col: a.column, 
                        regex: a.regex || '', 
                        idx: [...idx, i], 
                        actionid: a.actionid 
                    }
                ].concat(flatten(a.children, [...idx, i]))
              } else {
                acc.push(
                    { 
                        attribute: a.attribute, 
                        col: a.column, 
                        regex: a.regex || '', 
                        idx: [...idx, i], 
                        actionid: a.actionid 
                    }
                )
              }
        
              return acc;
            }, [])
        } 
    
        const rowData:RowData[] = flatten(attributes, [])

        let selectData = [...Array(30).keys()].map(col => ({ col, label: (col+1)+'' }))
    
        let date = this.props.t('timestamp_match_error')
        const timestamp = attributes.filter(a => a.attribute === 'timestamp')
        if (timestamp.length === 1) {   
            try {
                if(timestampRegex.length === 0) {
                    date = formatDateInfo(new Date(Date.parse(recordPieces[timestamp[0].column!])))
                }
                else {
                    date = new TimeFormat(timestampRegex).getMatchResult(recordPieces[timestamp[0].column!])
                }
            } catch (e) {

            } 
        }

        return (
            <div>
                <Table
                    style={style}
                    autoHeight
                    rowHeight={140}
                    data={rowData}
                    id="table"
                > 
                    <Column width={100} fixed>
                        <HeaderCell>{this.props.t('attribute')}</HeaderCell>
                        <Cell>
                            {({ attribute, idx }: RowData) => `${' - '.repeat(idx.length-1)}${attribute}` }
                        </Cell>
                    </Column>
                    
    
                    <Column flexGrow={1}>
                        <HeaderCell>{this.props.t('log_entry')}</HeaderCell>
                        <Cell> 
                            {({ attribute, col, regex, idx, actionid }: RowData) => 
                                <FlexboxGrid>
                                    <FlexboxGridItem colspan={24}>
                                        <div>
                                            <GDPRAttributeSelector
                                                value={ col !== undefined ? selectData[col] : undefined}
                                                disabled={recordPieces.length <= 0}
                                                onSelect={(item:SelectData) => {
                                                    this.props.onAttributeSelect(idx, attribute, item.col)
                                                    this.props.onBlur()
                                                }}
                                                onClean={() => this.props.onAttributeSelect(idx, attribute, undefined)}
                                                data={selectData}
                                                style={{width: '100%'}}
                                                tooltip={this.props.t('select_picker_tooltip')}
                                            />
                                        </div>
                                        
                                        <div style={{marginTop: 5}}>
                                            {attribute === 'timestamp' ? 
                                                <Form onCheck={this.props.onBlur} checkTrigger={'blur'}>
                                                    <Whisper 
                                                        delay={WHISPER_DELAY} 
                                                        trigger="hover" 
                                                        placement={'auto'} 
                                                        style={{maxWidth: "200px "}}
                                                        speaker={
                                                            <Tooltip 
                                                                trigger="hover" 
                                                                style={{maxWidth: "300px"}} 
                                                            >
                                                                {`${this.props.t('format_tooltip')} 'yyyy/mm/dd hh:ii:ss.jjj' or regex with groups, like \n'(?<year>[0-9]{4})/(?<month>[0-9]{2})/(?<day>[0-9]{2}) (?<hours>[0-9]{2}):(?<minutes>[0-9]{2}):(?<seconds>[0-9]{2}).(?<milliseconds>[0-9]{3})'`}
                                                            </Tooltip>
                                                    }>
                                                        <>
                                                        <FormControl
                                                            placeholder={this.props.t('required_timestamp_format')} 
                                                            value={timestampRegex}
                                                            onChange={(r:string, e) => this.props.onTimestampRegexChange(r)}
                                                            disabled={recordPieces.length <= 0}
                                                            style={{width: '100%'}}
                                                        />
                                                        <p>{date}</p>
                                                        </>
                                                    </Whisper>
                                                </Form> 
                                                :
                                                attribute === 'action' ?
                                                <Form onCheck={this.props.onBlur} checkTrigger={'blur'}>
                                                    <FormControl 
                                                        placeholder={this.props.t('action_id')} 
                                                        value={actionid} 
                                                        disabled={recordPieces.length <= 0}
                                                        onChange={(r:string) => this.props.onActionIdChange(idx, r)}
                                                        style={{width: '100%'}}
                                                    />
                                                </Form>
                                                :
                                                attribute === 'split' ?
                                                <>
                                                <div>
                                                    <Whisper speaker={<Tooltip>{this.props.t('split_regex_tooltip')}</Tooltip>}>
                                                        <Form onCheck={this.props.onBlur} checkTrigger={'blur'}>
                                                            <FormControl 
                                                                placeholder={this.props.t('regex')} 
                                                                value={regex} 
                                                                disabled={recordPieces.length <= 0}
                                                                onChange={(r:string) => 
                                                                    this.props.onAttributeRegexChange(idx, r)}
                                                                style={{width: '100%'}}
                                                            />
                                                        </Form>
                                                    </Whisper>
                                                </div>
                                                <div style={{marginTop: 5}}>
                                                    {this.addAttributeButton(idx)}
                                                </div>
                                                </>
                                                :
                                                <Whisper speaker={<Tooltip>{this.props.t('attribute_regex')}</Tooltip>}>
                                                    <Form onCheck={this.props.onBlur} checkTrigger={'blur'}>
                                                        <FormControl 
                                                            placeholder={this.props.t('attribute_regex')} 
                                                            value={regex} 
                                                            disabled={recordPieces.length <= 0}
                                                            onChange={(r:string) => 
                                                                this.props.onAttributeRegexChange(idx, r)}
                                                            style={{width: '100%'}}
                                                        />
                                                    </Form>
                                                </Whisper>
                                            }
                                        </div>
                                    </FlexboxGridItem>
                                </FlexboxGrid> 
                            }
                        </Cell>
                    </Column>

                    <Column width={30}>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {({ attribute, idx }: RowData) => (
                                <Icon
                                    onClick={() => {this.props.onRemoveColumn(idx); this.props.onBlur()}} 
                                    icon={'warning'}
                                    appearance='subtle'
                                    style={{cursor: 'pointer', marginTop: 10}}
                                />
                            ) }
                        </Cell>
                    </Column>
                </Table>

                {this.addAttributeButton([])}
            </div>
          );
    }
}


export default withTranslation()(ColumnSelector);