import React from "react";
import { Container, Content, Header } from "rsuite";
import MyNavbar from "../components/helperComponents/MyNavbar";

/* Root page with Navbar at the top and child below */

interface RootpageProps {
    header: string;
    isLoggedIn: boolean
    logout: () => void
    username: string
    stopRedirect: () => void
    tutorial?: boolean
    setTutorial: (b:boolean) => void
}

export const RootPage: React.FC<RootpageProps> = (props) => {
    return (
        <>
            <Container className="outer" style={{ minHeight: "100vh" }}>
                <Header>
                    <MyNavbar 
                        {...props}
                    />
                </Header>
                <Content>
                    <Container className="container">
                        {props.children}
                    </Container>
                </Content>
            </Container>
        </>
    );
}
