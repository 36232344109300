import React from "react";
import { ButtonGroup, ButtonToolbar, Icon, IconButton, Panel, Tooltip, Whisper } from "rsuite";
import { WorkspaceInfo } from "../../../../common/ClientServerInterface";
import { withTranslation } from 'react-i18next';
import { formatDate } from "../../utils/utils";
import AnalysisList from "./AnalysisList";
import ConfirmationModal from "../helperComponents/ConfirmationModal";

/* Renders a list of workspaces and their analyses */

interface WorkspaceListProps{
    workspaces: WorkspaceInfo[]
    leaveWorkspace: (id:number) => Promise<void>
    onChoose: (w_id:number, a_id:number) => void
    showResourceModal: (workspace:WorkspaceInfo) => void
    showAccessModal: (workspace:WorkspaceInfo) => void
    emptyMessage: string
    t: (k:string) => string
    tReady: boolean
}

interface WorkspaceListState{
    showDeleteModal: number
}

class WorkspaceList extends React.Component<WorkspaceListProps, WorkspaceListState>{

    constructor(props:WorkspaceListProps){
        super(props)
        this.state = {
            showDeleteModal: 0
        }
    }

    hideModal(){
        this.setState({showDeleteModal: 0})
    }

    render(){
        const buttonStyle = { }

        return(

            <>
            {this.props.workspaces.length === 0 ? <p>{this.props.emptyMessage}</p> :
                this.props.workspaces.map(workspace => {
    
                    let pub_date:string = String(workspace.pub_date)
                    let date = new Date(Date.parse(pub_date))
                    let date_formatted = formatDate(date)
    
                    return (
                        <Panel key={workspace.id} bordered style={{marginTop: 10 }} header={
                            <ButtonToolbar style={{ display: "flex"}}>
                                <div>
                                    <h2 className="heading" style={{marginTop: -15}}>{workspace.name}</h2>
                                    <p style={{color:'grey', fontSize: 12, marginTop: -10}}>
                                        {
                                            this.props.t('created_on') + ' ' + 
                                            date_formatted + ' ' + this.props.t('by') + ' ' + 
                                            workspace.created_by_username
                                        }
                                    </p>
                                </div>
                                
                                <ButtonGroup style={{marginLeft: 'auto'}}>

                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                        <Tooltip>{this.props.t('manage_resources')}</Tooltip>
                                    }>
                                        <IconButton 
                                            icon={<Icon icon="edit2" />} 
                                            placement='right' 
                                            appearance='ghost' 
                                            style={{...buttonStyle}} 
                                            onClick={() => this.props.showResourceModal(workspace)}
                                        />
                                    </Whisper>

                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                        <Tooltip>{this.props.t('manage_access')}</Tooltip>
                                    }>
                                        <IconButton 
                                            icon={<Icon icon="user-plus" />} 
                                            placement='right' 
                                            appearance='ghost' 
                                            style={{...buttonStyle}} 
                                            onClick={() => this.props.showAccessModal(workspace)}
                                            disabled={workspace.role !== 'Full'}
                                        />
                                    </Whisper>

                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                                        <Tooltip>{this.props.t('leave')}</Tooltip>
                                    }>
                                        <IconButton 
                                            icon={<Icon icon="close-circle" />} 
                                            placement='right' 
                                            appearance='ghost' 
                                            style={{...buttonStyle}} 
                                            onClick={() => this.setState({showDeleteModal: workspace.id})}
                                        />
                                    </Whisper>
                                </ButtonGroup>
                            </ButtonToolbar>

                        }>
                            <AnalysisList
                                analyses={workspace.analyses}
                                emptyMessage={this.props.t('no_analyses_workspace')}
                                onChoose={analysisId => this.props.onChoose(workspace.id, analysisId)}
                                workspaceId={workspace.id}
                            />
                        </Panel>
                    );
                })
            }
                
            <ConfirmationModal
                id={this.state.showDeleteModal} 
                onAccept={this.props.leaveWorkspace}
                onClose={this.hideModal.bind(this)}
                show={this.state.showDeleteModal !== 0}
                text={this.props.t('leave_workspace_confirmation')}
            />

            </>
        
        )}
}

export default withTranslation()(WorkspaceList)