import { Component } from "react";
import { Divider } from "rsuite";
import { CommentInfo, FalsePositiveInfo } from "../../../../common/ClientServerInterface";
import { Printer } from "../../engine/printer";
import { AnalysisParameters, GDPRRule } from "../../engine/rules";
import { Violation } from "../../models/engine-types";
import { falsePositiveViolationEquality } from "../../utils/utils";
import ViolationElement from "./ViolationElement";

/* Renders a rule for the violation display*/

interface IRuleProps {
    rule: GDPRRule
    allVisible: [boolean, number]
    comments: CommentInfo[]
    workspaceId: number
    analysisId: number
    userId: number
    analysisParameters: AnalysisParameters
    falsePositives: FalsePositiveInfo[]
}

export class Rule extends Component<IRuleProps>{

    render(){
        let rule = this.props.rule
        let violations : Violation[] = rule.getViolations(this.props.analysisParameters)
    
        return (
            <>
                <h2 style={{fontSize: 20}}>{rule.name}</h2>
                <h4 style={{fontSize: 16}}>{Printer.ppSummary(rule.article, violations)}</h4>
                {
                    violations.map((v,vi) => {
                        const fp = this.props.falsePositives.filter(fp => 
                            falsePositiveViolationEquality(fp, v, rule.name)
                        )
                        
                        return <ViolationElement 
                            key={vi} 
                            v={v} 
                            title={`Violation ${vi+1} of ${violations.length}:`} 
                            visible={this.props.allVisible } 
                            comments={this.props.comments} 
                            workspaceId={this.props.workspaceId} 
                            analysisId={this.props.analysisId}
                            userId={this.props.userId}
                            ruleName={rule.name}
                            checked={fp.length > 0}
                            fpComment={fp.length > 0 ? fp[0].comment : ''}
                        />
                    }
                    )
                }
                <Divider/>
            </>
        );
    }
}