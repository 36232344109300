import React from "react";
import { Alert, Button, Divider, Dropdown, FlexboxGrid, Icon, IconButton, List, Popover, Whisper } from "rsuite";
import { AnalysisInfo } from "../../../../common/ClientServerInterface";
import { withTranslation } from 'react-i18next';
import { ERROR_TIME, formatDate } from "../../utils/utils";
import { Repo } from "../../repositories/Repo";
import ConfirmationModal from "../helperComponents/ConfirmationModal";

/* Renders a list of analyses */

interface AnalysisListProps{
    analyses: AnalysisInfo[]
    onChoose: (a_id:number) => void
    emptyMessage: string
    workspaceId: number
    t: (s:string) => string
    tReady: boolean
}

interface AnalysisListState{
    showDeleteModal: number
    analyses: AnalysisInfo[]
}

class AnalysisList extends React.Component<AnalysisListProps, AnalysisListState>{

    constructor(props:AnalysisListProps){
        super(props)
        this.state = {
            showDeleteModal: 0,
            analyses: props.analyses
        }
    }

    hideModal(){
        this.setState({showDeleteModal: 0})
    }

    async addAnalysis(){
        this.props.onChoose(0)
    }

    async duplicateAnalysis(analysis:AnalysisInfo){
        await Repo.postAnalysis(this.props.workspaceId, {
            name: analysis.name + ' (copy)',
            analysisParameters: analysis.parameters,
            logs: analysis.logs.map(f => ({
                log_name: f.name, 
                log_content: f.content, 
                id: f.id,
                shapes: f.shapes.map(s => ({id: 0, content: s.content, shape_name: s.name})), 
                mapping: {content: f.mapping.content, mapping_name: f.mapping.name, id: f.mapping.id}
            })),
            transferComments: analysis.id
        })
        .then(res => {
            if (res.success) {
                let new_analysis = {...analysis, name: analysis.name + ' (copy)', id: res.payload!.id}
                this.state.analyses.push(new_analysis)
                this.setState({analyses: this.state.analyses})
            } else {
                Alert.error(this.props.t('save_analysis_error') + res.error_msg, ERROR_TIME)
            }
        })
    }

    async removeAnalysis(id:number){
        await Repo.removeAnalysis(this.props.workspaceId, id)
        .then(res => {
            if (res.success) {
                this.setState({analyses: this.state.analyses.filter(analysis => analysis.id !== id)})
            } else {
                Alert.error(this.props.t('remove_analysis_error'), ERROR_TIME);
            }
        })
    }

    renderMenu = (rowData:AnalysisInfo) => {
        return (
          <Popover full>
            <Dropdown.Menu>
              <Dropdown.Item 
                onSelect={() => {this.duplicateAnalysis(rowData)}}
                >
                  Duplicate
              </Dropdown.Item>
              <Dropdown.Item 
                onSelect={() => {this.setState({showDeleteModal: rowData.id})}}
              >
                  Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        );
    };
      
    renderActionCell = (rowData:AnalysisInfo) => {
        return (
            <Whisper placement="autoVerticalStart" trigger="click" speaker={this.renderMenu(rowData)}>
              <IconButton appearance="subtle" icon={<Icon icon='more' />}/>
            </Whisper>
        );
    };

    render(){
        const styleCenter = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60px',
        };
          
        const slimText = {
            fontSize: '0.766em',
            color: '#97969B',
            fontWeight: 4,
            paddingBottom: 5,
        };
          
        const titleStyle = {
            paddingBottom: 5,
            fontWeight: 500,
        };
          
        return(
            <>
                {
                    this.state.analyses.length > 0 ? 
                    <>
                    <List hover style={{marginTop: -10}}>
                        {this.state.analyses.map(analysis => (
                            <List.Item key={analysis.name} index={analysis.id}>
                                <FlexboxGrid>

                                <FlexboxGrid.Item colspan={2} style={styleCenter}>
                                    {React.cloneElement(<Icon icon='book2'></Icon>, {
                                    style: {
                                        color: 'darkgrey',
                                        fontSize: '1.5em',
                                    },
                                    })}
                                </FlexboxGrid.Item>
                                
                                <FlexboxGrid.Item
                                    colspan={8}
                                    style={{
                                    ...styleCenter,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    overflow: 'hidden',
                                    }}
                                >
                                    <div style={titleStyle}>{analysis.name}</div>
                                </FlexboxGrid.Item>
                                
                                <FlexboxGrid.Item
                                    colspan={6}
                                    style={{
                                    ...styleCenter,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    overflow: 'hidden',
                                    }}
                                >
                                    <div style={slimText}>
                                    <div>
                                        <Icon icon='user-circle-o' style={{padding: 10}}/>
                                        {analysis.createdByUsername}
                                    </div>
                                    <div>{formatDate(new Date(analysis.pub_date))}</div>
                                    </div>
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item
                                    colspan={6}
                                    style={{
                                    ...styleCenter,
                                    marginLeft: 'auto'
                                    }}
                                >
                                    <Button appearance='link' onClick={() => this.props.onChoose(analysis.id)}>
                                        View
                                    </Button>
                                    <span style={{ padding: 5 }}>|</span>
                                    {this.renderActionCell(analysis)}              
                                </FlexboxGrid.Item>

                                </FlexboxGrid>
                            </List.Item>
                        ))}
                    </List>
                    <div style={{display: 'flex'}}>
                        <Button 
                            appearance='link' 
                            style={{marginLeft: 'auto', marginRight: 30, marginTop: 10}}
                            onClick={this.addAnalysis.bind(this)}
                        >
                            + New analysis
                        </Button>
                    </div>
                </>
                :
                <div style={{marginTop: -30}}>
                    <Divider/>
                        {this.props.emptyMessage}
                        <Button 
                            appearance='link' 
                            style={{marginLeft: 'auto', marginRight: 40, marginTop: -3}}
                            onClick={this.addAnalysis.bind(this)}
                        >
                            {'+ ' + this.props.t('new_analysis')}
                        </Button>
                    <Divider/>
                    
                </div>

                }

            <ConfirmationModal
                id={this.state.showDeleteModal} 
                onAccept={this.removeAnalysis.bind(this)}
                onClose={() => this.setState({showDeleteModal: 0})}
                show={this.state.showDeleteModal !== 0}
                text={this.props.t('remove_analysis_confirmation')}
            />

            </>
        )}
}

export default withTranslation()(AnalysisList)