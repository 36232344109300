import React from "react";
import { Dropdown, Icon, IconButton, Popover, Table, Whisper } from "rsuite";
import { downloadString } from "../../utils/utils";
import { EditText } from "./EditableText";
import { withTranslation } from 'react-i18next';
import ConfirmationModal from "./ConfirmationModal";

/* Renders a list of resources */

export interface ResourceListData{
    id: number
    name: string
    info: string
    access: boolean
    content: string
}

interface ResourceListProps{
    data: ResourceListData[]
    saveName: (id:number, name:string) => Promise<void>
    remove: (id:number) => Promise<void>
    emptyMessage: string
    showContent: (s:string) => void
    access: boolean
    t: (k:string) => string
    tReady: boolean
}

interface ResourceListState{
  showDeleteModal: number
}

class ResourceList extends React.Component<ResourceListProps, ResourceListState>{

  constructor(props:ResourceListProps){
    super(props)
    this.state = {showDeleteModal: 0}
  }

  renderMenu = (rowData:ResourceListData) => {
      return (
        <Popover full>
          <Dropdown.Menu>

            <Dropdown.Item 
              onSelect={() => this.props.showContent(rowData.content)}
              disabled={rowData.content.length <= 0} 
            >
                {this.props.t('view_content')}
            </Dropdown.Item>

            <Dropdown.Item 
              disabled={rowData.content.length <= 0} 
              onSelect={() => downloadString(rowData.content, rowData.name)}
            >
                {this.props.t('download_as_file')}
            </Dropdown.Item>

            <Dropdown.Item 
              disabled={!rowData.access}
              onSelect={() => this.setState({showDeleteModal: rowData.id})}
            >
                {this.props.t('remove')}
            </Dropdown.Item>

          </Dropdown.Menu>
        </Popover>
      );
  };
    
  renderActionCell = (rowData:ResourceListData) => {
      return (
          <Whisper placement="autoVerticalStart" trigger="click" speaker={this.renderMenu(rowData)}>
            <IconButton style={{marginTop: -5}} appearance="subtle" icon={<Icon icon='more' />} />
          </Whisper>
      );
  };
  
  render(){
      return(
        <>
          <Table height={300} data={this.props.data} id="table" hover rowKey={'id'}>
    
            <Table.Column width={300}>
              <Table.HeaderCell>{this.props.t('name')}</Table.HeaderCell>
              <Table.Cell>
                  {this.props.access ?
                  (rowData:ResourceListData) => 
                    <EditText 
                        value={rowData.name} 
                        editClassName='' 
                        onSave={(name:string) => this.props.saveName(rowData.id, name)} 
                        size={20}
                    />
                    :
                    (rowData:ResourceListData) => rowData.name
                  }
              </Table.Cell>
            </Table.Column>
      
            <Table.Column width={500}>
              <Table.HeaderCell>{this.props.t('info')}</Table.HeaderCell>
              <Table.Cell dataKey="info"></Table.Cell>
            </Table.Column>
      
            <Table.Column width={100} fixed='right'>
              <Table.HeaderCell>{this.props.t('actions')}</Table.HeaderCell>
              <Table.Cell>
                {(rowData:ResourceListData) => this.renderActionCell(rowData)}
              </Table.Cell>
            </Table.Column>
          </Table>

          <ConfirmationModal
              id={this.state.showDeleteModal}
              onAccept={this.props.remove}
              onClose={() => this.setState({showDeleteModal: 0})}
              show={this.state.showDeleteModal !== 0}
              text={this.props.t('remove_resource_confirmation')}
          />
        </>
      )
  }
}

export default withTranslation()(ResourceList)