import { Component } from "react";
import { Alert, Button, Panel } from "rsuite";
import { CommentInfo } from "../../../../common/ClientServerInterface";
import { Violation } from "../../models/engine-types";
import { Repo } from "../../repositories/Repo";
import { withTranslation } from 'react-i18next';
import Comment from "./Comment";
import { ERROR_TIME } from "../../utils/utils";

/* Shows an overview of the comments to the analysis */

interface CommentOverviewProps{
    comments: CommentInfo[]
    workspaceId: number
    analysisId: number
    violations: Violation[]
    tReady: boolean
    t: (k:string) => string
    userId: number
    showLoginModal: (f:()=>void)=>void
}

interface CommentOverviewState{
    comments: CommentInfo[]
    comment_text: string
}

class CommentOverview extends Component<CommentOverviewProps, CommentOverviewState>{

    constructor(props:CommentOverviewProps){
        super(props)
        this.state = {
            comments: props.comments,
            comment_text: ''
        }
    }

    addComment(log_id:number, line:number, rule: string, data:string){
        Repo.postComment(this.props.workspaceId, 
            {
                text: this.state.comment_text, 
                line: line, 
                analysisid: this.props.analysisId, 
                logid: log_id, 
                rule: rule, 
                data: data
            }
        )
        .then(res => {
            if(res.success){
                this.state.comments.push(res.payload!)
                this.setState({comments: this.state.comments, comment_text: ''})
            } else{
                Alert.error(this.props.t('add_comment_error') + res.error_msg, ERROR_TIME)
                if(!Repo.isLoggedIn()) this.props.showLoginModal(() => this.addComment(log_id, line, rule, data))
            }
        })
    }

    removeComment(comment_id:number){
        Repo.removeComment(this.props.workspaceId, comment_id)
        .then(res => {
            if(res.success){
                const coms = this.state.comments.filter(c => c.id !== comment_id)
                this.setState({comments: coms})
            } else {
                Alert.error(this.props.t('remove_comment_error'), ERROR_TIME)
            }
        })
    }

    editComment(comment_id:number, text:string){
        Repo.editComment(this.props.workspaceId, comment_id, {text: text})
        .then(res => {
            if(res.success){
                const com = this.state.comments.find(c => c.id === comment_id)
                if(com){
                    let coms = this.state.comments.filter(c => c.id !== comment_id)
                    const newCom:CommentInfo = {
                        text: text, 
                        id: res.payload!.id, 
                        line: com.line, 
                        logId: com.logId, 
                        rule: com.rule,
                        data: com.data,
                        uploadedAt: com.uploadedAt,
                        uploadedBy: com.uploadedBy,
                        uploadedByUsername: com.uploadedByUsername,
                        editedAt: new Date(Date.now()),
                        removed: false,
                        history: [...com.history, com]
                    }
                    coms.push(newCom)
                    coms.sort((a,b) => new Date(a.uploadedAt).getTime() - new Date(b.uploadedAt).getTime())
                    this.setState({comments: coms})
                }
            } else {
                Alert.error(this.props.t('edit_comment_error') + res.error_msg, ERROR_TIME)
                if(!Repo.isLoggedIn()) this.props.showLoginModal(() => this.editComment(comment_id, text))
            }
        })
    }

    groupBy(list:CommentInfo[]):Map<string, CommentInfo[]> {
        const map = new Map();
        list.forEach((item) => {
             const key = `${item.logId} ${item.line} ${item.rule} ${item.data}`
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    }

    render(){
        const res = []
        const groups = this.groupBy(this.state.comments)
        for (const k of groups.keys()){
            let coms = groups.get(k)
            if(coms){
                const fc = coms[0]
                const violation = fc ? 
                    this.props.violations.find(v => v[0].e.fileid === fc.logId && v[0].e.index === fc.line) : 
                    undefined
                coms = coms.filter((v, i) => i !== 0)
                res.push(
                    <Panel collapsible bordered style={{marginTop: 10}} header={
                        <>
                        {violation ?
                            <p style={{marginLeft: 10}}><b>{this.props.t('violation')}: </b>{violation[0].desc}</p> 
                        : ''}
                        {fc ?
                            <Comment 
                                    editedAt={fc.editedAt} 
                                    history={fc.history} 
                                    access={Number(this.props.userId) === Number(fc!.uploadedBy)} 
                                    id={fc.id} 
                                    text={fc.text} 
                                    uploadedAt={fc.uploadedAt} 
                                    uploadedByUsername={fc.uploadedByUsername} 
                                    workspaceId={this.props.workspaceId} 
                                    editComment={this.editComment.bind(this)} 
                                    removeComment={this.removeComment.bind(this)}
                            />
                            : 
                            ''
                        }
                        <p style={{marginLeft: 10, marginTop: 10}}>
                            {
                                coms?.length ? 
                                (coms!.length) + ' ' + this.props.t('response') + (coms!.length > 1 ? 's.' : '.') :
                                this.props.t('no_responses')
                            }
                        </p> 
                        </>
                    }>
                        {
                        coms?.map(c => 
                            <Comment 
                                editedAt={c.editedAt} 
                                history={c.history} 
                                access={Number(this.props.userId) === Number(fc!.uploadedBy)} 
                                id={c.id} 
                                text={c.text} 
                                uploadedAt={c.uploadedAt} 
                                uploadedByUsername={c.uploadedByUsername} 
                                workspaceId={this.props.workspaceId} 
                                editComment={this.editComment.bind(this)} 
                                removeComment={this.removeComment.bind(this)}
                            />
                        )}
                        { violation ?
                            <div style={{ display: "flex", marginTop: 20, marginBottom: 20}}>
                                <textarea 
                                    rows={3}
                                    style={{width: '60%'}}
                                    onChange={s => this.setState({comment_text: s.target.value})}
                                    value={this.state.comment_text}
                                />
                                <Button 
                                    style={{marginLeft: 10, height: 40, marginTop: 'auto'}} 
                                    appearance='ghost'
                                    onClick={() => 
                                        this.addComment(violation[0].e.fileid, violation[0].e.index, fc.rule, fc.data)}
                                >
                                    {this.props.t('comment')}
                                </Button>
                            </div>
                            : ''
                        }
                    </Panel>
                )
            }
            }

        return res.length > 0 ? res : this.props.t('no_comments_overview')
    }
}

export default withTranslation()(CommentOverview)