import React from 'react';
import { fromJSON, ShapeInput, toJSON } from '../../models/shape';
import { ShapeInfo } from '../../../../common/ClientServerInterface';
import { withTranslation } from 'react-i18next';
import { IFileData } from '../../models/file';
import ShapePanel, { SelectShapeMappingData, ShapeMappingSelector } from './ShapePanel';
import { Alert, Button, ButtonGroup, ButtonToolbar, Divider, Icon, IconButton, Input, Panel, Popover, Table, Tooltip, Whisper } from 'rsuite';
import { downloadString, ERROR_TIME, formatDate, ShapeColors, WHISPER_DELAY } from '../../utils/utils';
import { BsFilterSquare, BsFilterSquareFill } from 'react-icons/bs';
import { TutorialTooltip } from '../helperComponents/TutorialTooltip';
import MyModal from '../helperComponents/MyModal';
import { EditText } from '../helperComponents/EditableText';
import { Repo } from '../../repositories/Repo';

/* Panel containing the components used to define a shape */


interface ShapeOverviewProps {
    fdata: IFileData,
    onChange: (fileData:ShapeInput, i:number) => void,
    workspaceId: number
    showLoginModal: (f:()=>void) => void
    getShapes: () => void
    onBlur: () => void
    shapes: ShapeInfo[]
    applyShapes: () => void
    updateShapes: (o:number, n:number, c:ShapeInput) => void
    activeShape: number
    setActiveShape: (n:number) => void
    removeShape: (n:number) => void
    addShape: (s?:ShapeInput) => void
    setFilterShape: (n:number) => void
    moveShapeUp: (n:number) => void
    moveShapeDown: (n:number) => void
    setDemoStep: (n:number) => void
    recordExample: string
    expanded?: boolean
    demoStep?: number
    t: (k:string) => string
    tReady: boolean
}

interface ShapeOverviewState {
    showLoadModal: boolean,
    showSaveModal: boolean,
    showImportModal: boolean,
    showExportModal: boolean,
    importShape: string,
    modalShapeId: number,
}

class ShapeOverview extends React.Component<ShapeOverviewProps, ShapeOverviewState> {

    constructor(props:ShapeOverviewProps){
        super(props)
        this.state = {
            showLoadModal: false,
            showSaveModal: false,
            showImportModal: false,
            showExportModal: false,
            modalShapeId: 0,
            importShape: ''
        }
    }

    loadShape = (shape_content: string) => {
        try {
            const jsonShape = fromJSON(shape_content)
            this.props.addShape(jsonShape)
            this.props.setActiveShape(this.props.fdata.shapes.length-1)
            this.props.applyShapes()
        } catch (error) {
            throw error
        }
    }

    uploadShape = (file: File) => {
        let fileText = ""
        const reader = new FileReader()
        reader.onload = async (e) => {  
            if(e.target){
                try {
                    const text = (e.target.result)
                    fileText = text as string
                    this.loadShape(fileText)
                } catch (error) {
                    Alert.error(this.props.t('upload_shape_error'), ERROR_TIME)
                }
            }
        }
        reader.readAsText(file)
    }

    saveShape = async (shape:ShapeInput) => {
        return Repo.postShape(this.props.workspaceId, {shape_name: shape.name, content: toJSON(shape)})
        .then(res => {
            if (res.success) {
                Alert.info(this.props.t('save_shape_success'), 10000)
                this.setState({showSaveModal: false})
                this.props.getShapes()
                return res.payload?.id
            } else {
                Repo.isLoggedIn().then(res => {
                    if(res.success)
                        Alert.error(this.props.t('save_shape_error') + res.error_msg, ERROR_TIME)
                    else
                        this.props.showLoginModal(() => this.saveShape(shape))
                })
                return 0
            }
        })
    }

    async overwrite(overwrite_shape:SelectShapeMappingData, shape:ShapeInput){
        shape.name = overwrite_shape.name
        const new_id = await this.saveShape(shape)
        if(new_id){
            this.props.updateShapes(overwrite_shape.id, new_id, shape)
            shape.id = new_id
            Repo.removeShape(this.props.workspaceId, overwrite_shape.id)
            this.props.getShapes()
        }
    }

    exportShape = (shape:ShapeInput) => {
         return toJSON(shape)  
    }

    downloadShape = (shape:ShapeInput) => {
        downloadString(this.exportShape(shape), 'gdpr_shape.txt')
    }
    
    render() {

        const fdata_shapes = this.props.fdata.shapes

        const modalShape = this.props.fdata.shapes.length > this.state.modalShapeId ?
            this.props.fdata.shapes[this.state.modalShapeId] : 
            undefined

        const shapeMenu = (i:number) =>                             
            <ShapeMenu
                idx={i}
                shapes={fdata_shapes}
                filterShape={this.props.fdata.filterShape}
                moveShapeDown={this.props.moveShapeDown}
                moveShapeUp={this.props.moveShapeUp}
                removeShape={this.props.removeShape}
                setFilterShape={this.props.setFilterShape}
                setActiveShape={this.props.setActiveShape}
                showSaveModal={() => this.setState({showSaveModal: true, modalShapeId: i})}
                showExportModal={() => this.setState({showExportModal: true, modalShapeId: i})}
                inWorkspace={this.props.workspaceId !== 0}
                t={this.props.t}
                tReady={this.props.tReady}
            />

        const shapes: SelectShapeMappingData[] = this.props.shapes.map((s) => ({
            name: s.name, 
            content: s.content, 
            info: `${this.props.t('created_at')} ${formatDate(new Date(s.pub_date))}`,
            id: s.id
        }))

        return (
            <div 
                style={{width:"95%", marginLeft: 10, height: 1090}}
            >
                <p style={{fontSize: 18}}>
                    <b>{this.props.t('active_shapes')}</b>
                    <TutorialTooltip 
                        active={this.props.demoStep === 1 || this.props.demoStep === 4} 
                        text={this.props.t('tutorial_shapes')}
                    />
                </p>
                
                {
                    <>
                    <Whisper 
                        trigger={'focus'}
                        placement='auto'
                        speaker={
                            <Popover>
                                <ButtonGroup vertical>
                                    <Button 
                                        appearance='ghost' 
                                        onClick={e => {
                                            this.props.addShape()
                                            this.props.setActiveShape(this.props.fdata.shapes.length-1)
                                        }}
                                    >
                                        {this.props.t('add_empty_shape')}
                                    </Button>
                                    <Button 
                                        appearance='ghost' 
                                        onClick={e => this.setState({showLoadModal: true})}
                                        disabled={this.props.workspaceId === 0}
                                    >
                                        {this.props.t('load_from_workspace')}
                                    </Button>
                                    <Button 
                                        appearance='ghost' 
                                        onClick={e => this.setState({showImportModal: true})}
                                    >
                                        {this.props.t('import_from_json')}
                                    </Button>
                                </ButtonGroup>
                            </Popover>
                        }
                    >
                        <IconButton 
                            appearance='ghost' 
                            style={{width: '100%', marginTop: 20}} 
                            icon={<Icon icon='plus'/>}
                        > 
                            {this.props.t('add_new_shape')}
                        </IconButton>
                    </Whisper>

                    <Table 
                        height={200} 
                        minHeight={100}
                        data={fdata_shapes.map(s => ({...s, key: s.name + s.id}))} 
                        rowKey={'key'}
                        id="table" hover
                    >

                        <Table.Column width={40}>
                            <Table.HeaderCell>{''}</Table.HeaderCell>
                            <Table.Cell>
                                {(s:ShapeInput, i:number) => 
                                <Whisper 
                                    speaker={
                                        <Tooltip>
                                            {
                                                this.props.activeShape !== i ? 
                                                    this.props.t('set_active_tooltip') : 
                                                    this.props.t('active_tooltip')
                                            }
                                        </Tooltip>
                                    }
                                >
                                    <Icon 
                                        icon={this.props.activeShape === i ? 'circle' : 'circle-o'} 
                                        onClick={() => this.props.setActiveShape(i)}
                                        style={{cursor: 'pointer', color: ShapeColors[i]}}
                                    />
                                </Whisper>
                                }
                            </Table.Cell>
                        </Table.Column>
        
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>{this.props.t('name')}</Table.HeaderCell>
                            <Table.Cell>
                                {(s:ShapeInput, i:number) => 
                                    <EditText 
                                        size={10}
                                        value={s.name}
                                        onSave={(name) => s.name = name}
                                        editClassName={''}
                                    />
                                }
                            </Table.Cell>
                        </Table.Column>
                
                        <Table.Column fixed='right' width={100}>
                            <Table.HeaderCell>{this.props.t('actions')}</Table.HeaderCell>
                            <Table.Cell>
                                {(s:ShapeInput, i:number) => (
                                    <Whisper trigger={'active'} speaker={<Popover>{shapeMenu(i)}</Popover>}>
                                        <IconButton icon={<Icon icon={'more'} />} />
                                    </Whisper>                        
                                )}
                            </Table.Cell>
                        </Table.Column>
                    </Table>

                    <Divider />

                    {this.props.activeShape >= 0 ? 
                        <Panel 
                            header={'Shape overview'} 
                            collapsible 
                            defaultExpanded={false} 
                            bordered 
                            style={{overflow: 'auto', overflowWrap: 'normal', maxHeight: 700}}
                        >
                            <ShapePanel
                                {...this.props}
                                shapeInput={this.props.fdata.shapes[this.props.activeShape]}
                                idx={this.props.activeShape}
                                onChange={f => this.props.onChange(f, this.props.activeShape)}
                                showSaveModal={(s:number) => 
                                    this.setState({showSaveModal: true, modalShapeId: s})}
                                showExportModal={(s:number) => 
                                    this.setState({showExportModal: true, modalShapeId: s})}
                            />
                        </Panel>
                        : 
                        ''
                    }
                  </>
                }

                <MyModal
                    title={this.props.t('load_shape')}
                    show={this.state.showLoadModal}
                    onClose={() => this.setState({showLoadModal: false})}
                    full
                >
                    <ShapeMappingSelector
                        onSelect={(shape:SelectShapeMappingData) => {
                            this.loadShape(shape.content)
                            this.setState({showLoadModal: false}) 
                            this.props.setActiveShape(this.props.fdata.shapes.length-1)
                        }}
                        data={shapes}
                        actionName={this.props.t('load')}
                    />
                </MyModal>

                <MyModal 
                    title={this.props.t('import_shape')}
                    show={this.state.showImportModal} 
                    onClose={() => this.setState({showImportModal: false})} 
                    onAccept={() => {
                        try {
                            this.loadShape(this.state.importShape) 
                            this.setState({showImportModal: false})
                        } catch (error) {
                            alert(this.props.t('import_shape_error'))
                        }
                    }} 
                >
                    <div style={{ display: "inline-block", marginBottom: "30px", width: "100%" }}>
                        <Input 
                            value={this.state.importShape}
                            placeholder={this.props.t('enter_shape')} 
                            onChange={(newShapeName, ev) => this.setState({importShape:newShapeName})}
                            margin="11px"
                        />
                    </div>
                </MyModal>

                {modalShape !== undefined ?
                    <>
                    <MyModal 
                        title={this.props.t('save_shape')}
                        show={this.state.showSaveModal} 
                        onClose={() => this.setState({showSaveModal: false})} 
                        full
                    >
                        <ShapeMappingSelector
                            onSelect={(overwrite_shape:SelectShapeMappingData) => {
                                this.overwrite(overwrite_shape, modalShape!)
                                this.setState({showSaveModal: false})
                            }}
                            data={shapes}
                            actionName={this.props.t('overwrite')}
                        />
                        <h4 style={{fontSize: 20}}>{this.props.t('save_new_shape')}</h4>
                        <ButtonToolbar style={{display: 'flex'}}>
                            <Input 
                                value={modalShape!.name}
                                placeholder={this.props.t('enter_shape_name')} 
                                onChange={(newShapeName, ev) => {
                                    modalShape!.name = newShapeName
                                    this.forceUpdate()
                                }}
                                style={{width: '40%'}}
                            />
                            <Button appearance='ghost' onClick={() => {this.saveShape(modalShape!)}}>
                                {this.props.t('save_shape')}
                            </Button>
                        </ButtonToolbar>
                    </MyModal>

                    <MyModal 
                        title={this.props.t('export_shape')}
                        show={this.state.showExportModal && modalShape !== undefined} 
                        onClose={() => this.setState({showExportModal: false})}
                        onAccept={() => this.setState({showExportModal: false})} 
                    >
                        <div>{this.exportShape(modalShape!)}</div>

                        <Button 
                            className='shape-panel-btn' 
                            onClick={() => {this.downloadShape(modalShape!)}} 
                            display={'inline-block'}
                        >
                            {this.props.t('save_to_file')}
                        </Button>

                        <Whisper 
                            trigger={'click'} 
                            placement={'top'} 
                            speaker={<Tooltip>{this.props.t('copied')}</Tooltip>}
                        >
                            <Button 
                                className='shape-panel-btn' 
                                onClick={() => {navigator.clipboard.writeText(this.exportShape(modalShape!))}}
                            >
                                {this.props.t('copy')}
                            </Button>
                        </Whisper>

                    </MyModal>
                    </>
                : ''
                }

                <div style={{marginTop: 26, width: '100%'}}>
                    <IconButton 
                        appearance='primary' 
                        style={{width: '100%'}} 
                        onClick={e => {
                            this.props.applyShapes()
                            if(this.props.demoStep === 4) this.props.setDemoStep(5)
                        }}
                        icon={<Icon icon='circle'/>}
                    > 
                        {this.props.t('apply')}
                    </IconButton>
                </div>
            </div>
        );
    }
}

interface ShapeMenuProps{
    idx: number
    shapes: ShapeInput[]
    removeShape: (n:number) => void
    setFilterShape: (n:number) => void
    moveShapeUp: (n:number) => void
    moveShapeDown: (n:number) => void
    setActiveShape: (n:number) => void
    showSaveModal: () => void
    showExportModal: () => void
    filterShape: number | undefined
    inWorkspace: boolean
    t: (k:string) => string
    tReady: boolean
}

class ShapeMenu extends React.Component<ShapeMenuProps>{

    render(){
        return(
            <ButtonGroup vertical style={{marginLeft: -10}}>
                <Whisper 
                    delay={WHISPER_DELAY} 
                    placement='left' 
                    speaker={<Tooltip>{this.props.t('remove')}</Tooltip>}
                >
                    <IconButton
                        icon={<Icon icon="close"/>} 
                        circle 
                        style={{marginLeft: "10px", marginTop: "2px", background: "transparent"}} 
                        onClick= {e => this.props.removeShape(this.props.idx)}
                    />
                </Whisper>

                <Whisper 
                    delay={WHISPER_DELAY} 
                    placement='left' 
                    speaker={<Tooltip>{this.props.t('move_up')}</Tooltip>}
                >
                    <IconButton 
                        disabled={this.props.idx === 0} 
                        icon={<Icon icon="arrow-up"/>} 
                        circle 
                        style={{marginLeft: "9px", background: "transparent"}} 
                        onClick= {e => this.props.moveShapeUp(this.props.idx)}
                    />
                </Whisper>

                <Whisper 
                    delay={WHISPER_DELAY} 
                    placement='left' 
                    speaker={<Tooltip>{this.props.t('move_down')}</Tooltip>}
                >
                    <IconButton 
                        disabled={this.props.idx >= this.props.shapes.length-1} 
                        icon={<Icon icon="arrow-down"/>} 
                        circle 
                        style={{marginLeft: "9px", background: "transparent"}} 
                        onClick= {e => this.props.moveShapeDown(this.props.idx)}
                    />
                </Whisper>

                <Whisper 
                    delay={WHISPER_DELAY} 
                    placement='left' 
                    speaker={<Tooltip>{this.props.t('filter_matched_log_entries')}</Tooltip>}
                >
                    <IconButton 
                        icon={this.props.filterShape === this.props.idx ? <BsFilterSquareFill/> : <BsFilterSquare/>} 
                        circle 
                        style={{marginLeft: "9px", background: "transparent"}} 
                        onClick= {e => this.props.setFilterShape(this.props.idx)}
                    />
                </Whisper>

                <Whisper 
                    delay={WHISPER_DELAY} 
                    placement='left' 
                    speaker={<Tooltip>{this.props.t('save_shape')}</Tooltip>}
                >
                    <IconButton 
                        icon={<Icon icon={'save'} />} 
                        circle 
                        style={{marginLeft: "9px", background: "transparent"}} 
                        onClick={e => this.props.showSaveModal()}
                        disabled={!this.props.inWorkspace}
                    />
                </Whisper>

                <Whisper 
                    delay={WHISPER_DELAY} 
                    placement='left' 
                    speaker={<Tooltip>{this.props.t('export_shape')}</Tooltip>}
                >
                    <IconButton 
                        icon={<Icon icon={'export'} />} 
                        circle 
                        style={{marginLeft: "9px", background: "transparent"}} 
                        onClick= {e => this.props.showExportModal()}
                    />
                </Whisper>

            </ButtonGroup>
        )
    }
}

export default withTranslation()(ShapeOverview)
