import { formatDate, thirtyDaysInMs } from "../utils/utils"
import { ActionsToGdpr, LogRecord, Mapping } from "./engine-types"

export type LegalGrounds        = { name: "Legal Grounds",      dsid: string, data: string, info: string }        
export type Delete              = { name: "Delete",             dsid: string, data: string, dataid: string, graceperiod: number }
export type DsAccess            = { name: "Ds Access",          dsid: string }
export type Inform              = { name: "Inform",             dsid: string, data: string }
export type Processing          = { name: "Processing",         dsid: string, data: string, dataid: string }
export type NotifyProc          = { name: "Notify Proc",        dsid: string, processorid: string, data: string, dataid: string }
export type ShareWith           = { name: "Share With",         dsid: string, processorid: string, data: string, dataid: string, info: string }
export type Collect             = { name: "Collect",            dsid: string, data: string, dataid: string, info: string }
export type CollectAndInform    = { name: "Collect And Inform", dsid: string, data: string, dataid: string, info: string }
export type DsDelete            = { name: "Ds Delete",          dsid: string, data: string, dataid: string, timelimit: number }
export type DsConsent           = { name: "Ds Consent",         dsid: string, data: string }
export type DsRestrict          = { name: "Ds Restrict",        dsid: string, data: string, dataid: string }
export type DsRepeal            = { name: "Ds Repeal",          dsid: string, data: string, dataid: string }
export type DsAccessRequest     = { name: "Ds Access Request",  dsid: string, timelimit: number }
export type DsRevoke            = { name: "Ds Revoke",          dsid: string, data: string }
export type DsObject            = { name: "Ds Object",          dsid: string, data: string }
export type EndOfCase           = { name: "End of Case",        dsid: string }

export type CoreGDPRSignature =
    | LegalGrounds  
    | Delete 
    | Processing 
    | DsAccess 
    | Inform
    | NotifyProc
    | ShareWith
    | Collect
    | DsDelete
    | DsConsent
    | DsRestrict
    | DsRepeal
    | DsAccessRequest
    | DsRevoke
    | DsObject
    | EndOfCase
    | CollectAndInform

export const GDPRActionInputs = () : CoreGDPRSignature[] =>  {
    const names : CoreGDPRSignature["name"][] = [
        "Legal Grounds",
        "Delete",
        "Processing",
        "Ds Access",
        "Inform",
        "Notify Proc",
        "Share With",
        "Collect",
        "Collect And Inform",
        "Ds Delete",
        "Ds Consent",
        "Ds Restrict",
        "Ds Repeal",
        "Ds Access Request",
        "Ds Revoke",
        "Ds Object",
        "End of Case"
    ]
    const dsid = "" , data = "", dataid = "", processorid = "", graceperiod = 0, timelimit = thirtyDaysInMs, info = ""
    return names.map(name => newAction({ name, dsid, data, dataid, processorid, graceperiod, timelimit: timelimit, info: info }, dsid, dataid))
}

export function newAction(a: CoreGDPRSignature, dsid: string, dataid: string, graceperiod?:number, timelimit?: number, info?:string) : CoreGDPRSignature {
    switch (a.name){
        case ("Ds Access"):
        case ("End of Case"):
            let a1 : DsAccess | EndOfCase = { name: a.name, dsid }
            return a1
        case ("Ds Access Request"):
            let a6 : DsAccessRequest = { name: a.name, dsid, timelimit: timelimit ? timelimit : thirtyDaysInMs }
            return a6
        case ("Ds Consent"):
        case ("Ds Revoke"):
        case ("Ds Object"):
            let a2 : DsConsent | DsRevoke | DsObject = { name: a.name, data: a.data, dsid }
            return a2
        case ("Inform"):
        case ("Legal Grounds"):
            let a8 : Inform | LegalGrounds = { name: a.name, data: a.data, dsid, info: info ? info : '' }
            return a8 
        case ("Notify Proc"): 
            let a3 : NotifyProc = { name: a.name, dsid, data: a.data, processorid: a.processorid, dataid}
            return a3
        case ("Share With"):
            let a10 : ShareWith = { name: a.name, dsid, data: a.data, processorid: a.processorid, dataid, info: info ? info : '' }
            return a10
        case ("Processing"):
        case ("Ds Restrict"):
        case ("Ds Repeal"):
            let a4 : Processing | DsRestrict | DsRepeal = { name: a.name, data: a.data, dsid, dataid }
            return a4
        case ("Collect"):
        case ("Collect And Inform"):
            let a9 : Collect | CollectAndInform = { name: a.name, data: a.data, dsid, dataid, info: info ? info : ''  }
            return a9
        case ("Ds Delete"):
            let a7 : DsDelete = { name: a.name, data: a.data, dsid, dataid, timelimit: timelimit ? timelimit : thirtyDaysInMs }
            return a7
        case ("Delete"):
            let a5 : Delete = { name: a.name, data: a.data, dsid, dataid, graceperiod: graceperiod ? graceperiod : 0 }
            return a5
        } 
}

export function toString(a: CoreGDPRSignature) : string {
    switch (a.name){
        // { name, dsid }
        case ("Inform"):
        case ("Ds Access"):
        case ("Ds Access Request"):
        case ("End of Case"):
            return `${a.name}(${a.dsid})`
        // { name, dsid, data }
        case ("Legal Grounds"): 
        case ("Ds Consent"):
        case ("Ds Revoke"):
        case ("Ds Object"):
            return `${a.name}(${a.dsid}, ${a.data})`
        // { name, processorid, dataid }
        case ("Notify Proc"): 
        case ("Share With"):
            return `${a.name}(${a.processorid}, ${a.dataid})`
        // { name, dsid, data, dataid }
        case ("Delete"):
        case ("Processing"):
        case ("Collect"):
        case ("Collect And Inform"):
        case ("Ds Delete"):
        case ("Ds Restrict"):
        case ("Ds Repeal"):
            return `${a.name}(${a.dsid}, ${a.data}, ${a.dataid})`
    } 
}

export function description (a: CoreGDPRSignature & LogRecord) : string { 
    switch (a.name) {
        case "Inform":
            return `Inform data subject (${a.dsid}) about collection of data ${a.data}.`
        case "End of Case":
            return `End of case for (${a.dsid}).`
        case "Ds Access":
            return `Access grant to data subject (${a.dsid}). Time: [${formatDate(a.timestamp)}].`      // `Access requested on [${accessRequest.timestamp.toLocaleString()}]`
        case "Ds Access Request":
            return `Access request from data subject (${a.dsid}). Time: [${formatDate(a.timestamp)}].`    // `Access provided on [${record.timestamp.toLocaleString()}]`
        case "Legal Grounds":
            return `Legal basis claimed on datapoint ${a.data} about data subject (${a.dsid}).` // `Legal grounds for datapoint ${record.data} about ${record.dsid}`,
        case "Ds Consent":
            return `Consent for datapoint ${a.data} (id: ${a.dataid}) from data subject (${a.dsid}).`
        case "Ds Revoke":
            return `Revocation of consent for datapoint ${a.data} from data subject (${a.dsid}).`
        case "Ds Object":
            return `Objection to processing of datapoint ${a.data} from data subject (${a.dsid}) based on Art. 6 (1e-f).` // Objection on datapoint ${record.data} from data subject ${record.dsid}
        case "Notify Proc": 
            return `Notify deletion request of datapoint (id: ${a.dataid}) to processor (${a.processorid}). Time: [${formatDate(a.timestamp)}]`
        case "Share With":
            return `Share datapoint (id: ${a.dataid}) with processor (${a.processorid})`
        case "Delete":
            return `Delete datapoint ${a.data} about (${a.dsid})`
        case "Processing":
            return `Process datapoint ${a.data} (id: ${a.dataid}) about data subject (${a.dsid})`      // `Processing of datapoint ${record.data} about ${record.dsid}`,
        case "Collect":
            return `Collect datapoint ${a.data} (id: ${a.dataid}) about data subject (${a.dsid})`
        case "Collect And Inform":
            return `Collect datapoint ${a.data} (id: ${a.dataid}) about data subject (${a.dsid}) and inform subject on the collect`
        case "Ds Delete":
            return `Deletetion request of data ${a.data} (id: ${a.dataid}) from data subject (${a.dsid}). Time: [${formatDate(a.timestamp)}]`
        case "Ds Restrict":
            return `Restrict processing of ${a.data} (id: ${a.dataid}) from data subject (${a.dsid})`   // `Restriction of processing for datapoint ${record.data} about ${record.dsid}`, 
        case "Ds Repeal":
            return `Repeal restriction on ${a.data} (id: ${a.dataid}) by data subject (${a.dsid})`
    }
}  

export function mappingToCSV(mapping:ActionsToGdpr){
    let rows:string[][] = [["Log action", "GDPR action", "data", "processorid", 
                            "description", "start of case", "if data collected", 
                            "grace period", "time limit", "info"]]

    for(const a in mapping){
        let actions = mapping[a]
        if(actions.gdprActions.length <= 0){
            rows.push([
                a,
                '',
                '',
                '',
                actions.description,
                '',
                '',
                '',
                '',
                ''
            ])
        } else {
            rows = rows.concat(actions.gdprActions.map(m => {
                const s = m.signature
                let data = 'data' in s ? s.data : ''
                let processorid = 'processorid' in s ? s.processorid : ''
                let graceperiod = 'graceperiod' in s ? s.graceperiod + '' : ''
                let timeLimit = 'timelimit' in s ? s.timelimit + '' : ''
                let info = 'info' in s ? s.info : ''

                let csv = [
                    a,
                    s.name,
                    data,
                    processorid,
                    actions.description,
                    m.startOfCase + "",
                    m.ifDataCollected + "",
                    graceperiod,
                    timeLimit,
                    info
                ]
            
                return csv
            }))
        }
    }

    let csvContent = "data:text/csv;charset=utf-8,"

    rows.forEach(function(rowArray) {
        let row = rowArray.join(",")
        csvContent += row + "\r\n"
    })

    return csvContent
}

export function csvToMapping(csv:string){
    const lines = csv.split("\n")

    lines.shift()
  
    let result:ActionsToGdpr = {}
        
    for(const line of lines){
  
        var currentline=line.split(",")

        const entry = currentline[0]
        const name = currentline[1]
        const data = currentline[2]
        const processorid = currentline[3]
        const startOfCase = currentline[5]
        const ifDataCollected = currentline[6]
        const graceperiod = Number(currentline[7])
        const timeLimit = Number(currentline[8])
        const info = currentline[9]

        if(!entry) continue
        if(!name && !result[entry]){
            const mapping:Mapping = {gdprActions: [], description: currentline[4]}
            result[entry] = mapping
        } else{    
            const dataid = ''
            const dsid = ''

            const newAction = () => {
                switch (name){
                    case ("Ds Access"):
                    case ("End of Case"):
                        let a1 : DsAccess | EndOfCase = { name: name, dsid }
                        return a1
                    case ("Ds Access Request"):
                        let a7 : DsAccessRequest = { name: name, dsid, timelimit: timeLimit ? timeLimit : thirtyDaysInMs }
                        return a7 
                    case ("Inform"):
                    case ("Ds Consent"):
                    case ("Ds Revoke"):
                    case ("Ds Object"):
                        let a2 : Inform | DsConsent | DsRevoke | DsObject = { name: name, data: data, dsid }
                        return a2
                    case ("Legal Grounds"): 
                        let a8 : LegalGrounds = { name: name, data: data, dsid, info: info ? info : ''}
                        return a8
                    case ("Notify Proc"): 
                        let a3 : NotifyProc = { name: name, dsid: dsid, data: data, processorid: processorid, dataid}
                        return a3
                    case ("Share With"):
                        let a10 : ShareWith = { name: name, dsid: dsid, data: data, processorid: processorid, dataid, info: info ? info : ''}
                        return a10
                    case ("Processing"):
                    case ("Ds Restrict"):
                    case ("Ds Repeal"):
                        let a4 : Processing | DsRestrict | DsRepeal = { name: name, data: data, dsid, dataid }
                        return a4
                    case ("Collect"):
                    case ("Collect And Inform"):
                        let a9 : Collect | CollectAndInform = { name: name, data: data, dsid, dataid, info: info ? info : '' }
                        return a9
                    case ("Ds Delete"):
                        let a6 : DsDelete = { name: name, data: data, dsid, dataid, timelimit: timeLimit ? timeLimit : thirtyDaysInMs }
                        return a6
                    case ("Delete"):
                        let a5 : Delete = { name: name, data: data, dsid, dataid, graceperiod: graceperiod ? graceperiod : 0 }
                        return a5
                    default:
                        throw new Error()
                } 
            }
            
            const action = newAction()
            if(result[entry]) {
                result[entry].gdprActions.push({signature: action, startOfCase: startOfCase==="true", ifDataCollected: ifDataCollected==="true"})
            }
            else {
                const mapping:Mapping = {gdprActions: [{signature: action, startOfCase: startOfCase==="true",  ifDataCollected: ifDataCollected==="true"}], description: currentline[4]}
                result[entry] = mapping
            }
        }
    }
  
    return result
}
